import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import LogRocket from 'logrocket';

import { rootReducer } from './rootSlices';
import rootSaga from './rootSaga';

import config from 'config';

const sagaMiddleware = createSagaMiddleware();
const customizedMiddleware = getDefaultMiddleware({
  thunk: false,
  serializableCheck: false,
  immutableCheck: false,
});

const middleware = [...customizedMiddleware, sagaMiddleware];

if (config.LOGROCKET_ID) {
  const logRocketMiddleware = LogRocket.reduxMiddleware();
  middleware.push(logRocketMiddleware);
}

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
});

sagaMiddleware.run(rootSaga);

export type AppDispatch = typeof store.dispatch;

export default store;
