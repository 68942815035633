import { PayloadAction } from '@reduxjs/toolkit';

export const getSelectedOptionKey = (option) => {
  let selectedOptionKey = null;
  option.ucs.some((uc, key) => {
    uc.classSchedule.some((ucClass) => {
      if (ucClass.selected) {
        selectedOptionKey = key;
      }
    });
  });

  return selectedOptionKey;
};

export const findSelectedOptions = (state, action: PayloadAction<number[]>) => {
  return state.options.map((option) => ({
    ...option,
    selected: option.ucs.reduce((acc: number | null, uc) => {
      const selectedIndex = uc.classSchedule.findIndex((classUnit) =>
        action.payload.includes(classUnit.classId)
      );

      if (selectedIndex >= 0) {
        return selectedIndex;
      }
      return acc;
    }, null),
  }));
};

export const ucSelectedOtherOption = (currentOption, currentClass, options) => {
  return options.some((option) => {
    if (option.optionId !== currentOption.optionId) {
      if (option.selected !== null && option.selected !== undefined) {
        if (option.ucs[option.selected].ucId === currentClass.ucId) {
          return true;
        }
      }
    }
    return false;
  });
};
