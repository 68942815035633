export enum GENERAL_ERROR_MESSAGE {
  INTERCEPTOR_LOGOUT = 'INTERCEPTOR_LOGOUT',
  BLACKLISTED_REQUEST = 'BLACKLISTED_REQUEST',
  ERR_INVALID_STUDENT = 'ERR_INVALID_STUDENT',
  ERR_STUDENT_INVALID_REGISTRATION = 'ERR_STUDENT_INVALID_REGISTRATION',
  ERR_SGH_SCHEDULE_MAX = 'ERR_SGH_SCHEDULE_MAX',
  ERR_INVALID_ACCESS = 'ERR_INVALID_ACCESS',
}

// grouped error message
export enum GENERAL_ERROR_MESSAGE_SERVER {
  ERR_SERVER = 'ERR_SERVER',
  ERR_FORBIDDEN_REQUEST = 'ERR_FORBIDDEN_REQUEST',
  ERR_WEBSERVICE_COMMUNICATION = 'ERR_WEBSERVICE_COMMUNICATION',
  ERR_GESDOC_FILE_NOT_FOUND = 'ERR_GESDOC_FILE_NOT_FOUND',
  ERR_GESDOC_COMMUNICATION = 'ERR_GESDOC_COMMUNICATION',
  ERR_UNKNOWN = 'ERR_UNKNOWN',
  ERR_SQLSERVER_DATA = 'ERR_SQLSERVER_DATA',
  ERR_SQLSERVER = 'ERR_SQLSERVER',
}

// grouped error message
export enum GENERAL_ERROR_MESSAGE_REQUEST {
  ERR_MISSING_PARAMETER = 'ERR_MISSING_PARAMETER',
  ERR_INVALID_PARAMETER = 'ERR_INVALID_PARAMETER',
  ERR_INVALID_TTL = 'ERR_INVALID_TTL',
  ERR_INVALID_TYPE = 'ERR_INVALID_TYPE',
  ERR_INVALID_LANG = 'ERR_INVALID_LANG',
  ERR_INVALID_TIMESTAMP = 'ERR_INVALID_TIMESTAMP',
  ERR_INVALID_REQUEST = 'ERR_INVALID_REQUEST',
}

export enum PERSONAL_DATA_ERROR_MESSAGE {
  ERR_STUDENT_PHOTO_NOT_FOUND = 'ERR_STUDENT_PHOTO_NOT_FOUND',
  ERR_STUDENT_DOESNT_EXIST = 'ERR_STUDENT_DOESNT_EXIST'
}

export enum APPLICATION_ERROR_MESSAGE {
  ERR_STUDENT_INVALID_APPLICATION = 'ERR_STUDENT_INVALID_APPLICATION',
  ERR_STUDENT_DUPLICATE_APPLICATION = 'ERR_STUDENT_DUPLICATE_APPLICATION',
  ERR_STUDENT_NO_APPLICATIONS = 'ERR_NO_APPLICATIONS',
}

export enum PAYMENT_ERROR_MESSAGE {
  ERR_STUDENT_DUPLICATE_PAYMENT_METHOD = 'ERR_STUDENT_DUPLICATE_PAYMENT_METHOD',
  ERR_STUDENT_INVALID_PAYMENT = 'ERR_STUDENT_INVALID_PAYMENT',
  ERR_STUDENT_INVALID_DEBIT = 'ERR_STUDENT_INVALID_DEBIT',
  ERR_DEBT_DELETED = 'ERR_DEBT_DELETED',
  ERR_DEBT_PAID = 'ERR_DEBT_PAID',
  ERR_ASSOCIATED_DEBT_MBREF = 'ERR_ASSOCIATED_DEBT_MBREF',
}

export enum CURRICULAR_PLAN_ERROR_MESSAGE {
  ERR_REGISTRATION_INVALID_UC = 'ERR_REGISTRATION_INVALID_UC',
}

export enum GRADES_ERROR_MESSAGE {
  ERR_FORBIDDEN_PAUTA_REQUEST = 'ERR_FORBIDDEN_PAUTA_REQUEST',
  ERR_TEACHER_NOT_FOUND = 'ERR_TEACHER_NOT_FOUND',
}

export enum CERTIFICATE_ERROR_MESSAGE{
  ERR_REGISTRATION_SUSPENDED_CERT = 'ERR_REGISTRATION_SUSPENDED_CERT',
  ERR_REGISTRATION_NOT_FINISHED_CERT = 'ERR_REGISTRATION_NOT_FINISHED_CERT',
  ERR_UNPAID_CERT_DEBTS = 'ERR_UNPAID_CERT_DEBTS',
  ERR_STUDENT_INVALID_CERTIFICATE = 'ERR_STUDENT_INVALID_CERTIFICATE',
  ERR_STUDENT_INVALID_CERTIFICATE_TYPE = 'ERR_STUDENT_INVALID_CERTIFICATE_TYPE',
  ERR_CERT_PAID = 'ERR_CERT_PAID',
  ERR_CERT_CANCELLED = 'ERR_CERT_CANCELLED',
  ERR_IDENTICAL_CERT_PROCESSING = 'ERR_IDENTICAL_CERT_PROCESSING',
  ERR_GRADE_FINAL_COURSE = 'ERR_GRADE_FINAL_COURSE',
  ERR_FINAL_COURSE_DOC_FINAL_MISSING = 'ERR_FINAL_COURSE_DOC_FINAL_MISSING',
  ERR_DEBT_DELETED = 'ERR_DEBT_DELETED',
}
