/**
 * SchedulesHome scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getSchedulesHomeSlice = (state: RootState) => state.SchedulesHome;
