/**
 * NewApplicationStatement container logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getLoadingStatus = (state: RootState) => state.NewApplication.loadingStatus;
export const getUser = (state: RootState) => state.App.user;
export const getNewApplicationData = (state: RootState) => state.NewApplication.applicationData;
export const getNewApplicationNumAdditionalDocs = (state: RootState) =>
  state.NewApplication.numAdditionalDocs;
export const getNewApplicationSlice = (state: RootState) => state.NewApplication;
