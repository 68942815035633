import React, { useEffect, useState } from 'react';
import config from 'config';
import styled from 'styled-components';
import { Button, DropdownFlags, Loading } from '@paco_ua/pacoui';
import crypto from 'crypto-browserify';
import useSignalRConnection from './useSignalRConnection';
import { HubConnectionState } from '@microsoft/signalr';
import { t } from 'app';
import { useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';
import AmaLogo from './CMD-assinatura-2.png';

const ModalWrapper = styled.div<{ height?: string }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: ${(props) => props.height || 'auto'};
  width: 100%;
  position: relative;
  flex-grow: 1;

  @media (max-width: 768px) {
    gap: 0.5rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 2rem;
  flex-grow: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    order: 2;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-grow: 1;
  justify-content: flex-end;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: auto;

  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const ModalButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  @media (max-width: 768px) {
    justify-content: center;
    gap: 0.5rem;
  }
`;

const ModalHeader = styled.div`
  display: flex;
  color: #5e81a9;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

const LeftSide = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    order: 1;
  }
`;

const AmaLogoWrapper = styled.div`
  width: 4rem;
  height: 3rem;
  object-fit: container;

  > img {
    width: 100%;
    height: 100%;
  }
`;

const FormInputWrapper = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

const AmaDescLink = styled.a`
  color: #0000ee;
  text-decoration: underline;
`;

const AmaDesc = styled.div`
  background-color: rgba(60, 93, 188, 0.2);
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0.6rem;

  @media (max-width: 768px) {
    padding: 0.5rem 0.3rem;
    gap: 0.25rem;
  }
`;

const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    margin-bottom: 0.25rem;
  }
`;

const FormInput = styled.input`
  width: 100%;
  padding: 1rem 0.5rem;
  border: 1px solid #ccc;
  outline: none;

  @media (max-width: 768px) {
    padding: 0.5rem 0.25rem;
  }
`;

const ErrorsWrapper = styled.div`
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const RedirectToAma = styled.a`
  color: #5e81a9;
  font-style: italic;
  font-size: 0.9em;
`;

const PautasList = styled.div`
  max-height: 8rem;
  overflow-y: auto;  padding: 0.5rem;

  @media (max-width: 768px) {
    max-height: 6rem;
  }
`;

const PautaItem = styled.div`
  font-size: 0.9em;
  padding: 0.1rem 0.3rem 0.1rem 0.1rem;
  margin-bottom: 0.2rem;
  background-color: #f0f0f0;
`;

const PautasListHeader = styled.div`
  font-size: 0.9em;
  color: #5e81a9;
  padding: 0.1rem 0.3rem 0.1rem 0.1rem;
`;


const PautasListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border: solid 2px #f0f0f0;
  padding 0.5rem;

  @media (max-width: 768px) {
    gap: 0.25rem;
  }
`;

const PautasListContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AmaSMS = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #f0f0f0;
  padding: 0.3rem;
  margin-top: 1rem;

  > span {
    font-size: 0.9em;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.15rem;
  }
`;

const AmaWarning = styled.span`
  font-weight: bold;
  font-size: 0.9em;
`;

interface Props {
  onClose: (success?: boolean) => void;
  pautas: { paraAssinaturaId: number; codPauta: string }[];
}

const SigningModal: React.FC<Props> = (props) => {
  const { connection, initiateConnection } = useSignalRConnection();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountryCode, setSelectedCountryCode] = useState('+351'); // default to Portugal
  const [pin, setPin] = useState('');
  const [otp, setOtp] = useState('');
  const [processId, setProcessId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // State to handle error messages
  const [detailErrorMessage, setDetailErrorMessage] = useState<string | null>(null); // State to handle error messages
  const [isLoading, setIsLoading] = useState(false); // state to toggle loading spinner
  const dispatch = useDispatch();

  // Regex patterns for validation
  const phonePattern = /^[+][0-9]{1,4} [0-9]{5,15}$/; // e.g. +351 912345678

  useEffect(() => {
    initiateConnection();
  }, []);

  useEffect(() => {
    let wsConnClosedWithSuccess = false;

    // Handle the received process ID
    connection?.on('ReceiveProcessId', (processId: React.SetStateAction<string | null>) => {
      setProcessId(processId);
      setIsLoading(false); // Stop loading spinner after receiving the process ID
    });

    // Handle the received PDF data
    connection?.on('ReceiveSignedPdf', (data) => {
      setIsLoading(false); // Stop loading spinner after receiving the PDF

      const base64Data = data.data;
      const fileName = data.fileName;

      const bytes = Uint8Array.from(atob(base64Data), (c) => c.charCodeAt(0));

      // Determine the content type based on file extension
      const contentType = fileName.endsWith('.zip') ? 'application/zip' : 'application/pdf';
      const blob = new Blob([bytes], { type: contentType });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      window.URL.revokeObjectURL(link.href);

      props.onClose(true);

      // Close the connection after receiving the PDF
      if (connection) {
        wsConnClosedWithSuccess = true;

        connection
          .stop()
          .then(() => {
            console.log('Connection closed after receiving PDF.');
          })
          .catch((err: any) => console.error('Error while closing the connection:', err));
      }
    });

    // Handle the received error messages
    connection?.on('Error', (errorMessage: string) => {
      setErrorMessage(t('pautas.errorHelpDesk'));
      setDetailErrorMessage(processErrorMessage(errorMessage));
      setIsLoading(false); // Stop loading spinner in case of an error
    });

    // This event handler listens for the connection closing.
    // This can be triggered for various reasons such as server restarts,
    // network disruptions, or the server intentionally closing the connection.
    connection?.onclose(() => {
      console.log('WS Connection was closed.');
      setErrorMessage(t('pautas.wsConnectionClosed'));
      setIsLoading(false);
      props.onClose(false);

      // Show toaster only if the connection was closed unexpectedly
      if (!wsConnClosedWithSuccess) {
        dispatch(actions.Toaster.showToaster({
          title: t('pautas.wsConnectionClosed'),
          icon: 'info',
          type: 'info',
        }));
      }
    });

    // Cleanup: remove the event listeners when the component unmounts
    return () => {
      connection?.off('ReceiveProcessId');
      connection?.off('ReceiveSignedPdf');
      connection?.off('Error');
    };
  }, [connection]);

  const handleInitiateSigning = async () => {
    console.log('HandleInitiateSigning Connection ID:', connection?.connectionId);

    if (!config.GRADES_MANAGEMENT_ENCRYPT_DATA_PUBLIC_KEY) {
      console.error('Public key not found in environment variables.');
      return;
    }

    // Clear the previous error message
    setErrorMessage(null);
    setDetailErrorMessage(null);

    // Validate phone number
    const completePhoneNumber = `${selectedCountryCode} ${phoneNumber}`;

    if (!phonePattern.test(completePhoneNumber)) {
      setErrorMessage(t('pautas.invalidPhoneNumber'));
      return;
    }

    // Call the InitiateSigning method on the server
    const encryptedPhoneNumber = encryptWithPublicKey(completePhoneNumber, config.GRADES_MANAGEMENT_ENCRYPT_DATA_PUBLIC_KEY);
    const encryptedPin = encryptWithPublicKey(pin, config.GRADES_MANAGEMENT_ENCRYPT_DATA_PUBLIC_KEY);
    const pautaIds = props.pautas.map(pauta => pauta.paraAssinaturaId);

    setIsLoading(true); // Start loading spinner while waiting for the response

    connection?.invoke('InitiateSigning', encryptedPhoneNumber, encryptedPin, pautaIds).catch((err) => {
      setErrorMessage(t('pautas.errorHelpDesk'));
      console.error('Error initiating signing:', err);
      setIsLoading(false); // Stop loading spinner in case of an error
    });
  };

  const handleCompleteSigning = async () => {
    // Clear the previous error message
    setErrorMessage(null);

    if (!processId) {
      setErrorMessage(t('pautas.errorHelpDesk'));
      console.error('ProcessID is missing. Please initiate signing first.');
      return;
    }

    // Call the InitiateSigning method on the server
    const pautaIds = props.pautas.map(pauta => pauta.paraAssinaturaId);
    const encryptedOtp = encryptWithPublicKey(otp, config.GRADES_MANAGEMENT_ENCRYPT_DATA_PUBLIC_KEY);
    setIsLoading(true); // Start loading spinner while waiting for the response

    connection?.invoke('CompleteSigning', processId, encryptedOtp, pautaIds).catch((err) => {
      setErrorMessage(t('pautas.errorHelpDesk'));
      console.error(err);
      setIsLoading(false); // Stop loading spinner in case of an error
    });
  };

  const handleForceSms = async () => {
    const completePhoneNumber = `${selectedCountryCode} ${phoneNumber}`;
    const encryptedPhoneNumber = encryptWithPublicKey(completePhoneNumber, config.GRADES_MANAGEMENT_ENCRYPT_DATA_PUBLIC_KEY);

    connection?.invoke('ForceSms', processId, encryptedPhoneNumber).catch((err) => {
      setErrorMessage(t('pautas.errorHelpDesk'));
      console.error(err);
    });
  }

  const encryptWithPublicKey = (data: string, publicKey: string): string => {
    const buffer: Buffer = Buffer.from(data, 'utf8');
    const encrypted: Buffer = crypto.publicEncrypt(
      {
        key: publicKey,
        padding: crypto.constants.RSA_PKCS1_PADDING,
      },
      buffer
    );

    return encrypted.toString('base64');
  };

  // If the error message contains white spaces, it is from AMA
  // If not, it comes from our backend and there should be a key in the translations
  const processErrorMessage = (errorMessage: string): string => {
    return containsWhitespace(errorMessage) ? errorMessage : t(`pautas.${errorMessage}`)
  }

  const containsWhitespace = (str: string): boolean => {
    return /\s/.test(str);
  }

  return (
    <>
      {connection?.state !== HubConnectionState.Connected ? (<Loading size="3x" overlay />) :
        (
          <ModalWrapper height="auto">
            <ModalHeader>
              <h3>{t('pautas.signingModalTitle')}</h3>
              <AmaLogoWrapper>
                <img src={AmaLogo} alt='AMA Logo' />
              </AmaLogoWrapper>
            </ModalHeader>
            <ContentWrapper>
              <LeftSide>
                {processId ? (
                  <>
                    <PautasListWrapper style={{ marginBottom: '1rem' }}>
                      <PautasListContainer>
                        <PautasListHeader>{t('pautas.signAma')}</PautasListHeader>
                        <PautasList>
                          {props.pautas.map((pauta) => (
                            <PautaItem key={pauta.paraAssinaturaId}>{pauta.codPauta}</PautaItem>
                          ))}
                        </PautasList>
                      </PautasListContainer>
                    </PautasListWrapper>
                    <span>{t('pautas.confirmAmaSign')}</span>
                    <AmaSMS>
                      <span>{t('pautas.sendSmsWarning')}.</span>
                      <Button style={{ fontSize: '0.9em', textTransform: 'uppercase' }} primary onClick={handleForceSms}>
                        {t('pautas.sendSMS')}
                      </Button>
                    </AmaSMS>
                  </>
                ) : (
                  <>
                    <AmaDesc>
                      <span>{t('pautas.digitalSign')} <AmaDescLink href='https://www.autenticacao.gov.pt/cmd-pedido-chave'>{t('pautas.digitalKey')}</AmaDescLink></span>
                      <span>{t('pautas.digitalSign2')} <AmaDescLink href='https://www.autenticacao.gov.pt/'>https://www.autenticacao.gov.pt/</AmaDescLink></span>
                    </AmaDesc>
                    <PautasListWrapper>
                      <PautasListContainer>
                        <PautasListHeader>{t('pautas.signAma')}</PautasListHeader>
                        <PautasList>
                          {props.pautas.map((pauta) => (
                            <PautaItem key={pauta.paraAssinaturaId}>{pauta.codPauta}</PautaItem>
                          ))}
                        </PautasList>
                      </PautasListContainer>
                    </PautasListWrapper>
                  </>
                )}
              </LeftSide>
              <RightSide>
                {processId ? (
                  <>
                    <FormInputWrapper>
                      <FormLabel htmlFor="otp">Código de segurança</FormLabel>
                      <FormInput
                        id="otp"
                        type="password"
                        autoFocus
                        placeholder="Inserir código de segurança temporário"
                        onChange={(event) => setOtp(event.target.value)}
                        value={otp}
                      />
                    </FormInputWrapper>

                    <AmaWarning>{t('pautas.warningSign')}.</AmaWarning>

                    <StyledContainer>
                      {/* Display error message if there's any */}
                      {(errorMessage || detailErrorMessage) && (
                        <ErrorsWrapper>
                          {detailErrorMessage && <ErrorMessage>{detailErrorMessage}</ErrorMessage>}
                          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </ErrorsWrapper>
                      )}

                      {isLoading ? (
                        <Loading size="3x" overlay />
                      ) : (
                        <ModalButtonsContainer>
                          <ModalButtons>
                            <Button danger onClick={props.onClose}>
                              {t('pautas.cancel', { textOnly: true })}
                            </Button>
                            <Button primary onClick={handleCompleteSigning}>
                              {t('pautas.submit', { textOnly: true })}
                            </Button>
                          </ModalButtons>
                        </ModalButtonsContainer>
                      )}
                    </StyledContainer>
                  </>
                ) : (
                  <>
                    <FormInputWrapper>
                      <FormLabel htmlFor="phoneNumber">{t('pautas.signingModalNumberPlaceholder')}</FormLabel>
                      <div style={{ display: 'flex' }}>
                        <div style={{ width: '25%', marginRight: '2%' }}>
                          <DropdownFlags defaultValue="pt" onChange={function noRefCheck() { }} />
                        </div>
                        <FormInput
                          id="phoneNumber"
                          placeholder={t('pautas.telemovel', {
                            textOnly: true,
                          })}
                          onChange={(event) => setPhoneNumber(event.target.value)}
                          value={phoneNumber}
                        />
                      </div>
                    </FormInputWrapper>

                    <FormInputWrapper>
                      <FormLabel htmlFor="pin">{t('pautas.insertPin')}</FormLabel>
                      <FormInput
                        id="pin"
                        type="password"
                        placeholder={t('pautas.signingModalPinPlaceholder', {
                          textOnly: true,
                        })}
                        onChange={(event) => setPin(event.target.value)}
                        value={pin}
                      />
                    </FormInputWrapper>

                    <StyledContainer>
                      {/* Display errors messages if there's any */}
                      {(errorMessage || detailErrorMessage) && (
                        <ErrorsWrapper>
                          {detailErrorMessage && <ErrorMessage>{detailErrorMessage}</ErrorMessage>}
                          {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                        </ErrorsWrapper>
                      )}

                      {isLoading ? (
                        <Loading size="3x" overlay />
                      ) : (
                        <ModalButtonsContainer>
                          <ModalButtons>
                            <Button danger onClick={props.onClose}>
                              {t('pautas.cancel', { textOnly: true })}
                            </Button>
                            <Button primary onClick={handleInitiateSigning}>
                              {t('pautas.continuar', { textOnly: true })}
                            </Button>
                          </ModalButtons>
                        </ModalButtonsContainer>
                      )}
                    </StyledContainer>
                  </>
                )}
              </RightSide>
            </ContentWrapper>
          </ModalWrapper>
        )}
    </>
  );
};

export default SigningModal;