/**
 * SeriationHistory scene slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISeriationResults } from 'shared/types';

interface ICurrentDetails {
  currentYear: string;
  currentScheduleName: null | string;
  allocationProb: null | 'high' | 'low';
  seriationDate: null | string;
  currentSemester: string;
}

interface ICurrentDetailsPayload {
  currentYear: string;
  currentScheduleName: null | string;
  allocationProb: null | 'high' | 'low';
  seriationDate: null | string;
  seriationId: null | number;
  currentSemester: string;
  phaseFilterList: string[];
  currentPhase: null | number;
  finalSeriation: null | boolean;
}

interface IGetSeriationPayload {
  registrationId: number;
  phase?: number;
  seriationId?: number;
}

const initialState = {
  seriationHistoryResults: [] as ISeriationResults[],
  currentDetails: {
    currentYear: '',
    currentScheduleName: null,
    allocationProb: null,
    seriationDate: null,
    currentSemester: '',
    existingPhases: [],
  } as ICurrentDetails,
  currentSelectedPhase: null as null | number,
  seriationId: null as null | number,
  finalSeriation: null as null | boolean,
  loading: true,
  invalidSchedule: false,
  phaseFilterList: [] as string[],
  filterParams: {
    phase: '' as string,
  },
};

export default createSlice({
  name: 'SeriationHistory',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    getSeriationHistoryResults: (state, action: PayloadAction<IGetSeriationPayload>) => {},
    setSeriationHistoryResults: (state, action: PayloadAction<ISeriationResults[]>) => {
      state.seriationHistoryResults = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCurrentDetails: (state, action: PayloadAction<ICurrentDetailsPayload>) => {
      const {
        phaseFilterList,
        currentPhase,
        seriationId,
        finalSeriation,
        ...currentDetails
      } = action.payload;
      state.currentDetails = currentDetails;
      state.phaseFilterList = phaseFilterList;
      state.currentSelectedPhase = currentPhase;
      state.seriationId = seriationId;
      state.finalSeriation = finalSeriation;
    },
    setCurrentSelectedPhase: (state, action: PayloadAction<number>) => {
      state.currentSelectedPhase = action.payload;
    },
    setIsScheduleInvalid: (state, action: PayloadAction<boolean>) => {
      state.invalidSchedule = action.payload;
    },
    reset: (state) => initialState,
  },
});
