import React, { useState, useEffect } from 'react';
import logo from './logo512.png';
import './CloseApp.css';
import config from 'config';

import { getStorage, setStorage } from 'utils';

const uuid = '0a07d29d-d043-4c7c-85bc-ac58f1d3c976';

const App = ({ children }) => {
  const [closed, setClosed] = useState(getStorage('63ERkoNglo') ? false : true);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const user = window.location.search.match(/user=([^&]+)/);
    if (user === uuid) {
      //setStorage('63ERkoNglo', uuid);
      setClosed(false);
    }
  }, []);

  const handleSubmit = (event) => {
    if (name === 'xU0deZnPLF0dhtE' && password === 'CN9Dk5c9Xu3oJIq') {
      setClosed(false);
      setStorage('63ERkoNglo', true);
    }
  };

  return children;
  if (!closed || config.ENVIRONMENT !== 'PROD') return children;
  else {
    return (
      <div className="CloseAppWrapper">
        <div className="App-disa">Acesso temporariamente restrito.</div>
        <div className="ContainerForm">
          <form className="form" onSubmit={handleSubmit}>
            <label className="form-inputs">
              Nome
              <input
                className="form-input"
                type="text"
                value={name}
                onChange={(e1) => setName(e1.target.value)}
              />
              <br />
              Password
              <input
                className="form-input"
                type="password"
                value={password}
                onChange={(e2) => setPassword(e2.target.value)}
              />
              <br />
              <input className="form-submit" type="submit" />
            </label>
          </form>
        </div>
        <div className="Container">
          <img src={logo} className="App-logo" alt="logo" />

          <div className="App-desc">
            Estamos a trabalhar num Portal Académico Online mais atual e com mais funcionalidades.
            <br />
            <div
              style={{
                marginTop: '50px',
                fontSize: '1.75rem',
                lineHeight: '1.5',
                fontWeight: 400,
                wordBreak: 'break-word',
              }}
            >
              Até lá continue a aceder a{' '}
              <a target="_blank" href="https://paco.ua.pt">
                https://paco.ua.pt
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default App;
