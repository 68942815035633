/**
 * Payments scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import _ from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDebitData, IPaymentData, IInitialState, ICallParams } from '../types';
import { DEFAULT_PAYMENTS, DEFAULT_DEBITS, DEFAULT_PENDING_DEBITS } from '../utils';

import { IWEBSOCKET_PAYLOAD } from 'scenes/VirtualSecretary/Payments/types';

const initialState: IInitialState = {
  debits: _.cloneDeep(DEFAULT_DEBITS), // debits list for the PendingDebitsPayment page -> allows for the pre selection of debits (before the payment steps)
  preSelectedDebits: [], // list of the pre selected debits
  selectedDebits: [], // pre seleted + debits selected on the "Other Debits" table
  payments: _.cloneDeep(DEFAULT_PAYMENTS), // payments already paid
  pendingDebits: _.cloneDeep(DEFAULT_PENDING_DEBITS),

  // resettable onUnmount
  multiSelectionModal: false, // "Other debits" toggle state on the first tab
  // debits table filter params
  filterParams: {
    type: '',
    search: '',
  },
  debitsFilterTypes: [],
  isMbInfoModalOpened: false,
  consultedMbPaymentInfo: null,
  loadingStatus: {
    loading: true,
    mbInfoModalLoading: true,
    loadingDebitsTable: true,
    loadingPendingDebitsTable: true,
    loadingPaymentsTable: true,
  },
  errorStatus: {
    errorLoadingMbInfo: false,
    errorLoadingDebitsTable: false,
    errorLoadingPendingDebitsTable: false,
    errorLoadingPaymentsTable: false,
  },
  isSocketActivated: false,
  isCancelPaymentsModalOpened: {
    id: -1,
    status: false,
  },
};

interface IPaymentsPayload {
  data: IPaymentData[];
  totalRecords: number;
}

interface IDebitsPayload {
  data: IDebitData[];
  totalRecords: number;
}

export default createSlice({
  name: 'Payments',
  initialState,
  reducers: {
    onMount: (state, action: PayloadAction<number | undefined>) => {},
    onUnmount: () => {},
    fetchData: () => {},
    fetchDebits: (state, action: PayloadAction<ICallParams>) => {
      state.debits.loading = true;
    },
    fetchPayments: (state, action: PayloadAction<ICallParams>) => {
      state.payments.loading = true;
    },
    fetchPendingDebits: (state) => {
      state.pendingDebits.loading = true;
    },
    setIsCancelPaymentsModalOpened: (
      state,
      action: PayloadAction<{ id: number; status: boolean }>
    ) => {
      state.isCancelPaymentsModalOpened = action.payload;
    },
    setPayments: (state, action: PayloadAction<IPaymentsPayload>) => {
      state.payments.data = action.payload.data;
      state.payments.total = action.payload.totalRecords;
      state.payments.loading = false;
    },
    setDebit: (state, action: PayloadAction<IDebitsPayload>) => {
      state.debits.data = action.payload.data;
      state.debits.total = action.payload.totalRecords;
      state.debits.loading = false;
    },
    setPendingDebits: (state, action: PayloadAction<any>) => {
      state.pendingDebits.data = action.payload;
      state.pendingDebits.loading = false;
    },
    resetDebitsList: (state) => {
      state.debits = initialState.debits;
    },
    setIsMbInfoModalOpened: (state, action: PayloadAction<boolean>) => {
      state.isMbInfoModalOpened = action.payload;
    },
    setConsultedMbPaymentInfo: (state, action: PayloadAction<any | null>) => {
      state.consultedMbPaymentInfo = action.payload;
    },
    setSelectedDebits: (state, action: PayloadAction<IDebitData[]>) => {
      state.selectedDebits = action.payload;
    },
    setPreSelectedDebits: (state, action: PayloadAction<IDebitData[]>) => {
      state.preSelectedDebits = action.payload;
    },
    fetchMbPaymentDetails: (state, action: PayloadAction<number>) => {},
    setDebitsFilterTypes: (state, action: PayloadAction<any>) => {
      state.debitsFilterTypes = action.payload.map((filter, key) => ({
        key: key,
        text: filter,
        value: filter,
      }));
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    setIsSocketActivated: (state, action: PayloadAction<boolean>) => {
      state.isSocketActivated = action.payload;
    },
    onPaymentNotification: (state, action: PayloadAction<IWEBSOCKET_PAYLOAD>) => {},
    handleWebsocketMessages: () => {},
    handleWebsocketConnection: () => {},
    deleteDebit: (state, action: PayloadAction<number>) => {},
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    resetResettableStates: (state) => {
      state.multiSelectionModal = initialState.multiSelectionModal;
      state.filterParams = initialState.filterParams;
      state.debitsFilterTypes = initialState.debitsFilterTypes;
      state.isMbInfoModalOpened = initialState.isMbInfoModalOpened;
      state.consultedMbPaymentInfo = initialState.consultedMbPaymentInfo;
      state.loadingStatus = initialState.loadingStatus;
      state.errorStatus = initialState.errorStatus;
      state.isSocketActivated = initialState.isSocketActivated;
      state.isCancelPaymentsModalOpened = initialState.isCancelPaymentsModalOpened;
    },
    reset: (state) => initialState,
  },
});
