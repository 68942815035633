/**
 * -----------------------------------------------------------------------------
 * Copyright (C) Ubiwhere, LDA
 * 2012-2018
 *
 *
 * The reproduction, transmission or use of this document or its contents is not
 * permitted without express written authorization. All rights, including rights
 * created by patent grant or registration of a utility model or design, are
 * reserved. Modifications made to this document are restricted to authorized
 * personnel only. Technical specifications and features are binding only when
 * specifically and expressly agreed upon in a written contract.
 * -----------------------------------------------------------------------------
 *
 * Router
 * Add new routes here
 */
import React from 'react';
// Import page components bellow here
// Keep them organized as the routes object

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import Home from 'scenes/Home';
import Help from 'scenes/Help';
import NewApplication from 'scenes/VirtualSecretary/Applications/NewApplication';
import RegistrationDetails from 'scenes/VirtualSecretary/RegistrationDetails';
import Registrations from 'scenes/VirtualSecretary/Registrations';
import SeriationHistory from 'scenes/Schedules/SeriationHistory';
import SeriationHistoryDetails from 'scenes/Schedules/SeriationHistoryDetails';
//import PersonalDataCompliance from 'scenes/PersonalDataCompliance';
import PageNotFound from 'scenes/PageNotFound';
import PageNoAccess from 'scenes/PageNoAccess';
import PageServerError from 'scenes/PageServerError';
import InMaintenance from 'scenes/InMaintenance';
import ApplicationsList from 'scenes/VirtualSecretary/Applications/ApplicationsList';
import ApplicationDetails from 'scenes/VirtualSecretary/Applications/ApplicationDetails';
import Payments from 'scenes/VirtualSecretary/Payments';
import PendingDebitsPayment from 'scenes/VirtualSecretary/Payments/PendingDebitsPayment';
import Grades from 'scenes/VirtualSecretary/Grades';
import AssessmentChoice from 'scenes/VirtualSecretary/AssessmentChoice';
import CurricularPlan from 'scenes/VirtualSecretary/CurricularPlan/CurricularPlan';
import CurricularUnitDetails from 'scenes/VirtualSecretary/CurricularPlan/CurricularUnitDetails';
import PersonalData from 'scenes/VirtualSecretary/PersonalData';
import CreatedSchedules from 'scenes/Schedules/CreatedSchedules';
import MySchedule from 'scenes/Schedules/MySchedule';
import FreeOptionsList from 'scenes/Schedules/FreeOptionsList';
import SchedulesHome from 'scenes/Schedules/SchedulesHome';
import SchedulesGuide from 'scenes/Schedules/SchedulesGuide';
import AllSchedules from 'scenes/Schedules/AllSchedules';
import NewCertificate from 'scenes/VirtualSecretary/Certificates/NewCertificate';
import CertificatesList from 'scenes/VirtualSecretary/Certificates/CertificatesList';
import CertificatesDetails from 'scenes/VirtualSecretary/Certificates/CertificatesDetails';
import CertificateValidator from 'scenes/VirtualSecretary/CertificateValidator';
import ExamsCalendar from 'scenes/VirtualSecretary/ExamsCalendar';
import EnrollmentRenewal from 'scenes/VirtualSecretary/Enrollments/EnrollmentRenewal';
import ExamEnrollment from 'scenes/VirtualSecretary/Enrollments/ExamEnrollment';
import ImprovementExamEnrollment from 'scenes/VirtualSecretary/Enrollments/ImprovementExamEnrollment';
import EvaluationTypeSelection from 'scenes/VirtualSecretary/Enrollments/EvaluationTypeSelection';

import { IRole, IGroup, IService, IHealthChecks } from 'config';
import GradesManagement from 'scenes/GradesManagement/GradesManagement';
import EditGrades from 'scenes/GradesManagement/EditGrades';
import GradesHistory from 'scenes/GradesManagement/GradesHistory';
import GradesDetails from 'scenes/GradesManagement/GradesDetails';
import Exams from 'scenes/VirtualSecretary/Enrollments/Exams';
import TransferalCompetence from 'scenes/VirtualSecretary/Enrollments/TransferalCompetence';
import ClassSupoort from 'scenes/VirtualSecretary/ClassSupoort';
import GradesGuide from 'scenes/GradesManagement/GradesGuide';
import SpecialExams from 'scenes/VirtualSecretary/Enrollments/SpecialExamsEnroll';
import Logs from 'scenes/Logs';
import LogsStudent from 'scenes/LogsStudent';

export interface IRoutes {
  exact: boolean;
  key: string;
  name: string;
  tags?: string[];
  description?: { pt: string; en: string };
  childOf?: string;
  module?: { name: string; service?: IService | String };
  path?: { pt: string; en: string };
  link?: string;
  hideFromSidebar?: boolean;
  inMaintenance?: boolean;
  detached?: boolean;
  disabled?: boolean;
  roles?: IRole[];
  groups?: IGroup[];
  component?: any;
  serviceHealthcheck?: IHealthChecks;
}

// Define routes here
// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
const Routes: IRoutes[] = [
  {
    exact: true,
    key: 'inMaintenance',
    name: 'inMaintenance',
    path: { pt: '/manutencao', en: '/maintenance' },
    hideFromSidebar: true,
    detached: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: InMaintenance,
  },
  {
    exact: true,
    key: 'home',
    name: 'initialPage',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/', en: '/' },
    inMaintenance: false,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: Home,
    module: { name: 'paco', service: '' },
  },
  {
    exact: true,
    key: 'schedulesselection',
    name: 'schedulesSelection',
    path: { pt: '/horarios/escolha', en: '/schedules/selection' },
    roles: [
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: SchedulesHome,
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'createdSchedules',
    name: 'createdSchedules',
    childOf: 'schedulesselection',
    inMaintenance: false,
    hideFromSidebar: true,
    path: { pt: '/horarios/criados', en: '/schedules/created' },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: CreatedSchedules,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'seriationhistory',
    name: 'seriationHistory',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: { pt: '/horarios/historico', en: '/schedules/history' },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: SeriationHistory,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'provisoryseriationhistory',
    name: 'provisorySeriationHistory',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: {
      pt: '/horarios/historico_provisorio/:id',
      en: '/schedules/provisory_history/:id',
    },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: SeriationHistory,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'provisoryscheduleseriationhistory',
    name: 'provisoryScheduleSeriationHistory',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: {
      pt: '/horarios/horario_historico_provisorio/:id',
      en: '/schedules/schedule_provisory_history/:id',
    },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: SeriationHistory,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'provisoryseriationhistorydetails',
    name: 'provisorySeriationHistoryDetails',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: {
      pt: '/horarios/historico_provisorio/detalhes/:id/:seriationId',
      en: '/schedules/provisory_history/details/:id/:seriationId',
    },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: SeriationHistoryDetails,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'seriationhistorydetails',
    name: 'seriationHistoryDetails',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: {
      pt: '/horarios/historico/detalhes/:id/:seriationId',
      en: '/schedules/history/details/:id/:seriationId',
    },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: SeriationHistoryDetails,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'mySchedule',
    name: 'mySchedule',
    inMaintenance: false,
    //link: 'https://paco.ua.pt/secvirtual/horarios/c_horario_aluno.asp',
    path: { pt: '/horarios/horario', en: '/schedules/schedule' },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: MySchedule,
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'createSchedule',
    name: 'createSchedule',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    inMaintenance: false,
    path: { pt: '/horarios/criar', en: '/schedules/create' },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: MySchedule,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'freeOptionsList',
    name: 'freeOptionsList',
    childOf: '',
    hideFromSidebar: false,
    inMaintenance: false,
    path: { pt: '/horarios/lista-opcoes-livres', en: '/schedules/free-options-list' },
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: FreeOptionsList,
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'editSchedule',
    name: 'editSchedule',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: { pt: '/horarios/:id/editar', en: '/schedules/:id/edit/' },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: MySchedule,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'duplicateSchedule',
    name: 'duplicateSchedule',
    childOf: 'createdSchedules',
    hideFromSidebar: true,
    path: { pt: '/horarios/:id/duplicar', en: '/schedules/:id/duplicate' },
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: MySchedule,
    module: { name: '', service: 'sgh' },
  },
  {
    exact: true,
    key: 'allschedules',
    name: 'allSchedules',
    path: { pt: '/horarios/todos-os-horarios', en: '/schedules/all-schedules' },
    component: AllSchedules,
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'help',
    name: 'help',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/ajuda', en: '/help' },
    inMaintenance: false,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: Help,
    module: { name: 'paco', service: '' },
  },
  {
    exact: true,
    key: 'schedulesGuide',
    name: 'guide',
    hideFromSidebar: false,
    path: { pt: '/horarios/ajuda', en: '/schedules/help' },
    groups: ['frontoffice'],
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: (props) => <SchedulesGuide id={'horarios'} {...props} />,
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'schedulesGuideFreeOptions',
    name: 'guide',
    hideFromSidebar: true,
    path: { pt: '/horarios/ajuda/opcoes_livres', en: '/schedules/free_options' },
    groups: ['frontoffice'],
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: (props) => <SchedulesGuide id={'freeOptionsHelp'} {...props} />,
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'schedulesGuideSpecific',
    name: 'guide',
    hideFromSidebar: true,
    path: { pt: '/horarios/ajuda/simulacao', en: '/schedules/help/simulation' },
    groups: ['frontoffice'],
    roles: [
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_FullAccess',
    ],
    component: (props) => <SchedulesGuide id={'simulationHelp'} {...props} />,
    module: { name: 'schedules', service: 'sgh' },
  },
  {
    exact: true,
    key: 'personaldata',
    name: 'personalData',
    path: { pt: '/dados-pessoais', en: '/personal-data' },
    disabled: false,
    hideFromSidebar: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: PersonalData,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'registrations',
    name: 'registrations',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/matriculas', en: '/registrations' },
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: Registrations,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'registrationdetails',
    name: 'registrationDetails',
    hideFromSidebar: true,
    disabled: false,
    childOf: 'registrations',
    path: { pt: '/matriculas/detalhes/:id', en: '/matriculas/detalhes/:id' },
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: RegistrationDetails,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'curricularPlan',
    name: 'curricularPlan',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/plano-curricular', en: '/curricular-plan' },
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: CurricularPlan,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'ucDetails',
    name: 'ucDetails',
    childOf: 'curricularPlan',
    inMaintenance: false,
    hideFromSidebar: true,
    path: {
      pt: '/plano-curricular/:ucCode/notas/:regid',
      en: '/curricular-plan/:ucCode/grades/:regid',
    },
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: CurricularUnitDetails,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'exams',
    name: 'exams',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/exames', en: '/exams' },
    component: Exams,
    inMaintenance: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'enrollmentRenewal',
    name: 'enrollmentRenewal',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    //path: { pt: '/renovacao-inscricao', en: '/enrollment-renewal' },
    link: 'https://paco.ua.pt/Inscricoes',
    component: EnrollmentRenewal,
    inMaintenance: true,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'examEnrollment',
    name: 'examEnrollment',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/inscricao-exames', en: '/exam-enrollment' },
    component: ExamEnrollment,
    inMaintenance: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'improvementExamEnrollment',
    name: 'improvementExamEnrollment',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/inscricao-melhorias', en: '/improvement-exam-enrollment' },
    component: ImprovementExamEnrollment,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'specialExams',
    name: 'specialExams',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/inscricoes-especial', en: '/special-exams' },
    component: SpecialExams,
    inMaintenance: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'evaluationTypeSelection',
    name: 'evaluationTypeSelection',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/selecao-avaliacao', en: '/evaluation-selection' },
    component: EvaluationTypeSelection,
    inMaintenance: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'transferalCompetence',
    name: 'transferalCompetence',
    childOf: 'enrollments',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/competencias-transferiveis', en: '/transferal-competence' },
    component: TransferalCompetence,
    inMaintenance: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'examsCalendar',
    name: 'examsCalendar',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/calendario-exames', en: '/exams-calendar' },
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: ExamsCalendar,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'assessmentchoice',
    name: 'assessmentChoice',
    childOf: 'subscriptions',
    path: { pt: '/escolha-avaliacao', en: '/assessment-choice' },
    disabled: true,
    hideFromSidebar: true,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: AssessmentChoice,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'classsupport',
    name: 'classSupport',
    hideFromSidebar: false,
    path: { pt: '/apoio-aulas', en: '/class-support' },
    // link: 'https://paco.ua.pt/aulas/Default.asp',
    disabled: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: ClassSupoort,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'grades',
    name: 'grades',
    path: { pt: '/notas', en: '/grades' },
    disabled: true,
    hideFromSidebar: true,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: Grades,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'payments',
    name: 'payments',
    path: { pt: '/pagamentos', en: '/payments' },
    disabled: false,
    hideFromSidebar: false,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: Payments,
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'pendingDebitsPayment',
    name: 'pendingDebitsPayment',
    childOf: 'payments',
    inMaintenance: false,
    hideFromSidebar: true,
    path: { pt: '/pagamentos/pagamento-debitos', en: '/payments/debit-payment' },
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: PendingDebitsPayment,
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'newapplication',
    name: 'newApplication',
    childOf: 'applications',
    path: { pt: '/novo-requerimento', en: '/new-application' },
    disabled: false,
    hideFromSidebar: false,
    component: NewApplication,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'applicationslist',
    name: 'applicationsList',
    childOf: 'applications',
    path: { pt: '/lista-requerimentos', en: '/requirement-list' },
    disabled: false,
    hideFromSidebar: false,
    component: ApplicationsList,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'applicationdetails',
    name: 'applicationDetails',
    childOf: 'applicationslist',
    hideFromSidebar: true,
    disabled: false,
    path: {
      pt: '/lista-requerimentos/:registrationId/:id',
      en: '/requirement-list/:registrationId/:id',
    },
    component: ApplicationDetails,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'newcertificate',
    name: 'newCertificate',
    childOf: 'certificates',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/nova-certidao', en: '/new-certificate' },
    component: NewCertificate,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'certificateslist',
    name: 'certificatesList',
    childOf: 'certificates',
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/lista-certidoes', en: '/certificate-list' },
    component: CertificatesList,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'certificatesdetails',
    name: 'certificatesDetails',
    childOf: 'certificateslist',
    disabled: false,
    path: {
      pt: '/lista-certidoes/:certificateId/:registrationId',
      en: '/certificate-list/:certificateId/:registrationId',
    },
    component: CertificatesDetails,
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    hideFromSidebar: true,
    groups: ['frontoffice'],
    module: { name: 'secVirtual', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'pageNotFound',
    name: 'pageNotFound',
    path: { pt: '/pagina-nao-encontrada', en: '/page-not-found' },
    hideFromSidebar: true,
    detached: false,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: PageNotFound,
  },
  {
    exact: true,
    key: 'pageServerError',
    name: 'pageServerError',
    path: { pt: '/erro-servidor', en: '/server-error' },
    hideFromSidebar: true,
    detached: false,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    component: PageServerError,
  },
  {
    exact: true,
    key: 'accessDenied',
    name: 'accessDenied',
    path: { pt: '/acesso-negado', en: '/access-denied' },
    hideFromSidebar: true,
    detached: false,
    groups: ['frontoffice'],
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    component: PageNoAccess,
  },
  {
    exact: true,
    key: 'certificateValidator',
    name: 'certificateValidator',
    childOf: '',
    disabled: false,
    inMaintenance: false,
    path: {
      pt: '/consulta-certidao',
      en: '/certificate-consultation',
    },
    component: CertificateValidator,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    hideFromSidebar: false,
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'certificateValidatorWithAccessKey',
    name: 'certificateValidatorWithAccessKey',
    childOf: '',
    disabled: false,
    inMaintenance: false,
    path: {
      pt: '/consulta-certidao/:accessKey',
      en: '/certificate-consultation/:accessKey',
    },
    component: CertificateValidator,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    hideFromSidebar: true,
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'credits',
    name: 'credits',
    hideFromSidebar: false,
    link: 'https://paco.ua.pt/Creditacoes/Geral/Default.aspx',
    disabled: false,
    childOf: 'secVirtual',
    roles: [
      'student',
      'exStudent',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
  },
  {
    exact: true,
    key: 'backofficeSgh',
    name: 'backofficeSgh',
    hideFromSidebar: false,
    link: 'https://api.paco20-play-aws.ua.pt/sgh_backoffice/admin/login/',
    disabled: false,
    roles: [
      'nonProfessor',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'backoffice', service: '' },
  },
  {
    exact: true,
    key: 'onlineEnrollments',
    name: 'onlineEnrollments',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/Matriculas/Geral/Default.aspx',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'postGraduationApplication',
    name: 'postGraduationApplication',
    hideFromSidebar: true,
    childOf: 'candidature',
    link: 'https://paco.ua.pt/Candidaturas',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'm23Applications',
    name: 'm23Applications',
    hideFromSidebar: true,
    childOf: 'candidature',
    link: 'https://paco.ua.pt/M23/Geral/Default.aspx',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'm23ApplicationsTESP',
    name: 'm23ApplicationsTESP',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/M23TESP/Geral/Default.aspx',
    disabled: true,
    childOf: 'candidature',
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'specialApplications',
    name: 'specialApplications',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/candidaturas',
    disabled: true,
    childOf: 'candidature',
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'TESPApplications',
    name: 'TESPApplications',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/candidaturas',
    disabled: true,
    childOf: 'candidature',
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'isolatedCurricularUnits',
    name: 'isolatedCurricularUnits',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/Candidaturas',
    disabled: true,
    childOf: 'candidature',
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'freeCoursesApplications',
    name: 'freeCoursesApplications',
    hideFromSidebar: true,
    link: 'https://www.ua.pt/pt/dlc/cursos-livres-e-ple',
    disabled: true,
    childOf: 'candidature',
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'internationalStudentsApplications',
    name: 'internationalStudentsApplications',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/candidaturas',
    disabled: true,
    childOf: 'candidature',
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'pedagogicalDossier',
    name: 'pedagogicalDossier',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/DPUC/secure/Default.aspx',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'teachers', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'courseDirectorOnlineCreditations',
    name: 'courseDirectorOnlineCreditations',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/Creditacoes/Geral/Default.aspx',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'teachers', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'organicDirectorsIsolatedUCs',
    name: 'organicDirectorsIsolatedUCs',
    hideFromSidebar: true,
    link: 'https://paco.ua.pt/candidaturas',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: 'teachers', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'academicServices',
    name: 'academicServices',
    hideFromSidebar: true,
    link: 'https://www.ua.pt/pt/sga/',
    disabled: true,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'aveiroUniversity',
    name: 'aveiroUniversity',
    hideFromSidebar: false,
    link: 'https://www.ua.pt/',
    disabled: false,
    roles: [
      'guest',
      'nonProfessor',
      'other',
      'protocol',
      'researcher',
      'scholarship',
      'exStudent',
      'student',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sgh_superUser_ReadOnly',
      'sgh_superUser_FullAccess',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'secVirtual' },
  },
  {
    exact: true,
    key: 'gradesManagement',
    name: 'gradesManagement',
    childOf: 'pautas',
    path: { pt: '/pautas/', en: '/grades/' },
    roles: [
      'professor',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'pautas_superUser_ReadOnly',
      'pautas_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'pautas' },
    component: GradesManagement,
  },
  {
    exact: true,
    key: 'editGrades',
    name: 'editGrades',
    childOf: 'gradesManagement',
    hideFromSidebar: true,
    disabled: false,
    path: { pt: '/pautas/:codigoPauta/editar', en: '/grades/:codigoPauta/edit' },
    roles: [
      'professor',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'pautas_superUser_ReadOnly',
      'pautas_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'pautas' },
    component: EditGrades,
  },
  {
    exact: true,
    key: 'gradeDetails',
    name: 'gradeDetails',
    childOf: 'gradesManagement',
    hideFromSidebar: true,
    disabled: false,
    path: { pt: '/pautas/:codigoPauta/detalhes/', en: '/grades/:codigoPauta/details/' },
    roles: [
      'professor',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'pautas_superUser_ReadOnly',
      'pautas_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'pautas' },
    component: GradesDetails,
  },
  {
    exact: true,
    key: 'gradeHistory',
    name: 'gradeHistory',
    childOf: 'gradesManagement',
    hideFromSidebar: true,
    disabled: false,
    path: { pt: '/pautas/:codigoPauta/historico', en: '/grades/:codigoPauta/history' },
    roles: [
      'professor',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'pautas_superUser_ReadOnly',
      'pautas_superUser_FullAccess',
    ],
    groups: ['frontoffice'],
    module: { name: '', service: 'pautas' },
    component: GradesHistory,
  },
  {
    exact: true,
    key: 'pautasHelp',
    name: 'pautasHelp',
    childOf: 'pautas',
    hideFromSidebar: false,
    path: { pt: '/pautas-ajuda/', en: '/grades-help/' },
    groups: ['frontoffice'],
    roles: [
      'professor',
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'pautas_superUser_ReadOnly',
      'pautas_superUser_FullAccess',
    ],
    component: (props) => <GradesGuide id={'pautas'} {...props} />,
    module: { name: '', service: 'pautas' },
  },
  {
    exact: true,
    key: 'logsGeneral',
    name: 'logsGeneral',
    detached: false,
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/accessos-geral', en: '/logs-general' },
    groups: ['frontoffice'],
    roles: [
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'sec_superUser_ReadOnly',
      'sec_superUser_FullAccess',
    ],
    module: { name: 'logs', service: '' },
    component: Logs,
  },
  {
    exact: true,
    key: 'logsStudent',
    name: 'logsStudent',
    detached: false,
    disabled: false,
    hideFromSidebar: false,
    path: { pt: '/accessos-estudante', en: '/logs-student' },
    groups: ['frontoffice'],
    roles: [
      'superUser_FullAccess',
      'superUser_ReadOnly',
      'exStudent',
      'student',
    ],
    module: { name: 'logs', service: '' },
    component: LogsStudent,
  },
];

export default Routes;
