/**
 * CertificatesDetails scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import moment from 'moment';
import { t } from 'app';
import { ITableStructure, FlagLabel } from '@paco_ua/pacoui';
import { ICertificateAccessHistory } from './logic/slice';
import React from 'react';

export const getCertificateState = (certificatesDetails) => {
  if (certificatesDetails.state.state === 'rejected') {
    return 'rejected';
  }

  if (!certificatesDetails.history.awaitingPayment) {
    return 'pendingPayment';
  }
  if (!certificatesDetails.history.pending) {
    return 'sending';
  }

  return 'processing';
};

export const getDeliveryMethod = (certificatesDetails) => {
  if (
    !certificatesDetails.state.method === null &&
    certificatesDetails.state.method !== undefined
  ) {
    return certificatesDetails.state.format;
  }

  if (!certificatesDetails.email === null && certificatesDetails.email !== undefined) {
    return certificatesDetails.email;
  }

  return null;
};

export const getCertificatePaymentDate = (certificatesDetails) => {
  if (certificatesDetails.history.awaitingPayment) {
    return moment(certificatesDetails.history.awaitingPayment).format('DD-MM-YYYY');
  }
};

export const isPaid = (certificatesDetails) => {
  if (certificatesDetails.history.awaitingPayment) {
    return true;
  } else {
    return false;
  }
};

export const getRegistration = (id, registrations) => {
  return registrations.find((reg) => reg.registrationId === id);
};

export const getAccessHistoryModalStructure = (
  accessHistory: ICertificateAccessHistory[]
): ITableStructure => {
  return {
    header: {
      type: 'columnTitle' as string,
      titles: [
        {
          text: t('certificates.accessCountry'),
        },
        {
          text: t('certificates.accessDate'),
        },
        {
          text: t('certificates.accessTime'),
        },
      ],
    },
    rows: accessHistory.map((el) => {
      return {
        cells: [
          {
            content: <FlagLabel countryCode={el.countryIso2} countryName={el.country} />,
            cellProps: '',
          },
          {
            content: moment(el.accessDate).format('DD-MM-YYYY'),
            cellProps: '',
          },
          {
            content: el.accessTime,
            cellProps: '',
          },
        ],
      };
    }),
  };
};
