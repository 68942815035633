/**
 * ApplicationsList scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { takeLatest, put, putResolve, select, call } from 'redux-saga/effects';

import { actions } from 'store/rootSlices';
import ErrorHandler from 'shared/errorHandler';
import { t } from 'app';
import * as selectors from './selectors';

import API from 'api';

function* onMountSaga() {
  try {
    yield put(actions.ApplicationsList.reset());

    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    yield put(
      actions.ApplicationsList.setErrorStatus({ fieldName: 'errLoading', fieldValue: true })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorLoadingRegistrations'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.ApplicationsList.setReset());
}

function* getStudentApplicationsSaga(params?: any) {
  try {
    yield put(
      actions.ApplicationsList.setLoadingStatus({ fieldName: 'loadingList', fieldValue: true })
    );
    yield put(
      actions.ApplicationsList.setErrorStatus({ fieldName: 'errLoadingList', fieldValue: false })
    );

    const registrationId = yield select(selectors.getRegistrationId);

    const response = yield call(API.secVirtual.getStudentApplications.call, {
      registrationId,
      ...params?.payload,
    });

    const studentApplications = response.data.map((application) => {
      return {
        id: application.id,
        category: application.theme,
        description: application.type,
        requestDate: application.requestDate,
        responseDate: application.responseDate,
        status: application.state,
        reference : application.ref
      };
    });

    yield put(actions.ApplicationsList.setStudentApplications(studentApplications));

    yield put(actions.ApplicationsList.setApplicationFilters(response.filters));

    yield put(
      actions.ApplicationsList.setLoadingStatus({ fieldName: 'loading', fieldValue: false })
    );

    yield put(
      actions.ApplicationsList.setLoadingStatus({ fieldName: 'loadingList', fieldValue: false })
    );
  } catch (e) {
    yield put(
      actions.ApplicationsList.setErrorStatus({ fieldName: 'errLoadingList', fieldValue: true })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.applicationsList_errorGetList'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

export default function* watcherSignin() {
  yield takeLatest('ApplicationsList/onMount', onMountSaga);
  yield takeLatest('ApplicationsList/onUnmount', onUnmountSaga);
  yield takeLatest('ApplicationsList/getStudentApplications', getStudentApplicationsSaga);
}
