/**
 * Form component
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { getFormElements, getForm, getHiddenFields, getFormElementsData } from './utils';
import { history } from 'utils';

import _ from 'lodash';

interface IProps {
  formSchema: any; // values list
  children: any;
}

const FormManager: React.FC<IProps> = ({ formSchema, children }) => {
  const [dynamicFormSchema, setDynamicFormSchema] = useState(formSchema as any);

  const form = useForm({
    resolver: getForm(dynamicFormSchema),
    shouldUnregister: history.location.pathname === '/dados-pessoais',
  });

  // contains a list with all the fields that the elements in the form depend on
  const watchForm = useMemo(() => form.watch(), [form]);

  const verifyHiddenFields = (schema, hiddenFields, updateSchema) => {
    let newSchema = [] as any;

    let newHiddenFieldsMatrix = schema.reduce((acc, schemaElement) => {
      if (schemaElement?.hidden === true) {
        acc.push(schemaElement.nameKey);
      }

      if (schemaElement.type === 'group' || schemaElement.type === 'editableGroupComponent') {
        if (schemaElement?.values) {
          schemaElement.values.forEach((groupChildElement) => {
            if (groupChildElement?.hidden) {
              acc.push(groupChildElement.nameKey);
            }
          });
        }
      }

      return acc;
    }, []);

    let newHiddenFields = !_.isEqual(hiddenFields, newHiddenFieldsMatrix);

    if (!newHiddenFields && !updateSchema) {
      return;
    }

    schema.forEach((schemaElement, elementIndex) => {
      let isHidden = hiddenFields.some((hiddenFieldNameKey) => {
        return hiddenFieldNameKey === schemaElement.nameKey;
      });

      // formSchema[elementIndex] may not correspond to schemaElement after the addition of new form elements to the schema
      let formSchemaElem = formSchema.find((element) => element.nameKey === schemaElement.nameKey);
      let newSchemaElement = {
        ...schemaElement,
        validations: isHidden ? ([] as any) : (formSchemaElem?.validations as any) || ([] as any),
        hidden: isHidden,
      };

      if (schemaElement.type === 'group' || schemaElement.type === 'editableGroupComponent') {
        newSchemaElement.values = schemaElement.values.map((groupChild, childKey) => {
          let isHiddenChild = hiddenFields.some((hiddenFieldNameKey) => {
            return hiddenFieldNameKey === groupChild.nameKey;
          });

          return {
            ...groupChild,
            validations: isHiddenChild
              ? ([] as any)
              : (formSchemaElem?.values[childKey]?.validations as any) || ([] as any),
            hidden: isHiddenChild,
          };
        });
      }

      newSchema.push(newSchemaElement);
    });

    if (newHiddenFields || updateSchema) {
      setDynamicFormSchema(newSchema);
    }
  };

  useEffect(() => {
    if (dynamicFormSchema) {
      const hiddenFields = getHiddenFields(watchForm, dynamicFormSchema);
      verifyHiddenFields(dynamicFormSchema, hiddenFields, false);
    }
  }, [watchForm]);

  useEffect(() => {
    const hiddenFields = getHiddenFields(watchForm, formSchema);
    verifyHiddenFields(formSchema, hiddenFields, true);
  }, [formSchema]);

  const formElements = useMemo(() => getFormElements(dynamicFormSchema, form), [dynamicFormSchema]);

  const formElementsData = useMemo(() => {
    const hiddenFields = getHiddenFields(watchForm, dynamicFormSchema);
    return getFormElementsData(dynamicFormSchema, form, hiddenFields);
  }, [dynamicFormSchema, form]);

  return <FormProvider {...form}>{children(form, formElements, formElementsData)}</FormProvider>;
};

export default FormManager;
