/**
 * Payments scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { takeLatest, put, putResolve, call, take, race, select } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';
import { t } from 'app';

import { IWEBSOCKET_PAYLOAD, EWEBSOCKET_RESULT } from 'scenes/VirtualSecretary/Payments/types';

import ErrorHandler from 'shared/errorHandler';

import API from 'api';

import { DEFAULT_PARAMS, DEFAULT_DEBITS_PARAMS } from '../utils';
import { ICallParams } from '../types';

import { PAYMENT_ERROR_MESSAGE } from 'shared/errorMessages';
import configs from 'config';

function* onMountSaga(action: PayloadAction<number | undefined>) {
  const registrationId = action.payload;
  try {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: true,
      })
    );

    if (registrationId) {
      yield putResolve(actions.StudentRegistrationDropdown.setRegistration(registrationId));
    } else {
      yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* fetchDebitsSaga(action: PayloadAction<ICallParams>) {
  try {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loadingDebitsTable',
        fieldValue: true,
      })
    );
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingDebitsTable',
        fieldValue: false,
      })
    );

    const registrationId = yield select(selectors.getRegistrationId);

    if (registrationId !== null && registrationId !== undefined) {
      const paymentsSlice = yield select(selectors.getPaymentsSlice);

      const debitsData = yield call(API.secVirtual.getStudentsUnpaidDebits.call, {
        ...action.payload,
        registrationId,
        ...paymentsSlice.filterParams,
      });

      yield put(actions.Payments.setDebit(debitsData));

      yield put(actions.Payments.setDebitsFilterTypes(debitsData.filters));
    }
  } catch (e) {
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingDebitsTable',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loadingDebitsTable',
        fieldValue: false,
      })
    );
  }
}

function* fetchPendingDebitsSaga() {
  try {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loadingPendingDebitsTable',
        fieldValue: true,
      })
    );
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingPendingDebitsTable',
        fieldValue: false,
      })
    );

    const paymentsSlice = yield select(selectors.getPaymentsSlice);
    const registrationId = yield select(selectors.getRegistrationId);

    if (!registrationId) {
      return;
    }

    const pendingData = yield call(API.secVirtual.getStudentsPendingDebits.call, registrationId);

    yield put(actions.Payments.setPendingDebits(pendingData));

    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loadingPendingDebitsTable',
        fieldValue: false,
      })
    );

    if (!paymentsSlice.isSocketActivated) {
      if (pendingData.length > 0) {
        yield* handleWebsocketConnectionSaga();
      }
    }
  } catch (e) {
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingPendingDebitsTable',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorLoadingPendingDebitsTable'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* fetchPaymentsSaga(action: PayloadAction<ICallParams>) {
  try {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loadingPaymentsTable',
        fieldValue: true,
      })
    );
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingPaymentsTable',
        fieldValue: false,
      })
    );

    const registrationId = yield select(selectors.getRegistrationId);

    if (!registrationId) {
      return;
    }

    const paymentsData = yield call(API.secVirtual.getStudentsPayments.call, {
      ...action.payload,
      registrationId,
    });
    yield put(actions.Payments.setPayments(paymentsData));
  } catch (e) {
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingPaymentsTable',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorLoadingPaymentsTable'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loadingPaymentsTable',
        fieldValue: false,
      })
    );
  }
}

function* fetchMbPaymentDetailsSaga(action: PayloadAction<number>) {
  try {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'mbInfoModalLoading',
        fieldValue: true,
      })
    );
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingMbInfo',
        fieldValue: false,
      })
    );

    yield put(actions.Payments.setIsMbInfoModalOpened(true));

    const registrationId = yield select(selectors.getRegistrationId);

    if (!registrationId) {
      return;
    }

    const mbPaymentDetails = yield call(API.secVirtual.getPaymentMbInfo.call, {
      paymentRequestId: action.payload,
      registrationId: registrationId,
    });

    yield put(actions.Payments.setConsultedMbPaymentInfo(mbPaymentDetails));
  } catch (e) {
    yield put(
      actions.Payments.setErrorStatus({
        fieldName: 'errorLoadingMbInfo',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (e.response.data.error_code === PAYMENT_ERROR_MESSAGE.ERR_STUDENT_INVALID_PAYMENT) {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newPaymentRequest_errorInvalidPayment'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.payments_errorLoadingPage'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  } finally {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'mbInfoModalLoading',
        fieldValue: false,
      })
    );
  }
}

function* fetchDataSaga(action: PayloadAction<number>) {
  try {
    yield put(actions.Payments.fetchPendingDebits());
    yield put(actions.Payments.fetchPayments(DEFAULT_PARAMS));
    yield put(actions.Payments.fetchDebits(DEFAULT_DEBITS_PARAMS));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorDeletingDebit'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.Payments.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }
}

function* deleteDebitSaga(action: PayloadAction<number>) {
  try {
    const registrationId = yield select(selectors.getRegistrationId);

    yield call(API.secVirtual.deletePaymentMb.call, {
      paymentRequestId: action.payload,
      registrationId: registrationId,
    });

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.payments_successDeletingDebit'),
        icon: 'check',
        type: 'success',
      })
    );

    yield put(actions.Payments.fetchPendingDebits());
    yield put(actions.Payments.fetchDebits(DEFAULT_DEBITS_PARAMS));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* handleWebsocketConnectionSaga() {
  try {
    const user = yield select(selectors.getUser);

    // initiate websocket communication
    const websocket = new WebSocket(
      `${configs.WEB_SOCKET_URL}?idToken=${user.idToken}${
        user.impersonate ? `&impersonate=${user.impersonate}` : ''
      }`
    );

    // Create channel
    const eventChannel = yield call(getWsChannel, websocket);

    const { cancel } = yield race({
      task: call(watchMessagesSaga, eventChannel),
      cancel: take('DEACTIVATE_WEBSOCKET'),
    });

    if (cancel) {
      console.log('Websocket closed');
      eventChannel.close();
    }
  } catch (e) {
    console.error('Websocket disconnected unexpectedly...');
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_errorWebsoket'),
          icon: 'info',
          type: 'warning',
        })
      );
    }
  }
}

function getWsChannel(websocket) {
  return eventChannel((emitter) => {
    const escape_char = '';

    websocket.onopen = function () {
      console.log('Websocket Connected.');
      websocket.send('{"protocol":"json","version":1}' + escape_char);
    };

    websocket.onmessage = (event) => {
      if (!event.data) {
        return;
      }

      try {
        let msgList = event.data.split(escape_char).slice(0, -1);

        let addressableMsgList = [] as any;

        msgList.forEach((websocketMsg) => {
          //if(!websocketMsg.startsWith("{}"))
          {
            // websocketMsg
            //     .replace(/\\u0022/g, '"')
            //     .replace('"{', '{')
            //     .replace('}"', '}');

            let payloadObj = JSON.parse(websocketMsg);

            // if it's a keep-alive message
            if (payloadObj.type !== 1 || !payloadObj.arguments) {
              return;
            }

            // mockMsg
            /*msg = {
            type: 1,
            target: 'BroadCast',
            arguments: [
              {
                request_payment_id: 57623,
                result: EWEBSOCKET_RESULT.success,
                operation: EWEBSOCKET_PAYMENT_TYPE.mbway_payment,
              },
            ],
          };*/

            addressableMsgList.push(payloadObj.arguments[0]);
          }
        });

        if (!addressableMsgList.length) {
          return;
        }
        return emitter(actions.Payments.onPaymentNotification(addressableMsgList));
      } catch (e) {
        console.error(e);
      }
    };

    // unsubscribe function
    return () => {
      websocket.close();
    };
  });
}

function* watchMessagesSaga(eventChannel) {
  const paymentsSlice = yield select(selectors.getPaymentsSlice);

  if (!paymentsSlice.isSocletActivated) {
    yield put(actions.Payments.setIsSocketActivated(true));
  }

  while (true) {
    const action = yield take(eventChannel);
    yield put(action);
  }
}

function* closeWebsocketSaga() {
  yield put({ type: 'DEACTIVATE_WEBSOCKET' });
}

function* onPaymentNotificationSaga(action: PayloadAction<IWEBSOCKET_PAYLOAD[]>) {
  for (let i = 0; i < action.payload.length; i++) {
    var payload = JSON.parse(action.payload[i].toString());

    if (payload.result === EWEBSOCKET_RESULT.success) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_successPaymentRequest'),
          icon: 'info',
          type: 'success',
        })
      );

      // update the pending debits table and the history/payments table
      yield put(actions.Payments.fetchPendingDebits());
      yield put(actions.Payments.fetchPayments(DEFAULT_PARAMS));
    } else if (payload.result === EWEBSOCKET_RESULT.expired) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_expiredPaymentRequest'),
          icon: 'info',
          type: 'warning',
        })
      );

      // update pending debits table and debits table
      yield put(actions.Payments.fetchPendingDebits());
      yield put(actions.Payments.fetchDebits(DEFAULT_DEBITS_PARAMS));
    } else if (payload.result === EWEBSOCKET_RESULT.cancel) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.payments_canceledPaymentRequest'),
          icon: 'info',
          type: 'danger',
        })
      );

      // update pending debits table and debits table
      yield put(actions.Payments.fetchPendingDebits());
      yield put(actions.Payments.fetchDebits(DEFAULT_DEBITS_PARAMS));
    }
  }
}

function* onUnmountSaga() {
  yield call(closeWebsocketSaga);

  // the slice states debits, preSelectedDebits, payments, selectedDebits and pendingDebits
  // may be in use in the PendingDebitsPayment page, so they should not be reset onUnmount
  yield put(actions.Payments.resetResettableStates());
}

export default function* watcherSignin() {
  yield takeLatest('Payments/onMount', onMountSaga);
  yield takeLatest('Payments/onUnmount', onUnmountSaga);
  yield takeLatest('Payments/fetchData', fetchDataSaga);
  yield takeLatest('Payments/fetchDebits', fetchDebitsSaga);
  yield takeLatest('Payments/deleteDebit', deleteDebitSaga);
  yield takeLatest('Payments/fetchPayments', fetchPaymentsSaga);
  yield takeLatest('Payments/fetchPendingDebits', fetchPendingDebitsSaga);
  yield takeLatest('Payments/fetchMbPaymentDetails', fetchMbPaymentDetailsSaga);
  yield takeLatest('Payments/onPaymentNotification', onPaymentNotificationSaga);
  yield takeLatest('Payments/watchMessages', watchMessagesSaga);
  yield takeLatest('Payments/handleWebsocketConnection', handleWebsocketConnectionSaga);
}
