import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  &&&{
    html,body {
      font-family: ${({ theme }) => theme.type.base};
      font-size:16px;
    }

    a{
      color:inherit;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
      font-family: ${({ theme }) => theme.type.base};
      font-weight: 500;
      margin: 0;
    }

  }
`;
