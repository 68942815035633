/**
 * Google Calendar icon
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React from 'react';

interface IProps {
  width: number;
  height: number;
}

const GoogleCalendar: React.FC<IProps> = ({ width, height }) => (
  <svg viewBox="0 0 32 32" width={width} height={height}>
    <path
      style={{
        lineHeight: 'normal',
        textIndent: 0,
        textAlign: 'start',
        textDecorationLine: 'none',
        textDecorationStyle: 'solid',
        textDecorationColor: '#000',
        textTransform: 'none',
        msBlockProgression: 'tb',
        isolation: 'auto',
        mixBlendMode: 'normal',
      }}
      d="M6.967 4A1.982 1.982 0 005 5.967v2.42c-.33.182-.633.415-.877.715a2.995 2.995 0 00-.613 2.49v.002L4.826 18l-1.312 6.39a3.01 3.01 0 00.615 2.503v.002A3.006 3.006 0 006.457 28h19.045c.912 0 1.777-.411 2.353-1.117v-.002a3.04 3.04 0 00.624-2.53L27.174 18l1.316-6.412a2.992 2.992 0 00-.61-2.488l-.002-.002A2.96 2.96 0 0027 8.385V5.967A1.982 1.982 0 0025.033 4H6.967zM7 6h18v2H7V6zm-.559 4H25v.004h.58a.985.985 0 01.951 1.182l-1.357 6.613-.041.201.04.201 1.345 6.553A1.038 1.038 0 0125.502 26H6.457a1.006 1.006 0 01-.984-1.207l1.353-6.592.041-.201-.04-.201-1.358-6.608h.002a.989.989 0 01.2-.826h.003A.99.99 0 016.44 10zm6.854 3c-1.25 0-2.385.342-2.953.682l.455 1.59c.454-.228 1.25-.569 2.045-.569.909 0 1.363.456 1.363 1.024 0 .795-.91 1.136-1.705 1.136h-.908v1.59h.91c1.023 0 1.932.456 1.932 1.365 0 .682-.57 1.25-1.705 1.25-.91 0-1.819-.341-2.274-.568l-.457 1.818c.568.341 1.592.682 2.842.682 2.5 0 3.976-1.25 3.976-2.955 0-1.363-.909-2.16-2.045-2.387 1.137-.455 1.706-1.248 1.706-2.271 0-1.363-1.137-2.387-3.182-2.387zM19 13v10h2V13h-2z"
      fontWeight={400}
      fontFamily="sans-serif"
      overflow="visible"
    />
  </svg>
);

GoogleCalendar.defaultProps = {
  width: 24,
  height: 24,
};

export default GoogleCalendar;
