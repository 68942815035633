import React from 'react'
import { useDispatch } from 'react-redux';
import { Text, Table, Button } from '@paco_ua/pacoui';
import styled from 'styled-components';
import { actions } from 'store/rootSlices';
import { getStats, getPercentage } from './utils';
import ConfirmActionModal from 'shared/components/ConfirmActionModal';
import { t } from 'app';
import Modal from './Modal';

const Header = styled.div`
display: flex;
flex-direction: column;
gap: 0.2rem;
`

const TableWrapper = styled.div`
margin-top: 0.1rem;
width: 100%;
overflow-x: auto;
`

const ButtonsWrapper = styled.div`
display: flex;
justify-content: flex-end;
gap: 1rem;
margin-top: 1rem;
margin-bottom: 1rem;
`

const StatisticsModal = ({ editedGradesData, codigoPauta, gradesEdits }) => {
  const dispatch = useDispatch();
  const { totalStudents, positiveGrades, negativeGrades, rnmStudents, missingStudents, dropOutStudents, failedByMissingStudents, studentsWithoutGrades } = getStats(editedGradesData);
  const [isConfirmSubmitModalOpen, setIsConfirmSubmitModalOpen] = React.useState(false);

  const handleIsConfirmSubmitModalOpen = () => {
    setIsConfirmSubmitModalOpen(!isConfirmSubmitModalOpen);
  }

  const handleIsConfirmSubmit = () => {
    dispatch(actions.EditGrades.setStatisticsModal());
    dispatch(actions.EditGrades.saveOrSubmitGrades({ codigoPauta: codigoPauta, gradesEdits: gradesEdits, isSubmission: true }));
    //setIsConfirmSubmitModalOpen(false);
  }

  const statisticsTableStructure = {
    header: {
      titles: [
        { text: t('pautas.data'), style: { textAlign: 'left', width: '60%' } },
        { text: t('pautas.value'), style: { textAlign: 'left', width: '20%' } },
        { text: t('pautas.percentage'), style: { textAlign: 'left', width: '20%' } },
      ],
      type: 'columnTitle'
    },
    rows: [
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.totalNumberOfStudents')
          },
          {
            cellProps: '',
            content: totalStudents,
          },
          {
            cellProps: '',
            content: '-'
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.studentsWithPositiveGrades')
          },
          {
            cellProps: '',
            content: positiveGrades,
          },
          {
            cellProps: '',
            content: getPercentage(positiveGrades, totalStudents)
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.studentsWithNegativeGrades')
          },
          {
            cellProps: '',
            content: negativeGrades,
          },
          {
            cellProps: '',
            content: getPercentage(negativeGrades, totalStudents)
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.rnmStudents')
          },
          {
            cellProps: '',
            content: rnmStudents,
          },
          {
            cellProps: '',
            content: getPercentage(rnmStudents, totalStudents)
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.absentStudents')
          },
          {
            cellProps: '',
            content: missingStudents,
          },
          {
            cellProps: '',
            content: getPercentage(missingStudents, totalStudents)
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.droppedOutStudents')
          },
          {
            cellProps: '',
            content: dropOutStudents,
          },
          {
            cellProps: '',
            content: getPercentage(dropOutStudents, totalStudents)
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.failedStudentsDueToAbsenteeism')
          },
          {
            cellProps: '',
            content: failedByMissingStudents,
          },
          {
            cellProps: '',
            content: getPercentage(failedByMissingStudents, totalStudents)
          }
        ],
        collapsableRows: []
      },
      {
        cells: [
          {
            cellProps: '',
            content: t('pautas.studentsWithoutGrades')
          },
          {
            cellProps: '',
            content: studentsWithoutGrades,
          },
          {
            cellProps: '',
            content: getPercentage(studentsWithoutGrades, totalStudents)
          }
        ],
        collapsableRows: []
      }
    ],
  };

  return (
    <>
      {isConfirmSubmitModalOpen &&
        <ConfirmActionModal
          onClick={handleIsConfirmSubmitModalOpen}
          onConfirm={handleIsConfirmSubmit}
          title={t('pautas.submitGradingSheetConfirmation')}
          body={t('pautas.gradingSheetCannotBeEditedAfterSubmission')}
          confirmButtonText={t('pautas.submitGradingSheet')}
        />
      }

      {!isConfirmSubmitModalOpen &&
        <Modal changeModal={() => { dispatch(actions.EditGrades.setStatisticsModal()) }}>
          <Header>
            {studentsWithoutGrades > 0 ? (
              <>
                <Text>{t('pautas.gradingSheetHasUnsavedGrades')}</Text>
                <Text style={{ fontSize: '0.9rem' }}>{t('pautas.gradingSheetIncomplete')}</Text>
              </>
            ) : (
              <>
                <Text>{t('pautas.gradingSheetComplete')}</Text>
                <Text style={{ fontSize: '0.9rem' }}>{t('pautas.gradingSheetReadyForSubmission')}</Text>
              </>
            )}
          </Header>
          <TableWrapper>
            <Table
              structure={statisticsTableStructure}
            />
          </TableWrapper>
          <ButtonsWrapper>
            {studentsWithoutGrades > 0 ? (
              <>
                <Button danger onClick={() => { dispatch(actions.EditGrades.setStatisticsModal()) }}>{t('pautas.cancel', { textOnly: true })}</Button>
                <Button action onClick={() => {
                  dispatch(actions.EditGrades.setStatisticsModal());
                  dispatch(actions.EditGrades.saveOrSubmitGrades({ codigoPauta: codigoPauta, gradesEdits: gradesEdits, isSubmission: false }));
                }}>{t('pautas.save', { textOnly: true })}</Button>
              </>
            ) : (
              <>
                <Button action onClick={() => {
                  dispatch(actions.EditGrades.setStatisticsModal());
                  dispatch(actions.EditGrades.saveOrSubmitGrades({ codigoPauta: codigoPauta, gradesEdits: gradesEdits, isSubmission: false }));
                }}>{t('pautas.save', { textOnly: true })}</Button>
                <Button action onClick={() => {
                  handleIsConfirmSubmitModalOpen();
                }}>{t('pautas.submitGradingSheet', { textOnly: true })}</Button>
              </>
            )}
          </ButtonsWrapper>
        </Modal>
      }
    </>
  )
}

export default StatisticsModal;