/**
 * MySchedules scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { IUc } from 'shared/types';
import moment from 'moment';

export const swapSchedules = (schedules, sourceId, targetId) => {
  if (!schedules[targetId] || !schedules[sourceId]) return schedules;
  if (sourceId === targetId) return schedules;
  let newSchedules = schedules.map((schedule, key) => {
    if (key === sourceId) {
      return { ...schedule, preference: schedules[targetId].preference };
    }

    if (sourceId < targetId) {
      if (key < sourceId || key > targetId) return schedule;
      return { ...schedule, preference: schedule.preference - 1 };
    } else {
      if (key > sourceId || key < targetId) return schedule;
      return { ...schedule, preference: schedule.preference + 1 };
    }
  });

  const tempObject = newSchedules.splice(sourceId, 1)[0];
  newSchedules.splice(targetId, 0, tempObject);

  return newSchedules;
};

export const selectClassesToViewSchedule = (state: IUc[], action: PayloadAction<number[]>) => {
  return state.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        const found = action.payload.find((classId) => classId === eachClass.classId);
        if (
          found ||
          eachClass.allocated.state === 'assigned' ||
          eachClass.allocated.state === 'automatic'
        ) {
          return { ...eachClass, selected: true, filtered: false };
        } else {
          return { ...eachClass, filtered: true };
        }
      }),
    };
  });
};

export const sortSchedules = (createdSchedules, activePhase: boolean) => {
  let reformatedSchedules = createdSchedules.schedules
    .sort((a, b) => (moment(a.phaseEndDate).isBefore(moment(b.phaseEndDate)) ? 1 : -1))
    .reduce(
      (acc: any, schedule) => {
        const { phase, ...scheduleRest } = schedule;
        if (activePhase && phase === createdSchedules.currentPhase) {
          if (!acc.activePhase) {
            acc.activePhase = {
              phase: phase,
              schedules: [scheduleRest],
            };
          } else {
            acc.activePhase.schedules.push(scheduleRest);
          }
        } else {
          if (phase !== acc.finishedPhases[acc.finishedPhases.length - 1]?.phase) {
            acc.finishedPhases.push({
              phase: phase,
              schedules: [scheduleRest],
            });
          } else {
            acc.finishedPhases[acc.finishedPhases.length - 1].schedules.push(scheduleRest);
          }
        }

        return acc;
      },
      { activePhase: null, finishedPhases: [] }
    );

  if (reformatedSchedules.activePhase) {
    reformatedSchedules.activePhase.schedules = reformatedSchedules.activePhase.schedules.sort(
      (a, b) => a.preference - b.preference
    );
  }

  if (!!reformatedSchedules.finishedPhases.length) {
    reformatedSchedules.finishedPhases = reformatedSchedules.finishedPhases.map(
      (finishedPhase) => ({
        ...finishedPhase,
        schedules: finishedPhase.schedules.sort((a, b) => a.preference - b.preference),
      })
    );
  }

  return reformatedSchedules;
};

export const getStatusName = (state) => {
  switch (state) {
    case 'assigned':
      return 'assigned';
    case 'notAssigned':
      return 'notAssigned';
    case 'automatic':
      return 'automatic';
    case 'proposal':
      return 'proposal';
  }
};
