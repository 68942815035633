import { useState, useEffect, Dispatch, SetStateAction } from "react";

interface Props {
  pautasLista: any[];
  selectedState: string;
}

export interface State {
  data: any;
  setData: Dispatch<SetStateAction<any>>;
  originalData: any;
  setOriginalData: Dispatch<SetStateAction<any>>;
  selectedPautaIds: number[];
  setSelectedPautaIds: Dispatch<SetStateAction<number[]>>;
  showError: boolean;
  setShowError: Dispatch<SetStateAction<boolean>>;
  isPdfReady: boolean;
  setIsPdfReady: Dispatch<SetStateAction<boolean>>;
  signingPdfUrl: string | null;
  setSigningPdfUrl: Dispatch<SetStateAction<string | null>>;
  pdfUrl: string | null;
  setPdfUrl: Dispatch<SetStateAction<string | null>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isAllSelected: boolean;
  setIsAllSelected: Dispatch<SetStateAction<boolean>>;
  maxPautasNumber: number;
  // navigate: any; // Alterar para onde esta definido o navigate
  formatDate: (dateString: string) => string;
  handleCheckboxChange: (id: number, isChecked: boolean) => void;
  handleSelectAll: ( event: React.ChangeEvent<HTMLInputElement>, data: any, selectedRows: number[], setSelectedPautaIds: Dispatch<SetStateAction<number[]>>, maxPautasNumber: number) => void;
  handleModal: (id: number | null, selectedPautaIds: number[], closeModal: boolean, setSelectedPautaIds: Dispatch<SetStateAction<number[]>>, setShowModal: Dispatch<SetStateAction<boolean>>, showErrorForDuration: (duration: number) => void) => void;
  showErrorForDuration: (duration: number) => void;
}

export const useInitializeState = (props: Props): State => {
  const { pautasLista, selectedState } = props;

  const [data, setData] = useState(pautasLista);
  const [originalData, setOriginalData] = useState(pautasLista);
  const [selectedPautaIds, setSelectedPautaIds] = useState<number[]>([]);
  const [showError, setShowError] = useState(false);
  const [isPdfReady, setIsPdfReady] = useState(false);
  const [signingPdfUrl, setSigningPdfUrl] = useState<string | null>(null);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAllSelected, setIsAllSelected] = useState(false);

  // https://github.com/amagovpt/doc-CMD-assinatura/blob/main/AMA%20-%20CCMovel%20Especificação%20de%20Serviços%20Assinatura.pdf
  const maxPautasNumber = 100; // value set by AMA for maximum pautas number that can be signed

  // const navigate = useNavigate();

  useEffect(() => {
    setOriginalData(props.pautasLista);
    setData(props.pautasLista);
  }, [props.pautasLista, showModal]);

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-GB").replace(/\//g, "-");
  };

  const showErrorForDuration = (duration: number) => {
    setShowError(true);
  
    setTimeout(() => {
      setShowError(false);
    }, duration);
  };

  const handleCheckboxChange = (id: number, isChecked: boolean) => {
    console.log('Checkbox Change - ID:', id, 'isChecked:', isChecked);
    setSelectedPautaIds((prevSelectedIds) => {
      let newSelectedIds = prevSelectedIds;
      if (isChecked && !prevSelectedIds.includes(id)) {
        newSelectedIds = [...prevSelectedIds, id];
      } else if (!isChecked) {
        newSelectedIds = prevSelectedIds.filter((pid) => pid !== id);
      }
  
      setIsAllSelected(newSelectedIds.length === maxPautasNumber); // update isAllSelected based on the count of selected checkboxes
  
      return newSelectedIds;
    });
  };
  
  return {
    data,
    setData,
    originalData,
    setOriginalData,
    selectedPautaIds,
    setSelectedPautaIds,
    showError,
    setShowError,
    isPdfReady,
    setIsPdfReady,
    signingPdfUrl,
    setSigningPdfUrl,
    pdfUrl,
    setPdfUrl,
    showModal,
    setShowModal,
    isLoading,
    isAllSelected,
    setIsAllSelected,
    setIsLoading,
    // navigate,
    formatDate,
    handleCheckboxChange,
    handleSelectAll, 
    handleModal,
    showErrorForDuration,
    maxPautasNumber,
  };
};

export const handleSelectAll = (
  event: React.ChangeEvent<HTMLInputElement>,
  data: any[],
  selectedRows: number[],
  setSelectedPautaIds: Dispatch<SetStateAction<number[]>>,
  maxPautasNumber: number
) => {
  if (event.target.checked) {
    const newSelectedIds = data.slice(0, maxPautasNumber).map((item) => item.paraAssinaturaId);
    console.log(newSelectedIds)
    setSelectedPautaIds(newSelectedIds);
  } else {
    setSelectedPautaIds([]);
  }
};

export const handleModal = (
  id?: number | null,
  selectedPautaIds?: number[],
  closeModal?: boolean,
  setSelectedPautaIds?: Dispatch<SetStateAction<number[]>>,
  setShowModal?: Dispatch<SetStateAction<boolean>>,
  showErrorForDuration?: (duration: number) => void
) => {
  if (!id && selectedPautaIds?.length === 0) {
    showErrorForDuration?.(1500);
    return;
  }

  if (id && setSelectedPautaIds) {
    setSelectedPautaIds([id]);
  }

  if (closeModal)
    setShowModal?.(false);
  else // toggle modal
    setShowModal?.((prevShowModal) => !prevShowModal);
};