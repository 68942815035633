/**
 * Help scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';

import { Text } from '@paco_ua/pacoui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { AnimatedBackground, Accordion, SearchBox, Dropdown, NoDataMessage } from '@paco_ua/pacoui';

//import config from 'config'

const Help = ({ t }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const { navigateTo } = actions.App;
  const { onMount, onUnmount, setTypeFilter, setSearchFilter, getHelpListing } = actions.Help;

  const { helpListing, filterTypes, filterParams, loadingStatus, errorStatus } = useSelector(
    selectors.getHelpSlice
  );

  useEffect(() => {
    dispatch(onMount());

    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  useEffect(() => {
    dispatch(getHelpListing(filterParams));
  }, [filterParams]);

  const getAccordionParent = (title: string) => {
    return (
      <AccordionParent>
        <Text transform="uppercase" weight="regular">
          {t(`routes.${title}`, { textOnly: true })}
        </Text>
        <FontAwesomeIcon icon={faAngleDown} />
      </AccordionParent>
    );
  };

  const getAccordionChild = (title: string, description: string) => {
    return (
      <AccordionChild
        key={`accordionChild-${title}`}
        onClick={() => {
          dispatch(
            navigateTo({
              key: title,
            })
          );
        }}
      >
        <AccordionChildDescription>
          <AccordionChildColumn>
            <Text size="article" color={theme.colors.plusDarkGrey} weight={'medium'}>
              {t(`routes.${title}`, { textOnly: true })}
            </Text>
          </AccordionChildColumn>
        </AccordionChildDescription>
      </AccordionChild>
    );
  };

  const getStructure = (data) => {
    if (Object.keys(data).length <= 0) {
      return [];
    }

    return Object.keys(data).map((module) => {
      return {
        parent: getAccordionParent(module),
        children: data[module].map((helpRow, index) =>
          getAccordionChild(helpRow.key, helpRow.description)
        ),
      };
    });
  };

  return (
    <PageStructure
      loading={loadingStatus.loading || errorStatus.errLoading}
      headerLeft={<Header title={t('routes.help')} />}
      subHeader={
        <Text color="darkGrey" size="article" weight={'regular'}>
          {t('routes.userHelpDescription', { textOnly: true })}&nbsp;
        </Text>
      }
      mainContent={
        <Wrapper>
          {!loadingStatus.loading && !errorStatus.errLoading && (
            <SearchAndFiltersWrapper>
              <SearchBox
                placeholder={t('generic.searchHelpPlaceholder', { textOnly: true })}
                borderColor={theme.colors.plusDarkGrey}
                iconColor={theme.colors.plusDarkGrey}
                defaultValue={filterParams.search !== '' ? filterParams.search : undefined}
                delay={500}
                onSearch={(value) => {
                  dispatch(setSearchFilter(value));
                  dispatch(setTypeFilter(''));
                }}
              />
              <Dropdown
                placeholder={t('generic.filterByTopic', {
                  textOnly: true,
                })}
                canBeCleared
                value={filterParams.type !== '' ? filterParams.type : undefined}
                options={filterTypes}
                selectionWeight={'400'}
                selectionFontSize={'medium'}
                onChange={(e, { value }) => {
                  dispatch(setTypeFilter(value));
                }}
                onClear={() => {
                  dispatch(setTypeFilter(''));
                }}
              />
            </SearchAndFiltersWrapper>
          )}

          {loadingStatus.loadingSearch && (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="20%" />
              <AnimatedBackground height={theme.sizes.large} width="50%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="50%" />
            </LoadingWrapper>
          )}

          {!loadingStatus.loading &&
            !loadingStatus.loadingSearch &&
            Object.keys(helpListing).length <= 0 && (
              <NoDataMessage
                header={t('generic.warningNoHelpFound')}
                body={t('generic.warningNoHelpFoundBody')}
              />
            )}

          {!loadingStatus.loading &&
            !loadingStatus.loadingSearch &&
            !errorStatus.errLoading &&
            helpListing && (
              <AccordionWrapper>
                <Accordion structure={getStructure(helpListing)} />
              </AccordionWrapper>
            )}
        </Wrapper>
      }
    />
  );
};

export default Help;

const Wrapper = styled.div``;

const AccordionParent = styled.div`
  height: 61px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  background-color: ${({ theme }) => theme.colors.softGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  border-right: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  border-top: 3px solid ${({ theme }) => theme.colors.primary};

  svg {
    font-size: 24px;
  }
`;

const AccordionChildDescription = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: 24px;
  justify-content: space-between;
`;

const AccordionChildColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

const AccordionChild = styled.div`
  height: 69px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  border-right: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.softGrey};
  }
`;

const AccordionWrapper = styled.div``;

const SearchAndFiltersWrapper = styled.div`
  width: 75%;
  display: flex;
  margin-bottom: 20px;

  > div {
    width: 50%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  margin-top: 20px;

  * {
    margin: 8px 0px;
  }
`;
