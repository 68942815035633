/**
 * MySchedules scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getMySchedulesSlice = (state: RootState) => state.MySchedules;
export const getSchedules = (state: RootState) => state.Schedules.mySchedules;
export const getRegistrations = (state: RootState) => state.App.user.registrations;
