/**
 * FreeOptionsDropdown container utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

export const getSelectedOptionKey = (option) => {
  let selectedOptionKey = null;
  option.ucs.some((uc, key) => {
    uc.classSchedule.some((ucClass) => {
      if (ucClass.selected) {
        selectedOptionKey = key;
      }
    });
  });

  return selectedOptionKey;
};

export const ucSelectedOtherOption = (currentOption, currentClass, options) => {
  return options.some((option) => {
    if (option.optionId !== currentOption.optionId) {
      if (option.selected !== null && option.selected !== undefined) {
        if (option.ucs[option.selected].ucId === currentClass.ucId) {
          return true;
        }
      }
    }
    return false;
  });
};
