/**
 * Home logic selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getAppSlice = (state: RootState) => state.App;
export const getUser = (state: RootState) => state.App.user;
export const getHelpSlice = (state: RootState) => state.Help;
