/**
 * ApplicationDetails scene slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IStatus {
  identifier: number;
  ref: string | null;
  requestDate: string | null;
  responseDate: string | null;
  response: string;
  state: string;
  theme: string | null;
  type: string | null;
}

interface IDetails {
  studentName: string;
  course: string;
  category: string;
  statement: string;
  documents: {
    id: string;
    name: string;
    url: string;
    description: string;
  }[];
  applicationDocument:
    | { id: string; nameKey: string; url: string; description: string }
    | undefined;
}

const initialState = {
  status: null as null | IStatus,
  details: null as null | IDetails,
  loadingStatus: {
    loading: true,
  },
  errorStatus: {
    errLoading: false,
  },
};

export default createSlice({
  name: 'ApplicationDetails',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    getStatusAndDetails: () => {},
    setStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    setDetails: (state, action: PayloadAction<IDetails>) => {
      state.details = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    reset: (state) => initialState,
  },
});
