import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { eventChannel } from 'redux-saga';

import config, { IRole } from 'config';

import { listAllScopes } from 'api/utils';
import { history, getCurrentRoute } from 'utils';

import { put, call, takeLatest } from 'redux-saga/effects';
import API from 'api';

export const createTranslationBundles = (translations) => {
  let translationBundles = { pt: {}, en: {}, zh: {} };

  translations.forEach((module) => {
    module.fields.properties.forEach((property) => {
      if (property.key) {
        translationBundles.pt[module.fields.name] = {
          ...translationBundles.pt[module.fields.name],
          [property.key]: property.value,
        };
        translationBundles.en[module.fields.name] = {
          ...translationBundles.en[module.fields.name],
          [property.key]: property.valueEn,
        };
        translationBundles.zh[module.fields.name] = {
          ...translationBundles.zh[module.fields.name],
          [property.key]: property.valueCh,
        };
      }
    });
  });

  return translationBundles;
};

export const initWebSocket = () => {
  //const url = config.SOCKET_BASE_URL + config.SOCKET_SCHEDULES_PATH;
  //return new WebSocket(url);
};

export const initWebSocketChannel = (socket) => {
  return eventChannel((emitter) => {
    const escape_char = '';

    socket.onopen = function () {
      console.log('Connected.');
      socket.send('{"protocol":"json","version":1}' + escape_char);
    };

    socket.onmessage = function (event) {
      const regx = new RegExp(`${escape_char}(?=.*${escape_char})`, 'g');
      const text = event.data.replace(regx, ',');

      const data = JSON.parse(`[${text.slice(0, -1)}]`);

      // keepalive message and response
      if (data[0].type === 6) {
        socket.send('{"type":6}' + escape_char);
      }

      const { route } = getCurrentRoute();

      // system state information
      if (data[0].type === 1) {
        switch (data[0].target) {
          case 'ranking_update':
            if (
              route.key === 'schedules' ||
              route.key === 'editschedules' ||
              route.key === 'duplicateschedules'
            ) {
              return emitter({
                type: 'Schedules/getSeriationResultRealTime',
                payload: '',
              });
            }
            break;
          case 'phase_start':
            if (
              route.key === 'schedules' ||
              route.key === 'editschedules' ||
              route.key === 'duplicateschedules'
            ) {
              return emitter({
                type: 'Schedules/onPhaseUpdate',
                payload: '',
              });
            }

            if (route.key === 'myschedules') {
              return emitter({
                type: 'MySchedules/onPhaseUpdate',
                payload: '',
              });
            }

            if (route.key === 'seriationhistory') {
              return emitter({
                type: 'SeriationHistory/onPhaseUpdate',
                payload: '',
              });
            }
            break;
          case 'phase_finished':
            //TODO get new phase and new data
            if (
              route.key === 'schedules' ||
              route.key === 'editschedules' ||
              route.key === 'duplicateschedules'
            ) {
              return emitter({
                type: 'Schedules/onPhaseUpdate',
                payload: '',
              });
            }

            if (route.key === 'myschedules') {
              return emitter({
                type: 'MySchedules/onPhaseUpdate',
                payload: '',
              });
            }

            if (route.key === 'seriationhistory') {
              return emitter({
                type: 'SeriationHistory/onPhaseUpdate',
                payload: '',
              });
            }
            break;
          default:
            console.log('Event not supported: ' + data[0].type);
        }

        /*return emitter({
          type: 'Toaster/showToaster',
          payload: {
            title: data['arguments'][0]['stage'],
            icon: 'info',
            type: 'warning',
          },
        });*/
      }
    };

    socket.onerror = function (error) {
      console.log('WebSocket error: ' + error.message);
    };

    socket.onclose = function (event) {
      if (event.wasClean) {
        console.log('Disconnected.');
      } else {
        console.log('Connection lost.'); // for example if server processes is killed
      }
      console.log('Code: ' + event.code + '. Reason: ' + event.reason);
    };

    return () => {
      console.log('Socket off');
      socket.close();
    };
  });
};

export const initTracking = () => {
  if (config.SENTRY_ID) {
    Sentry.init({
      dsn: config.SENTRY_ID,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }

  if (config.LOGROCKET_ID) {
    LogRocket.init(config.LOGROCKET_ID);
  }

  if (config.HOTJAR_TRACKING_ID && config.HOTJAR_SNIPPET_VERSION) {
    hotjar.initialize(config.HOTJAR_TRACKING_ID, config.HOTJAR_SNIPPET_VERSION);
  }

  if (config.GA_TRACKING_ID) {
    ReactGA.initialize(config.GA_TRACKING_ID);

    //record firstpage interaction / entryPoint
    ReactGA.set({ page: history.location.pathname });
    ReactGA.pageview(history.location.pathname);

    history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
  }
};

export const redirectAuthUrl = (scopes) => {
  const WSO2url = `${config.API_WSO2}authorize?response_type=${config.OIDC_CONFIG.responseType}&client_id=${config.OIDC_CONFIG.clientId}&state=${config.OIDC_CONFIG.state}&scope=${config.OIDC_CONFIG.scope} ${scopes}&redirect_uri=${config.OIDC_CONFIG.redirectUri}`;
  return WSO2url;
};

export const redirectAuth = () => {
  const allScopes = listAllScopes();
  window.location.replace(redirectAuthUrl(allScopes.join(' ')));
};

export const shouldSignin = () => {
  const code = (window.location.search.match(/code=([^&]+)/) || [])[1];
  const state = (window.location.search.match(/state=([^&]+)/) || [])[1];
  const sessionState = (window.location.search.match(/session_state=([^&]+)/) || [])[1];

  if (code) {
    return {
      code,
      state,
      sessionState,
    };
  }

  return null;
};

export const hasTokenParameter = () => {
  const accessToken = (window.location.search.match(/access_token_parameter=([^&]+)/) || [])[1];
  const idToken = (window.location.search.match(/id_token_parameter=([^&]+)/) || [])[1];
  const refreshToken = (window.location.search.match(/refresh_token_parameter=([^&]+)/) || [])[1];

  if (accessToken && idToken && refreshToken) {
    return {
      accessToken,
      idToken,
      refreshToken,
    };
  }

  return null;
};

export const decodeJWT = (token) => {
  let base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const getUserRoles = (userGroups, superUserData): IRole[] => {
  const { ROLES_RELATIONSHIPS } = config;
  let roles: IRole[] = [];

  if (userGroups) {
    ROLES_RELATIONSHIPS.forEach((roleRelationship) => {
      if (
        roleRelationship.relationships.some((relationship) => userGroups.includes(relationship))
      ) {
        roles.push(roleRelationship.role);
      }
    });
  }

  superUserData.forEach((group) => {
    let relation;
    if (group.includes('sgh_superUser_FullAccess')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('sgh_superUser_FullAccess')
      );
    } else if (group.includes('sec_superUser_FullAccess')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('sec_superUser_FullAccess')
      );
    } else if (group.includes('sgh_superUser_ReadOnly')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('sgh_superUser_ReadOnly')
      );
    } else if (group.includes('sec_superUser_ReadOnly')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('sec_superUser_ReadOnly')
      );
    } else if (group.includes('superUser_ReadOnly')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('superUser_ReadOnly')
      );
    } else if (group.includes('superUser_FullAccess')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('superUser_FullAccess')
      );
    } else if (group.includes('sec_superUser_FullAccess')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('sec_superUser_FullAccess')
      );
    } else if (group.includes('pautas_superUser_ReadOnly')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('pautas_superUser_ReadOnly')
      );
    } else if (group.includes('pautas_superUser_FullAccess')) {
      relation = ROLES_RELATIONSHIPS.find((roleRelation) =>
        roleRelation.role.includes('pautas_superUser_FullAccess')
      );
    }
    if (relation) {
      roles.push(relation.role);
    }
  });

  return roles;
};
