/**
 * CertificateValidator scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getUser = (state: RootState) => state.App.user;
export const getAppSlice = (state: RootState) => state.App.currentLocale;
export const getRegistrationId = (state: RootState) =>
  state.StudentRegistrationDropdown.registration;
export const getRegistrations = (state: RootState) => state.App.user.registrations;
export const getCertificateValidatorSlice = (state: RootState) => state.CertificateValidator;
/*  export const getNewCertificateSlice = (state: RootState) => state.NewCertificate;
 */
