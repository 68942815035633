import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../logic/selectors';
import { actions } from 'store/rootSlices';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

type Props = {
  form: any;
};

export const useWarningModal = ({ form }: Props) => {
  const [showModal, setShowModal] = useState(false);
  let modalContent;

  const { currentStep, showWarningModalOnSubmit, showRepeteadCertModal } = useSelector(
    selectors.getNewCertificateSlice
  );

  const {
    setCurrentStep,
    setShowWarningModalOnSubmit,
    setShowRepeteadCertWarningModal,
  } = actions.NewCertificate;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const submitButtonText = showRepeteadCertModal
    ? t('certificateModal.understood')
    : t('certificateModal.proceedAnyway');

  if (showWarningModalOnSubmit) {
    modalContent = {
      title: t('certificateModal.warningTitle'),
      markdown: t('certificateModal.text'),
      message: t('certificateModal.message'),
    };
  } else if (showRepeteadCertModal) {
    modalContent = {
      title: t('certificateModal.warningTitle'),
      message: t('certificateModal.warningCertificate'),
    };
  } else {
    modalContent = undefined;
  }

  const shouldRenderModal = () => {
    return showWarningModalOnSubmit || showRepeteadCertModal;
  };

  const sanitizeApiObject = (cert) => {
    //return sanitized object
    return _.omitBy(cert, _.isNull);
  };

  //runs every on form update
  const checkActiveCertificates = (data): void => {
    const hasRepeatedCertificate = form?.activeCertificates.some((cert) => {
      const commonKeys = _.intersection(Object.keys(data), Object.keys(cert));
      const sanitizedApiObject = sanitizeApiObject(cert);

      // Compare only common keys
      const areKeysEqual = commonKeys.every((key) => data[key] === sanitizedApiObject[key]);

      return areKeysEqual;
    });

    if (hasRepeatedCertificate) {
      dispatch(setShowRepeteadCertWarningModal(true));
    } else {
      dispatch(setShowRepeteadCertWarningModal(false));
    }
  };

  const onClose = (cancel?: boolean) => {
    if (cancel) {
      dispatch(setShowWarningModalOnSubmit(false));
      dispatch(setShowRepeteadCertWarningModal(false));
      setShowModal(false);

      return;
    }

    if (showWarningModalOnSubmit) {
      dispatch(setShowWarningModalOnSubmit(false));
      setShowModal(false);
      dispatch(setCurrentStep(currentStep + 1));
    } else if (showRepeteadCertModal) {
      dispatch(setShowRepeteadCertWarningModal(false));
      setShowModal(false);
      dispatch(setCurrentStep(currentStep + 1));
    } else {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  return {
    onClose,
    checkActiveCertificates,
    shouldRenderModal,
    setShowModal,
    showModal,
    submitButtonText,
    modalContent,
  };
};
