/**
 * ExamEnrollment scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getSpecialExamsSlice = (state: RootState) => state.SpecialExams;
export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getUserRegistrations = (state: RootState) => state.App.user.registrations;
export const getUser = (state: RootState) => state.App.user;
