/**
 * EnrollmentRenewal scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest, put } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';

function* onMountSaga() {
  yield put(actions.EnrollmentRenewal.setLoading(false));
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('EnrollmentRenewal/onMount', onMountSaga);
  yield takeLatest('EnrollmentRenewal/onUnmount', onUnmountSaga);
}
