/**
 * FreeOptionsList scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getFreeOptionsListSlice = (state: RootState) => state.FreeOptionsList;
export const getCurrentLocale = (state: RootState) => state.App.currentLocale;
