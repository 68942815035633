import axios from 'axios';
import API, { IApiCall } from 'api';
import i18n from 'i18next';
import store from 'store';
import { actions } from 'store/rootSlices';
import { camelizeKeys, decamelizeKeys } from 'humps';
import { JSONtoQueryString } from 'api/utils';
import Endpoints from 'api/endpoints';
import configs from 'config';
import { setStorage } from 'utils';
import { GENERAL_ERROR_MESSAGE } from 'shared/errorMessages';
import { t } from 'app';
import { put } from 'redux-saga/effects';
// Converts all responses for CamelCase
axios.interceptors.response.use(
  (response) => {
    response.data = camelizeKeys(response.data);
    return response;
  },
  async (error) => {
    try {
      const originalRequest = error.config;
      const user = JSON.parse(localStorage.getItem('user') || '{}');

      if (user?.refreshToken && error?.response?.status === 401) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;

          try {
            const response = await API.signin.refresh.call(user.refreshToken);

            const updatedUser = {
              ...user,
              accessToken: response.accessToken,
              refreshToken: response.refreshToken,
              idToken: response.idToken,
            };

            setStorage('user', updatedUser);
            //call store to update user
            await store.dispatch(actions.App.setUser(updatedUser));

            originalRequest.headers['Authorization'] = `Bearer ${response.accessToken}`;
            originalRequest.headers['IDToken'] = `Bearer ${response.idToken}`;
          } catch (e) {
            await store.dispatch(actions.App.signout());
          }

          //redo and camelize newResponse data
          try {
            const axiosApiInstance = axios.create();
            const redoneResponse = await axiosApiInstance(originalRequest);
            redoneResponse.data = camelizeKeys(redoneResponse.data);
            return redoneResponse;
          } catch (e) {
            return Promise.reject(e);
          }
        }
      } else {
        if (
          // error?.response?.status === 403 ||
          !user?.refreshToken &&
          error?.response?.status === 401
        ) {
          throw new Error(GENERAL_ERROR_MESSAGE.INTERCEPTOR_LOGOUT);
        } else if (error?.response?.status === 403) {
          throw new Error(GENERAL_ERROR_MESSAGE.ERR_INVALID_ACCESS);
        } else {
          return Promise.reject(error);
        }
      }
    } catch (e) {
      if (e instanceof Error) {
        if (e.message === GENERAL_ERROR_MESSAGE.ERR_INVALID_ACCESS)
          throw new Error(GENERAL_ERROR_MESSAGE.ERR_INVALID_ACCESS);
        else throw new Error(GENERAL_ERROR_MESSAGE.INTERCEPTOR_LOGOUT);
      }
      //call store to logout user
      else throw new Error(GENERAL_ERROR_MESSAGE.INTERCEPTOR_LOGOUT);
    }
  }
);
// Converts all requests to under-cased
axios.interceptors.request.use(
  async (config) => {
    let user = JSON.parse(localStorage.getItem('user') || '{}');
    const currentContentType = config.headers['Content-Type'];

    //TODO MARTELADA
    config.headers['Accept-Language'] = i18n.language;

    //all calls to every api we use are decamelized
    if (!(config.data instanceof FormData)) {
      config.data = decamelizeKeys(config.data);
    }
    if (config.url && config.params && !config.url.includes('secvirtual')) {
      config.params = decamelizeKeys(config.params);
    }
    if (currentContentType && currentContentType.includes('x-www-form-urlencoded') && config.data) {
      config.data = JSONtoQueryString(config.data);
    }

    //add correct rules and token to our requests
    if (config.url && !config.url.includes(Endpoints.signin.token)) {
      if (user?.accessToken) {
        config.headers['Authorization'] = `Bearer ${user.accessToken}`;
        config.headers['Content-Type'] = `application/octet-stream`;

        //so far only this API has this verification
        if (
          (user.idToken &&
            (config.url.includes(configs.API_SCHEDULES_PATH) ||
              config.url.includes(configs.API_SEC_VIRTUAL_PATH) ||
              config.url.includes(configs.API_STUDENT) ||
              config.url.includes(configs.API_SEARCH))) ||
          (config.url.includes(configs.API_PAUTAS_PATH) && !config.headers['IDToken'])
        ) {
          config.headers['IDToken'] = `Bearer ${user.idToken}`;
        }

        //so far only this API has this verification
        if (
          user.impersonate &&
          !config.url.includes(configs.API_TRANSLATIONS_PATH) &&
          !config.headers['impersonate']
        ) {
          config.headers['impersonate'] = user.impersonate;
        }
      }

      if (config.method !== 'get') {
        config.headers['Content-Type'] = `application/json`;
      }
      if (config.data instanceof FormData) {
        config.headers['Content-Type'] = `multipart/form-data`;
      }
      //remove last slash from wso2 requests
      if (config.url && config.url.includes('wso2')) {
        if (config.url.slice(-1) === '/') {
          config.url = config.url.substring(0, config.url.length - 1);
        }
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default axios;
