/**
 * PendingDebitsPayment validations
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import * as yup from 'yup';
import PhoneNumber from 'awesome-phonenumber';

export const yupSchema = (t) => {
  return yup.object().shape({
    countryDropdown: yup.object()
      .required()
      .test('phone', t('payments.phoneValidation', { textOnly: true }), function (value) {
        const pn = new PhoneNumber(value.input, value.countryValue);
        return pn.isValid();
      })
      .test('phone', "Apenas 91/96/93/92 são válidos", function (value) {
        const pn = String(value.input).startsWith("9")
        return pn;
      })
  });
}