/**
 * NewApplication scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';
import { history } from 'utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { Text, Button } from '@paco_ua/pacoui';

import NewApplicationSelectRegistration from './containers/NewApplicationSelectRegistration';
import NewApplicationSelectType from './containers/NewApplicationSelectType';
import NewApplicationStatement from './containers/NewApplicationStatement';
import NewApplicationSubmission from './containers/NewApplicationSubmission';

import FormManager from 'shared/containers/FormManager';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { getFormValues } from './utils';

const NewApplication = ({ t }) => {
  const theme = useContext(ThemeContext);

  const registrations = useSelector(selectors.getRegistrations);

  const user = useSelector(selectors.getUser);

  const steps = [
    t('applications.requestFirstStep'),
    t('applications.requestSecondStep'),
    t('applications.requestThirdStep'),
    t('applications.requestFourthStep'),
  ];

  const stepStyles = {
    done: {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
      fontWeight: 500,
      border: 'none',
      borderStyle: null,
      checkTick: true,
    },
    current: {
      backgroundColor: theme.colors.lightGrey,
      color: theme.colors.primary,
      fontWeight: 500,
      border: 'top',
      borderStyle: `2px solid ${theme.colors.primary}`,
      checkTick: false,
    },
    toDo: {
      backgroundColor: 'transparent',
      color: theme.colors.regularGrey,
      fontWeight: 400,
      border: 'all',
      borderStyle: `1px solid ${theme.colors.regularGrey}`,
      checkTick: false,
    },
  };

  //const { } = useSelector(state => state.NewApplication)
  const {
    onMount,
    onUnmount,
    setCurrentStep,
    getApplicationForm,
    setApplicationData,
    getApplicationTypes,
    setApplicationTypeId,
    submitApplication,
    setLoadingStatus,
    resetStepThree,
    resetApplicationSelection,
    setErrorStatus,
    resetPage,
  } = actions.NewApplication;

  const dispatch = useDispatch();
  const currentStep = useSelector(selectors.getCurrentStep);
  const newApplicationData = useSelector(selectors.getNewApplicationData);
  const { applicationTypes, filterParams } = useSelector(selectors.getNewApplicationSlice);
  const newApplicationErrorStatus = useSelector(selectors.getApplicationErrorStatus);
  const newApplicationLoading = useSelector(selectors.getApplicationLoading);
  const newApplicationTabLoading = useSelector(selectors.getApplicationTabLoading);
  const [newApplicationReset, setNewApplicationReset] = useState(false);

  const [loadingFile, setLoadingFile] = useState({});

  useEffect(() => {
    if (
      history.location.state &&
      (!newApplicationData.type.id || !newApplicationData.registrationId)
    ) {
      if (!newApplicationData.registrationId && registrations) {
        dispatch(
          setApplicationData({
            ...newApplicationData,
            registrationId:
              history.location.state.registrationId ?? registrations[0].registrationId,
          })
        );
      }
      if (applicationTypes.filters.length === 0 && newApplicationData.registrationId) {
        dispatch(getApplicationTypes(filterParams));
      }
      if (registrations && applicationTypes.filters.length > 0) {
        let key;
        if ('alteraçãoDeDados' in applicationTypes.data) {
          key = 'alteraçãoDeDados';
        } else if ('changeOfData' in applicationTypes.data) {
          key = 'changeOfData';
        }

        dispatch(setApplicationTypeId(applicationTypes.data[key][0].applicationId));
        if (!newApplicationData.type.id) {
          dispatch(
            setApplicationData({
              ...newApplicationData,
              type: {
                theme: applicationTypes.data[key][0].theme,
                id: applicationTypes.data[key][0].applicationId,
                name: applicationTypes.data[key][0].type,
              },
            })
          );
          currentStep === 1 && dispatch(setCurrentStep(3));
        }
        dispatch(
          setLoadingStatus({
            fieldName: 'loading',
            fieldValue: false,
          })
        );

        //clear history state after this exceptional situation (TODO: review this useEffect)
        history.replace();
      }
    }
  }, [
    registrations,
    newApplicationData.registrationId,
    filterParams,
    applicationTypes,
    applicationTypes.filters.length,
  ]);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  useEffect(() => {
    if (currentStep === 3 && newApplicationData.form === null) {
      dispatch(getApplicationForm());
    }
  }, [dispatch, currentStep]);

  useEffect(() => {
    if (newApplicationReset) {
      dispatch(
        setLoadingStatus({
          fieldName: 'loading',
          fieldValue: false,
        })
      );
      setNewApplicationReset(false);
    }
  }, [dispatch, newApplicationReset]);

  const onSubmitStatement = (data) => {
    dispatch(
      setLoadingStatus({
        fieldName: 'loading',
        fieldValue: true,
      })
    );
    dispatch(
      setApplicationData({
        ...newApplicationData,
        formValues: getFormValues(newApplicationData.form, data),
      })
    );
    dispatch(setCurrentStep(currentStep + 1));
  };

  const getFormSchema = useMemo(() => {
    if (newApplicationData.form) {
      const {
        expoFields,
        observations,
        mandatoryDocuments,
        additionalDocuments,
      } = newApplicationData.form;

      return [
        ...(expoFields.values && expoFields.values.length ? expoFields.values : []),
        ...(observations.values && observations.values.length ? observations.values : []),
        ...(mandatoryDocuments.values && mandatoryDocuments.values.length
          ? mandatoryDocuments.values
          : []),
        ...(additionalDocuments.values && additionalDocuments.values.length
          ? additionalDocuments.values
          : []),
      ];
    } else {
      return [];
    }
  }, [newApplicationData.form]);

  const getNextButtonDisabledState = (step) => {
    if (step === 1 && newApplicationData.registrationId) {
      return false;
    } else if (step === 2 && newApplicationData.type.id) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <PageStructure
      loading={newApplicationLoading || !registrations}
      headerLeft={<Header title={t('applications.requestTitle')} />}
      mainContent={
        <>
          <ApplicationStepsWrapper>
            {steps.map((step, index) => {
              if (index + 1 < currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.done.backgroundColor}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.done.color}
                      fontWeight={`${stepStyles.done.fontWeight}`}
                    >
                      {step}
                    </TabText>
                    <CheckTickWrapper>
                      <FontAwesomeIcon icon={faCheck} size={'xs'} />
                    </CheckTickWrapper>
                  </ApplicationStep>
                );
              } else if (index + 1 === currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.current.backgroundColor}
                    border={stepStyles.current.border}
                    borderStyle={stepStyles.current.borderStyle}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.current.color}
                      fontWeight={`${stepStyles.current.fontWeight}`}
                    >
                      {step}
                    </TabText>
                  </ApplicationStep>
                );
              } else {
                //if (index + 1 > currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.toDo.backgroundColor}
                    border={stepStyles.toDo.border}
                    borderStyle={stepStyles.toDo.borderStyle}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.toDo.color}
                      fontWeight={`${stepStyles.toDo.fontWeight}`}
                    >
                      {step}
                    </TabText>
                  </ApplicationStep>
                );
              }
            })}
          </ApplicationStepsWrapper>

          <FormManager formSchema={getFormSchema}>
            {({ handleSubmit, reset }, formElements, formElementsData) => {
              return (
                <>
                  {currentStep === 1 && <NewApplicationSelectRegistration />}
                  {currentStep === 2 && <NewApplicationSelectType />}
                  {currentStep === 3 && (
                    <NewApplicationStatement
                      formElements={formElements}
                      formElementsData={formElementsData}
                      setLoadingFile={setLoadingFile}
                    />
                  )}
                  {currentStep === 4 && <NewApplicationSubmission />}
                  <NavigationButtonsWrapper currentStep={currentStep}>
                    {currentStep !== 1 && (
                      <Button
                        decision
                        onClick={() => {
                          reset(); // reset form
                          setLoadingFile({});
                          dispatch(resetPage());
                          setNewApplicationReset(true);
                        }}
                      >
                        {t('applications.requestCancelButton')}
                      </Button>
                    )}
                    <BackwardForwardButtonsWrapper>
                      {currentStep !== 1 && (
                        <Button
                          decision
                          onClick={() => {
                            if (currentStep === 2) {
                              dispatch(
                                setErrorStatus({
                                  fieldName: 'errLoadingApplicationList',
                                  fieldValue: false,
                                })
                              );
                              reset(); // reset form
                              setLoadingFile({});
                              dispatch(resetApplicationSelection());
                            }
                            // solves some form manager validation errors when going back and forth
                            // selecting diferent application types
                            else if (currentStep === 3) {
                              dispatch(
                                setErrorStatus({
                                  fieldName: 'errLoadingApplicationForm',
                                  fieldValue: false,
                                })
                              );
                              setLoadingFile({});
                              reset(); // reset form
                              dispatch(resetStepThree());
                            }

                            dispatch(setCurrentStep(currentStep - 1));
                          }}
                        >
                          {t('applications.requestPreviousButton')}
                        </Button>
                      )}
                      {(currentStep === 1 || currentStep === 2) && (
                        <Button
                          action
                          color={theme.colors.primary}
                          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                          onClick={() => {
                            //dispatch(setLoading(true));
                            dispatch(setCurrentStep(currentStep + 1));
                          }}
                          disabled={
                            (currentStep === 2 && newApplicationTabLoading) ||
                            newApplicationErrorStatus.errLoadingApplicationList ||
                            getNextButtonDisabledState(currentStep)
                          }
                        >
                          {t('applications.requestNextButton')}
                        </Button>
                      )}
                      {currentStep === 3 && (
                        <Button
                          action
                          disabled={
                            newApplicationTabLoading ||
                            newApplicationErrorStatus.errLoadingApplicationForm ||
                            Object.keys(loadingFile).some(
                              (itemKey) => loadingFile[itemKey] === true
                            )
                          }
                          color={theme.colors.primary}
                          rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                          onClick={handleSubmit(onSubmitStatement)}
                        >
                          {t('applications.requestNextButton')}
                        </Button>
                      )}
                      {currentStep === 4 && (
                        <Button
                          action
                          color={theme.colors.primary}
                          rightIcon={<FontAwesomeIcon icon={faCheck} />}
                          onClick={() => {
                            dispatch(submitApplication());
                          }}
                        >
                          {t('applications.requestSubmitButton')}
                        </Button>
                      )}
                    </BackwardForwardButtonsWrapper>
                  </NavigationButtonsWrapper>
                </>
              );
            }}
          </FormManager>
        </>
      }
    />
  );
};

export default NewApplication;

const ApplicationStepsWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  overflow-y: hidden;
  overflow-x: auto;
`;

const ApplicationStep = styled.div<{
  backgroundColor: string;
  border?: string;
  borderStyle?: string;
}>`
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ border, borderStyle }) => {
    if (border === 'all') {
      return `border: ${borderStyle};`;
    } else if (border === 'top') {
      return `border-top: ${borderStyle};`;
    }
  }}

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const CheckTickWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;

  &&& {
    min-width: 16px;
    height: 16px;
  }

  svg {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const NavigationButtonsWrapper = styled.div<{ currentStep: number }>`
  display: flex;
  justify-content: ${({ currentStep }) => (currentStep === 1 ? `flex-end` : `space-between`)};
  margin-top: 32px;
`;

const BackwardForwardButtonsWrapper = styled.div`
  display: flex;

  > button:last-child {
    margin-left: 16px;
  }
`;

const TabText = styled(Text)`
  line-height: 1.2;
`;
