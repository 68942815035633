/**
 * Multi Payments Selection container logic
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import { ITranslations, IDebitData, IPagination } from 'scenes/VirtualSecretary/Payments/types';
import { DEFAULT_STRUCTURE, DEFAULT_PAGINATION } from './utils';
import { DEFAULT_UNSELECTED_DEBITS_PARAMS } from '../../utils';

export default (
  t: ITranslations,
  debits: IDebitData[],
  selectedDebits: IDebitData[],
  preSelectedDebits: IDebitData[],
  compileTableRow,
  total: number,
  fetchUnselectedDebits,
  filterParams,
  filtersChanged
) => {
  const theme = useContext(ThemeContext);
  const [structure, setStructure] = useState(DEFAULT_STRUCTURE(t));
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION(total));

  useEffect(() => {
    let updatePagination = { ...pagination };
    updatePagination.total = total;

    setPagination(updatePagination);
  }, [total]);

  useEffect(() => {
    handlePaginationNavigation(pagination);
  }, [pagination]);

  useEffect(() => {
    if (filtersChanged) {
      setPagination(DEFAULT_PAGINATION(total));
    }
  }, [filtersChanged]);

  // onMount
  useEffect(() => {
    // fetch new list
    fetchUnselectedDebits(DEFAULT_UNSELECTED_DEBITS_PARAMS);
  }, [filterParams]);

  useEffect(() => {
    const tableStructure = compileTableStructure(debits, theme, t);
    setStructure(tableStructure);
  }, [debits]);

  const compileTableStructure = (debits: IDebitData[], theme, t: ITranslations) => ({
    header: {
      type: 'columnTitle',
      titles: [
        { text: t('payments.debitsTablePaymentDescription', { textOnly: true }) },
        {
          text: t('payments.debitsTablePaymentValue', { textOnly: true }),
          style: { textAlign: 'center' },
        },
        {
          text: t('payments.debitsTableDueDate', { textOnly: true }),
          style: { textAlign: 'center' },
        },
        {
          text: t('payments.debitsTablePaymentStatus', { textOnly: true }),
          style: { textAlign: 'center' },
        },
      ],
    },
    rows: !debits.length
      ? ([] as any)
      : [
          ...debits.map((debit, key) =>
            compileTableRow(debit, selectedDebits, preSelectedDebits, theme, t)
          ),
        ],
  });

  const handlePaginationNavigation = (newPagination: IPagination) => {
    const exceedsTotal = newPagination.limit >= newPagination.total;

    if (exceedsTotal) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = false;
    }

    if (!exceedsTotal && newPagination.offset < newPagination.limit) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = true;
    }

    if (!exceedsTotal && newPagination.offset >= newPagination.limit) {
      newPagination.hasPrevious = true;
      newPagination.hasNext =
        newPagination.offset + newPagination.limit >= newPagination.total ? false : true;
    }

    setPagination(newPagination);
  };

  const onChangeRows = (value: number): void => {
    const newPagination = { ...pagination };
    newPagination.limit = value;
    newPagination.offset = 0;

    setPagination(newPagination);
    fetchUnselectedDebits(newPagination);
  };

  const onChangePage = (value: string): void => {
    const newPagination = { ...pagination };

    switch (value) {
      case 'prev':
        newPagination.offset -= newPagination.limit;
        break;
      case 'next':
        newPagination.offset += newPagination.limit;
        break;
      default:
        break;
    }

    setPagination(newPagination);

    fetchUnselectedDebits(newPagination);
  };

  return {
    theme,
    structure,
    pagination,
    onChangePage,
    onChangeRows,
  };
};
