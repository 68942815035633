import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

import { SearchBox, Dropdown, AnimatedBackground, Card } from '@paco_ua/pacoui';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import GradesManagementUtils from './utils';
import TableView from './TableView/index';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as selectors from './logic/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';



const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: minmax(min-content, max-content);
  overflow-y: auto;
  gap: 10px;
  max-height: calc(65vh - 200px);
  margin-left: 3rem;
  margin-right: 3rem;
`;

const SemesterBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SemesterTitle = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const StatLine = styled.div`
  display: flex;
  align-items: center;
  &:hover {
    > span.statLabel {
      color: #000;
    }
  }
`;

const StatLabel = styled.span.attrs({ className: 'statLabel' })`
  cursor: pointer;
  color: #757575;
  text-decoration: underline;
  margin-bottom: 0.9rem;
`;

const StatCount = styled.p`
  cursor: pointer;
  color: white;
  width: 1.6rem; 
  height: 1.6rem; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  margin-right: 0.3rem;
`;

const Dashboard = styled.div`
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 2%;
`;


const GradesManagement = ({ theme, t }) => {

  let year, semester;
  let formattedYear;
  const {
    pautaStatsArray,
    totalPautasStats,
    periodosDisponiveis,
    filtrarPorPeriodo,
    loadingStatus,
    selectedReduxState,
    handleSearchChange,
    handleYearChange,
    uniqueStatus,
    intersectedPautas,
    handleStateChange,
    currentLocale,
    handleDashboardClick
  } = GradesManagementUtils.useGradesManagement();
  const [year1, semester1] = filtrarPorPeriodo.split('-');
  const nextYear = parseInt(year1) + 1;
  const formattedFiltrarPorPeriodo = `${year1}/${nextYear.toString().slice(-2)} - ${semester1}º ${t('pautas.semestre', { textOnly: true })}`;
  const dropdownOptions = periodosDisponiveis.map((choice, index) => {
    [year, semester] = choice.split('-');

    if (semester === '0')
      formattedYear = `${parseInt(year)}/${parseInt(year.slice(-2)) + 1} - ${t('pautas.annualPeriod', { textOnly: true })}`; // when semester is 0 it means it's the whole year
    else
      formattedYear = `${parseInt(year)}/${parseInt(year.slice(-2)) + 1} - ${semester}º ${t('pautas.semestre', { textOnly: true })}`;

    return {
      key: choice,
      text: formattedYear,
      value: choice,
    };
  });

  const ref = useRef<HTMLDivElement>(null);

  const scrollToTop = () => {
    ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const pautasLista = useSelector(selectors.getPautasLista);

  const dispatch = useDispatch(); // Correctly call useDispatch at the top level

  useEffect(() => {
    // Dispatch the action on mount
    dispatch(actions.GradesManagement.onMount());

    // Return a cleanup function that dispatches another action when the component unmounts
    return () => {
      dispatch(actions.GradesManagement.onUnmount());
    };
  }, [dispatch]);

  const placeholderText = dropdownOptions.length > 0 ? dropdownOptions[0].text : "";

  return (
    <PageStructure
      loading={false}
      expanded={true}
      headerLeft={<Header title={t('routes.gradesManagement')} />}
      mainContent={
        <>
          {loadingStatus.loadingGrades ? (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="0%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
            </LoadingWrapper>
          ) : (
            <div ref={ref}>
              <Dashboard>
                {(totalPautasStats > 0) ? (
                  <Card>
                    {loadingStatus.loadingStats ? (
                      <>
                        <AnimatedBackground height={theme.sizes.large} width="30%" />
                        <AnimatedBackground height={theme.sizes.large} width="0%" />
                        <AnimatedBackground height={theme.sizes.large} width="90%" />
                      </>
                    ) : (
                      <>
                        <div style={{ display: 'flex', marginBottom: '1.5rem', alignItems: 'center' }}>
                          <FontAwesomeIcon icon={faInfoCircle} size="2x" color='#0EB4BD' />
                          <p style={{ marginLeft: '1%', fontSize: '1.1rem' }}>{totalPautasStats} {t('pautas.pendingGrades', { textOnly: true })}!</p>
                        </div>
                        <GridContainer>
                          {pautaStatsArray.map((item, index) => (
                            <SemesterBlock key={index}>
                              <SemesterTitle>
                                {item.semester.substring(0, 4)}/{parseInt(item.semester.substring(0, 4).toString().slice(-2)) + 1} - {item.semester.substring(4)}º {t('pautas.semestre', { textOnly: true })}
                              </SemesterTitle>
                              {item.withErrors && <StatLine onClick={() => handleDashboardClick("6", `${item.semester.substring(0, 4)}-${item.semester.substring(4)}`)}><StatCount style={{ backgroundColor: '#f44336' }}>{item.withErrors}</StatCount><StatLabel>{t('pautas.gradesWithErrors', { textOnly: true })}</StatLabel></StatLine>}
                              {item.toSubmit && <StatLine onClick={() => handleDashboardClick("2", `${item.semester.substring(0, 4)}-${item.semester.substring(4)}`)}><StatCount style={{ backgroundColor: '#0EB4BD' }}>{item.toSubmit}</StatCount><StatLabel>{t('pautas.gradesToSubmit', { textOnly: true })}</StatLabel></StatLine>}
                              {item.toSign && <StatLine onClick={() => handleDashboardClick("7", `${item.semester.substring(0, 4)}-${item.semester.substring(4)}`)}><StatCount style={{ backgroundColor: '#0EB4BD' }}>{item.toSign}</StatCount><StatLabel>{t('pautas.gradesToSign', { textOnly: true })}</StatLabel></StatLine>}
                            </SemesterBlock>
                          ))}
                        </GridContainer>
                      </>
                    )}
                  </Card>) :
                  <Card>
                    <div style={{ padding: '0.5rem 0', display: 'flex', gap: '1rem', alignItems: 'center' }}>
                      <FontAwesomeIcon icon={faInfoCircle} size="2x" color='#0EB4BD' />
                      <p style={{ fontSize: '1.1rem' }}>{t('pautas.noGradesStats')}</p>
                    </div>
                  </Card>}
              </Dashboard>

              <div style={{ display: 'flex', marginLeft: 30, marginRight: 30 }}>
                <Dropdown
                  options={dropdownOptions}
                  value={filtrarPorPeriodo || null}
                  onChange={(e: SyntheticEvent<HTMLElement, Event>, data: { [key: string]: any }) => handleYearChange(e, data)}
                  placeholder={filtrarPorPeriodo ? formattedFiltrarPorPeriodo : placeholderText}
                  style={{ marginRight: 10 }}
                />

                <Dropdown
                  options={[
                    { key: "Todas", text: t('pautas.all'), value: "Todas" },
                    ...uniqueStatus.map((choice) => ({
                      key: choice.toString(),
                      text: t(`pautas.gradeState${choice}`, { textOnly: true }),
                      value: choice.toString(),
                    })),
                  ]}
                  value={selectedReduxState}
                  onChange={(e: any, item: { key: string | number; text: string; value: string } | undefined) => handleStateChange(item)}
                  placeholder={t('pautas.stateFilter', {
                    textOnly: true,
                  })}
                  style={{ marginRight: 10 }}
                />

                <SearchBox
                  borderColor="#000"
                  defaultValue=""
                  iconColor=""
                  onSearch={handleSearchChange}
                  placeholder={t('pautas.search', {
                    textOnly: true,
                  })}
                  style={{ width: '100%' }}
                />
              </div>

              <div style={{ width: '100%' }}>
                <TableView scrollToTop={scrollToTop} pautasLista={[intersectedPautas]} selectedState={selectedReduxState} loading={loadingStatus.loadingTable} isPT={currentLocale === 'pt' ? true : false} />
              </div>
            </div>
          )}
        </>
      }
    />

  );
};

export default GradesManagement;
