/**
 * ClassSupoort scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
 
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'

import { actions } from 'store/rootSlices'
import * as selectors from './logic/selectors';
import { CallToAction, Text } from '@paco_ua/pacoui';

//import config from 'config'

const ClassSupoort = ({ t, i18n }) => {

  //const { } = useSelector(state => state.ClassSupoort)

  const { onMount, onUnmount} = actions.ClassSupoort
  //const { api_t } = actions.App

  const dispatch = useDispatch();
  const user = useSelector(selectors.getUser);

  useEffect(() => {

    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <CallToAction
        topBorder       
        footer={
          <Text color="darkGrey" size="article">
            {t('generic.iframeBanner')}
          </Text>
        }
        padding={'15px 38px'}
        noPointer
      />
      <iframe src={"https://paco.ua.pt/PACO2/secvirtual/aulas/lista_turmas_aluno.asp?token=" + user.idToken + "&impe=" + user.impersonate} width="100%" height="800px" frameBorder="0" ></iframe>
    </Wrapper>
  )
}

export default ClassSupoort

const Wrapper = styled.div`
  text-align: center;
`