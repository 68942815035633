/**
 * Help scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { takeLatest, call, put } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import ErrorHandler from 'shared/errorHandler';
import API from 'api';

import { t } from 'app';

function* onMountSaga() {
  yield put(actions.Help.setLoadingStatus({ fieldName: 'loading', fieldValue: true }));

  yield call(getHelpListingSaga);
}

function* getHelpListingSaga(params?: any) {
  try {
    yield put(actions.Help.setLoadingStatus({ fieldName: 'loadingSearch', fieldValue: true }));
    yield put(actions.Help.setErrorStatus({ fieldName: 'errLoading', fieldValue: false }));

    const helpListing = yield call(API.search.getFunctionalitiesHelp.call, {
      ...params?.payload,
    });

    yield put(actions.Help.setFilterTypes(helpListing.filters));
    yield put(actions.Help.setHelpListing(helpListing.data));

    yield put(actions.Help.setLoadingStatus({ fieldName: 'loading', fieldValue: false }));
    yield put(actions.Help.setLoadingStatus({ fieldName: 'loadingSearch', fieldValue: false }));
  } catch (e) {
    yield put(
      actions.Help.setErrorStatus({
        fieldName: 'errLoadingList',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorLoadingHelp', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.Help.reset());
}

export default function* watcherSignin() {
  yield takeLatest('Help/onMount', onMountSaga);
  yield takeLatest('Help/getHelpListing', getHelpListingSaga);
  yield takeLatest('Help/onUnmount', onUnmountSaga);
}
