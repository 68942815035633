/**
 * PdfReader component
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Worker, Viewer, OpenFile } from '@react-pdf-viewer/core';
import { getFilePlugin,RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { Text } from '@paco_ua/pacoui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/pro-light-svg-icons';



interface IProps {
  document: {
    url: string | Uint8Array;
    name?: string;
  };
  height?: string;
}

const PdfReader: React.FC<IProps> = ({ document, height }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // TODO: check if the httpHeaders are still necessary
  const [httpHeader, setHttpHeader] = useState(
    {} as
      | {
          Authorization: string;
          IDToken: string;
          'Access-Control-Allow-Origin': string;
          mode: string;
        }
      | undefined
  );
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: (file: OpenFile) => {
      // `file.name` is the URL of opened file
      const fileName = document.name;
      return `${fileName}`;
  },
  });
  const { Download } = getFilePluginInstance;

  const onDocumentLoadSuccess = ({ doc }) => {
    setNumPages(doc.numPages);
  };

  const onPageChange = ({ currentPage }) => {
    setPageNumber(currentPage + 1);
  };

  // TODO: check if the httpHeaders are still necessary
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');

    if (user) {
      setHttpHeader({
        'Access-Control-Allow-Origin': '*',
        mode: 'no-cors',
        Authorization: `Bearer ${user.accessToken}`,
        IDToken: `Bearer ${user.idToken}`,
      });
    } else {
      setHttpHeader(undefined);
    }
  }, []);

  return (
    <Wrapper height={height}>
      <PageNumbersWrapper>
        {document.name && (
          <TextLeft weight="medium" color="white">
            {document.name}
          </TextLeft>
        )}
        <TextRight weight="medium" color="white">
          {pageNumber}/{numPages}
        </TextRight>
        <Download>
          {
              (props: RenderDownloadProps) => (
                <FontAwesomeIcon
                  size="sm"
                  color={"white"}
                  icon={faDownload}
                  onClick={props.onClick}
                  style={{
                   cursor:"pointer",
                   marginLeft:"15px"
                  }}
                />
              )
          }
        </Download>
      </PageNumbersWrapper>

      {httpHeader !== undefined && (
        <PdfWrapper>
          <Worker workerUrl="https://unpkg.com/@react-pdf-viewer/pdfjs-dist-signature@2.5.207/build/pdf.worker.js">
            <Viewer
              onDocumentLoad={onDocumentLoadSuccess}
              onPageChange={onPageChange}
              fileUrl={document.url}
              withCredentials={true}
              plugins={[getFilePluginInstance]}
              /*httpHeaders={{
                mode: 'no-cors',
              }}*/
              httpHeaders={httpHeader}
            />
          </Worker>
        </PdfWrapper>
      )}
    </Wrapper>
  );
};

export default PdfReader;

const Wrapper = styled.div<{ height?: string }>`
  height: ${({ height }) => (height ? height : '100%')};
`;

const PdfWrapper = styled.div<{ height?: string }>`
  height: calc(100% - 70px);
  background-color: ${({ theme }) => theme.colors.softGrey};
  border: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  border-top: none;

  &&& {
    .rpv-core-inner-page {
      background-color: ${({ theme }) => theme.colors.softGrey};
    }
  }
`;

const PageNumbersWrapper = styled.div`
  width: 100%;
  height: 70px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 0 24px;
  display: flex;
  align-items: center;
`;

const TextLeft = styled(Text)`
  text-align: left;
  width: 100%;
`;

const TextRight = styled(Text)`
  text-align: right;
  width: 100%;
`;
