/**
 * FreeSlotsList scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  year: null as string | null,
  semester: null as string | null,
  loading: true as boolean,
  error: false as boolean,
};

export default createSlice({
  name: 'FreeOptionsList',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setPeriod: (state, action: PayloadAction<{ year: string; semester: string }>) => {
      state.year = action.payload.year;
      state.semester = action.payload.semester;
    },
    reset: () => initialState,
  },
});
