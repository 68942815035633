/**
 * SeriationHistoryDetails scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { call, put, putResolve, takeLatest, select } from 'redux-saga/effects';

import API from 'api';

import { t } from 'app';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import ErrorHandler from 'shared/errorHandler';

import { getCurrentRoute } from 'utils';

function* onMountSaga() {
  try {
    yield put(actions.SeriationHistoryDetails.setLoading(true));
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setRestriction(true));
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetSeriationHistory'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getSeriationHistoryDetailsSaga() {
  try {
    yield put(actions.SeriationHistoryDetails.setLoading(true));
    const registrationId = yield select(selectors.getRegistration);

    let response;
    const phaseName = getCurrentRoute().params.id;
    const seriationId = getCurrentRoute().params.seriationId;

    if (getCurrentRoute().route.key === 'seriationhistorydetails') {
      response = yield call(API.sgh.getHistoryDetails.call, {
        registrationId: registrationId,
        phase: phaseName,
        final: true,
        seriationId: seriationId,
      });

      if (response) {
        yield put(actions.SeriationHistoryDetails.setSeriationDate(response?.seriationDate));
        yield put(actions.SeriationHistoryDetails.setRanking(response?.ranking));

        if (response?.log) {
          yield put(actions.SeriationHistoryDetails.setLog(response?.log.split('\n')));
        }
        yield put(actions.SeriationHistoryDetails.setLoading(false));
      }
    } else if (getCurrentRoute().route.key === 'provisoryseriationhistorydetails') {
      response = yield call(API.sgh.getHistoryDetails.call, {
        registrationId: registrationId,
        phase: phaseName,
        final: false,
        seriationId: seriationId,
      });

      if (response) {
        yield put(actions.SeriationHistoryDetails.setSeriationDate(response.seriationDate));
        yield put(actions.SeriationHistoryDetails.setRanking(response.ranking));
        yield put(actions.SeriationHistoryDetails.setLog(response.log.split('\n')));
        yield put(actions.SeriationHistoryDetails.setLoading(false));
      }
    }

    if (!response) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionNoSeriationHistoryDetails'),
          icon: 'info',
          type: 'info',
        })
      );
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetSeriationHistory'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.SeriationHistoryDetails.reset());
}

export default function* watcherSignin() {
  yield takeLatest('SeriationHistoryDetails/onMount', onMountSaga);
  yield takeLatest('SeriationHistoryDetails/onUnmount', onUnmountSaga);
  yield takeLatest(
    'SeriationHistoryDetails/getSeriationHistoryDetails',
    getSeriationHistoryDetailsSaga
  );
}
