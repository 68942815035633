import axios from 'axios';
import Endpoints from 'api/endpoints';

import { IApiCall } from '../index';

import mockFunctionalities from 'api/schemas/mockFunctionalities';

const getFunctionalities = () => {
  //return mockFunctionalities.getFunctionalities;
  return axios
    .get(Endpoints.search.functionalities)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getFunctionalitiesSearch = (input: string) => {
  return axios
    .get(Endpoints.search.functionalitiesSearch, {
      params: {
        input: input,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getFunctionalitiesHelp = ({ search, type }) => {
  return axios
    .get(Endpoints.search.functionalitiesHelp, {
      params: {
        search,
        type,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export default {
  getFunctionalities: { call: getFunctionalities } as IApiCall,
  getFunctionalitiesSearch: { call: getFunctionalitiesSearch } as IApiCall,
  getFunctionalitiesHelp: { call: getFunctionalitiesHelp } as IApiCall,
};
