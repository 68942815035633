/**
 * Schedules scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { put, putResolve, all, call, takeLatest, select } from 'redux-saga/effects';

import { t } from 'app';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';
import * as mandatoryDropdownSelectors from '../containers/MandatoryOptionsDropdown/logic/selectors';
import * as freeDropdownSelectors from '../containers/FreeOptionsDropdown/logic/selectors';

import API from 'api';

import { getCurrentRoute } from 'utils';
import {
  reformatUcs,
  setClassesTypologies,
  getScheduleSelectedClasses,
} from 'scenes/Schedules/MySchedule/utils';

import { getScheduleInterval } from 'scenes/Schedules/utils';

import { IUc, ICreatedSchedule } from 'shared/types';

import ErrorHandler from 'shared/errorHandler';

interface IGetRegistrationSchedule {
  registrationId: number;
  schedule?: any;
}

interface IEditSchedule {
  type: 'Schedules/editSchedule';
  payload: number;
}
interface IDeleteScheduleSaga {
  type: 'Schedules/deleteSchedule';
  payload: number;
}

function* onMountSaga() {
  try {
    yield put(actions.Schedules.setLoadingSchedule(true));
    yield putResolve(actions.Schedules.reset());
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
    //it was commented to remove a flash apeearing in the page
    yield put(actions.Schedules.setLoadingSchedule(false));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setRestriction(true));
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetPhase'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onPhaseUpdateSaga() {
  yield putResolve(actions.Schedules.setLoadingRestriction(true));
  yield put(actions.Schedules.getAccessAndUcs());
}

function* getAccessAndUcsSaga() {
  try {
    yield putResolve(actions.Schedules.setRestriction(null));
    yield put(actions.Schedules.setLoadingRestriction(true));

    if (
      getCurrentRoute().route.key === 'mySchedule' ||
      getCurrentRoute().route.key === 'createSchedule'
    ) {
      const registration = yield select(selectors.getRegistration);
      
      const phases = yield call(API.sgh.getPhases.call);
      const {
        name,
        year,
        period,
        finished,
        type,
        hasAccess,
        motive,
        seriationFinished,
        hasAllocatedSchedule,
        attemptedSchedule,
      } = yield call(API.sgh.getStudentAccess.call, {
        registrationId: registration,
      });

      
      const isCurrentPhaseActive = phases.find(p => { return p.period === period && p.name === name})?.isActive ?? false
      yield put(actions.Schedules.setIsCurrentPhaseActive(isCurrentPhaseActive))

      // attemptedSchedule save if user attempted to create a schedule last time phases were open
      // This will allow us to display a more specific/significant message for the lack of schedule
      yield put(
        actions.Schedules.setCurrentPhase({
          phase: name,
          year: year,
          period: period,
          isFree: type === 'queue',
          finished,
          hasAllocatedSchedule,
          attemptedSchedule,
          seriationFinished,
        })
      );

      if (!hasAccess) {
        // If the access is not restricted, this action will, basically, tell the loader to finish its animation
        // and, when its finished, the loader callback will dispatch 'mySchedulesSaga' to continue the flow
        yield put(actions.Schedules.setRestriction(!hasAccess));
        yield put(actions.Schedules.setRestrictionMotive(motive));
        yield put(actions.Schedules.setLoadingSchedule(false));
      } else {
        yield* getCurrentModeSaga();
      }
    } else {
      yield* getCurrentModeSaga();
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setRestriction(true));
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetPhase'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(actions.Schedules.setLoadingRestriction(false));
  }
}

function* getCurrentModeSaga() {
  if (
    getCurrentRoute().route.key === 'mySchedule' ||
    getCurrentRoute().route.key === 'createSchedule'
  ) {
    const registration = yield select(selectors.getRegistration);
    if (getCurrentRoute().route.key === 'mySchedule') {
      yield putResolve(actions.Schedules.setCurrentMode('view'));
    } else {
      yield putResolve(actions.Schedules.setCurrentMode('create'));
    }
    yield* getRegistrationScheduleSaga({
      registrationId: registration,
    });
  } else if (getCurrentRoute().route.key === 'editSchedule') {
    yield putResolve(actions.Schedules.setCurrentMode('edit'));
    yield* handleScheduleEditOrDuplicateModeSaga();
  } else if (getCurrentRoute().route.key === 'duplicateSchedule') {
    yield putResolve(actions.Schedules.setCurrentMode('duplicate'));
    yield* handleScheduleEditOrDuplicateModeSaga();
  }
}

function* handleScheduleEditOrDuplicateModeSaga() {
  const scheduleIdToEdit = getCurrentRoute().params.id;
  const registration = yield select(selectors.getRegistration);

  let scheduleToEdit: ICreatedSchedule | null = null;

  try {
    scheduleToEdit = yield call(API.sgh.getSchedule.call, scheduleIdToEdit, registration);

    if (scheduleToEdit) {
      if (getCurrentRoute().route.key === 'editSchedule') {
        yield putResolve(actions.Schedules.setScheduleName(scheduleToEdit.name));
      }

      yield putResolve(
        actions.StudentRegistrationDropdown.setRegistration(scheduleToEdit.registrationId)
      );

      const {
        name,
        year,
        period,
        finished,
        type,
        motive,
        hasAccess,
        hasAllocatedSchedule,
        attemptedSchedule,
        seriationFinished,
      } = yield call(API.sgh.getStudentAccess.call, {
        registrationId: scheduleToEdit.registrationId,
      });

      // attemptedSchedule save if user attempted to create a schedule last time phases were open
      // This will allow us to display a more specific/significant message for the lack of schedule
      yield put(
        actions.Schedules.setCurrentPhase({
          phase: name,
          year: year,
          period: period,
          isFree: type === 'queue',
          finished,
          hasAllocatedSchedule,
          attemptedSchedule,
          seriationFinished,
        })
      );

      if (!hasAccess) {
        // If the access is not restricted, this action will, basically, tell the loader to finish its animation
        // and, when its finished, the loader callback will dispatch 'mySchedulesSaga' to continue the flow
        yield put(actions.Schedules.setRestriction(!hasAccess));
        yield put(actions.Schedules.setRestrictionMotive(motive));
      } else {
        yield put(
          actions.Breadcrumb.overridePath({ key: 'editschedules', name: scheduleToEdit.name })
        );

        // Ensure that schedule grid/list are both clean
        yield put(actions.Schedules.clearSchedule());

        yield* getRegistrationScheduleSaga({
          registrationId: scheduleToEdit.registrationId,
          schedule: scheduleToEdit,
        });

        yield put(actions.Schedules.setScheduleToEdit(scheduleToEdit));
      }
    }
    yield put(actions.Schedules.setLoadingSchedule(false));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetSchedule'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getRegistrationScheduleSaga(action: IGetRegistrationSchedule) {
  const currentPhase = yield select(selectors.getCurrentPhase);
  const { currentMode, scheduleGridInterval } = yield select(selectors.getSchedulesSlice);

  try {
    yield put(actions.Schedules.setLoadingSchedule(true));

    //this is called when changing registration id or when seriation updates. Therefore registrationID can be different
    const registration = action.registrationId || (yield select(selectors.getRegistration));
    let ucs;
    if (action.schedule !== null && action.schedule !== undefined) {
      ucs = {
        normal: action.schedule?.normal,
        mandatory: action.schedule?.mandatory,
        free: action.schedule?.free,
      };
    } else {
      ucs = yield call(API.sgh.getUcs.call, {
        registrationId: registration,
      });
    }

    const { mandatoryUcs, freeUcs, freeOptions, allUcs } = reformatUcs(
      ucs,
      currentMode === 'view' ||
        currentPhase?.finished ||
        (!currentPhase.phase && !currentPhase?.finished)
    );

    const unfilteredUcsToCount = reformatUcs(
      ucs,
      currentPhase?.finished || (!currentPhase.phase && !currentPhase?.finished)
    );

    const typologies = setClassesTypologies(ucs);
    yield put(actions.MandatoryOptionsDropdown.setMandatoryOptions(mandatoryUcs));
    yield put(actions.FreeOptionsDropdown.setFreeOptions({ ucs: freeUcs, options: freeOptions }));
    yield putResolve(actions.Schedules.setUcs(allUcs));
    yield putResolve(actions.Schedules.setUnfilteredUcs(unfilteredUcsToCount));
    yield putResolve(actions.Schedules.setMandatoryUcs(mandatoryUcs));
    yield putResolve(actions.Schedules.setFreeUcs(freeUcs));
    yield putResolve(actions.Schedules.setFreeOptionsGroups(freeOptions));

    yield put(
      actions.Schedules.setScheduleGridInterval(getScheduleInterval(allUcs, scheduleGridInterval))
    );
    //yield put(actions.Schedules.setPeriods(response.weeks || []));
    yield put(actions.Schedules.setClassesTypologies(typologies));
    //yield put(actions.Schedules.setPeriod(response.weeks ? response.weeks[0] : null));
    yield put(actions.Schedules.setCreatedSchedules(ucs.schedulesNumber));

    //Set a default translated scheduleName
    if (currentPhase?.phase && !currentPhase?.finished) {
      if (getCurrentRoute().route.key === 'createSchedule') {
        yield put(
          actions.Schedules.setScheduleName(
            `${t('sgh.schedule', { textOnly: true })} ${parseInt(ucs.schedulesNumber) + 1}`
          )
        );
      }
      if (getCurrentRoute().route.key === 'duplicateSchedule') {
        let copiedScheduleName = `${t('sgh.schedule', { textOnly: true })} ${
          parseInt(action?.schedule?.schedulesNumber) + 1
        }`;
        yield put(actions.Schedules.setScheduleName(copiedScheduleName));
      }
    }

    // According to currentPhase we should show freeOptions and open grid mode to exchange classes
    // Ucs need to be filtered according to allocated value, in order to use them for the exchange class grid
    yield put(actions.Schedules.setLoadingSchedule(false));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setUcs([]));
      yield put(actions.Schedules.setSchedulesNumber(0));
      yield put(actions.Schedules.setPeriods([]));
      yield put(actions.Schedules.setClassesTypologies([]));
      yield put(actions.Schedules.setPeriod(''));
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetUcs'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* filterClassesByPeriodSaga() {
  const { ucs, period } = yield select(selectors.getSchedulesSlice);

  const filteredUcs = ucs.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        if (eachClass.period && eachClass.period !== period) {
          return { ...eachClass, filtered: true };
        } else {
          return { ...eachClass, filtered: false };
        }
      }),
    };
  });

  yield put(actions.Schedules.setUcs(filteredUcs));
}

function* getStudentTotalChoicesSaga() {
  const { ucs, unfilteredUcs } = yield select(selectors.getSchedulesSlice);
  const mandatoryOptions = yield select(mandatoryDropdownSelectors.getMandatoryOptions);
  const freeOptions = yield select(freeDropdownSelectors.getFreeOptions);

  let totalChoices = 0;

  if (mandatoryOptions?.options) {
    mandatoryOptions.options.forEach((mandatoryOption) => {
      if (mandatoryOption?.selected !== null) {
        totalChoices += mandatoryOption.ucs[mandatoryOption.selected].ucTotalChoices;
      } else {
        totalChoices++;
      }
    });
  }

  if (freeOptions?.options) {
    freeOptions.options.forEach((freeOption) => {
      if (freeOption?.selected !== null) {
        totalChoices += freeOption.ucs[freeOption.selected].ucTotalChoices || 1;
      } else {
        totalChoices++;
      }
    });
  }

  if (unfilteredUcs.normalUcs) {
    totalChoices =
      unfilteredUcs.normalUcs
        .filter((uc) => !uc.classSchedule.every((classItem) => classItem.filtered))
        .reduce((acc: number, uc: IUc) => {
          return acc + (uc.ucTotalChoices || 0);
        }, 0) + totalChoices;
  }

  yield put(actions.Schedules.setStudentTotalChoices(totalChoices));
}

function* submitScheduleSaga() {
  const { ucs, scheduleName, createdSchedules } = yield select(selectors.getSchedulesSlice);
  const registration = yield select(selectors.getRegistration);

  try {
    yield put(actions.Schedules.setLoadingSchedule(true));
    yield put(actions.Schedules.setSavingSchedule(true));

    if (createdSchedules >= 30) {
      throw new Error('ERR_SGH_SCHEDULE_MAX');
    }

    const selectedClasses = getScheduleSelectedClasses(ucs, true);

    yield call(API.sgh.postSchedule.call, {
      data: {
        name: scheduleName,
        registrationId: registration,
        classes: selectedClasses,
      },
    });
    // Show success toast
    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionSuccessAddSchedule'),
        icon: 'check',
        type: 'success',
      })
    );

    yield put(actions.App.navigateTo({ key: 'createdSchedules', forceRefresh: true }));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (
        e?.message === 'ERR_SGH_SCHEDULE_MAX' ||
        e?.response?.data?.errcode === 'ERR_SGH_SCHEDULE_MAX'
      ) {
        yield put(
          actions.Toaster.showToaster({
            title: t('sgh.actionErrorAddScheduleMax'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('sgh.actionErrorAddSchedule'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }

    yield put(actions.Schedules.setLoadingSchedule(false));
    yield put(actions.Schedules.setSavingSchedule(false));
  }
}

function* editScheduleSaga(action: IEditSchedule) {
  const { ucs, scheduleName } = yield select(selectors.getSchedulesSlice);
  const registration = yield select(selectors.getRegistration);

  try {
    yield put(actions.Schedules.setLoadingSchedule(true));
    yield put(actions.Schedules.setSavingSchedule(true));
    yield put(actions.Schedules.setEditingSchedule(true));
    const selectedClasses = getScheduleSelectedClasses(ucs, true);

    yield call(API.sgh.patchSchedule.call, {
      registrationId: registration,
      schedules: [
        {
          id: action.payload,
          name: scheduleName,
          classes: selectedClasses,
        },
      ],
    });

    // Show success toast
    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionSuccessUpdateSchedule'),
        icon: 'check',
        type: 'success',
      })
    );

    yield put(actions.App.navigateTo({ key: 'createdSchedules' }));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setEditingSchedule(false));

      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorUpdateSchedule'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(actions.Schedules.setEditingSchedule(false));
    yield put(actions.Schedules.setLoadingSchedule(false));
    yield put(actions.Schedules.setSavingSchedule(false));
  }
}

function* editScheduleNameSaga(action) {
  try {
    if (getCurrentRoute().route.key === 'editSchedule') {
      const scheduleName = action.payload;
      const { scheduleToEdit } = yield select(selectors.getSchedulesSlice);

      if (scheduleToEdit && scheduleName !== scheduleToEdit.name) {
        const registration = yield select(selectors.getRegistration);

        yield call(API.sgh.patchSchedule.call, {
          registrationId: registration,
          schedules: [
            {
              id: scheduleToEdit.id,
              name: scheduleName,
            },
          ],
        });

        // Show success toast
        yield put(
          actions.Toaster.showToaster({
            title: t('sgh.actionSuccessUpdateSchedule'),
            icon: 'check',
            type: 'success',
          })
        );
      }
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorUpdateSchedule'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* deleteScheduleSaga(action: IDeleteScheduleSaga) {
  try {
    const id = action.payload;
    const registration = yield select(selectors.getRegistration);

    yield put(actions.Schedules.setSavingSchedule(true));
    yield put(actions.Schedules.setLoadingSchedule(true));

    yield call(API.sgh.deleteSchedule.call, [{ scheduleId: id, registrationId: registration }]);

    yield put(actions.App.navigateTo({ key: 'createdSchedules' }));

    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionSuccessUpdateSchedule_plural'),
        icon: 'check',
        type: 'success',
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorRemoveSchedule'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(actions.Schedules.setSavingSchedule(false));
    yield put(actions.Schedules.setLoadingSchedule(false));
  }
}

export default function* watcherSignin() {
  yield takeLatest('Schedules/onMount', onMountSaga);
  yield takeLatest('Schedules/onPhaseUpdate', onPhaseUpdateSaga);
  yield takeLatest('Schedules/getAccessAndUcs', getAccessAndUcsSaga);
  yield takeLatest('Schedules/getCurrentMode', getCurrentModeSaga);
  yield takeLatest(
    'Schedules/handleScheduleEditOrDuplicateMode',
    handleScheduleEditOrDuplicateModeSaga
  );
  yield takeLatest('Schedules/setPeriod', filterClassesByPeriodSaga);
  yield takeLatest('Schedules/getStudentTotalChoices', getStudentTotalChoicesSaga);
  yield takeLatest('Schedules/setMandatoryOptions', getStudentTotalChoicesSaga);
  yield takeLatest('Schedules/removeMandatoryOptions', getStudentTotalChoicesSaga);
  yield takeLatest('Schedules/submitSchedule', submitScheduleSaga);
  yield takeLatest('Schedules/editSchedule', editScheduleSaga);
  yield takeLatest('Schedules/setScheduleName', editScheduleNameSaga);
  yield takeLatest('Schedules/deleteSchedule', deleteScheduleSaga);
}
