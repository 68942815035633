/**
 * NewApplicationSelectType container logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getNewApplicationSlice = (state: RootState) => state.NewApplication;
