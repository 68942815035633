/**
 * ExamsCalendar scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getExamsCalendarSlice = (state: RootState) => state.ExamsCalendar;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getUser = (state: RootState) => state.App.user;
