/**
 * NewApplication scene utils
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

export const componentTemplateParser = (
  expoFields,
  expression,
  valueObj,
  currentlyInStatementTab: boolean
) => {
  // expression = text with parameters

  // match any sequence of <p>'s and  remove any surronding spaces of each row
  const newLineMatcher = /(<p>\1{0,})/g;
  let expressionLines: string[] = [];

  // list of expression lines to be rendered
  if (expression && !!expression.length) {
    expression.split(newLineMatcher).map((item: string) => {
      if (item !== '') {
        expressionLines.push(item.trim());
      }
    });
  }

  // matches {{varname}} ou {{varname}}, ou {{varname}}.
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}(\.|,)?/g;

  const result: any = [];

  let paragraphSequenceCounter = 0;
  expressionLines.forEach((line) => {
    const splittedLine = line.split(templateMatcher);
    let trimmedSplittedLine: string[] = [];

    // remove empty elements
    splittedLine.forEach((item: string) => {
      if (item && item !== '') {
        trimmedSplittedLine.push(item.trim());
      }
    });

    if (line === '<p>') {
      paragraphSequenceCounter++;
    } else {
      if (paragraphSequenceCounter > 1) {
        result.push(<Paragraph height={`${(paragraphSequenceCounter - 1) * 10}px`} />);
      }

      // reset counter
      paragraphSequenceCounter = 0;

      // large text boxes occupy an entire line, so they are always the first element of the line
      const largeTextBoxCandidate = expoFields.find(
        (element) => element.nameKey === trimmedSplittedLine[0]
      );

      result.push(
        <MarkdownContainer
          isLargeTextBox={
            largeTextBoxCandidate ? largeTextBoxCandidate.type === 'largetextbox' : false
          }
        >
          {trimmedSplittedLine.map((piece, index) => {
            if (valueObj[piece]) {
              const textBoxCandidate = expoFields.find((element) => element.nameKey === piece);

              return (
                <ValueObjWrapper
                  isTextBox={textBoxCandidate && textBoxCandidate.type === 'textbox' ? true : false}
                  currentlyInStatementTab={currentlyInStatementTab}
                >
                  {valueObj[piece]}
                </ValueObjWrapper>
              );
            } else {
              return (
                <MarkdownWrapper>
                  <ReactMarkdown
                    key={`markdown-${index}`}
                    source={piece}
                    renderers={{ paragraph: 'div' }}
                  />
                </MarkdownWrapper>
              );
            }
          })}
        </MarkdownContainer>
      );
    }
  });

  return result;
};

export const stringTemplateParser = (expression, valueObj) => {
  const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;
  let text = expression.replace(templateMatcher, (substring, value, index) => {
    value = valueObj[value];
    return value;
  });
  return text;
};

const getExpoFields = (expoFieldsValues, data) => {
  return expoFieldsValues.map((expoFieldsField) => {
    const targetExpoFieldValues = expoFieldsValues.find(
      (valueField) => valueField === expoFieldsField
    );
    return {
      nameKey: expoFieldsField.nameKey,
      value:
        (targetExpoFieldValues && targetExpoFieldValues.value) ||
        (targetExpoFieldValues &&
          targetExpoFieldValues.type === 'application_dropdown' &&
          targetExpoFieldValues.values.find(
            (property) => property.valueId === data[expoFieldsField.nameKey]
          ) &&
          targetExpoFieldValues.values.find(
            (property) => property.valueId === data[expoFieldsField.nameKey]
          ).nameKey) ||
        data[expoFieldsField.nameKey],
    };
  });
};

const getMandatoryDocuments = (mandatoryDocumentsValues, data) => {
  return mandatoryDocumentsValues.reduce((acc, mandatoryDocumentField) => {
    if (mandatoryDocumentField.type === 'group' && data[mandatoryDocumentField.nameKey]) {
      if (
        Object.keys(data).some(
          (dataField) => data[dataField] && dataField.includes(mandatoryDocumentField.nameKey)
        )
      ) {
        Object.keys(data[mandatoryDocumentField.nameKey]).forEach((itemNameKey) => {
          const itemValue = data[mandatoryDocumentField.nameKey][itemNameKey];

          //get each mandatory document doctypeID
          const docTypeId = mandatoryDocumentField.values?.find(
            (doc) => doc.nameKey === itemNameKey
          )?.docTypeId;

          if (itemValue !== null && itemValue !== undefined) {
            acc.push({
              ...itemValue,
              ...(docTypeId ? { docTypeId } : null),
            });
          }
        });
      }
    } else {
      if (
        Object.keys(data).some(
          (dataField) =>
            data[dataField] &&
            data[dataField].nameKey &&
            data[dataField].nameKey.includes(mandatoryDocumentField.nameKey)
          // some namekeys are equal but have more spaces, so this condition might not work everytime
        )
      ) {
        if (data[mandatoryDocumentField.nameKey]) {
          //add docTypeId that comes from mandatoryDocumentField here
          acc.push({
            ...data[mandatoryDocumentField.nameKey],
            ...(mandatoryDocumentField?.docTypeId
              ? { docTypeId: mandatoryDocumentField?.docTypeId }
              : null),
          });
        }
      }
    }

    return acc;
  }, []);
};

const getAdditionalDocuments = (additionalDocumentsValues, data) => {
  return additionalDocumentsValues.reduce((acc, additionalDocumentField) => {
    const foundNamekey = Object.keys(data).some(
      (dataField) =>
        data[dataField] &&
        data[dataField].nameKey &&
        data[dataField].nameKey.includes(additionalDocumentField.nameKey)
    );

    if (
      foundNamekey &&
      data[additionalDocumentField.nameKey] !== undefined &&
      data[additionalDocumentField.nameKey] !== null
    ) {
      //add docTypeId that comes from additionalDocumentField here
      acc.push({
        ...data[additionalDocumentField.nameKey],
        docTypeId: additionalDocumentField?.docTypeId,
      });
    }

    return acc;
  }, []);
};

export const getFormValues = (form, data) => {
  const { exposition, expoFields, mandatoryDocuments, additionalDocuments } = form;

  return {
    statement: exposition.text,
    expoFields: getExpoFields(expoFields.values, data),
    observations: data.observations,
    mandatoryDocuments: getMandatoryDocuments(mandatoryDocuments.values, data),
    additionalDocuments: getAdditionalDocuments(additionalDocuments.values, data),
  };
};

const MarkdownWrapper = styled.div`
  white-space: pre-wrap;
  display: inline-block;
  margin-right: 8px;
  line-height: 1.3;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const MarkdownContainer = styled.div<{ isLargeTextBox: boolean }>`
  > div {
    ${({ isLargeTextBox }) => isLargeTextBox && 'width: 100%'};
  }
`;

const Paragraph = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;

const ValueObjWrapper = styled.div<{ isTextBox: boolean; currentlyInStatementTab?: boolean }>`
  display: inline-block;

  > div {
    ${({ isTextBox, currentlyInStatementTab }) =>
      isTextBox && currentlyInStatementTab && 'width: 600px'};

    ${({ isTextBox, currentlyInStatementTab }) =>
      isTextBox &&
      currentlyInStatementTab &&
      `@media (max-width: 1240px) {
      width: 500px;
    }`}

    ${({ isTextBox, currentlyInStatementTab }) =>
      isTextBox &&
      currentlyInStatementTab &&
      `@media (max-width: 992px) {
      width: 400px;
    }`}

    ${({ isTextBox, currentlyInStatementTab }) =>
      isTextBox &&
      currentlyInStatementTab &&
      `@media (max-width: 767px) {
      width: 300px;
    }`}
  }
`;
