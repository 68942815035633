/**
 * ApplicationDetails scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEllipsisH } from '@fortawesome/pro-light-svg-icons';

import moment from 'moment';

import { Segment, Text, StatusLabel } from '@paco_ua/pacoui';

import FileDownloader from 'shared/components/FileDownloader';

import Header from 'shared/containers/TitleHeader';
import PageStructure from 'shared/containers/PageStructure';

import { textParser } from './utils';

import API from 'api';

import config from 'config';

const ApplicationDetails = ({ t }) => {
  const theme = useContext(ThemeContext);

  const { onMount, onUnmount, getStatusAndDetails } = actions.ApplicationDetails;
  //const { api_t } = actions.App

  const dispatch = useDispatch();

  const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

  const { loadingStatus, errorStatus } = useSelector(selectors.getApplicationDetailsSlice);
  const status = useSelector(selectors.getStatus);
  const details = useSelector(selectors.getDetails);
  const currentRegistrationId = useSelector(selectors.getRegistration);
  const user = useSelector(selectors.getUser);

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    setInitialLoading(true);
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, user.impersonate]);

  useEffect(() => {
    if (currentRegistrationId) {
      dispatch(getStatusAndDetails());
    }
  }, [currentRegistrationId /*, currentLocale*/]);

  useEffect(() => {
    if (initialLoading && !loadingStatus.loading) {
      setInitialLoading(false);
    }
  }, [loadingStatus.loading]);

  const APPLICATION_STATUS = {
    NC: {
      name: t('applications.listTableStatusUnknown', { textOnly: true }),
      color: theme.colors.regularGrey,
    },
    SBMT: {
      name: t('applications.listTableStatusDelivered', { textOnly: true }),
      color: theme.colors.successGreen,
    },
    ANL: {
      name: t('applications.listTableStatusAnalysis', { textOnly: true }),
      color: theme.colors.decisionYellow,
    },
    PROCSA: {
      name: t('applications.listTableStatusResponseSent', { textOnly: true }),
      color: theme.colors.successGreen,
    },
    CANC: {
      name: t('applications.listTableStatusCanceled', { textOnly: true }),
      color: theme.colors.dangerRed,
    },
  };

  return (
    <PageStructure
      loading={loadingStatus.loading || errorStatus.errLoading}
      headerLeft={
        <Header pullBackButton backButton title={t('applications.applicationDetailsTitle')} />
      }
      mainContent={
        <>
          {status && (
            <Segment>
              <Text weight={'regular'} size="large" color={theme.colors.plusDarkGrey}>
                {t('applications.detailsStatusTitle')}
              </Text>
              <StatusAndDate>
                <DateBlock>
                  <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                    {t('applications.reference')}
                  </Text>
                  <InfoText weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                    {status.ref && status.ref !== '' ? (
                      status.ref
                    ) : (
                      <FontAwesomeIcon icon={faEllipsisH} />
                    )}
                  </InfoText>
                </DateBlock>
                <DateBlock>
                  <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                    {t('applications.detailsStatusLabel')}
                  </Text>
                  <StatusLabelWrapper>
                    <StatusLabel
                      label={APPLICATION_STATUS[status.state].name}
                      background={APPLICATION_STATUS[status.state].color}
                    />
                  </StatusLabelWrapper>
                </DateBlock>
                <DateBlock>
                  <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                    {t('applications.detailsDateRequest')}
                  </Text>
                  <InfoText weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                    {status.requestDate ? (
                      moment(status.requestDate).format('DD/MM/YYYY')
                    ) : (
                      <FontAwesomeIcon icon={faEllipsisH} />
                    )}
                  </InfoText>
                </DateBlock>
                <DateBlock>
                  <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                    {t('applications.detailsDateAnswer')}
                  </Text>
                  <InfoText weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                    {status.responseDate ? (
                      moment(status.responseDate).format('DD/MM/YYYY')
                    ) : (
                      <FontAwesomeIcon icon={faEllipsisH} />
                    )}
                  </InfoText>
                </DateBlock>
              </StatusAndDate>

              <StatusAnswer>
                <Text size="xSmall" weight={'regular'} color={theme.colors.grey}>
                  {t('applications.detailsAnswer')}
                </Text>
                <ResponseText
                  weight={'regular'}
                  size="mediumSmall"
                  color={theme.colors.plusDarkGrey}
                  hasText={status.response !== '' && status.response ? true : false}
                >
                  {status.response && status.response !== '' ? (
                    status.response
                  ) : (
                    <FontAwesomeIcon icon={faEllipsisH} />
                  )}
                </ResponseText>
              </StatusAnswer>
            </Segment>
          )}

          {details && (
            <DetailsSegmentWrapper>
              <Segment>
                <Text weight={'regular'} size="large" color={theme.colors.plusDarkGrey}>
                  {t('applications.detailsDetailsTitle')}
                </Text>
                <DetailsNameAndCourse>
                  <DateBlock>
                    <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                      {t('applications.detailsDetailsStudentName')}
                    </Text>
                    <TextWrapper>
                      <Text weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                        {details.studentName}
                      </Text>
                    </TextWrapper>
                  </DateBlock>
                  <DateBlock>
                    <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                      {t('applications.detailsDetailsCourse')}
                    </Text>
                    <TextWrapper>
                      <Text weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                        {details.course}
                      </Text>
                    </TextWrapper>
                  </DateBlock>
                </DetailsNameAndCourse>
                <DetailsApplicationCategory>
                  <CategoryBlock>
                    <Text weight={'regular'} size="xSmall" color={theme.colors.grey}>
                      {t('applications.detailsDetailsCategory')}
                    </Text>
                    <CategoryText>
                      <Text weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                        {status && status.type ? (
                          status.theme ? (
                            `${status.theme} - ${status.type}`
                          ) : (
                            `${status.type}`
                          )
                        ) : (
                          <FontAwesomeIcon icon={faEllipsisH} />
                        )}
                      </Text>
                    </CategoryText>
                  </CategoryBlock>
                </DetailsApplicationCategory>
                {details.statement && (
                  <DetailsStatement>
                    <Text size="medium" color={theme.colors.primary} fontWeight="500">
                      {t('applications.detailsStatementTitle')}
                    </Text>
                    <div>{textParser(details.statement)}</div>
                    {/*<TextWrapper>
                      <Text weight={'regular'} size="mediumSmall" color={theme.colors.plusDarkGrey}>
                        {details.statement}
                      </Text>
                    </TextWrapper>*/}
                  </DetailsStatement>
                )}
                {details.documents.length > 0 && (
                  <FileDownloaderWrapper>
                    <Text size="medium" color={theme.colors.plusDarkGrey} fontWeight="500">
                      {t('applications.detailsFileDownloaderTile')}
                    </Text>
                    {details.documents.map((document, index) => {
                      return (
                        <FileContainer usePadding={!!index} key={`file-container-${index}`}>
                          <FileDownloader
                            url={`${API_SEC_VIRTUAL}${document.url}`}
                            heading={document.name}
                            description={document.description ? document.description : undefined}
                            icon={<FontAwesomeIcon icon={faFilePdf} />}
                            callToEndpoint={(endpoint, setLoading: (value) => void) => {
                              setLoading(true);

                              API.files.getFileOnTab
                                .call(endpoint)
                                .then((value) => {
                                  setLoading(false);
                                })
                                .catch((e) => {
                                  setLoading(false);
                                  dispatch(
                                    actions.Toaster.showToaster({
                                      title: t('secVirtualNotifications.general_errorLoadingFile'),
                                      icon: 'error',
                                      type: 'danger',
                                    })
                                  );
                                });
                            }}
                          />
                        </FileContainer>
                      );
                    })}
                  </FileDownloaderWrapper>
                )}
              </Segment>
            </DetailsSegmentWrapper>
          )}
        </>
      }
    />
  );
};

export default ApplicationDetails;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const DetailsSegmentWrapper = styled.div`
  margin-top: 32px;
`;

const StatusAndDate = styled.div`
  display: flex;
  margin-top: 13px;

  & > div:not(:first-child) {
    margin-left: 40px;
  }
`;

const DateBlock = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: 12px;
  }
`;

const FileContainer = styled.div<{ usePadding: boolean }>`
  padding-top: ${({ usePadding }) => (usePadding ? '12px' : 'None')};
`;

const CategoryBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusAnswer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 28px;

  & > div:nth-child(2) {
    margin-top: 12px;
  }
`;

const StatusLabelWrapper = styled.div`
  display: flex;
`;

const DetailsNameAndCourse = styled.div`
  display: flex;
  margin-top: 13px;

  & > div {
    flex: 1;
  }
`;

const DetailsApplicationCategory = styled.div`
  margin-top: 28px;
`;

const DetailsStatement = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  & > div:nth-child(2) {
    margin-top: 12px;
  }
`;

const FileDownloaderWrapper = styled.div`
  margin-top: 48px;

  & > div:nth-child(2) {
    margin-top: 12px;
  }
`;

const DocumentSegmentWrapper = styled.div`
  margin-top: 32px;
`;

const TextWrapper = styled.div`
  line-height: 1.3;
`;

const CategoryText = styled.div`
  margin-top: 12px;
  line-height: 1.3;
`;

const InfoText = styled(Text)`
  line-height: 1.5;
`;

const ResponseText = styled(Text)<{ hasText: boolean }>`
  margin-top: ${({ hasText }) => (hasText ? '14px' : '16px')};
  line-height: 1.3;
`;
