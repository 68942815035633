/**
 * SeriationHistoryDetails scene logic selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getSeriationHistoryDetailsSlice = (state: RootState) => state.SeriationHistoryDetails;
