/**
 * FileUploader component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState, useEffect, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { useDropzone } from 'react-dropzone';
import { actions } from 'store/rootSlices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowFromBottom, faTrash, faPlusCircle } from '@fortawesome/pro-light-svg-icons';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

import { Progress } from 'semantic-ui-react';

import { t } from 'app';
import API from 'api';

import { Text, Button } from '@paco_ua/pacoui';

import config from 'config';

interface IFileUploader {
  nameKey: string;
  register?: any;
  heading?: string;
  description?: string;
  icon?: JSX.Element;
  studentId?: number;
  onChange: (
    value:
      | { id: string; nameKey: string; url: string; fileName: string; nBytes: number }
      | undefined
  ) => void;
  registrationId?: number;
  minSize?: number; // in bytes
  maxSize?: number; // in bytes
  accept: string | string[];
  error: string;
  errorGroup: string;
  defaultValue: any;
  removable?: boolean;
  onRemove?: () => {};
  onRemoveField?: () => {};
  enableRemoveField?: boolean;
  value: any;
  setUploadingFlag?: (nameKey: string, value: boolean) => void;
  onError?: (string) => void;
}
interface IUploadedFiles {
  file?: any;
  name?: string;
  progress?: number;
  finished: boolean;
  error?: boolean;
  size?: number;
}

const FileUploader: React.FC<IFileUploader> = ({
  nameKey,
  heading,
  description,
  icon,
  studentId,
  registrationId,
  onChange,
  minSize,
  maxSize,
  accept,
  error,
  errorGroup,
  onRemoveField,
  enableRemoveField,
  defaultValue,
  removable,
  value,
  setUploadingFlag,
  onRemove,
  onError,
}) => {
  const theme = useContext(ThemeContext);
  const form = useFormContext();

  const didUpdateValue = useRef(false);

  const [uploadedFiles, setUploadedFiles] = useState([] as IUploadedFiles[]);

  const [uploadedSucceeded, setUploadedSuccess] = useState(null as null | boolean);

  const [disableDropzone, setDisableDropzone] = useState(false as boolean);
  const [dropzoneActiveColor, setDropzoneActiveColor] = useState(theme.colors.white as string);

  const [showFileLoading, setShowFileLoading] = useState(false);

  const [errorToDisplay, setErrorToDisplay] = useState(null as null | string);

  const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

  const dispatch = useDispatch();

  const DEFAULT_FILE_SIZES = {
    MIN_SIZE: 1,
    MAX_SIZE: 10485760,
  };

  // update the uploadedFiles list, knowing that the file upload has finished
  useEffect(() => {
    // if file upload was successfull
    if (uploadedSucceeded) {
      setUploadedFiles((prevState) =>
        prevState.map((file) => {
          // if the current iteration's file hasn't finished, than
          // it is the most recently uploaded file
          if (!file.finished) {
            return { ...file, finished: true, error: false };
          } else {
            return file;
          }
        })
      );
    } else {
      // if an error occured while uploading the file
      // create a toaster notification to inform the user and remove the
      // the entry from the uploadedFiles list
      setUploadedFiles((prevState) => {
        let newList = [] as any;

        prevState.map((file) => {
          if (!file.finished) {
            // reset
            setUploadedSuccess(null);
          } else {
            newList.append(file);
          }
        });

        return newList;
      });
    }
  }, [uploadedSucceeded]);

  useEffect(() => {
    onError && onError(errorGroup);
  }, [errorGroup]);

  const bytesToMegabytes = (nBytes: number) => {
    // if less than 100 KB, present the file size in KB
    if (nBytes < 1024 * 100) {
      return `${(nBytes / 1024).toFixed(2)} KB`;
    } else {
      return `${(nBytes / 1024 / 1024).toFixed(2)} MB`;
    }
  };

  useEffect(() => {
    if (value) {
      if (!didUpdateValue.current) {
        didUpdateValue.current = true;
      }
      setUploadedFiles([
        {
          name: value.fileName,
          size: value.nBytes,
          progress: 100,
          finished: true,
        },
      ]);
    } else {
      setUploadedFiles([]);
    }
    if (didUpdateValue.current) {
      form?.trigger && form.trigger(nameKey);
    }
  }, [value]);

  const { getRootProps, open, getInputProps, fileRejections } = useDropzone({
    noClick: true,
    disabled: disableDropzone,
    multiple: false,
    minSize: minSize ? minSize : DEFAULT_FILE_SIZES.MIN_SIZE,
    maxSize: maxSize ? maxSize : DEFAULT_FILE_SIZES.MAX_SIZE,
    accept,
    onDrop(files) {
      // garantie that the number of dropped files is not greater than 1.
      if (files.length !== 1 || uploadedFiles.length === 1 || uploadedSucceeded !== null) {
        return;
      }

      // reset
      setErrorToDisplay(null);

      setDisableDropzone(true);

      setUploadingFlag && setUploadingFlag(nameKey, true);

      const data = new FormData();
      files.forEach((file, index) => {
        data.append('files', file);
      });

      API.files.postFiles
        .call(studentId, registrationId, data, {
          onUploadProgress: (progressEvent) => {
            const percentageCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadedFiles([
              ...files.map((file) => {
                return {
                  name: file.name,
                  size: file.size,
                  progress: percentageCompleted,
                  finished: false,
                };
              }),
            ]);
          },
        })
        .then((data) => {
          if (data.length === 0) {
            throw {
              response: {
                data: {
                  error_code: 'errorUploadingFile',
                },
              },
            };
          }

          setUploadedSuccess(true);

          onChange({
            id: data[0].id,
            nameKey: nameKey,
            url: API_SEC_VIRTUAL + data[0].url,
            fileName: data[0].name,
            nBytes: files[0].size,
          });

          setUploadingFlag && setUploadingFlag(nameKey, false);
        })
        .catch((e) => {
          // reset
          setUploadedSuccess(null);
          setUploadedFiles([]);
          setDisableDropzone(false);
          setUploadingFlag && setUploadingFlag(nameKey, false);

          // TODO capturar erros que vém da api e mostrar no toaster.
          // Exemplo: quando o tamanho máximo é excedido e a api retorna o erro correspondente
          // Quando isto tiver sido resolvido, apagar o toaster abaixo
          dispatch(
            actions.Toaster.showToaster({
              title: t('secVirtualNotifications.general_errorUploadingFile'),
              icon: 'error',
              type: 'danger',
            })
          );
        });
    },
    onDragEnter() {
      if (!disableDropzone) {
        setDropzoneActiveColor(theme.colors.lightGrey);
      }
    },
    onDragLeave() {
      setDropzoneActiveColor(theme.colors.white);
    },
    onDropAccepted() {
      setDropzoneActiveColor(theme.colors.white);
    },
    onDropRejected(files, event) {
      setDropzoneActiveColor(theme.colors.white);

      if (files.length > 0 && files[0].errors.length > 0) {
        setErrorToDisplay(files[0].errors[0].code);
      }
    },
  });

  const removeFile = (index) => {
    setUploadedFiles(uploadedFiles.filter((file, i: number) => i !== index));

    setUploadedSuccess(null);

    onChange(undefined);
    setDisableDropzone(false);
  };

  const files = uploadedFiles.map((file: any, index: number) => {
    return (
      <FileWrapper key={`file-${index}`}>
        <FileNameAndProgress>
          <Text size="article" color={theme.colors.plusDarkGrey} key={`file-${index}`}>
            {file.name}
          </Text>

          <ProgressBar
            percent={file.progress}
            active={!file.finished && file.progress && file.progress !== 100}
            success={file.finished && !file.error}
            error={file.finished && file.error}
          />
        </FileNameAndProgress>
      </FileWrapper>
    );
  });

  const processFilename = (filename: string | undefined) => {
    // remove the file name extension

    if (!filename || filename === '') {
      return '';
    }

    const filenameSplit = filename.split('.');
    let filenameHeader = '';

    if (filenameSplit.length === 1) {
      return filenameSplit[0];
    }

    for (let i = 0; i < filenameSplit.length; i++) {
      if (i !== filenameSplit.length - 1) {
        if (i === 0) {
          filenameHeader += filenameSplit[i];
        } else {
          filenameHeader += `.${filenameSplit[i]}`;
        }
      }
    }

    return filenameHeader;
  };

  const getFileExtension = (filename) => {
    if (!filename || filename === '') {
      return '...';
    }

    const filenameSplit = filename.split('.');

    if (filenameSplit.length === 1 || (filenameSplit.length === 2 && filenameSplit[1] === '')) {
      return '...';
    }

    return filenameSplit[filenameSplit.length - 1];
  };

  const getFilesOrDescriptions = () => {
    if (
      (value === undefined || value === null) &&
      uploadedFiles.length &&
      (!uploadedFiles[0].finished || uploadedFiles[0].error)
    ) {
      return <UploaderDescription>{files}</UploaderDescription>;
    } else if ((value === undefined || value === null) && !uploadedFiles.length) {
      return (
        <UploaderDescription>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <TextDescription>
            {heading && (
              <StyledText size="article" weight={'medium'} color={theme.colors.plusDarkGrey}>
                {heading}
              </StyledText>
            )}
            {description && (
              <UploaderDescriptionText
                weight={'regular'}
                size="xSmall"
                color={theme.colors.darkGrey}
              >
                {description}
              </UploaderDescriptionText>
            )}
          </TextDescription>
        </UploaderDescription>
      );
    } else if (value) {
      // file upload has finished
      return (
        <UploaderDescription>
          {icon && <IconWrapper>{icon}</IconWrapper>}
          <TextDescription>
            {heading && (
              <UploadedFileHeading>
                <UploadedFileHeadingTitle>
                  <StyledText size="article" weight={'medium'} color={theme.colors.primary}>
                    {heading}
                  </StyledText>
                </UploadedFileHeadingTitle>
                <UploadedFileHeadingFileName>
                  <Text size="xSmall" weight="regular" color={theme.colors.darkGrey}>
                    {`(${processFilename(value.fileName)}`}
                  </Text>
                </UploadedFileHeadingFileName>
                <div>
                  <Text size="xSmall" weight="regular" color={theme.colors.darkGrey}>
                    {')'}
                  </Text>
                </div>
              </UploadedFileHeading>
            )}
            {
              <UploadedFileSubHeading>
                <Text size="xSmall" weight="medium" color={theme.colors.plusDarkGrey}>
                  {`${t('payments.format', { textOnly: true })}:`}
                </Text>
                <Text
                  size="xSmall"
                  weight="regular"
                  transform={'uppercase'}
                  color={theme.colors.plusDarkGrey}
                >
                  {`(${getFileExtension(value.fileName)})`}
                </Text>
                <Text size="xSmall" weight="medium" color={theme.colors.plusDarkGrey}>
                  {'|'}
                </Text>
                <Text size="xSmall" weight="medium" color={theme.colors.plusDarkGrey}>
                  {`${t('payments.fileSize', { textOnly: true })}:`}
                </Text>
                <Text size="xSmall" weight="regular" color={theme.colors.plusDarkGrey}>
                  {`${bytesToMegabytes(value.nBytes)}`}
                </Text>
              </UploadedFileSubHeading>
            }
          </TextDescription>
        </UploaderDescription>
      );
    } else {
      return <div></div>;
    }
  };

  const getErrorMessage = (code: string) => {
    if (code === 'file-too-large') {
      return t('generic.fileUploaderErrorLarge', {
        limit: bytesToMegabytes(maxSize ? maxSize : DEFAULT_FILE_SIZES.MAX_SIZE),
      });
    } else if (code === 'file-too-small') {
      return t('generic.fileUploaderErrorSmall', {
        limit: minSize ? minSize : DEFAULT_FILE_SIZES.MAX_SIZE,
      });
    } else if (code === 'too-many-files') {
      return t('generic.tooManyFilesError');
    } else if (code === 'file-invalid-type') {
      return t('generic.fileInvalidTypeError');
    }
  };

  return (
    <Wrapper>
      {enableRemoveField && (
        <RemoveFieldButtonWrapper>
          <StyledButton
            borderless
            color={theme.colors.darkGrey}
            borderlessHoverColor={theme.colors.dangerRed}
            onClick={() => {
              onRemoveField && onRemoveField();
            }}
          >
            <RemoveFileIconWrapper>
              <FontAwesomeIcon icon={faPlusCircle} size={'lg'} />
            </RemoveFileIconWrapper>
          </StyledButton>
        </RemoveFieldButtonWrapper>
      )}
      <InnerWrapper backgroundColor={dropzoneActiveColor}>
        <DropzoneContainer {...getRootProps({ className: 'dropzone' })}>
          {/* The disabled can be used to disable the menu open when the 'Upload File' button is clicked */}
          <input {...getInputProps()} disabled={false} />
          <DescriptionAndButtonUploader>
            {(icon || heading || description || files) && (
              <DescriptionWrapper>{getFilesOrDescriptions()}</DescriptionWrapper>
            )}
            {!uploadedFiles.length && (
              <Button
                action
                leftIcon={<FontAwesomeIcon icon={faArrowFromBottom} />}
                color={theme.colors.plusDarkGrey}
                onClick={() => {
                  open();
                }}
              >
                {t('generic.fileUploaderButton')}
              </Button>
            )}
            {((uploadedFiles.length > 0 && !uploadedFiles[0].error && uploadedFiles[0].finished) ||
              value) && (
              <ButtonDeleteFileWrapper removable={true}>
                <Button
                  action
                  loadingProps={{
                    width: '100px',
                  }}
                  disabled={showFileLoading}
                  loading={showFileLoading}
                  leftIcon={<FontAwesomeIcon icon={faEye} />}
                  color={theme.colors.plusDarkGrey}
                  onClick={() => {
                    setShowFileLoading(true);

                    API.files.getFileOnTab
                      .call(value.url)
                      .then((value) => {
                        setShowFileLoading(false);
                      })
                      .catch((e) => {
                        setShowFileLoading(false);
                        dispatch(
                          actions.Toaster.showToaster({
                            title: t('secVirtualNotifications.general_errorLoadingFile'),
                            icon: 'error',
                            type: 'danger',
                          })
                        );
                      });
                  }}
                >
                  {t('generic.viewFile')}
                </Button>

                <StyledButton
                  borderless
                  color={theme.colors.darkGrey}
                  borderlessHoverColor={theme.colors.dangerRed}
                  onClick={() => {
                    // 0 because each file field only accepts one file
                    removeFile(0);
                  }}
                >
                  <TrashIconWrapper>
                    <FontAwesomeIcon icon={faTrash} />
                  </TrashIconWrapper>
                </StyledButton>
              </ButtonDeleteFileWrapper>
            )}
          </DescriptionAndButtonUploader>
        </DropzoneContainer>
      </InnerWrapper>
      <MessageArea>
        <Text size="xSmall" weight="medium" color="grey">
          {t('generic.fileUploaderNote')}
        </Text>
      </MessageArea>
      {(!!fileRejections.length || error) && (
        <MessageArea>
          {errorToDisplay !== null /* || fileRejections.length > 0*/ && (
            <Text size="xSmall" weight="medium" color="dangerRed">
              {getErrorMessage(errorToDisplay)}
            </Text>
          )}
          {error && (
            <Text size="xSmall" weight="medium" color="dangerRed">
              {`* ${error}`}
            </Text>
          )}
        </MessageArea>
      )}
    </Wrapper>
  );
};

export default FileUploader;

const Wrapper = styled.div`
  position: relative;
`;

const InnerWrapper = styled.div<{ backgroundColor: string }>`
  border: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
  height: 69px;
  display: flex;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const DescriptionAndButtonUploader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const RemoveFieldButtonWrapper = styled.div`
  position: absolute;
  top: -19px;
  right: -20px;
  z-index: 999;

  svg {
    font-size: 18px;
    background-color: white;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  svg {
    display: flex;
    align-items: center;

    font-size: 26px;
  }
`;

const UploadedFileHeadingTitle = styled.div`
  min-width: 0px;
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UploadedFileHeadingFileName = styled.div`
  min-width: 0px;
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const UploaderDescription = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
`;

const TrashIconWrapper = styled.div`
  svg {
    font-size: 16px;
  }
`;

const TextDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 17px;
  max-width: 650px;
  justify-content: space-between;

  > div:not(:first-child) {
    margin-top: 4px;
  }
`;

const StyledText = styled(Text)`
  line-height: 1.3;
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const FileNameAndProgress = styled.div`
  position: relative;
`;

const ProgressBar = styled(Progress)`
  &&& {
    width: 100%;
    position: absolute;
    bottom: -2px;
    left: 0;
    margin: 0;

    .bar {
      height: 2px;
      min-width: 0;
      &.success {
        background-color: ${({ theme }) => theme.colors.successGreen} !important;
      }
    }
  }
`;

const UploadedFileHeading = styled.div`
  display: flex;
  align-items: center;

  div:nth-child(2) {
    margin-left: 4px;
  }
`;

const UploadedFileSubHeading = styled.div`
  display: flex;
  div:not(:first-child) {
    margin-left: 4px;
  }
`;

const ButtonDeleteFileWrapper = styled.div<{ removable: boolean }>`
  display: flex;

  button:first-child {
    margin-right: ${({ removable }) => (removable ? '16px' : '0px')};
  }
`;

const MessageArea = styled.div`
  margin-top: 4px;
`;

const UploaderDescriptionText = styled(Text)`
  margin-top: 4px;
`;

const DropzoneContainer = styled.div`
  padding: 16px;
  outline: none;
  width: 100%;
`;

const RemoveFileIconWrapper = styled.div`
  svg {
    transform: rotate(45deg);
  }
`;

const StyledButton = styled(Button)`
  z-index: 999;
`;
