import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import { t } from 'app';
// import 'bootstrap/dist/css/bootstrap.min.css';

export function StateProgressBar(state) {
  const percentage = state*(100/8);
  return (
    <div className="progressContainer" style={{ position: "relative", marginBottom: "20px" }}>
      <div style={{ height: "10px", width: "100px", backgroundColor: '#F9F9F9', position: 'absolute' }}>
        <ProgressBar
          now={percentage}
          style={{
            height: "10px",
            width: `${percentage}%`,
            backgroundColor: '#0EB4BD',
            borderRadius: '0',
          }}
        />
      </div>
      <p style={{ top: "15px", fontSize: "15px", position: "absolute", textAlign: "left", width: "100%", whiteSpace: "nowrap" }}>
        {t('pautas.gradeState'+state)}
      </p>
    </div>
  );
}