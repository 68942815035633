import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';

interface GradeDetail {
  codDisciplina: number;
  nomeDisciplina: string;
  nomeDisciplinaEng: string;
  codPauta: string;
  anoLectivo: number;
  semestre: number;
  tipoExame: string;
  epocaExame: number;
  ntotalAlunos: number;
  estadoActual: number;
}

interface GradesDetailsState {
  pautaData: GradeDetail[];
  dataExame: string | null;
  gradeCounts: {} | null;
  loadingStatus: {
    loadingGradeDetailsInfo: boolean;
    loadingGradeDetailsCounts: boolean;
  };
  pdfUrl: string | null;
  isPdfReady: boolean;
  isPdfModalOpen: boolean;
}

const initialState: GradesDetailsState = {
  pautaData: [],
  dataExame: null,
  gradeCounts: {},
  loadingStatus: {
    loadingGradeDetailsInfo: true,
    loadingGradeDetailsCounts: true
  },
  pdfUrl: null,
  isPdfReady: false,
  isPdfModalOpen: false
};

export default createSlice({
  name: 'GradesDetails',
  initialState,
  reducers: {
    onMount: () => { },
    onUnmount: () => { },
    reset: (state) => initialState,
    setGradeDetails: (state, action) => {
      state.pautaData = Array.isArray(action.payload) ? action.payload : [action.payload];
    },
    getGradeDetails: (state, action: PayloadAction<{ codigoPauta: string }>) => {},
    setDataExame: (state, action) => {
      state.dataExame = action.payload;
    },
    setGradeCounts: (state, action) => {
      state.gradeCounts = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    fetchPdf: () => {},
    setPdfUrl: (state, action) => {
      state.pdfUrl = action.payload;
    },
    setIsPdfReady: (state, action) => {
      state.isPdfReady = action.payload;
    },
    setIsPdfModalOpen: (state) => {
      state.isPdfModalOpen = !state.isPdfModalOpen;
    }
  },
});
