/**
 * FreeOptionsTable container sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { call, takeLatest, put, putResolve, select } from 'redux-saga/effects';

import { PayloadAction } from '@reduxjs/toolkit';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';

import * as selectors from './selectors';

import ErrorHandler from 'shared/errorHandler';

import {
  ICallParams,
  IRegistration,
  DEFAULT_PAGINATION_PARAMS,
  IFreeOptionsPayload,
} from '../types';

import { checkHasFilters } from '../utils';

function* onMountSaga(action: PayloadAction<IRegistration & { mandatoryFilters?: boolean }>) {
  try {
    yield put(
      actions.FreeOptionsTable.setLoadingStatus({ fieldName: 'loading', fieldValue: true })
    );
    yield putResolve(
      actions.FreeOptionsTable.fetchFreeOptions({
        ...DEFAULT_PAGINATION_PARAMS,
        registrationId: action.payload.registrationId,
        mandatoryFilters: action.payload.mandatoryFilters,
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetFreeOptionsList', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* fetchFreeOptionsSaga(
  action: PayloadAction<ICallParams & IRegistration & { mandatoryFilters?: boolean }>
) {
  try {
    yield put(
      actions.FreeOptionsTable.setLoadingStatus({ fieldName: 'loadingTable', fieldValue: true })
    );

    let freeOptionsListSlice = yield select(selectors.getFreeOptionsListSlice);
    let freeOptionsData: IFreeOptionsPayload;

    if (action.payload.mandatoryFilters) {
      if (!checkHasFilters(freeOptionsListSlice.filterParams)) {
        freeOptionsListSlice = {
          ...freeOptionsListSlice,
          filterParams: { ...freeOptionsListSlice.filterParams, onlyFilters: true },
        };
      }
    }

    if (action.payload.registrationId) {
      freeOptionsData = yield call(API.sgh.getStudentFreeOptions.call, {
        ...action.payload,
        ...freeOptionsListSlice.filterParams,
      });
    } else {
      freeOptionsData = yield call(API.sgh.getFreeOptions.call, {
        ...action.payload,
        ...freeOptionsListSlice.filterParams,
      });
    }

    yield put(actions.FreeOptionsTable.setFreeOptionsData(freeOptionsData));
  } catch (e) {
    yield put(
      actions.FreeOptionsTable.setErrorStatus({
        fieldName: 'errLoadingFreeOptions',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.freeOptionsList_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.FreeOptionsTable.setLoadingStatus({ fieldName: 'loading', fieldValue: false })
    );
    yield put(
      actions.FreeOptionsTable.setLoadingStatus({ fieldName: 'loadingTable', fieldValue: false })
    );
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('FreeOptionsTable/onMount', onMountSaga);
  yield takeLatest('FreeOptionsTable/fetchFreeOptions', fetchFreeOptionsSaga);
  yield takeLatest('FreeOptionsTable/onUnmount', onUnmountSaga);
}
