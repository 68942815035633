/**
 * Schedules utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import moment from 'moment';

export const getScheduleInterval = (ucs, currentInterval) => {
  let interval = { ...currentInterval };

  ucs.forEach((uc) => {
    uc.classSchedule.forEach((classItem) => {
      const currentIntervalMin = moment(interval.min, 'HH:mm:ss');
      const classMin = moment(classItem.startTime, 'HH:mm:ss');

      const currentIntervalMax = moment(interval.max, 'HH:mm:ss');
      const classMax = moment(classItem.endTime, 'HH:mm:ss');

      if (classMin.isBefore(currentIntervalMin)) {
        interval.min = classMin.format('HH:mm:ss');
      }

      if (classMax.isAfter(currentIntervalMax)) {
        interval.max = classMax.format('HH:mm:ss');
      }
    });
  });

  return interval;
};
