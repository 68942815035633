import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import { renderGradeInput, handleGradeChange, handleCSVImport, getDropdownSuplementaryGradesOptions, handleSupplementaryGradeChange, handleSupplementaryGradeClear, getGradesEditValues } from './utils.js'
import ConfirmActionModal from 'shared/components/ConfirmActionModal';
import { history } from 'utils';

import { Button, Table, Dropdown, AnimatedBackground } from '@paco_ua/pacoui';
import GradeTitle from 'shared/components/GradeTitle';
import CSVModal from './CSVModal/index'
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LabelsModal from './LabelsModal';
import { faEye, faUpload } from '@fortawesome/pro-light-svg-icons';
import StatisticsModal from './StatisticsModal';
import ErrorsModal from './ErrorsModal';
import ExamDatePicker from './DatePicker/ExamDatePicker';

const ButtonsDiv = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
  justify-content: flex-end;
  display: flex;
`;

const FileInputLabel = styled.label`
  display: inline-block;
  color: black;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 16px;
`;

const HiddenFileInput = styled.input`
  display: none; // Hide the actual input
`;

const TitleGradeExamDateStyle = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #757575;
  ${(props: { examDate: string, pickedExamDate?: string }) =>
    (props.pickedExamDate && props.pickedExamDate !== props.examDate) && css`
    text-decoration: underline dotted gold;
    text-decoration-thickness: 0.2rem;
  `}
`;

const ButtonsWrapper = styled.div`
display: flex;
justify-content: flex-end;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const ExamDateWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const EditGrades = ({ theme, t }) => {
  const dispatch = useDispatch();
  const gradesData = useSelector(selectors.selectGradeData);
  const HDADOS = useSelector(selectors.selectHDADOS);
  const validGradeValues = useSelector(selectors.selectValidGradeValues);
  const gradeEdits = useSelector(selectors.selectGradeEdits);
  const isStatisticsModalOpen = useSelector(selectors.selectIsStatisticsModalOpen);
  const isLabelsModalOpen = useSelector(selectors.selectIsLabelsModalOpen);
  const isConfirmPageLeaveModalOpen = useSelector(selectors.selectIsConfirmPageLeaveModalOpen);
  const isConfirmDiscardChangesModalOpen = useSelector(selectors.selectIsConfirmDiscardChangesModalOpen);
  const { codigoPauta } = useParams();
  const isCSVModalOpen = useSelector(selectors.isCSVModalOpen);
  const csvFile = useSelector(selectors.csvFile)
  const isErrorsModalOpen = useSelector(selectors.selectIsErrorsModalOpen);
  const dicionarios = useSelector(selectors.selectDicionarios);
  const dropdownValueSelected = useSelector(selectors.selectDropdownValueSelected);
  const pickedExamDate = useSelector(selectors.selectExamDate);
  const loadingStatus = useSelector(selectors.selectLoadingStatus);
  const gradeDetails = useSelector(selectors.selectGradeDetails);

  useEffect(() => {
    dispatch(actions.EditGrades.onMount(codigoPauta));

    return () => {
      dispatch(actions.EditGrades.onUnmount());
    };
  }, [dispatch]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasEdits()) {
        event.preventDefault();
        event.returnValue = '';
        //dispatch(actions.EditGrades.setIsConfirmPageLeaveModalOpen())
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [gradeEdits, pickedExamDate]);

  const handleGradeInputChange = handleGradeChange(dispatch, gradesData, gradeEdits, validGradeValues);

  const onCSVImport = (event) => {
    handleCSVImport(event, dispatch);
  };

  // Verify if gradesData is an array before mapping
  let tableStructure;

  const header = {
    titles: [
      { text: t('pautas.nmec'), style: { textAlign: 'left' } },
      { text: t('pautas.name'), style: { textAlign: 'left' } },
      { text: t('pautas.course'), style: { textAlign: 'left' } },
      { text: t('pautas.regime'), style: { textAlign: 'left' } },
      { text: t('pautas.grade'), style: { textAlign: 'center', width: '0.1%' } },
    ],
    type: 'columnTitle'
  }

  if (Array.isArray(gradesData)) {
    tableStructure = {
      header: header,
      rows: gradesData.map((item, index) => ({
        cells: [
          { cellProps: '', content: item.nmec },
          { cellProps: '', content: item.nome },
          { cellProps: '', content: item.curso },
          { cellProps: '', content: item.regime },
          { cellProps: { style: { textAlign: 'center' } }, content: renderGradeInput(item, gradeEdits, handleGradeInputChange, validGradeValues, gradesData) },
        ],
        key: `row-${index}`,
        collapsableRows: [],
      })),
    };
  } else {
    tableStructure = {
      header: header,
      rows: [],
    };
  }

  const handleLabelsModal = () => {
    dispatch(actions.EditGrades.setIsLabelsModalOpen());
  }

  const hasEdits = () => {
    return Object.keys(gradeEdits).length > 0 || (pickedExamDate && pickedExamDate !== HDADOS.dataExame);
  }

  const handleConfirmPageLeaveModal = () => {
    if (hasEdits()) {
      dispatch(actions.EditGrades.setIsConfirmPageLeaveModalOpen());
    } else {
      history.goBack();
    }
  }

  const handleErrorsModal = () => {
    dispatch(actions.EditGrades.setIsErrorsModalOpen());
  }

  const handleConfirmLeavePage = () => {
    history.goBack();
  }

  const handleConfirmDiscardChangesModal = () => {
    dispatch(actions.EditGrades.setIsConfirmDiscardChangesModalOpen());
  }

  const handleConfirmDiscardChanges = () => {
    dispatch(actions.EditGrades.clearGradeEdits());
    dispatch(actions.EditGrades.clearExamDate());
    handleConfirmDiscardChangesModal();
  }

  return (
    <PageStructure
      loading={!gradesData}
      expanded={true}
      headerLeft={<Header title={t('routes.editGrades')} />}
      mainContent={
        <>
          {(loadingStatus.loadingGradeData) && (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="40%" />
              <AnimatedBackground height={theme.sizes.large} width="40%" />
              <AnimatedBackground height={theme.sizes.large} width="0%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
            </LoadingWrapper>
          )}
          {(!loadingStatus.loadingGradeData && Object.keys(gradeDetails).length != 0) && (
            <>
              {isConfirmPageLeaveModalOpen &&
                <ConfirmActionModal
                  onClick={handleConfirmPageLeaveModal}
                  onConfirm={handleConfirmLeavePage}
                  title={t('pautas.confirmExitPage')}
                  body={t('pautas.changesWillNotBeSaved')}
                />
              }

              {isConfirmDiscardChangesModalOpen &&
                <ConfirmActionModal
                  onClick={handleConfirmDiscardChangesModal}
                  onConfirm={handleConfirmDiscardChanges}
                  title={t('pautas.confirmDiscardChanges')}
                  body={t('pautas.changesWillNotBeSaved')}
                />
              }

              {isStatisticsModalOpen && <StatisticsModal editedGradesData={getGradesEditValues(gradesData, gradeEdits)} codigoPauta={HDADOS.codigoPauta} gradesEdits={gradeEdits} />}
              {isLabelsModalOpen && <LabelsModal onClick={handleLabelsModal} />}
              <GradeTitle
                onBack={handleConfirmPageLeaveModal}
                codDisciplina={gradeDetails.codDisciplina.toString()}
                nomeDisciplina={gradeDetails.nomeDisciplina}
                nomeDisciplinaEng={gradeDetails.nomeDisciplinaEng}
                tipoExame={gradeDetails.tipoExame || HDADOS.tipoExame}
                epocaExame={gradeDetails.epocaExame || parseInt(HDADOS.epocaExame)}
                anoLectivo={gradeDetails.anoLectivo}
                codPauta={gradeDetails.codPauta}
              >
                <ExamDateWrapper>
                  <TitleGradeExamDateStyle examDate={HDADOS.dataExame} pickedExamDate={pickedExamDate ?? undefined}>
                    {t('pautas.examDate')}: {pickedExamDate || HDADOS.dataExame}
                  </TitleGradeExamDateStyle>
                  <ExamDatePicker
                    selectedDate={pickedExamDate || HDADOS.dataExame}
                    onDateChange={(date) => dispatch(actions.EditGrades.setExamDate(date))}
                  />
                </ExamDateWrapper>
              </GradeTitle>
              <ButtonsDiv>
                <FileInputLabel onClick={handleLabelsModal}><FontAwesomeIcon icon={faEye} /> {t('pautas.legend')}</FileInputLabel>
                <div>
                  <FileInputLabel htmlFor="csv-file-input"><FontAwesomeIcon icon={faUpload} /> {t('pautas.importFile')}</FileInputLabel>
                  <HiddenFileInput
                    id="csv-file-input"
                    type="file"
                    accept=".csv, .xlsx, .xlsm"
                    onChange={onCSVImport}
                  />
                </div>
                <Dropdown
                  placeholder={t('pautas.supplementaryNotes', { textOnly: true })}
                  canBeCleared
                  options={getDropdownSuplementaryGradesOptions(dicionarios)}
                  onChange={(e, { value }) => handleSupplementaryGradeChange(dispatch, gradesData, gradeEdits, value, dicionarios)}
                  onClear={() => handleSupplementaryGradeClear(dispatch, gradeEdits, dropdownValueSelected)}
                  style={{ width: "20rem" }}
                />
              </ButtonsDiv>
              {(isCSVModalOpen) && (
                <CSVModal
                  changeModal={() => dispatch(actions.EditGrades.setIsCSVModalOpen())}
                  csvFile={csvFile}
                  onSubmit={(nmecColumnIndex, gradeColumnIndex, hasHeader, csvFile) => {
                    dispatch(actions.EditGrades.uploadGrades({
                      codigoPauta: codigoPauta,
                      nmecColumnIndex: nmecColumnIndex,
                      gradeColumnIndex: gradeColumnIndex,
                      hasHeader: hasHeader,
                      csvFile: csvFile
                    }));
                  }}
                  onClose={() => dispatch(actions.EditGrades.setIsCSVModalOpen())}
                />
              )}
              {isErrorsModalOpen && !isCSVModalOpen && <ErrorsModal onClick={handleErrorsModal} />}
              <div style={{ width: '100%', overflowX: 'auto' }}>
                <Table loading={loadingStatus.loadingTable} structure={tableStructure} />
              </div>
              <ButtonsWrapper>
                {hasEdits() && <Button
                  danger
                  style={{ marginTop: '2rem', marginRight: '1rem' }}
                  onClick={() => { handleConfirmDiscardChangesModal() }}
                >
                  {t('pautas.cancel', { textOnly: true })}
                </Button>}
                <Button
                  action
                  style={{ marginTop: '2rem' }}
                  onClick={() => {
                    dispatch(actions.EditGrades.setStatisticsModal())
                  }}
                >
                  {t('pautas.save', { textOnly: true })}
                </Button>
              </ButtonsWrapper>
            </>
          )}
        </>
      }
    />
  );
};

export default EditGrades;