/**
 * Registration scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  errorStatus: {
    errLoadingRegistrations: false,
  },
};

export default createSlice({
  name: 'Registrations',
  initialState,
  reducers: {
    onMount: () => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    onUnmount: () => {},
    reset: (state) => initialState,
  },
});
