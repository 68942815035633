/**
 * Multi Payments Selection container
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { Table, Text, StatusLabel, SearchBox, Dropdown } from '@paco_ua/pacoui';

import { IMultiPaymentsSelectionProps, IDebitData } from 'scenes/VirtualSecretary/Payments/types';
import { DEBIT_STATUS, DEBIT_STATUS_COLOR } from '../DebitsTable/utils';
import { findItem } from './utils';

import useTable from './useTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

const compileTableRow = (
  debit: IDebitData,
  selectedDebits: IDebitData[],
  preSelectedDebits: IDebitData[],
  theme,
  addDebit,
  removeDebit
) => {
  const preSelectedDisabled = findItem(debit.debitId, preSelectedDebits);

  const preSelected = findItem(debit.debitId, selectedDebits);

  return {
    value: debit,
    defaultSelected: preSelectedDisabled || preSelected,
    disabled: preSelectedDisabled,
    rowProps: {},
    cells: [
      {
        content: (
          <ContentWrapper>
            {debit.debitType && (
              <Text size="xSmall" color="darkGrey">
                {t(`debitTypes.${debit.debitType.toLowerCase()}`, { textOnly: true })}
              </Text>
            )}
            <DebitStatusText
              size="article"
              weight="medium"
              color={debit.debitStatus === DEBIT_STATUS.IN_DEBIT ? 'dangerRed' : 'primary'}
            >
              {debit.description}
            </DebitStatusText>
          </ContentWrapper>
        ),
        cellProps: { width: 7, singleLine: false },
      },
      {
        content: (
          <Text size="article">
            {t('generic.euros', { textOnly: true, count: debit.value.toFixed(2) })}
          </Text>
        ),
        cellProps: {
          width: 3,
          singleLine: false,
          style: {
            textAlign: 'center',
          },
        },
      },
      {
        content: (
          <Text
            color={debit.debitStatus === DEBIT_STATUS.IN_DEBIT ? 'dangerRed' : ''}
            size="article"
          >
            {debit.dueDate && debit.dueDate !== '' ? (
              moment(debit.dueDate, 'DD-MM-YYYY').format('DD-MM-YYYY')
            ) : (
              <FontAwesomeIcon icon={faEllipsisH} />
            )}
          </Text>
        ),
        cellProps: {
          width: 3,
          singleLine: true,
          style: {
            textAlign: 'center',
          },
        },
      },
      {
        content: (
          <StatusLabelContainer>
            <StatusLabel
              background={DEBIT_STATUS_COLOR(theme)[debit.debitStatus]}
              label={t(`payments.${debit.debitStatus}`, { textOnly: true })}
            />
          </StatusLabelContainer>
        ),
        cellProps: {
          width: 3,
          singleLine: false,
          style: {
            textAlign: 'center',
          },
        },
      },
    ],
  };
};

const MultiPaymentsSelection = ({
  data,
  total,
  loading,
  fetchUnselectedDebits,
  setMultiple,
  selectedDebits,
  preSelectedDebits,
  addFilters,
  filterParams,
}: IMultiPaymentsSelectionProps) => {
  const theme = useContext(ThemeContext);

  const [filtersChanged, setFiltersChanged] = useState(false);

  const { structure, pagination, onChangePage, onChangeRows } = useTable(
    t,
    data,
    selectedDebits,
    preSelectedDebits,
    compileTableRow,
    total,
    fetchUnselectedDebits,
    filterParams,
    filtersChanged
  );

  useEffect(() => {
    setFiltersChanged(true);
  }, [filterParams]);

  useEffect(() => {}, [structure]);

  return (
    <>
      {addFilters && (
        <SearchAndFiltersWrapper>
          <SearchBox
            placeholder={addFilters.searchPlaceholder}
            borderColor={theme.colors.plusDarkGrey}
            iconColor={theme.colors.plusDarkGrey}
            delay={500}
            onSearch={(value) => {
              addFilters.onSearch(value);
            }}
          />
          <Dropdown
            placeholder={addFilters.dropdownPlaceholder}
            canBeCleared
            options={addFilters.filterTypes}
            selectionWeight={'400'}
            selectionFontSize={'medium'}
            onChange={(e, { value }) => {
              addFilters.onFilterChange(value);
            }}
            onClear={() => {
              addFilters.onFilterChange('');
            }}
          />
        </SearchAndFiltersWrapper>
      )}
      <PaymentsTableWrapper>
        <Table
          unstackable
          selectable
          loading={loading}
          onChangeSelected={(data, structure) => {
            setMultiple(data, structure);
          }}
          defaultSelectedRows={selectedDebits.map((debit) => {
            return {
              value: debit,
            };
          })}
          useValueAsId={'debitId'}
          selectedInfoTitle={
            <Text size="article" weight="regular" color="white">
              {t('payments.multipaymentsSelection')}
            </Text>
          }
          selectedInfoSubTitle={(value) => {
            return (
              <Text size="xSmall" color="white">
                {t('generic.selectedItem', { textOnly: true, count: value })}
              </Text>
            );
          }}
          structure={structure}
          resetSelectedIds={filtersChanged}
          setResetSelectedIdsFunction={() => {
            setFiltersChanged(false);
          }}
          pagination={pagination}
          onChangeRows={onChangeRows}
          onChangePage={onChangePage}
          translations={t}
        />
      </PaymentsTableWrapper>
    </>
  );
};

export default MultiPaymentsSelection;

const StatusLabelContainer = styled.div`
  div {
    margin: 0px auto;

    @media (max-width: 992px) {
      width: auto;
      max-width: 120px;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchAndFiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  > div {
    width: 33%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const PaymentsTableWrapper = styled.div`
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }

  &&& .ui.table tfoot tr td {
    height: 45px;
  }
`;

const DebitStatusText = styled(Text)`
  margin-top: 4px;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
