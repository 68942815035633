/**
 * PersonalData scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { call, takeLatest, put, select, all } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import { t } from 'app';

import API from 'api';
import * as selectors from './selectors';
import { reformatPersonalDataBlocks, updatePersonalDataBlocks } from '../utils';

import ErrorHandler from 'shared/errorHandler';
import { IContactsFields, IAddressFields } from './slice';

import { camelCase } from 'lodash';
import { ItemMeta } from 'semantic-ui-react';

function* onMountSaga() {
  try {
    yield put(actions.PersonalData.reset());
    yield put(
      actions.PersonalData.setLoadingStatus({ fieldName: 'loadingPage', fieldValue: true })
    );
    let [personalDataBlocks, studentCardBlock] = yield all([
      call(API.secVirtual.getPersonalData.call),
      call(API.secVirtual.getStudentCardInfo.call),
    ]);

    // let personalDataBlocks = yield call(API.secVirtual.getPersonalData.call);

    // convert nameKeys involved in forms, from snake case to camel case
    personalDataBlocks.address.values.forEach((item, index) => {
      personalDataBlocks.address.values[index].nameKey = camelCase(
        personalDataBlocks.address.values[index].nameKey
      );
      personalDataBlocks.address.values[index].placeholderKey = camelCase(
        personalDataBlocks.address.values[index].placeholderKey
      );
    });

    personalDataBlocks.contacts.values.forEach((item, index) => {
      //Remove alternative phone number
      if (personalDataBlocks.contacts.values[index].nameKey === 'alternative_phone_number')
        personalDataBlocks.contacts.values.splice(index, 1);
      personalDataBlocks.contacts.values[index].nameKey = camelCase(
        personalDataBlocks.contacts.values[index].nameKey
      );
      personalDataBlocks.contacts.values[index].placeholderKey = camelCase(
        personalDataBlocks.contacts.values[index].placeholderKey
      );
    });

    let picture = null;
    picture = personalDataBlocks.profile.values.filter(
      (item) => item.nameKey === 'photo' && item.value !== ''
    )[0]?.value;

    personalDataBlocks = reformatPersonalDataBlocks(personalDataBlocks, picture);

    yield put(actions.PersonalData.setPersonalDataBlocks(personalDataBlocks));
    yield put(actions.PersonalData.setStudentCardBlock(studentCardBlock));
  } catch (e) {
    yield put(
      actions.PersonalData.setErrorStatus({
        fieldName: 'errLoadingPersonalData',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.personalData_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.PersonalData.setLoadingStatus({ fieldName: 'loadingPage', fieldValue: false })
    );
  }
}

function* patchAddressFormSaga(action: IAddressFields) {
  try {
    yield put(
      actions.PersonalData.setLoadingStatus({ fieldName: 'loadingAddress', fieldValue: true })
    );

    const form = action.payload;

    const regionNames = new (Intl as any).DisplayNames('pt', {
      type: 'region',
      fallback: 'code',
    });

    const body = {
      ...form,
      homePostalCode: {
        prefix: form.homePostalCode.countryValue,
        number: form.homePostalCode.input,
      },
    };

    const personalDataBlocks = yield select(selectors.getPersonalDataBlocks);

    yield call(API.secVirtual.patchAddressForm.call, body);

    // converts country code to country name to display in FE
    const updatedBody = {
      ...body,
      homePostalCode: body.homePostalCode.number,
      homeCountry: body.homePostalCode.prefix.toUpperCase(),
      homeCountryName: regionNames.of(body.homePostalCode.prefix.toUpperCase()),
    };

    const updatedPersonalDataBlocks = updatePersonalDataBlocks(
      personalDataBlocks,
      'address',
      updatedBody,
      false
    );

    yield put(actions.PersonalData.setPersonalDataBlocks(updatedPersonalDataBlocks));

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.personalData_successUpdateContact'),
        icon: 'check',
        type: 'success',
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.personalData_errorUpdateContact'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.PersonalData.setLoadingStatus({ fieldName: 'loadingAddress', fieldValue: false })
    );
  }
}

function* patchContactsFormSaga(action: IContactsFields) {
  try {
    const form = action.payload;

    yield put(
      actions.PersonalData.setLoadingStatus({ fieldName: 'loadingContacts', fieldValue: true })
    );

    const personalDataBlocks = yield select(selectors.getPersonalDataBlocks);

    yield call(API.secVirtual.patchContactsForm.call, form);

    const updatedPersonalDataBlocks = updatePersonalDataBlocks(
      personalDataBlocks,
      'contacts',
      form,
      false
    );

    yield put(actions.PersonalData.setPersonalDataBlocks(updatedPersonalDataBlocks));

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.personalData_successUpdateAddress'),
        icon: 'check',
        type: 'success',
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.personalData_errorUpdateAddress'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.PersonalData.setLoadingStatus({ fieldName: 'loadingContacts', fieldValue: false })
    );
  }
}

function* resetFormSaga(action: IAddressFields | IContactsFields) {
  try {
    const form = action.payload;
    const field = action.type;

    let body = {};

    Object.keys(form).forEach((formField) => {
      body[form[formField].nameKey] = form[formField].value;
    });

    const personalDataBlocks = yield select(selectors.getPersonalDataBlocks);

    const updatedPersonalDataBlocks = updatePersonalDataBlocks(
      personalDataBlocks,
      field,
      body,
      false
    );

    yield put(actions.PersonalData.setPersonalDataBlocks(updatedPersonalDataBlocks));
  } catch (e) {}
}

function* showNoRegistrationWarning(action: any) {
  const state = action.payload;

  if (!state) {
    yield put(
      actions.Toaster.showToaster({
        title: t('personalData.errorRedirectRequirement', { textOnly: true }),
        icon: 'error',
        type: 'danger',
      })
    );
  }
}

function* onUnmountSaga() {
  yield put(actions.PersonalData.reset());
}

export default function* watcherSignin() {
  yield takeLatest('PersonalData/onMount', onMountSaga);
  yield takeLatest('PersonalData/onUnmount', onUnmountSaga);
  yield takeLatest('PersonalData/patchAddressForm', patchAddressFormSaga);
  yield takeLatest('PersonalData/patchContactsForm', patchContactsFormSaga);
  yield takeLatest('PersonalData/setHasRegistrationId', showNoRegistrationWarning);
  yield takeLatest('PersonalData/resetForm', resetFormSaga);
}
