/**
 * Home logic selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getAppSlice = (state: RootState) => state.App;
export const getHomeSlice = (state: RootState) => state.Home;
