export const setValuesStructure = (formValue, defaultValue) => {
  if (typeof formValue === 'string') {
    return formValue !== defaultValue ? defaultValue : formValue;
  }

  if (formValue?.input) {
    return formValue.input;
  }

  return defaultValue;
};
