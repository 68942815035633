/**
 * ExamsCalendar scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { select, put, putResolve, call, takeLatest, debounce } from 'redux-saga/effects';

import { t } from 'app';

import API from 'api';
import { PayloadAction } from '@reduxjs/toolkit';

import { actions } from 'store/rootSlices';
import { reformatCalendarEvents } from '../utils';
import ErrorHandler from 'shared/errorHandler';

import * as selectors from './selectors';

import config from 'config';

const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

function* onMountSaga() {
  try {
    yield put(actions.ExamsCalendar.setLoadingExamsCalendar(true));
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorLoadingRegistrations'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getExamCalendarSaga(action: PayloadAction<string>) {
  yield put(
    actions.ExamsCalendar.setErrorStatus({
      fieldName: 'errLoadingCalendarData',
      fieldValue: false,
    })
  );

  try {
    const registrationId = yield select(selectors.getRegistration);

    if (!registrationId) {
      return;
    }

    const examsCalendarSlice = yield select(selectors.getExamsCalendarSlice);

    let calendarData = yield call(API.secVirtual.getExamCalendar.call, {
      registrationId: registrationId,
      ...examsCalendarSlice.filterParams,
      date: action.payload !== '' ? action.payload : undefined,
    });
    

    calendarData.filters = calendarData.filters.map((filter) => {
      return {
        key: filter,
        translation: t(`examsCalendar.${filter}`, { textOnly: true }),
      };
    });

    yield put(actions.ExamsCalendar.setCalendarFilterTypes(calendarData.filters));

    calendarData.file.url = API_SEC_VIRTUAL + calendarData.file.url;

    let calendarFile = yield call(
      API.secVirtual.getCalendarIcsFile.call,
      calendarData.file.url,
      registrationId
    );

    yield put(actions.ExamsCalendar.setCurrentICS(calendarFile));

    yield put(actions.ExamsCalendar.setEventsData(reformatCalendarEvents(calendarFile)));

    yield put(actions.ExamsCalendar.setLoadingExamsCalendar(false));
    yield put(actions.ExamsCalendar.setInitialCalendarDate(calendarData.dayOfFirstEvent));
  } catch (e) {
    yield put(
      actions.ExamsCalendar.setErrorStatus({
        fieldName: 'errLoadingCalendarData',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.calendar_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.ExamsCalendar.setReset());
}

export default function* watcherSignin() {
  yield takeLatest('ExamsCalendar/onMount', onMountSaga);
  yield debounce(500, 'ExamsCalendar/getExamCalendar', getExamCalendarSaga);
  yield takeLatest('ExamsCalendar/onUnmount', onUnmountSaga);
}
