/**
 * CertificatesDetails scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICertificateDetails {
  certName: string;
  isReady: boolean;
  email: string;
  address: {
    homeAddress: string;
    homeLocality: string;
    homePostalCode: {
      number: string;
      prefix: string;
    };
  };
  history: {
    awaitingPayment: string | null;
    cancelled: string | null;
    rejected: string | null;
    pending: string | null;
    ready: string | null;
  };
  certificate: {
    id: number;
    name: string;
    url: string | null;
  };
  payment: {
    paymentId: number;
    debitId: number;
    costs: {
      costCertificate: string;
      mailCost: string;
    };
    totalCost: number;
    receipt: {
      id: number;
      name: string;
      url: string;
    };
  };
  model: {
    id: number;
    name: string;
    url: string;
  };
  state: {
    id: number;
    registrationId: number;
    theme: string;
    type: string;
    requestDate: string;
    format: string;
    state: string;
    language: string;
    method: string;
    ready: boolean;
    exemptionMotive: string;
    isExempt: boolean;
    responseDate: null | string;
    expirationDate: null | string;
  };
  loadingStatus: {
    loadingCertificatesDetails: boolean;
  };
  errorStatus: {
    errLoadingCertificateDetails: boolean;
  };
}

export interface ICertificateAccessHistory {
  accessDate: string;
  accessTime: string;
  countryIso2: string;
  ipAddress: string;
  country: string;
}

const initialState = {
  accessHistoryModalVisible: false,
  accessHistory: [] as ICertificateAccessHistory[],
  loadingStatus: {
    loadingCertificatesDetails: true,
    loadingAccessHistory: false,
  },
  errorStatus: {
    errLoadingCertificatesDetails: false,
    errorLoadingAccessHistory: false,
  },
  certificatesDetails: null as null | ICertificateDetails,
  certificateFile: undefined as Uint8Array | undefined,
};

export default createSlice({
  name: 'CertificatesDetails',
  initialState,
  reducers: {
    onMount: () => {},
    setCertificatesDetails: (state, action: PayloadAction<ICertificateDetails>) => {
      state.certificatesDetails = action.payload;
    },
    setCertificateFile: (state, action: PayloadAction<Uint8Array | undefined>) => {
      state.certificateFile = action.payload;
    },

    getData: (
      state,
      action: PayloadAction<{ certificateId: number; registrationId: number }>
    ) => {},
    certificatePayment: (state, action: PayloadAction<any>) => {},
    certificatePaymentPending: (state, action: PayloadAction<any>) => {},
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    openChangeHistoryModal: (
      state,
      action: PayloadAction<{ certificateId: number; registrationId: number }>
    ) => {},
    setVisibleAccessHistoryModal: (state, action: PayloadAction<boolean>) => {
      state.accessHistoryModalVisible = action.payload;
    },
    setAccessHistory: (state, action: PayloadAction<ICertificateAccessHistory[]>) => {
      state.accessHistory = action.payload;
    },
    reset: (state) => initialState,
    onUnmount: () => {},
  },
});
