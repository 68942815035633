import { call, takeLatest, put, select } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import API from 'api';
import { getFiltrarPorPeriodo } from './selectors';
import ErrorHandler from 'shared/errorHandler';

function* getGradesStatsSaga() {
  yield put(
    actions.GradesManagement.setLoadingStatus({
      fieldName: 'loadingStats',
      fieldValue: true,
    })
  );
  const stats = yield call(API.pautas.getGradesStats.call);
  yield put(actions.GradesManagement.setPautasStats(stats));
}

function* changeYearRequestSaga(action) {
  try {
    yield put(
      actions.GradesManagement.setLoadingStatus({
        fieldName: 'loadingTable',
        fieldValue: true,
      })
    );

    let period = yield select(getFiltrarPorPeriodo);

    if (!period) {
      const response = yield call(API.pautas.getTeacherGrades.call, { period });

      if (response && response.period) {
        period = response.period;
        yield put(actions.GradesManagement.setFiltrarPorPeriodo({ period: response.period }));
      }
    }

    period = yield select(getFiltrarPorPeriodo);
    const updatedPautas = yield call(API.pautas.getTeacherGrades.call, {
      period: period?.period || period,
    });

    //const response2 = yield call(API.pautas.getTeacherGrades.call, { period: period.period });

    if (updatedPautas?.periodosDisponiveis) {
      yield put(actions.GradesManagement.setPeriodosDisponiveis(updatedPautas.periodosDisponiveis));
    }

    if (updatedPautas?.pautas) {
      yield put(actions.GradesManagement.setPautasLista(updatedPautas.pautas));
    }

    if (action.payload?.changePautasLista) {
      yield put(actions.GradesManagement.setPautasListaChanged(true));
    }
  } catch (error) {
    console.error(error);
    const shouldRun = yield call(ErrorHandler, error);
  }
  yield put(
    actions.GradesManagement.setLoadingStatus({
      fieldName: 'loadingTable',
      fieldValue: false,
    })
  );
  yield put(
    actions.GradesManagement.setLoadingStatus({
      fieldName: 'loadingGrades',
      fieldValue: false,
    })
  );
  yield put(
    actions.GradesManagement.setLoadingStatus({
      fieldName: 'loadingStats',
      fieldValue: false,
    })
  );
}

function* onUnmountSaga() {
  // do nothing
}

export default function* gradesManagementWatcher() {
  yield takeLatest('GradesManagement/onMount', getGradesStatsSaga);
  yield takeLatest('GradesManagement/onMount', changeYearRequestSaga);
  yield takeLatest('GradesManagement/onUnmount', onUnmountSaga);
  yield takeLatest('GradesManagement/changeYearRequest', changeYearRequestSaga);
}
