/**
 * SeriationHistory scene sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { call, put, putResolve, takeLatest } from 'redux-saga/effects';

import API from 'api';

import { t } from 'app';

import { actions } from 'store/rootSlices';

import ErrorHandler from 'shared/errorHandler';

import { getCurrentRoute } from 'utils';

interface IGetSeriationResults {
  type: 'SeriationHistory/getSeriationResult';
  payload: {
    registrationId: number;
    phase?: number;
  };
}

function* onMountSaga() {
  try {
    yield put(actions.SeriationHistory.setLoading(true));
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setRestriction(true));
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetSeriationHistory'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getSeriationHistoryResultsSaga(action: IGetSeriationResults) {
  try {
    yield put(actions.SeriationHistory.setLoading(true));

    let { registrationId, phase } = action.payload;

    let response;

    let scheduleName = null;
    let allocationProb = null;
    let seriationDate = null;
    let seriationId = null;
    let finalSeriation = null;

    if (getCurrentRoute().route.key === 'provisoryseriationhistory') {
      const id = getCurrentRoute().params.id;
      response = yield call(API.sgh.getHistory.call, {
        registrationId: registrationId,
        phase: id,
        final: false,
      });

      allocationProb = response?.history?.[0]?.schedules?.[0]?.allocationProb || null;
      seriationDate = response?.history?.[0]?.schedules?.[0]?.seriationDate || null;
      seriationId = response?.history?.[0]?.seriationId || null;
      finalSeriation = response?.history?.[0]?.final;

      scheduleName = response?.history?.[0]?.schedules?.[0]?.name;
      const currentPhaseName = `${t('sgh.phase', { textOnly: true })} ${response?.currentPhase}`;

      yield put(
        actions.Breadcrumb.overridePath({
          key: 'provisoryseriationhistory',
          name: t('sgh.provisorySeriationHistoryDetailOff', {
            what: `${currentPhaseName}`,
          }),
        })
      );
    } else if (getCurrentRoute().route.key === 'provisoryscheduleseriationhistory') {
      const id =
        getCurrentRoute().params.id === 'null' ? null : Number(getCurrentRoute().params.id);

      response = yield call(API.sgh.getHistory.call, {
        registrationId: registrationId,
        final: false,
        scheduleId: id,
      });

      allocationProb = response?.history?.[0]?.schedules?.[0]?.allocationProb || null;
      seriationDate = response?.history?.[0]?.schedules?.[0]?.seriationDate || null;
      seriationId = response?.history?.[0]?.seriationId || null;
      finalSeriation = response?.history?.[0]?.final;

      scheduleName = response?.history?.[0]?.schedules?.[0]?.name;
      const currentPhaseName = `${t('sgh.phase', { textOnly: true })} ${response?.currentPhase}`;

      yield put(
        actions.Breadcrumb.overridePath({
          key: 'provisoryscheduleseriationhistory',
          name: t('sgh.provisoryScheduleSeriationHistory', {
            what: `${currentPhaseName}`,
          }),
        })
      );
    } else if (getCurrentRoute().route.key === 'seriationhistory') {
      response = yield call(API.sgh.getHistory.call, {
        registrationId: registrationId,
        final: true,
        phase: phase,
        current: phase === undefined || phase === null ? true : undefined,
      });

      if (!phase) {
        if (response.filters.length > 0) {
          phase = response.filters[0];
          if (!response.seriationFinished) {
            if (response.filters.length > 1) {
              phase = response.currentPhase;
            }
          }
        } else {
          phase = response.currentPhase;
        }
      }

      allocationProb = response?.history?.[0]?.schedules?.[0]?.allocationProb || null;
      seriationDate = response?.history?.[0]?.schedules?.[0]?.seriationDate || null;
      seriationId = response?.history?.[0]?.seriationId || null;
      finalSeriation = response?.history?.[0]?.final;

      yield put(
        actions.Breadcrumb.overridePath({
          key: 'seriationhistory',
          name: t('sgh.seriationHistoryBreadcrumb'),
        })
      );
    }

    yield putResolve(
      actions.SeriationHistory.setSeriationHistoryResults(
        response?.history.reduce((acc, historyItem) => {
          if (historyItem?.schedules && !!historyItem.schedules.length) {
            return [...acc, ...historyItem.schedules];
          }
          return acc;
        }, []) || []
      )
    );

    yield put(
      actions.SeriationHistory.setCurrentDetails({
        currentYear: response.year,
        currentScheduleName: scheduleName,
        allocationProb: allocationProb,
        seriationDate: seriationDate,
        seriationId: seriationId,
        currentSemester: response.semester,
        phaseFilterList: response.filters,
        currentPhase: phase || response.currentPhase,
        finalSeriation: finalSeriation,
      })
    );

    yield put(actions.SeriationHistory.setCurrentSelectedPhase(phase || response.currentPhase));

    yield put(actions.SeriationHistory.setLoading(false));
  } catch (e) {
    if (e && e.response && e.response.data && e.response.data.err_code === 'ERR_INVALID_SCHEDULE') {
      yield put(actions.SeriationHistory.setIsScheduleInvalid(true));
      yield put(actions.SeriationHistory.setLoading(false));
    } else {
      const shouldRun = yield call(ErrorHandler, e);
      if (shouldRun) {
        yield put(
          actions.Toaster.showToaster({
            title: t('sgh.actionErrorGetSeriationHistory'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.SeriationHistory.reset());
}

export default function* watcherSignin() {
  yield takeLatest('SeriationHistory/onMount', onMountSaga);
  yield takeLatest('SeriationHistory/onUnmount', onUnmountSaga);
  yield takeLatest('SeriationHistory/getSeriationHistoryResults', getSeriationHistoryResultsSaga);
}
