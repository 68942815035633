/**
 * EditableFieldGroup component
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Grid } from 'semantic-ui-react';
import { Text } from '@paco_ua/pacoui';

interface IProps {
  t?: any;
  name: string;
  errors?: (nameKeys: string[]) => any;
  title?: string;
  disableEdit?: boolean;
  padding?: string;
  form?: any;
  values: {
    nameKey: string;
    type?: string;
    disable?: boolean;
    placeholderKey?: string;
    value: string;
    validations?: [];
    visibleIf?: any;
  }[];
  register?: any;
  children?: any;
  labels: (nameKeys: string[]) => any;
}

const FieldsGroupDisplay: React.FC<IProps> = ({
  t,
  name,
  errors,
  values,
  title,
  padding,
  disableEdit,
  labels,
  form,
  children,
  register = {},
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const getLabels = labels(Object.keys(values).map((key) => values[key].nameKey));

  return (
    <Wrapper>
      <HeaderWrapper>
        <EditableBlockTitle>
          <Text transform={'uppercase'} size="xSmall" color={'grey'} weight={'regular'}>
            {title}
          </Text>
        </EditableBlockTitle>
      </HeaderWrapper>

      <Grid>
        {Object.keys(values).map((key) => {
          return (
            <Column
              paddingtop={padding ? padding : undefined}
              paddingbottom={padding ? padding : undefined}
              key={`editableBlock_${values[key].nameKey}`}
              mobile={16}
              tablet={8}
              computer={8}
              largeScreen={8}
              widescreen={8}
            >
              <TextFieldTitle>
                <Text color="grey" size="xSmall" fontWeight="400">
                  {getLabels[values[key].nameKey]}
                </Text>
              </TextFieldTitle>
              <VisibilityWrapper visible={true}>
                <Text fontWeight="400" color="black" size="mediumSmall">
                  {values[key].value}
                </Text>
              </VisibilityWrapper>
            </Column>
          );
        })}
      </Grid>
    </Wrapper>
  );
};
export default FieldsGroupDisplay;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  .ui.grid {
    flex-grow: 1;
  }
`;

const VisibilityWrapper = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const Column = styled(Grid.Column)<{ paddingtop?: string; paddingbottom?: string }>`
  &&&&& {
    padding-top: ${({ paddingtop }) => (paddingtop ? paddingtop : '1rem')};
    padding-bottom: ${({ paddingbottom }) => (paddingbottom ? paddingbottom : '1rem')};
  }
`;

const TextFieldTitle = styled.div`
  margin: 0px 0px 12px 0px;
`;

const EditableBlockTitle = styled.div`
  margin-bottom: 17px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
