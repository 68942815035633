/**
 * Payments Table container utilities
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { ITranslations, IPagination } from 'scenes/VirtualSecretary/Payments/types';

export const DEFAULT_STRUCTURE = (t: ITranslations) => ({
  header: {
    type: 'columnTitle',
    titles: [
      { text: t('payments.historyTableInvoiceNumber', { textOnly: true }) },
      { text: t('payments.historyTablePaymentDescription', { textOnly: true }) },
      { text: t('payments.historyTablePaymentValue', { textOnly: true }) },
      { text: t('payments.historyTablePaymentDate', { textOnly: true }) },
      //{ text: t('payments.historyTablePaymentATDate', { textOnly: true }) },
      { text: '' },
    ],
  },
  rows: [],
});

export const DEFAULT_PAGINATION = (total: number): IPagination => ({
  hasNext: total > 5 ? true : false,
  hasPrevious: false,
  offset: 0,
  limit: 5,
  total,
});
