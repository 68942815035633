/**
 * CertificatesDetails scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';
import { actions } from 'store/rootSlices';
import { getCurrentRoute } from 'utils';
import PageStructure from 'shared/containers/PageStructure';
import { AnimatedBackground } from '@paco_ua/pacoui';
import CertificateReady from './containers/CertificateReady';
import CertificateNotReady from './containers/CertificateNotReady';
import Header from 'shared/containers/TitleHeader';
import * as selectors from './logic/selectors';
import AccessHistoryModal from './components/AccessHistoryModal';

const CertificatesDetails = ({ t }) => {
  const theme = useContext(ThemeContext);

  const {
    loadingStatus,
    errorStatus,
    certificatesDetails,
    certificateFile,
    accessHistoryModalVisible,
  } = useSelector(selectors.getCertificatesDetailsSlices);

  const { navigateTo } = actions.App;
  const registrations = useSelector(selectors.getRegistrations);

  const user = useSelector(selectors.getUser);

  const { onMount, getData, onUnmount } = actions.CertificatesDetails;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  // get certificate and regidtration ids from the url
  const certificateId = getCurrentRoute().params.certificateId;
  const registrationId = getCurrentRoute().params.registrationId;

  useEffect(() => {
    if (registrations && certificateId && registrationId !== null && registrationId !== undefined) {
      dispatch(
        getData({
          certificateId,
          registrationId,
        })
      );
    }
  }, [certificateId, registrations]);

  return (
    <>
      {(loadingStatus.loadingCertificatesDetails ||
        errorStatus.errLoadingCertificatesDetails ||
        !certificatesDetails) && (
        <PageStructure
          headerLeft={<Header pullBackButton backButton title={t('routes.certificatesDetails')} />}
          mainContent={
            <>
              <LoadingWrapper>
                <AnimatedBackground height={theme.sizes.large} width="20%" />
                <AnimatedBackground height={theme.sizes.large} width="50%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="50%" />
              </LoadingWrapper>
            </>
          }
        />
      )}
      {certificateId && registrationId && certificatesDetails && (
        <>
          {!loadingStatus.loadingCertificatesDetails &&
            !errorStatus.errLoadingCertificatesDetails &&
            certificatesDetails &&
            certificatesDetails.isReady &&
            certificateFile !== null &&
            certificateFile && (
              <CertificateReady
                certificatesDetails={certificatesDetails}
                certificateFile={certificateFile}
              />
            )}
          {!loadingStatus.loadingCertificatesDetails &&
            !errorStatus.errLoadingCertificatesDetails &&
            (!certificatesDetails?.isReady || !certificateFile) && (
              <CertificateNotReady certificatesDetails={certificatesDetails} />
            )}
        </>
      )}
      {accessHistoryModalVisible && <AccessHistoryModal />}
    </>
  );
};

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const MessageNoCertificates = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

export default CertificatesDetails;
