/**
 * Home scene sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { put, call, takeLatest } from 'redux-saga/effects';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';
import { addIntermediatePhases } from '../utils';

import ErrorHandler from 'shared/errorHandler';

function* onMountSaga() {
  try {
    const phases = yield call(API.sgh.getPhases.call);
    yield put(actions.SchedulesHome.setPhases(phases));
    yield put(actions.SchedulesHome.setLoadingPhases(false));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetAllPhases'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('SchedulesHome/onMount', onMountSaga);
  yield takeLatest('SchedulesHome/onUnmount', onUnmountSaga);
}
