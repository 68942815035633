export default {
  SHOW_APPTOOLS: false,
  ENVIRONMENT: 'PROD',
  API_BASE_URL: 'https://api.paco20-play-aws.ua.pt/',
  INTERNAL_API_BASE_URL: null,
  SOCKET_BASE_URL: 'wss://api.paco20-play-aws.ua.pt/',
  SOCKET_SCHEDULES_PATH: 'sgh/websocket/',
  API_STUDENT: 'users-api/',
  API_SEARCH: 'search/',
  API_SCHEDULES_PATH: 'paco_2.0_sgh/v1/',
  API_SEC_VIRTUAL_PATH: 'paco_2.0_secvirtual/v1/',
  API_PAUTAS_PATH: 'paco_2.0_assinatura_pautas/v1/v1/',
  API_PAUTAS_WEBSOCKET: 'pautas/v1/',
  //API_SEC_VIRTUAL_PATH: 'secvirtual_dev/',
  API_TRANSLATIONS_PATH: 'st/',
  WEB_SOCKET_URL: 'wss://api.paco20-play-aws.ua.pt/secvirtual/websocket',
  API_WSO2: 'https://wso2-gw.ua.pt/',
  GA_TRACKING_ID: 'UA-11801506-36',
  HOTJAR_TRACKING_ID: null,
  HOTJAR_SNIPPET_VERSION: null,
  LOGROCKET_ID: null,
  HARDCODED_NMEC: null,
  HARDCODED_ROLES: null,
  SENTRY_ID: null,
  WHITELIST_DEV_NMEC: [
    68160,
    80169,
    500001,
    500002,
    500003,
    500004,
    500005,
    500006,
    500007,
    500008,
    500009,
    500010,
    500011,
    500012,
    500013,
    500014,
    500015,
  ],
  RECAPTCHA_PUBLIC_KEY: '6Lfqq8UgAAAAANTddAj_0qipNFqVL5YWPCmBs_jE',
  WHITELIST_DEV_EMAIL: [
    'cjhs@ua.pt',
    'claudio@ua.pt',
    'rmachado@ubiwhere.com',
    'machado.r@visit.uaveiro.eu',
    'jnvieira@ua.pt',
    'miguel.guimaraes@ua.pt',
    'nmonteiro@ua.pt',
    'ricardosaramago@ua.pt',
    'tpereira@ua.pt',
    'tsousa@ua.pt',
    'ana.trigo@ua.pt',
    'carlos.saraiva@ua.pt',
  ],
  OIDC_CONFIG: {
    clientId: 'TAzfdTs2PhcvoWSKffbqrSS9dq0a',
    clientSecret: 'VEF6ZmRUczJQaGN2b1dTS2ZmYnFyU1M5ZHEwYTo5N0xnZUJCdzV3Uk9mRmg2X3hIcTdFV0o0X1Vh',
    responseType: 'code',
    scope: 'openid profile email',
    state: '1234567890',
    redirectUri: 'https://paco2.ua.pt',
  },
  GRADES_MANAGEMENT_ENCRYPT_DATA_PUBLIC_KEY: `-----BEGIN CERTIFICATE-----
MIIDQjCCAioCCQCzT7WtwlLzRDANBgkqhkiG9w0BAQsFADBjMQswCQYDVQQLEwJJ
VDEMMAoGA1UEChMDQU1BMQ8wDQYDVQQHEwZMaXNib24xDzANBgNVBAgTBkxpc2Jv
bjELMAkGA1UEBhMCUFQxFzAVBgNVBAMTDkNNRC5hc3NpbmF0dXJhMB4XDTIzMDQw
NTE0NDkwOVoXDTI2MDQwNDE0NDkwOVowYzELMAkGA1UECxMCSVQxDDAKBgNVBAoT
A0FNQTEPMA0GA1UEBxMGTGlzYm9uMQ8wDQYDVQQIEwZMaXNib24xCzAJBgNVBAYT
AlBUMRcwFQYDVQQDEw5DTUQuYXNzaW5hdHVyYTCCASIwDQYJKoZIhvcNAQEBBQAD
ggEPADCCAQoCggEBAMytTBfxV2ezp2lAsAJvv8lH3SdEuNJ57bCUbGF6OcGRUoIj
5vVpaKFBZgTOgudfGopXp2lTyaO/EksHqQYhrTcH/eYwlNvtjwEdrn+WR+X0m5Cm
UvODuSnpbJH/gEKHk+eeYkmUxVqLgvrZjEXK7SRwYUYeJydIj5zUC7/at7NDXTCq
jnKQjG2g0CMX12SPZHMeJPE70/+DcQTq0UwxbPWqIyWWlTG+Ikmzdhh2NYt6z2KN
SV9K7VEvnh/zkntfhyUOsm0ffK6lnSESDBoKLGD2Ir23bPBZBetL8lr6RLNr9Pqq
oBfrLf4BpO/nodDswLChX2+pC+6wBeQLQ77F+jkCAwEAATANBgkqhkiG9w0BAQsF
AAOCAQEAr+tShuBiayXB143d9oom09g69Pf9X2K3AXQCDwVOIHCYOjST5pdWfoAx
ZGB13kuVU0w2kq5MRwtFGCWMXq4jZfeMF7CpyJoxzEtpDgP/SMQUT2mCFqZuRp33
bbggws6Hw5UlhXvkCCltmM5+q0tvefQNHLCQCxdp5LASZ/kNJs7ypQEI6V6ZjIw0
GBCn5kKClDrJICwAaUuEDsD5z7IwRiyAuoKsTtcHconwEZCkZLl5uBgXGXqeiKM4
fJHf4tKrvHmOcPlk1bp1GbXUaO/GiimjYHSvZMV860lKXOGCxgYYZMs0d2/aoQ6h
DnNq6v8qtoPArn7Fb8ZOfvMq/Om3fA==
-----END CERTIFICATE-----`,
};
