/**
 * MardownRenderer container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useContext, useMemo, useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import emoji from '@fec/remark-a11y-emoji';
import { Accordion } from 'semantic-ui-react';

import styled, { ThemeContext } from 'styled-components';

import * as selectors from './selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

import { Text } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';

import { getHeadings } from './utils';

interface IProps {
  pageStructure?: {
    headerLeft?: any;
    secondaryContent?: any;
    subHeader?: any;
    loading?: boolean;
    expanded?: boolean;
  };
  value: string;
  valueEn: string;
  valueCh: string;
}

const MardownRenderer: React.FC<IProps> = ({ pageStructure, value, valueEn, valueCh }) => {
  const [children, setChildren] = useState('');
  const [TOC, setTOC] = useState(null as React.ReactNode | null);
  const [activeItemTOC, setActiveItemTOC] = useState<number | null>(null);
  const [openItemTOC, seOpenItemTOC] = useState(null);
  const usedParam = useRef(false);

  const countHeadings = useRef(0);
  const countCaptions = useRef(0);

  const { currentLocale } = useSelector(selectors.getAppSlice);
  const theme = useContext(ThemeContext);

  const renderers = {
    root: (props) => {
      countHeadings.current = 0;
      countCaptions.current = 0;
      return props.children;
    },
    break: (props) => {
      return props.children;
    },
    paragraph: (props) => {
      return (
        <Text color="plusDarkGrey" size="article" breakWord>
          {props.children}
        </Text>
      );
    },
    strong: (props) => {
      return (
        <Text color="plusDarkGrey" as="span" weight="bold" size="article">
          {props.children}
        </Text>
      );
    },
    listItem: (props) => {
      return (
        <Text color="plusDarkGrey" as="li" size="article" breakWord>
          {props.children}
        </Text>
      );
    },
    link: (props) => {
      return (
        <Text as="a" href={props.href} target="_blank" color="primary" size="article">
          {props.children}
        </Text>
      );
    },
    image: (props) => {
      countCaptions.current++;
      return (
        <ImageWrapper>
          <img alt={props.alt || ''} src={props.src} />
          {props.alt && (
            <ImageCaption>
              <Text weight="light" as="span" color="grey" size="article">
                Fig.{countCaptions.current} {props.alt}
              </Text>
            </ImageCaption>
          )}
        </ImageWrapper>
      );
    },
    heading: (props) => {
      countHeadings.current++;
      return (
        <HeadingWrapper>
          <Text
            breakWord
            weight={props.level === 1 ? 'bold' : 'medium'}
            size={
              props.level === 1
                ? 'large'
                : props.level === 2
                ? 'medium'
                : props.level === 3
                ? 'mediumSmall'
                : 'small'
            }
            color={props.level === 1 || props.level === 2 ? 'primary' : 'plusDarkGrey'}
          >
            {props.children}
          </Text>
        </HeadingWrapper>
      );
    },
  };

  const renderTOC = (headings) => {
    let rootPanels = [] as any;
    let firstKey = null;

    let prevLevel = [] as number[];

    const setRootPanels = (key) => {
      const heading = headings[key];

      if (heading.level === 1) {
        if (firstKey === null) {
          firstKey = key;
        }

        rootPanels.push({
          key: key,
          title: {
            active: activeItemTOC === key,

            content: (
              <TextWrapper
                onClick={() => {
                  setActiveItemTOC(key);
                  scrollToOffset(`heading-${key}`);
                }}
              >
                <Text
                  size="medium"
                  weight={activeItemTOC === key ? 'medium' : 'regular'}
                  color={activeItemTOC === key ? 'primary' : 'plusDarkGrey'}
                >
                  {heading.value}
                </Text>
              </TextWrapper>
            ),
            icon: <IconWrapper></IconWrapper>,
          },
          content: { active: openItemTOC === key, content: [] },
        });
        prevLevel = [key];
      }

      if (heading.level === 2 && rootPanels[prevLevel[0]]) {
        rootPanels[prevLevel[0]] = {
          ...rootPanels[prevLevel[0]],
          content: {
            ...rootPanels[prevLevel[0]].content,
            content: [
              ...rootPanels[prevLevel[0]].content.content,
              {
                key: key,
                title: {
                  active: activeItemTOC === key,
                  content: (
                    <TextWrapper
                      onClick={() => {
                        setActiveItemTOC(key);
                        scrollToOffset(`heading-${key}`);
                      }}
                    >
                      <Text
                        size="mediumSmall"
                        weight={activeItemTOC === key ? 'medium' : 'regular'}
                        color={activeItemTOC === key ? 'primary' : 'plusDarkGrey'}
                      >
                        {heading.value}
                      </Text>
                    </TextWrapper>
                  ),
                  icon: <IconWrapper></IconWrapper>,
                },
              },
            ],
          },
        };
      }

      if (headings[key + 1]) {
        setRootPanels(key + 1);
      }
    };

    setRootPanels(0);
    rootPanels = rootPanels.map((rootPanel) => {
      if (!rootPanel.content.content.length) {
        return {
          key: rootPanel.key,
          title: rootPanel.title,
        };
      } else {
        return {
          key: rootPanel.key,
          title: {
            ...rootPanel.title,
            icon: (
              <IconWrapper
                open={rootPanel.key === openItemTOC}
                onClick={() => {
                  if (rootPanel.key === openItemTOC) {
                    seOpenItemTOC(null);
                  } else {
                    seOpenItemTOC(rootPanel.key);
                  }
                }}
              >
                <FontAwesomeIcon size="lg" icon={faAngleDown} />
              </IconWrapper>
            ),
          },
          content: {
            ...rootPanel.content,
            content: <Accordion.Accordion panels={rootPanel.content.content} />,
          },
        };
      }
    });

    activeItemTOC === null && setActiveItemTOC(firstKey);
    setTOC(<Accordion panels={rootPanels} />);
  };

  const renderContent = (rerender) => {
    let tocValue = '';
    if (currentLocale === 'pt') {
      tocValue = checkTOC(value);
    } else if (currentLocale === 'en') {
      tocValue = checkTOC(valueEn);
    } else if (currentLocale === 'zh') {
      tocValue = checkTOC(valueCh);
    }
    if (rerender) {
      setChildren(tocValue);
    }
  };

  useEffect(() => {
    if (TOC) {
      renderContent(false);
    }

    if (window.location.hash) {
      const headingNr = window.location.hash.match(/\d+/);

      if (headingNr !== null && headingNr !== undefined) {
        if (activeItemTOC !== null && activeItemTOC !== undefined) {
          if (activeItemTOC !== parseInt(headingNr[0]) && !usedParam.current) {
            usedParam.current = true;
            setActiveItemTOC(parseInt(headingNr[0]));
            scrollToOffset(window.location.hash.substring(1));
          }
        }
      }
    }

    return () => {
      setTOC(null);
    };
  }, [activeItemTOC, children, currentLocale, openItemTOC]);

  const scrollToOffset = (id) => {
    const yOffset = 0;
    const element = document.getElementById(id);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      //      document.getElementById('appBody')?.scrollTo({ top: y, behavior: 'smooth' });
      document.getElementById(id)?.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  const checkTOC = (markdown) => {
    const includesTOC = markdown.includes('[[toc]]');
    if (includesTOC) {
      const headings = getHeadings(markdown);
      renderTOC(headings);
      markdown = markdown.replace('[[toc]]', '');
    }
    return markdown;
  };

  useEffect(() => {
    renderContent(true);
  }, [currentLocale, value, valueEn, valueCh]);

  const reactMarkdown = useMemo(
    () => (
      <WrapperMarkdown>
        <ReactMarkdown plugins={[gfm, emoji]} children={children} renderers={renderers} />
      </WrapperMarkdown>
    ),
    [children]
  );

  return (
    <PageStructure
      loading={pageStructure?.loading}
      headerLeft={pageStructure?.headerLeft}
      expanded={pageStructure?.expanded}
      subHeader={pageStructure?.subHeader}
      secondaryContent={<WrapperTOC>{TOC}</WrapperTOC> || pageStructure?.secondaryContent}
      mainContent={reactMarkdown}
    />
  );
};

export default MardownRenderer;

const HeadingWrapper = styled.div`
  display: block;
  margin-bottom: 12px;

  &:not(:first-child) {
    margin-top: 12px;
  }
`;

const WrapperMarkdown = styled.div`
  &&& {
    > div {
      padding: 8px 0;
    }

    ${HeadingWrapper} {
      padding: 0;
    }

    ol {
      counter-reset: item;
      list-style: none;

      li {
        display: block;
        counter-increment: item;

        div:first-child {
          padding-left: 4px;
        }

        &::before {
          content: counter(item) '.';
          color: ${({ theme }) => theme.colors.primary};
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }

        &:not(:last-child) {
          margin-bottom: 8px;
        }
      }
    }

    ul {
      li {
        display: block;

        &::before {
          content: '•';
          color: ${({ theme }) => theme.colors.primary};
          font-weight: bold;
          display: inline-block;
          width: 1em;
          margin-left: -1em;
        }
      }
    }
  }
`;

const WrapperTOC = styled.div`
  &&& {
    .accordion {
      padding-left: 32px;
      .accordion {
        padding-left: 0;
        margin-top: 0;
      }
      & .title {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        div {
          text-align: left;
        }
      }
    }
  }
`;

const IconWrapper = styled.div<{ open?: boolean }>`
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  transform: rotate(${({ open }) => (open ? '180deg' : '0deg')});
  transition: transform linear 0.1s;

  &:hover {
    color: ${({ theme }) => theme.colors.primary} !important;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;

  &:hover {
    div {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }
`;

const ImageCaption = styled.div``;

const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px;

  img {
    width: 80%;
    height: auto;
  }

  ${ImageCaption} {
    padding-top: 8px;
    width: 80%;
    height: auto;
  }
`;
