/**
 * MySchedules scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMySchedule, IUc } from 'shared/types';
import { selectClassesToViewSchedule } from '../utils';

export interface IScheduleToDelete {
  id: number;
  name: string;
}
export interface ICurrentPhase {
  phase: number | null;
  year: string | null;
  period: string | null;
  isFree: boolean | null;
  finished: boolean | null;
  hasAllocatedSchedule: boolean | null;
  attemptedSchedule: boolean | null;
  seriationFinished: boolean | null;
}

const initialState = {
  createdSchedules: {
    activePhase: null,
    finishedPhases: [],
  } as any,
  currentPhase: null as ICurrentPhase | null,
  scheduleToDelete: null as IScheduleToDelete | null,
  loadingSchedules: true,
  showHistory: false,
  loadingScheduleToView: false,
  savingMySchedules: false,
  deleteModalOpen: false,
  dragEnabled: true,
  warningNoAccess: false as boolean,
  warningNoAccessMotive: null as string | null,
  viewScheduleModalIsOpen: false,
  currentActivePhase: null as number | null,
  seriationDate: null as string | null,
  nextSeriationDate: null as string | null,
  lastSeriatedPhase: null as number | null,
  ucs: [] as IUc[],
  viewScheduleHideFaces: false as boolean,
  scheduleGridInterval: { min: '8:00', max: '20:00' } as { min: string; max: string },
};

export default createSlice({
  name: 'MySchedules',
  initialState,
  reducers: {
    onMount: () => {},
    reset: (state) => initialState,
    getSchedules: (
      state,
      action: PayloadAction<{ registrationId: number; activateLoading: boolean; data?: any }>
    ) => {},
    onPhaseUpdate: () => {},
    addScheduleToDelete: (state, action: PayloadAction<IScheduleToDelete>) => {
      state.deleteModalOpen = true;
      state.scheduleToDelete = action.payload;
    },
    removeScheduleToDelete: (state) => {
      state.scheduleToDelete = null;
    },
    deleteSchedule: (state, action: PayloadAction<IScheduleToDelete>) => {},
    setDeleteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.deleteModalOpen = action.payload;
    },
    setCurrentActivePhase: (state, action: PayloadAction<null | number>) => {
      state.currentActivePhase = action.payload;
    },
    setLastSeriatedPhase: (state, action: PayloadAction<number>) => {
      state.lastSeriatedPhase = action.payload;
    },
    setNextSeriationDate: (state, action: PayloadAction<string | null>) => {
      state.nextSeriationDate = action.payload;
    },
    setSeriationDate: (state, action: PayloadAction<string | null>) => {
      state.seriationDate = action.payload;
    },
    updateSchedules: (state, action: PayloadAction<IMySchedule[]>) => {},
    setSchedules: (state, action: PayloadAction<IMySchedule[]>) => {
      state.createdSchedules = action.payload;
    },
    setSavingMySchedules: (state, action: PayloadAction<boolean>) => {
      state.savingMySchedules = action.payload;
    },
    setLoadingScheduleToView: (state, action: PayloadAction<boolean>) => {
      state.loadingScheduleToView = action.payload;
    },
    setWarningNoAccess: (state, action: PayloadAction<boolean>) => {
      state.warningNoAccess = action.payload;
    },
    setWarningNoAccessMotive: (state, action: PayloadAction<string>) => {
      state.warningNoAccessMotive = action.payload;
    },
    setShowHistory: (state, action: PayloadAction<boolean>) => {
      state.showHistory = action.payload;
    },
    setDragEnabled: (state, action: PayloadAction<boolean>) => {
      state.dragEnabled = action.payload;
    },
    getScheduleToView: (
      state,
      action: PayloadAction<{
        id: number;
        finishedPhase: boolean;
      }>
    ) => {},
    setLoadingSchedules: (state, action: PayloadAction<boolean>) => {
      state.loadingSchedules = action.payload;
    },
    hideScheduleModalOpening: (state) => {
      state.viewScheduleModalIsOpen = false;
    },
    setScheduleGridInterval: (state, action: PayloadAction<{ min: string; max: string }>) => {
      state.scheduleGridInterval = action.payload;
    },
    showScheduleModalOpening: (state) => {
      state.viewScheduleModalIsOpen = true;
    },
    setUcs: (
      state,
      action: PayloadAction<{
        ucs: IUc[];
        hideFaces: boolean;
      }>
    ) => {
      state.ucs = action.payload.ucs;
      state.viewScheduleHideFaces = action.payload.hideFaces;
    },
    selectClassesToViewSchedule: (state, action: PayloadAction<number[]>) => {
      state.ucs = selectClassesToViewSchedule(state.ucs, action);
    },
    setCurrentPhase: (state, action: PayloadAction<ICurrentPhase>) => {
      state.currentPhase = action.payload;
    },
  },
});
