/**
 * App utils
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 * @format
 */

import i18n from 'i18next';
import moment from 'moment';
import numeral from 'numeral';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';
import { matchPath } from 'react-router';
import ReactGA from 'react-ga';

import Routes from 'routes';

import translations, { numeralsInit } from 'translations';

import { createBrowserHistory } from 'history';
import { useEffect, useRef } from 'react';

import config from 'config';

//longlived variables
let lastRoutes = [];

const _history = createBrowserHistory({
  /* pass a configuration object here if needed */
});

export const history = _history;

export const initLocales = () => {
  numeralsInit();

  i18n.use(initReactI18next).init({
    lng: 'pt',
    fallbackLng: { default: ['pt'] },
    resources: translations,
  });

  moment.locale(i18n.language);
  numeral.locale(i18n.language);
};

export const i18nInstance = i18n;

export const hasPermission = (scope) => {
  const unparsedUser = localStorage.getItem('user');
  if (unparsedUser) {
    const user = JSON.parse(unparsedUser);
    if (user.scope) {
      return user.scope.includes(scope);
    }
  }

  return false;
};

export const immutableRemove = (items, index) => {
  return [...items.slice(0, index), ...items.slice(index + 1, items.length)];
};

export const immutableReplace = (items, index, data) => {
  return [
    ...items.slice(0, index),
    {
      ...data,
    },
    ...items.slice(index + 1),
  ];
};

export const getStorage = (item) => {
  const data = localStorage.getItem(item);
  if (data === null) return null;
  return JSON.parse(data);
};

export const setStorage = (item, data) => {
  localStorage.setItem(item, JSON.stringify(data));
};

export const hideLogs = () => {
  if (process.env.REACT_APP_ENV !== 'development') {
    console.log = () => {};
    console.warn = () => {};
    console.error = () => {};
  }
};

export const debounce = (func, delay) => {
  let timerId;
  return (...args) => {
    const boundFunc = func.bind(this, ...args);
    clearTimeout(timerId);
    timerId = setTimeout(boundFunc, delay);
  };
};

export const getCurrentRoute = () => {
  let foundRoute = null;
  Routes.forEach((route) => {
    if (route.path && !route.disabled) {
      Object.keys(route.path).forEach((pathKey) => {
        const path = route.path[pathKey];
        let match = matchPath(history.location.pathname, {
          path: path,
          exact: route.exact,
        });
        if (match) {
          foundRoute = { route, params: match.params };
        }
      });
    }
  });

  return foundRoute;
};

export const getLastRoute = () => {
  return lastRoutes[lastRoutes.length - 1];
};

export const addRouteToHistory = () => {
  if (lastRoutes.length >= 2) {
    lastRoutes.pop();
  }
  lastRoutes.unshift(getCurrentRoute());
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const generateMediaUrl = (uri) => {
  //const newURL = MEDIA_BASE_URL.substring(8)
  //if (uri.includes(newURL)) return uri
  //if (uri.includes('//www')) return uri
  //return `${MEDIA_BASE_URL}${uri}`
  return uri;
};

export const downloadFile = (uri) => {
  var link = document.createElement('a');
  link.setAttribute('type', 'hidden');
  link.download = uri;
  link.target = '_blank';
  link.href = generateMediaUrl(uri);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const createAndDownloadFile = (data, format, type) => {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, uuid() + '.' + format);
  else {
    // Others
    var a = document.createElement('a'),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = uuid() + '.' + format;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
};

export const uuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const GAEvent = (
  data /*: {
  category: String;
  action: String;
  label?: String;
  value?: number;
  nonInteraction?: Boolean;
  transport?: String;
}*/
) => {
  if (config.GA_TRACKING_ID) {
    ReactGA.event(data);
  }
};
