/**
 * CertificateReady container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import numeral from 'numeral';
import moment from 'moment';
import { t } from 'app';
import { actions } from 'store/rootSlices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faChevronRight, faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { StatusLabel, Segment, Text, Button } from '@paco_ua/pacoui';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import { MbIcon, MbWayIcon } from 'shared/components/Icons';
import CertificateState from '../../components/CertificateState';
import * as selectors from '../../logic/selectors';
import { getDeliveryMethod, getRegistration } from '../../utils';
import config from 'config';
import API from 'api';

/*
 * this Component is used to display certificates that are not ready, but also
 * certificates that are ready in paper format
 */
const CertificatesNotReady = ({ certificatesDetails }) => {
  const dispatch = useDispatch();
  const user = useSelector(selectors.getUser);

  const theme = useContext(ThemeContext);

  const { certificatePayment, certificatePaymentPending } = actions.CertificatesDetails;

  const PAID_STATUS_COLOR = {
    noDebits: theme.colors.darkGrey,
    awaitingPayment: theme.colors.decisionYellow,
    paid: theme.colors.successGreen,
  };

  const [selectedRegistration, setSelectedRegistration] = useState(undefined as any);

  const getPaymentLabelColor = (certificatesDetails) => {
    if (certificatesDetails.history.awaitingPayment) {
      return theme.colors.successGreen;
    } else {
      return theme.colors.decisionYellow;
    }
  };

  useEffect(() => {
    if (user.registrations && user.registrations.length > 0) {
      setSelectedRegistration(
        getRegistration(certificatesDetails.state.registrationId, user.registrations)
      );
    }
  }, [user.registrations]);

  return (
    <PageStructure
      headerLeft={<Header pullBackButton backButton title={certificatesDetails.model.name} />}
      mainContent={
        <Wrapper>
          {
            <Segment>
              <SegmentTitle weight="regular" color="plusDarkGrey" size="large">
                {t('certificates.detailsState')}
              </SegmentTitle>
              <CertificateState certificatesDetails={certificatesDetails} />
            </Segment>
          }
          <Segment>
            <SegmentTitle weight="regular" color="plusDarkGrey" size="large">
              {t('certificates.requestedCertificate')}
            </SegmentTitle>

            <SegmentDoc>
              <Segment padding="16px 20px" verticalCenter>
                <ModelText
                  size="article"
                  color="plusDarkGrey"
                  weight="medium"
                  iconPadding="18px"
                  icon={<FontAwesomeIcon size="lg" icon={faFilePdf} />}
                >
                  {certificatesDetails.model.name}
                </ModelText>
              </Segment>
            </SegmentDoc>

            <SegmentTitle weight="regular" color="plusDarkGrey" size="large">
              {t('certificates.detailsSubmittedData')}
            </SegmentTitle>

            <DetailsStudentWrapper>
              <Grid.Column largeScreen="8" tablet="16" mobile="16" computer="8">
                <DetailsElement>
                  <Text size="xSmall" color="grey">
                    {t('certificates.detailsStudentName')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {certificatesDetails.studentName}
                  </Text>
                </DetailsElement>
              </Grid.Column>
              <Grid.Column largeScreen="8" tablet="16" mobile="16" computer="8">
                <DetailsElement>
                  <Text size="xSmall" color="grey">
                    {t('certificates.registration')}
                  </Text>
                  {selectedRegistration !== undefined && (
                    <Text size="article" weight="medium" color="plusDarkGrey">
                      {selectedRegistration.courseCode}
                      <Text size="article" weight="regular" color="plusDarkGrey">
                        - {selectedRegistration.courseName}
                      </Text>
                    </Text>
                  )}
                </DetailsElement>
              </Grid.Column>
            </DetailsStudentWrapper>

            <DetailsType>
              <CostDetails>
                <Text size="article" weight="regular" color="primary">
                  {t('certificates.exemptionTitle')}
                </Text>
                <Text size="small" weight="regular" color="plusDarkGrey">
                  {certificatesDetails.state.isExempt
                    ? t('certificates.withExemption')
                    : t('certificates.withoutExemption')}
                </Text>

                {certificatesDetails.state.isExempt && (
                  <Text size="small" color="plusDarkGrey">
                    {t('certificates.exemptionMotiveShort')}:{' '}
                    {certificatesDetails.state.exemptionMotive}
                  </Text>
                )}
              </CostDetails>
              <DetailsTypeItem>
                <Text size="mediumSmall" weight="regular" color="primary">
                  {t('certificates.detailsLang')}
                </Text>
                <Text size="article" weight="regular" color="plusDarkGrey">
                  {certificatesDetails.state.language}
                </Text>
              </DetailsTypeItem>
              <DetailsTypeItem>
                <Text size="mediumSmall" weight="regular" color="primary">
                  {t('certificates.detailsFormat')}
                </Text>
                <Text size="article" weight="regular" color="plusDarkGrey">
                  {certificatesDetails.state.format}
                </Text>
              </DetailsTypeItem>
              {certificatesDetails.state.deliveryMethod && (
                <DetailsTypeItem>
                  <Text size="mediumSmall" weight="regular" color="primary">
                    {t('certificates.deliveryMethod')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {certificatesDetails.state.deliveryMethod}
                  </Text>
                </DetailsTypeItem>
              )}
              {getDeliveryMethod(certificatesDetails) && (
                <DetailsTypeItem>
                  <Text size="mediumSmall" weight="regular" color="primary">
                    {t('certificates.detailsDeliveryType')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {getDeliveryMethod(certificatesDetails)}
                  </Text>
                </DetailsTypeItem>
              )}
            </DetailsType>

            {certificatesDetails.email && (
              <>
                <Separator />

                <ConfirmationTitle>
                  <Text size="xSmall" weight="regular" transform="uppercase" color="grey">
                    {t('certificates.email')}
                  </Text>
                </ConfirmationTitle>

                <DetailsWrapper>
                  <Grid.Column largeScreen="8" tablet="16" mobile="16" computer="8">
                    <DetailsElement>
                      <Text size="xSmall" color="grey">
                        {t('certificates.detailsStudentEmail')}
                      </Text>
                      <Text size="article" weight="regular" color="plusDarkGrey">
                        {certificatesDetails.email}
                      </Text>
                    </DetailsElement>
                  </Grid.Column>
                </DetailsWrapper>
              </>
            )}

            {certificatesDetails?.address?.homeAddress && (
              <>
                <Separator />

                <ConfirmationTitle>
                  <Text size="xSmall" weight="regular" transform="uppercase" color="grey">
                    {t('certificates.address')}
                  </Text>
                </ConfirmationTitle>

                <DetailsWrapper>
                  {certificatesDetails?.address?.homeAddress && (
                    <Grid.Column largeScreen="8" tablet="16" mobile="16" computer="8">
                      <DetailsElement>
                        <Text size="xSmall" color="grey">
                          {t('generic.address')}
                        </Text>
                        <Text size="article" weight="regular" color="plusDarkGrey">
                          {certificatesDetails.address.homeAddress}
                        </Text>
                      </DetailsElement>
                    </Grid.Column>
                  )}
                  {certificatesDetails?.address?.homeLocality && (
                    <Grid.Column largeScreen="8" tablet="16" mobile="16" computer="8">
                      <DetailsElement>
                        <Text size="xSmall" color="grey">
                          {t('generic.locality')}
                        </Text>
                        <Text size="article" weight="regular" color="plusDarkGrey">
                          {certificatesDetails.address.homeLocality}
                        </Text>
                      </DetailsElement>
                    </Grid.Column>
                  )}
                  {certificatesDetails?.address?.homePostalCode?.number && (
                    <Grid.Column largeScreen="8" tablet="16" mobile="16" computer="8">
                      <DetailsElement>
                        <Text size="xSmall" color="grey">
                          {t('generic.postCode')}
                        </Text>
                        <Text size="article" weight="regular" color="plusDarkGrey">
                          {certificatesDetails.address.homePostalCode.number}
                        </Text>
                      </DetailsElement>
                    </Grid.Column>
                  )}
                </DetailsWrapper>
              </>
            )}
          </Segment>

          {certificatesDetails &&
            certificatesDetails.state &&
            certificatesDetails.state.paymentState === 'awaitingPayment' &&
            !certificatesDetails.payment.paymentId && (
              <Segment>
                <SegmentTitle weight="regular" color="plusDarkGrey" size="large">
                  {t('certificates.paymentData')}
                </SegmentTitle>
                <SegmentPayment
                  onClick={() => {
                    dispatch(certificatePayment([certificatesDetails.payment.debit]));
                  }}
                >
                  <Segment padding="16px 20px">
                    <SegmentPaymentWrapper>
                      <SegmentAreaLeft>
                        <SegmentPaymentIcons>
                          <SegmentPaymentIconInstance>
                            <MbWayIcon width={36} height={36} />
                          </SegmentPaymentIconInstance>
                          {'/'}
                          <SegmentPaymentIconInstance>
                            <MbIcon width={28} height={28} />
                          </SegmentPaymentIconInstance>
                        </SegmentPaymentIcons>
                        <Text size="article" color="plusDarkGrey" weight="medium">
                          {t('certificates.certificatePayment')}
                        </Text>
                      </SegmentAreaLeft>
                      <SegmentAreaRight>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </SegmentAreaRight>
                    </SegmentPaymentWrapper>
                  </Segment>
                </SegmentPayment>

                {/*<PaymentRow>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {certificatesDetails.model.name}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {t('generic.euros', {
                      count: numeral(
                        certificatesDetails.payment.costs.find((item) => item.type === 'certCost')
                          .value
                      ).format('0.00'),
                    })}
                  </Text>
                  </PaymentRow>*/}

                {certificatesDetails.payment.costs &&
                  certificatesDetails.payment.costs.map((item) => {
                    return (
                      <PaymentRow margin>
                        <DetailsElementPayment>
                          <Text size="article" weight="regular" color="plusDarkGrey">
                            {t(`certificates.${item.type}`)}
                          </Text>
                        </DetailsElementPayment>

                        <Text size="article" weight="regular" color="plusDarkGrey">
                          {t('generic.euros', {
                            count: numeral(item.value).format('0.00'),
                          })}
                        </Text>
                      </PaymentRow>
                    );
                  })}

                <SeparatorPayment />
                <PaymentRow>
                  <Text size="article" transform="uppercase" weight="regular" color="plusDarkGrey">
                    {t('certificates.totalPayment')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {t('generic.euros', {
                      count: numeral(certificatesDetails.payment.totalCost).format('0.00'),
                    })}
                  </Text>
                </PaymentRow>
              </Segment>
            )}

          {certificatesDetails &&
            certificatesDetails.state &&
            certificatesDetails.state.paymentState === 'awaitingPayment' &&
            certificatesDetails.payment.paymentId && (
              <Segment>
                <SegmentTitle weight="regular" color="plusDarkGrey" size="large">
                  {t('certificates.paymentData')}
                </SegmentTitle>
                <SegmentPayment
                  onClick={() => {
                    dispatch(certificatePaymentPending([certificatesDetails.payment.debit]));
                  }}
                >
                  <Segment padding="16px 20px">
                    <SegmentPaymentWrapper>
                      <SegmentAreaLeft>
                        <SegmentPaymentIcons>
                          <SegmentPaymentIconInstance>
                            <MbWayIcon width={36} height={36} />
                          </SegmentPaymentIconInstance>
                          {'/'}
                          <SegmentPaymentIconInstance>
                            <MbIcon width={28} height={28} />
                          </SegmentPaymentIconInstance>
                        </SegmentPaymentIcons>
                        <Text size="article" color="plusDarkGrey" weight="medium">
                          {t('certificates.certificatePaymentPending')}
                        </Text>
                      </SegmentAreaLeft>
                      <SegmentAreaRight>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </SegmentAreaRight>
                    </SegmentPaymentWrapper>
                  </Segment>
                </SegmentPayment>
                {certificatesDetails.payment.costs &&
                  certificatesDetails.payment.costs.map((item) => {
                    return (
                      <PaymentRow margin>
                        <DetailsElementPayment>
                          <Text size="article" weight="regular" color="plusDarkGrey">
                            {t(`certificates.${item.type}`)}
                          </Text>
                        </DetailsElementPayment>

                        <Text size="article" weight="regular" color="plusDarkGrey">
                          {t('generic.euros', {
                            count: numeral(item.value).format('0.00'),
                          })}
                        </Text>
                      </PaymentRow>
                    );
                  })}

                <SeparatorPayment />
                <PaymentRow>
                  <Text size="article" transform="uppercase" weight="regular" color="plusDarkGrey">
                    {t('certificates.totalPayment')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {t('generic.euros', {
                      count: numeral(certificatesDetails.payment.totalCost).format('0.00'),
                    })}
                  </Text>
                </PaymentRow>
              </Segment>
            )}

          {certificatesDetails &&
            certificatesDetails.state &&
            certificatesDetails.state.paymentState === 'paid' && (
              <Segment>
                <SegmentTitle weight="regular" color="plusDarkGrey" size="large">
                  {t('certificates.paymentData')}
                </SegmentTitle>
                <SegmentPayment
                  notButton
                  /* onClick={() => {
                    dispatch(certificatePayment([certificatesDetails.payment.debit]));
                  }} */
                >
                  <DebitSegmentWrapper>
                    <Segment borderColor={theme.colors.softRegularGrey} padding="14px 24px">
                      <PaymentsContainer>
                        <Grid stackable width={15}>
                          <Grid.Column width={certificatesDetails.payment.receipt !== null ? 3 : 5}>
                            <PaymentMethodWrapper>
                              <SegmentPaymentIconInstance>
                                {certificatesDetails.payment.details.type === 'mb' && (
                                  <MbIcon width={34} />
                                )}
                                {certificatesDetails.payment.details.type === 'mbway' && (
                                  <MbWayIcon width={28} height={28} />
                                )}
                                {certificatesDetails.payment.details.type !== 'mbway' &&
                                  certificatesDetails.payment.details.type !== 'mb' && (
                                    <FontAwesomeIcon icon={faMoneyBill} width={28} height={28} />
                                  )}
                              </SegmentPaymentIconInstance>
                              <Text size="small" weight="medium" color="plusDarkGrey">
                                {certificatesDetails.payment.details.type === 'mb' &&
                                  t('payments.atmReference')}
                                {certificatesDetails.payment.details.type === 'mbway' &&
                                  t('payments.mbWay')}
                                {certificatesDetails.payment.details.type !== 'mbway' &&
                                  certificatesDetails.payment.details.type !== 'mb' &&
                                  t('payments.other')}
                              </Text>
                            </PaymentMethodWrapper>
                          </Grid.Column>

                          {certificatesDetails.payment.details.type === 'mb' && (
                            <>
                              <Grid.Column width={1}>
                                <WrapperColumn>
                                  <Text size="small" weight="regular" color="grey">
                                    {t('payments.entity', { textOnly: true })}
                                  </Text>
                                  <TuitionPriceText
                                    size="mediumSmall"
                                    weight="regular"
                                    color="darkGrey"
                                  >
                                    {`${certificatesDetails.payment.details.entity}`}
                                  </TuitionPriceText>
                                </WrapperColumn>
                              </Grid.Column>

                              <Grid.Column width={1}>
                                <WrapperColumn>
                                  <Text size="small" weight="regular" color="grey">
                                    {t('payments.ref', { textOnly: true })}
                                  </Text>
                                  <TuitionPriceText
                                    size="mediumSmall"
                                    weight="regular"
                                    color="darkGrey"
                                  >
                                    {`${certificatesDetails.payment.details.ref}`}
                                  </TuitionPriceText>
                                </WrapperColumn>
                              </Grid.Column>
                            </>
                          )}

                          {certificatesDetails.payment.details.type === 'mbway' && (
                            <>
                              <Grid.Column width={2}>
                                <WrapperColumn>
                                  <Text size="small" weight="regular" color="grey">
                                    {t('payments.phoneNumber', { textOnly: true })}
                                  </Text>
                                  <TuitionPriceText
                                    size="mediumSmall"
                                    weight="regular"
                                    color="darkGrey"
                                  >
                                    {`${certificatesDetails.payment.details.phoneNumber}`}
                                  </TuitionPriceText>
                                </WrapperColumn>
                              </Grid.Column>
                            </>
                          )}

                          {certificatesDetails.payment.details.type !== 'mbway' &&
                            certificatesDetails.payment.details.type !== 'mb' && (
                              <>
                                <Grid.Column width={2}>
                                  <WrapperColumn>
                                    <Text size="small" weight="regular" color="grey">
                                      {t('payments.otherMethod', { textOnly: true })}
                                    </Text>
                                    <TuitionPriceText
                                      size="mediumSmall"
                                      weight="regular"
                                      color="darkGrey"
                                    >
                                      {`${certificatesDetails.payment.details.type}`}
                                    </TuitionPriceText>
                                  </WrapperColumn>
                                </Grid.Column>
                              </>
                            )}
                          <Grid.Column width={3}>
                            <WrapperColumn>
                              <Text size="small" weight="regular" color="grey">
                                {t('payments.receipt', { textOnly: true })}
                              </Text>
                              <TuitionPriceText
                                size="mediumSmall"
                                weight="regular"
                                color="darkGrey"
                              >
                                {certificatesDetails.payment.receiptNum !== '' ? 
                                `${certificatesDetails.payment.receiptNum}`:
                                `${t('payments.receiptNumber', { textOnly: true })}`}
                              </TuitionPriceText>
                            </WrapperColumn>
                          </Grid.Column>
                          <Grid.Column width={3}>
                            <WrapperColumn>
                              <Text size="small" weight="regular" color="grey">
                                {t('payments.paymentDate', { textOnly: true })}
                              </Text>
                              <TuitionPriceText
                                size="mediumSmall"
                                weight="regular"
                                color="darkGrey"
                              >
                                {`${moment(
                                  certificatesDetails.payment.paymentDate,
                                  'YYYY-MM-DD'
                                ).format('DD-MM-YYYY')}`}
                              </TuitionPriceText>
                            </WrapperColumn>
                          </Grid.Column>
                          <Grid.Column width={certificatesDetails.payment.receipt !== null ? 2 : 3}>
                            <CenteredGridColumn>
                              <StatusLabel
                                label={
                                  <LabelContentWrapper>
                                    {certificatesDetails.payment.details.type === 'mb' && (
                                      <MbIcon width={12} height={12} fill="white" />
                                    )}
                                    {certificatesDetails.payment.details.type === 'mbway' && (
                                      <MbWayIcon width={12} height={12} fill="white" />
                                    )}
                                    {certificatesDetails.payment.details.type !== 'mbway' &&
                                      certificatesDetails.payment.details.type !== 'mb' && (
                                        <FontAwesomeIcon
                                          icon={faMoneyBill}
                                          width={12}
                                          height={12}
                                        />
                                      )}
                                    <Text size="xSmall" weight="regular" color="white">
                                      {t(`payments.${certificatesDetails.state.paymentState}`, {
                                        textOnly: true,
                                      })}
                                    </Text>
                                  </LabelContentWrapper>
                                }
                                background={
                                  PAID_STATUS_COLOR[certificatesDetails.state.paymentState]
                                }
                              />
                            </CenteredGridColumn>
                          </Grid.Column>
                          {certificatesDetails.payment.receipt && (
                            <Grid.Column width={3}>
                              <CenteredGridColumn>
                                <Button
                                  onClick={() => {
                                    API.files.getFileOnTab
                                      .call(
                                        `${config.API_WSO2}${config.API_SEC_VIRTUAL_PATH}${certificatesDetails.payment.receipt.url}`
                                      )
                                      .catch((e) => {
                                        dispatch(
                                          actions.Toaster.showToaster({
                                            title: t(
                                              'secVirtualNotifications.general_errorLoadingFile'
                                            ),
                                            icon: 'error',
                                            type: 'danger',
                                          })
                                        );
                                      });
                                  }}
                                  data-testid={`paymentsTable_download_button`}
                                  action
                                  borderless
                                >
                                  <FontAwesomeIcon size={'2x'} icon={faFilePdf} />
                                </Button>
                              </CenteredGridColumn>
                            </Grid.Column>
                          )}
                        </Grid>
                      </PaymentsContainer>
                    </Segment>
                  </DebitSegmentWrapper>
                </SegmentPayment>

                <PaymentRow>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {certificatesDetails.payment.debit.description}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {`${t('generic.euros', {
                      textOnly: true,
                      count: numeral(certificatesDetails.payment.debit.value).format('0.00'),
                    })}`}
                  </Text>
                </PaymentRow>

                {/* {certificatesDetails.state.deliveryMethod && */}
                {certificatesDetails.payment.debit.associatedCosts &&
                  certificatesDetails.payment.debit.associatedCosts.map((item, index) => {
                    return (
                      <PaymentRow margin key={`assoc-costs-${index}`}>
                        <DeliveryMethodWrapper>
                          <Text size="article" weight="regular" color="plusDarkGrey">
                            {item.type}
                            {/* {t(`certificates.${item.type}`)} */}
                          </Text>
                          {item.type === 'mailCost' && certificatesDetails.history.ready && (
                            <Text size="xSmall" weight="regular" color="grey">
                              {`${t('payments.deliveryDateToDestination')} ${
                                certificatesDetails.history.ready
                              }`}
                            </Text>
                          )}
                        </DeliveryMethodWrapper>
                        <Text size="article" weight="regular" color="plusDarkGrey">
                          {`${t('generic.euros', {
                            textOnly: true,
                            count: numeral(item.value).format('0.00'),
                          })}`}
                        </Text>
                      </PaymentRow>
                    );
                  })}
                <SeparatorPayment />

                <PaymentRow>
                  <Text size="article" transform="uppercase" weight="regular" color="plusDarkGrey">
                    {t('certificates.totalPayment')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {t('generic.euros', {
                      count: numeral(certificatesDetails.payment.totalCost).format('0.00'),
                    })}
                  </Text>
                </PaymentRow>
              </Segment>
            )}
        </Wrapper>
      }
    />
  );
};

export default CertificatesNotReady;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const DebitSegmentWrapper = styled.div`
  margin-bottom: 12px;
`;

const WrapperColumnFeePrice = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const PaymentMethodWrapper = styled.div<{ marginTop?: string; maxWidth?: string }>`
  display: flex;
  align-items: center;
  height: 100%;

  > div:nth-child(2) {
    margin-left: 10px;
  }
`;

const LabelContentWrapper = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 4px;
  }
  &&& > div {
    margin-top: 0px !important;
  }
`;

const CenteredGridColumn = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
`;

const WrapperColumn = styled.div<{ marginTop?: string; maxWidth?: string }>`
  display: flex;
  flex-direction: column;

  > div:last-child {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '8px')};
  }
`;

const DeliveryMethodWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaymentsContainer = styled.div`
  display: flex;

  &&& .ui.grid {
    margin: 0px !important;
    width: 100%;
  }

  &&& .ui.grid > .column:not(.row) {
    padding: 0px !important;
  }
`;

const SegmentTitle = styled(Text)`
  &&& {
    display: block;
    margin-bottom: 24px;
  }
`;

const SegmentDoc = styled.div`
  margin-bottom: 40px;
`;

const SegmentPayment = styled.div<{ notButton?: boolean }>`
  margin-bottom: 40px;
  cursor: ${({ notButton }) => (notButton ? 'default' : 'pointer')};

  :hover {
    background-color: ${({ theme, notButton }) => (!notButton ? theme.colors.lightGrey : 'None')};
  }

  :active {
    background-color: ${({ theme, notButton }) =>
      !notButton ? theme.colors.softLightGrey : 'None'};
  }
`;

const SegmentPaymentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const SegmentAreaLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
`;

const SegmentPaymentIcons = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
`;

const SegmentPaymentIconInstance = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 4px 0px 4px;
`;

const SegmentAreaRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > div:not(:last-child) {
    margin-right: 40px;
  }
`;

const DetailsWrapper = styled(Grid)`
  &&& {
    .column {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
`;

const DetailsStudentWrapper = styled(Grid)`
  &&& {
    .column {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
`;

const DetailsType = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 14px;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }
`;

const DetailsTypeItem = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 14px;
  }
`;

const DetailsElement = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ModelText = styled(Text)`
  line-height: 1.5;
`;

const DetailsElementPayment = styled.div`
  display: flex;
  flex-direction: column;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.softRegularGrey};
  margin: 16px 0;
`;

const SeparatorPayment = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.softRegularGrey};
  margin: 24px 0;
`;

const PaymentRow = styled.div<{ margin?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: ${({ margin }) => (margin ? '24px' : '0')};
`;

const ConfirmationTitle = styled.div`
  margin-bottom: 18px;
`;

const EllipsisWrapper = styled.div`
  margin-top: 8px;
`;

const TuitionPriceText = styled(Text)``;

const TuitionText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 42px;
`;

const CostDetails = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:first-child) {
    margin-top: 4px;
  }

  > div:last-child {
    margin-bottom: 18px;
  }

  div:nth-child(2) {
    margin-top: 10px;
  }

  div:nth-child(3) {
    margin-top: 10px;
  }

  div:nth-child(4) {
    margin-top: 10px;
  }
`;
