/**
 * App logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getAppSlice = (state: RootState) => state.App;
export const getUser = (state: RootState) => state.App.user;
export const getTranslationsEdit = (state: RootState) => state.App.translationsEdit;
