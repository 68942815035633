/**
 * Schedules scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { t } from 'app';

import moment from 'moment';

import { IUc, ISelectedClasses, IClassTypology, IProvisorySeriationResult } from 'shared/types';

interface ISelectedClassesScheduleSubmission {
  classId: number;
  locked: boolean;
  ucId: number;
  group: number | null;
  aggregator: number | null;
}

export const reformatUcs = (
  ucs: { normal: []; mandatory: []; free: [] },
  phaseFinished,
  showDisabled?: boolean,
  showAllocated?: boolean,
  showAllocatedState?: boolean,
  hideAllocationProb?: boolean
) => {
  const normalUcs = reformatUcsUtil(
    ucs.normal,
    null,
    showDisabled,
    showAllocated,
    showAllocatedState,
    hideAllocationProb,
    phaseFinished
  );

  const mandatoryUcs =
    ucs?.mandatory?.map((mandatoryUc: any) => ({
      ...mandatoryUc,
      ucs: reformatUcsUtil(
        mandatoryUc.ucs,
        mandatoryUc.id,
        showDisabled,
        showAllocated,
        showAllocatedState,
        hideAllocationProb,
        phaseFinished
      ),
    })) || [];

  //Free ucs are always already allocated because they are too many to be added to the list at first
  const freeUcs: any = ucs.free.map((freeUc: any) => ({
    ...freeUc,
    ucs: reformatFreeUcs([freeUc], phaseFinished, freeUc.id),
  }));

  const freeOptions: { name: string; id: number; irregular?: boolean }[] = ucs.free.map(
    (ucUnit: any) => ({
      name: ucUnit.name,
      id: ucUnit.id,
      irregular: ucUnit?.irregular,
    })
  );

  const allUcs = [
    ...filterAllocatedClasses(normalUcs, phaseFinished, hideAllocationProb),
    ...getSelectedOrAllocatedMandatoryOptionUcs(mandatoryUcs, phaseFinished, false),
    ...freeUcs.reduce((acc, freeUc) => {
      return [...acc, ...freeUc.ucs.map((ucUnit) => ucUnit)];
    }, []),
  ];

  return { normalUcs, mandatoryUcs, freeUcs, freeOptions, allUcs };
};

export const reformatFreeUcs = (ucs: any[], phaseFinished, groupId) => {
  const freeUcs = ucs
    ?.map((freeUc: any) => {
      //TODO esta martelada deveria ser freeUc.name mas o backend está a mandar isto trocado
      if (freeUc.typologies !== null) {
        return {
          ...freeUc,
          ucs: reformatUcsUtil(ucs, groupId, null, null, null, null, null),
        };
      } else {
        return null;
      }
    })
    .filter((uc) => uc !== null);

  return [...getSelectedOrAllocatedMandatoryOptionUcs(freeUcs, phaseFinished, true)];
};

const reformatUcsUtil = (
  ucs,
  groupId,
  showDisabled,
  showAllocated,
  showAllocatedState,
  hideAllocationProb,
  phaseFinished
) => {
  return (
    ucs?.map((uc) => ({
      typologies: uc?.typologies || {},
      ucInitials: uc.initials,
      //TODO (HOTFIX) isto é apenas para resolver um problema para produção por causa da estrutura do backend.
      ucId: uc.uc || uc.id,
      irregular: uc.irregular,
      groupId: groupId === null || groupId === undefined ? null : groupId,
      ucColor: uc.color,
      ucFullName: uc.name,
      ucTotalChoices: uc.totalChoices,
      disabled: uc.disabled,
      full: uc.disabled ? t('sgh.infoAlertDisabledMandatory_0') : null,
      availableSlots: uc?.availableSlots || uc?.freeSlots || 0,
      classSchedule: uc.classes
        ? organizedClassesByPeriod(
            uc.classes,
            showDisabled !== null && showDisabled !== undefined ? showDisabled : uc.disabled,
            showAllocated,
            showAllocatedState,
            hideAllocationProb,
            phaseFinished
          )
        : [],
    })) || []
  );
};

const organizedClassesByPeriod = (
  classes,
  disabled,
  viewAllocated?: boolean,
  viewAllocatedState?: string,
  hideAllocationProb?: boolean,
  phaseFinished?: boolean
) => {
  return classes.reduce((acc, classItem) => {
    let newClassItems: any = [];
    classItem.lessons.forEach((lesson) => {
      //check if exists class already and return it
      const accToUpdate = newClassItems.find((accClassItem) => {
        return (
          accClassItem.classRoom === lesson.room &&
          accClassItem.weekday === moment(lesson.startDate).day() &&
          accClassItem.startTime === moment(lesson.startDate).format('HH:mm:ss') &&
          accClassItem.endTime === moment(lesson.endDate).format('HH:mm:ss')
        );
      });

      if (accToUpdate !== undefined) {
        accToUpdate.periodDays.push(moment(lesson.startDate).format('YYYY-MM-DD'));
      } else {
        newClassItems.push({
          classId: classItem.id,
          weekday: moment(lesson.startDate).day(),
          startTime: moment(lesson.startDate).format('HH:mm:ss'),
          endTime: moment(lesson.endDate).format('HH:mm:ss'),
          //period: classItem.period,
          periodDays: [moment(lesson.startDate).format('YYYY-MM-DD')],
          classType: classItem.type,
          className: viewAllocated ? classItem.number : classItem.name,
          irregularMessage:
            !phaseFinished &&
            !hideAllocationProb &&
            classItem?.allocated?.state === 'notAssigned' &&
            getIrregularMessageClass(classItem),
          irregularSituation: !phaseFinished && !hideAllocationProb && classItem.irregular,
          classRoom: lesson.room,
          disabled:
            disabled ||
            classItem.irregular === 'noLessons' ||
            classItem.irregular === 'noSlots' ||
            classItem.irregular === 'restrictionCourse' ||
            classItem.irregular === 'restrictionYear',
          full: disabled ? t('sgh.infoAlertDisabledMandatory_0') : null,
          selected:
            viewAllocated ||
            classItem.preSelected ||
            classItem?.allocated?.state === 'assigned' ||
            classItem?.allocated?.state === 'automatic',
          filtered: false,
          associatedClasses: classItem.associatedClasses,
          allocated:
            viewAllocated && viewAllocatedState
              ? classItem.allocated
              : classItem.allocated || { state: 'notAssigned' },
          allocation:
            hideAllocationProb !== true
              ? getAllocationValue[classItem?.allocated?.prob] || null
              : null,
          selectedStudents: classItem?.allocated?.selects || null,
          freeSlots: classItem?.allocated?.freeSlots || null,
          studentsLowerRanking: classItem?.allocated?.lowerRanking || null,
          studentsHigherRanking: classItem?.allocated?.higherRanking || null,
          slots: classItem.slots || 0,
        });
      }
    });

    return [...acc, ...newClassItems];
  }, []);
};

const getAllocationValue = {
  high: 'likely',
  medium: 'uncertain',
  low: 'unlikely',
};

export const filterAllocatedClasses = (ucs, phaseFinished, showPreselected) => {
  return ucs.map((uc) => ({
    ...uc,
    classSchedule: uc?.classSchedule?.map((classItem) => ({
      ...classItem,
      filtered:
        classItem?.allocated?.state === 'notAssigned'
          ? phaseFinished
            ? true
            : showPreselected
            ? classItem?.selected
              ? false
              : true
            : uc.classSchedule.some(
                (classesFilter) =>
                  classesFilter.classType === classItem.classType &&
                  (classesFilter.allocated.state === 'assigned' ||
                    classesFilter.allocated.state === 'automatic')
              )
          : false,
    })),
  }));
};

export const getSelectedOrAllocatedMandatoryOptionUcs = (mandatoryUcs, phaseFinished, unfilter) => {
  //we can not filter all mandatory if at least on is selected or assigned.
  return (
    mandatoryUcs?.reduce((acc, mandatoryUc) => {
      const selectedUcs = mandatoryUc.ucs.map((uc) => ({
        ...uc,
        classSchedule: uc.classSchedule.map((ucClass) => {
          if (phaseFinished) {
            if (ucClass.allocated.state === 'assigned' || ucClass.allocated.state === 'automatic') {
              return { ...ucClass, filtered: false };
            } else {
              return { ...ucClass, filtered: true };
            }
          } else {
            if (
              unfilter ||
              ucClass.selected ||
              ucClass.allocated.state === 'assigned' ||
              ucClass.allocated.state === 'automatic' ||
              uc.classSchedule.some(
                (classItem) =>
                  classItem.selected ||
                  classItem?.allocated?.state === 'assigned' ||
                  classItem?.allocated?.state === 'automatic'
              )
            ) {
              return { ...ucClass, filtered: false };
            } else {
              return { ...ucClass, filtered: true };
            }
          }
        }),
      }));

      return [...acc, ...selectedUcs];
    }, []) || []
  );
};

export const setReformatedClassesTypologies = (reformattedUcs) => {
  let existingTypologies = [] as IClassTypology[];
  reformattedUcs?.forEach((uc) => {
    Object.keys(uc.typologies) &&
      Object.keys(uc.typologies).forEach((typologyInitials) => {
        const typologyName = uc.typologies[typologyInitials].replace(/[|&:;$%@"<>()+,]/g, '');
        let exists = existingTypologies.some(
          (typology) =>
            typology.name === typologyName && typology.initials === typologyInitials.toUpperCase()
        );
        if (!exists) {
          existingTypologies.push({ name: typologyName, initials: typologyInitials.toUpperCase() });
        }
      });
  });

  return existingTypologies;
};

export const setClassesTypologies = (ucs) => {
  let existingTypologies = [] as IClassTypology[];

  ucs?.normal?.forEach((uc) => {
    Object.keys(uc.typologies) &&
      Object.keys(uc.typologies).forEach((typologyInitials) => {
        const typologyName = uc.typologies[typologyInitials].replace(/[|&:;$%@"<>()+,]/g, '');
        let exists = existingTypologies.some(
          (typology) =>
            typology.name === typologyName && typology.initials === typologyInitials.toUpperCase()
        );
        if (!exists) {
          existingTypologies.push({ name: typologyName, initials: typologyInitials.toUpperCase() });
        }
      });
  });

  ucs?.mandatory?.forEach((groupUc) => {
    groupUc.ucs.forEach((uc) => {
      Object.keys(uc.typologies) &&
        Object.keys(uc.typologies).forEach((typologyInitials) => {
          const typologyName = uc.typologies[typologyInitials].replace(/[|&:;$%@"<>()+,]/g, '');
          let exists = existingTypologies.some(
            (typology) =>
              typology.name === typologyName && typology.initials === typologyInitials.toUpperCase()
          );
          if (!exists) {
            existingTypologies.push({
              name: typologyName,
              initials: typologyInitials.toUpperCase(),
            });
          }
        });
    });
  });

  ucs?.free?.forEach((uc) => {
    uc?.typologies &&
      Object.keys(uc?.typologies) &&
      Object.keys(uc?.typologies).forEach((typologyInitials) => {
        const typologyName = uc?.typologies[typologyInitials].replace(/[|&:;$%@"<>()+,]/g, '');
        let exists = existingTypologies.some(
          (typology) =>
            typology.name === typologyName && typology.initials === typologyInitials.toUpperCase()
        );
        if (!exists) {
          existingTypologies.push({
            name: typologyName,
            initials: typologyInitials.toUpperCase(),
          });
        }
      });
  });

  return existingTypologies;
};

export const getUcFullName = (ucs: IUc[], selectedClass: number) => {
  let fullName = '';
  ucs.forEach((uc) => {
    if (uc.classSchedule.find((eachClass) => eachClass.classId === selectedClass)) {
      fullName = uc.ucFullName;
    }
  });
  return fullName;
};

export const setClassSelectionState = (
  state: IUc[],
  action: PayloadAction<{ ucId: number; groupId?: number | null; classId: number; type: string }>
) => {
  const associatedClasses = state
    .reduce((associatedClasses, eachUc) => {
      const comparisson =
        action.payload.groupId !== null || action.payload.groupId !== undefined
          ? eachUc.ucId === action.payload.ucId && eachUc.groupId === action.payload.groupId
          : eachUc.ucId === action.payload.ucId;
      if (comparisson) {
        eachUc.classSchedule.forEach((eachClass) => {
          if (
            eachClass.classId === action.payload.classId &&
            eachClass.associatedClasses?.classes
          ) {
            const stateClone = eachClass.associatedClasses.classes;
            associatedClasses = stateClone;
          }
        });
      }
      return associatedClasses;
    }, [] as number[])
    .filter((assoc) => assoc !== action.payload.classId);

  const classesOfThisUc = state.reduce((allClasses, eachUc) => {
    const comparisson =
      action.payload.groupId !== null || action.payload.groupId !== undefined
        ? eachUc.ucId === action.payload.ucId && eachUc.groupId === action.payload.groupId
        : eachUc.ucId === action.payload.ucId;

    if (comparisson) {
      eachUc.classSchedule.forEach((eachClass) => allClasses.push(eachClass.classId));
    }
    return allClasses;
  }, [] as number[]);

  // The class that triggered this function (the class clicked on)
  // was already selected?
  // If yes, the user wants to remove its selection
  const wantsToRemoveSelection = () => {
    const ucFound = state.find((uc) => uc.ucId === action.payload.ucId);
    if (ucFound) {
      const classFound = ucFound.classSchedule.find(
        (eachClass) => eachClass.classId === action.payload.classId
      );
      if (classFound) {
        return classFound.selected ? true : false;
      }
    }
  };

  return state.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        const comparisson =
          action.payload.groupId !== null || action.payload.groupId !== undefined
            ? eachClass.classId === action.payload.classId &&
              eachUc.groupId === action.payload.groupId
            : eachClass.classId === action.payload.classId;

        if (comparisson) {
          if (!eachClass.selected) {
            return {
              ...eachClass,
              selected: true,
              selectedAt: Date.now(),
            };
          } else {
            return {
              ...eachClass,
              selected: false,
              selectedAt: null,
            };
          }
        } else if (associatedClasses.includes(eachClass.classId) && !wantsToRemoveSelection()) {
          // If the class from this iteration it's associated with the selected one
          // it should be selected as well
          return {
            ...eachClass,
            selected: true,
            selectedAt: Date.now(),
          };
        } else if (associatedClasses.includes(eachClass.classId) && wantsToRemoveSelection()) {
          // If the class from this iteration it's associated with the selected one
          // but the user triggered this event because he wants to remove a selection
          // the associated classes should, also, be cleared/deselected
          return {
            ...eachClass,
            selected: false,
            selectedAt: null,
          };
        } else if (
          eachClass.selected &&
          eachClass.associatedClasses &&
          eachClass.associatedClasses.classes.length &&
          classesOfThisUc.includes(eachClass.classId) &&
          !associatedClasses.includes(eachClass.classId)
        ) {
          // (this solves the 'incremental associated classes' bug when changing selections)
          // If the class from this iteration is selected,
          // has 'associated classes',
          // belongs to the same uc of the selected one,
          // but is NOT associated with the class selected
          // it should lose its selection
          // I.E: deselect previous 'associated classes' whenever you change a class
          return {
            ...eachClass,
            selected: false,
            selectedAt: null,
          };
        } else if (
          (action.payload.groupId !== null || action.payload.groupId !== undefined
            ? eachUc.ucId === action.payload.ucId && eachUc.groupId === action.payload.groupId
            : eachUc.ucId === action.payload.ucId) &&
          eachClass.classType === action.payload.type &&
          eachClass.selected
        ) {
          // 'selected' should switch to false for all other classes (with the same type)
          // that belongs to the same ucId (payload)
          return { ...eachClass, selected: false, selectedAt: null };
        } else {
          return eachClass;
        }
      }),
    };
  });
};

export const getSelectedClasses = (ucs: IUc[]) => {
  let selectedClassesArray = ucs.reduce((selected: ISelectedClasses[], uc) => {
    uc.classSchedule.forEach((eachClass) => {
      if (
        eachClass.selected &&
        !eachClass.filtered &&
        eachClass.allocated.state === 'notAssigned'
      ) {
        selected.push({
          ucId: uc.ucId,
          ucColor: uc.ucColor,
          ucInitials: uc.ucInitials,
          ucFullName: uc.ucFullName,
          classType: eachClass.classType,
          className: eachClass.className,
          classId: eachClass.classId,
          classesDays: [],
          locked: eachClass.locked,
          selectedStudents: eachClass.selectedStudents,
          freeSlots: eachClass.freeSlots,
          studentsLowerRanking: eachClass.studentsLowerRanking,
          studentsHigherRanking: eachClass.studentsHigherRanking,
          allocated: eachClass.allocated,
          slots: eachClass.slots,
          selectedAt: eachClass.selectedAt,
        });
      } else if (
        eachClass.selected &&
        !eachClass.filtered &&
        (eachClass.allocated.state === 'assigned' || eachClass.allocated.state === 'automatic')
      ) {
        selected.push({
          ucId: uc.ucId,
          ucColor: uc.ucColor,
          ucInitials: uc.ucInitials,
          ucFullName: uc.ucFullName,
          classType: eachClass.classType,
          className: eachClass.className,
          classesDays: [],
          classId: eachClass.classId,
          locked: eachClass.locked,
          selectedStudents: eachClass.selectedStudents,
          freeSlots: eachClass.freeSlots,
          studentsLowerRanking: eachClass.studentsLowerRanking,
          studentsHigherRanking: eachClass.studentsHigherRanking,
          allocated: eachClass.allocated,
          selectedAt: eachClass.selectedAt,
        });
      }
    });
    return selected;
  }, []);

  return (
    selectedClassesArray
      .map((selectedClass) => ({
        ...selectedClass,
        overlaps: hasOverlaps(selectedClass, ucs),
        classesDays: ucs.reduce((acc: any, uc: any) => {
          let accData: any = [];
          uc.classSchedule.forEach((eachClass) => {
            if (eachClass.classId === selectedClass.classId) {
              accData = [
                ...accData,
                {
                  startTime: eachClass.startTime,
                  endTime: eachClass.endTime,
                  periodDays: eachClass.periodDays,
                },
              ];
            }
          });

          return [...acc, ...accData];
        }, []),
      }))
      // Return accumulator but filter duplicated items
      // Selecting 1 class from '1-3' period should select,
      // as well, the same class from '2-4' period
      // and this creates duplication because both classes has 'selected' property
      .filter(
        (eachClass: ISelectedClasses, index: number, self: ISelectedClasses[]) =>
          self.findIndex((c) => c.classId === eachClass.classId) === index
      )
  );
};

export const setScheduleSelectedMandatoryClasses = (mandatoryUcs, selectedClasses) => {
  return mandatoryUcs.map((mandatoryUc) => ({
    ...mandatoryUc,
    ucs: mandatoryUc.ucs.map((uc) => ({
      ...uc,
      classSchedule: uc.classSchedule.map((classItem) => ({
        ...classItem,
        selected: selectedClasses.some((selectedClass) => {
          if (selectedClass.groupId !== null && selectedClass.groupId !== undefined) {
            return (
              selectedClass.classId === classItem.classId && selectedClass.groupId === uc.groupId
            );
          }
          return selectedClass.classId === classItem.classId;
        }),
      })),
    })),
  }));
};

export const setClassLockState = (state: IUc[], action: PayloadAction<number>) => {
  return state.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        if (action.payload === eachClass.classId) {
          return { ...eachClass, locked: !eachClass.locked };
        } else {
          return eachClass;
        }
      }),
    };
  });
};

export const setClassesProvisorySeriationResult = (
  ucs: IUc[],
  seriationResult: IProvisorySeriationResult[]
) => {
  return ucs.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        const found = seriationResult.find(
          (eachSeriatedClass) => eachSeriatedClass.classId === eachClass.classId
        );

        if (found) {
          return {
            ...eachClass,
            allocation: found.allocation,
            selectedStudents: found.selectedStudents,
            freeSlots: found.freeSlots,
            studentsLowerRanking: found.studentsLowerRanking,
            studentsHigherRanking: found.studentsHigherRanking,
            placed: found.placed,
          };
        } else {
          //if response final we need to filter typologies if one allocated exists as well in ucs
          const hideClass = eachUc.classSchedule.some(
            (eachOtherClass) =>
              eachOtherClass.allocated &&
              eachOtherClass.classId !== eachClass.classId &&
              eachOtherClass.classType === eachClass.classType
          );
          return { ...eachClass, filtered: hideClass };
        }
      }),
    };
  });
};

export const clearSchedule = (state: IUc[]) => {
  return state.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        if (
          eachClass.selected &&
          eachClass.allocated.state === 'notAssigned' &&
          !eachClass.disabled
        ) {
          return { ...eachClass, selected: false };
        } else {
          return eachClass;
        }
      }),
    };
  });
};

export const selectClassesToEditSchedule = (state: IUc[], classes: any[]) => {
  let newUcs = [...state];

  newUcs = state.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        const found = classes.some((classItem) => {
          if (classItem.mandatoryGroupId !== null && classItem.mandatoryGroupId !== undefined) {
            return (
              classItem.classId === eachClass.classId &&
              classItem.mandatoryGroupId === eachUc.groupId
            );
          }
          return classItem.classId === eachClass.classId;
        });
        if (found) {
          return { ...eachClass, selected: true, filtered: false };
        } else {
          return eachClass;
        }
      }),
    };
  });

  return newUcs.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        //we reiterate the class to check select mandatoryGroups
        if (eachUc.groupId !== null && eachUc.groupId !== undefined) {
          const findClassSelected = eachUc.classSchedule.some(
            (eachClassToFind) => eachClassToFind.selected
          );
          if (findClassSelected) {
            return { ...eachClass, selected: eachClass.selected, filtered: false };
          }
        }
        return eachClass;
      }),
    };
  });
};

export const getScheduleSelectedClasses = (ucs: IUc[], submit?: boolean) => {
  // Selecting 1 class from '1-3' period should select the same class from '2-4' period
  // This creates a duplication because both classes has 'selected' property
  // That's why the reduce returned value is filtered, in the end
  return ucs
    .reduce((selected: ISelectedClassesScheduleSubmission[], uc: IUc) => {
      uc.classSchedule.forEach((eachClass) => {
        if (eachClass.selected && eachClass.allocated.state === 'notAssigned') {
          selected.push({
            classId: eachClass.classId,
            locked: eachClass.locked,
            ucId: uc.ucId,
            group: uc.groupId,
            aggregator: eachClass?.associatedClasses?.aggregator || null,
          });
        }
      });

      return selected;
    }, [])
    .filter(
      (
        eachClass: ISelectedClassesScheduleSubmission,
        index: number,
        self: ISelectedClassesScheduleSubmission[]
      ) =>
        self.findIndex((c) => {
          if (c.group !== null && c.group !== undefined) {
            return c.classId === eachClass.classId && c.group === eachClass.group;
          }
          return c.classId === eachClass.classId;
        }) === index
    );
};

const isBefore = (startTime, endTime) => {
  if (startTime === endTime) return true;
  return moment(startTime, 'HH:mm').isBefore(moment(endTime, 'HH:mm'));
};

const isAfter = (startTime, endTime) => {
  if (startTime === endTime) return true;
  return moment(startTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm'));
};

const hasOverlaps = (currentClass, selectedClasses) => {
  return selectedClasses.some((eachUc) => {
    let shouldOverlap = eachUc.classSchedule
      .filter(
        (allClassesItem) =>
          allClassesItem.classId === currentClass.classId &&
          (allClassesItem.weekday !== currentClass.weekday ||
            (isBefore(allClassesItem.endTime, currentClass.startTime) &&
              isAfter(allClassesItem.startTime, currentClass.endTime)))
      )
      .some((allClassesItem) => {
        return verifyOverlaps(allClassesItem, selectedClasses);
      });

    if (!shouldOverlap) {
      shouldOverlap = eachUc.classSchedule.some((allClassesItem) => {
        return (
          allClassesItem.classId !== currentClass.classId &&
          allClassesItem.selected &&
          !allClassesItem.filtered &&
          allClassesItem.weekday === currentClass.weekday &&
          !isBefore(allClassesItem.endTime, currentClass.startTime) &&
          !isAfter(allClassesItem.startTime, currentClass.endTime)
        );
      });
    }
    return shouldOverlap;
  });
};

const verifyOverlaps = (currentClass, selectedClasses) => {
  return selectedClasses.some((eachUc) => {
    return eachUc.classSchedule.some((allClassesItem) => {
      return (
        allClassesItem.classId !== currentClass.classId &&
        allClassesItem.selected &&
        !allClassesItem.filtered &&
        allClassesItem.weekday === currentClass.weekday &&
        !isBefore(allClassesItem.endTime, currentClass.startTime) &&
        !isAfter(allClassesItem.startTime, currentClass.endTime)
      );
    });
  });
};

export const hasProvisorySeriation = (ucs) => {
  return ucs.some((eachUc) =>
    eachUc.classSchedule.some(
      (eachClass) =>
        eachClass.studentsLowerRanking || eachClass.studentsHigherRanking || eachClass.freeSlots
    )
  );
};

export const compareUcs = (ucs, ucsOriginal) => {
  const selectedClassesId = [] as number[];
  const originalSelectedClassesId = [] as number[];

  ucs.forEach((uc) =>
    uc?.classSchedule?.forEach((classItem) => {
      if (classItem.selected && classItem?.allocated?.state === 'notAssigned') {
        selectedClassesId.push(classItem.classId);
      }
    })
  );

  if (!selectedClassesId.length) {
    return true;
  }

  ucsOriginal.forEach((uc) =>
    uc.classSchedule.forEach((classItem) => {
      if (classItem.selected && classItem?.allocated?.state === 'notAssigned') {
        originalSelectedClassesId.push(classItem.classId);
      }
    })
  );

  return (
    selectedClassesId.every((selectedClass) =>
      originalSelectedClassesId.some(
        (originalSelectedClass) => selectedClass === originalSelectedClass
      )
    ) &&
    originalSelectedClassesId.every((selectedClass) =>
      selectedClassesId.some((originalSelectedClass) => selectedClass === originalSelectedClass)
    )
  );
};

export const getIrregularMessageClass = (classItem) => {
  if (!classItem?.irregular) return null;

  return classItem?.irregular === 'noSlots'
    ? t('sgh.irregularSituationClassNoSlots')
    : classItem?.irregular
    ? t('sgh.irregularSituationClass')
    : null;
};

export const getIrregularMessageUC = (classItem) => {
  if (!classItem?.irregular) return null;

  return classItem?.irregular === 'noSlots'
    ? t('sgh.irregularSituationClassNoSlots')
    : classItem?.irregular
    ? t('sgh.irregularSituationClass')
    : null;
};

export const getIrregularMessageFreeOptions = (classItem) => {
  if (!classItem?.irregular) return null;

  switch (classItem?.irregular) {
    case 'noSlots':
      return t('sgh.irregularSituationClassNoSlotsFreeOptions'); //-> Esta turma não possui vagas. Contacte a secretaria do departamento,
    case 'noClasses':
      return t('sgh.irregularSituationNoClasses');
    case 'invalidCycle':
      return t('sgh.irregularSituationInvalidCycle');
    case 'invalidEcts':
      return t('sgh.irregularSituationInvalidEcts');
    case 'invalidPlan':
      return t('sgh.irregularSituationInvalidPlan');
    default:
      return t('sgh.irregularSituationClass');
  }
};

export const getIrregularMessageSmallFreeOptions = (classItem) => {
  if (!classItem?.irregular) return null;

  switch (classItem?.irregular) {
    case 'noSlots':
      return t('sgh.irregularSituationClassNoSlotsSmall');
    case 'noClasses':
      return t('sgh.irregularSituationNoClassesSmall');
    case 'invalidCycle':
      return t('sgh.irregularSituationInvalidCycleSmall');
    case 'invalidEcts':
      return t('sgh.irregularSituationInvalidEctsSmall');
    case 'invalidPlan':
      return t('sgh.irregularSituationInvalidPlanSmall');
    default:
      return t('sgh.infoIrregularGeneral');
  }
};

export const getIrregularMessageSmall = (classItem) => {
  if (!classItem?.irregularSituation) return null;

  return classItem.irregularSituation === 'noSlots'
    ? t('sgh.infoIrregularNoSlots')
    : classItem.irregularSituation === 'noLessons'
    ? t('sgh.infoIrregularNoLessons')
    : classItem.irregularSituation
    ? t('sgh.infoIrregularGeneral')
    : null;
};
