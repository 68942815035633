import { createSlice } from '@reduxjs/toolkit';
import { GradeHistoryListResponse, GradeItem } from './types';

import { PayloadAction } from '@reduxjs/toolkit';

interface GradeHistoryState {
  historyList: GradeHistoryListResponse;
  gradeDetails: GradeItem | null;
  loadingStatus: {
    loadingGradeHistory: boolean;
    loadingGradeDetails: boolean;
  };
}

const initialState: GradeHistoryState = {
  historyList: [],
  gradeDetails: null,
  loadingStatus: {
    loadingGradeHistory: true,
    loadingGradeDetails: true,
  }
};

export default createSlice({
  name: 'GradeHistory',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    reset: (state) => initialState,
    setGradeHistory: (state, action) => {
      state.historyList = action.payload;
    },
    setGradeDetails: (state, action) => {
      state.gradeDetails = action.payload;
    },
    getGradeHistory: (state, action: PayloadAction<{ codigoPauta: string }>) => {},
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
  },
});
