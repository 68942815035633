/**
 * Registration scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUser = (state: RootState) => state.App.user;
export const getRegistrationId = (state: RootState) =>
  state.StudentRegistrationDropdown.registration;
export const getRegistrationsSlice = (state: RootState) => state.Registrations;
