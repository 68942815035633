/**
 * Grades scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export default createSlice({
  name: 'Grades',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
  },
});
