/**
 * Header component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { t } from 'app';

import styled, { ThemeContext } from 'styled-components';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faSearch } from '@fortawesome/pro-light-svg-icons';

import {
  AnimatedBackground,
  Tooltip,
  ResponsiveLayout,
  Text,
  Logo,
  Button,
  Dropdown,
  SearchInput,
} from '@paco_ua/pacoui';

import { getLanguageName } from './utils';

import { getPathToPage } from 'shared/containers/Breadcrumb/utils';

import API from 'api';

const Header: React.FC<{ hasSidebar?: boolean }> = ({ hasSidebar }) => {
  const [search, setSearch] = useState(false);
  const [searchResults, setSearchResults] = useState([] as any);

  const { toggleSidebar, navigateTo, signout, changeLocale } = actions.App;
  const dispatch = useDispatch();
  const { user, currentLocale, startingApp, routingStructure } = useSelector(selectors.getAppSlice);

  const [loadingSearchResults, setLoadingSearchResults] = useState(false);

  const theme = useContext(ThemeContext);

  interface ISearchResult {
    childOf: string | null;
    description: string;
    service: undefined | string;
    key: string;
    name: string;
    path: string;
    pathEn: string;
    roles: string[];
    tags: string[];
  }

  return (
    <Wrapper search={search}>
      <LeftAreaWrapper>
        <SidebarAreaWrapper>
          {startingApp && <AnimatedBackground height={theme.sizes.xxLarge} width="40px" />}

          {!startingApp && hasSidebar && (
            <Hamburger
              noPadding={true}
              color={search ? theme.colors.plusDarkGrey : theme.colors.white}
              borderless={true}
              onClick={() => dispatch(toggleSidebar())}
              data-testid="header_button_hamburguer"
            >
              <FontAwesomeIcon size="lg" icon={faBars} />
            </Hamburger>
          )}

          <LogoWrapper>
            {startingApp && <AnimatedBackground height={theme.sizes.xxLarge} width="118px" />}
            {!startingApp && (
              <Logo
                small
                height="100%"
                width="118"
                fill={search ? theme.colors.plusDarkGrey : theme.colors.white}
              />
            )}
          </LogoWrapper>
        </SidebarAreaWrapper>

        <ResponsiveLayout
          web={() => (
            <BrandName
              padding={!startingApp}
              onClick={() => {
                if (!startingApp) {
                  dispatch(navigateTo({ key: 'home' }));
                }
              }}
            >
              {startingApp && <AnimatedBackground height={theme.sizes.xxLarge} width="90px" />}
              {!startingApp && 'paco'}
            </BrandName>
          )}
          tablet={() => null}
          mobile={() => null}
        />
      </LeftAreaWrapper>

      <RightAreaWrapper>
        {startingApp && <AnimatedBackground height={theme.sizes.xxLarge} width="200px" />}
        {!startingApp && (
          <>
            <SearchInputWrapper>
              <SearchInput
                enterAnimation
                delay={700}
                visible={search}
                onCancel={() => {
                  setSearch(false);
                }}
                onSelect={(data) => {
                  setSearch(false);
                  setSearchResults([]);
                  let value = {
                    name: data.value.split(' , ')[0],
                    link: data.value.split(' , ')[1],
                  };

                  if (value.link !== '') {
                    window.open(value.link, '_self');
                  } else {
                    dispatch(navigateTo({ key: value.name }));
                  }
                }}
                loadingSearchResults={loadingSearchResults}
                setLoadingSearchResults={(state) => {
                  setLoadingSearchResults(state);
                }}
                onSearch={(value) => {
                  //proceed only if value length is > 3
                  if (value.length < 3) {
                    setLoadingSearchResults(false);
                    return;
                  }
                  try {
                    API.search.getFunctionalitiesSearch.call(value).then((value: any[]) => {
                      let dropdownValues = [] as any[];

                      for (let i = 0; i < value.length; i++) {
                        let text = '';

                        const searchRoute = routingStructure.find(
                          (route) => route.key === value[i].key
                        );

                        if (searchRoute) {
                          const breadcrumbPath = getPathToPage(searchRoute, routingStructure);

                          if (breadcrumbPath.length === 1) {
                            text += `${breadcrumbPath[0].name}`;
                          } else if (breadcrumbPath.length > 1) {
                            text += `${breadcrumbPath[0].name} > ${
                              breadcrumbPath[breadcrumbPath.length - 1].name
                            }`;
                          } else {
                            text = `${t(`routes.${value[i].name}`, {
                              textOnly: true,
                            })}`;
                          }

                          dropdownValues.push({
                            key: value[i].key,
                            text: (
                              <SearchTextWrapper hasDescription={value[i].description !== ''}>
                                <Text weight={'regular'}>{text}</Text>
                                {value[i].description !== '' && (
                                  <Text size="xSmall" weight={'regular'}>
                                    {t(`routes.${value[i].description}`, { textOnly: true })}
                                  </Text>
                                )}
                              </SearchTextWrapper>
                            ),
                            value: `${value[i].key} , ${value[i].link}`,
                          });
                        }
                      }
                      setSearchResults(dropdownValues);
                      setLoadingSearchResults(false);
                    });
                  } catch (e) {
                    dispatch(
                      actions.Toaster.showToaster({
                        title: t('secVirtualNotifications.general_errorSearch'),
                        icon: 'error',
                        type: 'danger',
                      })
                    );

                    setLoadingSearchResults(false);
                  }
                }}
                resetSearchResults={() => {
                  setSearchResults([]);
                }}
                results={searchResults}
                placeholder={t('generic.pacoSearchPlaceholder', { textOnly: true })}
              />
            </SearchInputWrapper>
            {!search && (
              <>
                <Tools>
                  <div>
                    {currentLocale && (
                      <Tooltip
                        content={t(`generic.${getLanguageName(currentLocale).toLowerCase()}Switch`)}
                        trigger={
                          <Button
                            borderless
                            noPadding
                            onClick={() => {
                              let currentLang = getLanguageName(currentLocale);
                              if (currentLang === 'EN') {
                                dispatch(changeLocale('en'));
                              } else {
                                dispatch(changeLocale('pt'));
                              }
                              dispatch(navigateTo({ key: 'home' }));
                            }}
                            color={theme.colors.white}
                          >
                            <Text weight="light">{getLanguageName(currentLocale)}</Text>
                          </Button>
                        }
                      />
                    )}
                  </div>
                  <div>
                    <Tooltip
                      content={t(`generic.search`)}
                      trigger={
                        <Button
                          borderless
                          noPadding
                          onClick={() => {
                            setSearch(true);
                          }}
                          color={theme.colors.white}
                        >
                          <FontAwesomeIcon icon={faSearch} />
                        </Button>
                      }
                    />
                  </div>
                </Tools>
              </>
            )}
          </>
        )}
      </RightAreaWrapper>
    </Wrapper>
  );
};

export default Header;

const Wrapper = styled.div<{ search: boolean }>`
  width: 100%;
  height: ${({ theme }) => theme.header.height};
  display: flex;
  align-items: center;
  padding: 0 40px;
  background-color: ${({ search, theme }) => (search ? theme.colors.white : theme.colors.primary)};
  color: ${({ search, theme }) => (search ? theme.colors.plusDarkGrey : theme.colors.white)};
  transition: background 0.3s ease-in, color 0.2s ease-in;

  svg {
    transition: background 0.3s ease-in, color 0.2s ease-in, fill 0.2s ease-in;
  }

 /*  ${({ theme }) => theme.medias.xs} {
    max-height: 60px;
  } */

  @media(max-width: 915px) {
    max-height: 60px;
  }

  /* ${({ theme }) => theme.medias.sm} {
    max-height: 60px;
  } */
`;

const LogoWrapper = styled.div`
  height: auto;
  margin-left: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarAreaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(${({ theme }) => theme.sidebar.width} - 40px);
`;

const LeftAreaWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RightAreaWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  position: absolute;
`;

const Tools = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    &:first-child {
      border-right: ${({ theme }) => `1px solid ${theme.colors.softRegularGrey}`};
    }

    &:not(last-child) {
      padding: 0 12px 0 12px;
    }

    &:last-child {
      padding: 0 0 0 12px;
    }
  }
`;

const Hamburger = styled(Button)``;

const BrandName = styled.div<{ padding?: boolean }>`
  font-size: 1.75rem;
  margin-left: 40px;
  font-weight: 200;
  padding-top: ${({ padding }) => (padding ? '8px' : '0')};
  cursor: pointer;
`;

const SearchTextWrapper = styled.div<{ hasDescription: boolean }>`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: ${({ hasDescription }) => (hasDescription ? '4px' : '0px')};
  }
`;

const DropdownUser = styled(Dropdown)`
  margin-left: 24px;
`;
