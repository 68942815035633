import { CommunicationTypes } from './communicationTypes';
import { IPostMessageMiddleware, IMessageListener, ICommunicationPayloads } from './types';
import { checkIfOriginIsValid, checkIfTargetisValid } from './utils';

export const usePostMessageMiddleware = (
  targetWindow: Window | null | undefined,
  url?: string //use only for dev purposes
): IPostMessageMiddleware => {
  const listeners: IMessageListener[] = [];

  // Add event listener to handle incoming messages
  window.addEventListener('message', handleMessage);
  function handleMessage(message: any) {
    
    if (message.origin && !checkIfOriginIsValid(message.origin)) {
      console.warn(`Received message from unexpected origin: ${message.origin}`);
      return;
    }

    const { type, payload } = message.data;
    const listener = listeners.find((l) => l.type === type)?.listener;
    if (listener) {
      listener(payload);
    }
  }

  // Function to send a message to the target window
  const sendMessage = <T extends CommunicationTypes>(
    type: T,
    payload: ICommunicationPayloads[T]
  ) => {
    // Check if the target origin is provided and the target window exists
    if (targetWindow && checkIfTargetisValid(url ?? targetWindow?.location.origin)) {
      targetWindow.postMessage({ type, payload }, url ?? targetWindow?.location.origin);
    } else {
      console.log('Failed to send message. Target window is not provided.');
    }
  };

  // Function to add a message listener for a specific message type
  const addMessageListener = <T extends CommunicationTypes>(
    type: T,
    listener: (payload: ICommunicationPayloads[T]) => void
  ) => {
    listeners.push({ type, listener });
  };

  // Function to remove a message listener for a specific message type
  const removeMessageListener = <T extends CommunicationTypes>(type: T) => {
    const index = listeners.findIndex((l: IMessageListener) => l.type === type);

    if (index !== -1) {
      listeners.splice(index, 1);
    }
  };

  // Function to cleanup the middleware by removing all message listeners
  const cleanup = () => {
    window.removeEventListener('message', handleMessage);
    listeners.splice(0, listeners.length);
  };

  return {
    sendMessage,
    addMessageListener,
    removeMessageListener,
    cleanup,
  };
};
