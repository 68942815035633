import { t } from 'app';

export const getPathToPage = (currentRoute, routeList) => {
  let currentRouteElems: any[] = [];
  let didSetModule = false;

  const setModuleName = (route) => {
    if (route?.module?.name && !didSetModule) {
      didSetModule = true;
      currentRouteElems.unshift({
        name: t(`routes.${route.module.name}`, { textOnly: true }),
      });
    }
  };

  routeList.forEach((route) => {
    if (route.path) {
      if (currentRoute.key === route.key && !route.detached && !route.disabled) {
        setModuleName(route);

        if (route.childOf) {
          //iterate over other routes to search if is or has child of and continue adding in this order else
          const parentRoutes: any[] = [];

          const searchParentRoutes = (searchRoute) => {
            const parentRoute = routeList.find((pRoute) => searchRoute.childOf === pRoute.key);

            if (parentRoute) {
              setModuleName(parentRoute);

              parentRoutes.unshift({
                key: parentRoute.key,
                name: t(`routes.${parentRoute.name}`, { textOnly: true }),
                url: parentRoute.key,
              });

              searchParentRoutes(parentRoute);
            } else if (searchRoute?.childOf) {
              parentRoutes.unshift({
                key: `parent_${searchRoute.childOf}`,
                name: t(`routes.${searchRoute.childOf}`, { textOnly: true }),
              });
            }
          };

          searchParentRoutes(route);

          currentRouteElems = [...currentRouteElems, ...parentRoutes];
        }

        currentRouteElems.push({
          key: route.key,
          name: t(`routes.${route.name}`, { textOnly: true }),
          url: route.key,
        });
      }
    }
  });

  return currentRouteElems;
};

export const overridePath = (state, payload) => {
  return state.map((path) => {
    if (payload.key === path.key) {
      return {
        key: path.key,
        name: payload.name || t(`routes.${path.name}`, { textOnly: true }),
        url: payload.url || path.url,
      };
    } else {
      return path;
    }
  });
};
