import { RootState } from 'store/rootSlices';

export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getUser = (state: RootState) => state.App.user;
export const getGradesManagementSlice = (state: RootState) => state.GradesManagement;
export const getSelectedState = (state: RootState) => state.GradesManagement.selectedState;
export const getPautasLista = (state: RootState) => state.GradesManagement.pautasLista;
export const getPeriodosDisponiveis = (state: RootState) => state.GradesManagement.periodosDisponiveis;
export const getFiltrarPorPeriodo = (state: RootState) => state.GradesManagement.filtrarPorPeriodo;
export const getCurrentLocale = (state: RootState) => state.App.currentLocale;
export const getPautasStats = (state: RootState) => state.GradesManagement.pautaStats;
export const getLoadingStatus = (state: RootState) => state.GradesManagement.loadingStatus;

