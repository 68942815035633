/**
 * CertificatesDetails scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled, { ThemeContext } from 'styled-components';

import { actions } from 'store/rootSlices';

import { getCurrentRoute } from 'utils';

import { Grid } from 'semantic-ui-react';
import moment from 'moment';
import { Controller } from 'react-hook-form';
import { faCheck, faRedoAlt, faFilePdf } from '@fortawesome/pro-light-svg-icons';
import config from 'config';
import { FormInput, Text, Segment, Button, StatusLabel } from '@paco_ua/pacoui';

import ReCAPTCHA from 'react-google-recaptcha';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as yup from 'yup';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import * as selectors from './logic/selectors';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import API from 'api';

const CertificateValidator = ({ t }) => {
  const theme = useContext(ThemeContext);

  const { onMount, onUnmount } = actions.CertificatesValidator;

  const dispatch = useDispatch();

  const certCode = getCurrentRoute().params.accessKey;

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  const [recaptchaValue, setRecaptchaValue] = useState(null as string | null);

  const [outlineRecap, setOutlineRecap] = useState(false);

  useEffect(() => {
    if (recaptchaValue) {
      setOutlineRecap(false);
    }
  }, [recaptchaValue]);

  const {
    setHasValidated,
    validateCertificateCode,
    setLoadingStatus,
  } = actions.CertificatesValidator;

  const schema = yup.object().shape({
    certificateCode: yup
      .string()
      .required(t('forms.validationFieldRequired'))
      .test(`certificate-code-type`, function (value) {
        const { path, createError } = this;

        return (
          /([A-Z0-9]{1,30}-[A-Z0-9]{1,30}-[A-Z0-9]{1,30})/.test(value ? value : '') ||
          createError({
            path,
            message: t('forms.invalidFormat'),
          })
        );
      }),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      certificateCode: certCode ? certCode.toUpperCase() : '',
    },
  });

  const onChangeRecaptcha = (value: string) => {
    setRecaptchaValue(value);
  };

  const { hasValidated, certificateData, loadingStatus, errorStatus } = useSelector(
    selectors.getCertificateValidatorSlice
  );

  const submitForm = (data) => {
    if (!recaptchaValue) {
      setOutlineRecap(true);
    } else {
      dispatch(
        validateCertificateCode({
          accessCode: data.certificateCode,
          recaptchaToken: recaptchaValue,
        })
      );
    }
  };

  return (
    <PageStructure
      headerLeft={<Header title={t('certificates.checkCertificate')} />}
      subHeader={
        <Text color="plusDarkGrey" size="medium" weight="regular">
          {t('certificates.academicServices')}
        </Text>
      }
      mainContent={
        <Wrapper>
          <Segment backgroundColor={hasValidated ? theme.colors.softGrey : undefined}>
            <SegmentTitleWrapper>
              <Text size="large" color={'plusDarkGrey'} weight={'regular'}>
                {t('certificates.certCodeValidation')}
              </Text>

              <FormWrapper>
                <DescriptionWrapper>
                  <Text size="xSmall" weight="regular" color="grey">
                    {hasValidated
                      ? t('certificates.certificateCode', { textOnly: true })
                      : t('certificates.insertCertCode', { textOnly: true })}
                  </Text>
                  {!hasValidated && (
                    <Text size="xSmall" weight="regular" color="dangerRed">
                      {' *'}
                    </Text>
                  )}
                </DescriptionWrapper>
                <FormInputWrapper
                  backgroundColor={hasValidated ? theme.colors.softGrey : undefined}
                >
                  <Controller
                    name={'certificateCode'}
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <CustomFormInput
                          disabled={hasValidated}
                          borderColor={theme.colors.grey}
                          value={value}
                          placeholder={t(`certificates.certificateCodePlaceholder`, {
                            textOnly: true,
                          })}
                          fontSize={'regular'}
                          onChange={(e) => {
                            onChange && onChange(e.target.value.toUpperCase());
                          }}
                          error={
                            errors && errors.certificateCode
                              ? errors.certificateCode.message
                              : undefined
                          }
                        />
                      );
                    }}
                  />
                  {hasValidated && !certificateData?.hasExpired && (
                    <StatusLabel
                      label={
                        certificateData?.validAccessKey
                          ? t('certificates.valid')
                          : t('certificates.invalid')
                      }
                      background={
                        certificateData?.validAccessKey
                          ? theme.colors.successGreen
                          : theme.colors.dangerRed
                      }
                    />
                  )}
                  {hasValidated &&
                    certificateData?.validAccessKey &&
                    certificateData?.hasExpired && (
                      <StatusLabel
                        label={t('certificates.expired')}
                        background={theme.colors.decisionYellow}
                      />
                    )}
                </FormInputWrapper>
              </FormWrapper>

              {hasValidated && !certificateData?.validAccessKey && (
                <Text
                  size="mediumSmall"
                  color={'plusDarkGrey'}
                  weight={'medium'}
                  style={{ marginTop: '28px' }}
                >
                  {t('certificates.invalidCertCodeText')}
                </Text>
              )}

              {hasValidated && certificateData?.validAccessKey && !certificateData.hasExpired && (
                <ResultWrapper>
                  <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                    {t('certificates.infoCertificate')}
                  </Text>

                  <CertificateInfoWrapper>
                    <Grid.Column
                      largeScreen="8"
                      tablet="16"
                      mobile="16"
                      computer="8"
                      style={{ marginTop: '18px' }}
                    >
                      <DetailsElement>
                        <Text size="xSmall" color="grey">
                          {t('certificates.requestFourthStepName')}
                        </Text>
                        <Text size="mediumSmall" weight="regular" color="plusDarkGrey">
                          {certificateData?.details?.studentName}
                        </Text>
                      </DetailsElement>
                    </Grid.Column>
                    <Grid.Column
                      largeScreen="8"
                      tablet="16"
                      mobile="16"
                      computer="8"
                      style={{ marginTop: '18px' }}
                    >
                      <DetailsElement>
                        <Text size="xSmall" color="grey">
                          {t('certificates.nMec')}
                        </Text>
                        <Text size="mediumSmall" weight="regular" color="plusDarkGrey">
                          {certificateData?.details?.studentNumber}
                        </Text>
                      </DetailsElement>
                    </Grid.Column>
                  </CertificateInfoWrapper>

                  <CertificateInfoWrapper>
                    <Grid.Column largeScreen="16" tablet="16" mobile="16" computer="16">
                      <DetailsElement>
                        <Text size="xSmall" color="grey">
                          {t('certificates.registration')}
                        </Text>
                        <Text size="mediumSmall" weight="regular" color="plusDarkGrey">
                          {`${certificateData?.details?.courseCode} - ${certificateData?.details?.courseName}`}
                        </Text>
                      </DetailsElement>
                    </Grid.Column>
                  </CertificateInfoWrapper>

                  {certificateData?.file && (
                    <SegmentDoc bgColor={theme.colors.white}>
                      <Segment padding="16px 20px" verticalCenter>
                        <FontAwesomeIcon
                          size="2x"
                          icon={faFilePdf}
                          color={theme.colors.plusDarkGrey}
                        />
                        <SegmentDocWrapper>
                          <DocSubWrapper>
                            <ModelText
                              size="article"
                              color="plusDarkGrey"
                              weight="medium"
                              iconPadding="18px"
                            >
                              {certificateData?.file?.name}
                            </ModelText>
                            <Text size="xSmall" color="darkGrey" weight="regular">
                              {certificateData?.file?.description}
                            </Text>
                          </DocSubWrapper>
                          <DocLinkText
                            loading={loadingStatus.loadingCertificateFile}
                            loadingProps={{ width: '96px' }}
                            onClick={() => {
                              dispatch(
                                actions.CertificatesValidator.setLoadingStatus({
                                  fieldName: 'loadingCertificateFile',
                                  fieldValue: true,
                                })
                              );

                              if (certificateData?.file?.url) {
                                API.files.getFileOnTab
                                  .call(
                                    `${config.API_WSO2}${config.API_SEC_VIRTUAL_PATH}${certificateData?.file?.url}`
                                  )
                                  .then((value) => {
                                    dispatch(
                                      actions.CertificatesValidator.setLoadingStatus({
                                        fieldName: 'loadingCertificateFile',
                                        fieldValue: false,
                                      })
                                    );
                                  })
                                  .catch((e) => {
                                    dispatch(
                                      actions.CertificatesValidator.setLoadingStatus({
                                        fieldName: 'loadingCertificateFile',
                                        fieldValue: false,
                                      })
                                    );

                                    dispatch(
                                      actions.Toaster.showToaster({
                                        title: t(
                                          'secVirtualNotifications.general_errorLoadingFile'
                                        ),
                                        icon: 'error',
                                        type: 'danger',
                                      })
                                    );
                                  });
                              }
                            }}
                            size="xSmall"
                            color="darkGrey"
                            weight="regular"
                          >
                            {t('certificates.viewCert')}
                          </DocLinkText>
                        </SegmentDocWrapper>
                      </Segment>
                    </SegmentDoc>
                  )}

                  {certificateData?.details?.expirationDate !== null ?? 
                    <ValidationDateWrapper>
                      <Grid.Column largeScreen="16" tablet="16" mobile="16" computer="16">
                        <DetailsElement>
                          <Text size="xSmall" color="grey">
                            {t('certificates.certValidationDate')}
                          </Text>
                          <Text
                            size="article"
                            weight="regular"
                            color={certificateData?.hasExpired ? 'dangerRed' : 'successGreen'}
                          >
                            {certificateData?.hasExpired
                              ? t('certificates.certWithExpiredDate')
                              : `${moment(certificateData?.details?.expirationDate).format(
                                  'DD/MM/YYYY'
                                )}`}
                          </Text>
                        </DetailsElement>
                      </Grid.Column>
                    </ValidationDateWrapper>
                  }

                  {hasValidated && certificateData?.hasExpired && (
                    <Text size="mediumSmall" color={'plusDarkGrey'} weight={'regular'}>
                      {t('certificates.certExpiredText')}
                    </Text>
                  )}
                </ResultWrapper>
              )}

              {hasValidated && certificateData?.validAccessKey && certificateData?.hasExpired && (
                <Text
                  size="mediumSmall"
                  color={'plusDarkGrey'}
                  weight={'medium'}
                  style={{ marginTop: '28px' }}
                >
                  {t('certificates.expiredCertCodeText')}
                </Text>
              )}
            </SegmentTitleWrapper>

            {!hasValidated && (
              <OutlineRecaptcha outlineRecap={outlineRecap}>
                <ReCAPTCHA sitekey={config.RECAPTCHA_PUBLIC_KEY} onChange={onChangeRecaptcha} />
                {outlineRecap && (
                  <DescriptionWrapper>
                    <Text size="xSmall" weight="regular" color="dangerRed">
                      {' *'}
                    </Text>
                    <Text size="xSmall" weight="regular" color="dangerRed">
                      {t('certificates.recaptchaRequired', { textOnly: true })}
                    </Text>
                  </DescriptionWrapper>
                )}
              </OutlineRecaptcha>
            )}
          </Segment>

          {!hasValidated && (
            <CustomButton
              data-testid={`submit_cert_code_button`}
              disabled={hasValidated}
              loading={loadingStatus.loadingCertificateValidation}
              loadingProps={{ width: '100px' }}
              primary
              rightIcon={<FontAwesomeIcon size="sm" icon={faCheck} />}
              onClick={handleSubmit(submitForm)}
            >
              {t('certificates.validateCode')}
            </CustomButton>
          )}

          {hasValidated && !certificateData?.hasExpired && (
            <ViewInfoWrapper>
              <Grid.Column largeScreen="16" tablet="16" mobile="16" computer="16">
                <DetailsElement>
                  <Text size="xSmall" color="grey">
                    {t('certificates.viewInformation')}
                  </Text>
                  <Text size="article" weight="regular" color="plusDarkGrey">
                    {`${moment().format('DD/MM/YYYY | HH:mm')}`}
                  </Text>
                </DetailsElement>
              </Grid.Column>
            </ViewInfoWrapper>
          )}

          {hasValidated && (
            <BottomButtonWrapper>
              <CustomValAnotherButton
                data-testid={`validate_another_code_button`}
                disabled={!hasValidated}
                primary
                rightIcon={<FontAwesomeIcon size="sm" icon={faRedoAlt} />}
                onClick={() => {
                  reset();
                  setRecaptchaValue(null);
                  dispatch(setHasValidated(false));
                }}
              >
                {t('certificates.validateAnotherCode')}
              </CustomValAnotherButton>
            </BottomButtonWrapper>
          )}
        </Wrapper>
      }
    />
  );
};

export default CertificateValidator;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 4px;

  div:first-child {
    margin-right: 6px;
  }
`;

const FormInputWrapper = styled.div<{ backgroundColor?: string }>`
  display: flex;
  margin-top: 6px;
  align-items: center;

  > div:first-child {
    margin-right: 35px;
  }

  input {
    ${({ backgroundColor }) => (backgroundColor ? `background-color: ${backgroundColor}` : '')};
  }
`;

const CustomFormInput = styled(FormInput)<{ borderColor: string }>`
  border-bottom-style: solid !important;
  border-bottom-width: 1px !important;
  border-color: ${({ borderColor }) => borderColor} !important;
  min-width: 300px;
`;

const CustomButton = styled(Button)`
  margin-top: 40px;
  width: 149px;
`;

const CustomValAnotherButton = styled(Button)`
  margin-top: 40px;
  max-width: 200px;
`;

const BottomButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
`;

const ResultWrapper = styled.div`
  margin-top: 40px;
`;

const DetailsElement = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const CertificateInfoWrapper = styled(Grid)`
  &&& {
    .column {
      padding-top: 18px;
      padding-bottom: 18px;
    }
  }
`;

const SegmentDoc = styled.div<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};

  margin-top: 24px;
`;

const SegmentDocWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 18px;
  width: 100%;
`;

const DocSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModelText = styled(Text)`
  line-height: 1.5;
`;

const ValidationDateWrapper = styled.div`
  margin-top: 16px;
`;

const ViewInfoWrapper = styled.div`
  margin-top: 16px;
`;

const DocLinkText = styled(Text)`
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const OutlineRecaptcha = styled.div<{
  outlineRecap: boolean;
}>`
  margin-top: 32px;
  > div {
    > div {
      display: flex !important;
      justify-content: left !important;
    }
  }
`;
