/**
 * ScheduleConfirmationPreview container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from './selectors';

import styled from 'styled-components';

import { MultiToggle, ScheduleGrid } from '@paco_ua/pacoui';

import CaptionSchedule from 'scenes/Schedules/containers/CaptionSchedule';

import { filterUcsByPeriod } from './utils';

import { t } from 'app';

//import config from 'config'

const ScheduleConfirmationPreview: React.FC = () => {
  const { ucs, periods, period, scheduleGridInterval } = useSelector(selectors.getSchedulesSlice);

  // Preview should show only 'selected' classes
  const selectedUcs = ucs.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        if (!eachClass.selected) {
          return { ...eachClass, filtered: true };
        } else {
          return eachClass;
        }
      }),
    };
  });

  // We need to have a 'clone' of ucs and period
  // to change periods and filters ucs without impacting
  // 'period' and 'ucs' from Schedules' slice
  // (if we impact that slice, the buttons and the cards behind the modal will change
  // even if we are clicking on Modal's buttons)
  const [periodClone, setPeriod] = useState(period);
  const [ucsClone, setUcs] = useState(selectedUcs);

  const periodButtons = periods.map((periodItem, key) => ({
    children: `Período ${key + 1}`,
    onClick: () => {
      setPeriod(periodItem);
      setUcs(filterUcsByPeriod(ucsClone, periodItem));
    },
    selected: periodClone === periodItem,
    testId: 'schedules_multitoggle_period',
  }));

  return (
    <Wrapper>
      {!!periods.length && (
        <PeriodAndButtonsWrapper>
          <MultiToggle buttons={periodButtons} content={'buttons'} scrollableAtWidth={280} />
        </PeriodAndButtonsWrapper>
      )}
      <ScheduleGrid
        t={{ noUcs: t('sgh.noUc_plural') }}
        weekdays={6}
        startTime={scheduleGridInterval.min}
        endTime={scheduleGridInterval.max}
        intervalInMin={60}
        schedule={ucsClone}
        disabled
      />
      <CaptionWrapper>
        <CaptionSchedule ucs={ucs} />
      </CaptionWrapper>
    </Wrapper>
  );
};

export default ScheduleConfirmationPreview;

const Wrapper = styled.div`
  padding: 0;
`;

const PeriodAndButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 12px 18px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
`;

const CaptionWrapper = styled.div`
  margin-top: -20px;
  padding-left: 63px;
`;
