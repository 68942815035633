/**
 * NewCertificate scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest, call, put, putResolve, select } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import { t } from 'app';

import API from 'api';
import * as selectors from './selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { IDebitData } from 'scenes/VirtualSecretary/Payments/types';

import { getFinalResults } from '../utils';

import _ from 'lodash';

import ErrorHandler from 'shared/errorHandler';

function* onMountSaga() {
  yield put(actions.NewCertificate.onLoadPage());
}

function* onLoadPageSaga() {
  try {
    yield put(
      actions.NewCertificate.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: true,
      })
    );

    yield put(
      actions.NewCertificate.setErrorStatus({
        fieldName: 'errLoading',
        fieldValue: false,
      })
    );

    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    yield put(
      actions.NewCertificate.setErrorStatus({
        fieldName: 'errLoading',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorLoadingRegistrations'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.NewCertificate.setLoadingStatus({
        fieldName: 'tabLoading',
        fieldValue: false,
      })
    );

    yield put(
      actions.NewCertificate.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }
}

function* setActiveRegistrationSaga() {
  const certificateData = yield select(selectors.getNewCertificateSlice);
  const registrations = yield select(selectors.getRegistrations);

  // check already the student active registration
  const activeRegistration = registrations?.find((reg) => reg.registrationState === 'activo');
  yield put(
    actions.NewCertificate.setCertificateData({
      ...certificateData,
      registrationId: activeRegistration?.registrationId ?? null,
    })
  );
}

function* setCertificateDataSaga(action: PayloadAction<any>) {
  /* let originalPayload: any = _.cloneDeep(action.payload);
  let radioBtnMappings = {} as any;


  yield put(actions.NewCertificate.setRadioBtnMappings(radioBtnMappings)); */

  yield put(actions.NewCertificate.setProcessedCertificateData(action.payload));
}

function* getCertificateTypesSaga(params?: any) {
  yield put(
    actions.NewCertificate.setLoadingStatus({
      fieldName: 'tabLoading',
      fieldValue: true,
    })
  );

  try {
    const newCertificateSlice = yield select(selectors.getNewCertificateSlice);

    const certificateTypes = yield call(API.secVirtual.getCertificateTypes.call, {
      registrationId: newCertificateSlice.certificateData.registrationId,
      ...params?.payload,
    });

    yield put(
      actions.NewCertificate.setCertificateTypes({
        data: certificateTypes.data,
        filters: certificateTypes.filters,
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newCertificate_errorGetTypes'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.NewCertificate.setLoadingStatus({
        fieldName: 'tabLoading',
        fieldValue: false,
      })
    );
  }
}

function* getCertificateFormSaga() {
  yield put(
    actions.NewCertificate.setLoadingStatus({
      fieldName: 'tabLoading',
      fieldValue: true,
    })
  );

  try {
    const newCertificateSlice = yield select(selectors.getNewCertificateSlice);

    const certificateForm = yield call(API.secVirtual.getCertificateForm.call, {
      registrationId: newCertificateSlice.certificateData.registrationId,
      certificateCode: newCertificateSlice.selectedCertificateType.id,
    });

    // create radio button mappings, substitue value, valueId and visibleIf fields

    // store the changed form in the slice

    //let originalPayload: any = _.cloneDeep(action.payload);
    let radioBtnMappings = {} as any;

    // store the pointers to the requestedCertificate and details fields of the certificateForm in an array, in order
    // to iterate over them
    let tmpCertificateForm = [certificateForm.requestedCertificate, certificateForm.details];

    tmpCertificateForm.forEach((item, index) => {
      if (item && item.values) {
        // first create mappings of radio button options value_ids and change the payoad radio button options value_ids accordingly
        item.values.forEach((formComp, index) => {
          if (formComp.type === 'radio_button') {
            formComp.values.forEach((radioBtnOpt, subindex) => {
              if (!radioBtnMappings[formComp.nameKey]) {
                radioBtnMappings[formComp.nameKey] = [radioBtnOpt.valueId];
              } else {
                radioBtnMappings[formComp.nameKey].push(radioBtnOpt.valueId);
              }

              item.values[index].values[subindex] = {
                ...radioBtnOpt,
                valueId: subindex,
              };
            });
          }
        });

        // update default value for each radio button list according to the mapping
        item.values.forEach((formComp, index) => {
          if (formComp.type === 'radio_button') {
            radioBtnMappings[formComp.nameKey].forEach((value: number, index: number) => {
              if (value === formComp.value) {
                formComp.value = index;
                return;
              }
            });
          }
        });

        // then, change the visible_if values referencing radio button values
        item.values.forEach((formComp, index) => {
          if (formComp.visibleIf) {
            formComp.visibleIf.forEach((visibleIfElem, subindex) => {
              Object.keys(visibleIfElem).forEach((elemKey, subsubindex) => {
                // if the current visible_if object property key is in the mappings => itś related to radio buttons
                if (radioBtnMappings[elemKey]) {
                  // find mapped value, which corresponds to the position in the array of a property of radioBtnMappings
                  let mappedValue = -1;
                  radioBtnMappings[elemKey].forEach((value, index) => {
                    if (value === visibleIfElem[elemKey]) {
                      mappedValue = index;
                      return; // end loop
                    }
                  });

                  // set the appropriately mapped value
                  item.values[index].visibleIf[subindex][elemKey] = mappedValue;
                }
              });
            });
          }
        });
      }
    });

    yield put(actions.NewCertificate.setRadioBtnMappings(radioBtnMappings));
    yield put(
      actions.NewCertificate.setCertificateData({
        ...newCertificateSlice.certificateData,
        form: certificateForm,
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newCertificate_errorGetForm'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.NewCertificate.setLoadingStatus({
        fieldName: 'tabLoading',
        fieldValue: false,
      })
    );
  }
}

function* addDocumentToFormSchemaSaga(action: PayloadAction<any>) {
  let newForm = _.cloneDeep(action.payload);

  if (newForm.additionalDocuments.values.length > 0) {
    for (let i = newForm.additionalDocuments.values.length; i > 0; i--) {
      let idFoundInUse = false;
      action.payload.additionalDocuments.values.forEach((doc, key) => {
        if (doc.nameKey === `otherDocuments${i}`) {
          idFoundInUse = true;
        }
      });

      const initialAdditionalDoc = action.payload.additionalDocuments.values.find(
        (item) => item.nameKey === 'otherDocuments'
      );

      if (!idFoundInUse) {
        newForm.additionalDocuments.values.push({
          ...initialAdditionalDoc,
          nameKey: `otherDocuments${i}`,
          //placeholderKey: 'otherDocumentsPlaceholder',
        });

        break;
      }
    }
  }

  yield put(actions.NewCertificate.setFormSchema(newForm));
}

function* postCertificateChosenOptionsSaga(action: PayloadAction<any>) {
  // update cost according to the selected options
  yield put(
    actions.NewCertificate.setLoadingStatus({
      fieldName: 'costLoading',
      fieldValue: true,
    })
  );

  const newCertificateSlice = yield select(selectors.getNewCertificateSlice);

  try {
    let body = {};

    if (
      action.payload.exemptionMotiveOther !== undefined &&
      action.payload.exemptionMotiveOther !== null
    ) {
      body = {
        ...action.payload,
        exemptionMotive: `${action.payload.exemptionMotive}: ${action.payload.exemptionMotiveOther}`,
        numberCopies: action.payload.pageCounter,
        deliveryMethod: action.payload.deliveryMethod,
      };
    } else {
      body = {
        ...action.payload,
        exemptionMotive: action.payload.exemptionMotive,
        numberCopies: action.payload.pageCounter,
        deliveryMethod: action.payload.deliveryMethod,
      };
    }

    const selectedRegistrationId = newCertificateSlice.certificateData.registrationId;

    const processeBody = {
      certTypeId: newCertificateSlice.selectedCertificateType.id,
      withExemption: Boolean(body['exemption']),
      formatId: body['format'] || 0,
      deliveryMethodId: body['deliveryMethod'],
      numberCopies: body['numberCopies'],
      postalCode: body['postalCode'],
      ucsCodes:
        body['ucs_codes']
          ?.filter((uc) => {
            if (uc.isAvailable === undefined) return true;
            if (uc.isAvailable === true) return true;
            return false;
          })
          .map((uc) => uc.ucCode) ?? [],
    };

    if (processeBody.formatId !== undefined && processeBody.formatId !== null) {
      const totalCosts = yield call(
        API.secVirtual.postCertificateOptions.call,
        processeBody,
        selectedRegistrationId
      );

      yield put(actions.NewCertificate.setCertificateCosts(totalCosts));
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newCertificate_errorGetCost'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.NewCertificate.setLoadingStatus({
        fieldName: 'costLoading',
        fieldValue: false,
      })
    );
  }
}

function* removeDocumentFromFormSchemaSaga(action: PayloadAction<any>) {
  const form = action.payload.form;
  const docNamekey = action.payload.key;

  let newForm = _.cloneDeep(form);
  newForm.additionalDocuments.values = [];

  if (form.additionalDocuments.values.length > 0) {
    form.additionalDocuments.values.forEach((doc, key) => {
      if (doc.nameKey !== docNamekey) {
        newForm.additionalDocuments.values.push({
          ...doc,
        });
      }
    });
  }

  yield put(actions.NewCertificate.setFormSchema(newForm));
}

function* submitCertificateSaga(action: PayloadAction<any>) {
  // submit filled form
  try {
    //const registrationId = yield select(selectors.getRegistrationId);
    const newCertificateSlice = yield select(selectors.getNewCertificateSlice);
    const selectedRegistrationId = newCertificateSlice.certificateData.registrationId;

    const {
      certificateData,
      certificateOptions,
      selectedCertificateType,
      payNow,
      isExempt,
    } = action.payload;

    yield put(
      actions.NewCertificate.setLoadingSubmission({
        payNow: true,
        payLater: true,
      })
    );

    let formChosenOptions = getFinalResults(
      certificateData.form,
      certificateOptions,
      newCertificateSlice.radioBtnMappings
    );

    // if (formChosenOptions) {
    //   formChosenOptions['documents'] = undefined;
    // }

    const body = {
      theme: selectedCertificateType.theme,
      type: selectedCertificateType.name,
      code: selectedCertificateType.code,
      certTypeId: selectedCertificateType.id,
      details: {
        ...formChosenOptions,
        deliveryMethod: formChosenOptions?.deliveryMethod,
        exemption:
          formChosenOptions?.exemption && formChosenOptions?.exemption !== 'withoutExemption'
            ? true
            : false,
        email: formChosenOptions?.email?.alternativeEmail,
        exemptionMotive: formChosenOptions?.exemptionMotive,
        exemptionOtherText: formChosenOptions?.exemptionMotiveOther,
        numberCopies: formChosenOptions?.pageCounter ? formChosenOptions?.pageCounter : null,
        ucsCodes: formChosenOptions?.ucs_codes?.map((uc) => uc.ucCode),
      },
      documents: formChosenOptions?.documents ? formChosenOptions.documents : [],
    };

    let foundYear;
    let ucCodesObjectsList = [] as any;

    certificateData.form.requestedCertificate.values?.forEach((field) => {
      if (!foundYear && field.type === 'checkbox_table') {
        Object.keys(certificateOptions).forEach((namekey) => {
          if (field.nameKey === namekey) {
            foundYear = namekey;
          }
        });

        if (foundYear) {
          field.values.forEach((item) => {
            if (body.details['ucs_codes'].map((uc) => uc.ucCode).includes(item.valueId)) {
              ucCodesObjectsList.push({
                schoolYear: item.nameKey.academicYear.split('/')[0], //foundYear.split('/')[0],
                ucCode: item.nameKey.ucCode,
                semester: item.nameKey.semester,
              });
            }
          });
        }
      }
    });

    body.details['ucs'] = ucCodesObjectsList;

    const response = yield call(
      API.secVirtual.postCertificateForm.call,
      body,
      selectedRegistrationId
    );

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.newCertificate_successSubmit'),
        icon: 'check',
        type: 'success',
      })
    );

    yield putResolve(actions.NewCertificate.setCertificateSubmitted(true));

    console.log(response);

    if (response?.goApplication) {
      yield put(
        actions.App.navigateTo({
          key: 'applicationslist',
        })
      );
    } else if (!payNow || isExempt) {
      yield put(
        actions.App.navigateTo({
          key: 'certificatesdetails',
          params: {
            certificateId: response.certificateId,
            registrationId: selectedRegistrationId,
          },
        })
      );
    } else {
      const certificateDetails = yield call(
        API.secVirtual.getCertificatesDetails.call,
        response.certificateId,
        selectedRegistrationId
      );
      const certificatePaymentData = [certificateDetails.payment.debit] as IDebitData[];

      yield put(actions.Payments.setPreSelectedDebits(certificatePaymentData));
      yield put(actions.Payments.setSelectedDebits(certificatePaymentData));

      yield put(
        actions.App.navigateTo({
          key: 'pendingDebitsPayment',
          state: selectedRegistrationId,
        })
      );
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newCertificate_errorSubmit'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.NewCertificate.setLoadingSubmission({
        payNow: false,
        payLater: false,
      })
    );
  }
}

function* onUnmountSaga() {
  yield put(actions.NewCertificate.resetPage());
}

export default function* watcherSignin() {
  yield takeLatest('NewCertificate/onMount', onMountSaga);
  yield takeLatest('NewCertificate/onUnmount', onUnmountSaga);
  yield takeLatest('NewCertificate/getCertificateTypes', getCertificateTypesSaga);
  yield takeLatest('NewCertificate/getCertificateForm', getCertificateFormSaga);
  yield takeLatest('NewCertificate/submitCertificate', submitCertificateSaga);
  yield takeLatest('NewCertificate/postCertificateChosenOptions', postCertificateChosenOptionsSaga);
  yield takeLatest('NewCertificate/addDocumentToFormSchema', addDocumentToFormSchemaSaga);
  yield takeLatest('NewCertificate/removeDocumentFromFormSchema', removeDocumentFromFormSchemaSaga);
  yield takeLatest('NewCertificate/onLoadPage', onLoadPageSaga);
  yield takeLatest('NewCertificate/setCertificateData', setCertificateDataSaga);
  yield takeLatest('NewCertificate/setActiveRegistration', setActiveRegistrationSaga);
}
