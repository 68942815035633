/**
 * SchedulesGuide scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { put, call, takeLatest } from 'redux-saga/effects';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';

import ErrorHandler from 'shared/errorHandler';

function* onMountSaga(action) {
  try {
    yield put(actions.SchedulesGuide.setLoadingMarkdown(true));
    const markdowns = yield call(API.translations.getMarkdown.call);

    let param = action.payload;

    const guides = markdowns.find((markdown) => markdown.fields.name === 'apoio');

    if (guides) {
      yield put(
        actions.SchedulesGuide.setMarkdown(
          guides.fields.properties.find((field) => field.key === param)
        )
      );
      yield put(actions.SchedulesGuide.setLoadingMarkdown(false));
    } else {
      throw new Error();
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetScheduleGuide'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.SchedulesGuide.reset());
}

export default function* watcherSignin() {
  yield takeLatest('SchedulesGuide/onMount', onMountSaga);
  yield takeLatest('SchedulesGuide/onUnmount', onUnmountSaga);
}
