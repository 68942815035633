/**
 * FormLargeTextArea component
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React from 'react';

import styled from 'styled-components';

import { TextArea as SemanticTextArea } from 'semantic-ui-react';

import { Text } from '@paco_ua/pacoui';

interface IFormTextArea {
  register: any;
  onChange: (data: any) => void;
  error?: any;
  success?: any;
  name: string;
  value: string;
  placeholder: string;
  defaultValue: string;
}

const FormLargeTextArea: React.FC<IFormTextArea> = ({
  placeholder,
  name,
  error,
  onChange,
  defaultValue,
}) => {
  return (
    <Wrapper>
      <StyledTextArea
        placeholder={placeholder}
        onChange={(e, data) => onChange(data.value)}
        name={name}
        defaultValue={defaultValue}
      />
      <MessageArea>
        {error && (
          <Text size="xSmall" weight="medium" color="dangerRed">
            {error}
          </Text>
        )}
      </MessageArea>
    </Wrapper>
  );
};
export default FormLargeTextArea;

const Wrapper = styled.div`
  border: 0px;
`;

const StyledTextArea = styled(SemanticTextArea)`
  min-height: 80px;
  max-height: 300px;
  width: 100%;
  padding: 12px;
  background-color: #f5f5f5;
  border: 0px;
  font-size: 14px;
  line-height: 1.3;
  resize: vertical;

  ::placeholder {
    width: 100%;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.darkGrey};
    line-height: 1.3;
  }

  ::focus {
    outline: none;
  }
`;

const MessageArea = styled.div``;
