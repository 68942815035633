/**
 * ExamEnrollment scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';

import { actions } from 'store/rootSlices';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import styled from 'styled-components';
import { CallToAction, Text } from '@paco_ua/pacoui';

const TransferalCompetence = ({ t }) => {
  const { onMount, onUnmount } = actions.TransferalCompetence;

  const dispatch = useDispatch();
  const { loading } = useSelector(selectors.getExamEnrollmentSlice);
  const user = useSelector(selectors.getUser);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      <CallToAction
        topBorder       
        footer={
          <Text color="darkGrey" size="article">
            {t('generic.iframeBanner')}
          </Text>
        }
        padding={'15px 38px'}
        noPointer
      />
     <iframe src={"https://paco.ua.pt/PACO2/secvirtual/opcoes/aviso.asp?token=" + user.idToken + "&impe=" + user.impersonate} width="100%" height="800px" frameBorder="0" ></iframe>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  text-align: center;
`;
export default TransferalCompetence;
