import { IPagination, ITranslations } from '@paco_ua/pacoui';
import { IDropdownOption } from 'shared/types';
import { BooleanLocale } from 'yup/lib/locale';
export interface ICallParams {
  offset: number;
  limit: number;
}

export interface IPaymentData {
  paymentId: number;
  value: number;
  paymentType: string;
  description: string;
  dueDate: string;
  paymentDate: string;
  billReceiptLink: string;
  aTSubmissionDate: string;
  receiptState: string;
  debitType: string;
}

export interface IWEBSOCKET_PAYMENT_MSG {
  type: number;
  target?: string;
  arguments?: IWEBSOCKET_PAYLOAD[];
}

export interface IWEBSOCKET_PAYLOAD {
  request_payment_id: number;
  result: EWEBSOCKET_RESULT;
  operation: EWEBSOCKET_PAYMENT_TYPE;
}

export enum EWEBSOCKET_PAYMENT_TYPE {
  mbway_payment = 'mbway_payment',
  mb_payment = 'mb_payment',
}

export enum EWEBSOCKET_RESULT {
  expired = 'expired',
  cancel = 'cancel',
  success = 'success',
}

export interface IPayments {
  data: IPaymentData[];
  loading: boolean;
  total: number;
}

export interface IDebitData {
  debitId: number;
  debitOriginId: number;
  debitAcademicYear: number;
  debitDate: string | null;
  debitType: string;
  description: string;
  debitStatus: string;
  value: number;
  totalValue: number;
  coin: string;
  dueDate: string | null;
  associatedCosts:
    | null
    | [
        {
          type: string;
          value: number;
          copies: number;
          debitId: number;
        }
      ];
  //tuitionNumber: number;
}

export interface IDebits {
  data: IDebitData[];
  loading: boolean;
  total: number;
}

export enum PENDING_DEBIT_STATE {
  awaitingPayment = 'awaitingPayment',
  awaitingReceiptEmission = 'awaitingReceiptEmission',
}

export enum PAYMENT_METHOD {
  mb = 'mb',
  mbway = 'mbway',
  visa = 'visa',
}

export interface IPendingDebitsEntry {
  debits: IDebitData[];
  dueDate: string;
  paymentRequestId: number;
  totalValue: number;
  status: PENDING_DEBIT_STATE.awaitingPayment | PENDING_DEBIT_STATE.awaitingReceiptEmission;
  paymentMethod: PAYMENT_METHOD.mb | PAYMENT_METHOD.mbway | PAYMENT_METHOD.visa;
  remainingTime: string;
}

export interface IInitialState {
  payments: IPayments;
  debits: IDebits;
  multiSelectionModal: boolean;
  selectedDebits: IDebitData[];
  preSelectedDebits: IDebitData[];
  filterParams: {
    type: string;
    search: string;
  };
  debitsFilterTypes: IDropdownOption[];
  pendingDebits: IPendingDebits;
  isMbInfoModalOpened: boolean;
  isCancelPaymentsModalOpened: {
    id: number;
    status: boolean;
  };
  consultedMbPaymentInfo: {
    paymentRequestId: number;
    entity: number;
    ref: number;
    value: string;
    dueDate: string;
  } | null;
  loadingStatus: {
    loading: boolean;
    mbInfoModalLoading: boolean;
    loadingDebitsTable: boolean;
    loadingPendingDebitsTable: boolean;
    loadingPaymentsTable: boolean;
  };
  errorStatus: {
    errorLoadingMbInfo: boolean;
    errorLoadingDebitsTable: boolean;
    errorLoadingPendingDebitsTable: boolean;
    errorLoadingPaymentsTable: boolean;
  };
  isSocketActivated: boolean;
}

export interface IPaymentMethodsInfoProps {
  t: ITranslations;
}

export interface IPaymentsTableProps extends IPayments {
  t: ITranslations;
  fetchPayments(params: ICallParams): void;
}

export interface IPendingDebits {
  data: IPendingDebitsEntry[];
  loading: boolean;
  //total: number;
}

export interface IPendingDebitsTableProps extends IPendingDebits {
  t: ITranslations;
  openMbInfoModal: (paymentRequestId) => void;
}

export interface IDebitsTableProps extends IDebits {
  t: ITranslations;
  preSelectedDebits: IDebitData[];
  fetchDebits(params: ICallParams): void;
  setPreSelectedDebits: (debits) => void;
  openPendingDebitsPaymentPage: any;
  selectedDebits: IDebitData[];
  addFilters: any;
  filterParams: any;
}

export interface IMultiPaymentsSelectionProps extends IDebits {
  setMultiple: (multiple: IDebitData[], any) => void;
  fetchUnselectedDebits(params: ICallParams): void;
  selectedDebits: IDebitData[];
  preSelectedDebits: IDebitData[];
  addFilters: any;
  filterParams: any;
}

export interface IDebitStatus {
  UNPAID: string;
  IN_DEBIT: string;
  AWAITING_PAYMENT: string;
  IN_DEBIT_AWAITING_PAYMENT: string;
}

export { ITranslations, IPagination };
