/**
 * New Application Dropdown component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useState } from 'react';

import styled from 'styled-components';

import { t } from 'app';

import { Dropdown } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';

import { Text } from '@paco_ua/pacoui';

interface INewApplicationDropdown {
  register: any;
  onChange: (value: any) => void;
  error?: any;
  success?: any;
  name: string;
  placeholder: string;
  options: any[];
  defaultValue: number;
  value: number;
}

const NewApplicationDropdown: React.FC<INewApplicationDropdown> = ({
  error,
  name,
  defaultValue,
  onChange,
  value,
  options,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <Wrapper>
      <Dropdown
        name={name}
        placeholder={t('applications.requestThirdStepStatementDropdownPlaceholder', {
          textOnly: true,
        })}
        onChange={(e, data) => onChange(data.value)}
        options={options.map((v, index) => ({
          key: `option_${index}`,
          text: v.nameKey,
          value: v.valueId,
        }))}
        icon={
          <DropdownToggleIcon>
            <FontAwesomeIcon icon={dropdownOpen ? faChevronUp : faChevronDown} />
          </DropdownToggleIcon>
        }
        defaultValue={defaultValue}
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
      />

      <MessageArea>
        {error && (
          <Text size="xSmall" weight="medium" color="dangerRed">
            {`* ${error}`}
          </Text>
        )}
      </MessageArea>
    </Wrapper>
  );
};

export default NewApplicationDropdown;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  && {
    .ui.dropdown {
      padding: 16px 8px;
      background-color: ${({ theme }) => theme.colors.lightGrey};
      align-self: flex-start;
    }

    .ui.dropdown > .text {
      color: ${({ theme }) => theme.colors.primary};
      font-size: 15px;
      font-weight: 400;
      line-height: 1.3;
      max-width: 600px;
    }

    .ui.dropdown > .dropdown.icon {
      color: ${({ theme }) => theme.colors.grey};
    }

    .ui.dropdown .menu {
      border-radius: 0;
    }

    .ui.dropdown .menu > .item {
      color: ${({ theme }) => theme.colors.plusDarkGrey};
      font-size: 12px;
    }
  }
`;

//const StyledDropdown = styled(Dropdown)``;

const DropdownToggleIcon = styled.div`
  display: inline-block;
  svg {
    color: ${({ theme }) => theme.colors.grey};
    font-size: 10px;
    margin-left: 6px;
  }
`;

const MessageArea = styled.div`
  margin-top: 2px;
`;
