/**
 * Payments Methods Info container
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Text } from '@paco_ua/pacoui';

import { MbIcon, MbWayIcon /*, VisaIcon*/ } from 'shared/components/Icons';

import { IPaymentMethodsInfoProps } from 'scenes/VirtualSecretary/Payments/types';

const PaymenMethodsInfo = ({ t }: IPaymentMethodsInfoProps) => (
  <Wrapper>
    <Text size="large" weight="regular">
      {t('payments.paymentMethodsTitle', { textOnly: true })}
    </Text>

    <Content>
      <Description size="article" weight="regular">
        {t('payments.paymentMethodsDescription', { textOnly: true })}
      </Description>

      <Icons>
        <MbIcon width={48} height={48} />
        <MbWayIcon width={48} height={48} />
      </Icons>
    </Content>
  </Wrapper>
);

export default PaymenMethodsInfo;

const Wrapper = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 18px;
`;

const Description = styled(Text)`
  line-height: 1.3;
`;

const Icons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  svg {
    margin-left: 8px;
    margin-right: 8px;
  }
`;
