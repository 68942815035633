/**
 * NewCertificateSubmission container utils
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { t } from 'app';
import { ISubmissionTabData, ISubmissionTabRadio, ISubmissionTabEditableGroup } from '../../types';

interface ISubmissionRequested {
  schoolYear?: string;
  ucsCodes: any;
}

interface ISubmissionDetails {
  address?: ISubmissionTabEditableGroup[];
  email?: ISubmissionTabEditableGroup[];
  deliveryMethod: ISubmissionTabRadio;
  format: ISubmissionTabRadio;
  language: ISubmissionTabRadio;
  pageCounter?: number;
}

/**
 * Return the form information related to the request certificate section
 * for display in the new certificate submission tab.
 */
const getRequestedCertificateSubmission = (
  requestCertificateData,
  formData
): ISubmissionRequested | undefined => {
  let formRequestData = {} as ISubmissionRequested;

  if (requestCertificateData) {
    requestCertificateData.forEach((element) => {
      if (formData[element.nameKey] !== null && formData[element.nameKey] !== undefined) {
        if (element.type === 'dropdown') {
          const selectedOption = formData[element.nameKey];

          element.values.forEach((elem) => {
            if (elem.valueId === selectedOption) {
              formRequestData[element.nameKey] = elem.nameKey;
              return;
            }
          });
        } else if (element.type === 'checkbox_table') {
          let tableRows = [] as any[];

          formData[element.nameKey].forEach((formDataItem) => {
            element.values.forEach((valueItem) => {
              if (formDataItem === valueItem.valueId) {
                tableRows.push(valueItem.nameKey);
              }
            });
          });

          formRequestData[element.nameKey] = tableRows;
        }
      }
    });

    return formRequestData;
  } else {
    return undefined;
  }
};

/**
 * Return the form information related to the details section for display in the
 * new certificate submission tab.
 */
const getDetailsSubmission = (detailsData, formData): ISubmissionDetails => {
  let formRequestData = {} as any;

  detailsData.forEach((element) => {
    if (element.type === 'radio_button') {
      if (formData[element.nameKey] !== null && formData[element.nameKey] !== undefined) {
        const selectedOption = formData[element.nameKey];

        const nameKey = element.values[selectedOption].nameKey;

        formRequestData[element.nameKey] = {
          value: t(`certificates.${nameKey}`, {
            textOnly: true,
          }),
          valueNameKey: nameKey,
          elementNameKey: element.nameKey,
          description: element.values[selectedOption].descriptionKey
            ? element.values[selectedOption].descriptionKey
            : undefined,
        };
      }
    } else if (element.type === 'dropdown') {
      if (formData[element.nameKey] !== null && formData[element.nameKey] !== undefined) {
        const selectedOption = formData[element.nameKey];

        formRequestData[element.nameKey] = element.values[selectedOption].nameKey;
      }
    } else if (element.type === 'editableGroupComponent') {
      // check if any field is undefined
      let ignoreField = false;

      if (formData[element.nameKey] === null || formData[element.nameKey] === undefined) {
        ignoreField = true;
      }

      if (!ignoreField) {
        formRequestData[element.nameKey] = Object.keys(element.values).map((index) => {
          const key = element.values[index].nameKey;

          if (
            formData[element.nameKey][key] !== null &&
            formData[element.nameKey][key] !== undefined
          ) {
            return {
              nameKey: key,
              value: formData[element.nameKey][key],
            };
          }
        });
      }
    } else {
      formRequestData[element.nameKey] = formData[element.nameKey];
    }
  });

  return formRequestData;
};

/**
 * Return the form information for display in the new certificate submission tab.
 * @param form slice certificateData.form
 * @param formValues slice certificateData.formValues
 */
export const getSubmissionTabData = (form, formValues): ISubmissionTabData => {
  const { requestedCertificate, details /*, mandatoryDocuments, additionalDocuments*/ } = form;

  return {
    ...getRequestedCertificateSubmission(requestedCertificate.values, formValues),
    ...getDetailsSubmission(details.values, formValues),
  } as ISubmissionTabData;
};
