/**
 * PersonalData scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

export const reformatPersonalDataBlocks = (personalDataBlocks, picture) => {
  let personalDataBlocksClone = { ...personalDataBlocks };
  Object.keys(personalDataBlocksClone).forEach((personalDataBlockKey) => {
    personalDataBlocksClone[personalDataBlockKey] = {
      ...personalDataBlocksClone[personalDataBlockKey],
      editing: false,
      picture: personalDataBlockKey === 'profile' ? picture : null,
      showPicture: personalDataBlockKey === 'profile' ? true : false,
    };
  });

  return personalDataBlocksClone;
};

export const updateFormEditingStatus = (personalDataBlocks, block, editing) => ({
  ...personalDataBlocks,
  [block]: { ...personalDataBlocks[block], editing },
});

export const updatePersonalDataBlocks = (personalDataBlocks, block, form, editing) => ({
  ...personalDataBlocks,
  [block]: {
    ...personalDataBlocks[block],
    editing: editing,
    values: personalDataBlocks[block].values.map((field) => {
      let newValue = field;
      Object.keys(form).forEach((fieldName) => {
        if (fieldName === field.nameKey) {
          newValue = { ...field, value: form[fieldName] };
        }
      });
      return newValue;
    }),
  },
});
