import { call, takeLatest, put, select, all } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import { t } from 'app';
import ErrorHandler from 'shared/errorHandler';

import API from 'api';
import * as selectors from './selectors';

function* getGradeHistorySaga(action) {
  try {
    const codigoPauta = action.payload.codigoPauta;
    const responseHistory = yield call(API.pautas.getGradeHistory.call, codigoPauta);
    const responseDetails = yield call(API.pautas.getGradeDetails.call, codigoPauta);

    // Dispatch actions to update the store
    yield put(actions.GradesHistory.setGradeHistory(responseHistory));
    yield put(actions.GradesHistory.setGradeDetails(responseDetails));
  } catch (error) {
    const shouldRun = yield call(ErrorHandler, error);
  }
  yield put(
    actions.GradesHistory.setLoadingStatus({
      fieldName: 'loadingGradeHistory',
      fieldValue: false,
    })
  );
}

function* onMountSaga() {
  try {
    yield put(actions.GradesHistory.reset());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.certificateDetails_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  // yield put(actions.GradesHistory.reset());
}

export default function* gradesManagementWatcher() {
  yield takeLatest('GradeHistory/onMount', onMountSaga);
  yield takeLatest('GradeHistory/getGradeHistory', getGradeHistorySaga);
  yield takeLatest('GradeHistory/onUnmount', onUnmountSaga);
}
