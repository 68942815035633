import { IPagination } from '@paco_ua/pacoui';

export interface ICallParams {
  offset: number;
  limit: number;
}

export interface IRegistration {
  registrationId: number | null | undefined;
}

export const DEFAULT_PAGINATION_PARAMS = {
  offset: 0,
  limit: 10,
};

export const DEFAULT_PAGINATION = (total: number): IPagination => ({
  hasNext: total > 5 ? true : false,
  hasPrevious: false,
  offset: 0,
  limit: 10,
  total,
});

export interface IPaginationInfo {
  limit: number;
  offset: number;
  totalPages: number;
  totalRecords: number;
}

export interface IFreeOption {
  department: string;
  id: number;
  name: string;
  ects: number;
  scientificField: number;
  totalSlots: number;
  availableSlots: number;
  workload: {
    totalHours: number;
    classHours: {
      type: string;
      hours: number;
    }[];
  };
  irregular?: string;
  availableAt: string[];
  classes: [
    {
      type: string;
      name: string;
      lessons: {
        startDate: string;
        endDate: string;
      }[];
    }
  ];
}

export interface IFreeOptionsPayload {
  data: IFreeOption[];
  filters: [{ department: string[] }, { course: string[] }, { cycle: string[] }];
  limit: number;
  offset: number;
  totalPages: number;
  totalRecords: number;
  year: number;
  semester: number;
}
