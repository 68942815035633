/**
 * AuthWrapper component tests
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUser = (state: RootState) => state.App.user;
