import axios from 'axios';

import mockSchedule from 'api/schemas/mockSchedule';

import Endpoints from 'api/endpoints';
import config from 'config';

import { IApiCall } from '../index';

const getPhases = () => {
  //return mockSchedule.getPhases;
  return axios
    .get(Endpoints.sgh.phases)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getLogin = () => {
  // return mockSchedule.getLogin;
  return axios
    .get(Endpoints.sgh.login)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentAccess = ({ registrationId }) => {
  //return mockSchedule.getAccess;
  return axios
    .get(Endpoints.sgh.studentAccess, { params: { registrationId } })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getSchedule = (scheduleId, registrationId) => {
  //return mockSchedule.getSchedule;
  return axios
    .get(Endpoints.sgh.schedule, {
      params: {
        scheduleId,
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getSchedules = ({ registrationId }) => {
  //return mockSchedule.getSchedules;
  return axios
    .get(Endpoints.sgh.studentSchedules, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const deleteSchedule = (data) => {
  return axios
    .delete(Endpoints.sgh.schedule, { data })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const patchSchedules = (data) => {
  return axios
    .patch(Endpoints.sgh.studentSchedules, data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const patchSchedule = (data) => {
  return axios
    .patch(Endpoints.sgh.studentSchedules, data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getUcs = ({ registrationId }) => {
  //return mockSchedule.getUcs;
  return axios
    .get(Endpoints.sgh.studentUcs, { params: { registrationId } })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentFreeOptions = (params: {
  registrationId: number;
  search?: string;
  choosen?: boolean;
}) => {
  if (params?.search) {
    params = { ...params, search: params.search.trim() };
  }
  //if (params.choosen)
  //return mockSchedule.getSelectedFreeOptions;
  //return mockSchedule.getStudentFreeOptions;
  return axios
    .get(Endpoints.sgh.studentFreeOptions, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getFreeOptions = (params: {
  limit?: number;
  offset: number;
  department?: string;
  search?: string;
}) => {
  if (params?.search) {
    params = { ...params, search: params.search.trim() };
  }
  //return mockSchedule.getFreeOptions;
  return axios
    .get(Endpoints.sgh.freeOptions, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postSchedule = ({ data }) => {
  return axios
    .post(Endpoints.sgh.schedule, data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getHistory = ({
  final = true,
  registrationId,
  scheduleId = null,
  current = false,
  phase = null,
}) => {
  let params =
    scheduleId === null
      ? {
          registrationId,
          final,
          current,
          phaseName: phase,
        }
      : {
          registrationId,
          scheduleId,
          final,
          current,
          phaseName: phase,
        };

  //return mockSchedule.getHistory;
  return axios
    .get(Endpoints.sgh.history, {
      params: params,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getHistoryDetails = ({ final = true, registrationId, phase = null, seriationId }) => {
  return axios
    .get(Endpoints.sgh.historyDetails, {
      params: {
        registrationId,
        final,
        phaseName: phase,
        seriationId: seriationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getSeriation = ({ registrationId }) => {
  //return mockSchedule.getSeriation;

  return axios
    .get(Endpoints.sgh.studentSeriation, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export default {
  getLogin: {
    call: getLogin,
    scope: 'paco_2.0_sgh_login',
  } as IApiCall,
  getPhases: {
    call: getPhases,
    scope: 'paco_2.0_sgh_get_all_phases',
  } as IApiCall,
  getStudentAccess: {
    call: getStudentAccess,
    scope: 'paco_2.0_sgh_get_current_phase',
  } as IApiCall,
  getSchedule: {
    call: getSchedule,
    scope: 'paco_2.0_sgh_get_final_schedule',
  } as IApiCall,
  getSchedules: {
    call: getSchedules,
    scope: 'paco_2.0_sgh_get_temp_schedules',
  } as IApiCall,
  getUcs: {
    call: getUcs,
    scope: 'paco_2.0_sgh_get_student_ucs',
  } as IApiCall,
  getSeriation: {
    call: getSeriation,
    scope: 'paco_2.0_sgh_get_seriation_results',
  } as IApiCall,
  getHistory: {
    call: getHistory,
    scope: 'paco_2.0_sgh_get_history',
  } as IApiCall,
  getHistoryDetails: {
    call: getHistoryDetails,
    scope: 'paco_2.0_sgh_get_history_details',
  } as IApiCall,
  getStudentFreeOptions: {
    call: getStudentFreeOptions,
    scope: 'paco_2.0_sgh_get_free_options',
  } as IApiCall,
  getFreeOptions: {
    call: getFreeOptions,
    scope: 'paco_2.0_sgh_get_free_options_list',
  } as IApiCall,
  patchSchedules: {
    call: patchSchedules,
    scope: 'paco_2.0_sgh_patch_temp_schedules',
  } as IApiCall,
  deleteSchedule: {
    call: deleteSchedule,
    scope: 'paco_2.0_sgh_delete_all_schedules',
  } as IApiCall,
  patchSchedule: {
    call: patchSchedule,
    scope: 'paco_2.0_sgh_patch_temp_schedules',
  } as IApiCall,
  postSchedule: {
    call: postSchedule,
    scope: 'paco_2.0_sgh_post_temp_schedules',
  } as IApiCall,
};
