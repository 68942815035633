/**
 * Registration Details scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUser = (state: RootState) => state.App.user;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getLoading = (state: RootState) => state.RegistrationDetails.loading;
export const getRegistrationGeneralInfo = (state: RootState) =>
  state.RegistrationDetails.registrationGeneralInfo;
export const getEligibility = (state: RootState) => state.RegistrationDetails.eligibility;
export const getHistory = (state: RootState) => state.RegistrationDetails.history;
export const getScholarship = (state: RootState) => state.RegistrationDetails.scholarship;
export const getRegDetailsSlice = (state: RootState) => state.RegistrationDetails;
