/**
 * EnrollmentRenewal scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';

import { actions } from 'store/rootSlices';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

const EnrollmentRenewal = ({ t }) => {
  const { onMount, onUnmount } = actions.EnrollmentRenewal;

  const dispatch = useDispatch();
  const { loading } = useSelector(selectors.getEnrollmentRenewalSlice);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    <PageStructure
      loading={loading}
      headerLeft={<Header title={t('enrollments.enrollmentRenewal')} />}
      mainContent={<>Enrollment Renewal</>}
      secondaryContentOffset={113}
      secondaryContent={<>Secondary content</>}
    />
  );
};

export default EnrollmentRenewal;
