/**
 * CurricularPlan scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { actions } from 'store/rootSlices';
import ErrorHandler from 'shared/errorHandler';

import { PayloadAction } from '@reduxjs/toolkit';

import { call, takeLatest, put, putResolve, select, all } from 'redux-saga/effects';
import { t } from 'app';

import API from 'api';
import * as selectors from './selectors';

function* onMountSaga() {
  try {
    yield put(actions.CurricularPlan.reset());

    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.curricularPlan_errorLoadingPage', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getUcOptions(action: PayloadAction<{ name: string; code: number }>) {
  const ucCode = action.payload.code;
  const optionName = action.payload.name;
  const registrationId = yield select(selectors.getRegistration);

  // reset
  yield put(actions.CurricularPlan.setUcOptions({ name: optionName, courseList: [] }));

  yield put(actions.CurricularPlan.setUcOptionsModal(true));

  yield put(
    actions.CurricularPlan.setLoadingStatus({ fieldName: 'loadingModal', fieldValue: true })
  );

  if (!registrationId) {
    return;
  }

  try {
    const ucOptionList = yield call(
      API.secVirtual.getStudentModuleOptions.call,
      ucCode,
      registrationId
    );

    if (ucOptionList.length > 0) {
      yield put(
        actions.CurricularPlan.setUcOptions({ name: optionName, courseList: ucOptionList })
      );
    }
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.curricularPlan_errorLoadingOptions', {
            textOnly: true,
          }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.CurricularPlan.setLoadingStatus({ fieldName: 'loadingModal', fieldValue: false })
    );
  }
}

function* getCurricularPlanSaga() {
  yield put(
    actions.CurricularPlan.setErrorStatus({
      fieldName: 'errLoadingSummary',
      fieldValue: false,
    })
  );

  const registrationId = yield select(selectors.getRegistration);

  try {
    yield put(actions.CurricularPlan.setLoadingStatus({ fieldName: 'loading', fieldValue: true }));

    if (!registrationId) {
      return;
    }

    //gets all the curricular plan info for the registrationId selected
    const [curricularPlanData, extraCurricularUcs, mobilityUcs] = yield all([
      call(API.secVirtual.getStudentCurricularPlan.call, registrationId),
      call(API.secVirtual.getStudentExtraCurricularUcs.call, registrationId),
      call(API.secVirtual.getStudentMobilityUcs.call, registrationId),
    ]);

    const tmp = {
      registrationId,
      summary: curricularPlanData.summary,
      grade: curricularPlanData.grade,
    };

    yield put(actions.CurricularPlan.addCurricularPlanSummary(tmp));

    //it can be null. A student might not have a curricular plan associated
    const curricularPlan = curricularPlanData.curricularPlan;
    const accreditedUcs = curricularPlanData.accreditedUcs;

    //the properties of this object that are null or undefined must be using the optional chaining operator to avoid breaking the screen
    const regCurricularPlan = {
      registrationId: registrationId,
      curricularPlan: {
        courseName: curricularPlan?.courseCode,
        courseCode: curricularPlan?.courseCode,
        ucs: curricularPlan?.ucs,
        subUcs: curricularPlan?.submodules,
        accreditedUcs: accreditedUcs,
        mobilityUcs: mobilityUcs,
        extraCurricularUcs: extraCurricularUcs,
        /* ...(curricularPlan ? {} : null),
        ...(accreditedUcs ? {} : null), */
      },
    };

    yield put(actions.CurricularPlan.addCurricularPlan(regCurricularPlan));

    yield put(actions.CurricularPlan.setLoadingStatus({ fieldName: 'loading', fieldValue: false }));
  } catch (e) {
    yield put(
      actions.CurricularPlan.setErrorStatus({
        fieldName: 'errLoadingSummary',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.curricularPlan_errorLoadingPage', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
  }
}

function* onUnmountSaga() {
  yield put(actions.CurricularPlan.reset());
}

export default function* watcherSignin() {
  yield takeLatest('CurricularPlan/onMount', onMountSaga);
  yield takeLatest('CurricularPlan/onUnmount', onUnmountSaga);
  yield takeLatest('CurricularPlan/getCurricularPlan', getCurricularPlanSaga);
  yield takeLatest('CurricularPlan/getUcOptions', getUcOptions);
}
