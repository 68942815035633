/**
 * SelectedClasses container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Progress } from 'semantic-ui-react';

import { t } from 'app';

import { Button } from '@paco_ua/pacoui';

import { usePrevious } from 'utils';

interface ISelectedClasses {
  title: string;
  icon: JSX.Element;
  selected: number;
  seriationFinished: boolean;
  placedAmount?: number;
  total: number;
  children: React.ReactNode;
}

const SelectedClasses: React.FC<ISelectedClasses> = ({
  title,
  icon,
  selected,
  seriationFinished,
  placedAmount,
  total,
  children,
}) => {
  const childrenWrapperRef = useRef<HTMLDivElement>(null);
  const prevSelected = usePrevious(selected);
  const progress = Math.round((selected * 100) / total);

  useEffect(() => {
    //every time new element showsUp scrollToTop
    if ((prevSelected || 0) < selected) {
      childrenWrapperRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [prevSelected, selected]);

  const handleOnClick = () => {
    if (childrenWrapperRef.current !== null) {
      childrenWrapperRef.current.scrollBy({ top: 220, behavior: 'smooth' });
    }
  };

  return (
    <Wrapper>
      <Title>
        {icon}
        {title}
      </Title>
      <InnerWrapper classesSelected={selected}>
        <CounterWrapper>
          <Counter>
            <CounterTitle>
              {!seriationFinished
                ? t('sgh.selected', { textOnly: true })
                : `${t('sgh.placedIn', { textOnly: true })}`}
              :
            </CounterTitle>
            <CounterAmount> {selected} </CounterAmount>
          </Counter>
          <Counter>
            <CounterTitle>{t('generic.total', { textOnly: true })}:</CounterTitle>
            <CounterAmount> {total} </CounterAmount>
          </Counter>
          {/*<
          TODO this count should come from api. Some users have more than 190 ucs and they can only select a few from those
          Counter>
            <CounterTitle>{`${t('generic.total', { textOnly: true })}: `}</CounterTitle>
            <CounterAmount>{total}</CounterAmount>
          </Counter>*/}
        </CounterWrapper>

        <ProgressBar progress percent={progress} active={total > selected} />

        <ChildrenWrapper ref={childrenWrapperRef} classesSelected={selected}>
          {children}
        </ChildrenWrapper>
      </InnerWrapper>
      <ScrollDownButtonWrapper classesSelected={selected}>
        <SeeMoreButton full primary onClick={() => handleOnClick()}>
          {t('generic.seeMore')}
        </SeeMoreButton>
      </ScrollDownButtonWrapper>
    </Wrapper>
  );
};

export default SelectedClasses;

const Wrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.primary};
  overflow: hidden;
`;

const Title = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  font-size: 18px;

  svg {
    margin: 0 18px;
  }
`;

const InnerWrapper = styled.div<{ classesSelected: number }>`
  position: relative;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 0;
`;

const CounterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 20px 20px 0 20px;
`;

const Counter = styled.div`
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
`;

const CounterTitle = styled.span`
  font-weight: 300;
`;

const CounterAmount = styled.span``;

const ChildrenWrapper = styled.div<{ classesSelected: number }>`
  overflow: auto;
  padding: 26px 20px 20px 20px;
  max-height: 446px;

  > div {
    width: 100%;
    margin-bottom: 27px;
  }

  // Hide scrollbar
  // Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;
`;

const ScrollDownButtonWrapper = styled.div<{ classesSelected: number }>`
  height: 40px;
  display: ${({ classesSelected }) => (classesSelected >= 2 ? `flex` : `none`)};
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
`;

const ProgressBar = styled(Progress)<{ percent: number }>`
  &&& {
    margin: 10px 20px 8px 20px;
    border-radius: 0;
    height: 24px;

    .bar {
      height: 24px;
      border-radius: 0;
      background-color: ${({ theme, percent }) =>
        percent < 45
          ? theme.colors.dangerRed
          : percent < 99
          ? theme.colors.decisionYellow
          : theme.colors.successGreen};
    }
  }
`;

const SeeMoreButton = styled(Button)`
  width: 100%;
`;
