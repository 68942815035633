/**
 * Logs scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */
 
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from './logic/selectors'
import { actions } from 'store/rootSlices'
import { usePostMessageMiddleware } from 'shared/components/ModuleCommunication'
import PageStructure from 'shared/containers/PageStructure';
import { CommunicationTypes } from 'shared/components/ModuleCommunication/communicationTypes'
import config from 'config'

const Logs = ({ t, i18n }) => {

  const { onMount, onUnmount } = actions.Logs
  const { signout, navigateTo } = actions.App
  const { showToaster } = actions.Toaster;
  const { user, currentLocale } = useSelector(selectors.getAppSlice);

   //iframe
   const [embeddedModuleLoaded, setEmbeddedModuleLoaded] = useState(false);
   const [embeddedModuleHeight, setEmbeddedModuleHeight] = useState(0);
   const embeddedModuleRef = useRef<HTMLIFrameElement>(null);

   //trying middleware
  const { addMessageListener, sendMessage, removeMessageListener } = usePostMessageMiddleware(
    embeddedModuleRef.current?.contentWindow, 
    config.IFRAME_LOGS
  );

  const loadModule = async () => {    
      setEmbeddedModuleLoaded(true);
  };

  useEffect(() => {
    const iframeElement = embeddedModuleRef.current;
    
    if (iframeElement) {
      iframeElement.addEventListener('load', loadModule);
    }
    
    return () => {
      if (iframeElement) {
        iframeElement.removeEventListener('load', loadModule);
      }
    };
  }, []);

  useEffect(()=> {
    sendMessage(CommunicationTypes.STORE_OTHER_ACTION, user)
  },[user, embeddedModuleLoaded])

  useEffect(()=> {
    sendMessage(CommunicationTypes.CHANGE_LANG, {lang: currentLocale})
  },[currentLocale, embeddedModuleLoaded])

  useEffect(()=> {

    //here we define the handler for this type (LOGOUT) of message
    addMessageListener(CommunicationTypes.STORE_NAVIGATE_ACTION, (payload) => {            
      dispatch(navigateTo(payload));
    });

    //here we define the handler for this type (STORE_TOASTER_ACTION) of message
    addMessageListener(CommunicationTypes.STORE_TOASTER_ACTION, (payload) => {
      dispatch(showToaster(payload));
    });

    //here we define the handler for this type (IFRAME_HEIGHT) of message
    addMessageListener(CommunicationTypes.IFRAME_HEIGHT, (payload) => {
      setEmbeddedModuleHeight(Number(payload.height) ?? 0);
    });

    //here we define the handler for this type (LOGOUT) of message
    addMessageListener(CommunicationTypes.LOGOUT, (payload) => {
      dispatch(signout());
    });
    
    sendMessage(CommunicationTypes.STORE_OTHER_ACTION, user)
    sendMessage(CommunicationTypes.CHANGE_LANG, {lang: currentLocale})

    return () => {
      removeMessageListener(CommunicationTypes.STORE_NAVIGATE_ACTION)
    }
  },[embeddedModuleLoaded])


  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  return (
    <PageStructure
      loading={!embeddedModuleLoaded}
      mainContent={
        <ContentWrapper>
            <iframe
              title='logs'
              src={config.IFRAME_LOGS}
              ref={embeddedModuleRef}
              scrolling="no"
              width="100%"
              height={embeddedModuleHeight}
              style={{ resize: 'inherit' }}
              frameBorder="0"
            ></iframe>
        </ContentWrapper>
      }
    />
  )
}

export default Logs

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // to compensate PageStructure default margin-top
  margin-top: -20px;
`;