/**
 * SuperUserTools Container
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

interface IProps extends WithTranslation {
  roles?: object;
  translationMode?: boolean;
}

const SuperUserTools: React.FC<IProps> = ({ i18n }) => {
  const { user } = useSelector(selectors.getAppSlice);
  const { impersonateUser, cleanImpersonateUser } = actions.App;

  const dispatch = useDispatch();

  const [openTools, setOpenTools] = useState(false);
  const [impersonate, setImpersonate] = useState('');

  return (
    <Wrapper openTools={openTools}>
      <CloseButton data-testid="superuser_button_open" onClick={() => setOpenTools(!openTools)}>
        <span>
          {user.impersonate && 'USER'} {!user.impersonate && 'SUPERUSER'}
        </span>
        <ImpersonatingWrapper>
          {user.impersonate && <span>({user.impersonate})</span>}
        </ImpersonatingWrapper>
        <Icon openTools={openTools}>{'>'}</Icon>
      </CloseButton>
      <Tools>
        <div>
          <span>Impersonate User</span>
          <div>
            <input
              data-testid="superuser_input_nmec"
              value={impersonate}
              onChange={({ target }) => {
                setImpersonate(target.value);
              }}
              placeholder="NMEC"
            />
            <button
              data-testid="superuser_button_confirm_nmec"
              onClick={() => {
                dispatch(impersonateUser(impersonate));
              }}
            >
              OK
            </button>
          </div>
        </div>

        <div>
          <span>Restore</span>
          <div>
            <button
              disabled={!user.impersonate}
              onClick={() => {
                dispatch(cleanImpersonateUser());
              }}
            >
              Clear
            </button>
          </div>
        </div>
      </Tools>
    </Wrapper>
  );
};

export default withTranslation()(SuperUserTools);

const Wrapper = styled.div<{ openTools: boolean }>`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: ${({ openTools }) => (openTools ? 'calc(100% - 330px)' : 'calc(100% - 100px)')};
  background-color: #0eb4bd;

  box-shadow: 0 5px 8px 0 rgba(34, 36, 38, 0.46);
  z-index: 9999999;
  align-items: center;
  transition: all 0.3s;

  span {
    color: #fff;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
  }

  input {
    width: 80px;
  }

  button {
    margin-left: 5px;
  }
`;

const ImpersonatingWrapper = styled.div`
  position: absolute;
  top: 24px;
  font-size:12px;
  font-weight
`;

const Icon = styled.div<{ openTools: boolean }>`
  transition: all 0.3s;
  transform: rotate(${({ openTools }) => (openTools ? '0deg' : '180deg')});
`;

const Tools = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;

  > div:not(:first-child) {
    margin-left: 10px;
  }
`;

const CloseButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  padding: 10px 16px;
  box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  transition: all 0.3s;
  width: 100px;

  :hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 10px 0 20px -5px rgba(0, 0, 0, 0.5);
  }
`;
