/**
 * ImprovementExamEnrollment scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';

import { actions } from 'store/rootSlices';

//import { useForm } from 'react-hook-form';
import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  //faCircle,
  faArrowRight,
  faCheck,
  //faTimes,
} from '@fortawesome/pro-regular-svg-icons';

import { Text, Button, Segment, Dropdown, Modal, Checkbox,CallToAction } from '@paco_ua/pacoui';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { IImprovementExamUc } from './types';

import ImprovementExamsTable from './components/ImprovementExamsTable';

const ImprovementExamEnrollment = ({ t }) => {
  const theme = useContext(ThemeContext);

  const {
    onMount,
    onUnmount,
    setEnrollImprovementExamId,
    setRequestImprovementExamId,
  } = actions.ImprovementExamEnrollment;

  const { getStudentSignups } = actions.ImprovementExamEnrollment;
  const dispatch = useDispatch();
  const user = useSelector(selectors.getUser);

  //const { register, handleSubmit } = useForm();

  const { loading, signups, enrollImprovementExamId, requestImprovementExamId } = useSelector(
    selectors.getImprovementExamEnrollmentSlice
  );

  const currentRegistrationId = useSelector(selectors.getRegistration);

  const [selectedUcData, setSelectedUcData] = useState({} as IImprovementExamUc | undefined);
  const [enrollCheckbox, setEnrollCheckbox] = useState(null as boolean | null);
  const [filterValues, setFilterValues] = useState({ semester: null, academicYear: null } as {
    semester: number | null;
    academicYear: string | null;
  });

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  useEffect(() => {
    if (signups) {
      let ucData;
      if (enrollImprovementExamId) {
        ucData = signups.data.find((uc) => uc.signupId === enrollImprovementExamId);
        setSelectedUcData(ucData);
      } else if (requestImprovementExamId !== null) {
        ucData = signups.data.find((uc) => uc.signupId === requestImprovementExamId);
        setSelectedUcData(ucData);
      }
    }
  }, [enrollImprovementExamId, requestImprovementExamId]);

  useEffect(() => {
    if (filterValues.academicYear !== null && filterValues.semester !== null) {
      dispatch(getStudentSignups(filterValues));
    }
  }, [filterValues]);

  const handleDropdownSelection = (registrationId) => {
    dispatch(
      getStudentSignups({
        semester: null,
        academicYear: null,
      })
    );

    // reset
    setFilterValues({
      semester: null,
      academicYear: null,
    });
    setSelectedUcData(undefined);
    setEnrollCheckbox(null);
  };

  const handleRequestImprovOnClose = () => {
    dispatch(setRequestImprovementExamId(null));
    setEnrollCheckbox(null);
  };

  // TODO: check if the enroll improvement Exam modal is the same as the modal for the request improvement
  const handleEnrollImprovOnClose = () => {
    dispatch(setEnrollImprovementExamId(null));
    setEnrollCheckbox(null);
  };

  // return (
  //   <PageStructure
  //     loading={loading}
  //     headerLeft={<Header title={t('enrollments.improvementExamEnrollment')} />}
  //     subHeader={
  //       <>
  //         <NoticeWrapper>
  //           <Segment padding={'16px 40px'}>
  //             <SegmentContent>
  //               <FontAwesomeIcon icon={faExclamationCircle} size={'2x'} color={'darkGrey'} />
  //               <NoticeContent>
  //                 <Text weight={'bold'} color={'darkGrey'}>
  //                   {t('enrollments.attention', { textOnly: true })}
  //                 </Text>
  //                 <Text weight={'regular'} color={'darkGrey'}>
  //                   {`${t('enrollments.noticeContent_part1', {
  //                     textOnly: true,
  //                   })} ${t('enrollments.noticeContent_link', {
  //                     textOnly: true,
  //                   })} ${t('enrollments.noticeContent_part2', { textOnly: true })}`}
  //                 </Text>
  //               </NoticeContent>
  //             </SegmentContent>
  //           </Segment>
  //         </NoticeWrapper>
  //         <RegistrationDropdownWrapper>
  //           <StudentRegistrationDropdown
  //             loading={loading}
  //             onChange={() => {
  //               handleDropdownSelection(currentRegistrationId);
  //             }}
  //             data-testid="curricularplan_dropdown_registration"
  //           />
  //         </RegistrationDropdownWrapper>
  //       </>
  //     }
  //     mainContent={
  //       <>
  //         <Segment>
  //           <Text size="large" weight={'regular'} color={theme.colors.plusDarkGrey}>
  //             {t('enrollments.improvementEnrollmentsTitle')}
  //           </Text>
  //           <FilterWrapper>
  //             <Text size="medium" weight={'bold'} color={theme.colors.plusDarkGrey}>
  //               {`${t('enrollments.academicYear', { textOnly: true })}:`}
  //             </Text>
  //             {signups && (
  //               <FilterDropdownWrapper>
  //                 <Dropdown
  //                   onChange={(e, data) => {
  //                     setFilterValues({
  //                       ...filterValues,
  //                       academicYear: data.value,
  //                     });
  //                   }}
  //                   icon={null}
  //                   borderless
  //                   options={signups.filters.academicYear.map((item) => {
  //                     return {
  //                       text: item,
  //                       value: item,
  //                     };
  //                   })}
  //                   selectionFontSize={'medium'}
  //                   /*open={results && results.length > 0 ? true : false}*/
  //                 />
  //               </FilterDropdownWrapper>
  //             )}

  //             <Text size="medium" weight={'bold'} color={'plusDarkGrey'}>
  //               {`${t('enrollments.semester', { textOnly: true })}:`}
  //             </Text>

  //             {signups && (
  //               <FilterDropdownWrapper>
  //                 <Dropdown
  //                   fitToContent
  //                   onChange={(e, data) => {
  //                     setFilterValues({
  //                       ...filterValues,
  //                       semester: data.value - 1,
  //                     });
  //                   }}
  //                   icon={null}
  //                   borderless
  //                   options={signups.filters.semester.map((item) => {
  //                     return {
  //                       text:
  //                         item === 0
  //                           ? t('enrollments.annual', { textOnly: true })
  //                           : `${numeral(item).format('o')} ${t('enrollments.semester', {
  //                               textOnly: true,
  //                             })}`,
  //                       value: item + 1,
  //                     };
  //                   })}
  //                   selectionFontSize={'medium'}
  //                   /*open={results && results.length > 0 ? true : false}*/
  //                 />
  //               </FilterDropdownWrapper>
  //             )}
  //           </FilterWrapper>
  //           <TableWrapper>
  //             <ImprovementExamsTable
  //               loading={loading}
  //               signups={signups}
  //               setEnrollImprovementExamId={setEnrollImprovementExamId}
  //               setRequestImprovementExamId={setRequestImprovementExamId}
  //             ></ImprovementExamsTable>
  //           </TableWrapper>
  //         </Segment>
  //       </>
  //     }
  //     secondaryContentOffset={0}
  //     secondaryContent={
  //       <>
  //         {enrollImprovementExamId && selectedUcData && Object.keys(selectedUcData).length > 0 && (
  //           <Modal
  //             open={enrollImprovementExamId ? true : false}
  //             type={'confirmation'}
  //             header={
  //               <ModalHeaderWrapper>
  //                 <Text color="blackLight" size="xLarge" fontWeight="500">
  //                   {t(`enrollments.improvementEnroll`, { textOnly: true })}
  //                 </Text>
  //               </ModalHeaderWrapper>
  //             }
  //             onClose={() => handleEnrollImprovOnClose()}
  //             data-testid="curricularplan_modal_options_view"
  //           >
  //             <ModalContentWrapper>
  //               <ModalUcTitle>
  //                 <Text color="plusDarkGrey" size="medium" fontWeight="500">
  //                   {selectedUcData.uc.ucCode}
  //                 </Text>
  //                 <Text color="primary" size="medium" fontWeight="500">
  //                   {selectedUcData.uc.ucName}
  //                 </Text>
  //               </ModalUcTitle>
  //               <ModalUcInfo>
  //                 <ModalUcSingleInfo>
  //                   <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                     {t(`enrollments.yearOfStudy`, { textOnly: true })}
  //                   </Text>
  //                   <Text color="plusDarkGrey" size="article" weight="regular">
  //                     {`${numeral(selectedUcData.uc.yearOfStudy).format('o')} ${t(
  //                       'enrollments.year',
  //                       {
  //                         textOnly: true,
  //                       }
  //                     )}`}
  //                   </Text>
  //                 </ModalUcSingleInfo>
  //                 <ModalUcSingleInfo>
  //                   <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                     {t(`enrollments.semester`, { textOnly: true })}
  //                   </Text>
  //                   <Text color="plusDarkGrey" size="article" weight="regular">
  //                     {`${numeral(selectedUcData.uc.semester).format('o')} ${t(
  //                       'enrollments.semester',
  //                       {
  //                         textOnly: true,
  //                       }
  //                     )}`}
  //                   </Text>
  //                 </ModalUcSingleInfo>
  //                 <ModalUcSingleInfo>
  //                   <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                     {t(`enrollments.ects`, { textOnly: true })}
  //                   </Text>
  //                   <Text color="plusDarkGrey" size="article" weight="regular">
  //                     {selectedUcData.uc.ects}
  //                   </Text>
  //                 </ModalUcSingleInfo>
  //                 <ModalUcSingleInfo>
  //                   <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                     {t(`enrollments.grade`, { textOnly: true })}
  //                   </Text>
  //                   <Text color="plusDarkGrey" size="article" weight="regular">
  //                     {selectedUcData.uc.grade}
  //                   </Text>
  //                 </ModalUcSingleInfo>
  //               </ModalUcInfo>
  //               <EnrollInfoTitle>
  //                 <Text color="plusDarkGrey" size="medium" fontWeight="500">
  //                   {t('enrollments.improvementEnroll_info', { textOnly: true })}
  //                 </Text>
  //               </EnrollInfoTitle>
  //               <ModalUcInfo>
  //                 <ModalUcSingleInfo>
  //                   <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                     {t(`enrollments.examDate`, { textOnly: true })}
  //                   </Text>
  //                   <Text color="plusDarkGrey" size="article" weight="regular">
  //                     {moment(selectedUcData.examTime).format('LLL')}
  //                   </Text>
  //                 </ModalUcSingleInfo>
  //                 <ModalUcSingleInfo>
  //                   <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                     {t(`enrollments.examRoom`, { textOnly: true })}
  //                   </Text>
  //                   <Text color="plusDarkGrey" size="article" weight="regular">
  //                     {selectedUcData.examLocation}
  //                   </Text>
  //                 </ModalUcSingleInfo>
  //               </ModalUcInfo>
  //               <ModalUcDeadline>
  //                 <Text color="plusDarkGrey" size="xSmall" weight="regular">
  //                   {t(`enrollments.enrollDeadline`, { textOnly: true })}
  //                 </Text>
  //                 <Text color="primary" size="article" fontWeight="500">
  //                   {moment(selectedUcData.deadline).format('LLL')}
  //                 </Text>
  //               </ModalUcDeadline>
  //               <EnrollSubmission>
  //                 <Text color="plusDarkGrey" size="medium" weight="regular">
  //                   {`${t(`enrollments.enrollSubmission`, { textOnly: true })} `}
  //                 </Text>
  //                 <Text color="dangerRed" size="medium" weight="regular">
  //                   {'*'}
  //                 </Text>
  //               </EnrollSubmission>
  //               <EnrollSubmissionInfo>
  //                 <Text color="plusDarkGrey" size="small" weight="regular">
  //                   {`${t(`enrollments.enrollSubmissionInfo`, { textOnly: true })}`}
  //                 </Text>
  //               </EnrollSubmissionInfo>
  //               <ModalSegmentWrapper>
  //                 <Segment
  //                   padding={'24px'}
  //                   backgroundColor={enrollCheckbox ? theme.colors.primary : undefined}
  //                 >
  //                   <ModalSegmentContent>
  //                     <ModalCheckboxWrapper>
  //                       <Checkbox
  //                         name="enrollConfirmation"
  //                         /*defaultChecked={true}*/
  //                         onChange={(event) => {
  //                           setEnrollCheckbox(event.currentTarget.checked);
  //                         }}
  //                       />
  //                     </ModalCheckboxWrapper>
  //                     <ModalSegmentText>
  //                       <Text
  //                         color={enrollCheckbox ? 'white' : 'plusDarkGrey'}
  //                         size="article"
  //                         fontWeight="500"
  //                         ellipsize
  //                       >
  //                         {`${t(`enrollments.enrollSubmission_text`, { textOnly: true })} ${
  //                           selectedUcData.uc.ucCode
  //                         } ${selectedUcData.uc.ucName}`}
  //                       </Text>
  //                       <Text
  //                         color={enrollCheckbox ? 'white' : 'darkGrey'}
  //                         size="xSmall"
  //                         weight="regular"
  //                       >
  //                         {t(`enrollments.enrollSubmission_subtext`, { textOnly: true })}
  //                       </Text>
  //                     </ModalSegmentText>
  //                   </ModalSegmentContent>
  //                 </Segment>
  //               </ModalSegmentWrapper>
  //               <ModalButtonWrapper>
  //                 <Button
  //                   data-testid={`personaldata_cancel_button`}
  //                   disabled={enrollCheckbox ? false : true}
  //                   primary
  //                   leftIcon={<FontAwesomeIcon size="lg" icon={faCheck} />}
  //                   onClick={() => {}}
  //                 >
  //                   {t(`enrollments.submitEnroll`, { textOnly: true })}
  //                 </Button>
  //               </ModalButtonWrapper>
  //             </ModalContentWrapper>
  //           </Modal>
  //         )}

  //         {requestImprovementExamId && selectedUcData && (
  //           <Modal
  //             open={requestImprovementExamId ? true : false}
  //             type={'confirmation'}
  //             header={
  //               <Text color="blackLight" size="xLarge" fontWeight="500">
  //                 {requestImprovementExamId}
  //               </Text>
  //             }
  //             onClose={() => handleRequestImprovOnClose()}
  //             data-testid="curricularplan_modal_options_view"
  //           >
  //             <>{requestImprovementExamId}</>
  //           </Modal>
  //         )}

  //         <SecondaryContentWrapper>
  //           <Text
  //             size="mediumSmall"
  //             transform={'uppercase'}
  //             weight={'bold'}
  //             color={theme.colors.plusDarkGrey}
  //           >
  //             {t('enrollments.enrollmentConditions', { textOnly: true })}
  //           </Text>
  //           <SecondaryContentText>
  //             <Text size="xSmall" weight={'regular'} color={'darkGrey'}>
  //               {t('enrollments.enrollmentConditions_par1', { textOnly: true })}
  //             </Text>
  //             <Text size="xSmall" weight={'regular'} color={'darkGrey'}>
  //               {t('enrollments.enrollmentConditions_par2', { textOnly: true })}
  //             </Text>
  //             <Text size="xSmall" weight={'regular'} color={'darkGrey'}>
  //               {t('enrollments.enrollmentConditions_par3', { textOnly: true })}
  //             </Text>

  //             {/*<Text size="xSmall" weight={'regular'} color={'darkGrey'}>
  //               {t('enrollments.enrollmentConditions_par4', { textOnly: true })}
  //             </Text>*/}
  //           </SecondaryContentText>
  //           <KnowMoreWrapper>
  //             <KnowMoreButton
  //               borderless
  //               onClick={() => {
  //                 // TODO
  //               }}
  //             >
  //               <Text size="small" weight={'bold'} color={'primary'}>
  //                 {t('enrollments.knowMore', { textOnly: true })}
  //               </Text>
  //               <FontAwesomeIcon icon={faArrowRight} size={'1x'} color={theme.colors.primary} />
  //             </KnowMoreButton>
  //           </KnowMoreWrapper>
  //         </SecondaryContentWrapper>
  //       </>
  //     }
  //   />
  // );

  return (
    <Wrapper>
      <CallToAction
        topBorder       
        footer={
          <Text color="darkGrey" size="article">
            {t('generic.iframeBanner')}
          </Text>
        }
        padding={'15px 38px'}
        noPointer
      />
       <iframe src={"https://paco.ua.pt/PACO2/secvirtual/melhoria/fichainscricao.asp?token=" + user.idToken + "&impe=" + user.impersonate} width="100%" height="800px" frameBorder="0" ></iframe>
    </Wrapper>
  );
};

export default ImprovementExamEnrollment;

const NoticeWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const NoticeContent = styled.div`
  display: flex;
  flex-direction: column;

  margin-left: 18px;
`;

const SegmentContent = styled.div`
  display: flex;
  flex-direction: row;
`;

const RegistrationDropdownWrapper = styled.div`
  margin-top: 30px;
`;

const FilterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FilterDropdownWrapper = styled.div`
  margin-left: 8px;
  margin-right: 24px;
`;

const TableWrapper = styled.div`
  margin-top: 30px;
`;

const SecondaryContentWrapper = styled.div`
  border-style: solid;
  border-width: 0px;
  border-top: 3px solid ${({ theme }) => theme.colors.grey};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  /*max-height: 480px;*/

  padding: 27px 24px 0px 24px;
`;

const SecondaryContentText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  div:not(:first-child) {
    margin-top: 10px;
  }

  div:nth-child(3) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const KnowMoreWrapper = styled.div`
  padding: 32px 0px;
`;

const KnowMoreButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModalUcTitle = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 16px;
  }
`;

const ModalUcInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
`;

const ModalUcSingleInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 56px;
`;

const EnrollInfoTitle = styled.div`
  margin-top: 40px;
`;

const ModalUcDeadline = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

const EnrollSubmission = styled.div`
  margin-top: 24px;
`;

const EnrollSubmissionInfo = styled.div`
  margin-top: 16px;
`;

const ModalSegmentWrapper = styled.div`
  margin-top: 16px;
`;

const ModalSegmentContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModalSegmentText = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const ModalCheckboxWrapper = styled.div`
  margin-right: 16px;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 18px;
  justify-content: center;
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  text-align: center;
`;
