import React, { useState, useEffect } from 'react';
import Modal from 'shared/components/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from '../logic/selectors';
import { Button, Checkbox, Dropdown, Text, Loading } from '@paco_ua/pacoui';
import styled from "styled-components";
import { columnNameToIndex, countColumnsInCSV, getColumnNames } from './utils';
import { t } from 'app';

const ButtonsDiv = styled.div`
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  display: flex;
`;

const CheckboxDiv = styled.div`
  display: flex;
  gap: 0.8rem;
  margin-top: 1rem;
  margin-left: 10%;
`;

const DropdownsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const DropdownsDivWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 10%;
`;

const ExcelWarningText = styled(Text)`
  font-size: 15px;
`;

const CSVModal = ({ changeModal, csvFile, onSubmit, onClose }) => {
  const loadingStatus = useSelector(selectors.selectLoadingStatus);
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [nmecColumnLabel, setnmecColumnLabel] = useState('A');
  const [gradeColumnLabel, setgradeColumnLabel] = useState('B');
  const [hasHeader, setHasHeader] = useState(false);
  const columnsList : string[] = [];
  const columnOptions = getColumnNames(26, columnsList);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const nmecColumnIndex = columnNameToIndex(nmecColumnLabel);
    const gradeColumnIndex = columnNameToIndex(gradeColumnLabel);
    onSubmit(nmecColumnIndex, gradeColumnIndex, hasHeader, csvFile);
    setLoadingLabel(true);
    //onClose();
  };

  useEffect(() => {
    if (!loadingStatus.loadingCSVData) {
      onClose();
      dispatch(actions.EditGrades.setLoadingStatus({ fieldName: 'loadingCSVData', fieldValue: !loadingStatus.loadingCSVData }));
    }
  }, [loadingStatus.loadingCSVData]);

  return (
    <Modal changeModal={changeModal}>
      <p>{t('pautas.selectDataFromFile')}:</p>
      <DropdownsDivWrapper>
        <DropdownsDiv>
          <Text>{t('pautas.studentNumberColumn')}: </Text>
          <Dropdown options={columnOptions}
          placeholder={nmecColumnLabel}
          onChange={(e, {value}) => setnmecColumnLabel(value.toUpperCase())}
          style={{width:"4rem"}}
        />
        </DropdownsDiv>
        <DropdownsDiv>
          <Text>{t('pautas.gradeColumn')}: </Text>
          <Dropdown options={columnOptions}
            placeholder={gradeColumnLabel}
            onChange={(e, {value}) => setgradeColumnLabel(value.toUpperCase())}
            style={{width:"4rem"}}
          />
        </DropdownsDiv>
      </DropdownsDivWrapper>
      <CheckboxDiv>
        <Text>{t('pautas.hasHeader')}</Text>
        <Checkbox checked={hasHeader} onChange={() => setHasHeader(!hasHeader)}></Checkbox>
      </CheckboxDiv>
      <CheckboxDiv> 
        <ExcelWarningText>* {t('pautas.excelDefaultPage')}</ExcelWarningText>
      </CheckboxDiv> 
      <ButtonsDiv>
        {loadingLabel && (
            <Loading size="3x" overlay/>
          )}
        <Button danger onClick={onClose}>
          {t('pautas.cancel', { textOnly: true })}
        </Button>
        <Button action onClick={handleSubmit}>
          {t('pautas.confirm', { textOnly: true })}
        </Button>
      </ButtonsDiv>
    </Modal>
  );
};

export default CSVModal;