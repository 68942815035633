/**
 * ApplicationsList scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';

import { AnimatedBackground, Table, Text, StatusLabel, SearchBox, Dropdown } from '@paco_ua/pacoui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

//import config from 'config'

const ApplicationsList = ({ t }) => {
  const theme = useContext(ThemeContext);

  const {
    onMount,
    onUnmount,
    setTypeFilter,
    setSearchFilter,
    getStudentApplications,
  } = actions.ApplicationsList;

  const { navigateTo } = actions.App;
  const dispatch = useDispatch();

  const { loadingStatus, errorStatus, applicationFilters, filterParams } = useSelector(
    selectors.getApplicationsListSlice
  );

  const studentApplications = useSelector(selectors.getStudentApplications);
  const registrationId = useSelector(selectors.getRegistrationId);

  const user = useSelector(selectors.getUser);

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    dispatch(onMount());
  }, [dispatch, onMount, user.impersonate]);

  useEffect(() => {
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onUnmount]);

  useEffect(() => {
    if (registrationId !== null && registrationId !== undefined) {
      dispatch(getStudentApplications(filterParams));
    }
  }, [dispatch, filterParams, registrationId]);

  useEffect(() => {
    if (initialLoading && !loadingStatus.loading) {
      setInitialLoading(false);
    }
  }, [loadingStatus.loading]);

  const APPLICATION_STATUS = {
    NC: {
      name: t('applications.listTableStatusUnknown', { textOnly: true }),
      color: theme.colors.regularGrey,
    },
    SBMT: {
      name: t('applications.listTableStatusDelivered', { textOnly: true }),
      color: theme.colors.successGreen,
    },
    ANL: {
      name: t('applications.listTableStatusAnalysis', { textOnly: true }),
      color: theme.colors.decisionYellow,
    },
    PROCSA: {
      name: t('applications.listTableStatusResponseSent', { textOnly: true }),
      color: theme.colors.successGreen,
    },
    CANC: {
      name: t('applications.listTableStatusCanceled', { textOnly: true }),
      color: theme.colors.dangerRed,
    },
  };

  const tableStructure = {
    header: {
      type: 'columnTitle',
      titles: [
        { text: t('applications.listTableReference', { textOnly: true }) },
        { text: t('applications.listTableDescription', { textOnly: true }) },
        {
          text: t('applications.listTableRequestDate', { textOnly: true }),
          style: {
            textAlign: 'center',
          },
        },
        {
          text: t('applications.listTableResponseDate', { textOnly: true }),
          style: {
            textAlign: 'center',
          },
        },
        {
          text: t('applications.listTableStatus', { textOnly: true }),
          style: {
            textAlign: 'center',
          },
        },
        { text: '' },
      ],
    },
    rows: studentApplications.map((application) => {
      return {
        rowProps: {
          navigatable: true,
          color: theme.colors.white,
          onClick: () => {
            dispatch(
              navigateTo({
                key: 'applicationdetails',
                params: {
                  registrationId,
                  id: application.id,
                },
              })
            );
          },
        },
        cells: [
          {
            content: (
              <Text size="article" color={theme.colors.plusDarkGrey} weight="medium">
                {application.reference}
              </Text>
            ),
          },
          {
            content: (
              <Text size="article" color={theme.colors.primary} weight="medium">
                {application.description}
              </Text>
            ),
          },
          {
            content: (
              <Text size="article" color={theme.colors.plusDarkGrey}>
                {moment(application.requestDate).format('DD/MM/YYYY')}
              </Text>
            ),
            cellProps: { textAlign: 'center' },
          },
          {
            content: (
              <Text size="article" color={theme.colors.plusDarkGrey}>
                {application.responseDate ? (
                  moment(application.responseDate).format('DD/MM/YYYY')
                ) : (
                  <FontAwesomeIcon icon={faEllipsisH} />
                )}
              </Text>
            ),
            cellProps: { textAlign: 'center' },
          },
          {
            content: (
              <StatusLabelWrapper>
                <StatusLabel
                  label={APPLICATION_STATUS[application.status].name}
                  background={APPLICATION_STATUS[application.status].color}
                />
              </StatusLabelWrapper>
            ),
            cellProps: { textAlign: 'center' },
          },
          {
            content: <FontAwesomeIcon icon={faChevronRight} />,
          },
        ],
      };
    }),
  };

  return (
    <PageStructure
      loading={loadingStatus.loading || errorStatus.errLoading}
      headerLeft={<Header title={t('applications.listTitle')} />}
      subHeader={<StudentRegistrationDropdown />}
      mainContent={
        <>
          {!loadingStatus.loading && !errorStatus.errLoadingList && applicationFilters.length > 0 && (
            <SearchAndFiltersWrapper>
              <SearchBox
                placeholder={t('applications.listSearchInputPlaceholder', { textOnly: true })}
                borderColor={theme.colors.plusDarkGrey}
                iconColor={theme.colors.plusDarkGrey}
                delay={500}
                onSearch={(value) => {
                  dispatch(setSearchFilter(value));
                }}
              />
              <Dropdown
                //defaultValue={selectedRegistration}
                placeholder={t('applications.listDropdownFilterPlaceholder', {
                  textOnly: true,
                })}
                canBeCleared
                options={applicationFilters}
                selectionWeight={'400'}
                selectionFontSize={'medium'}
                onChange={(e, { value }) => {
                  dispatch(setTypeFilter(value));
                }}
                onClear={() => {
                  dispatch(setTypeFilter(''));
                }}
              />
            </SearchAndFiltersWrapper>
          )}

          {(loadingStatus.loadingList || errorStatus.errLoadingList) && (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="20%" />
              <AnimatedBackground height={theme.sizes.large} width="50%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="50%" />
            </LoadingWrapper>
          )}

          {!loadingStatus.loadingList &&
            !errorStatus.errLoadingList &&
            tableStructure &&
            tableStructure.rows.length > 0 && (
              <TableWrapper>
                <Table structure={tableStructure} navigatable unstackable />
              </TableWrapper>
            )}

          {!loadingStatus.loadingList &&
            !errorStatus.errLoadingList &&
            tableStructure &&
            tableStructure.rows.length === 0 && (
              <MessageNoCertificates>
                <Icon>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Icon>
                <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                  {t('applications.noApplicationsFound', { textOnly: true })}
                </Text>
              </MessageNoCertificates>
            )}
        </>
      }
    />
  );
};

export default ApplicationsList;

const SearchAndFiltersWrapper = styled.div`
  width: 75%;
  display: flex;
  margin-bottom: 24px;

  > div {
    width: 50%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const StatusLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  margin-top: 32px;

  * {
    margin: 8px 0px;
  }
`;

const MessageNoCertificates = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const TableWrapper = styled.div`
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }
  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;
