// Util function to transform the column name into a column index
export function columnNameToIndex(columnName) {
  let index = 0;
  for (let i = 0; i < columnName.length; i++) {
    index *= 26;
    index += columnName.charCodeAt(i) - 64;
  }
  return index - 1;
}

// Util function to transform a given index to csv column name (ex. A, B, ..., AA, AB, ...)
export function indexToColumnName(index) {
  let columnName = '';
  let remainder;
  while (index >= 0) {
    remainder = index % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    index = Math.floor(index / 26) - 1;
  }
  return columnName;
}

export const countColumnsInCSV = (csvContent) => {
  const detectCSVSeparator = (row) => {
    const separators = [',', ';', '\t'];
    let maxCount = 0;
    let detectedSeparator = ',';

    separators.forEach((separator) => {
      const count = (row.match(new RegExp(separator, "g")) || []).length;
      if (count > maxCount) {
        maxCount = count;
        detectedSeparator = separator;
      }
    });

    return detectedSeparator;
  };

  const rows = csvContent.split('\n');
  const firstRow = rows.find(row => row.trim() !== '');

  if (!firstRow) {
    console.error('CSV is empty or incorrectly formatted');
    return 0;
  }

  const separator = detectCSVSeparator(firstRow);
  const columns = firstRow.split(separator);
  return columns.length;
};

export const getColumnNames = (numColumns, columnsList) => {
  
  for (var num = 0; num < numColumns; num++) {
    let columnName = indexToColumnName(num);
    columnsList.push(columnName);
  } 
  
  const columnOptions = columnsList.map(columnName => ({
    key: columnName,
    text: columnName,
    value: columnName,
  }));
  return columnOptions
}