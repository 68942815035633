/**
 * Footer selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getCurrentLocale = (state: RootState) => state.App.currentLocale;
