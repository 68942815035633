import axios from 'axios';
import Endpoints from 'api/endpoints';
import { IParams } from '../index';

import mockPayments from 'api/schemas/mockPayments';
import mockUnpaidDebits from 'api/schemas/mockUnpaidDebits';
import mockSecVirtual from 'api/schemas/mockSecVirtual';

import { IApiCall } from '../index';

const getTeacherGrades = (params: { period?: string }) => {
  return axios
    .get(Endpoints.pautas.professorGradesInfo, {
      params: {
        ...params,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const getGradesStats = () => {
  return axios
    .get(Endpoints.pautas.gradesStatistics)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}

const getGradeDetails = (codigoPauta) => {
  return axios
    .get(Endpoints.pautas.gradeDetails(codigoPauta))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const getGradeHistory = (codigoPauta) => {
  return axios
    .get(Endpoints.pautas.gradeHistoryInfo(codigoPauta))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const getDecryptedGrade = (codigoPauta) => {
  return axios
    .get(Endpoints.pautas.gradeDecryptedInfo(codigoPauta))
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const putIntermediateGrade = (codigoPauta, data) => {
  return axios
    .put(Endpoints.pautas.saveIntermediateGrade(codigoPauta), data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const putFinalGrade = (codigoPauta, data) => {
  return axios
    .put(Endpoints.pautas.submitGrade(codigoPauta), data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postGradeFile = (
  codigoPauta,
  uniqueIdentifierColumn,
  gradeColumn,
  hasHeader,
  sheetNumber,
  file
) => {
  const formData = new FormData();
  formData.append('file', file);

  const endpoint = Endpoints.pautas.uploadGradeFile(
    codigoPauta,
    uniqueIdentifierColumn,
    gradeColumn,
    hasHeader,
    sheetNumber
  );

  return axios
    .post(endpoint, formData, {
      headers: {
        'Content-type': 'multipart/form-data'
      }
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getGradePdf = (id, signed) => {
  return axios
    .get(Endpoints.pautas.gradePdf(id, signed), { responseType: 'arraybuffer' })
    .then((response) => {
      if (response.headers['content-type'] !== 'application/pdf') {
        throw new Error('Received non-PDF content');
      }

      const blob = new Blob([response.request.response], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(blob);
      return fileURL;
    })
    .catch((err) => {
      throw err;
    });
};

export default {
  getTeacherGrades: {
    call: getTeacherGrades,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  getGradesStats: {
    call: getGradesStats,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  getGradeDetails: {
    call: getGradeDetails,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  getGradeHistory: {
    call: getGradeHistory,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  getDecryptedGrade: {
    call: getDecryptedGrade,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  putIntermediateGrade: {
    call: putIntermediateGrade,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  putFinalGrade: {
    call: putFinalGrade,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  postGradeFile: {
    call: postGradeFile,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
  getGradePdf: {
    call: getGradePdf,
    scope: 'paco_2.0_assinatura_pautas',
  } as IApiCall,
};
