/**
 * FreeOptionsTable container  selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getFreeOptionsListSlice = (state: RootState) => state.FreeOptionsTable;
export const getUcs = (state: RootState) => state.Schedules.freeUcs;
export const getAppSlice = (state: RootState) => state.App;
