/**
 * GradesGuide scene
 *
 * @author Pedro Monteiro <pmapm@ua.pt>
 *
 */
import React, { useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { Text } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';

import * as selectors from './logic/selectors';

import MarkdownRenderer from 'shared/containers/MarkdownRenderer';
import Header from 'shared/containers/TitleHeader';

import { getCurrentRoute } from 'utils';

const GradesGuide = ({ t, id }) => {
  const { markdown, loadingMarkdown } = useSelector(selectors.getGradesGuideSlice);
  const { user } = useSelector(selectors.getAppSlice);

  const { onMount, onUnmount } = actions.GradesGuide;
  const { navigateTo } = actions.App;
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  useEffect(() => {
    dispatch(onMount(id));

    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  //markdown renderer respects already pagestructure
  return (
    <MarkdownRenderer
      pageStructure={{
        loading: loadingMarkdown,
        headerLeft: <Header backButton title={t('routes.guide')} />,
        subHeader: (
          <Text weight="medium" color="primary" size="large">
            {id ? t(`routes.${id}`) : t('routes.pautasSignature')}
          </Text>
        ),
      }}
      value={markdown?.value || ''}
      valueEn={markdown?.valueEn || ''}
      valueCh={markdown?.valueCh || ''}
    />
  );
};

export default GradesGuide;
