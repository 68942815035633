/**
 * SelectedPayments container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import moment from 'moment';

import { Grid } from 'semantic-ui-react';
import { Text, Segment, StatusLabel } from '@paco_ua/pacoui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';

import { DEBIT_STATUS } from '../../../containers/DebitsTable/utils';
import { ASSOCIATED_COSTS_TYPES } from '../../types';

const SelectedPayments = ({ selectedDebits }) => {
  const theme = useContext(ThemeContext);

  const DEBIT_SITUATION_COLOR = {
    [DEBIT_STATUS.UNPAID]: theme.colors.darkGrey,
    [DEBIT_STATUS.IN_DEBIT]: theme.colors.dangerRed,
    [DEBIT_STATUS.AWAITING_PAYMENT]: theme.colors.darkGrey,
    [DEBIT_STATUS.IN_DEBIT_AWAITING_PAYMENT]: theme.colors.dangerRed,
  };

  const DEBIT_SITUATION = {
    unpaid: 'unpaid',
    inDebit: 'inDebit',
    awaitingPayment: 'unpaid',
    inDebitAwaitingPayment: 'inDebit',
  };

  const getDebitTitleColor = (debitType: string) => {
    return debitType === DEBIT_SITUATION.inDebit ||
      debitType === DEBIT_SITUATION.inDebitAwaitingPayment
      ? 'dangerRed'
      : 'plusDarkGrey';
  };

  return (
    <Wrapper>
      {selectedDebits.map((debit, index) => {
        return (
          <DebitSegmentWrapper key={`debitId-${index}`}>
            <Segment borderColor={theme.colors.softRegularGrey} padding="14px 24px">
              <PaymentsContainer>
                <Grid width={15}>
                  <Grid.Column width={8}>
                    <WrapperColumn>
                      <Text size="small" weight="medium" color="plusDarkGrey">
                        {debit.debitType}
                      </Text>
                      <TuitionText
                        size="mediumSmall"
                        weight="medium"
                        color={getDebitTitleColor(debit.debitStatus)}
                      >
                        {debit.description}
                      </TuitionText>
                    </WrapperColumn>
                  </Grid.Column>

                  <Grid.Column width={2}>
                    <WrapperColumn>
                      <Text size="small" weight="medium" color="plusDarkGrey">
                        {t('payments.value', { textOnly: true })}
                      </Text>
                      <TuitionPriceText size="article" weight="medium" color="plusDarkGrey">
                        {`${debit.value.toFixed(2)} ${t('generic.euros', { textOnly: true })}`}
                      </TuitionPriceText>
                    </WrapperColumn>
                  </Grid.Column>

                  <Grid.Column width={4}>
                    <WrapperColumn>
                      <Text size="small" weight="medium" color="plusDarkGrey">
                        {t('payments.debitsTableDueDate', { textOnly: true })}
                      </Text>
                      {debit.dueDate ? (
                        <TuitionPriceText size="article" weight="medium" color="plusDarkGrey">
                          {moment(debit.dueDate).format('DD-MM-YYYY')}
                        </TuitionPriceText>
                      ) : (
                        <EllipsisWrapper>
                          <FontAwesomeIcon icon={faEllipsisH} />
                        </EllipsisWrapper>
                      )}
                    </WrapperColumn>
                  </Grid.Column>

                  <Grid.Column width={2}>
                    <WrapperColumn marginTop={'4px'}>
                      <Text size="small" weight="medium" color="plusDarkGrey">
                        {t('payments.debitsTablePaymentStatus', { textOnly: true })}
                      </Text>
                      <StatusLabel
                        label={t(`payments.${DEBIT_SITUATION[debit.debitStatus]}`, {
                          textOnly: true,
                        })}
                        background={DEBIT_SITUATION_COLOR[debit.debitStatus]}
                      />
                    </WrapperColumn>
                  </Grid.Column>
                </Grid>
              </PaymentsContainer>
            </Segment>
            {debit.associatedCosts &&
              debit.associatedCosts.length > 0 &&
              debit.associatedCosts.map((associatedCost, index) => {
                return (
                  <Segment
                    key={`associated_cost-${index}`}
                    borderColor={'#DEDEDE'}
                    borderTopColor={theme.colors.white}
                    padding="14px 24px"
                  >
                    <PaymentsContainer>
                      <Grid width={15}>
                        <Grid.Column width={8}>
                          <WrapperColumn
                            marginTop={associatedCost.type === 'mailCost' ? '0px' : undefined}
                          >
                            {ASSOCIATED_COSTS_TYPES[associatedCost.type] ? 
                            <Text size="small" weight="medium" color="plusDarkGrey">
                              {t(`payments.${ASSOCIATED_COSTS_TYPES[associatedCost.type].name}`, {
                                textOnly: true,
                              })}
                            </Text> : 
                            <Text>
                              {associatedCost.type}
                            </Text>}
                            {ASSOCIATED_COSTS_TYPES[associatedCost.type] &&
                              <Text size="xSmall" weight="regular" color="plusDarkGrey">
                                {`${t(
                                  `payments.${ASSOCIATED_COSTS_TYPES[associatedCost.type].unit}`,
                                  {
                                    textOnly: true,
                                    count: Number(associatedCost.copies),
                                  }
                                )}`}
                              </Text>
                            }
                          </WrapperColumn>
                        </Grid.Column>

                        <Grid.Column width={8}>
                          <WrapperColumnFeePrice>
                            <Text size="article" weight="medium" color="plusDarkGrey">
                              {`${associatedCost.value.toFixed(2)} ${t('generic.euros', {
                                textOnly: true,
                              })}`}
                            </Text>
                          </WrapperColumnFeePrice>
                        </Grid.Column>
                      </Grid>
                    </PaymentsContainer>
                  </Segment>
                );
              })}
          </DebitSegmentWrapper>
        );
      })}
    </Wrapper>
  );
};

export default SelectedPayments;

const Wrapper = styled.div``;

const DebitSegmentWrapper = styled.div`
  margin-bottom: 12px;
`;

const PaymentsContainer = styled.div`
  display: flex;

  &&& .ui.grid {
    margin: 0px !important;
    width: 100%;
  }

  &&& .ui.grid > .column:not(.row) {
    padding: 0px !important;
  }
`;

const WrapperColumnFeePrice = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const WrapperColumn = styled.div<{ marginTop?: string; maxWidth?: string }>`
  display: flex;
  flex-direction: column;

  > div:last-child {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '8px')};
  }
`;

const TuitionText = styled(Text)`
  overflow: hidden;
  padding-right: 42px;
`;

const TuitionPriceText = styled(Text)``;

const EllipsisWrapper = styled.div`
  margin-top: 8px;
`;
