import React from "react";
import styled from "styled-components";
// import 'animate.css';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 31;
  z-index: 1102;
`;

const ModalWrapper = styled.div`
  background-color: #fff;
  width: 35%;
  height: auto;
  max-height: 98vh;
  padding: 1rem;
  z-index: 1103;
  position: fixed;
  top: 0;
  left: 0;
  margin: 1% 32.5% 0 32.5%;
  overflow-x: hidden;
  overflow-y: auto;
`;

interface ModalProps {
  children: React.ReactNode;
  changeModal: (...args: any[]) => void;
}


const Modal: React.FC<ModalProps> = (props) => {

  const hideModal = () => {
    props.changeModal();
  }

  return (
    <>
      <Backdrop className="animate__animated animate__fadeIn animate__fast" onClick={hideModal}></Backdrop>
      <ModalWrapper className="animate__animated animate__fadeIn animate__fast">{props.children}</ModalWrapper>
    </>
  )
}


export default Modal;