/**
 * CurricularPlan scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import React from 'react';

import { Theme as theme } from '@paco_ua/pacoui';

import {
  ICourseUC,
  ITableRawData,
  ITabItem,
  IColumnItem,
  Dictionary,
  ICurricularPlan,
  ICourseFields,
  ICourseTypes,
  GRADE_TYPE,
} from './types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import LabeledCell from './components/LabeledCell';

import numeral from 'numeral';

// same keys as the ones received from the endpoint
export const COURSE_FIELD_KEY: ICourseFields = {
  ACADEMIC_YEAR: 'academicYear',
  CREDITS: 'credits',
  ECTS: 'ects',
  GRADE: 'grade',
  INSTITUTION: 'institutionName',
  PROGRAMME: 'programName',
  SEMESTER: 'semester',
  TYPE: 'type',
  UC_CODE: 'ucCode',
  UC_NAME: 'ucName',
  YEAR_STUDY: 'yearOfStudy',
  UC_DETAILS: 'ucDetails', // for the column containing the right point arrow
};

export const COURSE_TYPE_KEY: ICourseTypes = {
  FIRST_YEAR: '1',
  SECOND_YEAR: '2',
  THIRD_YEAR: '3',
  FOURTH_YEAR: '4',
  FIFTH_YEAR: '5',
  SIXTH_YEAR: '6',
  EVERY_COURSE_UCS: 'everyCourseUc',
  EXTRA_CURRICULAR_UCS: 'extraCurricularUcs',
  MOBILITY_UCS: 'mobilityUcs',
  ACCREDITED_UCS: 'accreditedUcs',
};

export const findTabInList = (courseTypeTabs: ITabItem[], tabKey: string) => {
  const item = courseTypeTabs.find((tab: ITabItem) => tab.key === tabKey);

  if (item !== undefined) {
    return item.value;
  } else {
    return '';
  }
};

const CENTERED_TABLE_TITLES: string[] = [
  COURSE_FIELD_KEY.UC_CODE,
  COURSE_FIELD_KEY.CREDITS,
  COURSE_FIELD_KEY.ECTS,
  COURSE_FIELD_KEY.GRADE,
  COURSE_FIELD_KEY.ACADEMIC_YEAR,
  COURSE_FIELD_KEY.YEAR_STUDY,
  COURSE_FIELD_KEY.TYPE,
];

const UC_TYPE = {
  OPTION: 2 as number,
  MODULE: 5 as number,
};

const getOnClickRow = (uc, hasDetails, dispatch, navigateTo, currentRegistration) => {
  if (hasDetails) {
    return {
      onClick: () => {
        if (uc.hasOneSignup) {
          dispatch(
            navigateTo({
              key: 'ucDetails',
              params: {
                ucCode: uc.selectedOption ? uc.selectedOption.ucCode : uc.ucCode, // if uc is an option, otherwise
                regid: currentRegistration,
              },
            })
          );
        }
      },
      navigatable: uc.hasOneSignup,
    };
  }

  return {};
};

export const getCurricularPlanTableFormat = (
  data: ITableRawData,
  columnTitles: IColumnItem[],
  selectedTabKey: string,
  currentLocale: string,
  dispatch,
  navigateTo,
  currentRegistration,
  t
) => {
  const header = {
    type: 'columnTitle' as string,
    titles: columnTitles.map((title) => {
      if (CENTERED_TABLE_TITLES.indexOf(title.key) > -1) {
        return {
          text: title.value,
          style: {
            textAlign: 'center',
            fontWeight: 400,
            wordBreak: 'keep-all',
            wordWrap: 'normal',
          },
        };
      }

      return {
        text: title.value,
        style: { fontWeight: 400 },
      };
    }) as any,
  };

  let tableRows;
  let isTabNum = /^\d+$/.test(selectedTabKey);
  let tmpColumnTitles = [...columnTitles];

  // add empty column, in order to acommodate for the right pointing arrow
  header.titles.push({ text: '' });

  if (isTabNum || selectedTabKey === COURSE_TYPE_KEY.EVERY_COURSE_UCS) {
    // courseColumnsTitlesList
    let tableRowsCellArray = [] as any;

    tableRows = data.ucs.map((uc) => {
      return {
        rowProps: {
          color: theme.colors.white,
          ...getOnClickRow(
            uc,
            uc.ucType !== UC_TYPE.MODULE &&
              (uc.ucType !== UC_TYPE.OPTION ||
                (uc.ucType === UC_TYPE.OPTION && uc.selectedOption !== null)),
            dispatch,
            navigateTo,
            currentRegistration
          ),
          navigatable: true,
        },
        cells: tableRowsCellArray.concat(
          ...tmpColumnTitles.map((title) => {
            let content = uc[title.key];
            if (content === null && title.key !== COURSE_FIELD_KEY.GRADE) {
              content = <FontAwesomeIcon icon={faEllipsisH} />;
            } else if (title.key === COURSE_FIELD_KEY.UC_CODE) {
              if (uc.ucType === UC_TYPE.OPTION) {
                // it's an option

                if (uc.selectedOption) {
                  // option with grade
                  content = uc.selectedOption;
                  return [createTableCell(content.ucCode, { textAlign: 'center', width: 2 })];
                } else {
                  // option without grade
                  return [createTableCell(uc.ucCode, { textAlign: 'center', width: 2 })];
                }
              } else {
                return [createTableCell(content, { textAlign: 'center', width: 2 })];
              }
            } else if (title.key === COURSE_FIELD_KEY.UC_NAME) {
              if (uc.ucType === UC_TYPE.OPTION) {
                // it's an option
                let text: any;
                let label: any;

                if (uc.selectedOption) {
                  // option with grade
                  content = uc.selectedOption;
                  text = {
                    text: content.ucName,
                    props: {
                      color: theme.colors.primary,
                    },
                  };
                  label = {
                    text: `${uc.ucCode} - ${uc.ucName}`,
                    props: {
                      color: theme.colors.plusDarkGrey,
                    },
                  };
                } else {
                  // option without grade
                  text = {
                    text: content,
                    props: {
                      color: theme.colors.plusDarkGrey,
                    },
                  };
                  label = {
                    text: t('curricularPlan.viewOptionsName', { textOnly: true }),
                    props: {
                      color: theme.colors.plusDarkGrey,
                    },
                    isLink: true,
                    ucOptionCode: uc.ucCode,
                  };
                }

                return [
                  createLabeledCell(text, label, {
                    width: selectedTabKey === COURSE_TYPE_KEY.EVERY_COURSE_UCS ? 5 : 6,
                  }),
                ];
              } else if (uc.ucType === UC_TYPE.MODULE) {
                // module
                return [
                  createTableCell(content, {
                    width: selectedTabKey === COURSE_TYPE_KEY.EVERY_COURSE_UCS ? 5 : 6,
                  }),
                ];
              } else if (uc.mobilityInstitutionName !== null) {
                // mobility uc
                let text = {
                  text: content,
                  props: {
                    color: theme.colors.plusDarkGrey,
                  },
                };
                let label = {
                  text: uc.mobilityInstitutionName,
                  props: {
                    color: theme.colors.primary,
                  },
                };

                return [
                  createLabeledCell(text, label, {
                    width: selectedTabKey === COURSE_TYPE_KEY.EVERY_COURSE_UCS ? 5 : 6,
                  }),
                ];
              } else {
                return [
                  createTableCell(content, {
                    color: theme.colors.primary,
                    weight: 500,
                    width: selectedTabKey === COURSE_TYPE_KEY.EVERY_COURSE_UCS ? 5 : 6,
                  }),
                ];
              }
            } else if (title.key === COURSE_FIELD_KEY.YEAR_STUDY) {
              return [
                createTableCell(
                  `${numeral(Math.floor(content)).format('o')} ${t('curricularPlan.yearName', {
                    textOnly: true,
                  })}`,
                  {
                    textAlign: 'center',
                  }
                ),
              ];
            } else if (title.key === COURSE_FIELD_KEY.ECTS) {
              return [
                createTableCell(
                  parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 }),
                  { textAlign: 'center' }
                ),
              ];
            } else if (title.key === COURSE_FIELD_KEY.CREDITS) {
              return [
                createTableCell(
                  parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 }),
                  { textAlign: 'center' }
                ),
              ];
            } else if (title.key === COURSE_FIELD_KEY.SEMESTER) {
              let label: string;
              if (content > 0 && content <= 2) {
                label = t('curricularPlan.semesterName', { textOnly: true });
                return [
                  createTableCell(`${numeral(content).format('o')} ${label}`, {
                    style: { whiteSpace: 'nowrap' },
                  }),
                ];
              } else {
                label = t('curricularPlan.anualName', { textOnly: true });
                return [createTableCell(label, { style: { whiteSpace: 'nowrap' } })];
              }
            } else if (title.key === COURSE_FIELD_KEY.GRADE) {
              if (uc.ucType !== UC_TYPE.MODULE) {
                return [
                  content === null
                    ? createTableCell(<FontAwesomeIcon icon={faEllipsisH} />, {
                        textAlign: 'center',
                      })
                    : createTableCell(content, { textAlign: 'center' }),
                  createTableCell(
                    uc.hasOneSignup ? <FontAwesomeIcon icon={faChevronRight} /> : '',
                    {
                      textAlign: 'center',
                    }
                  ),
                ];
              } else {
                return [
                  content === null
                    ? createTableCell(<FontAwesomeIcon icon={faEllipsisH} />, {
                        textAlign: 'center',
                      })
                    : createTableCell(content, { textAlign: 'center', colSpan: 1 }),
                ];
              }
            }

            return [createTableCell(content, { textAlign: 'center' })];
          })
        ),
        collapsableOpen: true,

        collapsableRows: createFormattedSubucsRows(
          data.subUcs,
          uc.ucCode,
          tmpColumnTitles,
          currentLocale,
          dispatch,
          navigateTo,
          currentRegistration,
          t
        ),
      };
    });
  } else if (selectedTabKey === 'extraCurricularUcs') {
    let tableRowsCellArray = [] as any;

    tableRows = data.extraCurricularUcs.map((uc) => {
      return {
        rowProps: {
          color: theme.colors.white,
          onClick: () => {
            if (uc.hasOneSignup) {
              dispatch(
                navigateTo({
                  key: 'ucDetails',
                  params: { ucCode: uc.ucCode, regid: currentRegistration },
                })
              );
            }
          },
          navigatable: uc.hasOneSignup,
        },
        cells: tableRowsCellArray.concat(
          ...tmpColumnTitles.map((title) => {
            let content = uc[title.key];
            if (title.key === COURSE_FIELD_KEY.UC_CODE) {
              return [createTableCell(content, { textAlign: 'center', width: 2 })];
            } else if (title.key === COURSE_FIELD_KEY.UC_NAME) {
              const text = {
                text: content,
                props: {
                  color: 'primary',
                },
              };

              const label = {
                text: uc['department'],
                props: {
                  color: 'plusDarkGrey',
                },
              };

              return [createLabeledCell(text, label, { width: 6 })];
            } else if (
              title.key === COURSE_FIELD_KEY.ECTS ||
              title.key === COURSE_FIELD_KEY.CREDITS
            ) {
              return [
                createTableCell(
                  parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 }),
                  { textAlign: 'center' }
                ),
              ];
            } else if (title.key === COURSE_FIELD_KEY.GRADE) {
              return [
                content === null
                  ? createTableCell(<FontAwesomeIcon icon={faEllipsisH} />, {
                      textAlign: 'center',
                    })
                  : createTableCell(Math.floor(content), { textAlign: 'center' }),
                createTableCell(uc.hasOneSignup ? <FontAwesomeIcon icon={faChevronRight} /> : '', {
                  textAlign: 'center',
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.YEAR_STUDY) {
              return [
                createTableCell(uc[COURSE_FIELD_KEY.ACADEMIC_YEAR], {
                  textAlign: 'center',
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.SEMESTER) {
              let label: string;

              if (content > 0 && content <= 2) {
                label = t('curricularPlan.semesterName', { textOnly: true });
                return createTableCell(`${numeral(content).format('o')} ${label}`, {
                  style: { whiteSpace: 'nowrap' },
                });
              } else {
                label = t('curricularPlan.anualName', { textOnly: true });
                return [
                  createTableCell(label, {
                    textAlign: 'center',
                    style: { whiteSpace: 'nowrap' },
                  }),
                ];
              }
            }
            return [createTableCell(content, { textAlign: 'center' })];
          })
        ),
      };
    });
  } else if (selectedTabKey === 'mobilityUcs') {
    // mobilityColumnTitlesList
    let tableRowsCellArray = [] as any;

    tableRows = data.mobilityUcs.map((uc) => {
      return {
        rowProps: { color: theme.colors.white },
        cells: tableRowsCellArray.concat(
          ...columnTitles.map((title) => {
            let content = uc[title.key];
            if (content === null) {
              content = <FontAwesomeIcon icon={faEllipsisH} />;
            } else if (title.key === COURSE_FIELD_KEY.UC_NAME) {
              return [
                createTableCell(content, {
                  color: theme.colors.primary,
                  weight: 500,
                  width: 6,
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.PROGRAMME) {
              return [
                createTableCell(content, {
                  style: { textAlign: 'center' },
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.INSTITUTION) {
              return [
                createTableCell(content),
                createTableCell('', {
                  textAlign: 'center',
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.GRADE) {
              return [createTableCell(Math.floor(content), { textAlign: 'center' })];
            } else if (title.key === COURSE_FIELD_KEY.SEMESTER) {
              let label: string;

              if (content > 0 && content <= 2) {
                label = t('curricularPlan.semesterName', { textOnly: true });
                return [createTableCell(`${numeral(content).format('o')} ${label}`)];
              } else {
                label = t('curricularPlan.anualName', { textOnly: true });
                return [createTableCell(label, { textAlign: 'center' })];
              }
            }
            return [createTableCell(content, { textAlign: 'center' })];
          })
        ),
      };
    });
  } else if (selectedTabKey === 'accreditedUcs') {
    // accreditedColumnTitlesList
    let tableRowsCellArray = [] as any;

    tableRows = data.accreditedUcs.map((uc) => {
      return {
        rowProps: { color: theme.colors.white },
        cells: tableRowsCellArray.concat(
          ...columnTitles.map((title) => {
            let content = uc[title.key];
            if (title.key === COURSE_FIELD_KEY.UC_CODE) {
              return [createTableCell(content, { textAlign: 'center', width: 2 })];
            } else if (title.key === COURSE_FIELD_KEY.UC_NAME) {
              return [
                createTableCell(content, {
                  color: theme.colors.primary,
                  weight: 500,
                  width: 6,
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.TYPE) {
              return [
                createTableCell(
                  t(`curricularPlan.accrediteUcsType_${content}`, { textOnly: true }),
                  {
                    //textAlign: 'center',
                    style: {
                      // whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '200px',
                    },
                  }
                ),
                createTableCell('', {
                  textAlign: 'center',
                }),
              ];
            } else if (title.key === COURSE_FIELD_KEY.INSTITUTION) {
              return [createTableCell(content)];
            } else if (title.key === COURSE_FIELD_KEY.SEMESTER) {
              let label: string;

              if (content > 0 && content <= 2) {
                label = t('curricularPlan.semesterName', { textOnly: true });
                return [createTableCell(`${numeral(content).format('o')} ${label}`)];
              } else {
                label = t('curricularPlan.anualName', { textOnly: true });
                return [createTableCell(label, { textAlign: 'center' })];
              }
            }
            return [createTableCell(content, { textAlign: 'center' })];
          })
        ),
      };
    });
  }

  const structure = {
    header: header,
    rows: tableRows,
  };

  return structure;
};

/*const addEmptyCellToRows = (tableRows) => {
  let hasCollapsableRows = false;

  // first check if any row has collapsable rows
  tableRows.forEach((element) => {
    if (element.collapsableRows) {
      hasCollapsableRows = true;
      return;
    }
  });

  if (hasCollapsableRows) {
    tableRows.forEach((element) => {
      if (!element.collapsableRows) {
        element.cells.push(createTableCell(''));
      } else {
        element.collapsableRows.forEach((collapsedElement) => {
          collapsedElement.cells.push(createTableCell(''));
        });
      }
    });
  }

  return tableRows;
};*/

const createFormattedSubucsRows = (
  subucs: Dictionary<ICourseUC[]>,
  ucCode: number,
  columnsTitles: IColumnItem[],
  currentLocale: string,
  dispatch,
  navigateTo,
  currentRegistration,
  t
) => {
  if (
    subucs === undefined ||
    subucs === null ||
    subucs[ucCode] === undefined ||
    subucs[ucCode] === null
  )
    return null;

  let moduleSubUcs = subucs[ucCode];

  let subucsRowsCellArray = [] as any;

  let collapsableRows = moduleSubUcs.map((subuc) => {
    return {
      rowProps: {
        //color: theme.colors.dangerRed,
        navigatable: true,
        onClick: () => {
          dispatch(
            navigateTo({
              key: 'ucDetails',
              params: { ucCode: subuc.ucCode, regid: currentRegistration },
            })
          );
        },
      },
      cells: subucsRowsCellArray.concat(
        ...columnsTitles.map((title) => {
          let content = subuc[title.key];

          if (content === null && title.key !== COURSE_FIELD_KEY.GRADE) {
            content = <FontAwesomeIcon icon={faEllipsisH} />;
          } else if (title.key === COURSE_FIELD_KEY.UC_CODE) {
            return [createTableCell(content, { textAlign: 'right', width: 2 })];
          } else if (title.key === COURSE_FIELD_KEY.UC_NAME) {
            if (subuc.ucType === UC_TYPE.OPTION) {
              // it's an option
              let text: any;
              let label: any;
              if (subuc['selectedOption']) {
                // option with grade
                content = subuc['selectedOption'];

                text = {
                  text: content.ucName,
                  props: {
                    color: theme.colors.primary,
                  },
                };
                label = {
                  text: `${subuc.ucCode} ${subuc.ucName}`,
                  props: {
                    color: theme.colors.plusDarkGrey,
                  },
                };
              } else {
                // option without grade
                text = {
                  text: content,
                  props: {
                    color: theme.colors.primary,
                  },
                };
                label = {
                  text: t('curricularPlan.viewOptionsName', { textOnly: true }),
                  props: {
                    color: theme.colors.plusDarkGrey,
                  },
                  isLink: true,
                  //ucOptionCode: subuc.ucCode,
                };
              }

              return [createLabeledCell(text, label)];
            } else {
              return [
                createTableCell(content, {
                  color: theme.colors.primary,
                  weight: 500,
                }),
              ];
            }
          } else if (title.key === COURSE_FIELD_KEY.YEAR_STUDY) {
            return [
              createTableCell(
                `${numeral(Math.floor(content)).format('o')} ${t('curricularPlan.yearName', {
                  textOnly: true,
                })}`,
                {
                  textAlign: 'center',
                }
              ),
            ];
          } else if (title.key === COURSE_FIELD_KEY.ECTS) {
            return [
              createTableCell(
                parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 }),
                {
                  textAlign: 'center',
                }
              ),
            ];
          } else if (title.key === COURSE_FIELD_KEY.CREDITS) {
            return [
              createTableCell(
                parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 })
              ),
            ];
          } else if (title.key === COURSE_FIELD_KEY.SEMESTER) {
            let label: string;

            if (content > 0 && content <= 2) {
              label = t('curricularPlan.semesterName', { textOnly: true });
              return [createTableCell(`${numeral(content).format('o')} ${label}`)];
            } else {
              label = t('curricularPlan.anualName', { textOnly: true });
              return [createTableCell(label, { textAlign: 'center' })];
            }
          } else if (title.key === COURSE_FIELD_KEY.GRADE) {
            return [
              content === null
                ? createTableCell(<FontAwesomeIcon icon={faEllipsisH} />, {
                    textAlign: 'center',
                  })
                : createTableCell(content, { textAlign: 'center' }),
              createTableCell(content ? <FontAwesomeIcon icon={faChevronRight} /> : '', {
                textAlign: 'center',
                colSpan: 2,
              }),
            ];
          }

          return [createTableCell(content, { textAlign: 'center' })];
        })
      ),
    };
  });

  return collapsableRows;
};

const createTableCell = (content: string | number | JSX.Element, cellProps?: any) => {
  if (cellProps) {
    return {
      content: content,
      cellProps: {
        ...cellProps,
      },
    };
  } else {
    return {
      content: content,
    };
  }
};

const createLabeledCell = (content: any, label: any, cellProps?: {}) => {
  return {
    content: <LabeledCell content={content} label={label} />,
    cellProps: cellProps,
  };
};

export const getTableTabs = (curricularPlan: ICurricularPlan) => {
  let visibleTabs = [] as string[];

  Object.keys(curricularPlan.ucs ?? {}).forEach((tabYear) => {
    visibleTabs.push(tabYear);
  });

  // this chain defines the order os the tabs in the interface
  if (visibleTabs.length > 1) {
    visibleTabs.push(COURSE_TYPE_KEY.EVERY_COURSE_UCS);
  }
  if (curricularPlan.accreditedUcs?.length > 0) {
    visibleTabs.push(COURSE_TYPE_KEY.ACCREDITED_UCS);
  }
  if (curricularPlan.extraCurricularUcs?.length > 0) {
    visibleTabs.push(COURSE_TYPE_KEY.EXTRA_CURRICULAR_UCS);
  }
  if (curricularPlan.mobilityUcs?.length > 0) {
    visibleTabs.push(COURSE_TYPE_KEY.MOBILITY_UCS);
  }

  return visibleTabs;
};

export const getTableColumnsForMandatoryUcs = (
  mandatoryCourseColumns: IColumnItem[],
  ucs: ICourseUC[],
  subUcs: any,
  isExtraCurricular: boolean,
  t
) => {
  // mandatory ucs: ua ucs

  let visibleColumns = mandatoryCourseColumns.slice();
  let columnsToRemove = [] as number[];

  // elem1: ectsType, elem2: creditsType
  let ucsTypesList = findCreditTypes(ucs);

  if (!isExtraCurricular) {
    // year of study column
    columnsToRemove.unshift(2);
  } else {
    visibleColumns[2].value = t('curricularPlan.academicName', { textOnly: true });
    columnsToRemove.unshift(3);

    const extracurricularTypesList = findCreditTypes(ucs);
    ucsTypesList = [extracurricularTypesList[0], extracurricularTypesList[1]];
  }

  if (ucsTypesList[0] && !ucsTypesList[1]) {
    columnsToRemove.unshift(4);
  } else if (ucsTypesList[1] && !ucsTypesList[0]) {
    columnsToRemove.unshift(5);
  }

  columnsToRemove.forEach((columnIndex) => visibleColumns.splice(columnIndex, 1));

  return visibleColumns;
};

export const getTableColumnsForAllCourseUcs = (
  mandatoryCourseColumns: IColumnItem[],
  ucsDict: Dictionary<ICourseUC[]>,
  subUcs: Dictionary<ICourseUC[]>
) => {
  let visibleColumns = mandatoryCourseColumns.slice();
  let columnsToRemove = [] as number[];

  let ectsType = false;
  let creditsType = false;
  let hasCollapsableRows = false;

  for (let key in ucsDict) {
    // check if ucs have collapsable rows
    for (let i = 0; i < ucsDict[key].length; i++) {
      if (ucsDict[key][i].ucCode in subUcs) {
        hasCollapsableRows = true;
        break;
      }
    }

    if (hasCollapsableRows) {
      break;
    }
  }

  for (let key in ucsDict) {
    // 1 = ECTS
    //-1 = Créditos
    for (let uc of ucsDict[key]) {
      if (!creditsType && uc.bolonha === GRADE_TYPE.CREDITS) {
        creditsType = true;
      } else if (!ectsType && uc.bolonha === GRADE_TYPE.ECTS) {
        ectsType = true;
      }

      if (creditsType && ectsType) {
        break;
      }
    }
  }

  if (ectsType && !creditsType) {
    columnsToRemove.unshift(4);
  } else if (creditsType && !ectsType) {
    columnsToRemove.unshift(5);
  }

  columnsToRemove.forEach((columnIndex) => visibleColumns.splice(columnIndex, 1));

  return visibleColumns;
};

export const setupOptionsTableContent = (
  optionsCourseColumns: IColumnItem[],
  ucOptions,
  currentLocale: string,
  t
) => {
  const credits = findCreditTypes(ucOptions.courseList);

  let tmpOptionsList = [...optionsCourseColumns];

  if (!credits[0]) {
    const indexToRemove = tmpOptionsList.map((e) => e.key).indexOf(COURSE_FIELD_KEY.ECTS);

    tmpOptionsList.splice(indexToRemove, 1);
  } else if (!credits[1]) {
    const indexToRemove = tmpOptionsList.map((e) => e.key).indexOf(COURSE_FIELD_KEY.CREDITS);

    tmpOptionsList.splice(indexToRemove, 1);
  }

  // remove academic year column
  const indexAcademicYear = tmpOptionsList
    .map((e) => e.key)
    .indexOf(COURSE_FIELD_KEY.ACADEMIC_YEAR);
  tmpOptionsList.splice(indexAcademicYear, 1);

  const header = {
    type: 'columnTitle' as string,
    titles: tmpOptionsList.map((title) => {
      if (
        title.key === COURSE_FIELD_KEY.UC_CODE ||
        title.key === COURSE_FIELD_KEY.ACADEMIC_YEAR ||
        title.key === COURSE_FIELD_KEY.YEAR_STUDY
      ) {
        return {
          text: title.value,
          style: {
            textAlign: 'center',
          },
        };
      } else if (title.key === COURSE_FIELD_KEY.ECTS) {
        if (credits[0]) {
          return {
            text: title.value,
            style: {
              textAlign: 'center',
            },
          };
        }
      } else if (title.key === COURSE_FIELD_KEY.CREDITS) {
        if (credits[1]) {
          return {
            text: title.value,
            style: {
              textAlign: 'center',
            },
          };
        }
      }

      return {
        text: title.value,
      };
    }),
  };

  const tableRows = ucOptions.courseList.map((uc) => {
    return {
      rowProps: { color: theme.colors.white },
      cells: tmpOptionsList.map((title) => {
        let content = uc[title.key];

        if (content === null) {
          content = <FontAwesomeIcon icon={faEllipsisH} />;
        } else if (title.key === COURSE_FIELD_KEY.UC_NAME) {
          return createTableCell(content, {
            color: theme.colors.primary,
            weight: 500,
          });
        } else if (title.key === COURSE_FIELD_KEY.ECTS) {
          return createTableCell(
            parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 }),
            { textAlign: 'center' }
          );
        } else if (title.key === COURSE_FIELD_KEY.CREDITS) {
          return createTableCell(
            parseFloat(content).toLocaleString(currentLocale, { minimumFractionDigits: 1 }),
            { textAlign: 'center' }
          );
        } else if (title.key === COURSE_FIELD_KEY.SEMESTER) {
          let label: string;

          if (content > 0 && content <= 2) {
            label = t('curricularPlan.semesterName', { textOnly: true });
            return createTableCell(`${numeral(content).format('o')} ${label}`);
          } else {
            label = t('curricularPlan.anualName', { textOnly: true });
            return createTableCell(label);
          }
        }

        return createTableCell(content, { textAlign: 'center' });
      }),
    };
  });

  const structure = {
    header: header,
    rows: tableRows,
  };

  return structure;
};

const findCreditTypes = (ucsList: ICourseUC[]) => {
  let ectsType = false;
  let creditsType = false;

  // 1 = ECTS
  //-1 = Créditos
  for (let uc of ucsList) {
    if (!creditsType && uc.bolonha === GRADE_TYPE.CREDITS) {
      creditsType = true;
    } else if (!ectsType && uc.bolonha === GRADE_TYPE.ECTS) {
      ectsType = true;
    }

    if (creditsType && ectsType) break;
  }

  return [ectsType, creditsType];
};
