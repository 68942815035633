import axios from 'axios';
import Endpoints from 'api/endpoints';
import { IParams } from '../index';

import mockPayments from 'api/schemas/mockPayments';
import mockUnpaidDebits from 'api/schemas/mockUnpaidDebits';
import mockSecVirtual from 'api/schemas/mockSecVirtual';

import { IApiCall } from '../index';

const getPersonalData = () => {
  return axios
    .get(Endpoints.secVirtual.personalDataForms)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const getPersonalDataPhoneNumber = () => {
  return axios
    .get(Endpoints.secVirtual.personalDataPhoneNumber)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCertificateCodeValidation = (accessKey: string) => {
  return axios
    .get(Endpoints.secVirtual.certificateCodeValidation(accessKey))
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentCardInfo = () => {
  return axios
    .get(Endpoints.secVirtual.studentCardInfo)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const patchAddressForm = (data) => {
  return axios
    .patch(Endpoints.secVirtual.addressForm, data)
    .then((res) => res.status)
    .catch((err) => {
      throw err;
    });
};

const patchContactsForm = (data) => {
  return axios
    .patch(Endpoints.secVirtual.contactsForm, data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentPicture = () => {
  return axios
    .get(Endpoints.secVirtual.studentPicture)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentPictureFile = (endpoint) => {
  return axios
    .get(endpoint)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCertificatesList = (params?: IParams) => {
  return axios
    .get(Endpoints.secVirtual.studentCertificatesList, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCertificatesDetails = (certificateId: number, registrationId: number) => {
  //return mockSecVirtual.getCertificateReady;
  return axios
    .get(Endpoints.secVirtual.studentCertificatesDetails(certificateId), {
      params: {
        registrationId: registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCertificatesDetailsChangeHistory = (certificateId: number, registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.getCertificateChangeHistory(certificateId, registrationId))
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getAllStudentRegistrations = () => {
  return axios
    .get(Endpoints.secVirtual.allStudentRegistrations)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentRegistrationSummary = (registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.studentRegistrationSummary, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentCurricularPlan = (registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.studentCurricularPlan, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentExtraCurricularUcs = (registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.studentExtraCurricularUcs, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentMobilityUcs = (registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.studentMobilityUcs, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentAccreditedUcs = (registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.studentAccreditedUcs, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentModuleOptions = (ucCode: number, registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.studentModuleOptions(ucCode), {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getRegistration = ({ registrationId }) => {
  return axios
    .get(Endpoints.secVirtual.registration(registrationId))
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getApplicationTypes = (params: {
  registrationId: number;
  type?: string;
  search?: string;
}) => {
  return axios
    .get(Endpoints.secVirtual.getApplicationTypes, {
      params: {
        ...params,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const getApplicationForm = ({ registrationId, applicationId }) => {
  //return mockSecVirtual.getApplicationForm;
  return axios
    .get(Endpoints.secVirtual.getApplicationForm(applicationId), {
      params: {
        registrationId,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

const postApplicationForm = ({ registrationId, data }) => {
  return axios
    .post(Endpoints.secVirtual.postApplicationForm, data, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postCertAccessCodeWithRecaptcha = ({ accessCode, recaptchaToken }) => {
  return axios
    .post(Endpoints.secVirtual.postCertAccessCodeWithRecaptcha, {
      accessCode: accessCode,
      'g-recaptcha-response': recaptchaToken,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentApplications = (params: {
  registrationId: number;
  type?: string;
  search?: string;
}) => {
  return axios
    .get(Endpoints.secVirtual.getStudentApplications, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getApplicationDetails = ({ registrationId, applicationId }) => {
  return axios
    .get(Endpoints.secVirtual.getApplicationDetails(applicationId), {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCertificateTypes = (params: {
  registrationId: number;
  type?: string;
  search?: string;
}) => {
  //return mockSecVirtual.getCertificates;
  return axios
    .get(Endpoints.secVirtual.getCertificateTypes, {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCertificateForm = ({ registrationId, certificateCode }) => {
  //return mockSecVirtual.getCertificateForm;
  return axios
    .get(Endpoints.secVirtual.getCertificateForm(certificateCode, registrationId), {})
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postCertificateForm = (data, registrationId) => {
  return axios
    .post(Endpoints.secVirtual.postCertificateForm(registrationId), data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postCertificateOptions = (data, registrationId) => {
  return axios
    .post(Endpoints.secVirtual.postCertificateOptions(registrationId), data)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentCertificates = ({ registrationId }) => {
  return axios
    .get(Endpoints.secVirtual.getStudentCertificateRequests, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
const getStudentCertificatesOnMountState = ({ registrationId }) => {
  return axios
    .get(Endpoints.secVirtual.getStudentCertificateRequestsOnMountState, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentsPayments = (params) => {
  return axios
    .get(Endpoints.secVirtual.payments, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentsPendingDebits = (registrationId: number) => {
  return axios
    .get(Endpoints.secVirtual.pendingDebits, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentsUnpaidDebits = (params: {
  limit: number;
  offset: number;
  registrationId: number;
  type?: string;
  search?: string;
}) => {
  return axios
    .get(Endpoints.secVirtual.unpaidDebits, { params })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getExamCalendar = (params: {
  registrationId: number;
  type?: string;
  search?: string;
  date?: string;
}) => {
  return axios
    .get(Endpoints.secVirtual.getExamCalendar, { params: { ...params } })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCalendarIcsFile = (endpoint, registrationId) => {
  return axios
    .get(endpoint, registrationId)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postPaymentReferenceMbWay = ({ registrationId, data }) => {
  //return mockPayments.postRefMbWay;
  return axios
    .post(Endpoints.secVirtual.paymentsMbWay, data, {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postPaymentReferenceMb = ({ registrationId, data }) => {
  // return mockPayments.postRefMb;
  return axios
    .post(
      Endpoints.secVirtual.paymentsMb,
      { ...data },
      {
        params: {
          registrationId,
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getPaymentMbInfo = ({ paymentRequestId, registrationId }) => {
  // return mockPayments.postRefMb;
  return axios
    .get(Endpoints.secVirtual.paymentMbInfo(paymentRequestId), {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const deletePaymentMb = ({ paymentRequestId, registrationId }) => {
  // return mockPayments.postRefMb;
  return axios
    .delete(Endpoints.secVirtual.paymentMbInfo(paymentRequestId), {
      params: {
        registrationId,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getCurricularUnitDetails = (ucCode: number, registrationId: number) => {
  //return mockSecVirtual.getUcDetails;
  return axios
    .get(Endpoints.secVirtual.getCurricularUnitDetails(ucCode), {
      params: { registrationId: registrationId },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getStudentSignups = (data) => {
  //return mockSecVirtual.getStudentSignups;
  return axios
    .get(Endpoints.secVirtual.studentSignups)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export default {
  getPersonalData: {
    call: getPersonalData,
    scope: 'paco_2.0_secvirtual_get_personal_info',
  } as IApiCall,
  getPersonalDataPhoneNumber: {
    call: getPersonalDataPhoneNumber,
    scope: 'paco_2.0_secvirtual_get_personal_contact',
  } as IApiCall,
  getCertificateCodeValidation: {
    call: getCertificateCodeValidation,
  } as IApiCall,
  getStudentCardInfo: {
    call: getStudentCardInfo,
    scope: 'paco_2.0_secvirtual_get_card_info',
  } as IApiCall,
  patchAddressForm: {
    call: patchAddressForm,
    scope: 'paco_2.0_secvirtual_patch_address',
  } as IApiCall,
  patchContactsForm: {
    call: patchContactsForm,
    scope: 'paco_2.0_secvirtual_patch_contacts',
  } as IApiCall,
  getStudentPicture: {
    call: getStudentPicture,
    scope: 'paco_2.0_secvirtual_get_photo',
  } as IApiCall,
  getCertificatesList: {
    call: getCertificatesList,
    scope: 'paco_2.0_secvirtual_get_certificates',
  } as IApiCall,
  getCertificatesDetails: {
    call: getCertificatesDetails,
    scope: 'paco_2.0_secvirtual_get_student_certificate_details',
  } as IApiCall,
  getCertificatesDetailsChangeHistory: {
    call: getCertificatesDetailsChangeHistory,
    scope: 'paco_2.0_secvirtual_get_student_cert_history',
  } as IApiCall,
  getAllStudentRegistrations: {
    call: getAllStudentRegistrations,
    scope: 'paco_2.0_secvirtual_get_registrations',
  } as IApiCall,
  getStudentRegistrationSummary: {
    call: getStudentRegistrationSummary,
    scope: 'paco_2.0_secvirtual_get_curricular_plan_summary',
  } as IApiCall,
  getStudentCurricularPlan: {
    call: getStudentCurricularPlan,
    scope: 'paco_2.0_secvirtual_get_curricular_plan',
  } as IApiCall,
  getStudentExtraCurricularUcs: {
    call: getStudentExtraCurricularUcs,
    scope: 'paco_2.0_secvirtual_get_extracurricular_ucs',
  } as IApiCall,
  getStudentMobilityUcs: {
    call: getStudentMobilityUcs,
    scope: 'paco_2.0_secvirtual_get_mobility_ucs',
  } as IApiCall,
  getStudentAccreditedUcs: {
    call: getStudentAccreditedUcs,
    scope: 'paco_2.0_secvirtual_get_accredited_ucs',
  } as IApiCall,
  getStudentModuleOptions: {
    call: getStudentModuleOptions,
    scope: 'paco_2.0_secvirtual_get_options_modules_ucs',
  } as IApiCall,
  getRegistration: {
    call: getRegistration,
    scope: 'paco_2.0_secvirtual_get_registration_details',
  } as IApiCall,
  getApplicationTypes: {
    call: getApplicationTypes,
    scope: 'paco_2.0_secvirtual_get_applications',
  } as IApiCall,
  getApplicationForm: {
    call: getApplicationForm,
    scope: 'paco_2.0_secvirtual_get_application_details',
  } as IApiCall,
  postApplicationForm: {
    call: postApplicationForm,
    scope: 'paco_2.0_secvirtual_post_application',
  } as IApiCall,
  getStudentApplications: {
    call: getStudentApplications,
    scope: 'paco_2.0_secvirtual_get_student_applications',
  } as IApiCall,
  getApplicationDetails: {
    call: getApplicationDetails,
    scope: 'paco_2.0_secvirtual_get_student_application_details',
  } as IApiCall,
  getStudentsPayments: {
    call: getStudentsPayments,
    scope: 'paco_2.0_secvirtual_get_payments',
  } as IApiCall,
  getStudentsUnpaidDebits: {
    call: getStudentsUnpaidDebits,
    scope: 'paco_2.0_secvirtual_get_unpaid_debits',
  } as IApiCall,
  getStudentsPendingDebits: {
    call: getStudentsPendingDebits,
    scope: 'paco_2.0_secvirtual_get_pending_debits',
  } as IApiCall,
  getCertificateTypes: {
    call: getCertificateTypes,
    scope: 'paco_2.0_secvirtual_get_certificates',
  } as IApiCall,
  getCertificateForm: {
    call: getCertificateForm,
    scope: 'paco_2.0_secvirtual_get_certificate_details',
  } as IApiCall,
  postCertificateForm: {
    call: postCertificateForm,
    scope: 'paco_2.0_secvirtual_post_certificate',
  } as IApiCall,
  postCertificateOptions: {
    call: postCertificateOptions,
    scope: 'paco_2.0_secvirtual_get_certificate_cost',
  } as IApiCall,
  getStudentCertificates: {
    call: getStudentCertificates,
    scope: 'paco_2.0_secvirtual_get_student_certificates',
  } as IApiCall,
  getStudentCertificatesOnMountState: {
    call: getStudentCertificatesOnMountState,
    scope: 'paco_2.0_secvirtual_get_student_certificates',
  } as IApiCall,
  getExamCalendar: {
    call: getExamCalendar,
    scope: 'paco_2.0_secvirtual_get_calendar',
  } as IApiCall,
  postPaymentReferenceMbWay: {
    call: postPaymentReferenceMbWay,
    scope: 'paco_2.0_secvirtual_post_mb_way_ref_payment',
  } as IApiCall,
  postPaymentReferenceMb: {
    call: postPaymentReferenceMb,
    scope: 'paco_2.0_secvirtual_post_mb_ref_payment',
  } as IApiCall,
  getPaymentMbInfo: {
    call: getPaymentMbInfo,
    scope: 'paco_2.0_secvirtual_get_mb_ref_payment',
  } as IApiCall,
  deletePaymentMb: {
    call: deletePaymentMb,
    scope: 'paco_2.0_secvirtual_delete_mb_ref_payment',
  } as IApiCall,
  getStudentSignups: {
    call: getStudentSignups,
    scope: 'paco_2.0_secvirtual_get_sign_ups',
  } as IApiCall,
  getCurricularUnitDetails: {
    call: getCurricularUnitDetails,
    scope: 'paco_2.0_secvirtual_get_uc_grades',
  } as IApiCall,
  getCalendarIcsFile: {
    call: getCalendarIcsFile,
    scope: 'paco_2.0_secvirtual_get_calender_file',
  } as IApiCall,
  getStudentPictureFile: {
    call: getStudentPictureFile,
    scope: 'paco_2.0_secvirtual_get_photo',
  },
  postCertAccessCodeWithRecaptcha: {
    call: postCertAccessCodeWithRecaptcha,
  },
};
