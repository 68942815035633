/**
 * NewCertificateSelectType container logic selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getLoading = (state: RootState) => state.NewApplication.loadingStatus.loading;
export const getNewCertificateSlice = (state: RootState) => state.NewCertificate;
