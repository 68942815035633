/**
 * MardownRenderer utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

export const getHeadings = (markdown) => {
  const headingRegex = /\n(#+\s*)(.*)/g;

  return markdown.match(headingRegex).map((heading) => {
    let level = 0;
    let didFindSpecialChar = false;
    let trimTo = 0;

    for (let key = 0; key < heading.length; key++) {
      const char = heading[key];
      if (char === '#' && !didFindSpecialChar) {
        level++;
        if (heading[key + 1] !== '#') {
          didFindSpecialChar = true;
          trimTo = key + 1;
          break;
        }
      }
    }
    return { level, value: heading.slice(trimTo).replace(/\s+/, '') };
  });
};
