import { RootState } from 'store/rootSlices';

export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getUser = (state: RootState) => state.App.user;
export const selectHDADOS = (state: RootState) => state.EditGrades.HDADOS;
export const selectDicionarios = (state: RootState) => state.EditGrades.Dicionarios;
export const selectRegimes = (state: RootState) => state.EditGrades.regimes;
export const selectGradeData = (state: RootState) => state.EditGrades.gradesData;
export const selectValidGradeValues = (state: RootState) => state.EditGrades.validGradeValues;
export const selectGradeEdits = (state: RootState) => state.EditGrades.gradeEdits;
export const selectIsStatisticsModalOpen = (state: RootState) => state.EditGrades.isStatisticsModalOpen;
export const isCSVModalOpen = (state: RootState) => state.EditGrades.isCSVModalOpen;
export const csvFile = (state: RootState) => state.EditGrades.csvFile;
export const selectIsLabelsModalOpen = (state: RootState) => state.EditGrades.isLabelsModalOpen;
export const selectDropdownValueSelected = (state: RootState) => state.EditGrades.dropdownValueSelected;
export const selectIsConfirmPageLeaveModalOpen = (state: RootState) => state.EditGrades.isConfirmPageLeaveModalOpen;
export const selectIsErrorsModalOpen = (state: RootState) => state.EditGrades.isErrorsModalOpen;
export const selectImportErrors = (state: RootState) => state.EditGrades.importErrors;
export const selectImportWarnings = (state: RootState) => state.EditGrades.importWarnings;
export const selectIsConfirmDiscardChangesModalOpen = (state: RootState) => state.EditGrades.isConfirmDiscardChangesModalOpen;
export const selectExamDate = (state: RootState) => state.EditGrades.examDate;
export const selectLoadingStatus = (state: RootState) => state.EditGrades.loadingStatus;
export const selectGradeDetails = (state: RootState) => state.EditGrades.gradeDetails;
