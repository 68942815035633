/**
 * Schedules scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  setClassSelectionState,
  setClassLockState,
  setClassesProvisorySeriationResult,
  clearSchedule,
  setReformatedClassesTypologies,
} from '../utils';

import {
  IUc,
  IMySchedule,
  ICreatedSchedule,
  IClassTypology,
  IClassSelectionPayload,
  IProvisorySeriationResult,
} from 'shared/types';

export interface ICurrentPhase {
  phase: number | null;
  year: string | null;
  period: string | null;
  isFree: boolean | null;
  finished: boolean | null;
  hasAllocatedSchedule: boolean | null;
  attemptedSchedule: boolean | null;
  seriationFinished: boolean | null;
}

const initialState = {
  ucs: [] as IUc[],
  unfilteredUcs: {} as { mandatoryUcs: IUc[]; allUcs: IUc[] },
  mandatoryUcs: [] as IUc[],
  freeUcs: [] as IUc[],
  freeOptionsGroups: [] as any[],
  originalEditingUcs: [] as IUc[],
  restricted: null as null | boolean,
  restrictionMotive: null as null | string,
  currentPhase: null as ICurrentPhase | null,
  isCurrentPhaseActive: false as Boolean,
  currentMode: null as null | 'view' | 'create' | 'edit' | 'duplicate',
  scheduleToEdit: null as null | ICreatedSchedule,
  schedulesNumber: 0 as number,
  exchangeUcs: [] as IUc[],
  studentTotalChoices: 0 as number,
  confirmationModalIsOpen: false,
  mySchedules: null as null | IMySchedule[],
  period: '',
  scheduleName: null as string | null,
  editScheduleName: false as boolean,
  periods: [] as any,
  classesTypologies: [] as IClassTypology[],
  expandedWeekDay: null as null | number,
  scheduleType: 'grid' as 'grid' | 'gridExchange' | 'gridExpanded' | 'list',
  loadingRestriction: true,
  loadingSchedule: true,
  savingSchedule: false,
  editingSchedule: false,
  disclaimerMobileModalIsOpen: true,
  scheduleGridInterval: { min: '8:00', max: '20:00' } as { min: string; max: string },
  createdSchedules: null as null | number,
};

export default createSlice({
  name: 'Schedules',
  initialState,
  reducers: {
    onMount: () => {},
    reset: (state) => initialState,
    onPhaseUpdate: () => {},
    getAccessAndUcs: () => {},
    setCurrentPhase: (state, action: PayloadAction<ICurrentPhase>) => {
      state.currentPhase = action.payload;
    },
    setIsCurrentPhaseActive: (state, action: PayloadAction<Boolean>) => {
      state.isCurrentPhaseActive = action.payload;
    },
    getCurrentMode: () => {},
    setCurrentMode: (state, action: PayloadAction<'view' | 'create' | 'edit' | 'duplicate'>) => {
      state.currentMode = action.payload;
    },
    handleScheduleCreateMode: () => {},
    handleScheduleEditOrDuplicateMode: () => {},
    setScheduleToEdit: (state, action: PayloadAction<ICreatedSchedule>) => {
      state.scheduleToEdit = action.payload;
    },
    setRestriction: (state, action: PayloadAction<boolean | null>) => {
      state.restricted = action.payload;
    },
    setRestrictionMotive: (state, action: PayloadAction<string>) => {
      state.restrictionMotive = action.payload;
    },
    setUcs: (state, action: PayloadAction<IUc[]>) => {
      state.ucs = action.payload;
    },
    setUnfilteredUcs: (state, action: PayloadAction<{ mandatoryUcs: IUc[]; allUcs: IUc[] }>) => {
      state.unfilteredUcs = action.payload;
    },
    setCreatedSchedules: (state, action: PayloadAction<number | null>) => {
      state.createdSchedules = action.payload;
    },
    setMandatoryUcs: (state, action: PayloadAction<IUc[]>) => {
      state.mandatoryUcs = action.payload;
    },
    setFreeUcs: (state, action: PayloadAction<IUc[]>) => {
      state.freeUcs = action.payload;
    },
    setFreeOptionsGroups: (state, action: PayloadAction<any[]>) => {
      state.freeOptionsGroups = action.payload;
    },
    setSchedulesNumber: (state, action: PayloadAction<number>) => {
      state.schedulesNumber = action.payload;
    },
    setScheduleGridInterval: (state, action: PayloadAction<{ min: string; max: string }>) => {
      state.scheduleGridInterval = action.payload;
    },
    setClassesTypologies: (state, action: PayloadAction<IClassTypology[]>) => {
      state.classesTypologies = action.payload;
    },
    getStudentTotalChoices: (state, action: PayloadAction<IUc[]>) => {},
    setStudentTotalChoices: (state, action: PayloadAction<number>) => {
      state.studentTotalChoices = action.payload;
    },
    setEditScheduleName: (state, action: PayloadAction<boolean>) => {
      state.editScheduleName = action.payload;
    },
    setScheduleName: (state, action: PayloadAction<string>) => {
      state.scheduleName = action.payload;
    },
    setMandatoryOptions: (
      state,
      action: PayloadAction<{ ucId: number; groupId?: number | null }>
    ) => {
      state.ucs = state.ucs.map((uc) => {
        let comparator = uc.ucId === action.payload.ucId;
        if (action.payload.groupId !== null && action.payload.groupId !== undefined) {
          comparator = uc.ucId === action.payload.ucId && uc.groupId === action.payload.groupId;
        }
        return comparator
          ? {
              ...uc,
              classSchedule: uc.classSchedule.map((classItem) => ({
                ...classItem,
                filtered: false,
              })),
            }
          : uc;
      });
    },
    removeMandatoryOptions: (
      state,
      action: PayloadAction<{ ucId: number; groupId: number | null }>
    ) => {
      state.ucs = state.ucs.map((uc) =>
        uc.ucId !== action.payload.ucId && uc.groupId !== action.payload.groupId
          ? uc
          : {
              ...uc,
              classSchedule: uc.classSchedule.map((classItem) => ({
                ...classItem,
                filtered: true,
                selected: false,
              })),
            }
      );
    },
    setFreeOptions: (state, action: PayloadAction<{ ucItem: any }>) => {
      const newUcs = [...state.ucs, action.payload.ucItem];
      state.ucs = newUcs;
      state.classesTypologies = setReformatedClassesTypologies(newUcs);
      state.freeUcs = [...state.freeUcs, action.payload.ucItem];
    },
    removeFreeOptions: (state, action: PayloadAction<number>) => {
      state.ucs = state.ucs.reduce((acc: any, uc) => {
        if (uc.ucId !== action.payload) {
          acc.push(uc);
        }
        return acc;
      }, [] as any);

      state.freeUcs = state.freeUcs.reduce((acc: any, uc) => {
        if (uc.ucId !== action.payload) {
          acc.push(uc);
        }
        return acc;
      }, [] as any);
    },
    setPeriods: (state, action: PayloadAction<[]>) => {
      state.periods = action.payload;
    },
    setPeriod: (state, action: PayloadAction<string>) => {
      state.period = action.payload;
    },
    getMySchedules: () => {},
    setExpandedWeekDay: (state, action: PayloadAction<number | null>) => {
      state.expandedWeekDay = action.payload;
    },
    setScheduleType: (
      state,
      action: PayloadAction<'grid' | 'gridExchange' | 'gridExpanded' | 'list'>
    ) => {
      state.scheduleType = action.payload;
    },
    setDisclaimerMobileModalIsOpen: (state, action: PayloadAction<boolean>) => {
      state.disclaimerMobileModalIsOpen = action.payload;
    },
    setClassSelectionState: (state, action: PayloadAction<IClassSelectionPayload>) => {
      state.ucs = setClassSelectionState(state.ucs, action);
    },
    setClassLockState: (state, action: PayloadAction<number>) => {
      state.ucs = setClassLockState(state.ucs, action);
    },
    handleConfirmationModalOpening: (state, action: PayloadAction<boolean>) => {
      state.confirmationModalIsOpen = action.payload;
    },
    selectClassesToEditSchedule: (
      state,
      action: PayloadAction<{ classesId: number[]; freeUcs: IUc[] }>
    ) => {
      /*
      //we need to append freeUcs to ucs on edit because there is no other way to find them out
      const newUcs = [
        ...selectClassesToEditSchedule(state.ucs, action.payload.classesId),
        ...action.payload.freeUcs,
      ];
      state.ucs = newUcs;
      state.mandatoryUcs = setScheduleSelectedMandatoryClasses(
        state.mandatoryUcs,
        action.payload.classesId
      );
      state.freeUcs = action.payload.freeUcs;*/
      state.originalEditingUcs = [...action.payload.freeUcs];
    },
    editSchedule: (state, action: PayloadAction<number>) => {},
    submitSchedule: () => {},
    clearSchedule: (state) => {
      state.ucs = clearSchedule(state.ucs);
    },
    setClassesProvisorySeriationResult: (
      state,
      action: PayloadAction<{ ucs: IUc[]; seriationResult: IProvisorySeriationResult[] }>
    ) => {
      const { ucs, seriationResult } = action.payload;
      state.ucs = setClassesProvisorySeriationResult(ucs, seriationResult);
    },
    setLoadingRestriction: (state, action: PayloadAction<boolean>) => {
      state.loadingRestriction = action.payload;
    },
    setLoadingSchedule: (state, action: PayloadAction<boolean>) => {
      state.loadingSchedule = action.payload;
    },
    setSavingSchedule: (state, action: PayloadAction<boolean>) => {
      state.savingSchedule = action.payload;
    },
    setEditingSchedule: (state, action: PayloadAction<boolean>) => {
      state.editingSchedule = action.payload;
    },
    deleteSchedule: (state, action: PayloadAction<number>) => {},
  },
});
