import React from 'react';
import styled from 'styled-components';

import { t } from 'app';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTablet, faMobile, faDesktop } from '@fortawesome/pro-light-svg-icons';

import { Text, Button } from '@paco_ua/pacoui';

interface IProps {
  onEnter?: () => void;
}

const MobileSupportModal: React.FC<IProps> = ({ onEnter }) => {
  return (
    <Wrapper>
      <InfoIcon>
        <FontAwesomeIcon icon={faInfoCircle} />
      </InfoIcon>
      <TitleWarpper>
        <Text fontWeight={'500'} size={'xxLarge'} color={'plusDarkGrey'}>
          {t('generic.availableTabletDesktopTitle')}
        </Text>
      </TitleWarpper>
      <Text fontWeight={'500'} size={'mediumSmall'} color={'plusDarkGrey'}>
        {t('generic.availableTabletDesktopBody')}
      </Text>

      <FiguresWrapper>
        <HardwareIcon>
          <FontAwesomeIcon icon={faDesktop} color={'plusDarkGrey'} />

          <Text fontWeight={'500'} size={'mediumSmall'} color={'plusDarkGrey'}>
            DESKTOP
          </Text>
        </HardwareIcon>

        <HardwareIcon>
          <FontAwesomeIcon icon={faTablet} color={'plusDarkGrey'} />
          <Text fontWeight={'500'} size={'mediumSmall'} color={'plusDarkGrey'}>
            TABLET
          </Text>
        </HardwareIcon>

        <MobileIcon>
          <FontAwesomeIcon icon={faMobile} color={'lightGrey'} />
          <Text fontWeight={'500'} size={'mediumSmall'} color={'regularGrey'}>
            MOBILE
          </Text>
        </MobileIcon>
      </FiguresWrapper>

      <Button
        action
        onClick={() => {
          onEnter && onEnter();
        }}
      >
        {t('generic.enter')}
      </Button>

      <Footer size={'mediumSmall'} color={'darkGrey'}>
        {t('generic.mobileInDevelopment')}
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  margin-top: 20px;
`;

const TitleWarpper = styled.div`
  margin: 32px 0px;
`;

const FiguresWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 40px 0px;
`;

const InfoIcon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const HardwareIcon = styled.div`
  font-size: 60px;
  margin: 20px 0px;
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  margin-right: 50px;

  && :nth-child(1) {
    margin: 10px 10px;
  }
`;

const MobileIcon = styled.div`
  font-size: 60px;
  margin: 20px 0px;
  color: ${({ theme }) => theme.colors.darkGrey};

  && :nth-child(1) {
    margin: 10px 0px;
  }
`;

const Footer = styled(Text)`
  margin-top: 20px;
`;

export default MobileSupportModal;
