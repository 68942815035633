/**
 * StudentRegistrationDropdown container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';
import { t } from 'app';

import styled, { ThemeContext } from 'styled-components';

import { AnimatedBackground, Dropdown, StatusLabel, Text } from '@paco_ua/pacoui';

//import config from 'config'

interface IStudentRegistrationDropdown {
  onChange?: (value: number) => void;
  disabled?: boolean;
  loading?: boolean | undefined;
  loadingProps?: any;
}

const StudentRegistrationDropdown: React.FC<IStudentRegistrationDropdown> = ({
  disabled,
  onChange,
  loading,
  loadingProps,
}) => {
  const registration = useSelector(selectors.getRegistration);
  const user = useSelector(selectors.getUser);
  const { setRegistration } = actions.StudentRegistrationDropdown;
  const [text, setText] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    if (!registration && user.registrations && user.registrations.length) {
      dispatch(setRegistration(user.registrations[0].registrationId));
    }
  }, [dispatch, user, setRegistration, registration]);

  const theme = useContext(ThemeContext);

  const registrationsOptions = () => {
    if (user.registrations) {
      return user.registrations.map((registration, index) => {
        return {
          key: `registration-${index}`,
          text: (
            <StatusLabelWrapper>
              <span>{` ${registration.courseCode} | ${registration.courseName}`}</span>
              {`\n${registration.branchName}\n`}
              {registration.eligibilityState ||
                            registration.registrationState === 'activo' ? (
                              <StatusLabel
                                label={`${t('enrollmentState.' + registration.registrationState, {
                                  textOnly: true,
                                })}`}
                                background={theme.colors.successGreen}
                                hideTooltip
                              ></StatusLabel>
                            ) : registration.registrationState === 'comdiploma' || registration.registrationState === 'terminado' ? (
                              <StatusLabel
                                label={`${t('enrollmentState.' + registration.registrationState, {
                                  textOnly: true,
                                })}`}
                                background={theme.colors.softgrey}
                                hideTooltip
                              ></StatusLabel>
                            ):(
                              <StatusLabel
                                label={`${t('enrollmentState.' + registration.registrationState, {
                                  textOnly: true,
                                })}`}
                                background={theme.colors.dangerRed}
                                hideTooltip
                              ></StatusLabel>
                            )}
              {` * ${registration.academicYear}`}
            </StatusLabelWrapper>
          ),
          value: registration.registrationId,
        };
      });
    } else {
      return [];
    }
  };

  const selectedRegistration = () => {
    let reg = '';
    if (user.registrations) {
      user.registrations.forEach((userRegistrations) => {
        if (userRegistrations.registrationId === registration) {
          reg = userRegistrations.courseName;
        }
      });
      return reg;
    } else {
      return reg;
    }
  };

  // Handlers
  const handleRegistrationChange = (registrationId) => {
    dispatch(setRegistration(registrationId));
    onChange && onChange(registrationId);
    if (user.registrations) {
      user.registrations.forEach((userRegistrations) => {
        if (userRegistrations.registrationId === registrationId) {
          setText(
            `${userRegistrations.courseCode} | ${userRegistrations.courseName} | ${userRegistrations.academicYear}`
          );
        }
      });
    }
  };

  const ActiveRegistration = (registrationId) => {
    dispatch(setRegistration(registrationId));
    if (user.registrations) {
      const foundUserRegistration = user.registrations.find(
        (userRegistration) => userRegistration.registrationId === registrationId
      );

      if (foundUserRegistration) {
        const { courseCode, courseName, academicYear } = foundUserRegistration;
        setText(`${courseCode} | ${courseName} | ${academicYear}`);
        return `${courseCode} | ${courseName} | ${academicYear}`;
      }
    }
    // Handle the case when user.registrations is null or no matching registration was found.
    // You can return a default value or handle it as needed.
  };

  return (
    <Wrapper>
      <br />
      {loading && <AnimatedBackground height={'22px'} width={'70%'} />}

      {!loading && user.registrations && user.registrations.length === 1 && (
        <SingleRegistration color="plusDarkGrey" weight="regular" size="medium">
          {user.registrations[0].courseName}
        </SingleRegistration>
      )}
      {!loading && user.registrations && user.registrations.length > 1 && (
        <>
          {disabled && <SingleRegistration>{selectedRegistration()}</SingleRegistration>}
          {!disabled && (
            <CostumDrop
              text={text || ActiveRegistration(registration)}
              borderless
              selectionWeight={'400'}
              selectionFontSize={'medium'}
              defaultValue={registration || user.registrations[0].registrationId}
              options={registrationsOptions()}
              onChange={(e, { value }) => {
                handleRegistrationChange(value);
              }}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default StudentRegistrationDropdown;

const Wrapper = styled.div`
  &.visible.menu.transition {
    margin-top: 15px !important;
  }
`;

const CostumDrop = styled(Dropdown)`
  &.ui.dropdown > .text {
    white-space: break-spaces !important;
  }
  &.ui.dropdown > .text:first-line {
    font-size: medium !important;
    font-weight: 500;
  }

  &.ui.dropdown .menu > * {
    white-space: break-spaces !important;
    line-height: 1.4285em;

    span {
      font-weight: 700;
    }
  }

  div > div > div {
    display: inline;
  }

  .visible.menu.transition {
    margin-top: 15px !important;
    min-width: fit-content;
  }
`;

const StatusLabelWrapper = styled(Text)`
  & div {
    :hover {
      cursor: pointer;
    }
  }
`;

const SingleRegistration = styled(Text)``;
