/**
 * Debits Table container logic
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ITranslations, IDebitData, IPagination } from 'scenes/VirtualSecretary/Payments/types';
import { DEFAULT_STRUCTURE, DEFAULT_PAGINATION, isRowSelected } from './utils';
import { DEFAULT_UNSELECTED_DEBITS_PARAMS } from '../../utils';

import * as selectors from '../../logic/selectors';

export default (
  t,
  debits: IDebitData[],
  multiples: IDebitData[],
  compileTableRow,
  total: number,
  fetchDebits,
  disableButtons,
  setPreSelectedDebits,
  openPendingDebitsPaymentPage,
  selectedDebits,
  filterParams,
  filtersChanged
) => {
  const theme = useContext(ThemeContext);
  const [structure, setStructure] = useState(DEFAULT_STRUCTURE(t));
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION(total));

  const registrationId = useSelector(selectors.getRegistrationId);

  const [initialFetch, setInitialFetch] = useState(true);

  // initial debits fetch
  useEffect(() => {
    if (initialFetch) {
      setInitialFetch(false);
    } else {
      if (registrationId !== null && registrationId !== undefined) {
        // fetch new list
        fetchDebits(DEFAULT_UNSELECTED_DEBITS_PARAMS);
      }
    }
  }, [filterParams]);

  // reset pagination when filters are changed
  useEffect(() => {
    if (filtersChanged) {
      setPagination(DEFAULT_PAGINATION(total));
    }
  }, [filtersChanged]);

  // update pagination when total value is changed (when filtering)
  useEffect(() => {
    let updatePagination = { ...pagination };
    updatePagination.total = total;

    setPagination(updatePagination);
  }, [total]);

  useEffect(() => {
    handlePaginationNavigation(pagination);
  }, [pagination]);

  // Update table when new debits are fetched,
  // or to disable the payment buttons when multiple debits are selected
  useEffect(() => {
    const tableStructure = compileTableStructure(t, debits, multiples, theme);
    setStructure(tableStructure);
  }, [debits, selectedDebits]);

  const compileTableStructure = (
    t: ITranslations,
    debits: IDebitData[],
    multiples: IDebitData[],
    theme
  ) => ({
    header: {
      type: 'columnTitle',
      titles: [
        { text: t('payments.debitsTablePaymentDescription', { textOnly: true }) },
        {
          text: t('payments.debitsTablePaymentValue', { textOnly: true }),
          style: { textAlign: 'center' },
        },
        {
          text: t('payments.debitsTableDueDate', { textOnly: true }),
          style: { textAlign: 'center' },
        },
        {
          text: t('payments.debitsTablePaymentStatus', { textOnly: true }),
          style: { textAlign: 'center' },
        },
        { text: '' },
      ],
    },
    rows: !debits.length
      ? ([] as any)
      : [
          ...debits.map((debit, key) =>
            compileTableRow(
              debit,
              theme,
              t,
              openPendingDebitsPaymentPage,
              key,
              selectedDebits,
              isRowSelected(multiples, key),
              disableButtons,
              setPreSelectedDebits
            )
          ),
        ],
  });

  const handlePaginationNavigation = (newPagination: IPagination) => {
    const exceedsTotal = newPagination.limit >= newPagination.total;

    if (exceedsTotal) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = false;
    }

    if (!exceedsTotal && newPagination.offset < newPagination.limit) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = true;
    }

    if (!exceedsTotal && newPagination.offset >= newPagination.limit) {
      newPagination.hasPrevious = true;
      newPagination.hasNext =
        newPagination.offset + newPagination.limit >= newPagination.total ? false : true;
    }

    setPagination(newPagination);
  };

  const onChangeRows = (value: number): void => {
    const newPagination = { ...pagination };
    newPagination.limit = value;
    newPagination.offset = 0;

    handlePaginationNavigation(newPagination);
    setPagination(newPagination);
    fetchDebits(newPagination);
  };

  const onChangePage = (value: string): void => {
    const newPagination = { ...pagination };

    switch (value) {
      case 'prev':
        newPagination.offset -= newPagination.limit;
        break;
      case 'next':
        newPagination.offset += newPagination.limit;
        break;
      default:
        break;
    }

    handlePaginationNavigation(newPagination);
    setPagination(newPagination);
    fetchDebits(newPagination);
  };

  return {
    theme,
    structure,
    pagination,
    onChangePage,
    onChangeRows,
  };
};
