/**
 * LogsStudent scene
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */
 
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import * as selectors from './logic/selectors'
import { actions } from 'store/rootSlices'
import { usePostMessageMiddleware } from 'shared/components/ModuleCommunication'
import PageStructure from 'shared/containers/PageStructure';
import { CommunicationTypes } from 'shared/components/ModuleCommunication/communicationTypes'
import config from 'config'

const LogsStudent = ({ t, i18n }) => {

  const { onMount, onUnmount } = actions.LogsStudent
  const { showToaster } = actions.Toaster;
  const { signout } = actions.App
  const { user, currentLocale } = useSelector(selectors.getAppSlice);

  //iframe
  const [embeddedModuleLoaded, setEmbeddedModuleLoaded] = useState(false);
  const [embeddedModuleHeight, setEmbeddedModuleHeight] = useState(0);
  const embeddedModuleRef = useRef<HTMLIFrameElement>(null);
  //const { api_t } = actions.App

  const dispatch = useDispatch()

  //trying middleware
  const { addMessageListener, sendMessage } = usePostMessageMiddleware(
    embeddedModuleRef.current?.contentWindow,
    config.IFRAME_LOGS
  );
  
  const loadModule = async () => {    
    setEmbeddedModuleLoaded(true);
  };
  
  useEffect(() => {
    const iframeElement = embeddedModuleRef.current;
      
    if (iframeElement) {
      iframeElement.addEventListener('load', loadModule);
    }
      
    return () => {
      if (iframeElement) {
        iframeElement.removeEventListener('load', loadModule);
      }
    };
  }, []);
  
  useEffect(()=> {
    sendMessage(CommunicationTypes.STORE_OTHER_ACTION, user)
  },[user, embeddedModuleLoaded])
  
  useEffect(()=> {
    sendMessage(CommunicationTypes.CHANGE_LANG, {lang: currentLocale})
  },[currentLocale])


  useEffect(()=> {
    //here we define the handler for this type (STORE_TOASTER_ACTION) of message
    addMessageListener(CommunicationTypes.STORE_TOASTER_ACTION, (payload) => {
      dispatch(showToaster(payload));
    });
  
    //here we define the handler for this type (STORE_TOASTER_ACTION) of message
    addMessageListener(CommunicationTypes.IFRAME_HEIGHT, (payload) => {
      setEmbeddedModuleHeight(Number(payload.height) ?? 0);
    });

    //here we define the handler for this type (LOGOUT) of message
    addMessageListener(CommunicationTypes.LOGOUT, (payload) => {
        dispatch(signout());
    });
      
    sendMessage(CommunicationTypes.STORE_OTHER_ACTION, user)
    sendMessage(CommunicationTypes.STORE_NAVIGATE_ACTION, { key:'/student' })
    sendMessage(CommunicationTypes.CHANGE_LANG, {lang: currentLocale})
  },[embeddedModuleLoaded])
  
  useEffect(() => {
  
    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);
  
  return (
    <PageStructure
      loading={!embeddedModuleLoaded}
      mainContent={
        <ContentWrapper>
            <iframe
              title='logs'
              src={config.IFRAME_LOGS}
              ref={embeddedModuleRef}
              scrolling="no"
              width="100%"
              height={embeddedModuleHeight}
              style={{ resize: 'inherit' }}
              frameBorder="0"
            ></iframe>
        </ContentWrapper>
      }
    />
  )
}

export default LogsStudent

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  // to compensate PageStructure default margin-top
  margin-top: -20px;
`;