/**
 * NewCertificateStatement container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { actions } from 'store/rootSlices';
import { t } from 'app';

import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPlus, fal, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { useFormContext } from 'react-hook-form';

import { Grid } from 'semantic-ui-react';
import {
  AnimatedBackground,
  Text,
  Segment,
  Button as ButtonComponent,
  Tooltip,
} from '@paco_ua/pacoui';
import { getFinalResults } from '../../utils';
import { IFormPostValues } from '../../types';

import numeral from 'numeral';

import API from 'api';
import { useWarningModal } from '../../hooks/useWarningModal';

const NewCertificateStatement = ({
  formElements,
  formElementsData,
  formElementsWatch,
  setLoadingFile,
  resetField,
}) => {
  const theme = useContext(ThemeContext);

  const user = useSelector(selectors.getUser);
  const newCertificateData = useSelector(selectors.getNewCertificateData);
  const newCertificateNumAdditionalDocs = useSelector(selectors.getNewCertificateNumAdditionalDocs);
  const newCertificateSlice = useSelector(selectors.getNewCertificateSlice);
  const { showToaster } = actions.Toaster;

  const {
    setLoadingStatus,
    setErrorStatus,
    setExempt,
    setEnglishWarning,
    setIsDigital,
    postCertificateChosenOptions,
    addDocumentToFormSchema,
    addAdditionalDoc,
    remAdditionalDoc,
    setNumAdditionalDocs,
    removeDocumentFromFormSchema,
    setCertificateCosts,
    setNeedsRequirement,
    clearWarnings,
  } = actions.NewCertificate;

  const DEFAULT_MAX_ADDITIONAL_DOCS = 3;

  const [maxAdditionalDocuments, setMaxAdditionalDocuments] = useState(DEFAULT_MAX_ADDITIONAL_DOCS);
  const [uploadingAdditionalFile, setUploadingAdditionalFile] = useState(false);

  const { form } = newCertificateData;
  const { selectedCertificatePreviewUrl, loadingStatus, errorStatus } = newCertificateSlice;

  const {
    formState: { errors },
  } = useFormContext();
  const { checkActiveCertificates } = useWarningModal({ form });

  const dispatch = useDispatch();

  const [loadingCertificateModel, setLoadingCertificateModel] = useState(false);

  const registration =
    user.registrations &&
    user.registrations.find(
      (registration) => registration.registrationId === newCertificateData.registrationId
    );

  // Knowing if it's safe to render the form
  const formElementsReady = () => {
    if (
      Object.keys(formElements).length !== 0 &&
      formElements.constructor === Object &&
      Object.keys(formElementsData).length !== 0 &&
      formElementsData.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  };

  // To-do: typechecking
  const customFormElements = Object.keys(formElements).reduce(
    (acc, key) => {
      if (form) {
        const found = Object.keys(form).find(
          (k) =>
            form[k].values &&
            form[k].values.length &&
            form[k].values.find((value) => value.nameKey === key)
        );
        if (found) {
          acc[found] = [...acc[found], { nameKey: key, element: formElements[key] }];
        }
      }

      return acc;
    },
    {
      requestedCertificate: [] as any,
      additionalDocuments: [] as any,
      details: [] as any,
      mandatoryDocuments: [] as any,
    } // the order is important
  );

  // To-do: typechecking
  const customFormElementsData = Object.keys(formElementsData).reduce(
    (acc, key) => {
      if (form) {
        const found = Object.keys(form).find(
          (k) =>
            form[k].values &&
            form[k].values.length &&
            form[k].values.find((value) => value.nameKey === key)
        );
        if (found) {
          acc[found] = [...acc[found], formElementsData[key]];
        }
      }

      return acc;
    },
    {
      requestedCertificate: [] as any,
      additionalDocuments: [] as any,
      details: [] as any,
      mandatoryDocuments: [] as any,
    } // the order is important
  );

  useEffect(() => {
    let nNonOtherDocuments = 0;
    customFormElementsData.additionalDocuments.forEach((document) => {
      if (!document.nameKey.includes('otherDocuments')) {
        nNonOtherDocuments++;
      }
    });

    setMaxAdditionalDocuments(nNonOtherDocuments + DEFAULT_MAX_ADDITIONAL_DOCS);

    let nOtherDocuments = 1;

    if (newCertificateNumAdditionalDocs > 1) {
      nOtherDocuments = newCertificateNumAdditionalDocs - nNonOtherDocuments;
    }

    dispatch(setNumAdditionalDocs(nOtherDocuments + nNonOtherDocuments));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormElementsData.additionalDocuments]);

  const getUcsTableNamekeys = (formElementsData, formElementsWatch): any => {
    const ucsNamekeysList = [] as any;

    //if has one or more dpUcs with unavailable dps but has other dpUcs with dp available
    let needsRequirement = false;

    Object.keys(formElementsData).forEach((namekey) => {
      if (formElementsData[namekey].placeholderKey === 'ucListPlaceholder') {
        ucsNamekeysList.push(namekey);

        const ucs = formElementsData[namekey].values;
        const selectedUcs = formElementsWatch(namekey);

        selectedUcs.forEach((selectedUc) => {
          if (
            ucs.find((uc) => uc.nameKey.isAvailable === false && selectedUc === uc.nameKey.ucCode)
          ) {
            needsRequirement = true;
          }
        });
      }
    });

    let tableNamekeysList;
    if (Object.entries(formElementsData).length !== 0) {
      ucsNamekeysList.forEach((nk) => {
        if (formElementsWatch(nk) !== null && formElementsWatch(nk) !== undefined) {
          tableNamekeysList = formElementsWatch(nk);
        }
      });
    }

    if (needsRequirement) {
      dispatch(setNeedsRequirement(true));
    } else {
      dispatch(clearWarnings());
    }

    return tableNamekeysList;
  };

  const [activatedUcsList, setActivatedUcsList] = useState({} as any);
  const watchElements = formElementsWatch();

  const conditionalFormValidation = useCallback(
    (row, formValues): boolean => {
      //the condition is if the language chosen is english and the year is before 2012
      if (
        formValues.language === 1 &&
        row.nameKey['isAvailableEn'] === false &&
        row.nameKey['genericBlock'] === false
      ) {
        return true;
      }

      return false;
    },
    [watchElements.language]
  );

  const getWarning = useCallback(
    (row, formValues) => {
      let warning = {
        warning: null,
        message: null,
      };

      Object.keys(row.nameKey)
        .filter((key) => key === 'genericBlock' || key === 'isAvailable')
        .forEach((value) => {
          if (value === 'isAvailable' && row.nameKey[value] === false) {
            warning = {
              warning: t(`certificates.reqNeedWarning`),
              message: t(`certificates.reqNeedMessage`),
            };
          } else if (value === 'genericBlock' && row.nameKey[value] === true) {
            warning = {
              warning: t(`certificates.certGenericWarning`),
              message: t(`certificates.certGenericMessage`),
            };
          }
        });

      if (warning.message !== null) {
        return warning;
      } else {
        return null;
      }
    },
    [watchElements.language]
  );

  // used to show a warning for requested certificates in english
  useEffect(() => {
    //it means the format is english
    if (watchElements.language === 1) {
      dispatch(setEnglishWarning(true));
    } else {
      dispatch(setEnglishWarning(false));
    }

    resetField('0/1');
  }, [watchElements.language]);

  // used to show a warning for requested certificates in digital
  useEffect(() => {
    //it means the format is digital
    if (watchElements.format === 0) {
      dispatch(setIsDigital(true));
    } else {
      dispatch(setIsDigital(false));
    }

    resetField('0/1');
  }, [watchElements.format]);

  // used to update the cost after changes in the form checkbox table
  useEffect(() => {
    const activatedTableNameKey = getUcsTableNamekeys(formElementsData, formElementsWatch);
    setActivatedUcsList(activatedTableNameKey);
    checkActiveCertificates(watchElements);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formElementsWatch()]);

  useEffect(() => {
    if (
      (customFormElementsData !== null &&
        customFormElementsData !== undefined &&
        customFormElements !== null &&
        customFormElements !== undefined &&
        form !== null &&
        form !== undefined &&
        watchElements.format !== null) ||
      (watchElements.pageCounter !== undefined && watchElements.deliveryMethod !== undefined)
    ) {
      const formChosenOptions: IFormPostValues | null = getFinalResults(
        form,
        watchElements,
        newCertificateSlice.radioBtnMappings
      );

      dispatch(postCertificateChosenOptions(formChosenOptions));

      // Update exemption for the submission button in step 4
      dispatch(setExempt(Boolean(watchElements.exemption)));
    } else {
      dispatch(setCertificateCosts({ costs: [], totalCost: 0 }));
      dispatch(
        setLoadingStatus({
          fieldName: 'costLoading',
          fieldValue: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    form,
    watchElements.format,
    watchElements.pageCounter,
    watchElements.exemption,
    activatedUcsList,
    //we might need to add deliveryMethod
  ]);

  const getChildren = (formElements, formElementsData, groupName) =>
    Object.keys(formElements).reduce((acc: any, key: any) => {
      const FormElement = formElements[key];
      const nameKey = key;

      const { hidden, type, placeholderKey } = formElementsData[key];

      if (hidden) {
        return undefined;
      }

      if (type === 'textbox') {
        return {
          ...acc,
          [nameKey]: (
            <FormElement
              border
              placeholder={t(`certificates.${placeholderKey}`, {
                textOnly: true,
              })}
              error={
                errors[groupName] &&
                errors[groupName][nameKey] &&
                t('forms.' + errors[groupName][nameKey].message, {
                  textOnly: true,
                })
              }
            />
          ),
        };
      }

      return acc;
    }, {});

  if (
    loadingStatus.tabLoading ||
    errorStatus.errLoadingCertificateForm ||
    !form ||
    !formElementsReady()
  ) {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
        <Segment>
          <LoadingWrapper>
            <AnimatedBackgroundTitle height={theme.sizes.large} width="60%" />
            <AnimatedBackground height={theme.sizes.large} width="20%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
          </LoadingWrapper>
        </Segment>
      </Grid.Column>
    );
  } else {
    return (
      <>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
          <Segment>
            <TitleWrapper>
              <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                {t('certificates.requestThirdStepTitle')}
              </Text>
              <DescriptionText size="article" color={'darkGrey'} weight={'regular'}>
                {t('certificates.requestThirdStepDescription')}
              </DescriptionText>
            </TitleWrapper>
            <CertificateData>
              <CertificateDataNameAndCourse>
                <CertificateDataBlock>
                  <Text size="xSmall" color={'grey'} weight={'regular'}>
                    {t('certificates.requestThirdStepName')}
                  </Text>
                  <DataText size="mediumSmall" color={'plusDarkGrey'} weight={'regular'}>
                    {form.studentName}
                  </DataText>
                </CertificateDataBlock>
                <CertificateDataBlock>
                  <Text size="xSmall" color={'grey'} weight={'regular'}>
                    {t('certificates.requestThirdStepCourse')}
                  </Text>
                  <DataText size="mediumSmall" color={'plusDarkGrey'} weight={'regular'}>
                    {registration && `${registration.courseCode} - ${registration.courseName}`}
                  </DataText>
                </CertificateDataBlock>
              </CertificateDataNameAndCourse>
              <CertificateDataCertificateType>
                <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                  {t('certificates.requestedCertificate')}
                </Text>
                <CertificateSegment>
                  <Segment padding={'17px'}>
                    <SegmentContent>
                      <SegmentDescription>
                        <Text size="mediumSmall" color={'plusDarkGrey'} weight={'regular'}>
                          {newCertificateSlice.selectedCertificateType.name}
                        </Text>
                        <SegmentSubtitleText size="xSmall" color={'grey'} weight={'regular'}>
                          {newCertificateSlice.selectedCertificateType.description}
                        </SegmentSubtitleText>
                      </SegmentDescription>
                      {selectedCertificatePreviewUrl !== '' && (
                        <ModelWrapper>
                          <Text
                            size="xSmall"
                            loading={loadingCertificateModel}
                            loadingProps={{ width: '96px' }}
                            color={'plusDarkGrey'}
                            weight={'regular'}
                            as={'a'}
                            onClick={() => {
                              if (
                                !loadingCertificateModel &&
                                selectedCertificatePreviewUrl !== ''
                              ) {
                                setLoadingCertificateModel(true);
                                try {
                                  API.files.getFileOnTab
                                    .call(selectedCertificatePreviewUrl)
                                    .then((value) => {
                                      setLoadingCertificateModel(false);
                                    })
                                    .catch((err) => {
                                      setLoadingCertificateModel(false);
                                      dispatch(
                                        showToaster({
                                          title: t(
                                            'secVirtualNotifications.general_errorLoadingFile'
                                          ),
                                          icon: 'error',
                                          type: 'danger',
                                        })
                                      );
                                    });
                                } catch (e) {
                                  setLoadingCertificateModel(false);
                                  dispatch(
                                    actions.Toaster.showToaster({
                                      title: t('secVirtualNotifications.general_errorLoadingFile'),
                                      icon: 'error',
                                      type: 'danger',
                                    })
                                  );
                                }
                              }
                            }}
                          >
                            {t('certificates.visualizeModel')}
                          </Text>
                        </ModelWrapper>
                      )}
                    </SegmentContent>
                  </Segment>
                </CertificateSegment>
              </CertificateDataCertificateType>
            </CertificateData>

            <CertificateStatement>
              {customFormElementsData?.requestedCertificate &&
                customFormElementsData?.requestedCertificate?.find(
                  (field) => field['nameKey'] === 'exemption'
                ) && (
                  <Segment>
                    {Object.keys(customFormElementsData).map((field, index) => {
                      if (field === 'requestedCertificate') {
                        return (
                          customFormElements.requestedCertificate &&
                          customFormElements.requestedCertificate.map(
                            ({ element, nameKey }, key) => {
                              let FormElement = element;
                              //const defaultValue = formElementsData[key].value;
                              const type = customFormElementsData.requestedCertificate[key].type;
                              const hidden =
                                customFormElementsData.requestedCertificate[key].hidden;
                              if (type === 'radio_button') {
                                const options =
                                  customFormElementsData.requestedCertificate[key].values;

                                if (hidden) {
                                  return [];
                                }

                                return (
                                  <div
                                    key={`certificateStatementRequestedCertificate-${index}-${nameKey}`}
                                  >
                                    {customFormElementsData.requestedCertificate[key].labelKey && (
                                      <ExemptionWrapper>
                                        <Text size="small" color={'darkGrey'} weight={'regular'}>
                                          {t(
                                            `certificates.${customFormElementsData.requestedCertificate[key].labelKey}`
                                          )}
                                        </Text>
                                        <Text size="small" color={'red'} weight={'regular'}>
                                          {t('*')}
                                        </Text>
                                        <Tooltip
                                          content={t('certificates.costNoteDescription')}
                                          trigger={
                                            <FontAwesomeIcon icon={faInfoCircle} size={'lg'} />
                                          }
                                          position="right center"
                                        />
                                      </ExemptionWrapper>
                                    )}

                                    <ExemptionRadioListWrapper>
                                      <FormElement
                                        options={options.map((option) => ({
                                          name: option.nameKey,
                                          valueId: option.valueId,
                                          label: t(`certificates.${option.nameKey}`, {
                                            textOnly: true,
                                          }),
                                        }))}
                                        name={nameKey}
                                        error={
                                          errors[
                                            customFormElementsData.requestedCertificate[key].nameKey
                                          ] &&
                                          t(
                                            `forms.${
                                              errors[
                                                customFormElementsData.requestedCertificate[key]
                                                  .nameKey
                                              ].message
                                            }`,
                                            {
                                              textOnly: true,
                                            }
                                          )
                                        }
                                      />
                                    </ExemptionRadioListWrapper>
                                  </div>
                                );
                              } else if (type === 'dropdown') {
                                //populate options first
                                const dropdownOptions = [] as {
                                  key: string;
                                  nameKey: string;
                                  valueId: string;
                                }[];

                                customFormElementsData.requestedCertificate[key].values.forEach(
                                  (option) => {
                                    dropdownOptions.push({
                                      key: `option_${option.valueId}`,
                                      nameKey: option.nameKey,
                                      valueId: option.valueId,
                                    });
                                  }
                                );

                                //check after if its hidden
                                if (hidden) {
                                  return [];
                                }

                                return (
                                  <DropdownContainer
                                    key={`certificateStatementRequestedCertificate-${index}-${nameKey}`}
                                  >
                                    <ExemptionReasonWrapper>
                                      <Text size="small" color={'darkGrey'} weight={'regular'}>
                                        {t(
                                          `certificates.${customFormElementsData.requestedCertificate[key].nameKey}`
                                        )}
                                      </Text>
                                      <Text size="small" color={'red'} weight={'regular'}>
                                        {t('*')}
                                      </Text>
                                    </ExemptionReasonWrapper>
                                    <DropdownWrapper
                                      key={`formElementWrapper-${customFormElementsData.requestedCertificate[key].nameKey}`}
                                    >
                                      <FormElement
                                        placeholder={t(
                                          'applications.requestThirdStepStatementDropdownPlaceholder',
                                          {
                                            textOnly: true,
                                          }
                                        )}
                                        options={dropdownOptions}
                                        error={
                                          errors[
                                            customFormElementsData.requestedCertificate[key].nameKey
                                          ] &&
                                          t(
                                            `forms.${
                                              errors[
                                                customFormElementsData.requestedCertificate[key]
                                                  .nameKey
                                              ].message
                                            }`,
                                            {
                                              textOnly: true,
                                            }
                                          )
                                        }
                                      />
                                    </DropdownWrapper>
                                  </DropdownContainer>
                                );
                              } else if (type === 'textbox' || type === 'big_textbox') {
                                if (hidden) {
                                  return [];
                                }

                                return (
                                  <FormElement
                                    key={`certificateStatementRequestedCertificate-${index}-${nameKey}`}
                                    placeholder={t(
                                      `certificates.${customFormElementsData.requestedCertificate[key].placeholderKey}`,
                                      {
                                        textOnly: true,
                                      }
                                    )}
                                    error={
                                      errors[
                                        customFormElementsData.requestedCertificate[key].nameKey
                                      ] &&
                                      t(
                                        `forms.${
                                          errors[
                                            customFormElementsData.requestedCertificate[key].nameKey
                                          ].message
                                        }`,
                                        {
                                          textOnly: true,
                                        }
                                      )
                                    }
                                  />
                                );
                              }
                            }
                          )
                        );
                      } else if (field === 'additionalDocuments') {
                        return (
                          customFormElements.additionalDocuments &&
                          customFormElements.additionalDocuments.map(
                            ({ element, nameKey }, key) => {
                              let FormElement = element;
                              //const defaultValue = formElementsData[key].value;
                              const type = customFormElementsData.additionalDocuments[key].type;
                              const hidden = customFormElementsData.additionalDocuments[key].hidden;

                              const maxSize =
                                customFormElementsData.additionalDocuments[key] &&
                                customFormElementsData.additionalDocuments[key].validations.find(
                                  (validation) => validation.type === 'maxFileSize'
                                );

                              const minSize =
                                customFormElementsData.additionalDocuments[key] &&
                                customFormElementsData.additionalDocuments[key].validations.find(
                                  (validation) => validation.type === 'minFileSize'
                                );

                              const acceptTypes =
                                customFormElementsData.additionalDocuments[key] &&
                                customFormElementsData.additionalDocuments[key].validations.find(
                                  (validation) => validation.type === 'fileType'
                                );

                              if (hidden) {
                                return [];
                              }

                              return (
                                <AdditionalDocumentsWrapper key={`additionalDocs-${key}`}>
                                  {key === 0 && (
                                    <AdditionalDocumentsTitle>
                                      <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                                        {t(`certificates.${nameKey}`, { textOnly: true })}
                                      </Text>
                                    </AdditionalDocumentsTitle>
                                  )}
                                  <FileUploaderWrapper key={`fileUploaderWrapper-${index}`}>
                                    <FormElement
                                      nameKey={nameKey}
                                      icon={<FontAwesomeIcon icon={faFilePdf} />}
                                      heading={t(
                                        'certificates.documentsPlaceholder-otherDocument',
                                        {
                                          textOnly: true,
                                        }
                                      )}
                                      description={
                                        formElementsData[nameKey].placeholderKey.includes(
                                          'otherDocumentsPlaceholder'
                                        )
                                          ? t(
                                              `certificates.${formElementsData[nameKey].placeholderKey}`
                                            )
                                          : formElementsData[nameKey].placeholderKey
                                      }
                                      studentId={user.nmec}
                                      registrationId={newCertificateData.registrationId}
                                      error={
                                        errors[
                                          customFormElementsData.additionalDocuments[key].nameKey
                                        ] &&
                                        t(
                                          `forms.${
                                            errors[
                                              customFormElementsData.additionalDocuments[key]
                                                .nameKey
                                            ].message
                                          }`,
                                          {
                                            textOnly: true,
                                          }
                                        )
                                      }
                                      enableRemoveField={
                                        key === 0 ||
                                        key <= maxAdditionalDocuments - DEFAULT_MAX_ADDITIONAL_DOCS
                                          ? false
                                          : true
                                      }
                                      onRemoveField={
                                        key === 0 ||
                                        key <= maxAdditionalDocuments - DEFAULT_MAX_ADDITIONAL_DOCS
                                          ? undefined
                                          : () => {
                                              const payload = {
                                                form: form,
                                                key: nameKey,
                                              };
                                              if (
                                                newCertificateNumAdditionalDocs >
                                                maxAdditionalDocuments - DEFAULT_MAX_ADDITIONAL_DOCS
                                              ) {
                                                dispatch(removeDocumentFromFormSchema(payload));
                                                dispatch(remAdditionalDoc()); // counter
                                              }
                                            }
                                      }
                                      setUploadingFlag={(nameKey: string, value: boolean) => {
                                        setUploadingAdditionalFile(value);
                                        setLoadingFile((currentValue) => {
                                          return {
                                            ...currentValue,
                                            [nameKey]: value,
                                          };
                                        });
                                      }}
                                      maxSize={maxSize && maxSize.params[0]}
                                      minSize={minSize && minSize.params[0]}
                                      accept={acceptTypes && acceptTypes.params[0]}
                                    />
                                  </FileUploaderWrapper>
                                  <Button
                                    disabled={
                                      uploadingAdditionalFile ||
                                      newCertificateNumAdditionalDocs >= maxAdditionalDocuments
                                    }
                                    onClick={() => {
                                      if (
                                        newCertificateNumAdditionalDocs < maxAdditionalDocuments
                                      ) {
                                        dispatch(addDocumentToFormSchema(form));
                                        dispatch(addAdditionalDoc());
                                      }
                                    }}
                                    primary
                                  >
                                    <Text
                                      size="xSmall"
                                      color={'plusDarkGrey'}
                                      weight={'regular'}
                                      icon={<FontAwesomeIcon icon={faPlus} size={'1x'} />}
                                    >
                                      {t('certificates.addDocument')}
                                    </Text>
                                  </Button>
                                </AdditionalDocumentsWrapper>
                              );
                            }
                          )
                        );
                      } else {
                        return [];
                      }
                    })}
                  </Segment>
                )}
            </CertificateStatement>

            <CertificateStatement>
              <Row paddingtop={'0px'} paddingbottom={'0px'}>
                <Segment>
                  <LanguageTitleWrapper>
                    <Text size="large" color={'plusDarkGrey'} weight={'regular'}>
                      {t('certificates.languageFormatsTitle')}
                    </Text>
                  </LanguageTitleWrapper>
                  {Object.keys(customFormElementsData).map((field, index) => {
                    if (field === 'details') {
                      return (
                        customFormElements[field] &&
                        customFormElements[field].map(({ element, nameKey }, key) => {
                          let FormElement = element;

                          //const defaultValue = formElementsData[key].value;
                          const type = customFormElementsData.details[key].type;
                          const hidden = customFormElementsData.details[key].hidden;

                          if (type === 'radio_button') {
                            const options = customFormElementsData.details[key].values;
                            if (hidden) {
                              return [];
                            }

                            return (
                              <CertificateStatementRequest
                                key={`certificateStatementDetails-${index}-${nameKey}`}
                              >
                                {customFormElementsData.details[key].labelKey && (
                                  <ExemptionWrapper>
                                    <Text size="small" color={'darkGrey'} weight={'regular'}>
                                      {t(
                                        `certificates.${customFormElementsData.details[key].labelKey}`
                                      )}
                                    </Text>
                                    <Text size="small" color={'red'} weight={'regular'}>
                                      {t('*')}
                                    </Text>
                                  </ExemptionWrapper>
                                )}

                                <ExemptionRadioListWrapper>
                                  <FormElement
                                    options={options.map((option) => ({
                                      name: option.nameKey,
                                      valueId: option.valueId,
                                      label: option.nameKey,
                                      descriptionLabel: option.descriptionKey
                                        ? option.descriptionKey
                                        : undefined,
                                    }))}
                                    name={nameKey}
                                    error={
                                      errors[customFormElementsData.details[key].nameKey] &&
                                      t(`forms.validationFieldRequired`, {
                                        //HAMMER: yup might be returning a type error validation because the default value is null and the validationtype is number
                                        textOnly: true,
                                      })
                                    }
                                  />
                                </ExemptionRadioListWrapper>
                              </CertificateStatementRequest>
                            );
                          } else if (type === 'textbox') {
                            if (hidden) {
                              return [];
                            }

                            return (
                              <FormElement
                                key={`certificateStatementDetails-${index}-${nameKey}`}
                                placeholder={t(
                                  `certificates.${customFormElementsData.details[key].placeholderKey}`,
                                  {
                                    textOnly: true,
                                  }
                                )}
                                error={
                                  errors[customFormElementsData.details[key].nameKey] &&
                                  t(
                                    `forms.${
                                      errors[customFormElementsData.details[key].nameKey].message
                                    }`,
                                    {
                                      textOnly: true,
                                    }
                                  )
                                }
                              />
                            );
                          } else if (type === 'counter') {
                            if (hidden) {
                              return [];
                            }

                            return (
                              <PageCounterWrapper
                                key={`certificateStatementDetails-${index}-${nameKey}`}
                              >
                                {customFormElementsData.details[key].nameKey && (
                                  <ExemptionWrapper>
                                    <Text size="small" color={'darkGrey'} weight={'regular'}>
                                      {t(
                                        `certificates.${customFormElementsData.details[key].nameKey}`
                                      )}
                                    </Text>
                                    <Text size="small" color={'red'} weight={'regular'}>
                                      {t('*')}
                                    </Text>
                                  </ExemptionWrapper>
                                )}
                                <FormElement
                                  defaultValue={customFormElementsData.details[key].value} //TODO to remove
                                  error={
                                    errors[customFormElementsData.details[key].nameKey] &&
                                    t(
                                      `forms.${
                                        errors[customFormElementsData.details[key].nameKey].message
                                      }`,
                                      {
                                        textOnly: true,
                                      }
                                    )
                                  }
                                />
                              </PageCounterWrapper>
                            );
                          } else if (type === 'editableGroupComponent') {
                            if (hidden) {
                              return [];
                            }

                            FormElement = element.value;
                            const FormChildren = element?.values
                              ? getChildren(
                                  element?.values,
                                  customFormElementsData.details[key].values,
                                  nameKey
                                )
                              : undefined;

                            return (
                              <EditableGroupWrapper
                                key={`certificateStatementDetails-${index}-${nameKey}`}
                              >
                                <LineSeparator />
                                <FormElement
                                  //name={nameKey}
                                  padding={'10px'}
                                  title={t(
                                    `certificates.${customFormElementsData.details[key].nameKey}`,
                                    {
                                      textOnly: true,
                                    }
                                  )}
                                  values={customFormElementsData.details[key].values}
                                  labels={(nameKeys) => {
                                    return nameKeys.reduce((acc, itemNameKey) => {
                                      return {
                                        ...acc,
                                        [itemNameKey]: t(`certificates.${itemNameKey}`, {
                                          textOnly: true,
                                        }),
                                      };
                                    }, {});
                                  }}
                                  children={FormChildren}
                                  errors={(nameSubKeys) => {
                                    const nameKey = customFormElementsData.details[key].nameKey;

                                    return nameSubKeys.reduce((acc, itemNameKey) => {
                                      if (errors[nameKey] && errors[nameKey][itemNameKey]) {
                                        return {
                                          ...acc,
                                          [itemNameKey]: t(
                                            `forms.${errors[nameKey][itemNameKey].message}`,
                                            {
                                              textOnly: true,
                                            }
                                          ),
                                        };
                                      }
                                      return acc;
                                    }, {});
                                  }}
                                />
                                {errors[customFormElementsData.details[key].nameKey] && (
                                  <Text
                                    style={{ marginTop: '20px' }}
                                    size="xSmall"
                                    weight="regular"
                                    color="dangerRed"
                                  >
                                    {t(`certificates.impersonateEmailError`)}
                                  </Text>
                                )}
                              </EditableGroupWrapper>
                            );
                          }
                        })
                      );
                    } else {
                      return [];
                    }
                  })}
                </Segment>
              </Row>
            </CertificateStatement>

            <CertificateStatement>
              {Object.keys(customFormElementsData).map((field, index) => {
                if (field === 'requestedCertificate') {
                  return (
                    customFormElements.requestedCertificate &&
                    customFormElements.requestedCertificate.map(({ element, nameKey }, key) => {
                      let FormElement = element;
                      //const defaultValue = formElementsData[key].value;
                      const type = customFormElementsData.requestedCertificate[key].type;
                      const hidden = customFormElementsData.requestedCertificate[key].hidden;
                      if (type === 'checkbox_table') {
                        if (hidden) {
                          return [];
                        }

                        // Concatenate the arrays inside the objects
                        let concatenatedArray = customFormElementsData.requestedCertificate
                          .filter((rq) => rq.type === 'checkbox_table')
                          .reduce((result, obj) => {
                            // Use the spread operator (...) to concatenate arrays
                            return result.concat(obj.values);
                          }, []);

                        return (
                          <CheckboxContainer
                            key={`certificateStatementRequestedCertificate-${index}-${nameKey}`}
                          >
                            <TableTitleWrapper>
                              <Text size="large" color={'plusDarkGrey'} weight={'regular'}>
                                {t('certificates.frequentedCurricularUnit', { textOnly: true })}
                              </Text>
                            </TableTitleWrapper>
                            <FrequentedUcsTableWrapper>
                              <FormElement
                                disabledAccordion="true"
                                structure={{
                                  header: {
                                    type: 'columnTitle',
                                    titles: Object.keys(
                                      concatenatedArray[0].nameKey // supposing the keys for each uc are equal
                                    )
                                      .filter(
                                        (col) =>
                                          col !== 'isAvailable' &&
                                          col !== 'genericBlock' &&
                                          col !== 'isAvailableEn'
                                      )
                                      .map((field) => {
                                        return {
                                          text: t(`certificates.${field}`, { textOnly: true }),
                                          style:
                                            field === 'ucName'
                                              ? { textAlign: 'left', fontWeight: 400 }
                                              : { textAlign: 'center', fontWeight: 400 },
                                        };
                                      }),
                                  },
                                  rows: concatenatedArray.map((row) => {
                                    return {
                                      disabled:
                                        conditionalFormValidation(row, watchElements) ||
                                        row.nameKey['genericBlock'] === true,

                                      value: row.valueId,
                                      rowProps: {
                                        color: theme.colors.white,
                                        textAlign: row.nameKey === 'ucCode' ? 'left' : 'center',
                                      },
                                      cells: Object.keys(row.nameKey)
                                        .filter(
                                          (key) =>
                                            key !== 'isAvailable' &&
                                            key !== 'genericBlock' &&
                                            key !== 'isAvailableEn'
                                        )
                                        .map((key) => {
                                          if (key === 'semester') {
                                            return {
                                              content:
                                                row.nameKey[key] === 0 ? (
                                                  <Text size="article" weight="regular">{`${t(
                                                    'certificates.anual',
                                                    {
                                                      textOnly: true,
                                                    }
                                                  )}`}</Text>
                                                ) : row.nameKey[key] === -1 ? (
                                                  <></>
                                                ) : (
                                                  <Text size="article" weight="regular">{`${numeral(
                                                    row.nameKey[key]
                                                  ).format('o')} ${t('certificates.semester', {
                                                    textOnly: true,
                                                  })}`}</Text>
                                                ),
                                              cellProps: { textAlign: 'center' },
                                            };
                                          } else if (key === 'ucCode') {
                                            return {
                                              content: (
                                                <Text size="article" weight="medium">
                                                  {row.nameKey[key]}
                                                </Text>
                                              ),
                                              cellProps: { textAlign: 'center' },
                                            };
                                          } else if (key === 'ucName') {
                                            return {
                                              content: (
                                                <>
                                                  <Text
                                                    size="article"
                                                    color="primary"
                                                    weight={'medium'}
                                                  >
                                                    {row.nameKey[key]}
                                                    {conditionalFormValidation(
                                                      row,
                                                      watchElements
                                                    ) && (
                                                      <WarningIcon>
                                                        <FontAwesomeIcon
                                                          icon={faInfoCircle}
                                                          size={'sm'}
                                                        />
                                                      </WarningIcon>
                                                    )}
                                                  </Text>
                                                  {getWarning(row, watchElements) !== null &&
                                                    conditionalFormValidation(
                                                      row,
                                                      watchElements
                                                    ) !== true && (
                                                      <WarningContainer>
                                                        <div>
                                                          <Text weight="bold">
                                                            <FontAwesomeIcon
                                                              size="sm"
                                                              color={theme.colors.decisionYellow}
                                                              icon={faExclamationTriangle}
                                                            />
                                                            {
                                                              getWarning(row, watchElements)
                                                                ?.warning
                                                            }
                                                          </Text>
                                                          <Text size="small">
                                                            {
                                                              getWarning(row, watchElements)
                                                                ?.message
                                                            }
                                                          </Text>
                                                        </div>
                                                      </WarningContainer>
                                                    )}
                                                </>
                                              ),
                                              cellProps: {
                                                textAlign: 'left',
                                                ...(getWarning(row, watchElements) !== null &&
                                                conditionalFormValidation(row, watchElements) !==
                                                  true
                                                  ? {
                                                      className: 'dp-warning',
                                                    }
                                                  : null),
                                              },
                                            };
                                          } else {
                                            return {
                                              content: (
                                                <Text size="article" weight="regular">
                                                  {row.nameKey[key]}
                                                </Text>
                                              ),
                                              cellProps: { textAlign: 'center' },
                                            };
                                          }
                                        }),
                                    };
                                  }),
                                }}
                                error={
                                  errors[
                                    customFormElementsData.requestedCertificate[key].nameKey
                                  ] &&
                                  t(
                                    `forms.${
                                      errors[
                                        customFormElementsData.requestedCertificate[key].nameKey
                                      ].message
                                    }`,
                                    {
                                      textOnly: true,
                                    }
                                  )
                                }
                              />
                            </FrequentedUcsTableWrapper>
                          </CheckboxContainer>
                        );
                      }
                    })
                  );
                } /*else if (field === 'mandatoryDocuments') {
                        return <></>; // There aren't mandatory documents when requesting certificates
                      }*/ else {
                  return [];
                }
              })}
            </CertificateStatement>
          </Segment>
        </Grid.Column>
      </>
    );
  }
};

export default NewCertificateStatement;

const Row = styled.div<{ paddingtop?: string; paddingbottom?: string }>`
  padding-top: ${({ paddingtop }) => (paddingtop ? paddingtop : '1rem')};
  padding-bottom: ${({ paddingbottom }) => (paddingbottom ? paddingbottom : '1rem')};
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const CertificateData = styled.div`
  margin-top: 56px;
`;

const CertificateDataNameAndCourse = styled.div`
  display: flex;

  & > div {
    flex: 0 50%;
  }
`;

const PageCounterWrapper = styled.div`
  margin-top: 30px;
`;

const CertificateDataCertificateType = styled.div`
  margin-top: 50px;
`;

const CertificateDataBlock = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: 12px;
  }
`;

const CertificateSegment = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
`;

const TableTitleWrapper = styled.div`
  margin-bottom: 16px;
`;

const FileUploaderWrapper = styled.div`
  margin-top: 12px;
`;

const DropdownWrapper = styled.div`
  display: flex;
  margin: 16px 0px;
`;

const ExemptionReasonWrapper = styled.div`
  display: flex;

  div:nth-child(2) {
    margin: 0px 7px;
  }
  margin-top: 24px;
`;

const SegmentContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SegmentDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ExemptionWrapper = styled.div`
  display: flex;

  div:nth-child(2) {
    margin: 0px 7px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LineSeparator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.softRegularGrey};
  margin: 16px 0px;
`;

const LanguageTitleWrapper = styled.div`
  margin-bottom: 8px;
`;

const CertificateStatement = styled.div`
  margin-top: 20px;
`;

const CertificateStatementRequest = styled.div`
  margin-top: 30px;
`;

const ExemptionRadioListWrapper = styled.div`
  margin-top: 12px;
`;

const Button = styled(ButtonComponent)`
  margin-top: 20px;
`;

const AdditionalDocumentsTitle = styled.div`
  margin-top: 32px;
`;

const ModelWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const FrequentedUcsTableWrapper = styled.div`
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }

  &&& .dp-warning {
    height: unset !important;
    padding: 20px !important;
  }
`;

const AnimatedBackgroundTitle = styled(AnimatedBackground)`
  margin-bottom: 20px;
`;

const DescriptionText = styled(Text)`
  margin-top: 8px;
`;

const SegmentSubtitleText = styled(Text)`
  margin-top: 8px;
`;

const DropdownContainer = styled.div``;

const CheckboxContainer = styled.div``;

const AdditionalDocumentsWrapper = styled.div``;

const EditableGroupWrapper = styled.div``;

const DataText = styled(Text)`
  line-height: 1.3;
`;

const WarningContainer = styled.div`
  display: flex;
  height: 100%;

  >div: first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: auto;
    font-size: 13px;

    svg {
      padding-right: 4px;
    }

    span {
      font-size: 0.75rem !important;
    }
  }
`;

const WarningIcon = styled.span`
  padding-left: 8px;

  > svg {
    width: 1rem !important;
    color: ${({ theme }) => theme.colors.darkGrey} !important;
  }
`;
