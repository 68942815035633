/**
 * ScheduleListView container utils
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import moment from 'moment';
import { IUc, IScheduleClass, IDropdownOption } from 'shared/types';
import { getIrregularMessageSmall } from '../../utils';
const getClassTypeFullName = (type: string, classesTypologies) => {
  return classesTypologies.find((typology) => typology.initials === type).name;
};

const getWeekday = (dayNumber: number) => {
  return moment.weekdays()[dayNumber];
};

const removeSeconds = (time: string) => {
  return time.replace(/(.*)\D\d+/, '$1');
};

export const getFirstScheduleType = (uc, classesTypologies) => {
  let firstType = '';

  if (classesTypologies) {
    firstType = classesTypologies.find((typology) => {
      return uc.classSchedule.find(
        (classItem) => classItem.classType === typology.initials && !classItem.filtered
      );
    })?.initials;
  }

  debugger;

  return firstType;
};

export const getDropdownOptions = (
  uc: IUc,
  classType: string,
  classesTypologies,
  getIrregularMessage?: (value: string, infoDropdown: boolean) => any
) => {
  const dropdowOptions = uc.classSchedule.reduce(
    (typeClassesOption: IDropdownOption[], eachClass: IScheduleClass, index) => {
      const classAlreadyAccumulated = typeClassesOption.find((o) => o.id === eachClass.classId);
      if (!classAlreadyAccumulated && !eachClass.filtered && eachClass.classType === classType) {
        typeClassesOption.push({
          key: `${eachClass.classId}-${index}_${uc.ucId}_${uc.groupId || 'noGroup'}`,
          id: eachClass.classId,
          text: `${getClassTypeFullName(eachClass.classType, classesTypologies)}-${
            eachClass.className
          }`,
          value: eachClass.classId,
          disabled: eachClass.disabled,
        });

        if (eachClass.irregularSituation && getIrregularMessage) {
          const message = getIrregularMessageSmall(eachClass);

          typeClassesOption.push({
            key: `${eachClass.classId}-info-${index}_${uc.ucId}_${uc.groupId || 'noGroup'}`,
            id: eachClass.classId,
            text: getIrregularMessage(message, true),
            value: eachClass.classId,
            className: 'subItem info',
            disabled: true,
          });
        }

        const descriptions = uc.classSchedule.reduce((result: string[], element) => {
          if (eachClass.classId === element.classId) {
            result.push(
              `${getWeekday(element.weekday)} | ${removeSeconds(
                element.startTime
              )} - ${removeSeconds(element.endTime)}`
            );
          }
          return result;
        }, []);

        if (!!descriptions.length) {
          descriptions.forEach((description) => {
            typeClassesOption.push({
              key: `${description}-${eachClass.classId}-subItem-${index}_${uc.ucId}_${
                uc.groupId || 'noGroup'
              }`,
              value: eachClass.classId,
              text: description,
              className: 'subItem',
              disabled: eachClass.disabled,
            });
          });
        }
      }
      return typeClassesOption;
    },
    []
  );

  return dropdowOptions;
};

export const getDropdownValue = (ucs: IUc[], ucId: number, type: string, classesTypologies) => {
  //find uc that matches and is selected, having in mind thatt we have groups this works due to previous implement loging in other controllers

  let selectedClass = null as null | IScheduleClass;

  ucs.some((uc) => {
    const classToSelect = uc.classSchedule.find(
      (classItem) => classItem.selected && classItem.classType === type
    );
    if (uc.ucId === ucId && classToSelect) {
      selectedClass = classToSelect;
      return true;
    }
  });

  if (selectedClass) {
    return selectedClass.classId;
  } else {
    // undefined should be the returned value to 'reset' the input
    // however it doesn't clear the selection + show the placeholder
    // returning '' as suggested here
    // https://github.com/Semantic-Org/Semantic-UI-React/issues/3625
    return '';
  }
};

// Find 'allocated' property of the selected class
// and disable if allocated = true (already allocated so no need to re-select)
export const getAllocatedState = (ucs: IUc[], ucId: number, type: string) => {
  const uc = ucs.find((uc) => uc.ucId === ucId);
  if (uc) {
    const selectedClass = uc.classSchedule.find(
      (eachClass) => eachClass.classType === type && eachClass.selected
    );

    if (
      (selectedClass && selectedClass?.allocated.state === 'assigned') ||
      selectedClass?.allocated.state === 'automatic'
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// Get dropdown border color after final seriation
// to match the logic applied @ <ClassCards />
// (highlight it according to 'placed' property - automatic, manual and not placed)
export const getDropdownBorderColor = (
  ucs: IUc[],
  ucId: number,
  type: string,
  allocated: boolean,
  colors: { [key: string]: string }
) => {
  const uc = ucs.find((uc) => uc.ucId === ucId);
  if (uc) {
    const selectedClass = uc.classSchedule.find(
      (eachClass) => eachClass.classType === type && eachClass.selected
    );

    if (selectedClass) {
      if (selectedClass.allocated.state === 'automatic') {
        return colors.blue;
      } else if (selectedClass.allocated.state === 'assigned' || allocated) {
        return colors.green;
      }
    }
  }
};

export const getScheduleAndLockState = (
  ucs: IUc[],
  ucId: number,
  ucGroupId: number | null | undefined,
  type: string
) => {
  const uc = ucs.find((uc: IUc) => {
    if (ucGroupId !== undefined && ucGroupId !== null) {
      return uc.ucId === ucId && uc.groupId === ucGroupId;
    }
    return uc.ucId === ucId;
  });

  if (uc) {
    const selectedClasses = uc.classSchedule.reduce((selected: IScheduleClass[], eachClass) => {
      if (eachClass.classType === type && eachClass.selected) {
        selected.push(eachClass);
      }
      return selected;
    }, []);

    if (selectedClasses && selectedClasses.length) {
      return {
        allocation: selectedClasses[0].allocation,
        locked: selectedClasses[0].locked,
        schedule: selectedClasses.map((eachClass) => {
          return {
            weekDay: getWeekday(eachClass.weekday),
            start: removeSeconds(eachClass.startTime),
            end: removeSeconds(eachClass.endTime),
            locked: eachClass.locked,
          };
        }),
      };
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getFilteredUcForZommAtScheduleOptions = (
  uc: IUc | null,
  type: string,
  period: string
) => {
  if (uc) {
    // If there's a class already 'allocated', show it only
    // If there's no class 'allocated', show all classes for the selected typology
    const classSchedule = (): IScheduleClass[] => {
      // Get typologies (T, TP, P, etc) already w/ allocated classes
      const allocatedClassesTypologies = [
        ...new Set(
          uc.classSchedule
            .filter(
              (eachClass) =>
                eachClass.allocated.state === 'assigned' ||
                eachClass.allocated.state === 'automatic'
            )
            .map((allocatedClass) => allocatedClass.classType)
        ),
      ];

      // Filter all classes with classType included @ allocatedClassesTypologies that are not allocated
      // I mean: for class types with classes already 'allocated', show only that one
      return uc.classSchedule.reduce((filteredClasses, eachClass) => {
        if (
          allocatedClassesTypologies.includes(eachClass.classType) &&
          (eachClass.allocated.state === 'assigned' || eachClass.allocated.state === 'automatic')
        ) {
          filteredClasses.push(eachClass);
        } else if (!allocatedClassesTypologies.includes(eachClass.classType)) {
          filteredClasses.push(eachClass);
        }

        return filteredClasses;
      }, [] as IScheduleClass[]);
    };

    return {
      ...uc,
      classSchedule: classSchedule().map((eachClass) => {
        if (eachClass.period && (eachClass.classType !== type || eachClass.period !== period)) {
          return { ...eachClass, filtered: true };
        } else if (!eachClass.period && eachClass.classType !== type) {
          return { ...eachClass, filtered: true };
        } else {
          return { ...eachClass, filtered: false };
        }
      }),
    };
  } else {
    return uc;
  }
};
