/**
 * SelectDetails container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../../logic/selectors';
import { actions } from 'store/rootSlices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { Grid } from 'semantic-ui-react';
import { Text, Segment } from '@paco_ua/pacoui';

import { MbIcon, MbWayIcon /*, VisaIcon*/ } from 'shared/components/Icons';

import SelectedPayments from '../../components/SelectedPayments';

//import config from 'config'

const SelectPaymentMethod = ({ selectedDebits, totalCost, allowMbwayPayment }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const MBWAY_PAYMENT_LIMIT = 110; // euros

  const { paymentMethod } = useSelector(selectors.getPendingDebitsPaymentSlice);

  const { setPaymentMethod } = actions.PendingDebitsPayment;

  const handleMethodSelection = (type: string) => {
    if (type === paymentMethod) {
      dispatch(setPaymentMethod(undefined));
      return;
    }

    dispatch(setPaymentMethod(type));
  };

  return (
    <>
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
        {selectedDebits.length > 0 && (
          <>
            <SegmentTitleWrapper>
              <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
                {t('payments.performedSelection')}
              </Text>
            </SegmentTitleWrapper>

            <SelectedPayments selectedDebits={selectedDebits} />
          </>
        )}
        {selectedDebits.length === 0 && (
          <Segment>
            <MessageNoApplications>
              <Icon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
              <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                {t('payments.noDebitsSelect', { textOnly: true })}
              </Text>
            </MessageNoApplications>
          </Segment>
        )}
      </Grid.Column>

      <PaymentMethodColumn>
        <WrapperTitlePayment>
          <Text size="large" weight="medium">
            {t('payments.paymentMethodsTitle', { textOnly: true })}
          </Text>

          <Text size="article" weight="regular" color="darkGrey">
            {t('payments.selectPaymentMethodModalSubtitle', { textOnly: true })}
          </Text>
        </WrapperTitlePayment>

        <Options>
          <Option active={paymentMethod === 'mb'} onClick={() => handleMethodSelection('mb')}>
            <MbIcon width={40} height={40} />
            <Text size="article" weight="regular" color="darkGrey" transform="uppercase">
              {t('payments.atmReference', { textOnly: true })}
            </Text>
          </Option>
          <Option
            disabled={totalCost > MBWAY_PAYMENT_LIMIT || !allowMbwayPayment}
            disableCursor={totalCost > MBWAY_PAYMENT_LIMIT || !allowMbwayPayment}
            active={
              paymentMethod === 'mbway' && totalCost <= MBWAY_PAYMENT_LIMIT && allowMbwayPayment
            }
            onClick={() => handleMethodSelection('mbway')}
          >
            <MbWayIcon
              width={50}
              fill={
                totalCost > MBWAY_PAYMENT_LIMIT || !allowMbwayPayment
                  ? theme.colors.grey
                  : undefined
              }
            />
            <Text size="article" weight="regular" color="darkGrey" transform="uppercase">
              {t('payments.mbWay', { textOnly: true })}
            </Text>
          </Option>
          {/*
          <Option active={method === 'visa'} onClick={() => handleMethodSelection('visa')}>
            <VisaIcon width={50} />
            <Text size="article" weight="regular" color="darkGrey" transform="uppercase">
              {t('payments.creditCard', { textOnly: true })}
            </Text>
          </Option>
          */}
        </Options>

        {totalCost > MBWAY_PAYMENT_LIMIT && (
          <MbwayDisabledNote>
            <Text size="article" weight="regular" color="darkGrey">
              {t('payments.mbwayDisabledNote', { textOnly: true })}
            </Text>
          </MbwayDisabledNote>
        )}

        {!allowMbwayPayment && (
          <MbwayDisabledNote>
            <Text size="article" weight="regular" color="darkGrey">
              {t('payments.mbwayNotAllowedNote', { textOnly: true })}
            </Text>
          </MbwayDisabledNote>
        )}
      </PaymentMethodColumn>
    </>
  );
};

export default SelectPaymentMethod;

const WrapperTitlePayment = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const MbwayDisabledNote = styled.div``;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const MessageNoApplications = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const Option = styled.button<{
  active: boolean;
  disableCursor?: boolean;
}>`
  width: 140px;
  height: 140px;
  cursor: ${({ theme, disableCursor }) => (disableCursor ? 'default' : 'pointer')};
  background: none;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 12px;
  border: 0.5px solid ${({ theme }) => theme.colors.softGrey};

  div {
    margin-top: 16px;
  }

  // ACTIVE STYLE

  ${({ active, theme }) =>
    active &&
    `
    background: ${theme.colors.primary};
    border: none;

    * {
      path {
        fill: ${theme.colors.white};
      }
    }

    div {
      color: ${theme.colors.white};
    }
  `}

  // HOVER STYLE

  &:hover:not([disabled]) {
    background: ${({ theme }) => theme.colors.primary};
    border: none;

    * {
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }

    div {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  // RESPONSIVENESS

  @media (max-width: 590px) {
    width: 100%;
    height: 150px;
    margin-left: 0;
    margin-right: 0;
  }
`;

const Options = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  padding: 20px 0px;

  @media (max-width: 902px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media (max-width: 590px) {
    padding-top: 24px;
    padding-bottom: 24px;

    button {
      width: 100%;
    }
  }
`;

const PaymentMethodColumn = styled(Grid.Column)`
  margin-top: 40px;
`;
