import React, { useContext } from 'react';
import * as selectors from '../../logic/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import { AnimatedBackground, Modal, Table, Text } from '@paco_ua/pacoui';
import styled, { ThemeContext } from 'styled-components';
import { getAccessHistoryModalStructure } from '../../utils';
import { useTranslation } from 'react-i18next';

const AccessHistoryModal: React.FC = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const { loadingStatus, errorStatus, accessHistoryModalVisible, accessHistory } = useSelector(
    selectors.getCertificatesDetailsSlices
  );

  const { setVisibleAccessHistoryModal } = actions.CertificatesDetails;

  return (
    <Modal
      type="confirmation"
      open={accessHistoryModalVisible}
      header={
        <Text color="blackLight" size="xLarge" fontWeight="500">
          {t('certificates.changeHistory')}
        </Text>
      }
      onClose={() => {
        dispatch(setVisibleAccessHistoryModal(false));
      }}
      submitButtonText={t('generic.close')}
      onSubmit={() => {
        dispatch(setVisibleAccessHistoryModal(false));
      }}
    >
      <ModalStatement>
        {loadingStatus.loadingAccessHistory && (
          <>
            <AnimatedBackground height={theme.sizes.large} width="100%" />
          </>
        )}
        {!loadingStatus.loadingAccessHistory && (
          <TableWrapper>
            <Table
              loading={loadingStatus.loadingAccessHistory}
              unstackable
              structure={getAccessHistoryModalStructure(accessHistory)}
            />
          </TableWrapper>
        )}
      </ModalStatement>
    </Modal>
  );
};

export default AccessHistoryModal;

const ModalStatement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  & > div:nth-child(2) {
    margin-top: 12px;
  }
`;

const TableWrapper = styled.div`
  overflow: auto;
  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;
