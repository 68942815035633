/**
 * Created Schedule container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUpdateSchedule } from 'scenes/Schedules/CreatedSchedules/containers/CreatedSchedule/types';

const initialState = {
  isLoading: false,
};

export default createSlice({
  name: 'CreatedSchedule',
  initialState,
  reducers: {
    updateSchedule: (state, action: PayloadAction<IUpdateSchedule>) => {
      state.isLoading = true;
    },
  },
});
