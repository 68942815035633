/**
 * Help scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDropdownOption } from 'shared/types';
import { t } from 'app';
import { IParams } from 'api';

const initialState = {
  helpListing: [] as any,
  filterTypes: [] as IDropdownOption[],
  filterParams: {
    type: '' as string,
    search: '' as string,
  },
  loadingStatus: {
    loading: true as boolean,
    loadingSearch: true as boolean,
  },
  errorStatus: {
    errLoading: false,
  },
};

export default createSlice({
  name: 'Help',
  initialState,
  reducers: {
    onMount: () => {},
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    setHelpListing: (state, action: PayloadAction<any>) => {
      state.helpListing = action.payload;
    },
    setFilterTypes: (state, action: PayloadAction<any>) => {
      state.filterTypes = action.payload.map((filter, key) => {
        return {
          key: key,
          text: t(`routes.${filter}`, { textOnly: true }),
          value: filter,
        };
      });
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    onUnmount: () => {},
    getHelpListing: (state, action?: PayloadAction<IParams>) => {},
    reset: (state) => initialState,
  },
});
