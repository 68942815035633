import React, { useEffect, useState } from "react";
import Modal from "shared/components/Modal";
import styled from "styled-components";
import { Button, Text } from '@paco_ua/pacoui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesSquare, faExclamationSquare } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from "react-redux";
import * as selectors from '../logic/selectors';
import { t } from 'app';

const ErrorsModalHeader = styled.div`
display: flex;
justify-content: space-between;
margin-bottom: 1rem;
padding-bottom: 1rem;
border-bottom: 1px solid #ccc;
`

const ErrorsModalHeaderWrapper = styled.div`
display: flex;
align-items: center;
gap: 1rem;
`

const ErrorsModalItem = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 1rem;
max-height: 25rem;
overflow-y: auto;
`

const WarningText = styled(Text)`
  color: orange;
`;

interface Error {
  line: number;
  nmec: string;
  errors: string[];
}

interface ImportWarnings {
  warning1?: string[];
  warning2?: string[];
  warning3?: string[];
}

function flattenArray(arr: any[][]): any[] {
  return arr.reduce((acc, val) => acc.concat(val), []);
}

const ErrorsModal = ({ onClick }) => {
  const importErrors = useSelector(selectors.selectImportErrors);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const importWarnings: ImportWarnings = useSelector(selectors.selectImportWarnings);
  const [warningMessages, setWarningMessages] = useState<string[]>([]);

  useEffect(() => {
    const messages = importErrors.map((error: Error) => 
    error.errors.map(err => {
      const errorCode = err.replace(' ', '');
      const translationKey = `pautas.import${errorCode}`;
      return `${t('pautas.errorInLine', {textOnly: true})} ${error.line} (${error.nmec}) - ${t(translationKey, {textOnly: true})}`;
      })
    );
    setErrorMessages(flattenArray(messages));
    
    // Warning 1 - Student doesn't belong to the pauta
    // Warning 2 - Student grade was updated (if there was already a grade in the DB)
    // Warning 3 - Student grade was not updated because of empty value
    
    let localWarningMessages: string[] = [];
    if (importWarnings.warning1) {
      localWarningMessages.push(...importWarnings.warning1.map(nmec => `${t('pautas.studentWithNmec', {textOnly: true})} ${nmec} ${t('pautas.studentDoesntBelongToPauta', {textOnly: true})}`));
    }
    if (importWarnings.warning3 && importWarnings.warning3.length > 0) { // Warnings of type 3 should be shown before Warnings of type 2 because they are of higher importance
      importWarnings.warning3.forEach(warningJson => {
        try {
          const warningObj = JSON.parse(warningJson);
          localWarningMessages.push(`${t('pautas.changeDetectedForStudent', {textOnly: true})} ${warningObj.name} (NMEC ${warningObj.nmec}) ${t('pautas.gradeDidNotChange', {textOnly: true})}`);
        } catch (error) {
          console.error('Error parsing warning message:', error);
        }
      })
    }
    if (importWarnings.warning2 && importWarnings.warning2.length > 0) {
      importWarnings.warning2.forEach(warningJson => {
        try {
          const warningObj = JSON.parse(warningJson);
          localWarningMessages.push(`${t('pautas.changeDetectedForStudent', {textOnly: true})} ${warningObj.name} (NMEC ${warningObj.nmec}) ${t('pautas.newGrade', {textOnly: true})} ${warningObj.new_grade}, ${t('pautas.previousGrade', {textOnly: true})} ${warningObj.previous_grade}`);
        } catch (error) {
          console.error('Error parsing warning message:', error);
        }
      });
    }

    setWarningMessages(localWarningMessages);
  }, [importErrors, importWarnings]);


  return (
    <Modal changeModal={onClick}>
      <ErrorsModalHeader>
      {(importErrors.length > 0) && ( // If there are errors, show errors header
          <ErrorsModalHeaderWrapper>
            <FontAwesomeIcon icon={faTimesSquare} style={{ fontSize: '2.5rem', color: 'red' }}></FontAwesomeIcon>
            <Text color="red">{t('pautas.errorsModalHeader')}</Text>
          </ErrorsModalHeaderWrapper>
        )}
        {(!(importErrors.length > 0) && Object.keys(importWarnings).length > 0) && ( // If there are only warnings show warnings header
          <ErrorsModalHeaderWrapper>
            <FontAwesomeIcon icon={faExclamationSquare} style={{ fontSize: '2.5rem', color: 'orange' }}></FontAwesomeIcon>
            <WarningText color="orange">{t('pautas.warningsModalHeader')}</WarningText>
          </ErrorsModalHeaderWrapper>
        )}
      </ErrorsModalHeader>
      <ErrorsModalItem>
        {errorMessages.map((message, index) => (
          <Text color="red" key={index}>{message}</Text>
        ))}
        {warningMessages.map((message, index) => 
          <WarningText color="orange" key={`warning-${index}`}>{message}</WarningText>
        )}
      </ErrorsModalItem>
      <Button style={{ marginTop: '1rem', float: 'right' }} danger onClick={onClick}>
            {t('pautas.close', {textOnly: true})}
      </Button>
    </Modal>
  )
}

export default ErrorsModal;