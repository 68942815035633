/**
 * FileDownloader component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

import { Text, Button } from '@paco_ua/pacoui';

import { t } from 'app';

interface IFileDownloader {
  heading: string;
  description?: string;
  icon?: JSX.Element;
  url: string;
  callToEndpoint: (endpoint: string, setLoading: (value) => void) => void;
}

const FileDownloader: React.FC<IFileDownloader> = ({
  heading,
  description,
  icon,
  url,
  callToEndpoint,
}) => {
  const theme = useContext(ThemeContext);

  const [showFileLoading, setShowFileLoading] = useState(false);

  return (
    <Wrapper>
      <FileWrapper>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <FileNameAndDescription>
          <HeadingText size="article" color={theme.colors.primary} fontWeight="medium">
            {heading}
          </HeadingText>
          {description && (
            <FileDescriptionText size="xSmall" weight="regular" color={theme.colors.plusDarkGrey}>
              {description}
            </FileDescriptionText>
          )}
        </FileNameAndDescription>
      </FileWrapper>
      <Button
        action
        disabled={showFileLoading ? showFileLoading : undefined}
        loading={showFileLoading ? showFileLoading : undefined}
        leftIcon={<FontAwesomeIcon icon={faEye} />}
        onClick={() => {
          callToEndpoint(url, setShowFileLoading);
        }}
      >
        {t('generic.fileDownloaderButton')}
      </Button>
    </Wrapper>
  );
};

export default FileDownloader;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
`;

const FileWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:first-child) {
    margin-left: 22px;
    margin-right: 22px;
  }
`;

const IconWrapper = styled.div`
  svg {
    font-size: 26px;
  }
`;

const FileNameAndDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const FileDescriptionText = styled(Text)`
  margin-top: 8px;
`;

const HeadingText = styled(Text)``;
