/**
 * NewCertificateSelectType container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import React, { useContext, useEffect, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { Grid } from 'semantic-ui-react';
import { AnimatedBackground, Text, Segment, Accordion, SearchBox, Dropdown } from '@paco_ua/pacoui';

import config from 'config';

import API from 'api';

const NewCertificateSelectType = () => {
  const theme = useContext(ThemeContext);

  const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

  const {
    filterParams,
    certificateTypes,
    selectedCertificateType,
    loadingStatus,
    errorStatus,
  } = useSelector(selectors.getNewCertificateSlice);

  const {
    setSearchFilter,
    setTypeFilter,
    getCertificateTypes,
    resetSelectedCertificateType,
    setSelectedCertificatePreviewUrl,
    setSelectedCertificateType,
  } = actions.NewCertificate;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCertificateTypes(filterParams));
  }, [filterParams]);

  const [initialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (!loadingStatus.tabLoading && initialLoading) {
      setInitialLoading(false);
    }
  }, [loadingStatus.tabLoading]);

  const getAccordionParent = (title: string) => {
    return (
      <AccordionParent>
        {title}
        <FontAwesomeIcon icon={faAngleDown} />
      </AccordionParent>
    );
  };

  const [loadingModels, setLoadingModels] = useState({});

  const getAccordionChild = (
    themeGroup: string,
    title: string,
    description: string,
    code: string,
    previewUrl: string,
    price: number,
    index: number,
    id: number | null
  ) => {
    return (
      <AccordionChild
        key={`accordionChild-${code}-${index}`}
        onClick={() => {
          dispatch(setSelectedCertificatePreviewUrl(previewUrl));
          dispatch(
            setSelectedCertificateType({
              theme: themeGroup,
              code: code,
              name: title,
              price: price,
              id: id,
              description: description,
            })
          );
        }}
      >
        <Input
          type="radio"
          onChange={() => {
            dispatch(setSelectedCertificatePreviewUrl(previewUrl));
            dispatch(
              setSelectedCertificateType({
                theme: themeGroup,
                code: code,
                name: title,
                price: price,
                id: id,
                description: description,
              })
            );
          }}
          checked={selectedCertificateType.code === code}
        />
        <AccordionChildDescription>
          <AccordionChildColumn>
            <AccordionTitleText size="article" color={theme.colors.primary} weight={'medium'}>
              {title}
            </AccordionTitleText>
            {description && description !== '' && (
              <AccordionChildDescritionText
                size="xSmall"
                color={theme.colors.plusDarkGrey}
                weight={'medium'}
              >
                {description}
              </AccordionChildDescritionText>
            )}
          </AccordionChildColumn>
          <ModelViewColumn>
            {previewUrl !== '' && (
              <ModelWrapper>
                <Text
                  loading={loadingModels[`${code}-${index}`]}
                  loadingProps={{ width: '96px' }}
                  size="xSmall"
                  color={'plusDarkGrey'}
                  weight={'regular'}
                  as={!loadingModels[`${code}-${index}`] && 'a'}
                  onClick={() => {
                    if (!loadingModels[`${code}-${index}`] && previewUrl !== '') {
                      setLoadingModels((currentValues) => {
                        return { ...currentValues, [`${code}-${index}`]: true };
                      });
                      try {
                        API.files.getFileOnTab
                          .call(previewUrl)
                          .then((value) => {
                            if (value) {
                              setLoadingModels((currentValues) => {
                                return { ...currentValues, [`${code}-${index}`]: false };
                              });
                            } else {
                              setLoadingModels((currentValues) => {
                                return { ...currentValues, [`${code}-${index}`]: false };
                              });
                            }
                          })
                          .catch((err) => {
                            setLoadingModels((currentValues) => {
                              return { ...currentValues, [`${code}-${index}`]: false };
                            });
                            dispatch(
                              actions.Toaster.showToaster({
                                title: t('secVirtualNotifications.general_errorLoadingFile'),
                                icon: 'error',
                                type: 'danger',
                              })
                            );
                          });
                      } catch (e) {
                        setLoadingModels((currentValues) => {
                          return { ...currentValues, [`${code}-${index}`]: false };
                        });
                        dispatch(
                          actions.Toaster.showToaster({
                            title: t('secVirtualNotifications.general_errorLoadingFile'),
                            icon: 'error',
                            type: 'danger',
                          })
                        );
                      }
                    }
                  }}
                >
                  {t('certificates.visualizeModel')}
                </Text>
              </ModelWrapper>
            )}
            <VerticalSeparator />
            <PriceWrapper>
              <Text size="small" color={theme.colors.plusDarkGrey} weight={'bold'}>
                {`${price.toFixed(2)}€`}
              </Text>
            </PriceWrapper>
          </ModelViewColumn>
        </AccordionChildDescription>
      </AccordionChild>
    );
  };

  const structure = Object.keys(certificateTypes.data).map((type) => {
    return {
      parent: getAccordionParent(certificateTypes.data[type][0].theme),
      children: certificateTypes.data[type].map((t, index) =>
        getAccordionChild(
          t.theme,
          t.type,
          t.description,
          t.code,
          `${API_SEC_VIRTUAL}${t.previewUrl}`,
          t.price,
          index,
          t.id
        )
      ),
    };
  });

  return (
    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
      <Segment>
        <SegmentTitleWrapper>
          <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
            {t('certificates.requestSecondStepTitle')}
          </Text>
          <TitleDescriptionWrapper>
            <DescriptionText size="article" color={theme.colors.darkGrey} weight={'regular'}>
              {t('certificates.requestSecondStepDescription')}
            </DescriptionText>
          </TitleDescriptionWrapper>
        </SegmentTitleWrapper>

        {!loadingStatus.tabLoading && !errorStatus.errLoadingCertificateList && !initialLoading && (
          <SearchAndFiltersWrapper>
            <SearchBox
              placeholder={t('forms.searchPlaceholder', { textOnly: true })}
              borderColor={theme.colors.plusDarkGrey}
              iconColor={theme.colors.plusDarkGrey}
              defaultValue={filterParams.search !== '' ? filterParams.search : undefined}
              delay={500}
              onSearch={(value) => {
                dispatch(setSearchFilter(value));
                dispatch(resetSelectedCertificateType());
              }}
            />
            <Dropdown
              placeholder={t('certificates.listDropdownFilterPlaceholder', {
                textOnly: true,
              })}
              canBeCleared
              value={filterParams.type !== '' ? filterParams.type : undefined}
              options={certificateTypes.filters}
              selectionWeight={'400'}
              selectionFontSize={'medium'}
              onChange={(e, { value }) => {
                dispatch(setTypeFilter(value));
                dispatch(resetSelectedCertificateType());
              }}
              onClear={() => {
                dispatch(setTypeFilter(''));
                dispatch(resetSelectedCertificateType());
              }}
            />
          </SearchAndFiltersWrapper>
        )}

        {(loadingStatus.tabLoading || errorStatus.errLoadingCertificateList) && (
          <LoadingWrapper>
            <AnimatedBackground height={theme.sizes.large} width="20%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
          </LoadingWrapper>
        )}

        {!loadingStatus.tabLoading &&
          !errorStatus.errLoadingCertificateList &&
          structure.length > 0 && (
            <AccordionWrapper>
              <Accordion structure={structure} />
            </AccordionWrapper>
          )}

        {!loadingStatus.tabLoading &&
          !errorStatus.errLoadingCertificateList &&
          structure.length === 0 && (
            <MessageNoCertificates>
              <Icon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
              <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                {t('certificates.noCertificatesFound', { textOnly: true })}
              </Text>
            </MessageNoCertificates>
          )}
      </Segment>
    </Grid.Column>
  );
};

export default NewCertificateSelectType;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const TitleDescriptionWrapper = styled.div`
  margin-top: 8px;
  line-height: 1.3;
`;

const MessageNoCertificates = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  margin-top: 32px;

  * {
    margin: 8px 0px;
  }
`;

const AccordionWrapper = styled.div`
  margin-top: 32px;
`;

const AccordionParent = styled.div`
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.softGrey};
  border-right: 1px solid ${({ theme }) => theme.colors.softGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.softGrey};
  border-top: 2px solid ${({ theme }) => theme.colors.darkGrey};

  svg {
    font-size: 24px;
  }
`;

const AccordionChild = styled.div`
  min-height: 69px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.colors.softGrey};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.softGrey};
  }
`;

const AccordionChildDescription = styled.div`
  display: flex;
  flex-grow: 1;
  margin-left: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  justify-content: space-between;
`;

const AccordionChildColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;

const VerticalSeparator = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.darkGrey};
  width: 0px;
  margin-left: 20px;
  margin-right: 11px;
`;

const ModelViewColumn = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ModelWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const PriceWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 56px;

  div:last-child {
    justify-content: right;
  }
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchAndFiltersWrapper = styled.div`
  width: 75%;
  display: flex;
  margin-bottom: 24px;
  margin-top: 32px;

  > div {
    width: 50%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const DescriptionText = styled(Text)`
  margin-top: 8px;
`;

const AccordionChildDescritionText = styled(Text)`
  margin-top: 4px;
`;

const Input = styled.input`
  /* hide default inner circle */
  appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.regularGrey};
  border-radius: 50%;
  outline: none;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    opacity: 1;
  }

  /* inner circle */
  :before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: middle;
  }

  :checked:before {
    background: ${({ theme }) => theme.colors.primary};
  }

  :checked {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }
`;

const AccordionTitleText = styled(Text)`
  line-height: 1.3;
`;
