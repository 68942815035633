/**
 * NewApplicationSubmission container logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getLoading = (state: RootState) => state.NewApplication.loadingStatus.loading;
export const getUser = (state: RootState) => state.App.user;
export const getNewApplicationData = (state: RootState) => state.NewApplication.applicationData;
export const getNewApplicationTabLoading = (state: RootState) =>
  state.NewApplication.loadingStatus.tabLoading;
export const getNewApplicationSlice = (state: RootState) => state.NewApplication;
