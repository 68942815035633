import { t } from 'app';
import {
  faGraduationCap,
  faUserGraduate,
  faCabinetFiling,
  faTh,
  faUsersCog,
  faChalkboardTeacher,
  faPenSquare,
  faAddressCard,
} from '@fortawesome/pro-light-svg-icons';
import { getPathToPage } from 'shared/containers/Breadcrumb/utils';
import { IRole } from 'config';
import { IRoutes } from 'routes';

export const LinksList = (userRoles: IRole[], currentPath, routes: IRoutes[], isImpersonating) => {
  const orderedRoutes: any = [];

  routes
    .filter((route) => {
      if (route.hideFromSidebar) return false;
      if (!route.roles) return false;
      // if (
      //   process.env.REACT_APP_ENV === 'production' &&
      //   (route.childOf === 'enrollments' || route.key === 'examsCalendar')
      // )
      //   return false;

      //UPDATE: We don't really wanna do this, we eant to check all including super users
      //check all but super user
      if (
        route?.roles.some((routeRole) =>
          userRoles
            //.filter((userRole) => !userRole.toLowerCase().includes('superuser'))
            .includes(routeRole)
        )
      ) {
        return true;
      }

      const isWasStudent = route?.roles.some(
        (routeRole) => routeRole === 'student' || routeRole === 'exStudent'
      );

      if (isWasStudent && isImpersonating) return true;
      if (!isWasStudent && userRoles.some((role) => role.toLowerCase().includes('superuser')))
        return true;
    })
    .forEach((filteredRoute) => {
      const routeStructure = {
        title: t(`routes.${filteredRoute.name}`, { textOnly: true }),
        icon: routeIcons[filteredRoute.name] || null,
        path: filteredRoute.path ? filteredRoute.key : null,
        link: filteredRoute.link ? filteredRoute.link : null,
        disabled: filteredRoute.disabled,
        selected: filteredRoute.key === currentPath,
      };

      const routeStructureSubLinks = (name, subRoute, key) => ({
        title: name,
        icon: routeIcons[key] || null,
        collapsed: false,
        subLinks: [subRoute],
      });

      if (!filteredRoute?.module?.name) {
        if (!filteredRoute.childOf) {
          orderedRoutes.push(routeStructure);
        } else {
          //if has childOf property has a subsublik of a button
          //first check if path exists before adding subsublinks
          let indexOfPath = orderedRoutes.findIndex(
            (orderedRoute) =>
              orderedRoute.title === t(`routes.${filteredRoute.childOf}`, { textOnly: true })
          );

          if (indexOfPath < 0) {
            orderedRoutes.push(
              routeStructureSubLinks(
                t(`routes.${filteredRoute.childOf}`, { textOnly: true }),
                routeStructure,
                filteredRoute.childOf
              )
            );
          } else {
            orderedRoutes[indexOfPath].subLinks.push(routeStructure);
          }
        }
      } else {
        //if has module property has a sublik of a button
        //first check if path exists before adding sublinks
        let indexOfModule = orderedRoutes.findIndex((orderedRoute) => {
          return (
            orderedRoute.title === t(`routes.${filteredRoute?.module?.name}`, { textOnly: true })
          );
        });

        if (indexOfModule < 0) {
          if (!filteredRoute.childOf) {
            orderedRoutes.push(
              routeStructureSubLinks(
                t(`routes.${filteredRoute.module.name}`, { textOnly: true }),
                routeStructure,
                filteredRoute.module.name
              )
            );
          } else {
            //if has childOf property has a subsublik of a button
            //first check if path exists before adding subsublinks
            let indexOfPath = orderedRoutes.findIndex(
              (orderedRoute) =>
                orderedRoute.title === t(`routes.${filteredRoute.childOf}`, { textOnly: true })
            );

            if (indexOfPath < 0) {
              orderedRoutes.push(
                routeStructureSubLinks(
                  t(`routes.${filteredRoute.childOf}`, { textOnly: true }),
                  routeStructure,
                  filteredRoute.childOf
                )
              );
            } else {
              orderedRoutes[indexOfPath].subLinks.push(routeStructure);
            }
          }
        } else {
          if (!filteredRoute.childOf) {
            orderedRoutes[indexOfModule].subLinks.push(routeStructure);
          } else {
            //if has childOf property has a subsublik of a button
            //first check if path exists before adding subsublinks
            let indexOfPath = orderedRoutes[indexOfModule].subLinks.findIndex(
              (orderedRoute) =>
                orderedRoute.title === t(`routes.${filteredRoute.childOf}`, { textOnly: true })
            );

            if (indexOfPath < 0) {
              orderedRoutes[indexOfModule].subLinks.push(
                routeStructureSubLinks(
                  t(`routes.${filteredRoute.childOf}`, { textOnly: true }),
                  routeStructure,
                  filteredRoute.childOf
                )
              );
            } else {
              orderedRoutes[indexOfModule].subLinks[indexOfPath].subLinks.push(routeStructure);
            }
          }
        }
      }
    });

  return orderedRoutes;
};

export const setSelectedItems = (location, sidebarLinks, routes) => {
  const breadcrumbPath = getPathToPage(location, routes);

  let newSideBarLink = [...sidebarLinks];
  newSideBarLink.forEach((link) => {
    let found = false;
    let foundSublink = false;

    if (link.subLinks) {
      link.subLinks.forEach((subLink) => {
        if (subLink.subLinks) {
          foundSublink = false;

          subLink.subLinks.forEach((subSubLink) => {
            if (breadcrumbPath.some((breadcrumb) => breadcrumb.key === subSubLink.path)) {
              found = true;
              foundSublink = true;
              subSubLink.selected = true;
            } else {
              subSubLink.selected = false;
            }
          });

          if (foundSublink) {
            subLink.collapsed = true;
            subLink.selected = false;
          } else {
            subLink.collapsed = false;
            subLink.selected = false;
          }
        } else {
          if (breadcrumbPath.some((breadcrumb) => breadcrumb.key === subLink.path)) {
            found = true;
            subLink.selected = true;
          } else {
            subLink.selected = false;
          }
        }
      });

      if (found) {
        link.collapsed = true;
        link.selected = true;
      } else {
        if (breadcrumbPath.some((breadcrumb) => breadcrumb.name === link.title)) {
          link.selected = true;
          link.collapsed = false;
        } else {
          link.selected = false;
          link.collapsed = false;
        }
      }
    } else {
      if (breadcrumbPath.some((breadcrumb) => breadcrumb.key === link.path)) {
        found = true;
        link.selected = true;
      } else {
        link.selected = false;
      }
    }
  }, []);

  return newSideBarLink;
};

const routeIcons = {
  paco: faGraduationCap,
  secVirtual: faCabinetFiling,
  schedules: faTh,
  personalData: faUserGraduate,
  backoffice: faUsersCog,
  teachers: faChalkboardTeacher,
  candidature: faAddressCard,
  onlineEnrollments: faPenSquare,
};
