/**
 * StudentRegistrationDropdown container slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 * @format
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  registration: null as null | number,
  registrationOptions: [] as any,
  loadingRegistrations: true as boolean,
};

export default createSlice({
  name: 'StudentRegistrationDropdown',
  initialState,
  reducers: {
    getRegistrations: (state) => {
      //state.registration = null;
    },
    setLoadingRestriction: (state, action: PayloadAction<boolean>) => {
      state.loadingRegistrations = action.payload;
    },
    setRegistration: (state, action: PayloadAction<number | null>) => {
      state.registration = action.payload;
    },
  },
});
