/**
 * Payment Modal container
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 * TODO To be finished. Waiting for design mock
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { useDispatch } from 'react-redux';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEllipsisH } from '@fortawesome/pro-light-svg-icons';

import { t } from 'app';

import { AnimatedBackground, Button, Modal, Text } from '@paco_ua/pacoui';

import { IPaymentDetailMb } from './types';

interface IPaymentModal {
  open: boolean;
  onClose(): void;
  mbPaymentDetails: IPaymentDetailMb | null;
  currentLocale: string;
  loading: boolean;
}

const MbPaymentInfoModal = ({
  onClose,
  open,
  mbPaymentDetails,
  currentLocale,
  loading,
}: IPaymentModal) => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);

  const getPaymentDetailsTable = (paymentDetails: IPaymentDetailMb) => {
    let table = [] as any;

    Object.keys(paymentDetails).forEach((key) => {
      if (key !== 'paymentRequestId') {
        table.push(
          <MbwayResponseColumn key={key}>
            <TextFieldTitle>
              <Text color="plusDarkGrey" size="small" weight="medium">
                {t(`payments.${key}`)}
              </Text>
            </TextFieldTitle>

            {(paymentDetails[key] === '' || paymentDetails[key] === null) && (
              <FontAwesomeIcon icon={faEllipsisH} />
            )}
            {paymentDetails[key] !== '' && paymentDetails[key] !== null && key === 'dueDate' && (
              <Text weight="bold" color="plusDarkGrey" size="mediumSmall">
                {moment(paymentDetails[key]).format('DD-MM-YYYY')}
              </Text>
            )}
            {paymentDetails[key] !== '' &&
              paymentDetails[key] !== null &&
              key !== 'dueDate' &&
              key !== 'value' && (
                <Text weight="bold" color="plusDarkGrey" size="mediumSmall">
                  {paymentDetails[key]}
                </Text>
              )}
            {paymentDetails[key] !== '' && paymentDetails[key] !== null && key === 'value' && (
              <Text weight="bold" color="plusDarkGrey" size="mediumSmall">
                {`${parseFloat(paymentDetails[key]).toLocaleString(currentLocale, {
                  minimumFractionDigits: 2,
                })} ${t('generic.euros', { textOnly: true })}`}
              </Text>
            )}
          </MbwayResponseColumn>
        );
      }
    });

    return table;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
    >
      <>
        <Title>
          <Text loading={loading} size="xLarge" weight="medium" color="plusDarkGrey">
            {t('payments.atmPaymentDetails', { textOnly: true })}
          </Text>
        </Title>

        {loading && (
          <LoadingWrapper height={'20px'}>
            <AnimatedBackground height={theme.sizes.large} width="60%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
          </LoadingWrapper>
        )}

        {!loading && mbPaymentDetails && Object.keys(mbPaymentDetails).length > 0 && (
          <MbReferenceInfo>
            <MBReferenceTitle>
              <Text size="medium" color="plusDarkGrey" weight="medium">
                {t('payments.atmReference', { readOnly: true })}
              </Text>
            </MBReferenceTitle>
            <MbwayResponseWrapper>{getPaymentDetailsTable(mbPaymentDetails)}</MbwayResponseWrapper>
          </MbReferenceInfo>
        )}

        {!loading && (mbPaymentDetails === null || Object.keys(mbPaymentDetails).length <= 0) && (
          <MessageNoInfo>
            <Icon>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Icon>
            <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
              {t('payments.nopaymentDetailsInfo', { textOnly: true })}
            </Text>
          </MessageNoInfo>
        )}

        <ButtonsProgressWrapper>
          <Button
            onClick={() => {
              onClose && onClose();
            }}
            action
            color={theme.colors.primary}
          >
            {t('generic.close')}
          </Button>
        </ButtonsProgressWrapper>
      </>
    </Modal>
  );
};

export default MbPaymentInfoModal;

const Title = styled.div`
  margin-bottom: 20px;
`;

const ButtonsProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 48px;
`;

const MbwayResponseWrapper = styled.div`
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors.softGrey};
  border-style: solid;
  border-width: thin;
  border-color: ${({ theme }) => theme.colors.softLightGrey};
  border-top: 3px solid ${({ theme }) => theme.colors.primary};
  padding: 16px 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const MbwayResponseColumn = styled.div``;

const TextFieldTitle = styled.div`
  margin: 0px 0px 12px 0px;
`;

const MessageNoInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const MBReferenceTitle = styled.div``;

const MbReferenceInfo = styled.div`
  margin-top: 48px;
`;

const LoadingWrapper = styled.div<{ height: string }>`
  width: 100%;
  margin-top: 48px;
  margin-bottom: 148px;

  height: ${({ height }) => `${height}`};

  &&& div:first-child {
    margin-bottom: 24px;
  }
`;
