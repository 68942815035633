/**
 * SuperUserTools scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getAppSlice = (state: RootState) => state.App;
export const getRoles = (state: RootState) => state.AppTools.roles;
