/**
 * EvaluationTypeSelection scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getEvaluationTypeSelectionSlice = (state: RootState) => state.EvaluationTypeSelection;
export const getUser = (state: RootState) => state.App.user;
