/**
 * CertificatesList selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getCertificatesListSlice = (state: RootState) => state.CertificatesList;
export const getUser = (state: RootState) => state.App.user;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getAppSlice = (state: RootState) => state.App.currentLocale;
