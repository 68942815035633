import React, { useState } from 'react';
import styled from 'styled-components';

import { Text } from '@paco_ua/pacoui';

import { useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';

import logoUA from 'assets/images/logo512.png';
import { Container } from 'semantic-ui-react';

const InMaintenance = ({ t }) => {
  const [open, setOpen] = useState(0);
  const { navigateTo } = actions.App;
  const dispatch = useDispatch();
  return (
    <Wrapper>
      <Container>
        <AlignCenter>
          <LogoUA
            onClick={() => {
              if (open >= 5 && open < 10) {
                setOpen(open + 1);
              }
              if (open >= 10) {
                localStorage.setItem('openSystem', 'true');
                dispatch(navigateTo({ key: 'home' }));
              }
            }}
            src={logoUA}
            alt="Universidade de Aveiro"
          />
        </AlignCenter>
        <WrapperText>
          <FormattedText weight="bold" size="xLarge">
            <AlignCenter
              onClick={() => {
                if (open < 5) {
                  setOpen(open + 1);
                }
                if (open >= 5) {
                  setOpen(0);
                }
              }}
            >
              A escolha de horários via paco2.0 está concluída.
            </AlignCenter>
          </FormattedText>
          <FormattedText size="large">
            <AlignCenter>
              Estamos a trabalhar num Portal Académico Online mais estável e com mais
              funcionalidades.
            </AlignCenter>
          </FormattedText>
          <FormattedText size="xLarge">
            <AlignCenter>
              {'Até lá continue a aceder a\u00A0'}
              <PacoLegacyHref href={t('links.pacoLegacy', { textOnly: true })}>
                {t('links.pacoLegacy', { textOnly: true })}
              </PacoLegacyHref>
            </AlignCenter>
          </FormattedText>
        </WrapperText>
      </Container>
    </Wrapper>
  );
};
export default InMaintenance;

const FormattedText: React.FC<{
  size?: string;
  color?: string;
  weight?: string;
  onClick?: () => void;
}> = ({ children, size, onClick, weight, color }) => (
  <div
    onClick={() => {
      onClick && onClick();
    }}
  >
    <Text color={color || 'white'} size={size ? size : 'large'} weight={weight}>
      {children}
    </Text>
  </div>
);

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 26%;

  ${({ theme }) => theme.medias.sm} {
    padding: 0 20%;
  }
  ${({ theme }) => theme.medias.xs} {
    padding: 0 80px;
  }
`;

const LogoUA = styled.img`
  width: 120px;
  height: 120px;
  margin: 120px 0 80px;
  filter: brightness(0) invert(1);
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 48px;
  }
`;

const PacoLegacyHref = styled.a`
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`;
