/**
 * CurricularPlan scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from 'semantic-ui-react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import {
  AnimatedBackground,
  Text,
  CallToAction,
  MultiToggle,
  ITable,
  Table,
  NoDataMessage,
  Loading,
  Modal,
} from '@paco_ua/pacoui';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import {
  COURSE_FIELD_KEY,
  COURSE_TYPE_KEY,
  getTableTabs,
  getTableColumnsForMandatoryUcs,
  getTableColumnsForAllCourseUcs,
  getCurricularPlanTableFormat,
  findTabInList,
  setupOptionsTableContent,
} from './utils';

import { ITableRawData, IColumnItem, ITabItem } from './types';

import { Theme as theme } from '@paco_ua/pacoui';

import numeral from 'numeral';

const CurricularPlan = ({ t }) => {
  const { onMount, onUnmount, getCurricularPlan, setUcOptionsModal } = actions.CurricularPlan;
  // const { setUserRegistrations } = actions.App;

  const { navigateTo } = actions.App;

  const {
    loadingStatus,
    errorStatus,
    summaries,
    curricularPlans,
    ucOptionsModal,
    ucOptions,
  } = useSelector(selectors.getCurricularPlanSlice);

  const user = useSelector(selectors.getUser);

  const registrations = useSelector(selectors.getUserRegistrations);

  const currentLocale = useSelector(selectors.getAppSlice);

  const currentRegistrationId = useSelector(selectors.getRegistration);

  const dispatch = useDispatch();

  const [tabSelectionIndex, setTabSelectionIndex] = useState('');

  const [visibleTabsList, setVisibleTabsList] = useState([] as string[]);

  const [tableContent, setTableContent] = useState(null as any | ITable);

  const [optionsTableContent, setOptionsTableContent] = useState(null as any | ITable);

  const [noDataAvailable, setNoDataAvailable] = useState(false);

  // same keys as the ones received from the endpoint
  const COURSE_FIELD = {
    ACADEMIC_YEAR: {
      key: COURSE_FIELD_KEY.ACADEMIC_YEAR,
      value: t('curricularPlan.academicName', { textOnly: true }),
    },
    CREDITS: {
      key: COURSE_FIELD_KEY.CREDITS,
      value: t('curricularPlan.creditsName', { textOnly: true }),
    },
    ECTS: {
      key: COURSE_FIELD_KEY.ECTS,
      value: t('curricularPlan.ectsName', { textOnly: true }),
    },
    GRADE: {
      key: COURSE_FIELD_KEY.GRADE,
      value: t('curricularPlan.classificationName', { textOnly: true }),
    },
    INSTITUTION: {
      key: COURSE_FIELD_KEY.INSTITUTION,
      value: t('curricularPlan.institutionName', { textOnly: true }),
    },
    PROGRAMME: {
      key: COURSE_FIELD_KEY.PROGRAMME,
      value: t('curricularPlan.programName', { textOnly: true }),
    },
    SEMESTER: {
      key: COURSE_FIELD_KEY.SEMESTER,
      value: t('curricularPlan.semesterName', { textOnly: true }),
    },
    TYPE: {
      key: COURSE_FIELD_KEY.TYPE,
      value: t('curricularPlan.typeName', { textOnly: true }),
    },
    UC_CODE: {
      key: COURSE_FIELD_KEY.UC_CODE,
      value: t('curricularPlan.ucCodeName', { textOnly: true }),
    },
    UC_NAME: {
      key: COURSE_FIELD_KEY.UC_NAME,
      value: t('curricularPlan.curricularUnitName', { textOnly: true }),
    },
    YEAR_STUDY: {
      key: COURSE_FIELD_KEY.YEAR_STUDY,
      value: t('curricularPlan.schoolYearName', { textOnly: true }),
    },
  };

  const COURSE_TYPE = {
    FIRST_YEAR: {
      key: COURSE_TYPE_KEY.FIRST_YEAR,
      value: `${numeral(1).format('o')} ${t('curricularPlan.year', { textOnly: true })}`,
    },
    SECOND_YEAR: {
      key: COURSE_TYPE_KEY.SECOND_YEAR,
      value: `${numeral(2).format('o')} ${t('curricularPlan.year', { textOnly: true })}`,
    },
    THIRD_YEAR: {
      key: COURSE_TYPE_KEY.THIRD_YEAR,
      value: `${numeral(3).format('o')} ${t('curricularPlan.year', { textOnly: true })}`,
    },
    FOURTH_YEAR: {
      key: COURSE_TYPE_KEY.FOURTH_YEAR,
      value: `${numeral(4).format('o')} ${t('curricularPlan.year', { textOnly: true })}`,
    },
    FIFTH_YEAR: {
      key: COURSE_TYPE_KEY.FIFTH_YEAR,
      value: `${numeral(5).format('o')} ${t('curricularPlan.year', { textOnly: true })}`,
    },
    SIXTH_YEAR: {
      key: COURSE_TYPE_KEY.SIXTH_YEAR,
      value: `${numeral(6).format('o')} ${t('curricularPlan.year', { textOnly: true })}`,
    },
    EVERY_COURSE_UCS: {
      key: COURSE_TYPE_KEY.EVERY_COURSE_UCS,
      value: t('curricularPlan.everyCourseUc', { textOnly: true }),
    },
    EXTRA_CURRICULAR_UCS: {
      key: COURSE_TYPE_KEY.EXTRA_CURRICULAR_UCS,
      value: t('curricularPlan.extraCurricular', { textOnly: true }),
    },
    MOBILITY_UCS: {
      key: COURSE_TYPE_KEY.MOBILITY_UCS,
      value: t('curricularPlan.mobility', { textOnly: true }),
    },
    ACCREDITED_UCS: {
      key: COURSE_TYPE_KEY.ACCREDITED_UCS,
      value: t('curricularPlan.Accredited', { textOnly: true }),
    },
  };

  const MANDATORY_COURSE_COLUMNS: IColumnItem[] = [
    COURSE_FIELD.UC_CODE,
    COURSE_FIELD.UC_NAME,
    COURSE_FIELD.YEAR_STUDY,
    COURSE_FIELD.SEMESTER,
    COURSE_FIELD.CREDITS,
    COURSE_FIELD.ECTS,
    COURSE_FIELD.GRADE,
  ];

  const MOBILITY_COURSE_COLUMNS: IColumnItem[] = [
    COURSE_FIELD.PROGRAMME,
    COURSE_FIELD.UC_NAME,
    COURSE_FIELD.ACADEMIC_YEAR,
    COURSE_FIELD.GRADE,
    COURSE_FIELD.INSTITUTION,
  ];

  const ACCREDITED_COURSE_COLUMNS: IColumnItem[] = [
    COURSE_FIELD.UC_CODE,
    COURSE_FIELD.UC_NAME,
    COURSE_FIELD.ACADEMIC_YEAR,
    COURSE_FIELD.GRADE, // may be "Not Applicable"
    COURSE_FIELD.TYPE,
  ];

  const OPTIONS_COURSE_COLUMNS: IColumnItem[] = [
    COURSE_FIELD.UC_CODE,
    COURSE_FIELD.UC_NAME,
    COURSE_FIELD.ACADEMIC_YEAR,
    COURSE_FIELD.SEMESTER,
    COURSE_FIELD.CREDITS,
    COURSE_FIELD.ECTS,
  ];

  const COURSE_TYPE_TABS: ITabItem[] = [
    COURSE_TYPE.FIRST_YEAR,
    COURSE_TYPE.SECOND_YEAR,
    COURSE_TYPE.THIRD_YEAR,
    COURSE_TYPE.FOURTH_YEAR,
    COURSE_TYPE.FIFTH_YEAR,
    COURSE_TYPE.EVERY_COURSE_UCS,
    COURSE_TYPE.EXTRA_CURRICULAR_UCS,
    COURSE_TYPE.MOBILITY_UCS,
    COURSE_TYPE.ACCREDITED_UCS,
  ];

  const tabButtons = visibleTabsList // exclude mobility_ucs tab
    .map((tabKey: string) => {
      return {
        children: findTabInList(COURSE_TYPE_TABS, tabKey),
        onClick: () => {
          setTabSelectionIndex(tabKey);
        },
        selected: tabSelectionIndex === tabKey,
        testId: 'curricularplan_multitoggle_curricularUnitTypes',
      };
    });

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  useEffect(() => {
    if (
      Object.keys(curricularPlans).length > 0 &&
      currentRegistrationId &&
      currentRegistrationId in curricularPlans &&
      registrations
    ) {
      const visibleTabs = getTableTabs(curricularPlans[currentRegistrationId]);

      if (visibleTabs.length <= 0) {
        setNoDataAvailable(true);
      }

      setVisibleTabsList(visibleTabs);
    }
  }, [registrations, curricularPlans]);

  // runs when a registration is selected in the dropdown
  useEffect(() => {
    if (visibleTabsList.length > 0) {
      // selection of the default tab
      setTabSelectionIndex(visibleTabsList[0]);
    }
  }, [visibleTabsList]);

  // runs when a registrationId is selected or the locale is changed
  useEffect(() => {
    if (currentRegistrationId) {
      dispatch(getCurricularPlan());
      // dispatch(actions.StudentRegistrationDropdown.getRegistrations());
    }
  }, [currentRegistrationId, currentLocale]);

  // runs when a new tab is selected or the locale is changed
  useEffect(() => {
    if (
      tabSelectionIndex !== '' &&
      currentRegistrationId &&
      currentRegistrationId >= 0 &&
      Object.keys(curricularPlans).length > 0
    ) {
      setupTableContent(currentRegistrationId, tabSelectionIndex);
    }
  }, [tabSelectionIndex, curricularPlans]);

  useEffect(() => {
    if (ucOptions.courseList.length > 0) {
      setOptionsTableContent(
        setupOptionsTableContent(OPTIONS_COURSE_COLUMNS, ucOptions, currentLocale, t)
      );
    } else {
      setOptionsTableContent(null);
    }
  }, [ucOptions]);

  const setupTableContent = (dropdownOption: number, tabKey: string) => {
    let tmpColumnTitlesList = [] as IColumnItem[];
    let tableData: ITableRawData = {
      ucs: [],
      subUcs: {},
      accreditedUcs: [],
      mobilityUcs: [],
      extraCurricularUcs: [],
    };

    // check if string is an integer
    const isNum = /^\d+$/.test(tabKey);

    if (isNum) {
      tmpColumnTitlesList = getTableColumnsForMandatoryUcs(
        MANDATORY_COURSE_COLUMNS,
        curricularPlans[dropdownOption].ucs[tabKey],
        curricularPlans[dropdownOption].subUcs,
        false,
        t
      );

      tableData.ucs = curricularPlans[dropdownOption].ucs[tabKey];

      tableData.subUcs = curricularPlans[dropdownOption].subUcs;
    } else if (tabKey === COURSE_TYPE_KEY.EVERY_COURSE_UCS) {
      tmpColumnTitlesList = getTableColumnsForAllCourseUcs(
        MANDATORY_COURSE_COLUMNS,
        curricularPlans[dropdownOption].ucs,
        curricularPlans[dropdownOption].subUcs
      );

      tableData.ucs = [];

      for (var key in curricularPlans[dropdownOption].ucs) {
        tableData.ucs.push(...curricularPlans[dropdownOption].ucs[key]);
      }

      tableData.subUcs = curricularPlans[dropdownOption].subUcs;
    } else if (tabKey === COURSE_TYPE_KEY.EXTRA_CURRICULAR_UCS) {
      tmpColumnTitlesList = getTableColumnsForMandatoryUcs(
        MANDATORY_COURSE_COLUMNS,
        curricularPlans[dropdownOption].extraCurricularUcs,
        curricularPlans[dropdownOption].subUcs,
        true,
        t
      );
      tableData.extraCurricularUcs = curricularPlans[dropdownOption].extraCurricularUcs.slice();
    } else if (tabKey === COURSE_TYPE_KEY.MOBILITY_UCS) {
      tmpColumnTitlesList = MOBILITY_COURSE_COLUMNS.slice();
      tableData.mobilityUcs = curricularPlans[dropdownOption].mobilityUcs.slice();
    } else if (tabKey === COURSE_TYPE_KEY.ACCREDITED_UCS) {
      tmpColumnTitlesList = ACCREDITED_COURSE_COLUMNS.slice();
      tableData.accreditedUcs = curricularPlans[dropdownOption].accreditedUcs.slice();
    } else {
      console.error('Invalid tab id');
    }

    const newTable = getCurricularPlanTableFormat(
      tableData,
      tmpColumnTitlesList,
      tabKey,
      currentLocale,
      dispatch,
      navigateTo,
      currentRegistrationId,
      t
    );

    if (newTable) {
      setTableContent(newTable);
    }
  };

  const handleDropdownSelection = (registrationId) => {
    if (!(registrationId in curricularPlans)) {
      dispatch(getCurricularPlan());
    }

    // reset states
    setVisibleTabsList([]);
    setTabSelectionIndex('');
    setNoDataAvailable(false);
  };

  const handleModalOnClose = () => {
    dispatch(setUcOptionsModal(false));
  };

  return (
    <PageStructure
      loading={loadingStatus.loading || errorStatus.errLoadingSummary}
      headerLeft={<Header title={t('routes.curricularPlan')} />}
      subHeader={
        <StudentRegistrationDropdown
          loading={loadingStatus.loading}
          onChange={() => {
            handleDropdownSelection(currentRegistrationId);
          }}
          data-testid="curricularplan_dropdown_registration"
        />
      }
      mainContent={
        <>
          {noDataAvailable && (
            <NoDataMessageWrapper>
              <NoDataMessage
                header={t('curricularPlan.noCurricularUnitsFound', { textOnly: true })}
              />
            </NoDataMessageWrapper>
          )}

          {loadingStatus.loading && (
            <LoadingWrapper>
              <Loading overlay size={'5x'} />
            </LoadingWrapper>
          )}

          {!loadingStatus.loading &&
            !noDataAvailable &&
            currentRegistrationId &&
            Object.keys(summaries).length > 0 &&
            currentRegistrationId in summaries &&
            summaries[currentRegistrationId]?.summary && (
              <SummaryWrapper>
                <Grid>
                  <Grid.Column largeScreen={10}>
                    <Text size="large" fontWeight="400">
                      {t('curricularPlan.summary', { textOnly: true })}
                    </Text>
                  </Grid.Column>
                </Grid>
                {Object.keys(curricularPlans).length > 0 ? (
                  <Grid columns="three">
                    <Grid.Row textAlign={'center'}>
                      <Grid.Column textAlign={'left'}>
                        <SummaryItem>
                          <SummaryText color="Grey" size="xSmall">
                            {t('curricularPlan.totalEctsEarned', { textOnly: true })}
                          </SummaryText>

                          <SummaryValuesText fontWeight="500" color="Black" size="xxLarge">
                            {summaries[currentRegistrationId].summary?.totalEctsEarned}
                          </SummaryValuesText>
                        </SummaryItem>
                      </Grid.Column>
                      <Grid.Column textAlign={'left'}>
                        <SummaryItem>
                          <SummaryText color="Grey" size="xSmall">
                            {t('curricularPlan.totalEctsToDo', { textOnly: true })}
                          </SummaryText>

                          <SummaryValuesText fontWeight="500" color="Black" size="xxLarge">
                            {summaries[currentRegistrationId].summary?.totalEctsToDo}
                          </SummaryValuesText>
                        </SummaryItem>
                      </Grid.Column>
                      {summaries[currentRegistrationId] &&
                        summaries[currentRegistrationId].summary?.totalEctsAccredited !== null &&
                        summaries[currentRegistrationId].summary?.totalEctsAccredited !==
                          undefined && (
                          <Grid.Column textAlign={'left'}>
                            <SummaryItem>
                              <SummaryText color="Grey" size="xSmall">
                                {t('curricularPlan.totalEctsAccredited', { textOnly: true })}
                              </SummaryText>

                              <SummaryValuesText fontWeight="500" color="Black" size="xxLarge">
                                {summaries[currentRegistrationId].summary?.totalEctsAccredited}
                              </SummaryValuesText>
                            </SummaryItem>
                          </Grid.Column>
                        )}

                      <Grid.Column textAlign={'left'}>
                        <SummaryItem>
                          <SummaryText color="Grey" size="xSmall">
                            {t('curricularPlan.totalUcsCompleted', { textOnly: true })}
                          </SummaryText>

                          <Text fontWeight="500" color="Black" size="xxLarge">
                            {summaries[currentRegistrationId].summary?.totalUcsCompleted}
                          </Text>
                        </SummaryItem>
                      </Grid.Column>
                      <Grid.Column textAlign={'left'}>
                        <SummaryItem>
                          <SummaryText color="Grey" size="xSmall">
                            {t('curricularPlan.totalUcsToDo', { textOnly: true })}
                          </SummaryText>

                          <Text fontWeight="500" color="Black" size="xxLarge">
                            {summaries[currentRegistrationId].summary?.totalUcsToDo}
                          </Text>
                        </SummaryItem>
                      </Grid.Column>
                      {summaries[currentRegistrationId] &&
                        summaries[currentRegistrationId].summary?.totalUcsAccredited !== null &&
                        summaries[currentRegistrationId].summary?.totalUcsAccredited !==
                          undefined && (
                          <Grid.Column textAlign={'left'}>
                            <SummaryItem>
                              <SummaryText color="Grey" size="xSmall">
                                {t('curricularPlan.totalUcsAccredited', { textOnly: true })}
                              </SummaryText>

                              <Text fontWeight="500" color="Black" size="xxLarge">
                                {summaries[currentRegistrationId].summary?.totalUcsAccredited}
                              </Text>
                            </SummaryItem>
                          </Grid.Column>
                        )}
                    </Grid.Row>
                  </Grid>
                ) : (
                  t('curricularPlan.noCurricularUnitsFound', { textOnly: true })
                )}
              </SummaryWrapper>
            )}

          {!loadingStatus.loading &&
            !noDataAvailable &&
            Object.keys(curricularPlans).length > 0 &&
            currentRegistrationId &&
            summaries[currentRegistrationId] &&
            summaries[currentRegistrationId].grade !== null &&
            summaries[currentRegistrationId].grade !== undefined &&
            summaries[currentRegistrationId].grade !== 0 && (
              <AverageWrapper>
                <AverageGradeTitleWrapper>
                  <Grid>
                    <Grid.Column tablet={8} computer={10} largeScreen={10}>
                      <Text size="large" fontWeight="400">
                        {t('curricularPlan.average', { textOnly: true })}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </AverageGradeTitleWrapper>

                <AverageGradeBodyWrapper>
                  <Grid verticalAlign={'middle'}>
                    <Grid.Column
                      textAlign={'center'}
                      mobile={3}
                      tablet={2}
                      computer={1}
                      largeScreen={1}
                    >
                      <InfoIconWrapper>
                        <FontAwesomeIcon icon={faInfoCircle} size="2x" />
                      </InfoIconWrapper>
                    </Grid.Column>
                    <Grid.Column mobile={6} tablet={8} computer={11} largeScreen={11}>
                      <AverageSubtitleWrapper>
                        <Text size="article" fontWeight="500">
                          {t('curricularPlan.average', { textOnly: true })}
                        </Text>
                      </AverageSubtitleWrapper>
                      <Text size="article" fontWeight="400" color="grey">
                        {t('curricularPlan.averageContent', { textOnly: true })}
                      </Text>
                    </Grid.Column>
                    <Grid.Column
                      textAlign={'right'}
                      mobile={7}
                      tablet={6}
                      computer={4}
                      largeScreen={3}
                    >
                      <Text size="xxLarge" fontWeight="500">
                        {`${summaries[currentRegistrationId].grade.toLocaleString(
                          currentLocale
                        )} ${t('curricularPlan.averageValues', { textOnly: true })}`}
                      </Text>
                    </Grid.Column>
                  </Grid>
                </AverageGradeBodyWrapper>

                <CallToAction
                  footer={
                    <CallToActionWrapper>
                      <Text weight="regular" color="darkGrey" size="article">
                        {t('curricularPlan.averageMoreInformation', { textOnly: true })}
                      </Text>
                      <FontAwesomeIcon icon={faAngleRight} />
                    </CallToActionWrapper>
                  }
                  onAction={() => {
                    window.open(
                      t('links.curricularPlanAverageFormula', { textOnly: true }),
                      '_blank'
                    );
                  }}
                />
              </AverageWrapper>
            )}

          {!loadingStatus.loading && !noDataAvailable && tabButtons.length > 0 && (
            <CourseTableWrapper>
              <MultiToggle
                buttons={tabButtons}
                content={'buttons'}
                scrollableAtWidth={'calc(100% - 10px)'}
              />
            </CourseTableWrapper>
          )}

          {!loadingStatus.loading &&
            !noDataAvailable &&
            Object.keys(curricularPlans).length > 0 &&
            currentRegistrationId &&
            curricularPlans[currentRegistrationId] &&
            tableContent && (
              <TableWrapper>
                <Table
                  unstackable
                  structure={tableContent}
                  collapsedColor={theme.colors.softGrey}
                  noAutoEmptyCells
                  lockCollapsableInPlace
                />
              </TableWrapper>
            )}

          {ucOptionsModal && (
            <Modal
              open={ucOptionsModal}
              type={'confirmation'}
              header={
                <Text
                  color="blackLight"
                  size="xLarge"
                  fontWeight="500"
                  loading={loadingStatus.loading}
                >
                  {ucOptions.name}
                </Text>
              }
              onClose={() => handleModalOnClose()}
              data-testid="curricularplan_modal_options_view"
            >
              <ModalContentWrapper>
                <>
                  {!loadingStatus.loadingModal &&
                  optionsTableContent /*&& ucOptions.courseList.length > 0*/ && (
                      <Table
                        unstackable
                        structure={optionsTableContent}
                        headerColor={theme.colors.white}
                        noTopBorder
                      />
                    )}

                  {!loadingStatus.loadingModal && optionsTableContent === null && (
                    <>
                      <NoOptionsListWrapper>
                        <FontAwesomeIcon
                          icon={faInfoCircle}
                          size="4x"
                          color={theme.colors.primary}
                        />
                        <Text color={'plusDarkGrey'} size={'medium'} weight={'regular'}>
                          {t('curricularPlan.noOptionsUcsFound', { textOnly: true })}
                        </Text>
                      </NoOptionsListWrapper>
                    </>
                  )}

                  {loadingStatus.loadingModal && (
                    <AnimatedBackground height={theme.sizes.xLarge} width="100%" />
                  )}
                </>
              </ModalContentWrapper>
            </Modal>
          )}
        </>
      }
    />
  );
};

export default CurricularPlan;

const SummaryWrapper = styled.div`
  border-style: solid;
  border-width: thin;
  border-color: ${({ theme }) => theme.colors.softRegularGrey};
  margin-bottom: 32px;
  margin-top: 16px;
  padding: 38px;

  .row {
    margin-left: 0px;
  }
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const TableWrapper = styled.div`
  overflow: auto;
  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;

const AverageGradeTitleWrapper = styled.div`
  border-top: 3px solid ${({ theme }) => theme.colors.primary};
  margin-bottom: 0px;
  margin-top: 40px;
  padding: 14px 19px;
  background-color: ${({ theme }) => theme.colors.softGrey};
  box-shadow: ${({ theme }) => `0 0 0 1px ${theme.colors.softGrey} inset`};

  .row {
    margin-left: 0px;
  }
`;

const CourseTableWrapper = styled.div`
  margin-top: 40px;
`;

const AverageGradeBodyWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  margin: 0px;
  padding: 14px 19px;

  .row {
    margin-left: 0px;
  }
`;

const InfoIconWrapper = styled.div``;

const AverageSubtitleWrapper = styled.div`
  margin-bottom: 2px;
`;

const AverageWrapper = styled.div``;

const CallToActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
  position: relative;
`;

const NoOptionsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div:nth-child(2) {
    margin-bottom: 20px;
    margin-top: 40px;
  }
`;

const NoDataMessageWrapper = styled.div`
  margin: 100px 0px;
`;

const ModalContentWrapper = styled.div`
  && .table {
    border: 0px solid !important;
    overflow: auto;
  }
`;

const SummaryText = styled(Text)`
  margin-bottom: 12px;
`;

const SummaryValuesText = styled(Text)`
  margin-bottom: 24px;
`;
