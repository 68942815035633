/**
 * CertificatesDetails selectors
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getUser = (state: RootState) => state.App.user;
export const getRegistrations = (state: RootState) => state.App.user.registrations;
export const getRegistrationId = (state: RootState) =>
  state.StudentRegistrationDropdown.registration;
export const getCertificatesDetailsSlices = (state: RootState) => state.CertificatesDetails;
