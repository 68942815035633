/**
 * App reducers
 * Please write a description
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 * @format
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegistration } from 'shared/types';
import { IRoutes } from 'routes';

import { IRole } from 'config';

export interface IUser {
  name: string;
  fullName?: string;
  roles: IRole[];
  email?: string;
  accessToken: string;
  refreshToken?: string;
  idToken?: string;
  tokenType?: string;
  registrations?: IRegistration[] | null;
  nmec?: number | string;
  scopes: string[];
  impersonate?: string;
  expiresIn?: any;
}

export interface ISignin {
  code: string;
  state?: string;
  sessionState?: string;
}

export type ITranslation = any;

const initialState = {
  user: {
    name: '',
    roles: ['guest'],
    accessToken: '',
    registrations: [],
    scopes: [],
  } as IUser,
  routingStructure: [] as IRoutes[],
  translationsEdit: false,
  currentLocale: '',
  apiTranslations: [] as any[],
  isSidebarOpen: false,
  startingApp: true,
};

export default createSlice({
  name: 'App',
  initialState,
  reducers: {
    onMount: () => {},
    setRoutingStructure: (state, action: PayloadAction<IRoutes[]>) => {
      state.routingStructure = action.payload;
    },
    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },
    toggleApiTranslationsEdit: () => {},
    setTranslationsEdit: (state, action: PayloadAction<boolean>) => {
      state.translationsEdit = action.payload;
    },
    setApiTranslations: (state, action: PayloadAction<any[]>) => {
      state.apiTranslations = action.payload;
    },
    updateApiTranslation: (state, action: PayloadAction<ITranslation>) => {},
    navigateTo: (
      state,
      action: PayloadAction<{
        key: string;
        params?: any;
        state?: any;
        forceRefresh?: boolean;
        hash?: string;
      }>
    ) => {},
    navigateBack: () => {},
    changeLocale: (state, action: PayloadAction<string>) => {},
    setCurrentLocale: (state, action: PayloadAction<string>) => {
      state.currentLocale = action.payload;
    },
    setStartingApp: (state, action: PayloadAction<boolean>) => {
      state.startingApp = action.payload;
    },
    onFirstSignIn: () => {},
    updateUserRole: (state, action: PayloadAction<string>) => {},
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    signout: () => {},
    impersonateUser: (state, action: PayloadAction<string>) => {},
    cleanImpersonateUser: () => {},
  },
});
