/**
 * NewApplicationSubmission container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';
import API from 'api';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-light-svg-icons';

import { Grid } from 'semantic-ui-react';
import { AnimatedBackground, Text, Segment } from '@paco_ua/pacoui';

import { componentTemplateParser } from '../../utils';

import FileDownloader from 'shared/components/FileDownloader';

const NewApplicationSubmission = () => {
  const theme = useContext(ThemeContext);

  const user = useSelector(selectors.getUser);
  const newApplicationData = useSelector(selectors.getNewApplicationData);
  const newApplicationTabLoading = useSelector(selectors.getNewApplicationTabLoading);
  const { errorStatus } = useSelector(selectors.getNewApplicationSlice);

  const registration =
    user.registrations &&
    user.registrations.find(
      (registration) => registration.registrationId === newApplicationData.registrationId
    );
  const statement = newApplicationData.form && newApplicationData.form.exposition.text;
  const { formValues } = newApplicationData;

  const { setLoadingStatus } = actions.NewApplication;
  const dispatch = useDispatch();

  const StudentName = formValues ? formValues.expoFields.find(
    (key) => key.nameKey === 'nomeestudante'
  ).value : user.name;

  useEffect(() => {
    dispatch(
      setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }, [dispatch, setLoadingStatus]);

  if (newApplicationTabLoading) {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
        <Segment>
          <LoadingWrapper>
            <AnimatedBackground height={theme.sizes.xxLarge} width="80%" />
          </LoadingWrapper>
        </Segment>
      </Grid.Column>
    );
  } else {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
        <Segment>
          <SegmentTitleWrapper>
            <Text size="large" color={theme.colors.plusDarkGrey}>
              {t('applications.requestFourthStepTitle')}
            </Text>
            <DescriptionText size="article" color={theme.colors.darkGrey}>
              {t('applications.requestFourthStepDescription')}
            </DescriptionText>
          </SegmentTitleWrapper>
          <ApplicationData>
            <ApplicationDataNameAndCourse>
              <ApplicationDataBlock>
                <Text size="xSmall" color={theme.colors.grey}>
                  {t('applications.requestFourthStepName')}
                </Text>
                <TextWrapper size="mediumSmall" color={theme.colors.plusDarkGrey}>
                  {StudentName}
                </TextWrapper>
              </ApplicationDataBlock>
              <ApplicationDataBlock>
                <Text size="xSmall" color={theme.colors.grey}>
                  {t('applications.requestFourthStepCourse')}
                </Text>
                <RequestText size="mediumSmall" color={theme.colors.plusDarkGrey}>
                  {registration && `${registration.courseCode} - ${registration.courseName}`}
                </RequestText>
              </ApplicationDataBlock>
            </ApplicationDataNameAndCourse>
            <ApplicationDataApplicationType>
              <ApplicationDataBlock>
                <Text size="xSmall" color={theme.colors.grey}>
                  {t('applications.requestFourthStepApplicationType')}
                </Text>
                <RequestText size="mediumSmall" color={theme.colors.plusDarkGrey}>
                  {newApplicationData.type.name}
                </RequestText>
              </ApplicationDataBlock>
            </ApplicationDataApplicationType>
          </ApplicationData>

          <ApplicationStatementWrapper>
            <ApplicationStatementHeading>
              <Text size="medium" color={theme.colors.primary} fontWeight="500">
                {t('applications.requestFourthStepStatementHeading')}
              </Text>
            </ApplicationStatementHeading>
            <ApplicationStatement>
              <ApplicationStatementText size="mediumSmall" color={theme.colors.plusDarkGrey}>
                {newApplicationData.form &&
                  componentTemplateParser(
                    newApplicationData.form.expoFields.values,
                    statement,
                    newApplicationData.form.expoFields.values.reduce((acc, field, index) => {
                      const formValueField =
                        newApplicationData.formValues &&
                        newApplicationData.formValues.expoFields.find(
                          (expoFieldsField) => expoFieldsField.nameKey === field.nameKey
                        );
                      acc[field.nameKey] = (
                        <ApplicationStatementFieldText
                          key={`applicationStatementFieldText-${index}`}
                          size="mediumSmall"
                          fontWeight="500"
                          color={theme.colors.plusDarkGrey}
                        >
                          {formValueField && `${formValueField.value}\u00A0`}
                        </ApplicationStatementFieldText>
                      );
                      return acc;
                    }, {}),
                    false
                  )}
              </ApplicationStatementText>
              {formValues && formValues.observations && (
                <ApplicationStatementObservations>
                  <Text size="medium" color={theme.colors.plusDarkGrey} fontWeight="500">
                    {t('applications.requestFourthStepStatementObservationsTitle')}
                  </Text>
                  <TextWrapper size="mediumSmall" color={theme.colors.plusDarkGrey}>
                    {formValues.observations}
                  </TextWrapper>
                </ApplicationStatementObservations>
              )}

              {formValues &&
                formValues.mandatoryDocuments &&
                !!formValues.mandatoryDocuments.length && (
                  <ApplicationMandatoryDocuments>
                    <ApplicationMandatoryDocumentsTitle
                      size="medium"
                      color={theme.colors.plusDarkGrey}
                      fontWeight="500"
                    >
                      {t('applications.requestFourthStepMandatoryFilesTitle')}
                    </ApplicationMandatoryDocumentsTitle>
                    {formValues.mandatoryDocuments.map((document, index) => (
                      <FileDownloaderWrapper
                        key={`applicationMandatoryDocumentDownloader-${index}`}
                      >
                        <FileDownloader
                          heading={document.nameKey}
                          description={document.fileName}
                          url={document.url}
                          icon={<FontAwesomeIcon icon={faFilePdf} />}
                          callToEndpoint={(endpoint, setLoading: (value) => void) => {
                            setLoading(true);

                            API.files.getFileOnTab
                              .call(endpoint)
                              .then((value) => {
                                setLoading(false);
                              })
                              .catch((e) => {
                                setLoading(false);
                                dispatch(
                                  actions.Toaster.showToaster({
                                    title: t('secVirtualNotifications.general_errorLoadingFile'),
                                    icon: 'error',
                                    type: 'danger',
                                  })
                                );
                              });
                          }}
                        />
                      </FileDownloaderWrapper>
                    ))}
                  </ApplicationMandatoryDocuments>
                )}

              {formValues &&
                formValues.additionalDocuments &&
                !!formValues.additionalDocuments.length && (
                  <ApplicationAdditionalDocuments>
                    <ApplicationAdditionalDocumentsTitle
                      size="medium"
                      color={theme.colors.plusDarkGrey}
                      fontWeight="500"
                    >
                      {t('applications.requestFourthStepAdditionalFilesTitle')}
                    </ApplicationAdditionalDocumentsTitle>
                    {formValues.additionalDocuments.map((document, index) => (
                      <FileDownloaderWrapper
                        key={`applicationAdditinalDocumentDownloader-${index}`}
                      >
                        <FileDownloader
                          heading={
                            document.nameKey.includes('otherDocuments')
                              ? t('applications.otherDocuments', { textOnly: true })
                              : document.nameKey
                          }
                          description={document.fileName}
                          url={document.url}
                          icon={<FontAwesomeIcon icon={faFilePdf} />}
                          callToEndpoint={(endpoint, setLoading: (value) => void) => {
                            setLoading(true);

                            API.files.getFileOnTab
                              .call(endpoint)
                              .then((value) => {
                                setLoading(false);
                              })
                              .catch((e) => {
                                setLoading(false);
                                dispatch(
                                  actions.Toaster.showToaster({
                                    title: t('secVirtualNotifications.general_errorLoadingFile'),
                                    icon: 'error',
                                    type: 'danger',
                                  })
                                );
                              });
                          }}
                        />
                      </FileDownloaderWrapper>
                    ))}
                  </ApplicationAdditionalDocuments>
                )}
            </ApplicationStatement>
          </ApplicationStatementWrapper>
        </Segment>
      </Grid.Column>
    );
  }
};

export default NewApplicationSubmission;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

const ApplicationData = styled.div`
  margin-top: 56px;
`;

const ApplicationDataNameAndCourse = styled.div`
  display: flex;

  & > div {
    flex: 0 50%;
  }
`;

const ApplicationDataApplicationType = styled.div`
  margin-top: 28px;
`;

const ApplicationDataBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const ApplicationStatementWrapper = styled.div`
  margin-top: 48px;
`;

const ApplicationStatementHeading = styled.div``;

const ApplicationStatement = styled.div`
  margin-top: 12px;

  .ui.placeholder {
    max-width: 80%;
  }
`;

const ApplicationStatementText = styled(Text)`
  display: inline-block;
`;

const ApplicationStatementFieldText = styled(Text)`
  display: inline-block;
`;

const ApplicationStatementObservations = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

const ApplicationMandatoryDocuments = styled.div`
  margin-top: 40px;
`;

const ApplicationMandatoryDocumentsTitle = styled(Text)`
  margin-bottom: 12px;
`;

const ApplicationAdditionalDocuments = styled.div`
  margin-top: 40px;
`;

const ApplicationAdditionalDocumentsTitle = styled(Text)`
  margin-bottom: 12px;
`;

const FileDownloaderWrapper = styled.div`
  margin-top: 12px;
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextWrapper = styled(Text)`
  margin-top: 12px;
`;

const RequestText = styled(Text)`
  margin-top: 12px;
  line-height: 1.3;
`;

const DescriptionText = styled(Text)`
  margin-top: 8px;
`;
