import signin from './calls/signin';
import student from './calls/student';
import sgh from './calls/sgh';
import routes from './calls/search';
import translations from './calls/translations';
import secVirtual from './calls/secVirtual';
import files from './calls/files';
import search from './calls/search';
import pautas from './calls/pautas';

import { IScope } from 'config/types';

//export { default as Schemas } from './schemas';

export interface IApiCall {
  call: any;
  scope?: IScope[] | IScope;
}

export default {
  routes,
  signin,
  student,
  sgh,
  translations,
  secVirtual,
  files,
  search,
  pautas,
};

export interface IParams {
  search?: string;
  type?: string;
  state?: string;
  offset?: number;
  limit?: number;
}
