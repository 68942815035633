/**
 * NewCertificateStatement container logic selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUser = (state: RootState) => state.App.user;
export const getNewCertificateData = (state: RootState) => state.NewCertificate.certificateData;
export const getNewCertificateNumAdditionalDocs = (state: RootState) =>
  state.NewCertificate.numAdditionalDocs;
export const getNewCertificateSlice = (state: RootState) => state.NewCertificate;
