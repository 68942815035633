/**
 * StudentRegistrationDropdown scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getRegistrationSlices = (state: RootState) => state.StudentRegistrationDropdown;
export const getUser = (state: RootState) => state.App.user;
