export const ASSOCIATED_COSTS_TYPES = {
  lateFeeCost: {
    name: 'lateFeeCost',
    unit: 'days',
  },
  ucCost: {
    name: 'lateFeeCost',
    unit: 'ucs',
  },
  certCost: {
    name: 'unit',
    unit: 'copies',
  },
  mailCost: {
    name: 'deliveryFee',
    unit: 'delivery',
  },
};

interface IPaymentMethods {
  MB: string;
  VISA: string;
  MBWAY: string;
}

export const PAYMENT_METHODS: IPaymentMethods = {
  MB: 'mb',
  MBWAY: 'mbway',
  VISA: 'visa',
};

export interface IMbwayResponse {
  entity: number;
  ref: number;
  value: string;
  dueDate: string;
}
