import React, { useEffect, useState } from "react";
import Modal from "shared/components/Modal";
import styled from "styled-components";
import { Text } from '@paco_ua/pacoui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from "react-redux";
import * as selectors from '../logic/selectors';
import { t } from 'app';

const LabelsModalHeader = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-bottom: 1rem;
`

const LabelsModalItem = styled.div`
display: flex;
flex-direction: column;
margin-bottom: 1rem;
`

const HoverCloseIcon = styled.div`
  cursor:pointer;
`


interface SpecialGrade {
  valor: string;
  descricao: string;
}

interface Regime {
  code: string;
  name: string;
}

const LabelsModal = ({ onClick }) => {
  const dicionarios = useSelector(selectors.selectDicionarios);
  const regimes = useSelector(selectors.selectRegimes);
  const hdados = useSelector(selectors.selectHDADOS);
  const [bodyItems, setBodyItems] = useState<SpecialGrade[]>([]);
  const [regimeItems, setRegimeItems] = useState<Regime[]>([]);

  useEffect(() => {
    if (dicionarios['data'] && dicionarios['data'].length > 0 && dicionarios['data'][0].bodyItems.length > 0) {
      setBodyItems(dicionarios['data'][0].bodyItems);
    }
    if (regimes['data'] && regimes['data'].length > 0) {
      setRegimeItems(regimes['data']);
    }
  }, [dicionarios, regimes]);

  return (
    <Modal changeModal={onClick}>
      <LabelsModalHeader>
        <Text>{t('pautas.codes')}</Text>
        <HoverCloseIcon>
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: '1.2rem' }} onClick={onClick}></FontAwesomeIcon>
        </HoverCloseIcon>
      </LabelsModalHeader>
      <LabelsModalItem>
        <Text>{t('pautas.evaluationResultCodes')}: {hdados.tipoAvaliacao.nomeTipoAvaliacao}</Text>
        {bodyItems.map((item, index) => (
          <Text key={index}>{item.valor} - {t(`pautas.evaluationCode${item.valor}`)}</Text>
        ))}
      </LabelsModalItem>
      <LabelsModalItem>
        <Text>{t('pautas.examTypes')}:</Text>
        <Text>NM - {t('pautas.normalExam')}</Text>
        <Text>RE - {t('pautas.appealExam')}</Text>
        <Text>ML - {t('pautas.improvementExam')}</Text>
        <Text>DZ - {t('pautas.specialExam')}</Text>
        <Text>SV - {t('pautas.summerSemester')}</Text>
        <Text>SE - {t('pautas.extraordinarySemester')}</Text>
        <Text>RA - {t('pautas.earlyAppeal')}</Text>
      </LabelsModalItem>
      <LabelsModalItem>
        <Text>{t('pautas.studentAttendanceRegimes')}</Text>
        {regimeItems.map((item, index) => (
          <Text key={index}>{item.code} - {t(`pautas.studentAttendanceRegime${item.code}`)}</Text>
        ))}
      </LabelsModalItem>
    </Modal>
  )
}

export default LabelsModal;