/**
 * App
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState, useRef, useEffect, useCallback } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, withRouter, Redirect } from 'react-router';

import { ThemeProvider as UAThemeProvider, Theme, GoToTopButton } from '@paco_ua/pacoui';

import GlobalStyles from 'ui/globalStyles';
import config from 'config';
import { actions } from 'store/rootSlices';

import AppTools from 'app/AppTools';
import SuperUserTools from 'app/SuperUserTools';
import Translation from 'app/Translation';

import Toaster from 'shared/containers/Toaster';
import ToasterAction from 'shared/containers/ToasterAction';
import Breadcrumb from 'shared/containers/Breadcrumb';
import Header from 'shared/containers/Header';
import SystemBar from 'shared/containers/SystemBar';
import PageStructure from 'shared/containers/PageStructure';

import Sidebar from 'shared/components/Sidebar';
import Footer from 'shared/components/Footer';
import AuthWrapper from 'shared/components/AuthWrapper';

import InMaintenanceScene from 'scenes/InMaintenanceScene';

import { i18nInstance, addRouteToHistory, getCurrentRoute } from 'utils';

import { redirectAuthUrl } from './utils';

import * as selectors from './logic/selectors';

export const t = (key, options?: any) => {
  if (options && options.textOnly) {
    let { textOnly, ...tOptions } = options;
    return i18nInstance.t(key, tOptions);
  } else {
    return <Translation tKey={key} options={options} />;
  }
};

function App({ location }) {
  const { onMount } = actions.App;
  const user = useSelector(selectors.getUser);
  const { startingApp, routingStructure } = useSelector(selectors.getAppSlice);

  const dispatch = useDispatch();

  const [showGoToTopButton, setShowGoToTopButton] = useState(false);
  const showGoToTopButtonRef = useRef(false);

  const [shoulRenderSideBar, setShoulRenderSideBar] = useState(false);

  const [ref, setRef] = useState(null as null | HTMLDivElement);

  const handleOnScroll = (evt) => {
    const scrollTop = evt.target.scrollTop;
    if (scrollTop > 120 && !showGoToTopButtonRef.current) {
      setShowGoToTopButton(true);
    } else if (scrollTop <= 120 && showGoToTopButtonRef.current) {
      setShowGoToTopButton(false);
    }
  };

  const onRefSet = useCallback((node) => {
    if (node !== null) {
      setRef(node);
      node.addEventListener('scroll', handleOnScroll);
    }
  }, []);

  const onSystemBarSet = useCallback((node) => {
    if (node !== null) {
      if (node.__authConfig) {
        //TODO UA needs to refactor this asap... it is not working because requires login to be done with their clientId
        //node.__authConfig.login = redirectAuthUrl() + '&/null=&#x22;';
        node.__authConfig.clientId = '';
        node.__authConfig.callbackUri = '&#x22;';
      }
    }
  }, []);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      if (ref !== null) {
        ref.removeEventListener('scroll', handleOnScroll);
      }
    };
  }, [dispatch, onMount]);

  useEffect(() => {
    showGoToTopButtonRef.current = showGoToTopButton;
  }, [showGoToTopButton]);

  useEffect(() => {
    addRouteToHistory();
  }, [location]);

  const routingRules = (route) => {
    if (route) {
      if (getCurrentRoute().route.key === 'inMaintenance') {
        return <Redirect to={'/'} />;
      }

      return (
        <AuthWrapper
          checkSuperUser
          roles={route.roles}
          fallback={<Redirect to={'/access-denied'} />}
        >
          {verifyRoute(route)}
        </AuthWrapper>
      );
    } else {
      if (location.pathname === '/backoffice/sgh') {
        window.open('https://api.paco20-play-aws.ua.pt/sgh_backoffice/admin', '_self');
        return <Redirect to={'/'} />;
      } else if (!getCurrentRoute()) {
        return <Redirect to={'/page-not-found'} />;
      }
    }
  };

  const verifyRoute = (route) => {
    const now = new Date();
    const currentHour = now.getHours();
    if (!route.component || route.inMaintenance) {
      return <InMaintenanceScene t={t} />;
    }
    return <route.component theme={Theme} t={t} />;
  };

  const handleGoToTop = () => {
    if (ref !== null) {
      ref.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    handleGoToTop();
  }, [location.pathname]);

  return (
    <UAThemeProvider theme={Theme}>
      <ThemeProvider theme={Theme}>
        <GlobalStyles />
        <AppWrapper ref={onRefSet} id="appBody">
          {/* <SystemBar ref={onSystemBarSet} publicLinks={JSON.stringify([])} containerFluid />*/}
          <SystemBar />

          <StickyToaster>
            <Toaster />
            <ToasterAction />
          </StickyToaster>

          <Switch>
            {routingStructure
              .filter((route) => route.detached && !route.disabled && route.path)
              .map((route) => (
                <Route
                  exact={route.exact}
                  key={route.key}
                  path={
                    route.path &&
                    Object.keys(route.path).map((key) =>
                      key && route.path ? route.path[key] : null
                    )
                  }
                  render={() => routingRules(route)}
                />
              ))}
            {/*localStorage.getItem('openSystem') !== 'true' && <Redirect to={'/maintenance'} />*/}

            <Route path="/">
              <StickyHeader>
                <Header hasSidebar={shoulRenderSideBar} />
              </StickyHeader>

              <RouteContainer>
                {!startingApp && (
                  <Sidebar
                    onReady={(hasRoutes) => {
                      setShoulRenderSideBar(hasRoutes);
                    }}
                    userRoles={user.roles}
                  />
                )}

                <RouteWrapper>
                  <Breadcrumb loading={startingApp} />

                  {startingApp && (
                    <PageStructure
                      loading={true}
                      headerLeft={<div></div>}
                      mainContent={<div></div>}
                    />
                  )}
                  {!startingApp && (
                    <>
                      {routingStructure
                        .filter((route) => !route.detached && !route.disabled && route.path)
                        .map((route) => (
                          <Route
                            exact={route.exact}
                            key={route.key}
                            path={
                              route.path &&
                              Object.keys(route.path).map((key) =>
                                key && route.path ? route.path[key] : null
                              )
                            }
                            render={() => routingRules(route)}
                          />
                        ))}
                      <FooterWrapper>
                        <Footer />
                      </FooterWrapper>
                    </>
                  )}
                </RouteWrapper>
                <GoToTopButton showButton={showGoToTopButton} onClick={() => handleGoToTop()} />
              </RouteContainer>
              <Route path="*" render={() => routingRules(null)} />
            </Route>
          </Switch>
        </AppWrapper>

        {config.SHOW_APPTOOLS && <AppTools />}
        <AuthWrapper
          roles={[
            'superUser_FullAccess',
            'superUser_ReadOnly',
            'sgh_superUser_ReadOnly',
            'sgh_superUser_FullAccess',
            'sec_superUser_ReadOnly',
            'sec_superUser_FullAccess',
            'pautas_superUser_ReadOnly',
            'pautas_superUser_FullAccess',
          ]}
        >
          <SuperUserTools />
        </AuthWrapper>
      </ThemeProvider>
    </UAThemeProvider>
  );
}

export default withRouter(App);

const AppWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
  overflow-y: auto;
`;

const RouteContainer = styled.div`
  display: flex;
  flex: 1;
  max-width: 100vw;
`;

const RouteWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  width: 0;
  flex-direction: column;
  padding: 0px 40px;

  @media only screen and (max-width: 767px) {
    padding: 0px 10px;
  }
`;

const FooterWrapper = styled.footer`
  margin: 120px -40px 0;
  background-color: rgb(0, 0, 0);

  @media only screen and (max-width: 767px) {
    margin: 120px -10px 0;
  }
`;

const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 999;
`;

const StickyToaster = styled.div`
  position: sticky;
  top: 0;
  z-index: 9999;
`;
