import React from 'react';
import styled from "styled-components";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 31;
  z-index: 1102;
`;

const ModalWrapper = styled.div`
  background-color: #fff;
  width: 35%;
  position: fixed;
  padding: 1rem;
  z-index: 1103;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface ModalProps {
  children: React.ReactNode;
  changeModal: (id?: number) => void;
}

const Modal: React.FC<ModalProps> = (props) => {

  const hideModal = () => {
    props.changeModal();
  }

  return (
    <>
      <Backdrop onClick={hideModal}></Backdrop>
      <ModalWrapper>{props.children}</ModalWrapper>
    </>
  )
}


export default Modal;