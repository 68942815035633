/**
 * Debits Table container logic
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';

import { ITranslations, IPagination } from '@paco_ua/pacoui';
import { DEFAULT_STRUCTURE } from './utils';

import { DEFAULT_PAGINATION, IFreeOption } from './types';

import * as selectors from './logic/selectors';

export default (
  t,
  freeOptions: IFreeOption[],
  compileTableRow,
  total: number,
  fetchFreeOptions,
  filterParams,
  registrationId,
  onSelectRow,
  mandatoryFilters,
  ucs
) => {
  const theme = useContext(ThemeContext);
  const [structure, setStructure] = useState(DEFAULT_STRUCTURE(t));
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION(total));
  const dispatch = useDispatch();

  const freeOptionsListSlice = useSelector(selectors.getFreeOptionsListSlice);

  const [initialFetch, setInitialFetch] = useState(true);

  // initial debits fetch
  useEffect(() => {
    if (initialFetch) {
      setInitialFetch(false);
    } else {
      //setPagination(DEFAULT_PAGINATION(total));
      // fetch new list
      fetchFreeOptions({
        limit: freeOptionsListSlice.freeOptionsPage.limit,
        offset: 0,
        mandatoryFilters,
      });
    }
  }, [filterParams]);

  // update pagination when total value is changed (when filtering)
  useEffect(() => {
    let updatePagination = { ...pagination };
    updatePagination.total = total;

    setPagination(updatePagination);
  }, [total]);

  useEffect(() => {
    handlePaginationNavigation(pagination);
  }, [pagination]);

  // Update table when new debits are fetched,
  // or to disable the payment buttons when multiple debits are selected
  useEffect(() => {
    const tableStructure = compileTableStructure(t, freeOptions, theme);
    setStructure(tableStructure);
  }, [freeOptions]);

  const compileTableStructure = (t: ITranslations, freeOptions: IFreeOption[], theme) => ({
    header: {
      type: 'columnTitle',
      titles: [
        { text: t('sgh.department', { textOnly: true }) },
        { text: t('sgh.scientificField', { textOnly: true }) },
        { text: t('sgh.code', { textOnly: true }) },
        { text: t('sgh.uc', { textOnly: true }) },
        //{ text: t('sgh.workload', { textOnly: true }) },
        { text: t('sgh.ects', { textOnly: true }) },
        { text: t('sgh.slots', { textOnly: true }) },
        { text: t('sgh.freeSlots', { textOnly: true }) },
        { text: '' },
      ],
    },
    rows: !freeOptions.length
      ? ([] as any)
      : [
          ...freeOptions.map((freeOption) =>
            compileTableRow(freeOption, theme, t, registrationId, onSelectRow, dispatch, ucs)
          ),
        ],
  });

  const handlePaginationNavigation = (newPagination: IPagination) => {
    const exceedsTotal = newPagination.limit >= newPagination.total;

    if (exceedsTotal) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = false;
    }

    if (!exceedsTotal && newPagination.offset < newPagination.limit) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = true;
    }

    if (!exceedsTotal && newPagination.offset >= newPagination.limit) {
      newPagination.hasPrevious = true;
      newPagination.hasNext =
        newPagination.offset + newPagination.limit >= newPagination.total ? false : true;
    }

    setPagination(newPagination);
  };

  const onChangeRows = (value: number): void => {
    const newPagination = { ...pagination };
    newPagination.limit = value;
    newPagination.offset = 0;

    handlePaginationNavigation(newPagination);
    setPagination(newPagination);
    fetchFreeOptions({ ...newPagination, mandatoryFilters });
  };

  const onChangePage = (value: string): void => {
    const newPagination = { ...pagination };

    switch (value) {
      case 'prev':
        newPagination.offset -= newPagination.limit;
        break;
      case 'next':
        newPagination.offset += newPagination.limit;
        break;
      default:
        break;
    }

    handlePaginationNavigation(newPagination);
    setPagination(newPagination);
    fetchFreeOptions({ ...newPagination, mandatoryFilters });
  };

  return {
    theme,
    structure,
    pagination,
    onChangePage,
    onChangeRows,
  };
};
