/**
 * RegistrationDetails scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { Segment, Text, StatusLabel, Table, InfoBox } from '@paco_ua/pacoui';

import EligibilityGraph from './containers/EligibilityGraph';

import { getEligibilityState } from '../Registrations/utils';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

//import config from 'config'

const RegistrationDetails = ({ t, i18n }) => {
  const { onMount, onUnmount } = actions.RegistrationDetails;

  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  const user = useSelector(selectors.getUser);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  const {
    loading,
    errorStatus,
    registrationGeneralInfo,
    scholarship,
    eligibility,
    history,
  } = useSelector(selectors.getRegDetailsSlice);

  const eligibilityHistoryTableTitles = [
    {
      text: t('registrations.detailsEligibilityHistoryEligibility'),
      style: {
        textAlign: 'center',
        fontWeight: 400,
        wordBreak: 'keep-all',
        wordWrap: 'normal',
      },
    },
    {
      text: t('registrations.detailsEligibilityHistoryScholarYear'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryAccumulatedECTS'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryECTSEarned'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryAccumulatedSignups'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryScholarYearCoef'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryCoefType'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryECTSToRelease'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
    {
      text: t('registrations.detailsEligibilityHistoryECTSEnrolled'),
      style: { textAlign: 'center', fontWeight: 400, wordBreak: 'keep-all', wordWrap: 'normal' },
    },
  ];

  const getEligibilityColor = (status: number) => {
    if (status === 100) {
      return theme.colors.dangerRed;
    } else if (status === 1 || status === 2) {
      return theme.colors.successGreen;
    } else if (status === 25 || status === 50) {
      return theme.colors.decisionYellow;
    } else if (status === -1) {
      return theme.colors.grey;
    }
  };

  const getWidth = (field) => {
    if (field === 'eligibilityState' || field === 'academicYear' || field === 'typeAcademicYear') {
      return { width: 2 };
    }

    return null;
  };

  const eligibilityHistoryTableStructure = {
    header: {
      type: 'columnTitle',
      titles: eligibilityHistoryTableTitles,
    },
    rows: history.map((item) => {
      return {
        rowProps: { color: theme.colors.white },
        cells: Object.keys(item).map((field, index) => {
          return {
            content:
              field === 'eligibilityState' ? (
                item[field] ? (
                  <StatusLabelWrapper>
                    <StatusLabel
                      label={getEligibilityState(item[field])}
                      background={getEligibilityColor(item[field])}
                    />
                  </StatusLabelWrapper>
                ) : (
                  <FontAwesomeIcon icon={faEllipsisH} />
                )
              ) : item[field] !== null && item[field] !== undefined ? (
                <Text
                  size="small"
                  color={theme.colors.plusDarkGrey}
                  weight={'regular'}
                  wordBreak="keep-all"
                  wordWrap="normal"
                >
                  {item[field]}
                </Text>
              ) : (
                <FontAwesomeIcon icon={faEllipsisH} />
              ),
            cellProps: {
              ...getWidth(field),
              textAlign: 'center',
            },
          };
        }),
      };
    }),
  };

  const noData = () => {
    return (
      <Segment>
        <Text size="mediumSmall" color={theme.colors.plusDarkGrey} weight={'regular'}>
          {t('registrations.noData')}
        </Text>
      </Segment>
    );
  };

  return (
    <PageStructure
      loading={loading || errorStatus.errorRegistrationDetails}
      headerLeft={<Header pullBackButton title={t('registrations.detailsTitle')} backButton />}
      mainContent={
        <GridWrapper>
          <GridInnerWrapper>
            {!registrationGeneralInfo && noData()}
            {registrationGeneralInfo && (
              <Segment>
                <Text size="large" color={theme.colors.primary} weight={'regular'}>
                  {`${registrationGeneralInfo.courseCode} - ${registrationGeneralInfo.courseName}`}
                </Text>
                <RegistrationGeneralInfo>
                  <RegistrationGeneralInfoBlock>
                    <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                      {t('registrations.detailsRegistrationDate')}
                    </Text>
                    <Text size="mediumSamll" color={theme.colors.plusDarkGrey} weight={'regular'}>
                      {registrationGeneralInfo.registrationDate}
                    </Text>
                  </RegistrationGeneralInfoBlock>
                  <RegistrationGeneralInfoBlock>
                    <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                      {t('registrations.detailsScholarYear')}
                    </Text>
                    <Text size="mediumSamll" color={theme.colors.plusDarkGrey} weight={'regular'}>
                      {registrationGeneralInfo.registrationAcademicYear}
                    </Text>
                  </RegistrationGeneralInfoBlock>
                  <RegistrationGeneralInfoBlock>
                    <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                      {t('registrations.detailsAccessRegime')}
                    </Text>
                    <Text size="mediumSamll" color={theme.colors.plusDarkGrey} weight={'regular'}>
                      {registrationGeneralInfo.accessScheme}
                    </Text>
                  </RegistrationGeneralInfoBlock>
                  {registrationGeneralInfo.registrationStatus === 'ACTIVO' && (
                    <RegistrationGeneralInfoBlock>
                      <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                        {t('registrations.detailsCurricularYear')}
                      </Text>

                      <Text size="mediumSamll" color={theme.colors.plusDarkGrey} weight={'regular'}>
                        {registrationGeneralInfo.academicYear}
                      </Text>
                    </RegistrationGeneralInfoBlock>
                  )}

                  <RegistrationGeneralInfoBlock>
                    <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                      {t('registrations.detailsStudentTypology')}
                    </Text>
                    <Text size="mediumSamll" color={theme.colors.plusDarkGrey} weight={'regular'}>
                      {registrationGeneralInfo.typology}
                    </Text>
                  </RegistrationGeneralInfoBlock>
                </RegistrationGeneralInfo>
              </Segment>
            )}
          </GridInnerWrapper>

          <GridInnerWrapper>
            {scholarship && (
              <Segment>
                <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
                  {t('registrations.detailsScholarship')}
                </Text>
                <Scholarship>
                  <ScholarshipBlock>
                    <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                      {t('registrations.detailsScholarshipStatus')}
                    </Text>
                    <ScholarshipLabel>
                      <StatusLabel
                        label={
                          scholarship.scholarshipStatus === 'Deferida'
                            ? t('registrations.detailsScholarshipStatusTrue'): 
                            scholarship.scholarshipStatus === 'Em análise' 
                            ? t('registrations.detailsScholarshipStatusWaiting')
                            : t('registrations.detailsScholarshipStatusFalse')
                        }
                        background={
                          scholarship.scholarshipStatus === 'Deferida'
                            ? theme.colors.successGreen : 
                            scholarship.scholarshipStatus === 'Em análise'
                            ? theme.colors.decisionYellow
                            : theme.colors.dangerRed
                        }
                      />
                    </ScholarshipLabel>
                  </ScholarshipBlock>
                  <ScholarshipBlock>
                    <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                      {t('registrations.detailsScholarshipCategory')}
                    </Text>
                    <CategoryText
                      size="mediumSamll"
                      color={theme.colors.plusDarkGrey}
                      weight={'regular'}
                    >
                      {scholarship.scholarshipCategory}
                    </CategoryText>
                  </ScholarshipBlock>
                </Scholarship>
              </Segment>
            )}
          </GridInnerWrapper>
          <GridInnerWrapper>
            {eligibility && history.length && (
              <Segment>
                <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
                  {t('registrations.detailsEligibility')}
                </Text>
                <Eligibility>
                  <Segment padding={'24px'}>
                    <Text size="mediumSamll" color={theme.colors.primary} weight={'regular'}>
                      {t('registrations.detailsEligibilityInfo')}
                    </Text>
                    <EligibilityGeneralInfo>
                      <EligibilityGeneralInfoECTSToDo>
                        <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                          {t('registrations.detailsEligibilityInfoECTSToDo')}
                        </Text>
                        <DetailsWrapper size="xLarge" color={theme.colors.black} weight={'regular'}>
                          {eligibility.totalEctsToDo}
                        </DetailsWrapper>
                      </EligibilityGeneralInfoECTSToDo>
                      <EligibilityGeneralInfoECTSEarned>
                        <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                          {t('registrations.detailsEligibilityInfoECTSEarned')}
                        </Text>
                        <DetailsWrapper size="xLarge" color={theme.colors.black} weight={'regular'}>
                          {eligibility.totalEctsEarned}
                        </DetailsWrapper>
                      </EligibilityGeneralInfoECTSEarned>
                    </EligibilityGeneralInfo>
                  </Segment>

                  <Segment padding={'24px'}>
                    <Text
                      size="mediumSamll"
                      color={theme.colors.primary}
                      weight={'regular'}
                      whiteSpace={'nowrap'}
                    >
                      {`${t('registrations.detailsEligibilityCurrentYear', {
                        textOnly: true,
                      })} ${
                        registrationGeneralInfo && registrationGeneralInfo.academicYear
                          ? registrationGeneralInfo.academicYear
                          : ''
                      }`}
                    </Text>
                    <EligibilityCurrentYear>
                      <EligibilityCurrentYearECTSToDo>
                        <Text
                          size="xSmall"
                          color={theme.colors.grey}
                          weight={'regular'}
                          breakWord={false}
                        >
                          {t('registrations.detailsEligibilityCurrentYearECTSToDo')}
                        </Text>
                        <DetailsWrapper size="xLarge" color={theme.colors.black} weight={'regular'}>
                          {eligibility.currentYearEctsToDo}
                        </DetailsWrapper>
                      </EligibilityCurrentYearECTSToDo>
                      <EligibilityCurrentYearECTSEarned>
                        <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                          {t('registrations.detailsEligibilityCurrentYearECTSEarned')}
                        </Text>
                        <DetailsWrapper size="xLarge" color={theme.colors.black} weight={'regular'}>
                          {eligibility.currentYearEctsEarned}
                        </DetailsWrapper>
                      </EligibilityCurrentYearECTSEarned>
                      <EligibilityState>
                        <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                          {t('registrations.detailsEligibilityCurrentYearEligibility')}
                        </Text>
                        <EligibilityStateLabel>
                          <StatusLabel
                            label={getEligibilityState(eligibility.eligibilityState)}
                            background={getEligibilityColor(eligibility.eligibilityState)}
                          />
                        </EligibilityStateLabel>
                      </EligibilityState>
                    </EligibilityCurrentYear>
                  </Segment>
                </Eligibility>

                {eligibility && (
                  <EligibilityGraphWrapper>
                    <EligibilityGraph
                      data={{
                        eligibilityState: eligibility.eligibilityState,
                        signUpsAmount: eligibility.numberSignups,
                        ectsEarned: eligibility.totalEctsEarned,
                        minimumECTS: eligibility.graphMinEcts,
                        maximumECTS: eligibility.graphMaxEcts,
                      }}
                    />
                  </EligibilityGraphWrapper>
                )}

                <EligibilityHistory>
                  <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
                    {t('registrations.detailsEligibilityHistory')}
                  </Text>
                  <EligibilityHistoryTableWrapper>
                    <Table
                      unstackable
                      structure={eligibilityHistoryTableStructure}
                      headerCellPadding="20px 5px"
                    />
                  </EligibilityHistoryTableWrapper>
                </EligibilityHistory>
              </Segment>
            )}
          </GridInnerWrapper>
          <GridInnerWrapper>
            {eligibility && history.length && (
              <InfoBox
                title={t('registrations.detailsInfoEligibilityHeader')}
                link={t('links.registrationDetailsInfoEligibility', { textOnly: true })}
                padding={'42px 42px'}
              >
                <Text size="article" color={theme.colors.darkGrey} weight={'regular'}>
                  {t('registrations.detailsInfoEligibilityBody')}
                </Text>
              </InfoBox>
            )}
          </GridInnerWrapper>
        </GridWrapper>
      }
    />
  );
};

export default RegistrationDetails;

const GridWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const GridInnerWrapper = styled.div``;

const StatusLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const RegistrationGeneralInfo = styled.div`
  display: flex;
  margin-top: 28px;

  > div:not(:first-child) {
    margin-left: 40px;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    > div:not(:first-child) {
      margin: 40px 0 0 0;
    }
  }
`;

const RegistrationGeneralInfoBlock = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: 12px;
  }
`;

const Scholarship = styled.div`
  display: flex;
  margin-top: 28px;

  > div:not(:first-child) {
    margin-left: 40px;
  }
`;

const ScholarshipBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScholarshipLabel = styled.div`
  margin-top: 12px;
`;

const Eligibility = styled.div`
  display: flex;
  margin-top: 28px;
  overflow: auto;

  > div {
    flex: 1;
  }

  > div:not(:first-child) {
    margin-left: 40px;
  }

  @media (max-width: 992px) {
    flex-direction: column;

    > div:not(:first-child) {
      margin: 40px 0 0 0;
    }
  }
`;

const EligibilityGeneralInfo = styled.div`
  display: flex;
  margin-top: 26px;

  > div:not(:first-child) {
    margin-left: 40px;
  }
`;

const EligibilityGeneralInfoECTSToDo = styled.div`
  display: flex;
  flex-direction: column;
`;

const EligibilityGeneralInfoECTSEarned = styled.div`
  display: flex;
  flex-direction: column;
`;

const EligibilityCurrentYear = styled.div`
  display: flex;
  margin-top: 28px;

  > div:not(:first-child) {
    margin-left: 40px;
  }
`;

const EligibilityCurrentYearECTSToDo = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

const EligibilityCurrentYearECTSEarned = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
`;

const EligibilityState = styled.div`
  display: flex;
  flex-direction: column;
`;

const EligibilityStateLabel = styled.div`
  margin-top: 14px;
`;

const EligibilityGraphWrapper = styled.div`
  margin: 28px 0 48px 0;
`;

const EligibilityHistory = styled.div``;

const EligibilityHistoryTableWrapper = styled.div`
  width: 100%;
  margin-top: 28px;
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;

const DetailsWrapper = styled(Text)`
  margin-top: 12px;
`;

const CategoryText = styled(Text)`
  margin-top: 16px;
`;
