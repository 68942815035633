/**
 * AuthWrapper component tests
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React from 'react';

import { useSelector } from 'react-redux';
import * as selectors from './selectors';

import { IRole, IScope } from 'config';

interface IProps {
  children?: any;
  scopes?: IScope[];
  roles?: IRole[];
  fallback?: any;
  checkSuperUser?: boolean;
}

const AuthWrapper: React.FC<IProps> = ({ roles, scopes, fallback, children, checkSuperUser }) => {
  const user = useSelector(selectors.getUser);
  let shouldRender = false;

  if (roles) {
    shouldRender = roles.some((role) => {
      // we verify super user in order to onpy give access to certain routes if impersonating someone
      // later we need to check the user we are impersonating roles and not our roles
      if (checkSuperUser) {
        if (role.toLowerCase().includes('superuser')) {
          if (user.impersonate) {
            return true;
          } 
        }
      }
      return user.roles.includes(role);
    });
  } else {
    shouldRender = true;
  }

  if (scopes) {
    shouldRender =
      roles && !shouldRender ? false : scopes.some((scope) => user.scopes.includes(scope));
  }

  if (shouldRender) {
    if (!children) {
      return fallback;
    }
    return children;
  } else {
    if (fallback) {
      return fallback;
    }
    return null;
  }
};

export default AuthWrapper;
