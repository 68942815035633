import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import API from 'api';
import { getValidGradeValues, compressGradeData } from '../utils.js';
import { getCurrentRoute } from 'utils';
import { t } from 'app';
import ErrorHandler from 'shared/errorHandler';

const { navigateTo, navigateBack } = actions.App;

function* fetchGradeData(action) {
  try {
    const response = yield call(API.pautas.getDecryptedGrade.call, action.payload);
    const gradeDetails = yield call(API.pautas.getGradeDetails.call, action.payload);
    let hDados, bDados, fDados, dicionarios, regimes = {};

    response.forEach((item) => {
      switch (item.type) {
        case 'HDADOS':
          hDados = item;
          break;
        case 'BDADOS':
          bDados = item.data;
          break;
        case 'FDADOS':
          fDados = item;
          break;
        case 'Dicionarios':
          dicionarios = item;
          break;
        case 'Regimes':
          regimes = item;
          break;
      }
    });

    yield put(actions.EditGrades.setHDADOS(hDados));
    yield put(actions.EditGrades.setGradeData(bDados));
    yield put(actions.EditGrades.setFDADOS(fDados));
    yield put(actions.EditGrades.setDicionarios(dicionarios));
    yield put(actions.EditGrades.setRegimes(regimes));
    yield put(actions.EditGrades.setGradeDetails(gradeDetails));

    const validValues = getValidGradeValues(hDados, dicionarios);
    yield put(actions.EditGrades.setValidGradeValues(validValues));

  } catch (error: any) {
    console.error('Error in fetchGradeData:', error);
    const shouldRun = yield call(ErrorHandler, error);
  }
  yield put(
    actions.EditGrades.setLoadingStatus({
      fieldName: 'loadingGradeData',
      fieldValue: false,
    })
  );
  yield put(
    actions.EditGrades.setLoadingStatus({
      fieldName: 'loadingTable',
      fieldValue: false,
    })
  );
}

function* saveOrSubmitGradesSaga(action) {
  try {
    yield put(
      actions.EditGrades.setLoadingStatus({
        fieldName: 'loadingTable',
        fieldValue: true,
      })
    );
    const { HDADOS, examDate } = yield select(state => state.EditGrades);

    const editedGradesData = action.payload.gradesEdits;
    const isSubmission = action.payload.isSubmission;
    const codigoPauta = action.payload.codigoPauta;
    
    // Prepare the data in the required format
    const studentsGrades = Object.entries(editedGradesData).map(([nmec, grade]) => ({
      nmec,
      grade
    }));
  
    // Return the complete object
    const formattedData = {
      codigo_pauta: codigoPauta,
      data_exame: (examDate || HDADOS.dataExame), 
      students_grades: studentsGrades
    };

    const compressedData = compressGradeData(formattedData);

    isSubmission ?
      yield call(API.pautas.putFinalGrade.call, HDADOS.codigoPauta, compressedData) : 
      yield call(API.pautas.putIntermediateGrade.call, HDADOS.codigoPauta, compressedData);

    if (isSubmission) {
      yield put(navigateTo({key: getCurrentRoute().route.childOf}));
    }
    else {
      yield call(fetchGradeData, { "payload": HDADOS.codigoPauta });
      yield put(actions.EditGrades.clearGradeEdits());
    }
    
    yield put(
      actions.EditGrades.setLoadingStatus({
        fieldName: 'loadingTable',
        fieldValue: false,
      })
    );

    yield put(actions.Toaster.showToaster({
      title: t('pautas.changesSavedSuccessfully'),
      icon: 'check',
      type: 'success',
    }));
    
    } catch (error) {
    yield put(actions.Toaster.showToaster({
      title: t('pautas.errorSavingChanges'),
      icon: 'error',
      type: 'danger',
    }));
    console.error('Error in saveGradesSaga:', error);
  }
}

function* uploadGradesSaga(action) {
  try {
    const { codigoPauta, nmecColumnIndex, gradeColumnIndex, hasHeader, csvFile } = action.payload;

    const response = yield call(API.pautas.postGradeFile.call, codigoPauta, nmecColumnIndex + 1, gradeColumnIndex + 1, hasHeader, 1, csvFile);

    const gradesData = yield select(state => state.EditGrades.gradesData)

    let gradeEdits = {}

    response.data.forEach((item) => {
      if (item.type === 'BDADOS') {
        item.data.forEach((updatedGrade) => {
          const currentGrade = gradesData.find((grade) => grade.nmec === updatedGrade.nmec);
          if (currentGrade && currentGrade.nota !== updatedGrade.nota) {
            gradeEdits[updatedGrade.nmec] = updatedGrade.nota;
          }
        });
      }
    });

    if (Object.keys(gradeEdits).length > 0) { // Update cells that were successfully imported
      yield put(actions.EditGrades.setGradeEdits(gradeEdits));
    }
    if (response.errors.length > 0 || Object.keys(response.warnings).length > 0) { // Show modal if there are any warnings or errors
      yield put(actions.EditGrades.setImportErrors(response.errors));
      yield put(actions.EditGrades.setImportWarnings(response.warnings));
      yield put(actions.EditGrades.setIsErrorsModalOpen());
    }
    else if (Object.keys(gradeEdits).length > 0) { // If there are no errors or warnings and there is at least 1 imported grade, show success toaster
      yield put(actions.Toaster.showToaster({
        title: t('pautas.importSuccessToaster'),
        icon: 'check',
        type: 'success',
      }));
    }

    yield put(
      actions.EditGrades.setLoadingStatus({
        fieldName: 'loadingCSVData',
        fieldValue: false,
      })
    );

  } catch (error) {
    console.error('Error in uploadGradesSaga:', error);
    yield put(actions.Toaster.showToaster({
      title: t('pautas.errorImportingGrades'),
      icon: 'error',
      type: 'danger',
    }));
  }
}

function* onMountSaga(action) {
  yield call(fetchGradeData, action);
}

function* onUnmountSaga() {
  yield put(actions.EditGrades.reset());
}

export default function* editGradesWatcher() {
  yield takeLatest('EditGrades/onMount', onMountSaga);
  yield takeLatest('EditGrades/onUnmount', onUnmountSaga);
  yield takeLatest("EditGrades/fetchGradeData", fetchGradeData);
  yield takeLatest("EditGrades/uploadGrades", uploadGradesSaga);
  yield takeLatest("EditGrades/saveOrSubmitGrades", saveOrSubmitGradesSaga);
}