/**
 * ExamsCalendar scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import i18n from 'i18next';

import { useDispatch, useSelector } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /*faFileDownload,*/ faCalendar } from '@fortawesome/pro-regular-svg-icons';

import { EventCalendar, Button, Text, Caption, Segment /*, CallToAction*/ } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import Header from 'shared/containers/TitleHeader';

import { actions } from 'store/rootSlices';

import * as selectors from './logic/selectors';

import { convertEventToIcs, setEventData } from './utils';
import { createAndDownloadFile } from 'utils';
import PageWarningComponent from 'shared/containers/PageWarningComponent';
//import config from 'config'

const ExamsCalendar = ({ t, size }) => {
  const theme = useContext(ThemeContext);
  const {
    currentICS,
    eventsData,
    loadingExamsCalendar,
    filterParams,
    calendarFilterTypes,
    calendarCurrentYearMonth,
    errorStatus,
    initialCalendarDate,
  } = useSelector(selectors.getExamsCalendarSlice);

  const currentRegistrationId = useSelector(selectors.getRegistration);

  const {
    onMount,
    onUnmount,
    getExamCalendar,
    setSearchFilter,
    setTypeFilter,
    setCalendarCurrentYearMonth,
    setLoadingExamsCalendar,
    setInitialCalendarDate,
  } = actions.ExamsCalendar;

  const user = useSelector(selectors.getUser);
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);

  //temporary solution to check the users viewport width
  const [windowViewportWidth, setWindowViewportWidth] = useState(window.innerWidth);

  //temporaryUseEffect to check the users viewport width
  useEffect(() => {
    const handleResize = () => {
      setWindowViewportWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  useEffect(() => {
    if (currentRegistrationId) {
      dispatch(setLoadingExamsCalendar(true));
      dispatch(getExamCalendar(calendarCurrentYearMonth));
    }
  }, [currentRegistrationId, filterParams]);

  useEffect(() => {
    if (initialLoading && !loadingExamsCalendar) {
      setInitialLoading(false);
    }
  }, [loadingExamsCalendar]);

  const Icon = ({ color, label }) => {
    return (
      <EventIcon color={color}>
        <Text color="white" weight="bold" size="xSmall">
          {label}
        </Text>
      </EventIcon>
    );
  };

  const getIcon = (label) => {
    label = label.toLowerCase();
    switch (label) {
      case 'ex':
        return <Icon color={theme.colors.primary} label={label} />;
      case 'pr':
        return <Icon color={theme.colors.decisionYellow} label={label} />;
      case 'al':
        return <Icon color={theme.colors.dangerRed} label={label} />;
      case 'si':
        return <Icon color={theme.colors.decisionYellow} label={label} />;
      case 'm':
        return <Icon color={theme.colors.successGreen} label={label} />;
      default:
        return <Icon color={theme.colors.softRegularGrey} label={label} />;
    }
  };

  const getFooter = (event) => (
    <Button
      data-testid={`exams_calendar_footer`}
      onClick={() => {
        const icsEvent = convertEventToIcs(event, currentICS);
        createAndDownloadFile(escape(icsEvent), 'ics', 'data:text/calendar');
      }}
      color={theme.colors.darkGrey}
      borderless
      noPadding
      leftIcon={<FontAwesomeIcon size="lg" icon={faCalendar} />}
    >
      <Text weight="bold" transform="none" size="xSmall">
        {t('examsCalendar.saveToGoogleCalendar')}
      </Text>
    </Button>
  );

  //this condition is used to prevent the user from visiting this page in mobile devices
  if (windowViewportWidth < 700) {
    return <PageWarningComponent message={t('generic.mobileInDevelopmentChangeToPortrait')} />;
  }

  return (
    <PageStructure
      expanded
      loading={(loadingExamsCalendar && initialLoading) || errorStatus.errLoadingCalendarData}
      headerLeft={<Header title={t('routes.examsCalendar')} />}
      subHeader={
        <StudentRegistrationDropdown
          loading={loadingExamsCalendar && initialLoading}
          data-testid="exams_calendar_dropdown_registration"
        />
      }
      mainContent={
        <>
          {currentICS && (
            <EventCalendar
              loading={loadingExamsCalendar}
              leftButtonTooltip={t('examsCalendar.prevMonth')}
              rightButtonTooltip={t('examsCalendar.nextMonth')}
              todayButtonText={t('examsCalendar.buttonToday')}
              events={setEventData(eventsData, getIcon, getFooter)}
              seeMore={t('examsCalendar.seeMore')}
              seeLocation={t('examsCalendar.seeLocation')}
              language={i18n.language}
              initialDate={initialCalendarDate}
              addFilters={{
                searchPlaceholder: t('forms.searchPlaceholder', { textOnly: true }),
                dropdownPlaceholder: t('examsCalendar.listDropdownFilterPlaceholder', {
                  textOnly: true,
                }),
                onSearch: (value) => {
                  dispatch(setSearchFilter(value));
                },
                onFilterChange: (value) => {
                  dispatch(setTypeFilter(value));
                },
                onFilterClear: (value) => {
                  dispatch(setTypeFilter(value));
                },
                filterTypes: calendarFilterTypes,
              }}
              onSeeLocation={(event) => {}}
              updateCalendarCurrentYearMonth={(date) => {
                dispatch(setLoadingExamsCalendar(true));
                dispatch(getExamCalendar(date));
                dispatch(setCalendarCurrentYearMonth(date));
              }}
            />
          )}

          {!loadingExamsCalendar && (
            <EventCaption>
              <Caption
                title={`${t('examsCalendar.legend', { textOnly: true })}:`}
                references={[
                  {
                    title: (
                      <Text color="plusDarkGrey" weight="bold" size="xSmall">
                        {t('examsCalendar.exams')}
                      </Text>
                    ),
                    icon: getIcon('ex'),
                  },
                ]}
              />
            </EventCaption>
          )}
        </>
      }
      secondaryContentOffset={58}
      secondaryContent={
        <SidebarWrapper>
          <Segment padding={'24px'} borderTopColor={theme.colors.plusDarkGrey}>
            <SegmentWrapper>
              <SegmentHeader>
                <Text color="plusDarkGrey" transform="uppercase" weight="bold" size="medium">
                  {t('examsCalendar.availableActions')}
                </Text>
              </SegmentHeader>
              <SegmentWrapperActions>
                <Text
                  iconWidth="20px"
                  icon={<FontAwesomeIcon size="lg" icon={faCalendar} />}
                  color="plusDarkGrey"
                  size="small"
                  weight="medium"
                  as="a"
                  onClick={() => {
                    if (currentICS) {
                      createAndDownloadFile(escape(currentICS), 'ics', 'data:text/calendar');
                    }
                  }}
                >
                  {t('examsCalendar.saveToGoogleCalendar')}
                </Text>
              </SegmentWrapperActions>
            </SegmentWrapper>
          </Segment>
        </SidebarWrapper>
      }
    />
  );
};

export default ExamsCalendar;

const EventCaption = styled.div`
  margin-top: 8px;
`;

const EventIcon = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  text-transform: uppercase;
`;

const SidebarWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SegmentHeader = styled.div`
  margin-bottom: 24px;
`;

const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SegmentWrapperActions = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 8px;
  }
`;
