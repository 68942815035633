/**
 * NewApplicationStatement container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { actions } from 'store/rootSlices';
import { t } from 'app';

import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faPlus, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { useFormContext } from 'react-hook-form';

import { Grid } from 'semantic-ui-react';
import { AnimatedBackground, Text, Segment, Button } from '@paco_ua/pacoui';

import { componentTemplateParser } from '../../utils';

const NewApplicationStatement = ({ formElements, formElementsData, setLoadingFile }) => {
  const theme = useContext(ThemeContext);

  const user = useSelector(selectors.getUser);
  const newApplicationData = useSelector(selectors.getNewApplicationData);
  const newApplicationLoadingStatus = useSelector(selectors.getLoadingStatus);
  const newApplicationNumAdditionalDocs = useSelector(selectors.getNewApplicationNumAdditionalDocs);
  const { form } = newApplicationData;

  const {
    addDocumentToFormSchema,
    addAdditionalDoc,
    remAdditionalDoc,
    removeDocumentFromFormSchema,
    setNumAdditionalDocs,
  } = actions.NewApplication;

  const { errorStatus } = useSelector(selectors.getNewApplicationSlice);

  const {
    formState: { errors },
  } = useFormContext();

  const dispatch = useDispatch();

  const [uploadingAdditionalFile, setUploadingAdditionalFile] = useState(false);

  const DEFAULT_MAX_ADDITIONAL_DOCS = 3;

  const [maxAdditionalDocuments, setMaxAdditionalDocuments] = useState(DEFAULT_MAX_ADDITIONAL_DOCS);

  const registration =
    user.registrations &&
    user.registrations.find(
      (registration) => registration.registrationId === newApplicationData.registrationId
    );

  const statement = newApplicationData.form && newApplicationData.form.exposition.text;
  // Knowing if it's safe to render the form
  const formElementsReady = () => {
    if (
      Object.keys(formElements).length !== 0 &&
      formElements.constructor === Object &&
      Object.keys(formElementsData).length !== 0 &&
      formElementsData.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  };

  // To-do: typechecking
  const customFormElements = Object.keys(formElements).reduce(
    (acc, key) => {
      if (form) {
        const found = Object.keys(form).find(
          (k) =>
            form[k].values &&
            form[k].values.length &&
            form[k].values.find((value) => value.nameKey === key)
        );
        if (found && found !== 'exposition') {
          acc[found] = [...acc[found], { nameKey: key, element: formElements[key] }];
        }
      }

      return acc;
    },
    {
      expoFields: [] as any,
      observations: [] as any,
      mandatoryDocuments: [] as any,
      additionalDocuments: [] as any,
    }
  );

  // To-do: typechecking
  const customFormElementsData = Object.keys(formElementsData).reduce(
    (acc, key) => {
      if (form) {
        const found = Object.keys(form).find(
          (k) =>
            form[k].values &&
            form[k].values.length &&
            form[k].values.find((value) => value.nameKey === key)
        );
        if (found && found !== 'exposition') {
          acc[found] = [...acc[found], formElementsData[key]];
        }
      }

      return acc;
    },
    {
      expoFields: [] as any,
      observations: [] as any,
      mandatoryDocuments: [] as any,
      additionalDocuments: [] as any,
    }
  );

  useEffect(() => {
    let nNonOtherDocuments = 0;
    customFormElementsData.additionalDocuments.forEach((document) => {
      if (!document.nameKey.includes('otherDocuments')) {
        nNonOtherDocuments++;
      }
    });

    setMaxAdditionalDocuments(nNonOtherDocuments + DEFAULT_MAX_ADDITIONAL_DOCS);

    let nOtherDocuments = 1;

    if (newApplicationNumAdditionalDocs > 1) {
      nOtherDocuments = newApplicationNumAdditionalDocs - nNonOtherDocuments;
    }

    dispatch(setNumAdditionalDocs(nOtherDocuments + nNonOtherDocuments));
  }, [customFormElementsData.additionalDocuments]);

  const getMandatoryDocumentsData = (
    customFormElementsData,
    field,
    nameKey,
    isGroupItem = false
  ) => {
    let documents;

    if (isGroupItem) {
      customFormElementsData[field].some((field) => {
        if (field.group) {
          const groupDocumentKey = Object.keys(field.group.values).find(
            (groupField) => field.group.values[groupField].nameKey === nameKey
          );
          if (groupDocumentKey) {
            documents = field.group.values[groupDocumentKey];
            return true;
          }
        } else {
          return false;
        }
      });
    } else {
      customFormElementsData[field].some((field) => {
        if (field.group) {
          if (field.group.value.nameKey === nameKey) {
            documents = field.group.value;
            return true;
          }
        } else {
          if (field.nameKey === nameKey) {
            documents = field;
            return true;
          }
        }
      });
    }

    return {
      hidden: documents && documents.hidden,
      placeholderKey: documents && documents.placeholderKey,
      maxSize:
        documents && documents.validations.find((validation) => validation.type === 'maxFileSize'),
      minSize:
        documents && documents.validations.find((validation) => validation.type === 'minFileSize'),
      acceptTypes:
        documents && documents.validations.find((validation) => validation.type === 'fileType'),
    };
  };

  const getMandatoryDocumentsChildren = (formElements, field) =>
    Object.keys(formElements).map((key) => {
      const FormElement = formElements[key];
      const nameKey = key;

      const { hidden, placeholderKey, maxSize, minSize, acceptTypes } = getMandatoryDocumentsData(
        customFormElementsData,
        field,
        nameKey,
        true
      );

      if (hidden) {
        return undefined;
      }

      return (
        <FormElement
          nameKey={nameKey}
          icon={<FontAwesomeIcon icon={faFilePdf} />}
          heading={nameKey}
          description={placeholderKey ? placeholderKey : ''}
          //heading={t(`applications.documentsPlaceholder-${placeholderKey}`)}
          studentId={user.nmec}
          registrationId={newApplicationData.registrationId}
          errorGroup={
            errors[nameKey] &&
            typeof errors[nameKey].message !== 'string' &&
            errors[nameKey].message?.type === 'group' &&
            t('forms.' + errors[nameKey].message.message, {
              textOnly: true,
            })
          }
          error={
            errors[nameKey] &&
            typeof errors[nameKey].message === 'string' &&
            t('forms.' + errors[nameKey].message, {
              textOnly: true,
            })
          }
          maxSize={maxSize && maxSize.params[0]}
          minSize={minSize && minSize.params[0]}
          accept={acceptTypes && acceptTypes.params[0]}
        />
      );
    });

  if (newApplicationLoadingStatus.tabLoading || !formElementsReady()) {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
        <Segment>
          <LoadingWrapper>
            <AnimatedBackground height={theme.sizes.large} width="60%" />
            <AnimatedBackground height={theme.sizes.large} width="20%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
          </LoadingWrapper>
        </Segment>
      </Grid.Column>
    );
  } else if (errorStatus.errLoadingApplicationForm) {
    return (
      <MessageNoApplications>
        <Icon>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Icon>
        <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
          {t('applications.noApplicationsFound', { textOnly: true })}
        </Text>
      </MessageNoApplications>
    );
  } else {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
        <Segment>
          <SegmentTitleWrapper>
            <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
              {t('applications.requestThirdStepTitle')}
            </Text>
            <DescriptionWrapper size="article" color={theme.colors.darkGrey} weight={'regular'}>
              {t('applications.requestThirdStepDescription')}
            </DescriptionWrapper>
          </SegmentTitleWrapper>
          <ApplicationData>
            <ApplicationDataNameAndCourse>
              <ApplicationDataBlock>
                <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                  {t('applications.requestThirdStepName')}
                </Text>
                <ApplicationDataBlockText>
                  <TextWrapper
                    size="mediumSmall"
                    color={theme.colors.plusDarkGrey}
                    weight={'regular'}
                  >
                    {
                      customFormElementsData.expoFields.find(
                        (key) => key.nameKey === 'nomeestudante'
                      ).value
                    }
                  </TextWrapper>
                </ApplicationDataBlockText>
              </ApplicationDataBlock>
              <ApplicationDataBlock>
                <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                  {t('applications.requestThirdStepCourse')}
                </Text>
                <ApplicationDataBlockText>
                  <TextWrapper
                    size="mediumSmall"
                    color={theme.colors.plusDarkGrey}
                    weight={'regular'}
                  >
                    {registration && `${registration.courseCode} - ${registration.courseName}`}
                  </TextWrapper>
                </ApplicationDataBlockText>
              </ApplicationDataBlock>
            </ApplicationDataNameAndCourse>
            <ApplicationDataApplicationType>
              <ApplicationDataBlock>
                <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                  {t('applications.requestThirdStepApplicationType')}
                </Text>
                <ApplicationDataBlockText>
                  <TextWrapper
                    size="mediumSmall"
                    color={theme.colors.plusDarkGrey}
                    weight={'regular'}
                  >
                    {newApplicationData.type.name}
                  </TextWrapper>
                </ApplicationDataBlockText>
              </ApplicationDataBlock>
            </ApplicationDataApplicationType>
          </ApplicationData>
          <ApplicationStatementWrapper>
            <ApplicationStatementHeading>
              <Text size="medium" color={theme.colors.plusDarkGrey} fontWeight="500">
                {t('applications.requestThirdStepStatementHeading')}
              </Text>
              <DescriptionWrapper size="article" color={theme.colors.darkGrey} weight={'regular'}>
                {t('applications.requestThirdStepStatementDescription')}
              </DescriptionWrapper>
            </ApplicationStatementHeading>
            <ApplicationStatement>
              {Object.keys(customFormElementsData).map((field, index) => {
                if (field === 'expoFields') {
                  return (
                    <ApplicationStatementText
                      key={`applicationStatementText-${field}-${index}`}
                      size="article"
                      color={theme.colors.plusDarkGrey}
                    >
                      {componentTemplateParser(
                        customFormElementsData.expoFields,
                        statement,
                        customFormElementsData.expoFields.reduce((acc, statementField, index) => {
                          const FormElement = formElements[statementField.nameKey];
                          const getComponent = () => {
                            if (
                              statementField.type === 'textbox' ||
                              statementField.type === 'largetextbox'
                            ) {
                              // texto livre
                              return (
                                <FormInputWrapper
                                  key={`FormInputWrapper-${index}`}
                                  isTextBox={statementField.type === 'textbox' ? true : false}
                                >
                                  <FormElement
                                    noAutocomplete
                                    fontSize={'small'}
                                    placeholder={t(statementField.placeholderKey, {
                                      textOnly: true,
                                    })}
                                    error={
                                      errors[statementField.nameKey] &&
                                      t('forms.' + errors[statementField.nameKey].message, {
                                        textOnly: true,
                                      })
                                    }
                                  />
                                </FormInputWrapper>
                              );
                            } else if (statementField.type !== 'text') {
                              return (
                                <FormElementWrapper key={`formElementWrapper-${index}`}>
                                  <FormElement
                                    options={statementField.values}
                                    error={
                                      errors[statementField.nameKey] &&
                                      t('forms.' + errors[statementField.nameKey].message, {
                                        textOnly: true,
                                      })
                                    }
                                  />
                                </FormElementWrapper>
                              );
                            } else {
                              return (
                                <StatementDisabledInputWrapper
                                  key={`statementDisabledInputWrapper-${index}`}
                                >
                                  {/* Auto fill fields */}
                                  <StatementDisabledInputLabel>
                                    {t(
                                      `applications.statementDisabledInput-${statementField.nameKey}`
                                    )}
                                  </StatementDisabledInputLabel>
                                  <Text
                                    color={theme.colors.primary}
                                    size="article"
                                    fontWeight="500"
                                    weight={'regular'}
                                  >
                                    {statementField.value}
                                  </Text>
                                </StatementDisabledInputWrapper>
                              );
                            }
                          };

                          acc[statementField.nameKey] = getComponent();
                          return acc;
                        }, {}),
                        true
                      )}
                    </ApplicationStatementText>
                  );
                } else if (field === 'observations') {
                  return (
                    customFormElements.observations &&
                    customFormElements.observations.map(({ element, nameKey }, index) => {
                      const FormElement = element;
                      const placeholderKey = customFormElementsData.observations.find(
                        (field) => field.nameKey === nameKey
                      ).placeholderKey;
                      return (
                        <ApplicationStatementObservations
                          key={`applicationStatementObservations-${index}`}
                        >
                          <Text size="medium" color={theme.colors.plusDarkGrey} fontWeight="500">
                            {t('applications.requestThirdStepStatementObservationsTitle')}
                          </Text>
                          <ApplicationStatementObservationsTextAreaWrapper>
                            <FormElement
                              placeholder={t(`applications.${placeholderKey}`, {
                                textOnly: true,
                              })}
                              error={
                                errors.observations &&
                                t('forms.' + errors.observations.message, { textOnly: true })
                              }
                            />
                          </ApplicationStatementObservationsTextAreaWrapper>
                        </ApplicationStatementObservations>
                      );
                    })
                  );
                } else if (field === 'mandatoryDocuments' && customFormElements[field].length > 0) {
                  let docVisible = false;
                  customFormElementsData[field].forEach((doc) => {
                    if (doc.hidden === false) {
                      docVisible = true;
                    } else if (doc.hidden === null || doc.hidden === undefined) {
                      // meaning a group of files
                      if (doc.group?.value?.hidden === false) {
                        docVisible = true;
                      }
                    }
                  });
                  // if no document is visible, don't render the mandatory doc title
                  if (!docVisible) {
                    return [];
                  }

                  return (
                    <ApplicationStatementFiles key={`applicationStatementFiles-${index}`}>
                      {customFormElements[field] && (
                        <Text size="medium" color={theme.colors.plusDarkGrey} fontWeight="500">
                          {field === 'mandatoryDocuments'
                            ? t('applications.requestThirdStepMandatoryFilesTitle')
                            : t('applications.requestThirdStepAdditionalFilesTitle')}
                        </Text>
                      )}
                      {customFormElements[field] &&
                        customFormElements[field].map(({ element, nameKey }, index) => {
                          const FormElement = element?.group ? element.group?.value : element;
                          const FormChildren = element?.group
                            ? getMandatoryDocumentsChildren(element.group?.values, field)
                            : undefined;

                          const {
                            hidden,
                            placeholderKey,
                            maxSize,
                            minSize,
                            acceptTypes,
                          } = getMandatoryDocumentsData(customFormElementsData, field, nameKey);

                          if (hidden) {
                            return null;
                          } else {
                            return (
                              <FileUploaderWrapper key={`fileUploaderWrapper-${index}`}>
                                <FormElement
                                  nameKey={nameKey}
                                  icon={<FontAwesomeIcon icon={faFilePdf} />}
                                  heading={nameKey}
                                  description={placeholderKey ? placeholderKey : ''}
                                  studentId={user.nmec}
                                  registrationId={newApplicationData.registrationId}
                                  error={
                                    errors[nameKey] &&
                                    t('forms.' + errors[nameKey].message, {
                                      textOnly: true,
                                    })
                                  }
                                  maxSize={maxSize && maxSize.params[0]}
                                  minSize={minSize && minSize.params[0]}
                                  accept={acceptTypes && acceptTypes.params[0]}
                                  setUploadingFlag={(nameKey: string, value: boolean) => {
                                    setLoadingFile((currentValue) => {
                                      return {
                                        ...currentValue,
                                        [nameKey]: value,
                                      };
                                    });
                                  }}
                                  children={FormChildren}
                                />
                              </FileUploaderWrapper>
                            );
                          }
                        })}
                    </ApplicationStatementFiles>
                  );
                } else if (
                  field === 'additionalDocuments' &&
                  customFormElements[field].length > 0
                ) {
                  return (
                    customFormElements.additionalDocuments && (
                      <>
                        {customFormElements.additionalDocuments.map(({ element, nameKey }, key) => {
                          const FormElement = element;
                          //const defaultValue = formElementsData[key].value;
                          //const type = customFormElementsData.additionalDocuments[key].type;
                          const hidden = customFormElementsData.additionalDocuments[key].hidden;

                          const maxSize =
                            customFormElementsData.additionalDocuments[key] &&
                            customFormElementsData.additionalDocuments[key].validations.find(
                              (validation) => validation.type === 'maxFileSize'
                            );

                          const minSize =
                            customFormElementsData.additionalDocuments[key] &&
                            customFormElementsData.additionalDocuments[key].validations.find(
                              (validation) => validation.type === 'minFileSize'
                            );

                          const acceptTypes =
                            customFormElementsData.additionalDocuments[key] &&
                            customFormElementsData.additionalDocuments[key].validations.find(
                              (validation) => validation.type === 'fileType'
                            );

                          if (hidden) {
                            return [];
                          }

                          return (
                            <AdditionalDocumentsWrapper key={`additionalDocs-${key}`}>
                              {key === 0 && (
                                <AdditionalDocumentsTitle>
                                  <Text size="medium" color={'plusDarkGrey'} fontWeight="500">
                                    {t(`applications.otherDocumentsTitle`, { textOnly: true })}
                                  </Text>
                                </AdditionalDocumentsTitle>
                              )}
                              <FileUploaderWrapper key={`fileUploaderWrapper-${index}`}>
                                <FormElement
                                  nameKey={nameKey}
                                  icon={<FontAwesomeIcon icon={faFilePdf} />}
                                  heading={
                                    nameKey.includes('otherDocuments')
                                      ? t(`applications.otherDocuments`, { textOnly: true })
                                      : nameKey
                                  }
                                  description={
                                    formElementsData[nameKey].placeholderKey.includes(
                                      'otherDocumentsPlaceholder'
                                    )
                                      ? t(
                                          `applications.${formElementsData[nameKey].placeholderKey}`
                                        )
                                      : formElementsData[nameKey].placeholderKey
                                  }
                                  studentId={user.nmec}
                                  registrationId={newApplicationData.registrationId}
                                  error={
                                    errors[
                                      customFormElementsData.additionalDocuments[key].nameKey
                                    ] &&
                                    t(
                                      `forms.${
                                        errors[
                                          customFormElementsData.additionalDocuments[key].nameKey
                                        ].message
                                      }`,
                                      {
                                        textOnly: true,
                                      }
                                    )
                                  }
                                  enableRemoveField={
                                    key === 0 ||
                                    key <= maxAdditionalDocuments - DEFAULT_MAX_ADDITIONAL_DOCS
                                      ? false
                                      : true
                                  }
                                  onRemoveField={
                                    key === 0 ||
                                    key <= maxAdditionalDocuments - DEFAULT_MAX_ADDITIONAL_DOCS
                                      ? undefined
                                      : () => {
                                          const payload = {
                                            form: form,
                                            key: nameKey,
                                          };
                                          if (
                                            newApplicationNumAdditionalDocs >
                                            maxAdditionalDocuments - DEFAULT_MAX_ADDITIONAL_DOCS
                                          ) {
                                            dispatch(removeDocumentFromFormSchema(payload));
                                            dispatch(remAdditionalDoc()); // counter
                                          }
                                        }
                                  }
                                  setUploadingFlag={(nameKey: string, value: boolean) => {
                                    setUploadingAdditionalFile(value);
                                    setLoadingFile((currentValue) => {
                                      return {
                                        ...currentValue,
                                        [nameKey]: value,
                                      };
                                    });
                                  }}
                                  maxSize={maxSize && maxSize.params[0]}
                                  minSize={minSize && minSize.params[0]}
                                  accept={acceptTypes && acceptTypes.params[0]}
                                />
                              </FileUploaderWrapper>
                            </AdditionalDocumentsWrapper>
                          );
                        })}
                        <AddFileButtonWrapper>
                          <Button
                            disabled={
                              uploadingAdditionalFile ||
                              newApplicationNumAdditionalDocs >= maxAdditionalDocuments
                            }
                            onClick={() => {
                              if (newApplicationNumAdditionalDocs < maxAdditionalDocuments) {
                                dispatch(addDocumentToFormSchema(form));
                                dispatch(addAdditionalDoc()); // counter
                              }
                            }}
                            primary
                          >
                            <Text
                              size="xSmall"
                              color={'plusDarkGrey'}
                              weight={'regular'}
                              icon={<FontAwesomeIcon icon={faPlus} size={'1x'} />}
                            >
                              {t('certificates.addDocument')}
                            </Text>
                          </Button>
                        </AddFileButtonWrapper>
                      </>
                    )
                  );
                } else {
                  return [];
                }
              })}
            </ApplicationStatement>
          </ApplicationStatementWrapper>
        </Segment>
      </Grid.Column>
    );
  }
};

export default NewApplicationStatement;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const ApplicationData = styled.div`
  margin-top: 56px;
`;

const ApplicationDataNameAndCourse = styled.div`
  display: flex;

  & > div {
    flex: 0 50%;
  }
`;

const ApplicationDataApplicationType = styled.div`
  margin-top: 28px;
`;

const ApplicationDataBlock = styled.div`
  display: flex;
  flex-direction: column;
`;

const ApplicationDataBlockText = styled.div`
  margin-top: 12px;
`;

const ApplicationStatementWrapper = styled.div`
  margin-top: 48px;
`;

const ApplicationStatementHeading = styled.div`
  & > div:first-child:after {
    content: '*';
    color: ${({ theme }) => theme.colors.dangerRed};
  }
  display: flex;
  flex-direction: column;
`;

const ApplicationStatement = styled.div`
  margin-top: 24px;

  .ui.placeholder {
    max-width: 80%;
  }
`;

const ApplicationStatementFiles = styled.div`
  margin-top: 40px;
`;

const FileUploaderWrapper = styled.div`
  margin-top: 12px;
  > div > div {
    height: unset;
  }
`;

const FormElementWrapper = styled.div`
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 8px;
`;

const StatementDisabledInputWrapper = styled.div`
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 6px;
  margin-right: 8px;
  padding: 8px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const StatementDisabledInputLabel = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  margin-bottom: 4px;
  font-size: 10px;
`;

const ApplicationStatementText = styled(Text)`
  & {
    display: inline-block !important;
  }
`;

const ApplicationStatementObservations = styled.div`
  margin-top: 40px;
`;

const ApplicationStatementObservationsTextAreaWrapper = styled.div`
  margin-top: 16px;
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const AdditionalDocumentsWrapper = styled.div``;

const AdditionalDocumentsTitle = styled.div`
  margin-top: 32px;
`;

const AddFileButtonWrapper = styled.div`
  margin-top: 20px;
`;

const FormInputWrapper = styled.div<{ isTextBox?: boolean }>`
  margin-right: 8px;
  margin-top: 6px;
  margin-bottom: 6px;
  & input {
    background-color: ${({ theme }) => theme.colors.lightGrey};
  }
`;

const DescriptionWrapper = styled(Text)`
  margin-top: 8px;
`;

const TextWrapper = styled(Text)`
  line-height: 1.3;
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const MessageNoApplications = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;
