/**
 * ScheduleGridView container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, actions } from 'store/rootSlices';

import styled from 'styled-components';

import { ScheduleGrid } from '@paco_ua/pacoui';

import { t } from 'app';

//import config from 'config'

const ScheduleGridView: React.FC<{ ucs: any }> = ({ ucs }) => {
  const {
    loadingSchedule,
    scheduleType,
    expandedWeekDay,
    currentPhase,
    scheduleGridInterval,
    currentMode,
  } = useSelector((state: RootState) => state.Schedules);

  const dispatch = useDispatch();
  const { setExpandedWeekDay, setClassSelectionState } = actions.Schedules;
  // Scene in view mode?
  const viewMode = currentMode === 'view';

  return (
    <Wrapper>
      <ScheduleGrid
        t={{ noUcs: t('sgh.noUc_plural') }}
        disabled={
          (currentPhase?.phase && currentPhase?.finished) || !currentPhase?.phase || viewMode
            ? true
            : false
        }
        weekdays={6}
        startTime={scheduleGridInterval.min}
        endTime={scheduleGridInterval.max}
        intervalInMin={60}
        schedule={ucs}
        loading={loadingSchedule}
        onReady={(classes) => {
          if (expandedWeekDay === null) {
            const firstWeekDayWithClasses = classes.findIndex((classItem) => {
              return classItem.classes.length > 0;
            });
            if (firstWeekDayWithClasses >= 0) {
              dispatch(setExpandedWeekDay(firstWeekDayWithClasses));
            }
          }
        }}
        onToggleWeekDay={(weekday) => {
          dispatch(setExpandedWeekDay(weekday));
        }}
        expandedDay={scheduleType !== 'gridExpanded' ? null : expandedWeekDay}
        onSelect={(classItem) => {
          dispatch(
            setClassSelectionState({
              ucId: classItem.ucId,
              groupId: classItem.groupId,
              classId: classItem.classId,
              type: classItem.classType,
            })
          );
        }}
      />
    </Wrapper>
  );
};

export default ScheduleGridView;

const Wrapper = styled.div`
  min-height: 422px;
`;
