/**
 * NewApplicationSelectRegistration container logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getRegistrations = (state: RootState) => state.App.user.registrations;
export const getNewApplicationData = (state: RootState) => state.NewApplication.applicationData;
