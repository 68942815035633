/**
 * FormTable
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Text, Table } from '@paco_ua/pacoui';

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  error?: string;
  structure: any;
  defaultValue?: any;
  value: any;
  disabledAccordion?: boolean;
  onChange: (value: any) => {};
}

const FormTable: React.FC<IProps> = ({
  error,
  structure,
  onChange,
  defaultValue,
  value,
  disabledAccordion,
  ...props
}) => {
  const createTableStructure = () => {
    return structure;
  };

  const resetRows = useMemo(() => {
    if (defaultValue?.length === 0) return true;
    return false;
  }, [defaultValue]);

  return (
    <Wrapper>
      <TableWrapper>
        {
          <Table
            unstackable
            selectable
            disabledAccordion={disabledAccordion}
            onChangeSelected={(data) => {
              onChange(data);
            }}
            resetRows={resetRows}
            structure={createTableStructure()}
          />
        }
      </TableWrapper>

      <MessageArea>
        {error && (
          <Text size="xSmall" weight="medium" color="dangerRed">
            {error}
          </Text>
        )}
      </MessageArea>
    </Wrapper>
  );
};

export default FormTable;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

const MessageArea = styled.div``;

const TableWrapper = styled.div<{ hasDescription?: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 1;
  flex: 1;
  flex-direction: ${({ hasDescription }) => (hasDescription ? 'column' : 'row')};

  > div {
    flex-grow: 1;
  }
`;
