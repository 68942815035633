/**
 * CurricularPlan scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { boolean } from 'yup/lib/locale';

import { Dictionary, ISummary, ICurricularPlan, ICourseUC } from '../types';

const initialState = {
  summaries: {} as Dictionary<ISummary>, // key: registrationId
  curricularPlans: {} as Dictionary<ICurricularPlan>, // key: registrationId
  defaultDropdownOption: -1,
  ucOptionsModal: false,
  ucOptions: {
    name: '',
    courseList: [] as ICourseUC[],
  },
  loadingStatus: {
    loading: true,
    loadingModal: false,
  },
  errorStatus: {
    errLoadingSummary: false,
  },
};

export default createSlice({
  name: 'CurricularPlan',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setUcOptionsModal: (state, action: PayloadAction<boolean>) => {
      state.ucOptionsModal = action.payload;
    },
    getUcOptions: (state, action: PayloadAction<{ name: string; code: number }>) => {},
    setUcOptions: (
      state,
      action: PayloadAction<{
        name: string;
        courseList: ICourseUC[];
      }>
    ) => {
      state.ucOptions = action.payload;
    },
    setDefaultDropdownOption: (state, action: PayloadAction<number>) => {
      state.defaultDropdownOption = action.payload;
    },
    getCurricularPlan: () => {},
    addCurricularPlanSummary: (state, action: PayloadAction<any>) => {
      state.summaries[action.payload['registrationId']] = {
        grade: action.payload['grade'],
        summary: action.payload['summary'],
      };
    },
    addCurricularPlan: (state, action: PayloadAction<any>) => {
      state.curricularPlans[action.payload['registrationId']] = action.payload['curricularPlan'];
    },
    reset: (state) => initialState,
  },
});
