export const lineToAxisPlugin = {
  afterDatasetsDraw: function (chart, easing) {
    if (!chart.initialAnimationCompleted) {
      chart.initialAnimationCompleted = easing >= 1;
    }

    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach((element, index) => {
          const backgroundColor = dataset.backgroundColor[index];
          const yBottom = chart.scales['y-axis-0'].bottom;
          const yCurrent = element.tooltipPosition().y;
          
          let yInterpolated;
          if (chart.initialAnimationCompleted) {
            yInterpolated = yBottom; // no animation after initial render
          } else {
            yInterpolated = yCurrent + (yBottom - yCurrent) * easing; // animate on initial render
          }

          ctx.save();
          ctx.strokeStyle = backgroundColor;
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(element._model.x, yCurrent);
          ctx.lineTo(element._model.x, yInterpolated);
          ctx.stroke();
          ctx.restore();
        });
      }
    });
  }
};
