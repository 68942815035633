/**
 * Mandatory Options Dropdown container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { put, call, takeLatest } from 'redux-saga/effects';

import { t } from 'app';

import { actions } from 'store/rootSlices';

import { getSelectedOptionKey } from '../utils';

import ErrorHandler from 'shared/errorHandler';

function* setMandatoryOptionsSaga(action: any) {
  const mandatoryOptions = action.payload;
  const { setOptions } = actions.MandatoryOptionsDropdown;
  const { showToaster } = actions.Toaster;

  try {
    const newOptions = mandatoryOptions
      ? mandatoryOptions.map((option, index) => {
          let selectedOptionKey = getSelectedOptionKey(option);

          return {
            id: index + 1,
            optionId: option.id,
            selected: selectedOptionKey,
            name: option.name,
            open: selectedOptionKey ? true : false,
            ucs: option.ucs,
          };
        })
      : [];

    yield put(setOptions(newOptions));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(setOptions([]));

      yield put(
        showToaster({
          title: t('sgh.actionErrorGetMandatoryOptions'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

export default function* watcherSignin() {
  yield takeLatest('MandatoryOptionsDropdown/setMandatoryOptions', setMandatoryOptionsSaga);
}
