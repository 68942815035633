export const getPercentage = (value: number, total: number) => {
  return (Math.round((value / total) * 100)+'%');
} 

export const getStats = (gradesData: any) => {
  return {
    totalStudents: gradesData.length,
    positiveGrades: getPositiveGrades(gradesData),
    negativeGrades: getNegativeGrades(gradesData),
    rnmStudents: getRNMStudents(gradesData),
    missingStudents: getMissingStudents(gradesData),
    dropOutStudents: getDropOutStudents(gradesData),
    failedByMissingStudents: getFailedByMissingStudents(gradesData),
    studentsWithoutGrades: getStudentsWithoutGrades(gradesData)
  }
} 

const getPositiveGrades = (gradesData: any) => {
  return gradesData.filter((aluno: any) => (parseInt(aluno.nota) >= 10 && parseInt(aluno.nota) <= 20)).length;
} 

const getNegativeGrades = (gradesData: any) => {
  return gradesData.filter((aluno: any) => (parseInt(aluno.nota) >= 0 && parseInt(aluno.nota) < 10)).length;
}

const getRNMStudents = (gradesData: any) => {
  return gradesData.filter((aluno: any) => parseInt(aluno.nota) === 66).length;
}

const getMissingStudents = (gradesData: any) => {
  return gradesData.filter((aluno: any) => parseInt(aluno.nota) === 77).length;
}

const getDropOutStudents = (gradesData: any) => {
  return gradesData.filter((aluno: any) => parseInt(aluno.nota) === 88).length;
}

const getFailedByMissingStudents = (gradesData: any) => {
  return gradesData.filter((aluno: any) => parseInt(aluno.nota) === 99).length;
}

const getStudentsWithoutGrades = (gradesData: any) => {
  return gradesData.filter((aluno: any) => aluno.nota === '').length;
}