import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table, Checkbox } from "@paco_ua/pacoui";
import { useInitializeState, State } from './utils';
import { StateProgressBar } from '../StateProgressBar/index';
import Modal from '../Modal/Modal'
import SigningModal from '../Modal/SigningModal'
import { actions } from 'store/rootSlices';
import { t } from 'app';
interface TableViewProps {
  pautasLista: any[];
  selectedState: string;
  loading: boolean;
  isPT: boolean;
}

let data_listaPautas: any[];


const TableView: React.FC<TableViewProps & { scrollToTop: () => void }> = (props) => {
  const dispatch = useDispatch();
  const { navigateTo } = actions.App;
  const array_listaPautas = props.pautasLista;
  const loadingStatus = props.loading;

  array_listaPautas.forEach((subarray) => {
    if (Array.isArray(subarray) && subarray.length > 0) {
      data_listaPautas = subarray;

    }
  });

  const formatDate = (dateString: string) => {
    const dateObj = new Date(dateString);
    return dateObj.toLocaleDateString("en-GB");
  };




  const {
    data,
    selectedPautaIds,
    showError,
    showModal,
    setSelectedPautaIds,
    setShowModal,
    handleModal,
    showErrorForDuration,
    handleCheckboxChange,
    handleSelectAll,
    isAllSelected,
    setIsAllSelected,
    maxPautasNumber
  }: State = useInitializeState(props);

  //Ver onde esta definido o navigate
  // const navigate = useNavigate();
  // const { handleRowClick, handleEditClick } = handleNavigation(navigate);

  const tooltipText = t('pautas.infoDisabledCheckbox', { textOnly: true });



  const tableStructure = {
    header: {
      titles: [
        ...(Number(props.selectedState) === 7
          ? [{
            text: <Checkbox
              checked={isAllSelected}
              onClick={() => setIsAllSelected(!isAllSelected)}
              onChange={(e) => handleSelectAll(e, data_listaPautas, [], setSelectedPautaIds, maxPautasNumber)}
            />,
            style: { textAlign: 'left', width: '0.5%' }
          }]
          : [{ text: '', style: { textAlign: 'left', width: '0.5%', fontSize: "15px" } }]),
        { text: t('pautas.code'), style: { textAlign: 'left', width: '0%', fontSize: "15px", } },
        { text: t('pautas.uc'), style: { textAlign: 'left', width: '50%', fontSize: "15px", } },
        { text: t('pautas.codigoPauta'), style: { textAlign: 'left', width: '5%', fontSize: "15px", } },
        { text: t('pautas.tipoExame'), style: { textAlign: 'left', width: '2%', fontSize: "15px", } },
        { text: t('pautas.examDeadline'), style: { textAlign: 'left', width: '8%', fontSize: "15px", } },
        { text: t('pautas.studentsNumber'), style: { textAlign: 'left', width: '11%', fontSize: "15px", } },
        { text: t('pautas.gradeState'), style: { textAlign: 'left', width: '20%', fontSize: "15px", } },
        { text: '', style: { textAlign: 'left', width: '-100%', padding: '0px', fontSize: "15px", } },
        { text: '', style: { textAlign: 'left', width: '-100%', padding: '0px', fontSize: "15px", } },
      ],
      type: 'columnTitle',
    },
    rows: Array.isArray(data_listaPautas)
      ? data_listaPautas.map((subarrayItem) => {
        const isDisabled = selectedPautaIds.length >= 100 && !selectedPautaIds.includes(subarrayItem.paraAssinaturaId);
        return {
          cells: [
            {
              cellProps: '',
              content: Number(props.selectedState) === 7 || Number(props.selectedState) === 4 ? (
                <div title={isDisabled ? tooltipText : ""}>
                  <Checkbox
                    disabled={isDisabled}
                    checked={selectedPautaIds.includes(subarrayItem.paraAssinaturaId)}
                    onChange={(e: any) => handleCheckboxChange(subarrayItem.paraAssinaturaId, e.target.checked)}
                  />
                </div>
              ) : null,
            },

            { cellProps: '', content: subarrayItem.codDisciplina.toString() },

            {
              cellProps: '',
              content: (
                <span style={{ color: '#0EB4BD', textTransform: 'capitalize' }}>
                  {subarrayItem && props.isPT ? subarrayItem.nomeDisciplina : subarrayItem.nomeDisciplinaEng}
                </span>
              ),
            },
            { cellProps: '', content: subarrayItem && subarrayItem.codPauta ? subarrayItem.codPauta.toString() : '' },
            { cellProps: '', content: subarrayItem && subarrayItem.tipoExame ? subarrayItem.tipoExame : '' },
            { cellProps: '', content: subarrayItem && subarrayItem.dataLimiteLancamento !== '-' ? formatDate(subarrayItem.dataLimiteLancamento) : <div style={{ textAlign: 'center' }}>{subarrayItem.dataLimiteLancamento}</div> },

            {
              cellProps: '',
              content: subarrayItem?.ntotalAlunos === 1
                ? '1' + (subarrayItem.nmecAluno ? ` (${subarrayItem.nmecAluno})` : '')
                : `${subarrayItem?.ntotalAlunos || ''}`,
            },


            {
              cellProps: '',
              content: StateProgressBar(subarrayItem.estadoActual),
            },
            {
              cellProps: '',
              content:
                subarrayItem.estadoActual === 1 || subarrayItem.estadoActual === 2 || subarrayItem.estadoActual === 6 ? (
                  <Button onClick={() => dispatch(navigateTo({ key: 'editGrades', params: { codigoPauta: subarrayItem.codPauta } }))} action>{t('pautas.edit', { textOnly: true })}</Button>
                ) : subarrayItem.estadoActual === 7 && !(Number(props.selectedState) === 7) ? (
                  <Button action content="Action Button" onClick={() => handleModal(subarrayItem.paraAssinaturaId, [subarrayItem.paraAssinaturaId], false, setSelectedPautaIds, setShowModal, showErrorForDuration)}>{t('pautas.sign', { textOnly: true })}</Button>
                ) : null,
            },
            { cellProps: '', content: <Button onClick={() => dispatch(navigateTo({ key: 'gradeDetails', params: { codigoPauta: subarrayItem.codPauta } }))} action>{t('pautas.details', { textOnly: true })}</Button> },
          ],
          collapsableRows: [],
        };

      })
      : [],
  };


  return (
    <div className="overviewTable" style={{ margin: '0px 30px 80px 30px' }}>
      {showModal && (
        <Modal changeModal={handleModal}>
          <SigningModal
            onClose={(success) => {
              handleModal(null, selectedPautaIds, true, setSelectedPautaIds, setShowModal, showErrorForDuration);
              dispatch(actions.GradesManagement.onMount()); // always reload the table contents
              setIsAllSelected(false);
              if (success) {
                setSelectedPautaIds([]); // clear the selected pautas after signing
                props.scrollToTop();
              }
            }}
            pautas={data_listaPautas.filter(pauta => selectedPautaIds.includes(pauta.paraAssinaturaId))}  // checked pautas to enumerate
          />
        </Modal>

      )}

      <div style={{ display: 'flex', margin: '20px' }}></div>
      <div style={{ width: '100%', overflowX: 'auto' }}>
        <Table loading={loadingStatus} structure={tableStructure} />
      </div>
      <div style={{
        color: 'red',
        textAlign: 'center',
        marginTop: '1rem',
        height: '5px',
        visibility: showError ? 'visible' : 'hidden'
      }}>
        {showError && `* ${t('pautas.pautaToSign', {
          textOnly: true,
        })}`}
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: '0rem', marginLeft: '-1.75rem' }}>

        {
          parseInt(props.selectedState) === 7 && parseInt(props.selectedState) !== 4 && (
            <Button action content="Action Button" onClick={() => handleModal(null, selectedPautaIds, false, setSelectedPautaIds, setShowModal, showErrorForDuration)}>
              {t('pautas.sign', { textOnly: true })}
            </Button>
          )}
      </div>
    </div>
  );
};

export default TableView;