/**
 * NewCertificate scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICosts, IFormPostValues } from '../types';
import { IDropdownOption } from 'shared/types';
import { IParams } from 'api';
import _ from 'lodash';

interface ICertificateData {
  registrationId: null | number;
  type: {
    theme: null | string;
    code: string;
    name: null | string;
    price: number;
  };
  form: null | { [key: string]: any };
  //formValues: null | { [key: string]: any };
  submissionTabData: null | { [key: string]: any };
}

interface ICertificateTypes {
  [key: string]: {
    description: string;
    theme: string;
    type: string;
    code: string;
    paymentExemption: boolean;
    previewUrl: string;
    price: number;
    id: number;
  }[];
}

const initialState = {
  currentStep: 1,
  isExempt: false,
  hasModeration: false,
  englishWarning: false,
  isDigital: false,
  needsRequirement: false,
  showWarningModalOnSubmit: false,
  showRepeteadCertModal: false,
  selectedCertificatePreviewUrl: '',
  selectedCertificateType: {
    theme: null,
    code: '',
    name: null,
    price: -1,
    id: null as null | number,
    description: null as null | string,
  },
  certificateData: {
    registrationId: null,
    form: null,
    submissionTabData: null, // processed form values for display in the submission tab page
  } as ICertificateData,
  radioBtnMappings: {} as any,
  certificateTypes: {
    data: {} as ICertificateTypes,
    filters: [] as IDropdownOption[],
  },
  certificateCosts: {
    costs: [],
    totalCost: 0,
  } as ICosts,
  numAdditionalDocs: 1,
  filterParams: {
    type: '' as string,
    search: '' as string,
  },
  certificateSubmitted: false,
  loadingSubmission: {
    payNow: false,
    payLater: false,
  },
  refreshPage: false,
  loadingStatus: {
    loading: true,
    tabLoading: true,
    costLoading: true,
  },
  errorStatus: {
    errLoading: false,
    errLoadingCertificateList: false,
    errLoadingCertificateForm: false,
    errSubmittingForm: false,
  },
};

export default createSlice({
  name: 'NewCertificate',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    onLoadPage: (state) => {},
    setExempt: (state, action: PayloadAction<boolean>) => {
      state.isExempt = action.payload;
    },
    setHasModeration: (state, action: PayloadAction<boolean>) => {
      state.hasModeration = action.payload;
    },
    setEnglishWarning: (state, action: PayloadAction<boolean>) => {
      state.englishWarning = action.payload;
    },
    setIsDigital: (state, action: PayloadAction<boolean>) => {
      state.isDigital = action.payload;
    },
    setNeedsRequirement: (state, action: PayloadAction<boolean>) => {
      state.needsRequirement = action.payload;
      state.showWarningModalOnSubmit = true;
    },
    clearWarnings: (state) => {
      state.needsRequirement = false;
      state.showWarningModalOnSubmit = false;
    },
    setShowWarningModalOnSubmit: (state, action: PayloadAction<boolean>) => {
      state.showWarningModalOnSubmit = action.payload;
    },
    setShowRepeteadCertWarningModal: (state, action: PayloadAction<boolean>) => {
      state.showRepeteadCertModal = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setActiveRegistration: () => {},
    setCertificateData: (state, action: PayloadAction<any>) => {},
    setProcessedCertificateData: (state, action: PayloadAction<any>) => {
      // make radio button value_ids mappings to array indices
      state.certificateData = action.payload;
    },
    setRadioBtnMappings: (state, action: PayloadAction<any>) => {
      state.radioBtnMappings = action.payload;
    },
    setSelectedCertificatePreviewUrl: (state, action: PayloadAction<string>) => {
      state.selectedCertificatePreviewUrl = action.payload;
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    getCertificateTypes: (state, action?: PayloadAction<IParams>) => {},
    setCertificateTypes: (state, action: PayloadAction<any>) => {
      state.certificateTypes = {
        data: action.payload.data,
        filters: action.payload.filters.map((filter, key) => ({
          key: key,
          text: filter,
          value: filter,
        })),
      };
    },
    setFormSchema: (state, action: PayloadAction<any>) => {
      state.certificateData.form = action.payload;
    },
    setNumAdditionalDocs: (state, action: PayloadAction<any>) => {
      state.numAdditionalDocs = action.payload;
    },
    addDocumentToFormSchema: (state, action: PayloadAction<any>) => {},
    remAdditionalDoc: (state) => {
      state.numAdditionalDocs -= 1;
    },
    removeDocumentFromFormSchema: (state, action: PayloadAction<any>) => {},
    resetAdditionDocs: (state) => {
      state.numAdditionalDocs = initialState.numAdditionalDocs;
    },
    postCertificateChosenOptions: (state, action: PayloadAction<any>) => {},
    setCertificateCosts: (state, action: PayloadAction<ICosts>) => {
      state.certificateCosts = action.payload;
    },
    getCertificateForm: () => {},
    addAdditionalDoc: (state) => {
      state.numAdditionalDocs += 1;
    },
    setSelectedCertificateType: (state, action: PayloadAction<any>) => {
      state.selectedCertificateType = action.payload;
    },
    resetSelectedCertificateType: (state) => {
      state.selectedCertificateType = initialState.selectedCertificateType;
    },
    submitCertificate: (state, action: PayloadAction<any>) => {},
    resetSubmissionTabData: (state) => {
      state.certificateData.submissionTabData = initialState.certificateData.submissionTabData;
    },
    resetFormTabData: (state) => {
      state.loadingStatus.costLoading = initialState.loadingStatus.costLoading;
      state.isExempt = initialState.isExempt;

      state.certificateData.form = initialState.certificateData.form;
      state.numAdditionalDocs = initialState.numAdditionalDocs;
      state.certificateCosts = initialState.certificateCosts;
    },
    setCertificateSubmitted: (state, action: PayloadAction<boolean>) => {
      state.certificateSubmitted = action.payload;
    },
    setLoadingSubmission: (
      state,
      action: PayloadAction<{ payNow: boolean; payLater: boolean }>
    ) => {
      state.loadingSubmission = action.payload;
    },
    setRefreshPage: (state, action: PayloadAction<boolean>) => {
      state.refreshPage = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    resetPage: (state) => initialState,
  },
});
