/**
 * ImprovementExamEnrollment scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest, call, put } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import { t } from 'app';

import API from 'api';

function* onMountSaga() {
  yield put(actions.ImprovementExamEnrollment.setLoading(true));

  yield put(
    actions.ImprovementExamEnrollment.getStudentSignups({
      semester: null,
      annual: null,
    })
  );
}

function* getStudentSignupsSaga(action: any) {
  const body = action.payload;

  try {
    const signups = yield call(API.secVirtual.getStudentSignups.call, body);

    yield put(actions.ImprovementExamEnrollment.setData(signups));
  } catch {
    yield put(
      actions.Toaster.showToaster({
        title: t('getStudentSignupsSaga()', { textOnly: true }),
        icon: 'error',
        type: 'danger',
      })
    );
  } finally {
    yield put(actions.ImprovementExamEnrollment.setLoading(false));
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('ImprovementExamEnrollment/onMount', onMountSaga);
  yield takeLatest('ImprovementExamEnrollment/onUnmount', onUnmountSaga);
  //yield takeLatest('ImprovementExamEnrollment/getStudentSignups', getStudentSignupsSaga);
}
