/**
 * NewApplication scene sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { takeLatest, call, put, putResolve, select } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { IApplicationTypeData } from '../types';
import ErrorHandler from 'shared/errorHandler';

import { APPLICATION_ERROR_MESSAGE } from 'shared/errorMessages';

import API from 'api';

import { t } from 'app';

import { stringTemplateParser } from '../utils';
import { history } from 'utils';

function* onMountSaga() {
  try {
    yield put(actions.NewApplication.resetPage());

    yield put(
      actions.NewApplication.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: true,
      })
    );
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    yield put(
      actions.NewApplication.setErrorStatus({
        fieldName: 'errLoading',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorLoadingRegistrations'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    if (!history.location.state) {
      yield put(
        actions.NewApplication.setLoadingStatus({
          fieldName: 'loading',
          fieldValue: false,
        })
      );
    }
  }
}

function* setActiveRegistrationSaga() {
  const applicationData = yield select(selectors.getNewApplicationData);
  const registrations = yield select(selectors.getRegistrations);

  // check already the student active registration
  const activeRegistration = registrations?.find((reg) => reg.registrationState === 'activo');
  yield put(
    actions.NewApplication.setApplicationData({
      ...applicationData,
      registrationId: activeRegistration?.registrationId ?? null,
    })
  );
}

function* getApplicationTypesSaga(params?: any) {
  yield put(
    actions.NewApplication.setLoadingStatus({
      fieldName: 'tabLoading',
      fieldValue: true,
    })
  );

  try {
    const applicationData = yield select(selectors.getNewApplicationData);
    const registrationId = applicationData.registrationId;

    const applicationTypes: IApplicationTypeData = yield call(
      API.secVirtual.getApplicationTypes.call,
      {
        registrationId: registrationId,
        ...params?.payload,
      }
    );

    yield put(
      actions.NewApplication.setApplicationTypes({
        data: applicationTypes.data,
        filters: applicationTypes.filters,
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      if (APPLICATION_ERROR_MESSAGE.ERR_STUDENT_NO_APPLICATIONS === e.response?.data?.error_code) {
        yield put(
          actions.NewApplication.setErrorStatus({
            fieldName: 'errNoApplications',
            fieldValue: true,
          })
        );

        yield put(
          actions.Toaster.showToaster({
            title: t(`secVirtualNotifications.newApplication_errorNoApplications`),
            icon: 'error',
            type: 'danger',
          })
        );

        yield put(
          actions.NewApplication.setLoadingStatus({
            fieldName: 'loading',
            fieldValue: false,
          })
        );
      } else {
        yield put(
          actions.NewApplication.setErrorStatus({
            fieldName: 'errLoadingApplicationList',
            fieldValue: true,
          })
        );

        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newApplication_errorGetTypes'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  } finally {
    yield put(
      actions.NewApplication.setLoadingStatus({
        fieldName: 'tabLoading',
        fieldValue: false,
      })
    );
  }
}

function* getApplicationFormSaga() {
  yield put(
    actions.NewApplication.setLoadingStatus({
      fieldName: 'tabLoading',
      fieldValue: true,
    })
  );

  try {
    const applicationData = yield select(selectors.getNewApplicationData);

    const registrationId = applicationData.registrationId;

    const applicationForm = yield call(API.secVirtual.getApplicationForm.call, {
      registrationId,
      applicationId: applicationData.type.id,
    });

    yield put(
      actions.NewApplication.setApplicationData({
        ...applicationData,
        form: applicationForm,
      })
    );
  } catch (e) {
    yield put(
      actions.NewApplication.setErrorStatus({
        fieldName: 'errLoadingApplicationForm',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newApplication_errorGetForm'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.NewApplication.setLoadingStatus({
        fieldName: 'tabLoading',
        fieldValue: false,
      })
    );
  }
}

function* addDocumentToFormSchemaSaga(action: PayloadAction<any>) {
  let newForm = _.cloneDeep(action.payload);

  if (newForm.additionalDocuments.values.length > 0) {
    for (let i = newForm.additionalDocuments.values.length; i > 0; i--) {
      let idFoundInUse = false;
      action.payload.additionalDocuments.values.forEach((doc, key) => {
        if (doc.nameKey === `otherDocuments${i}`) {
          idFoundInUse = true;
        }
      });

      const initialAdditionalDoc = action.payload.additionalDocuments.values.find(
        (item) => item.nameKey === 'otherDocuments'
      );

      if (!idFoundInUse) {
        newForm.additionalDocuments.values.push({
          ...initialAdditionalDoc,
          nameKey: `otherDocuments${i}`,
          //placeholderKey: 'otherDocumentsPlaceholder',
        });

        break;
      }
    }
  }

  yield put(actions.NewApplication.setFormSchema(newForm));
}

function* removeDocumentFromFormSchemaSaga(action: PayloadAction<any>) {
  const form = action.payload.form;
  const docNamekey = action.payload.key;

  let newForm = _.cloneDeep(form);
  newForm.additionalDocuments.values = [];

  if (form.additionalDocuments.values.length > 0) {
    form.additionalDocuments.values.forEach((doc, key) => {
      if (doc.nameKey !== docNamekey) {
        newForm.additionalDocuments.values.push({
          ...doc,
        });
      }
    });
  }

  yield put(actions.NewApplication.setFormSchema(newForm));
}

function* submitApplicationSaga() {
  yield put(
    actions.NewApplication.setLoadingStatus({
      fieldName: 'loading',
      fieldValue: true,
    })
  );
  try {
    const { type, formValues, registrationId } = yield select(selectors.getNewApplicationData);
    const applicationTypeId = yield select(selectors.getApplicationTypeId);
    const registrationIdentifier = '' + registrationId;

    const getDocuments = () => {
      let mandatoryDocuments = [];
      let additionalDocuments = [];
      if (formValues.mandatoryDocuments && formValues.mandatoryDocuments.length) {
        mandatoryDocuments = formValues.mandatoryDocuments.map((document) => ({
          name: document.fileName,
          id: document.id,
          docTypeId: document?.docTypeId,
        }));
      }

      if (formValues.additionalDocuments && formValues.additionalDocuments.length) {
        additionalDocuments = formValues.additionalDocuments.map((document) => ({
          name: document.fileName,
          id: document.id,
          docTypeId: document?.docTypeId,
        }));
      }

      return [...mandatoryDocuments, ...additionalDocuments];
    };

    const data = {
      id: applicationTypeId,
      theme: type.theme,
      type: type.name,
      exposition: stringTemplateParser(
        formValues.statement,
        formValues.expoFields.reduce((acc, field) => {
          acc[field.nameKey] = field.value;
          return acc;
        }, {})
      ),
      observations: formValues.observations,
      documents: getDocuments(),
    };

    yield call(API.secVirtual.postApplicationForm.call, {
      registrationId: registrationIdentifier,
      data,
    });

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.newApplication_successSubmit'),
        icon: 'check',
        type: 'success',
      })
    );

    yield put(actions.App.navigateTo({ key: 'applicationslist' }));
  } catch (e) {
    yield put(
      actions.NewApplication.setErrorStatus({
        fieldName: 'errSubmittingForm',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (
        e.response.data.error_code === APPLICATION_ERROR_MESSAGE.ERR_STUDENT_DUPLICATE_APPLICATION
      ) {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newApplication_errorDuplicateApplication'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newApplication_errorSubmit'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  } finally {
    yield put(
      actions.NewApplication.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }
}

function* onUnmountSaga() {
  yield put(actions.NewApplication.resetPage());
}

export default function* watcherSignin() {
  yield takeLatest('NewApplication/onMount', onMountSaga);
  yield takeLatest('NewApplication/onUnmount', onUnmountSaga);
  yield takeLatest('NewApplication/getApplicationTypes', getApplicationTypesSaga);
  yield takeLatest('NewApplication/getApplicationForm', getApplicationFormSaga);
  yield takeLatest('NewApplication/submitApplication', submitApplicationSaga);
  yield takeLatest('NewApplication/addDocumentToFormSchema', addDocumentToFormSchemaSaga);
  yield takeLatest('NewApplication/removeDocumentFromFormSchema', removeDocumentFromFormSchemaSaga);
  yield takeLatest('NewApplication/setActiveRegistration', setActiveRegistrationSaga);
}
