/**
 * Payments Table container logic
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';

import {
  ITranslations,
  IPaymentData,
  IPagination,
  ICallParams,
} from 'scenes/VirtualSecretary/Payments/types';
import { DEFAULT_STRUCTURE, DEFAULT_PAGINATION } from './utils';

export default (
  t: ITranslations,
  payments: IPaymentData[],
  compileTableRow,
  total: number,
  fetchPayments: (params: ICallParams) => void,
  loadingReceipt,
  setLoadingReceipt
) => {
  const theme = useContext(ThemeContext);
  const [structure, setStructure] = useState(DEFAULT_STRUCTURE(t));
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION(total));

  const compileTableStructure = (
    t: ITranslations,
    payments: IPaymentData[],
    theme,
    loadingReceipt,
    setLoadingReceipt
  ) => ({
    header: {
      type: 'columnTitle',
      titles: [
        {
          text: t('payments.historyTableInvoiceNumber', { textOnly: true }),
          style: { textAlign: 'center', wordBreak: 'keep-all' },
        },
        {
          text: t('payments.historyTablePaymentDescription', { textOnly: true }),
        },
        {
          text: t('payments.historyTablePaymentValue', { textOnly: true }),
          style: { textAlign: 'center', wordBreak: 'keep-all' },
        },
        {
          text: t('payments.historyTablePaymentDate', { textOnly: true }),
          style: { textAlign: 'center', wordBreak: 'keep-all' },
        },
        { text: '' },
      ],
    },
    rows: !payments.length
      ? ([] as any)
      : payments.map((payment) => {
          return compileTableRow(payment, theme, t, loadingReceipt, setLoadingReceipt);
        }),
  });

  const handlePaginationNavigation = (newPagination: IPagination) => {
    const exceedsTotal = newPagination.limit >= newPagination.total;

    if (exceedsTotal) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = false;
    }

    if (!exceedsTotal && newPagination.offset < newPagination.limit) {
      newPagination.hasPrevious = false;
      newPagination.hasNext = true;
    }

    if (!exceedsTotal && newPagination.offset >= newPagination.limit) {
      newPagination.hasPrevious = true;
      newPagination.hasNext =
        newPagination.offset + newPagination.limit >= newPagination.total ? false : true;
    }

    setPagination(newPagination);
  };

  const onChangeRows = (value: number): void => {
    const newPagination = { ...pagination };
    newPagination.limit = value;
    newPagination.offset = 0;

    handlePaginationNavigation(newPagination);
    setPagination(newPagination);
    fetchPayments(newPagination);
  };

  const onChangePage = (value: string): void => {
    const newPagination = { ...pagination };

    switch (value) {
      case 'prev':
        newPagination.offset -= newPagination.limit;
        break;
      case 'next':
        newPagination.offset += newPagination.limit;
        break;
      default:
        break;
    }

    handlePaginationNavigation(newPagination);
    setPagination(newPagination);
    fetchPayments(newPagination);
  };

  useEffect(() => {
    if (payments.length) {
      const tableStructure = compileTableStructure(
        t,
        payments,
        theme,
        loadingReceipt,
        setLoadingReceipt
      );
      setStructure(tableStructure);

      if (!pagination.total) {
        setPagination(DEFAULT_PAGINATION(total));
      }
    }
  }, [payments, loadingReceipt]);

  return {
    theme,
    structure,
    pagination,
    onChangePage,
    onChangeRows,
  };
};
