/**
 * EnrollmentRenewal scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getEnrollmentRenewalSlice = (state: RootState) => state.EnrollmentRenewal;
