import { actions } from 'store/rootSlices';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import * as selectors from './logic/selectors';
import { t } from 'app';

class GradesManagementUtils {
  static useGradesManagement() {
    const { pautasLista, periodosDisponiveis, filtrarPorPeriodo, loadingStatus, pautaStats, selectedState: selectedReduxState } = useSelector(selectors.getGradesManagementSlice);
    const dispatch = useDispatch();
    const [selectedState, setSelectedState] = useState<string>(selectedReduxState);
    const [searchText, setSearchText] = useState("");
    const [selectedYear, setSelectedYear] = useState<string | null>(null);
    const currentLocale = useSelector(selectors.getCurrentLocale);
    const [filteredPautasByState, setFilteredPautasByState] = useState<any[]>([]);

    const pautaStatsArray = Object.entries(pautaStats).map(([semester, details]) => ({
      semester,
      ...details,
    }));

    const totalPautasStats = pautaStatsArray.reduce((accumulator, current) => {
      const toSubmit = current.toSubmit || 0;
      const toSign = current.toSign || 0;
      const withErrors = current.withErrors || 0;

      return accumulator + toSubmit + toSign + withErrors;
    }, 0);

    const handleSearchChange = (searchText: string) => {
      setSearchText(searchText);
    };

    useEffect(() => {
      const updatedFilteredPautasByState = applyFilters(pautasLista ?? [], selectedReduxState, searchText);
      
      let updatedCountPautasByState = updatedFilteredPautasByState.length;
    
      if (JSON.stringify(filteredPautasByState) !== JSON.stringify(updatedFilteredPautasByState)) {
        setFilteredPautasByState(updatedFilteredPautasByState);
        //dispatch(actions.GradesManagement.setPautasLista(updatedFilteredPautasByState));
      }

      if (updatedCountPautasByState > 0 && pautasLista?.length !== 0) {
        dispatch(
          actions.Toaster.showToaster({
            title: `${t('pautas.have', { textOnly: true })} ${updatedCountPautasByState} ${t('pautas.homeActionInfoToaster' + selectedReduxState, { textOnly: true })}`,
            icon: 'info',
            type: 'info',
          })
        );
      }
      else{
        dispatch(actions.GradesManagement.setSelectedState('Todas'));
        setSelectedState('Todas');
      }
    }, [pautasLista]);
    
    

    const handleYearChange = (e, data) => {
      const selectedValue = data.value;
      setSelectedYear(selectedValue);
      dispatch(actions.GradesManagement.changeYearRequest({ period: data.value }));
      dispatch(actions.GradesManagement.setFiltrarPorPeriodo(data.value));

      const filteredPautasByState = applyFilters(pautasLista ?? [], selectedState, searchText);
      const availableStatesForYear = Array.from(new Set(filteredPautasByState.map((item) => item.estadoActual)));
      if (!availableStatesForYear.includes(selectedState)) {
        handleStateChange({ value: 'Todas' });
        return;
      }

      //displayInfoToaster(selectedValue);
    };

    const applyFilters = (pautas: any[], selectedState: string, searchText: string) => {

      return pautas
        .filter((item) => {
          const searchString = searchText.toLowerCase();
          return (
            item.codPauta.toLowerCase().includes(searchString) ||
            item.nomeDisciplina.toLowerCase().includes(searchString) ||
            item.tipoExame.toLowerCase().includes(searchString) ||
            item.dataLimiteLancamento.toLowerCase().includes(searchString) ||
            item.ntotalAlunos.toString().includes(searchString) ||
            item.estadoActual.toString().includes(searchString)

          );

        })
        .filter((item) => {
          if (selectedState && selectedState !== 'Todas') {
            return item.estadoActual === parseInt(selectedState, 10);
          }
          return true;
        });
    };

    const uniqueStatus = Array.isArray(pautasLista)
      ? Array.from(new Set(pautasLista.map((item: { estadoActual: any }) => item.estadoActual)))
      : [];

    const intersectedPautas = pautasLista
      ?.filter((item) => {
        const searchString = searchText.toLowerCase();
        const matchesSearch =
          item.codPauta.toLowerCase().includes(searchString) ||
          item.nomeDisciplina.toLowerCase().includes(searchString) ||
          item.tipoExame.toLowerCase().includes(searchString) ||
          item.dataLimiteLancamento.toLowerCase().includes(searchString) ||
          item.ntotalAlunos.toString().includes(searchString)

        if (selectedState && selectedState !== 'Todas')
          return matchesSearch && item.estadoActual === parseInt(selectedState, 10);

        return matchesSearch;
      }).map((item) => ({ ...item, estadoActual: item.estadoActual, dataLimiteLancamento: new Date(item.dataLimiteLancamento).getFullYear() < 1970 ? '-' : item.dataLimiteLancamento }));


    const handleStateChange = (selectedItem: { key?: string | number; text?: string; value: string } | undefined) => {
      if (selectedItem) {
        const selectedValue = selectedItem.value;

        setSelectedState(selectedValue);
        dispatch(actions.GradesManagement.setSelectedState(selectedValue));

        let filteredPautasByState;

        if (selectedValue && selectedValue !== 'Todas') {
          filteredPautasByState = applyFilters(pautasLista ?? [], selectedValue, searchText);
        } else {
          filteredPautasByState = applyFilters(pautasLista ?? [], '', searchText);
        }

        setFilteredPautasByState(filteredPautasByState);

        displayInfoToaster(selectedValue);
      }
    };


    const displayInfoToaster = (selectedState) => {
      if (selectedState) {
        const updatedFilteredPautasByState = applyFilters(pautasLista ?? [], selectedState, searchText);
        setFilteredPautasByState(updatedFilteredPautasByState);

        const updatedCountPautasByState = updatedFilteredPautasByState.length;

        if (updatedCountPautasByState > 0) {
          dispatch(
            actions.Toaster.showToaster({
              title: `${t('pautas.have', { textOnly: true })} ${updatedCountPautasByState} ${t('pautas.homeActionInfoToaster' + selectedState, { textOnly: true })}`,
              icon: 'info',
              type: 'info',
            })
          );
        }
      }
    };


    const handleDashboardClick = (state: string, period: string) => {
      dispatch(actions.GradesManagement.setPautasListaState(state));
      dispatch(actions.GradesManagement.changeYearRequest({ period: period, changePautasLista: true }));
      dispatch(actions.GradesManagement.setFiltrarPorPeriodo(period));
      dispatch(actions.GradesManagement.setSelectedState(state));
      setSelectedYear(period);
      setSelectedState(state);
    }

    return {
      pautasLista,
      pautaStatsArray,
      totalPautasStats,
      periodosDisponiveis,
      filtrarPorPeriodo,
      loadingStatus,
      selectedReduxState,
      searchText,
      selectedYear,
      handleSearchChange,
      handleYearChange,
      applyFilters,
      uniqueStatus,
      filteredPautasByState,
      intersectedPautas,
      handleStateChange,
      currentLocale,
      handleDashboardClick
    };
  }
}

export default GradesManagementUtils;
