import React from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

import { createYupSchema, createValidationFormData } from './validations';
import FormElement from './FormElement';

export const getFormElements = (formSchema, form) => {
  let formElements = {};

  formSchema.forEach((formElement, index) => {
    //if is a group of values inside another
    if (formElement.type === 'group' && formElement.values && formElement.values.length > 0) {
      formElements[formElement.nameKey] = {
        group: {
          value: (props) => {
            return constructFormElements(form, formElement, props, formElement.nameKey);
          },
          values: {},
        },
      };

      formElement.values.forEach((fieldElement) => {
        formElements[formElement.nameKey].group.values[fieldElement.nameKey] = (props) => {
          return constructFormElements(
            form,
            fieldElement,
            props,
            `${formElement.nameKey}.${fieldElement.nameKey}`
          );
        };
      });
    } else if (
      formElement.type === 'editableGroupComponent' &&
      formElement.values &&
      formElement.values.length > 0
    ) {
      formElements[formElement.nameKey] = {
        value: (props) => {
          return constructFormElements(form, formElement, props, formElement.nameKey);
        },
        values: {},
      };

      formElement.values.forEach((fieldElement) => {
        formElements[formElement.nameKey].values[fieldElement.nameKey] = (props) => {
          return constructFormElements(
            form,
            fieldElement,
            props,
            `${formElement.nameKey}.${fieldElement.nameKey}`
          );
        };
      });
    } else {
      formElements[formElement.nameKey] = (props) => {
        return constructFormElements(form, formElement, props, formElement.nameKey);
      };
    }

    if (formElement.hidden) {
      form.unregister(formElement.nameKey);
    }
  });

  return formElements;
};

const constructFormElements = (form, formElement, props, name) => {
  let defaultValue;

  defaultValue =
    form.getValues(formElement.nameKey) !== undefined
      ? form.getValues(formElement.nameKey)
      : typeof formElement.value === 'undefined'
      ? undefined
      : formElement.value;

  return (
    <FormElement
      name={name}
      type={formElement.type}
      register={form.register}
      values={formElement.values}
      control={form.control}
      defaultValue={defaultValue}
      form={form}
      {...props}
    />
  );
};

export const getFormElementsData = (formSchema, form, hiddenFields) => {
  let formElementsData = {};

  formSchema.forEach((formElement) => {
    //if is a group of values inside another
    if (formElement.type === 'group' && formElement.values && formElement.values.length > 0) {
      let hidden: boolean = false;

      if (hiddenFields !== null) {
        hidden = hiddenFields.some((field) => {
          return field === formElement.nameKey;
        });
      }

      formElementsData[formElement.nameKey] = {
        group: {
          value: {
            ...formElement,
            hidden,
          },
          values: {},
        },
      };

      formElement.values.forEach((fieldElement) => {
        let hidden: boolean = false;
        if (hiddenFields !== null) {
          hidden = hiddenFields.some((field) => {
            return field === fieldElement.nameKey;
          });
        }

        formElementsData[formElement.nameKey].group.values[fieldElement.nameKey] = {
          ...fieldElement,
          hidden,
        };
      });
    } else if (
      formElement.type === 'editableGroupComponent' &&
      formElement.values &&
      formElement.values.length > 0
    ) {
      //if is a group of values inside another
      let hidden: boolean = false;

      if (hiddenFields !== null) {
        hidden = hiddenFields.some((field) => {
          return field === formElement.nameKey;
        });
      }

      formElementsData[formElement.nameKey] = {
        ...formElement,
        hidden,
        values: {},
      };

      formElement.values.forEach((fieldElement) => {
        let hidden: boolean = false;
        if (hiddenFields !== null) {
          hidden = hiddenFields.some((field) => {
            return field === fieldElement.nameKey;
          });
        }

        formElementsData[formElement.nameKey].values[fieldElement.nameKey] = {
          ...fieldElement,
          hidden,
        };
      });
    } else {
      let hidden: boolean = false;

      if (hiddenFields !== null) {
        hidden = hiddenFields.some((field) => {
          return field === formElement.nameKey;
        });
      }

      formElementsData[formElement.nameKey] = {
        ...formElement,
        hidden,
      };
    }
  });

  return formElementsData;
};

export const watchFields = (formSchema) =>
  formSchema.reduce((acc: any, formElement: any) => {
    let newElementsToWatch: any = [];

    if (
      (formElement.type === 'group' || formElement.type === 'editableGroupComponent') &&
      formElement.values &&
      formElement.values.length > 0
    ) {
      formElement.values.forEach((fieldElement) => {
        if (fieldElement.visibleIf !== null) {
          fieldElement.visibleIf.forEach((visibilityRule) => {
            Object.keys(visibilityRule).forEach((visibilityItem: any) => {
              if (
                !acc.some((watchValues) => watchValues === visibilityItem) &&
                !newElementsToWatch.some((watchValues) => watchValues === visibilityItem)
              ) {
                newElementsToWatch.push(visibilityItem);
              }
            });
          });
        }
      });
    }

    if (formElement.visibleIf !== null) {
      formElement.visibleIf.forEach((visibilityRule) => {
        Object.keys(visibilityRule).forEach((visibilityItem: any) => {
          if (
            !acc.some((watchValues) => watchValues === visibilityItem) &&
            !newElementsToWatch.some((watchValues) => watchValues === visibilityItem)
          ) {
            newElementsToWatch.push(visibilityItem);
          }
        });
      });
    }
    return [...acc, ...newElementsToWatch];
  }, []);

const andReducer = (acc, initalValue: boolean) => {
  return acc && initalValue;
};

const orReducer = (acc, initalValue: boolean) => {
  return acc || initalValue;
};

// watchForm: o nome, e respetivo valor, dos campos cujos valores são dependencias de outros campos
export const getHiddenFields = (watchForm, formSchema) => {
  if (!watchForm) {
    return [];
  }

  let hiddenComponents = [] as string[];

  // iterar por cada componente no formSchema
  formSchema.forEach((uiComponent) => {
    if (uiComponent['visibleIf'] !== null) {
      let visibilityResults = [] as boolean[]; // array com os resultados dos vários objectos no visibleIf, correspondentes a um componente no formSchema

      // iterar por todos os visibleIfs, para ir adicionando o resultado de cada um no visibilityResults
      uiComponent['visibleIf'].forEach((visibleEntry) => {
        // iterar por todos os campos no visibleIf e agregar tudo com a operação AND
        const result = Object.keys(visibleEntry)
          .map((nameKey) => {
            if (watchForm[nameKey] !== null) {
              return watchForm[nameKey] === visibleEntry[nameKey];
            } else {
              return false;
            }
          })
          .reduce(andReducer, true);

        visibilityResults.push(result);
      });

      const visibleResult = visibilityResults.reduce(orReducer, false);

      if (!visibleResult) {
        hiddenComponents.push(uiComponent['nameKey']);
      }
    }
  });

  return hiddenComponents;
};

export const getForm = (formSchema) => {
  const formValidation = createValidationFormData(formSchema);
  const yupSchema = formValidation.reduce(createYupSchema, {});
  const validationSchema = yup.object().shape(yupSchema);
  return yupResolver(validationSchema);
};

//TODO fix in backend
export const flagInputHotfix = (value) => ({ input: value, countryValue: 'pt' });
