/**
 * Mandatory Options Dropdown container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IUc } from 'shared/types';

import { findSelectedOptions } from '../utils';

interface IMandatoryOptionsDropdown {
  id: number;
  name: string;
  selected: number | null;
  ucs: (IUc & { selected: boolean })[];
  optionId: number;
}

const initialState = {
  options: [] as IMandatoryOptionsDropdown[],
  confirmationModalIsOpen: false,
  optionIndexToRemove: null as null | number,
  ucIdToRemove: null as null | { ucId: number; groupId: number | null },
};

export default createSlice({
  name: 'MandatoryOptionsDropdown',
  initialState,
  reducers: {
    setMandatoryOptions: (state, action: PayloadAction<any[]>) => {},
    setOptions: (state, action: PayloadAction<IMandatoryOptionsDropdown[]>) => {
      state.options = action.payload;
    },
    setConfirmationModalOpening: (state, action: PayloadAction<boolean>) => {
      state.confirmationModalIsOpen = action.payload;
    },
    // hold option index to remove while waiting for user confirmation
    setOptionIndexToRemove: (state, action: PayloadAction<number | null>) => {
      state.optionIndexToRemove = action.payload;
    },
    // hold UC id to remove while waiting for user confirmation
    setUcIdToRemove: (
      state,
      action: PayloadAction<{ ucId: number; groupId: number | null } | null>
    ) => {
      state.ucIdToRemove = action.payload;
    },
  },
});
