/**
 * Debits Table container
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faEye } from '@fortawesome/pro-regular-svg-icons';

import {
  Table,
  Button as ButtonComponent,
  Text,
  StatusLabel,
  SearchBox,
  Dropdown,
} from '@paco_ua/pacoui';
import styled from 'styled-components';

import {
  IDebitsTableProps,
  ITranslations,
  IDebitData,
} from 'scenes/VirtualSecretary/Payments/types';
import { DEBIT_STATUS, DEBIT_STATUS_COLOR } from './utils';

import useTable from './useTable';
import { findItem } from './utils';

const ASSOCIATED_COSTS_TYPES = {
  lateFeeCost: 'days',
  ucCost: 'ucs',
  certCost: 'copies',
  mailCost: 'mailCost',
};

const getCellButton = (
  debit: IDebitData[],
  status: string,
  t: ITranslations,
  //openPaymentModal: (data: IDebitData[]) => void,
  openPendingDebitsPaymentPage: () => void,
  disableButtons?: boolean,
  color?: string,
  setPreSelectedDebits?: (debit: IDebitData[]) => void
) => {
  switch (status) {
    case DEBIT_STATUS.UNPAID:
    case DEBIT_STATUS.IN_DEBIT:
      return (
        <Button
          color={color}
          onClick={() => {
            //openPaymentModal(debit);

            // when clicking the button corresponding to a specific debit
            if (setPreSelectedDebits) {
              setPreSelectedDebits([debit[0]]);
            }

            openPendingDebitsPaymentPage();
          }}
          leftIcon={<FontAwesomeIcon icon={faCreditCard} />}
          action
          disabled={disableButtons}
        >
          {t('generic.pay', { textOnly: true })}
        </Button>
      );
    // TODO: add onClick action
    case DEBIT_STATUS.AWAITING_PAYMENT:
      return (
        <Button
          color={color}
          onClick={() => true}
          leftIcon={<FontAwesomeIcon icon={faEye} />}
          action
          disabled={disableButtons}
        >
          {t('generic.reference', { textOnly: true })}
        </Button>
      );
    case DEBIT_STATUS.IN_DEBIT_AWAITING_PAYMENT:
      return (
        <Button
          color={color}
          onClick={() => true}
          leftIcon={<FontAwesomeIcon icon={faEye} />}
          action
          disabled={disableButtons}
        >
          {t('generic.check', { textOnly: true })}
        </Button>
      );
    default:
      return null;
  }
};

const compileTableRow = (
  debit: IDebitData,
  theme,
  t: ITranslations,
  openPendingDebitsPaymentPage: () => void,
  value: number,
  selectedDebits: IDebitData[],
  isSelected?: boolean,
  disableButtons?: boolean,
  setPreSelectedDebits?: (debit: IDebitData[]) => void
) => {
  const preSelected = findItem(debit.debitId, selectedDebits);
  let tableRow: any = {
    value: { ...debit, id: value },
    defaultSelected: preSelected,
    rowProps: {},
    cells: [
      {
        content: (
          <ContentWrapper>
            {debit.debitType && (
              <Text size="xSmall" color="darkGrey">
                {t(`debitTypes.${debit.debitType.toLowerCase()}`, { textOnly: true })}
              </Text>
            )}
            <DebitStatusText
              size="article"
              weight="medium"
              color={debit.debitStatus === DEBIT_STATUS.IN_DEBIT ? 'dangerRed' : 'primary'}
            >
              {debit.description}
            </DebitStatusText>
          </ContentWrapper>
        ),
        cellProps: { width: 6, singleLine: false },
      },
      {
        content: (
          <CurrencyText size="article">
            {t('generic.euros', { textOnly: true, count: debit.value.toFixed(2) })}
          </CurrencyText>
        ),
        cellProps: {
          width: 2,
          singleLine: false,
          style: {
            textAlign: 'center',
          },
        },
      },
      {
        content: (
          <Text
            color={debit.debitStatus === DEBIT_STATUS.IN_DEBIT ? 'dangerRed' : ''}
            size="article"
          >
            {moment(debit.dueDate).format('DD-MM-YYYY')}
          </Text>
        ),
        cellProps: {
          width: 3,
          singleLine: true,
          style: {
            textAlign: 'center',
          },
        },
      },
      {
        content: (
          <StatusLabelContainer>
            <StatusLabel
              background={DEBIT_STATUS_COLOR(theme)[debit.debitStatus]}
              label={t(`payments.${debit.debitStatus}`, { textOnly: true })}
            />
          </StatusLabelContainer>
        ),
        cellProps: {
          width: 2,
          singleLine: false,
          style: {
            textAlign: 'center',
          },
        },
      },
      {
        content: getCellButton(
          [debit],
          debit.debitStatus,
          t,
          openPendingDebitsPaymentPage,
          disableButtons,
          '',
          setPreSelectedDebits
        ),
        cellProps: { width: 3, singleLine: false },
      },
    ],
  };

  // if in debit and selected in multiple payment show data below
  if (debit.debitStatus === DEBIT_STATUS.IN_DEBIT && isSelected) {
    tableRow = {
      ...tableRow,
      ...{
        collapsableOpen: true,
        collapsableRows: [
          {
            cells: [
              {
                content: (
                  <ContentWrapper>
                    <Text weight="regular" size="small">
                      {t('payments.inDebit')}
                    </Text>
                    <InDebitDescriptionText size="xSmall">
                      {t('payments.inDebitDescription')}
                    </InDebitDescriptionText>
                  </ContentWrapper>
                ),
                cellProps: { colSpan: 6, singleLine: false },
              },
            ],
          },
        ],
      },
    };
  }

  if (debit.associatedCosts !== null && debit.associatedCosts.length > 0 && isSelected) {
    tableRow = {
      ...tableRow,
      ...{
        collapsableOpen: true,
        collapsableRows: [
          ...debit.associatedCosts.map((entry) => {
            return {
              rowProps: {
                bgColor: theme.colors.softLightGrey,
                disableHover: true,
              },
              cells: [
                {
                  cellProps: { colSpan: 1, singleLine: false },
                },
                {
                  content: (
                    <ExtraFeeWrapper>
                      <ExtraFeeCellWrapper>
                        {ASSOCIATED_COSTS_TYPES[entry.type] ? (
                          <Text color="plusDarkGrey" size="small" weight="medium">
                            {t(`payments.${entry.type}`, { textOnly: true })}
                          </Text>
                        ) : (
                          <Text>{entry.type}</Text>
                        )}
                      </ExtraFeeCellWrapper>
                      {entry.type !== ASSOCIATED_COSTS_TYPES.mailCost &&
                        ASSOCIATED_COSTS_TYPES[entry.type] && (
                          <ExtraFeeValueCellWrapper>
                            <Text color="plusDarkGrey" size="xSmall" weight="regular">
                              {`${t(`payments.${ASSOCIATED_COSTS_TYPES[entry.type]}`, {
                                textOnly: true,
                                count: entry.copies,
                              })}`}
                            </Text>
                          </ExtraFeeValueCellWrapper>
                        )}
                    </ExtraFeeWrapper>
                  ),
                  cellProps: { colSpan: 1, singleLine: false },
                },
                {
                  content: (
                    <CostCellWrapper>
                      <Text color="plusDarkGrey" size="article" weight="regular">
                        {`${t('generic.euros', {
                          textOnly: true,
                          count: entry.value.toFixed(2),
                        })}`}
                      </Text>
                    </CostCellWrapper>
                  ),
                  cellProps: { colSpan: 1, singleLine: false },
                },
                {
                  content: '',
                  cellProps: {
                    colSpan: '100%',
                    singleLine: false,
                  },
                },
              ],
            };
          }),
          ...(tableRow.collapsableRows ?? []),
        ],
      },
    };
  }

  return tableRow;
};

const DebitsTable = ({
  t,
  loading,
  data,
  preSelectedDebits,
  total,
  fetchDebits,
  setPreSelectedDebits,
  openPendingDebitsPaymentPage,
  selectedDebits,
  addFilters,
  filterParams,
}: IDebitsTableProps) => {
  const [disablePayButtons, setDisabelPayButtons] = useState(false);

  const [filtersChanged, setFiltersChanged] = useState(false);

  const [numSelectedDebits, setNumSelectedDebits] = useState(0);

  const { structure, pagination, onChangePage, onChangeRows, theme } = useTable(
    t,
    data,
    preSelectedDebits,
    compileTableRow,
    total,
    fetchDebits,
    disablePayButtons,
    setPreSelectedDebits,
    openPendingDebitsPaymentPage,
    selectedDebits,
    filterParams,
    filtersChanged
  );

  useEffect(() => {
    setFiltersChanged(true);
  }, [filterParams]);

  return (
    <>
      <SearchAndFiltersWrapper>
        <SearchBox
          placeholder={addFilters.searchPlaceholder}
          borderColor={theme.colors.plusDarkGrey}
          iconColor={theme.colors.plusDarkGrey}
          delay={500}
          onSearch={(value) => {
            addFilters.onSearch(value);
          }}
        />
        <Dropdown
          placeholder={addFilters.dropdownPlaceholder}
          canBeCleared
          options={addFilters.filterTypes}
          selectionWeight={'400'}
          selectionFontSize={'medium'}
          onChange={(e, { value }) => {
            addFilters.onFilterChange(value);
          }}
          onClear={() => {
            addFilters.onFilterChange('');
          }}
        />
      </SearchAndFiltersWrapper>

      <DebitTableWrapper>
        <Table
          dropdownUpward
          unstackable
          disabledAccordion
          structure={structure}
          loading={loading}
          selectable
          onChangeSelected={(selectedDebitsList, structureRows) => {
            if (selectedDebitsList.length !== numSelectedDebits) {
              setNumSelectedDebits(selectedDebitsList.length);

              if (selectedDebitsList.length > 1) {
                setDisabelPayButtons(true);
              } else {
                setDisabelPayButtons(false);
              }

              setPreSelectedDebits(selectedDebitsList);
            }
          }}
          selectedInfoTitle={
            <Text size="article" weight="regular" color="white">
              {t('payments.multipaymentsSelection')}
            </Text>
          }
          selectedInfoSubTitle={(value) => {
            return (
              <Text size="xSmall" color="white">
                {t('generic.selectedItem', { textOnly: true, count: value })}
              </Text>
            );
          }}
          selectedInfoAction={
            <>
              {getCellButton(
                preSelectedDebits,
                DEBIT_STATUS.UNPAID,
                t,
                openPendingDebitsPaymentPage,
                undefined,
                theme.colors.white,
                undefined
              )}
            </>
          }
          pagination={pagination}
          onChangeRows={onChangeRows}
          onChangePage={onChangePage}
          resetSelectedIds={filtersChanged}
          setResetSelectedIdsFunction={() => {
            setFiltersChanged(false);
          }}
          translations={t}
          collapsedColor={theme.colors.softGrey}
        />
      </DebitTableWrapper>
    </>
  );
};

export default DebitsTable;

const Button = styled(ButtonComponent)`
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatusLabelContainer = styled.div`
  div {
    margin: 0px auto;

    @media (max-width: 992px) {
      width: auto;
      max-width: 120px;
    }
  }
`;

const DebitStatusText = styled(Text)`
  margin-top: 4px;
  line-height: 1.3;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const CurrencyText = styled(Text)`
  word-break: keep-all;
`;

const DebitTableWrapper = styled.div`
  margin-top: 28px;
  overflow: auto;

  &&& th {
    padding-right: 14px !important;
    padding-left: 14px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-right: 14px !important;
    padding-left: 14px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;

const InDebitDescriptionText = styled(Text)`
  margin-top: 8px;
`;

const SearchAndFiltersWrapper = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  > div {
    width: 33%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const ExtraFeeWrapper = styled.div``;

const ExtraFeeCellWrapper = styled.div`
  line-height: 1;
`;

const ExtraFeeValueCellWrapper = styled.div`
  line-height: 1;
  margin-top: 4px;
`;

const CostCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;
