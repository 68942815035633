import React from "react";
import { actions } from 'store/rootSlices';
import { t } from 'app';
import { gzip } from 'pako';

export const inputStyle = {
  textAlign: 'right',
  height: '2rem',
  width: '6rem',
};

export const getGradesEditValues = (gradesData, gradeEdits) => {
  return gradesData.map(grade => ({
    ...grade,
    nota: gradeEdits[grade.nmec] ?? grade.nota,
  }));
}

// Dynamically determines the range of valid grades based on HDADOS and Dicionarios
export const getValidGradeValues = (HDADOS, Dicionarios) => {
  let validValues = [];

  // Dynamically add values based on HDADOS.tipoAvaliacao.gama if available
  if (HDADOS.tipoAvaliacao && HDADOS.tipoAvaliacao.gama) {
    const min = parseInt(HDADOS.tipoAvaliacao.gama.valorMinimo, 10);
    const max = parseInt(HDADOS.tipoAvaliacao.gama.valorMaximo, 10);
    validValues = Array.from({ length: max - min + 1 }, (_, i) => i + min);
  }

  // Add additional valid values from Dicionarios
  if (Dicionarios && Dicionarios.data && Array.isArray(Dicionarios.data)) {
    Dicionarios.data.forEach(dicionario => {
      if (dicionario.bodyItems && Array.isArray(dicionario.bodyItems)) {
        dicionario.bodyItems.forEach(item => {
          const valor = parseInt(item.valor, 10);
          if (!validValues.includes(valor)) {
            validValues.push(valor);
          }
        });
      }
    });
  }

  return validValues;
};


// Determine valid values
export const isValidGradeValue = (value, validGradeValues) => {
  if (value === '') return true;

  const intValue = parseInt(value, 10);
  return validGradeValues.includes(intValue) && intValue.toString() === value;
};


// Determine the border style based on grade changes
export const getBorderStyle = (originalValue, currentValue) => {
  return originalValue !== currentValue ? '2px solid yellow' : '1px solid';
};

// Handle changes to the grade input fields
export const handleGradeChange = (dispatch, gradesData, gradeEdits, validGradeValues) => (nmec, newValue) => {
  if (isValidGradeValue(newValue, validGradeValues)) {
    const newGradeEdits = { ...gradeEdits, [nmec]: newValue };
    dispatch(actions.EditGrades.setGradeEdits(newGradeEdits));

  } else {
    // If the value is invalid, place an empty string in the gradeEdits
    const revertedGradeEdits = { ...gradeEdits, [nmec]: '' };
    dispatch(actions.EditGrades.setGradeEdits(revertedGradeEdits));
  }
};

// Render the grade input field
export const renderGradeInput = (item, gradeEdits, handleGradeChange, validGradeValues, gradesData) => {
  const currentValue = gradeEdits[item.nmec] ?? item.nota;
  const borderStyle = getBorderStyle(item.nota, currentValue);
  const isValid = isValidGradeValue(currentValue, validGradeValues);

  return (
    <input
      id={`grade-${item.nmec}`}
      type="text"
      value={currentValue}
      onChange={(e) => handleGradeChange(item.nmec, e.target.value)}
      onKeyDown={(e) => handleKeyDown(e, item.nmec, gradesData)}
      style={{ ...inputStyle, border: borderStyle, borderColor: isValid ? 'initial' : 'red', outline: 'none' }}
    />
  );
};

const handleKeyDown = (e, nmec, gradesData) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    const currentIndex = gradesData.findIndex(item => item.nmec === nmec);
    const nextIndex = currentIndex + 1;

    if (nextIndex < gradesData.length) {
      const nextInput = document.getElementById(`grade-${gradesData[nextIndex].nmec}`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  }
};

export const handleCSVImport = async (event, dispatch) => {
  if (event.target.files && event.target.files[0]) {
    const file = event.target.files[0];

    dispatch(actions.EditGrades.setCSVFile(file));
    dispatch(actions.EditGrades.setIsCSVModalOpen());
  }
};

// Get suplementary grades options for the dropdown
export const getDropdownSuplementaryGradesOptions = (Dicionarios) => {
  let options = [];

  if (Dicionarios && Dicionarios.data && Array.isArray(Dicionarios.data)) {
    Dicionarios.data.forEach(dicionario => {
      if (dicionario.bodyItems && Array.isArray(dicionario.bodyItems)) {
        dicionario.bodyItems.forEach(item => {
          options.push({
            key: item.valor,
            text: item.valor + " - " + t(`pautas.evaluationCode${item.valor}`, { textOnly: true }),
            value: item.valor,
          });
        });
      }
    });
  }

  return options;
};

export const handleSupplementaryGradeChange = (dispatch, gradesData, gradeEdits, value) => {
  // Update gradesData where nota is ""
  const updatedGradesData = gradesData.reduce((acc, grade) => {
    if (grade.nota === "") {
      return { ...acc, [grade.nmec]: value };
    }
    return acc;
  }, {});

  // Update gradeEdits where nota is ""
  const updatedGradeEdits = Object.entries(gradeEdits).reduce((acc, [nmec, grade]) => {
    if (grade === "") {
      return { ...acc, [nmec]: value };
    }
    return { ...acc, [nmec]: grade };
  }, {});

  // Combine updatedGradesData and updatedGradeEdits
  const newGradeEdits = { ...updatedGradesData, ...updatedGradeEdits };

  dispatch(actions.EditGrades.setGradeEdits(newGradeEdits));
  dispatch(actions.EditGrades.setDropdownValueSelected(value));
}

// Clear suplementary grades from gradeEdits
export const handleSupplementaryGradeClear = (dispatch, gradeEdits, value) => {
  const updatedGradeEdits = Object.entries(gradeEdits).reduce((acc, [nmec, grade]) => {
    if (value === grade) {
      return { ...acc, [nmec]: "" };
    }
    return { ...acc, [nmec]: grade };
  }, {});

  dispatch(actions.EditGrades.setGradeEdits(updatedGradeEdits));
}

// Compress the grade data
export const compressGradeData = (dataObject) => {
  // Convert the data object to JSON string
  const jsonData = JSON.stringify(dataObject);

  // Compress the JSON string using pako
  const compressed = gzip(jsonData);

  const base64Data = Buffer.from(compressed).toString('base64');

  return {
    "base_64": base64Data
  }
}