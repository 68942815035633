import axios from 'axios';
import Endpoints from 'api/endpoints';

import config from 'config';

import { listAllScopes, checkBlacklist } from 'api/utils';

import { IApiCall } from '../index';

const token = (data) => {
  return axios
    .post(
      Endpoints.signin.token,
      {
        ...data,
      },
      {
        headers: {
          Authorization: `Basic ${config.OIDC_CONFIG.clientSecret}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const refresh = (refreshToken) => {
  return axios
    .post(
      Endpoints.signin.token,
      {
        grantType: 'refresh_token',
        refreshToken,
      },
      {
        headers: {
          Authorization: `Basic ${config.OIDC_CONFIG.clientSecret}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getSuperUser = async (iupi, token) => {
  return checkBlacklist(['student', 'researcher', 'protocol', 'scholarship'], false)
    .then(() =>
      axios
        .get(Endpoints.signin.superUser, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // params: {
          //   iupi,
          // },
        })
        .then((res) => res.data)
        .catch((err) => {
          throw err;
        })
    )
    .catch((err) => {
      throw err;
    });
};

const getRcuGroups = async (iupi, token) => {
  return axios
    .get(Endpoints.signin.rcuGroups, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // params: {
      //   iupi,
      // },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const postLogin = async (scopes) => {
  return axios
    .post(Endpoints.signin.login,{
      scopes,
    })
    .then(res => res.data)
    .catch(err => {throw err;})
};

export default {
  token: { call: token } as IApiCall,
  refresh: { call: refresh } as IApiCall,
  getSuperUser: { call: getSuperUser } as IApiCall,
  getRcuGroups: { call: getRcuGroups } as IApiCall,
  postLogin: {call: postLogin} as IApiCall,
};
