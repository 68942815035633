/**
 * PageStructure container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Container, Grid } from 'semantic-ui-react';

import { AnimatedBackground, LoadingBackgroundWrapper } from '@paco_ua/pacoui';

interface IProps {
  loading?: boolean;
  expanded?: boolean;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  subHeader?: React.ReactNode;
  mainContent: React.ReactNode;
  secondaryContent?: React.ReactNode;
  secondaryContentNonStickyGrow?: boolean;
  secondaryContentOffset?: number;
}

const PageStructure: React.FC<IProps> = ({
  loading,
  expanded,
  headerLeft,
  headerRight,
  subHeader,
  mainContent,
  secondaryContent,
  secondaryContentOffset,
  secondaryContentNonStickyGrow,
}) => {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper expanded={expanded ? 1 : 0}>
      {(headerLeft || headerRight) && (
        <HeaderWrapper>
          <Grid centered={secondaryContent ? false : expanded ? false : true}>
            <ContentHeaderWrapperColumn
              maxWidth={!secondaryContent && !expanded}
              mobile={16}
              tablet={16}
              computer={!secondaryContent && !expanded ? 16 : 16}
              largeScreen={!secondaryContent && !expanded ? 12 : 16}
              widescreen={!secondaryContent && !expanded ? 12 : 16}
            >
              <Grid padded={false}>
                <HeaderColumnWrapper
                  hassubheader={subHeader !== undefined ? 1 : 0}
                  mobile={16}
                  tablet={16}
                  computer={!secondaryContent && !expanded ? 10 : 10}
                  largeScreen={!secondaryContent && !expanded ? 10 : 10}
                  widescreen={!secondaryContent && !expanded ? 10 : 10}
                >
                  <DisplayableWrapper hide={loading}>
                    <HeaderLeftWrapper>{headerLeft}</HeaderLeftWrapper>
                  </DisplayableWrapper>
                  {loading && headerLeft && (
                    <AnimatedBackground height={theme.sizes.xxLarge} width="80%" />
                  )}
                </HeaderColumnWrapper>

                <HeaderColumnWrapper
                  hassubheader={subHeader !== undefined ? 1 : 0}
                  mobile={16}
                  tablet={16}
                  computer={!secondaryContent && !expanded ? 6 : 6}
                  largeScreen={!secondaryContent && !expanded ? 6 : 6}
                  widescreen={!secondaryContent && !expanded ? 6 : 6}
                >
                  <HeaderRightWrapper>
                    <DisplayableWrapper hide={loading}>{headerRight}</DisplayableWrapper>
                    {loading && headerRight && (
                      <AnimatedBackground height={theme.sizes.xxLarge} width="40%" />
                    )}
                  </HeaderRightWrapper>
                </HeaderColumnWrapper>
              </Grid>
            </ContentHeaderWrapperColumn>

            {subHeader && (
              <SubHeaderWrapper
                maxWidth={!secondaryContent && !expanded}
                mobile={16}
                tablet={16}
                computer={secondaryContent ? 12 : expanded ? 16 : 16}
                largeScreen={secondaryContent ? 12 : expanded ? 16 : 12}
                widescreen={secondaryContent ? 12 : expanded ? 16 : 12}
              >
                <DisplayableWrapper hide={loading}>{subHeader}</DisplayableWrapper>
                {loading && <AnimatedBackground height={theme.sizes.xxLarge} width="100%" />}
              </SubHeaderWrapper>
            )}
          </Grid>
        </HeaderWrapper>
      )}

      <ContentWrapper>
        <ContentWrapperGrid centered={secondaryContent ? false : expanded ? false : true}>
          <ContentWrapperColumn
            maxWidth={!secondaryContent && !expanded}
            mobile={16}
            tablet={16}
            computer={secondaryContent ? 12 : expanded ? 16 : 16}
            largeScreen={secondaryContent ? 12 : expanded ? 16 : 12}
            widescreen={secondaryContent ? 12 : expanded ? 16 : 12}
          >
            {loading && (
              <LoadingBackgroundWrapper
                loading={true}
                maskProps={{
                  lineBreak: '8px',
                  widths: ['30%', '40%', '100%', '100%', '100%', '100%', '60%', '40%'],
                }}
                length={8}
              />
            )}
            <DisplayableWrapper hide={loading}>{mainContent}</DisplayableWrapper>
          </ContentWrapperColumn>
          {secondaryContent && (
            <Grid.Column largeScreen={4} computer={4}>
              <SecondaryContentWrapper
                fix={secondaryContentNonStickyGrow}
                offset={secondaryContentOffset}
              >
                {loading && <AnimatedBackground height={theme.sizes.xxLarge} width="100%" />}
                <DisplayableWrapper hide={loading}>{secondaryContent}</DisplayableWrapper>
              </SecondaryContentWrapper>
            </Grid.Column>
          )}
        </ContentWrapperGrid>
      </ContentWrapper>
    </Wrapper>
  );
};

export default PageStructure;

const Wrapper = styled(Container)<{ expanded?: boolean }>`
  &&& {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: ${({ expanded }) => (expanded ? '1920px' : '1388px')};
    ${({ expanded }) => expanded && `padding:0;`}
  }
`;

const DisplayableWrapper = styled.div<{ hide?: boolean }>`
  display: ${({ hide }) => (hide ? 'none' : 'unset')};
  height: 100%;
`;

const HeaderWrapper = styled.div``;

const HeaderLeftWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const HeaderRightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const SubHeaderWrapper = styled(({ maxWidth, ...rest }) => <Grid.Column {...rest} />)<{
  maxWidth?: boolean;
}>`
  &&& {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 14px;

    ${({ maxWidth }) =>
      maxWidth &&
      `
        @media only screen and (max-width: 1919px) and (min-width: 1200px) {
          min-width: 1000px !important;
        }
      `}
  }
`;

const ContentHeaderWrapperColumn = styled(({ maxWidth, ...rest }) => <Grid.Column {...rest} />)<{
  maxWidth?: boolean;
}>`
  &&& {
    padding-bottom: 0 !important;
    margin-bottom: ${({ hassubheader }) => (hassubheader ? `8px` : `15px`)};

    ${({ maxWidth }) =>
      maxWidth &&
      `
    @media only screen and (max-width: 1919px) and (min-width: 1200px) {
      min-width: 1000px !important;
    }
    `}
  }
`;

const HeaderColumnWrapper = styled(Grid.Column)<{ hassubheader: boolean }>`
  &&& {
    padding-bottom: 0 !important;
    margin-bottom: ${({ hassubheader }) => (hassubheader ? `8px` : `15px`)};
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;

  > .grid {
    padding-top: 40px;
  }
`;

const SecondaryContentWrapper = styled.div<{ offset?: number; fix?: boolean }>`
  position: sticky;
  top: 100px;
  margin-top: ${({ offset }) => (offset ? `${offset}px` : `0`)};

  ${({ fix }) =>
    fix
      ? `  
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;

        >div{
          height: 0;
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
      `
      : ``}
`;

const ContentWrapperGrid = styled(Grid)`
  &&& {
    flex-grow: 1;
    padding-top: 0;
    margin-top: 20px;

    @media only screen and (max-width: 767px) {
      &.ui.grid > .column:not(.row) {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
      }
    }
  }
`;

const ContentWrapperColumn = styled(({ maxWidth, ...rest }) => <Grid.Column {...rest} />)<{
  maxWidth?: boolean;
}>`
  &&& {
    ${({ maxWidth }) =>
      maxWidth &&
      `
    @media only screen and (max-width: 1919px) and (min-width: 1200px) {
      min-width: 1000px !important;
    }
    `}
  }
`;
