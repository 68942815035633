/**
 * LogsStudent scene slice
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  loadingModule: true
}

export default createSlice({
  name: 'LogsStudent',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setLoadingModule: (state, action: PayloadAction<boolean>) => {
      state.loadingModule = action.payload;
    },
  }
})