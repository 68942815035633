/**
 * ClassCardDetails container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState, useContext } from 'react';
import moment from 'moment';

import { t } from 'app';

import styled, { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faAngleUp, faAngleDown, faClone } from '@fortawesome/pro-light-svg-icons';

import { Text, Accordion } from '@paco_ua/pacoui';

import { GAEvent } from 'utils';

interface IProps {
  locked?: boolean;
  overlaps?: boolean;
  finalPhase?: boolean;
  ranking: {
    total?: number;
    above?: number;
    below?: number;
    freeSlots?: number;
  };
  classItem: {
    ucId: number;
    ucInitials: string;
    ects?: number;
    ucFullName: string;
    color?: string;
    classType: string;
    className: string;
    classId: number;
    locked: boolean;
    classesDays: { startTime: string; endTime: string; periodDays: string[] }[];
    allocationStatus?: 'likely' | 'uncertain' | 'unlikely';
    automaticAllocationHist?: string;
    automaticAllocation?: IAutomaticAllocation[];
    allocated: {
      state: 'assigned' | 'automatic' | 'notAssigned';
      previousTries?:
        | {
            id: number;
            phaseName: number;
            name: number;
            totalSlots: number;
            freeSlots: number;
            occupiedSlots: number;
            selects: number;
            hist: string;
          }[]
        | null;
    };
    slots?: number;
  };
  seriationFinalResult?: {
    classId: number;
    name: string;
    freeSlotsStudent: number;
    occupiedSlotsStudent: number;
    totalSlots: number;
  }[];
}

interface IAutomaticAllocation {
  classId: number;
  className: string;
  occupiedSlots: number;
  totalSlots: number;
  freeSlots: number;
}

const ClassCardDetails: React.FC<IProps> = ({ overlaps, ranking, classItem }) => {
  const [seriationPlacedResultOpen, setSeriationPlacedResultOpening] = useState(false);
  const [rankingResultOpening, setRankingResultOpening] = useState(false);

  const theme = useContext(ThemeContext);

  const hasRankingProvisory =
    ranking.total !== undefined &&
    ranking.total !== null &&
    ranking.freeSlots !== undefined &&
    ranking.freeSlots !== null;

  const getSeriationAllocationHeader = (state: 'assigned' | 'automatic') => {
    if (state === 'assigned') {
      return (
        <SeriationPlacedResultHeader>
          <SeriationPlacedManualResult>{t('sgh.placed')}</SeriationPlacedManualResult>
        </SeriationPlacedResultHeader>
      );
    } else if (state === 'automatic') {
      return (
        <SeriationPlacedResultHeader>
          <SeriationPlacedAutomaticResult>
            <FontAwesomeIcon icon={faInfoCircle} color={theme.colors.primary} />
            {t('sgh.automaticSeriation')}
          </SeriationPlacedAutomaticResult>
        </SeriationPlacedResultHeader>
      );
    }
  };

  const getAccordion = () => {
    return (
      <AccordionIconWrapper>
        <FontAwesomeIcon icon={faAngleDown /*rankingResultOpening ? faAngleUp : faAngleDown*/} />
      </AccordionIconWrapper>
    );
  };

  const getAccordionResults = () => {
    return (
      <AccordionIconWrapper>
        <FontAwesomeIcon icon={faAngleDown} />
      </AccordionIconWrapper>
    );
  };

  const getClassDetails = () => {
    return (
      <>
        <ClassDetailsItemSchedule>
          <Text color="primary" weight="bold">
            {t('sgh.schedule')}:
          </Text>
          {classItem.classesDays.map((classDay, key) => (
            <Text color="plusDarkGrey" weight="light" key={`classCardDetails_classesDay_${key}`}>
              {/*all period days correspond to same day*/}
              {`${moment(classDay.periodDays[0]).format('dddd')} | ${moment(
                classDay.startTime,
                'HH:mm:ss'
              ).format('HH:mm')} - ${moment(classDay.endTime, 'HH:mm:ss').format('HH:mm')}`}
            </Text>
          ))}
        </ClassDetailsItemSchedule>
        <ClassDetailsItemSchedule>
          <Text color="primary" weight="bold">
            {t('sgh.overlap')}:
          </Text>
          <Text color="plusDarkGrey" weight="light">
            {overlaps ? t('sgh.classHasOverlaps') : t('sgh.classDoesntHaveOverlaps')}
          </Text>
        </ClassDetailsItemSchedule>
      </>
    );
  };

  //TODO check this container props+ structure
  return (
    <Wrapper colorId={classItem.color}>
      <ContentWrapper /*paddingBottom={!hasRankingProvisory}*/>
        <FullTitle>
          <UCID>{`${classItem.ucId} - `}</UCID>
          <UCInitials>{classItem.ucInitials}</UCInitials>
        </FullTitle>

        <ClassName color={theme.colors.plusDarkGrey} size="small" weight="light">
          {classItem.className}
        </ClassName>

        <UCFullName color={theme.colors.plusDarkGrey} size="article" weight="medium">
          {classItem.ucFullName}
        </UCFullName>

        {classItem?.allocated?.state === 'notAssigned' &&
          (!hasRankingProvisory ? (
            <SlotsNumber color={theme.colors.plusDarkGrey} size="small" weight="light">
              {t('sgh.studentAvailableSlots_plural')}: {classItem.slots}
            </SlotsNumber>
          ) : (
            <SlotsNumber color={theme.colors.plusDarkGrey} size="small" weight="light">
              {t('sgh.studentAvailableSlots_plural')}: {ranking.freeSlots}
            </SlotsNumber>
          ))}

        {classItem?.allocated?.state === 'notAssigned' && (
          <Accordion
            structure={[
              {
                parent: getAccordion(),
                children: [
                  <React.Fragment key={`classCardDetails_classItem_`}>
                    {getClassDetails()}
                    {hasRankingProvisory && (
                      <RankingRow>
                        <RankingCol>
                          <Ranking color={theme.colors.plusDarkGrey} size="xLarge" weight="regular">
                            {ranking.total}
                          </Ranking>
                          <RankingDesc size="xSmall" weight="light" color={theme.colors.darkGrey}>
                            {t('sgh.studentSelectedClass', { count: ranking.total })}
                          </RankingDesc>
                        </RankingCol>
                        <RankingCol>
                          <Ranking color={theme.colors.dangerRed} size="xLarge" weight="regular">
                            {ranking?.above || 0}
                          </Ranking>
                          <RankingDesc size="xSmall" weight="light" color={theme.colors.darkGrey}>
                            {t('sgh.studentRankingAbove', { count: ranking?.above || 0 })}
                          </RankingDesc>
                        </RankingCol>
                        <RankingCol>
                          <Ranking color={theme.colors.plusDarkGrey} size="xLarge" weight="regular">
                            {ranking?.below || 0}
                          </Ranking>
                          <RankingDesc size="xSmall" weight="light" color={theme.colors.darkGrey}>
                            {t('sgh.studentRankingBelow', { count: ranking?.below || 0 })}
                          </RankingDesc>
                        </RankingCol>
                        <RankingCol>
                          <Ranking color={theme.colors.successGreen} size="xLarge" weight="regular">
                            {ranking.freeSlots}
                          </Ranking>
                          <RankingDesc size="xSmall" weight="light" color={theme.colors.darkGrey}>
                            {t('sgh.studentAvailableSlots', { count: ranking.freeSlots })}
                          </RankingDesc>
                        </RankingCol>
                      </RankingRow>
                    )}
                  </React.Fragment>,
                ],
              },
            ]}
            onOpen={() => {
              setRankingResultOpening(!rankingResultOpening);
              GAEvent({
                category: 'Schedules',
                action: 'Opened Accordion',
                label: 'ClassCardDetails',
              });
            }}
          />
        )}

        {(classItem?.allocated?.state === 'assigned' ||
          classItem?.allocated?.state === 'automatic') && (
          <>
            {getSeriationAllocationHeader(classItem?.allocated?.state)}
            <Row>
              <SeriationPlacedResultWrapper>
                <SeriationTriesWrapper>
                  <Accordion
                    structure={[
                      {
                        parent: getAccordionResults(),
                        children: [
                          <React.Fragment key={`classCardDetails_classItem_allocatedDetails`}>
                            {getClassDetails()}
                            {classItem.allocated.previousTries && (
                              <ClassDetailsItem>
                                <Text color="primary" weight="bold">
                                  {t('sgh.ranking')}:
                                </Text>
                                {classItem.allocated.previousTries.map((result, index) => {
                                  return (
                                    <SeriationTriesTextWrapper
                                      key={`classCardDetails_seriationTries_${index}`}
                                    >
                                      <Row>
                                        <SeriationTriesText color={theme.colors.darkGrey}>
                                          <b>{`${index + 1}. `}</b>
                                          {t('sgh.finalSeriationResult', { class: result.name })}.
                                        </SeriationTriesText>
                                      </Row>

                                      <Row>
                                        <NumberSlotsText fontWeight={'light'}>
                                          {result.occupiedSlots}
                                        </NumberSlotsText>
                                        <SeriationTriesText
                                          color={theme.colors.darkGrey}
                                          marginTop={20}
                                        >
                                          ({t('generic.in')} {result.totalSlots})
                                        </SeriationTriesText>
                                      </Row>

                                      <Row>
                                        <SeriationTriesText
                                          color={theme.colors.dangerRed}
                                          marginTop={5}
                                        >
                                          {result.freeSlots}&nbsp;
                                          {t('sgh.studentAvailableSlots', {
                                            count: result.freeSlots,
                                          })}
                                        </SeriationTriesText>
                                      </Row>
                                      <Row>
                                        <SeriationPhaseText
                                          color={theme.colors.primary}
                                          marginTop={5}
                                        >
                                          {t('sgh.phase')} {result.phaseName}
                                        </SeriationPhaseText>
                                      </Row>
                                    </SeriationTriesTextWrapper>
                                  );
                                })}
                              </ClassDetailsItem>
                            )}
                          </React.Fragment>,
                        ],
                      },
                    ]}
                    onOpen={() => {
                      setSeriationPlacedResultOpening(!seriationPlacedResultOpen);
                    }}
                  />
                </SeriationTriesWrapper>
              </SeriationPlacedResultWrapper>
            </Row>
          </>
        )}
      </ContentWrapper>
      <FooterWrapper overlaps={overlaps ? 1 : 0}>
        <FooterDesc overlaps={overlaps ? 1 : 0}>
          <FontAwesomeIcon size="lg" icon={faClone} />
          {overlaps ? t('sgh.withOverlap') : t('sgh.withoutOverlap')}
        </FooterDesc>
      </FooterWrapper>
    </Wrapper>
  );
};

export default ClassCardDetails;

interface IWrapper {
  colorId?: string;
}

const Wrapper = styled.div<IWrapper>`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px #00000029;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    opacity: 1;
  }

  &:after {
    width: 10px;
    background: ${({ colorId, theme }) => (colorId ? colorId : theme.colors.darkGrey)};
    height: 100%;
    content: '';
    position: absolute;
    right: 0;
  }
`;

const ContentWrapper = styled.div<{ paddingBottom?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${({ paddingBottom }) => (paddingBottom ? `14px 18px 18px` : `14px 18px 0`)};
`;

const FooterWrapper = styled.div<{
  overlaps: number;
}>`
  padding: 11px 30px 11px 20px;
  width: 100%;

  ${({ overlaps, theme }) =>
    !overlaps
      ? `background-color: ${theme.colors.softGrey};`
      : `background-color: ${theme.colors.primary};`}
`;

const FooterDesc = styled.div<{
  overlaps: number;
}>`
  font-size: 12px;
  letter-spacing: 0px;

  ${({ overlaps, theme }) =>
    !overlaps ? `color: ${theme.colors.grey};` : `color: ${theme.colors.white};`}

  svg {
    margin-right: 14px;
    ${({ overlaps, theme }) =>
      !overlaps ? `color: ${theme.colors.plusDarkGrey};` : `color: ${theme.colors.white};`}
  }
`;

const Row = styled.div<{ spaceBetween?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ spaceBetween }) => (spaceBetween ? 'space-between' : 'flex-start')};
`;

const RankingRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -10px;
  margin-bottom: 18px;
  justify-content: space-between;
`;

const RankingCol = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
  padding: 5px 10px;
`;

const FullTitle = styled.div``;

const UCID = styled.span`
  font-size: 15px;
  line-height: 18px;
  font-weight: ${({ theme }) => theme.weight.light};
  color: ${({ theme }) => theme.colors.plusDarkGrey};
`;

const UCInitials = styled.span`
  font-size: 15px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.primary};
`;

const ClassName = styled(Text)`
  margin-top: 20px;
`;

const SlotsNumber = styled(Text)`
  margin-top: 6px;
  text-transform: capitalize;
`;

const UCFullName = styled(Text)`
  margin-top: 6px;
`;

const Ranking = styled(Text)`
  line-height: 30px;
  letter-spacing: 0px;
`;

const RankingDesc = styled(Text)`
  line-height: 16px;
  letter-spacing: 0px;
`;

const AccordionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  border: none;
  background: none;
  margin-top: 8px;
  margin-bottom: 8px;

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }

  svg {
    font-size: 24px;
  }
`;

const ClassDetailsItem = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
`;

const ClassDetailsItemSchedule = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-top: 8px;
  }

  > div:last-child {
    margin-bottom: 16px;
  }
`;

const SeriationPlacedResultWrapper = styled.div`
  width: 100%;
`;

const SeriationTriesWrapper = styled.div`
  margin-left: 6px;
`;

const SeriationPlacedResultHeader = styled.div`
  margin-top: 12px;
`;

const SeriationPlacedAutomaticResult = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.light};

  > svg {
    margin-right: 6px;
  }
`;

const SeriationPlacedManualResult = styled.div`
  color: ${({ theme }) => theme.colors.successGreen};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.light};
`;

const SeriationTriesTextWrapper = styled.div``;

const SeriationTriesText = styled.div<{
  color?: string;
  marginTop?: number;
}>`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.weight.light};
  text-align: left;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ color }) => color && `color: ${color};`}
`;

const SeriationPhaseText = styled.div<{
  color?: string;
  marginTop?: number;
}>`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop}px;`}
  ${({ color }) => color && `color: ${color};`}
`;

const NumberSlotsText = styled.div<{ fontWeight?: string }>`
  font-size: 28px;
  text-align: left;
  margin-top: 17px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  ${({ theme, fontWeight }) => fontWeight && `font-weight: ${theme.weight[fontWeight]};`}
`;
