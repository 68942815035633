/**
 * Home scene slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export interface IPhases {
  name: number;
  year: number;
  period: number;
  beginDate: string;
  endDate: string;
  seriationDate: string;
  type: 'queue' | 'manual' | 'automatic' | 'normal';
  finished: boolean;
  final: boolean;
  isActive: boolean;
}

const initialState = {
  phases: [] as IPhases[],
  loadingPhases: true as boolean,
  markdown: [] as Array<{
    value: string;
    valueEn: string;
    valueCh: string;
  }>,
  loadingMarkdown: true as boolean,
};

export default createSlice({
  name: 'Home',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setPhases: (state, action: PayloadAction<IPhases[]>) => {
      state.phases = action.payload;
    },
    setLoadingPhases: (state, action: PayloadAction<boolean>) => {
      state.loadingPhases = action.payload;
    },
    setMarkdown: (state, action: PayloadAction<any>) => {
      state.markdown = action.payload;
    },
    setLoadingMarkdown: (state, action: PayloadAction<boolean>) => {
      state.loadingMarkdown = action.payload;
    },
  },
});
