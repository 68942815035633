/**
 * ExamsCalendar scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDropdownOption } from 'shared/types';

interface IEvent {
  uuid: string;
  id: number;
  title: string;
  desc: string;
  start: Date;
  end: Date;
  icon: string;
  location: string;
}

const initialState = {
  loadingExamsCalendar: true as boolean,
  eventsData: [] as IEvent[],
  currentICS: null as any,
  filterParams: {
    type: '' as string,
    search: '' as string,
  },
  calendarFilterTypes: [] as IDropdownOption[],
  calendarCurrentYearMonth: '' as string,
  initialCalendarDate: '' as string,
  errorStatus: {
    errLoadingCalendarData: false,
  },
};

export default createSlice({
  name: 'ExamsCalendar',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    getExamCalendar: (state, action: PayloadAction<string>) => {},
    getExamCalendarData: (state, action: PayloadAction<string>) => {},
    setLoadingExamsCalendar: (state, action: PayloadAction<boolean>) => {
      state.loadingExamsCalendar = action.payload;
    },
    setEventsData: (state, action: PayloadAction<IEvent[]>) => {
      state.eventsData = action.payload;
    },
    setCurrentICS: (state, action: PayloadAction<any>) => {
      state.currentICS = action.payload;
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    setCalendarFilterTypes: (state, action: PayloadAction<any>) => {
      state.calendarFilterTypes = action.payload.map((filter, key) => {
        return {
          key: key,
          text: filter.translation,
          value: filter.key,
        };
      });
    },
    setCalendarCurrentYearMonth: (state, action: PayloadAction<string>) => {
      state.calendarCurrentYearMonth = action.payload;
    },
    setInitialCalendarDate: (state, action: PayloadAction<string>) => {
      state.initialCalendarDate = action.payload;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setReset: (state) => initialState,
    
  },
});
