import React from 'react';
import styled from 'styled-components';

import { t } from 'app';

import { Caption } from '@paco_ua/pacoui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsH } from '@fortawesome/pro-light-svg-icons';
import { faSmile, faFrown, faMeh } from '@fortawesome/pro-regular-svg-icons';

import { hasProvisorySeriation } from '../../MySchedule/utils';

const CaptionSchedule: React.FC<{ ucs?: any; hideSmiles?: boolean }> = ({ ucs, hideSmiles }) => {
  const captionReferences =
    ucs?.length > 0 && !hideSmiles
      ? [
          { title: t('sgh.legendAllocationProbable'), icon: <FontAwesomeIcon icon={faSmile} /> },
          { title: t('sgh.legendAllocationUncertain'), icon: <FontAwesomeIcon icon={faMeh} /> },
          { title: t('sgh.legendAllocationImprobable'), icon: <FontAwesomeIcon icon={faFrown} /> },
          {
            title: t('sgh.legendExpandSchedule'),
            icon: (
              <ExpandIcon>
                <FontAwesomeIcon size="lg" icon={faArrowsH} />
              </ExpandIcon>
            ),
          },
        ]
      : [
          {
            title: t('sgh.legendExpandSchedule'),
            icon: (
              <ExpandIcon>
                <FontAwesomeIcon size="lg" icon={faArrowsH} />
              </ExpandIcon>
            ),
          },
        ];

  return <Caption references={captionReferences} />;
};

export default CaptionSchedule;

const ExpandIcon = styled.div`
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 13px;
`;
