/**
 * Payments scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';
import { useLocation } from 'react-router-dom';
import { CallToAction, Segment, MultiToggle, Text } from '@paco_ua/pacoui';

import PaymentMethodsInfo from './containers/PaymenMethodsInfo';
import PaymentsTable from './containers/PaymentsTable';
import DebitsTable from './containers/DebitsTable';
import PendingDebitsTable from './containers/PendingDebitsTable';
import MbPaymentInfoModal from './containers/MbPaymentInfoModal';

import PageStructure from 'shared/containers/PageStructure';
import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import Header from 'shared/containers/TitleHeader';

const Payments = ({ t }) => {
  //const theme = useContext(ThemeContext);

  const {
    onMount,
    onUnmount,
    fetchData,
    fetchPayments,
    fetchDebits,
    setSelectedDebits,
    setPreSelectedDebits,
    resetDebitsList,
    setSearchFilter,
    setTypeFilter,
    setIsMbInfoModalOpened,
    setConsultedMbPaymentInfo,
    fetchMbPaymentDetails,
  } = actions.Payments;

  const dispatch = useDispatch();

  const { navigateTo } = actions.App;

  const {
    payments,
    debits,
    loadingStatus,
    errorStatus,
    selectedDebits,
    preSelectedDebits,
    debitsFilterTypes,
    filterParams,
    pendingDebits,
    isMbInfoModalOpened,
    consultedMbPaymentInfo,
  } = useSelector(selectors.getPaymentsSlice);

  const registrationId = useSelector(selectors.getRegistrationId);
  const user = useSelector(selectors.getUser);
  const [initialLoading, setInitialLoading] = useState(true);
  const [changePaymentTab, setChangePaymentTab] = useState(true); // Change payment Tab

  const appSlice = useSelector(selectors.getAppSlice);
  const location = useLocation();

  useEffect(() => {
    setInitialLoading(true);
    dispatch(onMount(location.state));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  useEffect(() => {
    if (registrationId !== null && registrationId !== undefined) {
      dispatch(fetchData());
    }
  }, [registrationId]);

  const [pageErrorDisplayed, setPageErrorDisplayed] = useState(false);

  // present error notification when an error occurs while fetching information
  // for one of the tables
  useEffect(() => {
    if (!pageErrorDisplayed) {
      if (
        errorStatus.errorLoadingDebitsTable ||
        errorStatus.errorLoadingPendingDebitsTable ||
        errorStatus.errorLoadingPaymentsTable
      ) {
        dispatch(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.payments_errorLoadingPage'),
            icon: 'error',
            type: 'danger',
          })
        );
        setPageErrorDisplayed(true);
      }
    }
  }, [errorStatus]);

  // disable initial error after the initial loading is successfull
  useEffect(() => {
    if (
      initialLoading &&
      !(
        loadingStatus.loading ||
        loadingStatus.loadingDebitsTable ||
        loadingStatus.loadingPaymentsTable ||
        loadingStatus.loadingPendingDebitsTable ||
        errorStatus.errorLoadingDebitsTable ||
        errorStatus.errorLoadingPendingDebitsTable ||
        errorStatus.errorLoadingPaymentsTable
      )
    ) {
      setInitialLoading(false);
    }
  }, [errorStatus, loadingStatus]);

  return (
    <PageStructure
      loading={
        loadingStatus.loading ||
        (initialLoading &&
          (loadingStatus.loadingDebitsTable ||
            loadingStatus.loadingPaymentsTable ||
            loadingStatus.loadingPendingDebitsTable)) ||
        errorStatus.errorLoadingDebitsTable ||
        errorStatus.errorLoadingPendingDebitsTable
      }
      headerLeft={<Header title={t('payments.paymentsTitle', { textOnly: true })} />}
      subHeader={<StudentRegistrationDropdown />}
      secondaryContent={<CallToAction topBorder noPointer footer={<PaymentMethodsInfo t={t} />} />}
      mainContent={
        <>
          <MultiToggleWrapper>
            <MultiToggle
              buttons={[
                {
                  onClick: () => {
                    if (!changePaymentTab) setChangePaymentTab(true);
                  },
                  children: t('payments.paymentsTitle', { textOnly: true }),
                  selected: changePaymentTab ? 'ready' : '',
                  testId: '',
                },
                {
                  onClick: () => {
                    if (changePaymentTab) setChangePaymentTab(false);
                  },
                  children: t('payments.historySectionLabel', { textOnly: true }),
                  selected: changePaymentTab ? '' : 'ready',
                  testId: '',
                },
              ]}
              content={'buttons'}
            />
          </MultiToggleWrapper>

          {changePaymentTab ? (
            <>
              {pendingDebits.data.length > 0 ? (
                <Row>
                  <Segment>
                    <TableHeader>
                      <Text size="large" weight="regular">
                        {t('payments.pendingDebitsTableTitle', { textOnly: true })}
                      </Text>
                    </TableHeader>

                    <PendingDebitsTable
                      t={t}
                      data={pendingDebits.data}
                      loading={pendingDebits.loading}
                      openMbInfoModal={(paymentRequestId) => {
                        dispatch(fetchMbPaymentDetails(paymentRequestId));
                      }}
                    />
                  </Segment>
                </Row>
              ) : (
                <></>
              )}
              {isMbInfoModalOpened && (
                <MbPaymentInfoModal
                  onClose={() => {
                    dispatch(setIsMbInfoModalOpened(false));
                    dispatch(setConsultedMbPaymentInfo(null));
                  }}
                  open={isMbInfoModalOpened}
                  mbPaymentDetails={consultedMbPaymentInfo}
                  currentLocale={appSlice.currentLocale}
                  loading={loadingStatus.mbInfoModalLoading}
                />
              )}
              <Row>
                <Segment>
                  <TableHeader>
                    <Text size="large" weight="regular">
                      {t('payments.debitsSectionTitle', { textOnly: true })}
                    </Text>
                  </TableHeader>

                  <DebitsTable
                    t={t}
                    openPendingDebitsPaymentPage={() => {
                      dispatch(resetDebitsList());
                      dispatch(
                        navigateTo({
                          key: 'pendingDebitsPayment',
                        })
                      );
                    }}
                    preSelectedDebits={preSelectedDebits}
                    fetchDebits={(params) => {
                      dispatch(fetchDebits(params));
                    }}
                    setPreSelectedDebits={(debits) => {
                      dispatch(setPreSelectedDebits(debits));
                      dispatch(setSelectedDebits(debits));
                    }}
                    selectedDebits={selectedDebits}
                    addFilters={{
                      searchPlaceholder: t('forms.searchPlaceholder', { textOnly: true }),
                      dropdownPlaceholder: t('paymentTool.multiPaymentFilter', {
                        textOnly: true,
                      }),
                      onSearch: (value) => {
                        dispatch(setSearchFilter(value));
                      },
                      onFilterChange: (value) => {
                        dispatch(setTypeFilter(value));
                      },
                      onFilterClear: (value) => {
                        dispatch(setTypeFilter(value));
                      },
                      filterTypes: debitsFilterTypes,
                    }}
                    filterParams={filterParams}
                    {...debits}
                  />

                  <TableFooter>
                    <TableFooterNoteText size="article" weight="medium">
                      {`${t('generic.note', { textOnly: true })}:`}
                    </TableFooterNoteText>
                    <TableFooterText size="article" weight="regular">
                      {t('payments.pendingDebitsInfo')}
                    </TableFooterText>
                  </TableFooter>
                </Segment>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Segment>
                  <TableHeader>
                    <Text size="large" weight="regular">
                      {t('payments.historySectionLabel', { textOnly: true })}
                    </Text>
                  </TableHeader>

                  <PaymentsTable
                    t={t}
                    fetchPayments={(params) => dispatch(fetchPayments(params))}
                    {...payments}
                  />
                </Segment>
              </Row>
            </>
          )}
        </>
      }
    />
  );
};

export default Payments;

const Row = styled.div<{ paddingtop?: string; paddingbottom?: string }>`
  padding-top: ${({ paddingtop }) => (paddingtop ? paddingtop : '1rem')};
  padding-bottom: ${({ paddingbottom }) => (paddingbottom ? paddingbottom : '1rem')};
`;

const TableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const TableFooter = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

/*const WrapperColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperPayments = styled.div<{ noMargin?: boolean }>`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '16px')};

  ${WrapperColumn} {
    > div:first-child {
      padding-bottom: 8px;
    }
  }
`;*/

const TableFooterText = styled(Text)`
  margin-top: 8px;
  line-height: 1.3;
`;

const TableFooterNoteText = styled(Text)``;

const MultiToggleWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
`;
