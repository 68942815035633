/**
 * SchedulesSystemHelp container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState } from 'react';

import { useSelector } from 'react-redux';
import * as selectors from './selectors';

import styled, { ThemeContext } from 'styled-components';

import { useForm } from 'react-hook-form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { Modal, Text, Button, Checkbox } from '@paco_ua/pacoui';

import { setStorage, getStorage } from 'utils';

import { t } from 'app';

//import config from 'config'

const SchedulesSystemHelp = ({ shouldHide }) => {
  const ucs = useSelector(selectors.getUcs);

  const [storeHasPreSelections, setStoreHasPreSelections] = useState(null as null | boolean);

  // Local storage store values as strings
  // we're converting it to boolean for easier logical comparisons
  const showSchedulesSystemHelp =
    getStorage('showSchedulesSystemHelp') === null ? true : getStorage('showSchedulesSystemHelp');

  // The system help is, mainly, to explain why the student has some selections by default
  // So, we only should show it if there's at least one 'pre-selection'
  const hasPreSelections = () => {
    // Find from the previous array if there's, at least, 1 'preselected
    if (storeHasPreSelections !== null) {
      return storeHasPreSelections;
    }

    if (ucs.some((uc) => uc.classSchedule.some((eachClass) => eachClass.selected) === true)) {
      setStoreHasPreSelections(true);
      return true;
    } else {
      setStoreHasPreSelections(false);
      return false;
    }
  };

  // Hooks
  const theme = useContext(ThemeContext);
  const [showModal, setShowingModal] = useState(true);
  const { register, handleSubmit } = useForm();

  // Handlers
  const handleOnSubmit = handleSubmit(({ dontShowAgain }) => {
    if (dontShowAgain) {
      setStorage('showSchedulesSystemHelp', false);
    }
    handleOnClose();
  });

  const handleOnClose = () => {
    setShowingModal(false);
  };

  const getHeader = () => {
    return (
      <HelpHeader>
        <HelpHeaderIcon>
          <FontAwesomeIcon icon={faInfoCircle} />
        </HelpHeaderIcon>
        <Text size={'xLarge'} color={theme.colors.plusDarkGrey} weight={'medium'}>
          {t('sgh.systemHelpHeader')}
        </Text>
      </HelpHeader>
    );
  };

  const hide =
    shouldHide ||
    !showSchedulesSystemHelp ||
    !hasPreSelections() ||
    (showSchedulesSystemHelp && !showModal);

  return (
    <Wrapper>
      <Modal open={!hide} type={'content'} header={getHeader()} onClose={() => handleOnClose()}>
        <HelpBody>
          <Text size={'article'} color={theme.colors.plusDarkGrey} weight={'light'}>
            {t('sgh.systemHelpBody')}
          </Text>

          <HelpBodyCheckboxAndButton>
            <HelpBodyCheckbox>
              <Checkbox {...register('dontShowAgain')} value={true}>
                {t('generic.dontShowAnymore', { textOnly: true })}
              </Checkbox>
            </HelpBodyCheckbox>
            <HelpBodyButton>
              <Button action onClick={handleOnSubmit}>
                {t('generic.enter')}
              </Button>
            </HelpBodyButton>
          </HelpBodyCheckboxAndButton>
        </HelpBody>
      </Modal>
    </Wrapper>
  );
};

export default SchedulesSystemHelp;

const Wrapper = styled.div``;

const HelpHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 28px;
`;

const HelpHeaderIcon = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 60px;
  margin-bottom: 40px;
`;

const HelpBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  white-space: pre-line;
`;

const HelpBodyCheckboxAndButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HelpBodyCheckbox = styled.div`
  margin-top: 42px;
`;

const HelpBodyButton = styled.div`
  margin-top: 42px;
`;
