enum CommunicationTypes {
  STORE_TOASTER_ACTION = 'toaster',
  STORE_NAVIGATE_ACTION = 'navigate',
  STORE_LOCALE_ACTION = 'currentLocale',
  STORE_EXAMPLE_ACTION = 'example',
  STORE_OTHER_ACTION = 'other',
  IFRAME_HEIGHT = 'height',
  CHANGE_LANG = 'lang',
  LOGOUT = 'logout'
}

export { CommunicationTypes };
