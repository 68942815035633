import axios from 'axios';

import Endpoints from 'api/endpoints';

const getTranslations = () => {
  return axios
    .get(Endpoints.st.translations)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getMarkdown = () => {
  return axios
    .get(Endpoints.st.markdown)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export default {
  getTranslations: {
    call: getTranslations,
  },
  getMarkdown: {
    call: getMarkdown,
  },
};
