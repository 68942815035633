/**
 * CurricularUnitDetails sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { actions } from 'store/rootSlices';

import { PayloadAction } from '@reduxjs/toolkit';

import { call, takeLatest, put } from 'redux-saga/effects';
import { t } from 'app';

import API from 'api';
import ErrorHandler from 'shared/errorHandler';
import { CURRICULAR_PLAN_ERROR_MESSAGE } from 'shared/errorMessages';

function* onMountSaga() {}

function* getUcDetailsSaga(
  action: PayloadAction<{ selectedUcCode: number; registrationId: number }>
) {
  try {
    yield put(actions.CurricularUnitDetails.setLoading(true));

    const ucDetails = yield call(
      API.secVirtual.getCurricularUnitDetails.call,
      action.payload.selectedUcCode,
      action.payload.registrationId
    );

    yield put(actions.CurricularUnitDetails.setUcDetails(ucDetails));

    yield put(actions.CurricularUnitDetails.setLoading(false));
  } catch (e) {
    yield put(
      actions.CurricularUnitDetails.setErrorStatus({
        fieldName: 'errLoadingDetails',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      if (
        e.response.data.error_code === CURRICULAR_PLAN_ERROR_MESSAGE.ERR_REGISTRATION_INVALID_UC
      ) {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.curricularPlan_errorInvalidUc'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.curricularUnitDetails_errorLoadingPage', {
              textOnly: true,
            }),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  } finally {
    yield put(actions.CurricularUnitDetails.setLoading(false));
  }
}

function* onUnmountSaga() {
  yield put(actions.CurricularUnitDetails.reset());
}

export default function* watcherSignin() {
  yield takeLatest('CurricularUnitDetails/onMount', onMountSaga);
  yield takeLatest('CurricularUnitDetails/getUcDetails', getUcDetailsSaga);
  yield takeLatest('CurricularUnitDetails/onUnmount', onUnmountSaga);
}
