/**
 * NewCertificateSubmission container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import ReactMarkdown from 'react-markdown';

import numeral from 'numeral';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

import { Grid } from 'semantic-ui-react';
import { AnimatedBackground, Text, Segment, Table } from '@paco_ua/pacoui';
import FieldsGroupDisplay from 'shared/components/FieldsGroupDisplay';
import { getSubmissionTabData } from './utils';

import FileDownloader from 'shared/components/FileDownloader';

import SelectedPayments from 'scenes/VirtualSecretary/Payments/PendingDebitsPayment/components/SelectedPayments';

import { IDebitData } from 'scenes/VirtualSecretary/Payments/types';

import API from 'api';

const NewApplicationSubmission = ({ formElementsGetValues }) => {
  const theme = useContext(ThemeContext);

  const user = useSelector(selectors.getUser);
  const newCertificateData = useSelector(selectors.getNewCertificateData);
  const newCertificateSlice = useSelector(selectors.getNewCertificateSlice);

  const { showToaster } = actions.Toaster;

  const registration =
    user.registrations &&
    user.registrations.find(
      (registration) => registration.registrationId === newCertificateData.registrationId
    );

  const { form /*, formValues*/ } = newCertificateData;

  const {
    selectedCertificatePreviewUrl,
    selectedCertificateType,
    loadingStatus,
    //errorStatus,
  } = newCertificateSlice;

  const submissionTabData = getSubmissionTabData(form, formElementsGetValues());

  const dispatch = useDispatch();

  const [loadingCertificateModel, setLoadingCertificateModel] = useState(false);

  const getUcsListNameKey = () => {
    let ucsTableNameKey = '';

    Object.keys(submissionTabData).forEach((nameKey) => {
      if (nameKey.includes('0/1')) {
        ucsTableNameKey = nameKey;
      }
    });

    return ucsTableNameKey;
  };

  const findFormDataIndex = (nameKey) => {
    if (newCertificateData.form) {
      return newCertificateData.form.requestedCertificate.values.findIndex(
        (item) => item.nameKey === nameKey
      );
    }

    return null;
  };

  const studentName = form ? form.studentName : user.name;

  const mockCertificatePaymentData = [
    {
      associatedCosts: null,
      coin: 'EUR',
      debitAcademicYear: -1,
      debitDate: null,
      debitId: -1,
      debitOriginId: -1,
      debitStatus: 'unpaid',
      debitType: newCertificateSlice.selectedCertificateType.theme
        ? newCertificateSlice.selectedCertificateType.theme
        : '',
      description: newCertificateSlice.selectedCertificateType.name
        ? newCertificateSlice.selectedCertificateType.name
        : '',
      dueDate: null,
      totalValue: newCertificateSlice.certificateCosts.totalCost,
      value: newCertificateSlice.certificateCosts.totalCost,
    },
  ] as IDebitData[];

  if (loadingStatus.tabLoading) {
    return (
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
        <Segment>
          <LoadingWrapper>
            <AnimatedBackground height={theme.sizes.xxLarge} width="80%" />
          </LoadingWrapper>
        </Segment>
      </Grid.Column>
    );
  } else {
    return (
      <>
        <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
          <Segment>
            <SegmentTitleWrapper>
              <TitleWrapper>
                <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                  {t('certificates.requestFourthStepTitle')}
                </Text>
                <DescriptionText size="article" color={'darkGrey'} weight={'regular'}>
                  {t('certificates.requestFourthStepDescription')}
                </DescriptionText>
              </TitleWrapper>
            </SegmentTitleWrapper>
            <CertificateData>
              <CertificateDataNameAndCourse>
                <CertificateDataBlock>
                  <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
                    {t('certificates.requestFourthStepName')}
                  </Text>
                  <FourthStepText
                    size="mediumSmall"
                    color={theme.colors.plusDarkGrey}
                    weight={'regular'}
                  >
                    {studentName}
                  </FourthStepText>
                </CertificateDataBlock>
                <CertificateDataBlock>
                  <Text size="xSmall" color={'grey'} weight={'regular'}>
                    {t('certificates.requestFourthStepCourse')}
                  </Text>
                  <CourseText size="mediumSmall" color={'plusDarkGrey'} weight={'regular'}>
                    {registration && `${registration.courseCode} - ${registration.courseName}`}
                  </CourseText>
                </CertificateDataBlock>
              </CertificateDataNameAndCourse>
              <CertificateDataCertificateType>
                <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                  {t('certificates.requestedCertificate')}
                </Text>
                <CertificateSegment>
                  <Segment padding={'17px'}>
                    <SegmentContent>
                      <SegmentDescription>
                        <Text size="mediumSmall" color={'plusDarkGrey'} weight={'regular'}>
                          {selectedCertificateType?.name}
                        </Text>
                        <SegmentSubtitleText size="xSmall" color={'grey'} weight={'regular'}>
                          {selectedCertificateType?.description}
                        </SegmentSubtitleText>
                      </SegmentDescription>
                      {selectedCertificatePreviewUrl !== '' && (
                        <ModelWrapper>
                          <Text
                            size="xSmall"
                            loading={loadingCertificateModel}
                            loadingProps={{ width: '96px' }}
                            color={'plusDarkGrey'}
                            weight={'regular'}
                            as={'a'}
                            onClick={() => {
                              if (
                                !loadingCertificateModel &&
                                selectedCertificatePreviewUrl !== ''
                              ) {
                                setLoadingCertificateModel(true);
                                try {
                                  API.files.getFileOnTab
                                    .call(selectedCertificatePreviewUrl)
                                    .then((value) => {
                                      setLoadingCertificateModel(false);
                                    })
                                    .catch((err) => {
                                      setLoadingCertificateModel(false);
                                      dispatch(
                                        showToaster({
                                          title: t(
                                            'secVirtualNotifications.general_errorLoadingFile'
                                          ),
                                          icon: 'error',
                                          type: 'danger',
                                        })
                                      );
                                    });
                                } catch (e) {
                                  setLoadingCertificateModel(false);
                                  dispatch(
                                    actions.Toaster.showToaster({
                                      title: t('secVirtualNotifications.general_errorLoadingFile'),
                                      icon: 'error',
                                      type: 'danger',
                                    })
                                  );
                                }
                              }
                            }}
                          >
                            {t('certificates.visualizeModel')}
                          </Text>
                        </ModelWrapper>
                      )}
                    </SegmentContent>
                  </Segment>
                </CertificateSegment>
                {submissionTabData.schoolYear && (
                  <CertificateSegment>
                    <RequestedYearWrapper>
                      <Text weight={'bold'} size={'mediumSmall'} color={'primary'}>
                        {t(`certificates.requestedAcademicYear`, { textOnly: true })}
                      </Text>
                      <Text weight={'regular'} size={'small'} color={'plusDarkGrey'}>
                        {submissionTabData.schoolYear}
                      </Text>
                    </RequestedYearWrapper>
                  </CertificateSegment>
                )}
                {submissionTabData.exemptionMotive && (
                  <CertificateSegment>
                    <RequestedYearWrapper>
                      <Text weight={'bold'} size={'mediumSmall'} color={'primary'}>
                        {t(`certificates.requestedExemptionMotive`, { textOnly: true })}
                      </Text>
                      <Text weight={'regular'} size={'small'} color={'plusDarkGrey'}>
                        {submissionTabData.exemptionMotive}
                      </Text>
                    </RequestedYearWrapper>
                  </CertificateSegment>
                )}
                {formElementsGetValues() &&
                  Object.keys(formElementsGetValues()).filter(
                    (elementKey) =>
                      formElementsGetValues()[elementKey] !== null &&
                      formElementsGetValues()[elementKey] !== undefined &&
                      formElementsGetValues()[elementKey].fileName !== null &&
                      formElementsGetValues()[elementKey].fileName !== undefined
                  ).length > 0 && (
                    <CertificateAdditionalDocsWrapper>
                      <CertificateAdditionalDocuments>
                        <CertificateAdditionalDocumentsTitle
                          size="medium"
                          color={theme.colors.plusDarkGrey}
                          fontWeight="500"
                        >
                          {t('applications.requestFourthStepAdditionalFilesTitle')}
                        </CertificateAdditionalDocumentsTitle>
                        {formElementsGetValues() &&
                          Object.keys(formElementsGetValues())
                            .filter(
                              (elementKey) =>
                                formElementsGetValues()[elementKey] !== null &&
                                formElementsGetValues()[elementKey] !== undefined &&
                                formElementsGetValues()[elementKey].fileName !== null &&
                                formElementsGetValues()[elementKey].fileName !== undefined
                            )
                            .map((documentKey, index) => (
                              <FileDownloaderWrapper
                                key={`applicationAdditinalDocumentDownloader-${index}`}
                              >
                                <FileDownloader
                                  heading={
                                    formElementsGetValues()[documentKey].nameKey.includes(
                                      'otherDocuments'
                                    )
                                      ? t('applications.otherDocuments', { textOnly: true })
                                      : formElementsGetValues()[documentKey].nameKey
                                  }
                                  description={formElementsGetValues()[documentKey].fileName}
                                  url={formElementsGetValues()[documentKey].url}
                                  icon={<FontAwesomeIcon icon={faFilePdf} />}
                                  callToEndpoint={(endpoint, setLoading: (value) => void) => {
                                    setLoading(true);

                                    API.files.getFileOnTab
                                      .call(endpoint)
                                      .then((value) => {
                                        setLoading(false);
                                      })
                                      .catch((e) => {
                                        setLoading(false);
                                        dispatch(
                                          actions.Toaster.showToaster({
                                            title: t(
                                              'secVirtualNotifications.general_errorLoadingFile'
                                            ),
                                            icon: 'error',
                                            type: 'danger',
                                          })
                                        );
                                      });
                                  }}
                                />
                              </FileDownloaderWrapper>
                            ))}
                      </CertificateAdditionalDocuments>
                    </CertificateAdditionalDocsWrapper>
                  )}
                <Row paddingtop={'20px'} paddingbottom={'0px'}>
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={16}
                    largeScreen={14}
                    widescreen={12}
                  >
                    <Segment>
                      <TitleWrapper>
                        <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                          {t('certificates.languageFormatsTitle')}
                        </Text>
                      </TitleWrapper>

                      {submissionTabData && submissionTabData.language && (
                        <LanguageWrapper>
                          <Text size="mediumSmall" color={'primary'} weight={'regular'}>
                            {t('certificates.requestedLanguage')}
                          </Text>
                          <Text color={'plusDarkGrey'} weight={'regular'} size={'small'}>
                            {submissionTabData.language.valueNameKey}
                          </Text>
                        </LanguageWrapper>
                      )}

                      {submissionTabData && submissionTabData.format && (
                        <FormatWrapper>
                          <Text size="mediumSmall" color={'primary'} weight={'regular'}>
                            {t('certificates.detailsFormat')}
                          </Text>
                          <Text color={'plusDarkGrey'} weight={'regular'} size={'small'}>
                            {submissionTabData.format.valueNameKey}
                          </Text>
                          {submissionTabData.format.description && (
                            <DescriptionWrapper>
                              <Text color={'darkGrey'} size={'xSmall'}>
                                <ReactMarkdown
                                  key={`markdown-requested-format`}
                                  children={submissionTabData.format.description}
                                  renderers={{ paragraph: 'span' }}
                                />
                              </Text>
                            </DescriptionWrapper>
                          )}
                        </FormatWrapper>
                      )}
                      {submissionTabData && submissionTabData.email && (
                        <>
                          <LineSeparator />
                          <FieldsGroupDisplay
                            t={t}
                            disableEdit
                            name="email"
                            padding={'5px'}
                            title={t(`certificates.email`, {
                              textOnly: true,
                            })}
                            values={submissionTabData.email}
                            labels={(nameKeys) => {
                              return nameKeys.reduce((acc, namekey) => {
                                return {
                                  ...acc,
                                  [namekey]: t(`certificates.${namekey}`, {
                                    textOnly: true,
                                  }),
                                };
                              }, {});
                            }}
                          />
                        </>
                      )}

                      {submissionTabData && submissionTabData.address && (
                        <>
                          <LineSeparator />
                          <FieldsGroupDisplay
                            t={t}
                            name="address"
                            disableEdit
                            padding={'5px'}
                            title={t(`certificates.address`, {
                              textOnly: true,
                            })}
                            values={submissionTabData.address}
                            labels={(nameKeys) => {
                              return nameKeys.reduce((acc, namekey) => {
                                return {
                                  ...acc,
                                  [namekey]: t(`certificates.${namekey}`, {
                                    textOnly: true,
                                  }),
                                };
                              }, {});
                            }}
                          />
                        </>
                      )}
                    </Segment>
                  </Grid.Column>
                </Row>
                {submissionTabData && submissionTabData[getUcsListNameKey()] && (
                  <>
                    <RequestedCurricularYearWrapper>
                      {newCertificateData.form && (
                        <Table
                          unstackable
                          disabledAccordion={true}
                          structure={{
                            header: {
                              type: 'columnTitle',
                              titles: Object.keys(
                                newCertificateData.form.requestedCertificate.values[
                                  findFormDataIndex(getUcsListNameKey())
                                ].values[0].nameKey
                              )
                                .filter(
                                  (col) =>
                                    col !== 'isAvailable' &&
                                    col !== 'genericBlock' &&
                                    col !== 'isAvailableEn'
                                )
                                .map((field) => {
                                  return {
                                    text: t(`certificates.${field}`, { textOnly: true }),
                                    style:
                                      field !== 'ucName'
                                        ? { textAlign: 'center', fontWeight: '400' }
                                        : { textAlign: 'left', fontWeight: '400' },
                                  };
                                }),
                            },

                            rows: submissionTabData[getUcsListNameKey()].map((row) => {
                              return {
                                collapsableOpen: true,
                                rowProps: { color: theme.colors.white },
                                cells: Object.keys(row)
                                  .filter(
                                    (key) =>
                                      key !== 'isAvailable' &&
                                      key !== 'genericBlock' &&
                                      key !== 'isAvailableEn'
                                  )
                                  .map((key) => {
                                    if (key === 'ucCode') {
                                      return {
                                        content: (
                                          <Text
                                            size={'article'}
                                            fontWeight={'500'}
                                            color={'plusDarkGrey'}
                                          >
                                            {row[key].toString()}
                                          </Text>
                                        ),
                                        cellProps: { textAlign: 'center' },
                                      };
                                    } else if (key === 'ucName') {
                                      return {
                                        content: (
                                          <>
                                            <Text
                                              size={'article'}
                                              fontWeight={'500'}
                                              color={'plusDarkGrey'}
                                            >
                                              {row[key].toString()}
                                            </Text>
                                            {row['isAvailable'] === false && (
                                              <WarningContainer>
                                                <div>
                                                  <Text weight="bold">
                                                    {t(`certificates.reqNeedWarning`)}
                                                    <FontAwesomeIcon
                                                      size="lg"
                                                      color={theme.colors.decisionYellow}
                                                      icon={faExclamationTriangle}
                                                    />
                                                  </Text>
                                                  <Text size="small">
                                                    {t(`certificates.reqNeedMessage`)}
                                                  </Text>
                                                </div>
                                              </WarningContainer>
                                            )}
                                          </>
                                        ),
                                        cellProps: {},
                                      };
                                    } else if (key === 'semester') {
                                      if (row[key] === 0) {
                                        return {
                                          content: (
                                            <Text
                                              size={'article'}
                                              weight={'regular'}
                                              color={'plusDarkGrey'}
                                            >
                                              {t('certificates.anual', { textOnly: true })}
                                            </Text>
                                          ),
                                          cellProps: { textAlign: 'center' },
                                        };
                                      } else if (row[key] === -1) {
                                        return <></>;
                                      } else {
                                        return {
                                          content: (
                                            <Text
                                              size={'article'}
                                              weight={'regular'}
                                              color={'plusDarkGrey'}
                                            >
                                              {`${numeral(row[key]).format(
                                                'o'
                                              )} ${t('certificates.semester', { textOnly: true })}`}
                                            </Text>
                                          ),
                                          cellProps: { textAlign: 'center' },
                                        };
                                      }
                                    } else if (key === 'isAvailable') {
                                      return {
                                        content: (
                                          <Text
                                            size={'article'}
                                            weight={'regular'}
                                            color={'plusDarkGrey'}
                                          >
                                            {row[key]
                                              ? t('certificates.certIsAvailable', {
                                                  textOnly: true,
                                                })
                                              : t('certificates.certIsNotAvailable', {
                                                  textOnly: true,
                                                })}
                                          </Text>
                                        ),
                                        cellProps: { textAlign: 'center' },
                                      };
                                    }

                                    return {
                                      content: (
                                        <Text
                                          size={'article'}
                                          weight={'regular'}
                                          color={'plusDarkGrey'}
                                        >
                                          {row[key]}
                                        </Text>
                                      ),
                                      cellProps: { textAlign: 'center' },
                                    };
                                  }),
                              };
                            }),
                          }}
                        />
                      )}
                    </RequestedCurricularYearWrapper>
                  </>
                )}
              </CertificateDataCertificateType>
            </CertificateData>
            <Row paddingtop={'20px'} paddingbottom={'0px'}>
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
                <Segment>
                  <TitleWrapper>
                    <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                      {t('certificates.paymentSummary')}
                    </Text>
                  </TitleWrapper>

                  {<SelectedPayments selectedDebits={mockCertificatePaymentData} />}
                </Segment>
              </Grid.Column>
            </Row>
          </Segment>
        </Grid.Column>

        {/*formValues && formValues.otherDocument && (
          <Row paddingtop={'20px'} paddingbottom={'0px'}>
            <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
              <Segment>
                <TitleWrapper>
                  <Text size="medium" color={'plusDarkGrey'} weight={'regular'}>
                    {t('certificates.visualizeCertificate')}
                  </Text>
                </TitleWrapper>
                <PdfReader
                  height="800px"
                  document={{
                    url: formValues.otherDocument.url,
                    name:
                      'Certidões - De carga horária e conteúdo programático - Uma só disciplina (Preview)', //formValues.otherDocument.fileName,
                  }}
                />
              </Segment>
            </Grid.Column>
          </Row>
                )*/}
      </>
    );
  }
};

export default NewApplicationSubmission;

const Row = styled.div<{ paddingtop?: string; paddingbottom?: string }>`
  padding-top: ${({ paddingtop }) => (paddingtop ? paddingtop : '1rem')};
  padding-bottom: ${({ paddingbottom }) => (paddingbottom ? paddingbottom : '1rem')};
`;

const LanguageWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  div:nth-child(2) {
    margin-top: 15px;
  }
`;

const DescriptionWrapper = styled.div`
  && a {
    color: ${({ theme }) => theme.colors.primary};

    :hover {
      text-decoration: underline;
    }
  }
`;

const FormatWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;

  div:nth-child(2) {
    margin-top: 15px;
  }
  div:nth-child(3) {
    margin-top: 12px;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
`;

const CertificateData = styled.div`
  margin-top: 56px;
`;

const CertificateDataNameAndCourse = styled.div`
  display: flex;

  & > div {
    flex: 0 50%;
  }
`;

const CertificateSegment = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 17px;
`;

const CertificateDataCertificateType = styled.div`
  margin-top: 50px;
`;

const CertificateDataBlock = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    margin-top: 12px;
  }
`;

const SegmentContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SegmentDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const ModelWrapper = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const LineSeparator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.lightGrey};
  margin: 16px 0px;
`;

const RequestedCurricularYearWrapper = styled.div`
  margin-top: 24px;
  overflow: auto;
`;

const RequestedYearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  div:first-child {
    margin-bottom: 15px;
  }
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DescriptionText = styled(Text)`
  margin-top: 8px;
`;

const SegmentSubtitleText = styled(Text)`
  margin-top: 8px;
`;

const CourseText = styled(Text)`
  line-height: 1.3;
`;

const FourthStepText = styled(Text)`
  margin-top: 12px;
`;

const CertificateAdditionalDocsWrapper = styled.div``;

const FileDownloaderWrapper = styled.div`
  margin-top: 12px;
`;

const CertificateAdditionalDocumentsTitle = styled(Text)`
  margin-bottom: 12px;
`;

const CertificateAdditionalDocuments = styled.div`
  margin-top: 40px;
`;

const WarningContainer = styled.div`
  display: flex;
  height: 100%;

  >div: first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    height: auto;
    font-size: 13px;

    svg {
      padding-left: 8px;
    }

    span {
      font-size: 0.75rem !important;
    }
  }
`;
