import React, { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-light-svg-icons';

import styled from 'styled-components';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
import format from 'date-fns/format';
import * as selectors from './logic/selectors';
import { useSelector } from 'react-redux';


const CalendarIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`;

const ExamDatePicker = ({ selectedDate, onDateChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentLocale = useSelector(selectors.getCurrentLocale);
  registerLocale('pt', pt);


  const isSelectableDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Normalize today's date by removing time

    return date.getDay() !== 0 && date <= today; // Disable Sundays and future dates
  };

  const stringToDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(year, month-1, day);
  };

  
  return (
    <>
      <DatePickerContainer>
        <CalendarIcon icon={faCalendarAlt} size={'lg'} onClick={() => setIsOpen(!isOpen)} />
        {isOpen && (
          <Wrapper>
            <ReactDatePicker
              inline
              locale={currentLocale === "pt" ? "pt" : "en"}
              selected={stringToDate(selectedDate)}
              onChange={(date) => {
                const formattedDate = format(date, "dd-MM-yyyy");
                onDateChange(formattedDate);
                setIsOpen(false);
              }}
              filterDate={isSelectableDate}
            />
          </Wrapper>
          )}
        </DatePickerContainer>
    </>
  );
};

export default ExamDatePicker;

const DatePickerContainer = styled.div`
  position: relative;
`;


const Wrapper = styled.div`
      position: absolute;
      z-index: 111;
      transform: translateY(-1.5rem) translateX(2rem);


  > * {
    .react-datepicker-popper {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      overflow: hidden;
    }

    .react-datepicker__input-container {
      display: unset;
    }

    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
      margin: 2px;
      width: 32px;
      line-height: 32px;
      text-align: center;
      border-radius: 50%;
      font-size: 16px;
      color: #333;
      font-weight: 500;

      &:hover {
        background-color: #f0f0f0;
      }
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-range,
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end {
      background-color: #0eb4bd;
      color: white;
      font-weight: bold;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: #bad9f1;
    }

    .react-datepicker__day--outside-month {
      color: #ccc;
    }

    .react-datepicker__header {
      background-color: white;
      border-bottom: 1px solid #f0f0f0;
      padding-top: 8px;
      position: relative;

      .react-datepicker__current-month,
      .react-datepicker-time__header,
      .react-datepicker-year-header {
        color: #333;
        font-weight: bold;
        font-size: 18px;
      }

      .react-datepicker__navigation {
        top: 20px;
        color: #333;
        cursor: pointer;
        position: absolute;
        &.react-datepicker__navigation--previous {
          left: 20px;
        }
        &.react-datepicker__navigation--next {
          right: 20px;
        }
      }
    }

    .react-datepicker__day--disabled {
      color: #ccc;
      cursor: not-allowed;
    }


  }
`;