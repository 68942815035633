import React from 'react';
import styled from 'styled-components';
import Header from 'shared/containers/TitleHeader';
import { t } from 'app';
import * as selectors from './logic/selectors';
import { useSelector } from 'react-redux';

interface IProps {
  codDisciplina: string;
  nomeDisciplina: string;
  nomeDisciplinaEng: string;
  tipoExame: string;
  epocaExame: number;
  anoLectivo: number;
  codPauta: string;
  onBack?: () => void;
  children?: React.ReactNode;
}

const GradeTitle: React.FC<IProps> = ({
  codDisciplina,
  nomeDisciplina,
  nomeDisciplinaEng,
  tipoExame,
  epocaExame,
  anoLectivo,
  codPauta,
  onBack,
  children
}) => {

  const currentLocale = useSelector(selectors.getCurrentLocale);

  return (
    <GradeTitleWrapper>
      <GradeContainer>
        <Header backButton title={currentLocale === 'en' && nomeDisciplinaEng ? nomeDisciplinaEng.toLocaleUpperCase() : nomeDisciplina.toLocaleUpperCase()} onBack={onBack}/>
        <DisciplineCode>{codDisciplina}</DisciplineCode>
        <ExameType>
          {tipoExame}
        </ExameType>
      </GradeContainer>
      <ExameInfo>
        <YearSemesterCode>
        {anoLectivo}/{parseInt(anoLectivo.toString().slice(-2)) + 1} - {epocaExame && epocaExameMap[epocaExame]
              ? t(`pautas.${epocaExameMap[epocaExame]}`)
              : 'Época de Exame Desconhecido'} - {tipoExame && tipoExameMap[tipoExame]
                ? t(`pautas.${tipoExameMap[tipoExame]}`)
                : 'Tipo de Exame Desconhecido'} - {codPauta}
        </YearSemesterCode>
      </ExameInfo>
      {children}
    </GradeTitleWrapper>
  );
};

export default GradeTitle;

export const tipoExameMap: { [key: string]: string } = {
  NM: 'normalExam',
  RE: 'appealExam',
  ML: 'improvementExam',
  DZ: 'specialExam',
  SV: 'summerSemesterExam',
  SE: 'extraordinarySemesterExam',
  ES: 'supplementaryExam',
};

export const epocaExameMap: { [key: string]: string } = {
  1: 'firstSemester',
  2: 'secondSemester',
  3: 'appealPeriod',
  4: 'extraordinaryPeriod',
  5: 'specialPeriod',
  6: 'annualPeriod',
};

const GradeContainer = styled.div`
  display: flex;
`;

const DisciplineCode = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 1.5;
  color: #292929;
  padding-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
`;

const ExameInfo = styled.div`
  margin-top: 0.3rem;
  display: flex;
`;

const ExameType = styled.div`
  font-weight: 300;
  line-height: 1.5;
  color: #292929;
  font-size: 18px;
  padding-top: 10px;
  margin-right: 3px;
`;

const YearSemesterCode = styled.div`
  font-weight: 300;
  line-height: 1.5;
  color: #757575;
  font-size: 18px;
  margin-right: 5px;
`;

const GradeTitleWrapper = styled.div`
  margin-bottom: 1rem;
`;
