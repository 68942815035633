/**
 * Payments scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import _ from 'lodash';
import {
  IPayments,
  IDebits,
  IPendingDebitsEntry,
  IDebitData,
  IPendingDebits,
  ICallParams,
} from './types';

export const DEFAULT_PAYMENTS: IPayments = {
  data: [],
  loading: true,
  total: 0,
};

export const DEFAULT_DEBITS: IDebits = {
  data: [],
  loading: true,
  total: 0,
};

export const DEFAULT_PENDING_DEBITS: IPendingDebits = {
  data: [] as IPendingDebitsEntry[],
  loading: true,
};

export const DEFAULT_PARAMS: ICallParams = {
  offset: 0,
  limit: 5,
};

export const DEFAULT_DEBITS_PARAMS: ICallParams = {
  offset: 0,
  limit: 20,
};

export const DEFAULT_UNSELECTED_DEBITS_PARAMS: ICallParams = {
  offset: 0,
  limit: 5,
};

export const removeDebits = (debits: IDebitData[], multiples: IDebitData[]) => {
  const newDebits: IDebitData[] = [];

  debits.forEach((debit: IDebitData) => {
    if (!_.find(multiples, { debitId: debit.debitId })) {
      newDebits.push(debit);
    }
  });

  return newDebits;
};

export const getPaymentInfo = (selectedDebits) => {
  return selectedDebits.map((debit) => ({
    id: debit.debitId,
    value: debit.value,
    associatedCosts: debit.associatedCosts,
  }));
};
