/**
 * AppTools Container
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { withTranslation, WithTranslation } from 'react-i18next';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

interface IProps extends WithTranslation {
  roles?: object;
  translationMode?: boolean;
}

const AppTools: React.FC<IProps> = ({ i18n }) => {
  const { user, translationsEdit } = useSelector(selectors.getAppSlice);
  const roles = useSelector(selectors.getRoles);
  const { updateUserRole, impersonateUser, changeLocale, toggleApiTranslationsEdit } = actions.App;

  const dispatch = useDispatch();

  const [openTools, setOpenTools] = useState(false);
  const [impersonate, setImpersonate] = useState('');

  return (
    <Wrapper openTools={openTools}>
      <CloseButton onClick={() => setOpenTools(!openTools)}>
        <span>Tools</span>
        <Icon openTools={openTools}>{'>'}</Icon>
      </CloseButton>
      <Tools>
        <div>
          <span>Impersonate User</span>
          <div>
            <input
              value={impersonate}
              onChange={({ target }) => {
                setImpersonate(target.value);
              }}
              placeholder="NMEC"
            />
            <button
              onClick={() => {
                dispatch(impersonateUser(impersonate));
              }}
            >
              OK
            </button>
          </div>
        </div>
        {/*<div>
          <span>Users</span>
          <Switcher
            onChange={(event: React.FormEvent<HTMLSelectElement>) => {
              dispatch(updateUserRole(event.currentTarget.value));
            }}
            defaultValue={user.role}
          >
            <option disabled value={``}>
              Switch..
            </option>
            {Object.keys(roles).map((role, key) => (
              <option key={`roles_${role}_${key}`} value={role}>
                {role}
              </option>
            ))}
          </Switcher>
            </div>*/}
        <div>
          <span>View translations</span>
          <button onClick={() => dispatch(toggleApiTranslationsEdit())}>
            {translationsEdit ? 'ON' : 'OFF'}
          </button>
        </div>

        <div>
          <span>Language</span>
          <Switcher
            onChange={(event: React.FormEvent<HTMLSelectElement>) => {
              dispatch(changeLocale(event.currentTarget.value));
            }}
            value={i18n.language}
          >
            <option disabled value={``}>
              Switch..
            </option>
            {i18n.options.resources &&
              Object.keys(i18n.options.resources).map((key) => (
                <option key={`translations_input_${key}`} value={key}>
                  {key}
                </option>
              ))}
          </Switcher>
        </div>
      </Tools>
    </Wrapper>
  );
};

export default withTranslation()(AppTools);

const Wrapper = styled.div<{ openTools: boolean }>`
  display: flex;
  flex-direction: row;
  position: fixed;
  bottom: 10px;
  width: 100%;
  left: ${({ openTools }) => (openTools ? 'calc(100% - 500px)' : 'calc(100% - 64px)')};
  background-color: #1b57fd;
  box-shadow: 0 5px 16px 0 rgba(34, 36, 38, 0.46);
  z-index: 9999999;
  align-items: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.3s;

  span {
    color: #fce661;
    font-size: 12px;
    display: block;
    margin-bottom: 5px;
  }

  button {
    margin-left: 5px;
  }
`;

const Icon = styled.div<{ openTools: boolean }>`
  transition: all 0.3s;
  transform: rotate(${({ openTools }) => (openTools ? '0deg' : '180deg')});
`;

const Tools = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: row;

  > div:not(:first-child) {
    margin-left: 10px;
  }
`;

const CloseButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #fce661;
  font-weight: bold;
  padding: 10px 16px;
  box-shadow: 5px 0 20px -5px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.3s;

  :hover {
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 10px 0 20px -5px rgba(0, 0, 0, 0.5);
  }
`;

const Switcher = styled.select``;
