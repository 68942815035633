import React from 'react';
import styled from 'styled-components';

import { Text } from '@paco_ua/pacoui';
import { Container } from 'semantic-ui-react';

import logoUA from 'assets/images/logo512.png';

const PageNotFound = ({ theme, t }) => {
  return (
    <Wrapper>
      <Container>
        <AlignCenter>
          <LogoUA src={logoUA} alt="Universidade de Aveiro" />
        </AlignCenter>
        <WrapperText>
          <FormattedText weight="bold" size="xxxLarge">
            <AlignCenter>404</AlignCenter>
          </FormattedText>
          <FormattedText>
            <AlignCenter>{t('offline.pageNotFound')}...</AlignCenter>
          </FormattedText>
        </WrapperText>
      </Container>
    </Wrapper>
  );
};

export default PageNotFound;

const FormattedText: React.FC<{
  size?: string;
  color?: string;
  weight?: string;
  onClick?: () => void;
}> = ({ children, size, onClick, weight, color }) => (
  <div
    onClick={() => {
      onClick && onClick();
    }}
  >
    <Text color={'plusDarkGrey'} size={size ? size : 'large'} weight={weight}>
      {children}
    </Text>
  </div>
);

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 26%;

  ${({ theme }) => theme.medias.sm} {
    padding: 0 20%;
  }

  ${({ theme }) => theme.medias.xs} {
    padding: 0 80px;
  }
`;

const LogoUA = styled.img`
  width: 120px;
  height: 120px;
  margin: 120px 0 80px;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;

  > div:last-child {
    margin-bottom: 48px;
  }
`;
