/**
 * ApplicationDetails scene utils
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';
import * as DOMPurify from 'dompurify';

//TODO: add parser package
export const parseBulletAndBoldElements = (text: string) => {
  if (/\*\*(.*?)\*\*/.test(text)) {
    // Replace bold marks with <strong> tags
    const boldTransformed = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Check if the text contains bullet marks
    const hasBulletMarks = /^(\s*)(\*|-)\s+(.*?)(\n|$)/gm.test(boldTransformed);

    if (hasBulletMarks) {
      // Replace bullet marks with <li> tags
      const bulletTransformed = boldTransformed.replace(
        /^(\s*)(\*|-)\s+(.*?)(\n|$)/gm,
        '$1<li>$3</li>'
      );
      return { __html: DOMPurify.sanitize(`<ul>${bulletTransformed}</ul> `) };
    } else {
      return { __html: DOMPurify.sanitize(boldTransformed) };
    }
  } else {
    return { __html: DOMPurify.sanitize(text) };
  }
};

export const textParser = (text) => {
  const textWithDelimiters = text.replace(/<p>/gi, '<delimiter><p><delimiter>');

  let splitArray = textWithDelimiters.split('<delimiter>') as any[];

  splitArray = splitArray.filter((item) => item !== '');

  let result = [] as any[];
  let tmpString = '';

  splitArray.forEach((item, index) => {
    if (item !== '<p>') {
      tmpString += item;

      let paragraphCounter = 0;
      for (let i = index + 1; i < splitArray.length; i++) {
        if (splitArray[i] !== '<p>') {
          break;
        } else {
          paragraphCounter++;
        }
      }

      if (paragraphCounter > 0) {
        result.push(
          <div
            key={`paragraph-div-${index}`}
            dangerouslySetInnerHTML={parseBulletAndBoldElements(tmpString)}
          />
        );
        result.push(<Paragraph height={`${paragraphCounter * 10}px`} key={`paragraph-${index}`} />);

        // reset
        tmpString = '';
        paragraphCounter = 0;
      } else if (index === splitArray.length - 1) {
        result.push(<div key={`paragraph-${index}`}>{tmpString}</div>);
      }
    }
  });

  return result;
};

const Paragraph = styled.div<{ height: string }>`
  height: ${({ height }) => height};
`;
