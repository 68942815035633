/**
 * SchedulesGuide scene logic selectors
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getAppSlice = (state: RootState) => state.App;
export const getSchedulesGuideSlice = (state: RootState) => state.SchedulesGuide;
