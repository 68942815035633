/**
 * FreeOptionsDropdown container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import numeral from 'numeral';

import { t } from 'app';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { DropdownSelector, Modal, Text } from '@paco_ua/pacoui';

import FreeOptionsTable from 'scenes/Schedules/containers/FreeOptionsTable';

import { reformatFreeUcs } from 'scenes/Schedules/MySchedule/utils';

import { ucSelectedOtherOption } from './utils';
//import config from 'config'

const FreeOptionsDropdown = () => {
  const ucs = useSelector(selectors.getUcs);
  const registration = useSelector(selectors.getRegistration);
  const { currentPhase } = useSelector(selectors.getSchedulesSlices);

  const {
    options,
    confirmationModalIsOpen,
    freeOptionsSearchModalIsOpen,
    optionIndexToRemove,
    optionToRemove,
    ucIdToRemove,
  } = useSelector(selectors.getFreeOptions);

  const dispatch = useDispatch();
  const { setFreeOptions, removeFreeOptions } = actions.Schedules;
  const {
    setOptions,
    setConfirmationModalOpening,
    setFreeOptionsSearchModalOpening,
    setOptionIndexToRemove,
    setOptionToRemove,
    setUcIdToRemove,
  } = actions.FreeOptionsDropdown;
  const { removeUcList } = actions.FreeOptionsTable;

  const handleSelection = (selectedOption) => {
    const selectedOptionKey = freeOptionsSearchModalIsOpen.option?.['key'][0];
    let groupId = null;
    let reformatedUc = {};

    const newOptions = options.map((option, key) => {
      if (key === selectedOptionKey) {
        groupId = option.optionId;
        reformatedUc = reformatFreeUcs([selectedOption], currentPhase?.finished, groupId)[0];
        return {
          ...option,
          selected: 0,
          ucs: [{ ...reformatedUc }],
        };
      } else {
        return option;
      }
    });

    if (groupId !== null) {
      dispatch(
        setFreeOptions({
          ucItem: reformatedUc,
        })
      );
    }

    dispatch(setOptions(newOptions));
    dispatch(setFreeOptionsSearchModalOpening({ modalOpen: false, option: null }));
  };

  const handleRemoval = (selectedOption) => {
    const selectedOptionKey = selectedOption?.['key'][0];
    let selectedUc: any = null;

    const newOptions = options.map((option, key) => {
      if (key === selectedOptionKey) {
        selectedUc = option.ucs[0];
        return {
          ...option,
          selected: null,
          ucs: [],
        };
      } else {
        return option;
      }
    });

    dispatch(removeUcList(selectedUc?.ucId));
    dispatch(removeFreeOptions(selectedUc?.ucId));
    dispatch(setOptions(newOptions));
  };

  // Check if option to remove contains classes that are, already, selected @ Schedules
  // if true, show a confirmadtion modal before removing
  const handleCheckingIfOptionHasSelectedClasses = (selection, selectedOption) => {
    const [optionIndex, itemIndex] = selection;
    // Find respective Schedule's state UC
    // and evaluate if it has classes selected
    const ucId = options?.[optionIndex]?.ucs?.[itemIndex]?.ucId || null;
    const groupId = options?.[optionIndex]?.ucs?.[itemIndex]?.groupId || null;

    const scheduleUcToRemove = ucs.find((uc) => uc.ucId === ucId && uc.groupId === groupId);

    if (scheduleUcToRemove) {
      const scheduleUcToRemoveHasSelectedClasses = scheduleUcToRemove?.classSchedule.find(
        (eachClass) => {
          return eachClass.selected;
        }
      );

      if (scheduleUcToRemoveHasSelectedClasses) {
        // set option index and uc id to remove while waiting for user confirmation
        dispatch(setOptionIndexToRemove(optionIndex));
        dispatch(setOptionToRemove(selectedOption));
        dispatch(setUcIdToRemove(ucId));
        // show modal and wait for user confirmation
        dispatch(setConfirmationModalOpening(true));
      } else {
        handleRemoval(selectedOption);
      }
    } else {
      handleRemoval(selectedOption);
    }
  };

  const handleModalSubmission = () => {
    if (optionIndexToRemove !== null && optionToRemove !== null && ucIdToRemove !== null) {
      handleRemoval(optionToRemove);
    }

    dispatch(setConfirmationModalOpening(false));
  };

  const handleModalOnCloseOrOnCancel = () => {
    dispatch(setOptionIndexToRemove(null));
    dispatch(setUcIdToRemove(null));
    dispatch(setConfirmationModalOpening(false));
  };

  if (options.length <= 0) {
    return null;
  }

  return (
    <Wrapper>
      <Modal
        type={'confirmation'}
        open={confirmationModalIsOpen}
        header={t('sgh.actionRemoveOption')}
        message={t('sgh.actionRemoveOptionDetails')}
        border
        cancelButtonText={t('generic.cancel')}
        submitButtonText={t('generic.remove')}
        onClose={() => handleModalOnCloseOrOnCancel()}
        onCancel={() => handleModalOnCloseOrOnCancel()}
        onSubmit={() => {
          handleModalSubmission();
        }}
      />

      <Modal
        type={'content'}
        width="90%"
        fullHeight
        open={freeOptionsSearchModalIsOpen.modalOpen}
        header={
          <ModalHeader>
            <Text color="blackLight" size="xLarge" fontWeight="500">
              {freeOptionsSearchModalIsOpen?.option?.title || ''}
            </Text>
            {currentPhase?.year && currentPhase?.period && (
              <Text color="blackLight" size="normal">
                {`${currentPhase.year}/${parseInt(currentPhase.year) + 1}`}
                {` - ${numeral(currentPhase.period).format('o')} ${t('generic.semester', {
                  textOnly: true,
                })}`}
              </Text>
            )}
          </ModalHeader>
        }
        onClose={() => {
          dispatch(setFreeOptionsSearchModalOpening({ modalOpen: false, option: null }));
        }}
        data-testid="schedules_modal_free_list_table"
      >
        <FreeOptionsTable
          t={t}
          onLoad={(_loading) => {}}
          onError={(_error) => {}}
          onPeriod={(period) => {}}
          mandatoryFilters
          registrationId={registration}
          onSelectRow={(option) => {
            handleSelection(option);
          }}
        />
      </Modal>

      <DropdownSelector
        title={t('sgh.freeOptions')}
        tip={t('sgh.selectUcPerOption')}
        options={options.map((option) => {
          return {
            title: option.name,
            disabled: option.irregular,
            info: option.irregular && t('sgh.infoAlertDisabledFreeUcGroup'),
            items: option.ucs.map((eachClass, key) => {
              const countSlots = eachClass.availableSlots;
              const noClasses = !eachClass?.classSchedule?.length;
              return {
                id: key,
                title: eachClass.ucFullName,
                selected: option.selected === key,
                disabled:
                  currentPhase?.finished ||
                  eachClass.disabled ||
                  ucSelectedOtherOption(option, eachClass, options) ||
                  eachClass.classSchedule.some(
                    (classItem) =>
                      classItem?.allocated?.state === 'assigned' ||
                      classItem?.allocated?.state === 'automatic'
                  ),
                infoType: noClasses === false ? 'warning' : 'danger',
                info: eachClass.disabled
                  ? t('sgh.infoAlertDisabledMandatory', {
                      context: `${countSlots}`,
                      count: countSlots,
                    })
                  : noClasses
                  ? t('sgh.infoAlertDisabledMandatoryNoClasses')
                  : t('sgh.infoAlertDisabledMandatory', {
                      context: `${countSlots}`,
                      count: countSlots,
                    }),
              };
            }),
          };
        })}
        onRemove={(optionId, option) => {
          handleCheckingIfOptionHasSelectedClasses(optionId, option);
        }}
        onToggleOption={(optionId, option) => {
          if (option?.items?.length === 0) {
            dispatch(setFreeOptionsSearchModalOpening({ modalOpen: true, option }));
          }
        }}
      />
    </Wrapper>
  );
};

export default FreeOptionsDropdown;

const Wrapper = styled.div`
  max-width: 280px;
  min-width: 150px;
  border-top: 1px solid #2eb4bd;
  border-left: 1px solid #2eb4bd;
  border-right: 1px solid #2eb4bd;
  width: 280px;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 30px;
  }
`;
