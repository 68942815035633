/**
 * Payments Table container
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import React, { useState } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faDownload } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';
import { Table, Button as ButtonComponent, Text, StatusLabel } from '@paco_ua/pacoui';
import styled, { ThemeContext } from 'styled-components';
import { actions } from 'store/rootSlices';

import {
  IPaymentsTableProps,
  ITranslations /*, IPaymentData*/,
} from 'scenes/VirtualSecretary/Payments/types';

import useTable from './useTable';
import config from 'config';
import API from 'api';

const compileTableRow = (
  payment: any /*IPaymentData*/,
  theme: any,
  t: ITranslations,
  loadingReceipt,
  setLoadingReceipt
) => ({
  rowProps: {},
  cells: [
    {
      content: (
        <CellWrapper>
          <PaymentIdText weight="medium" size="article">
            {payment.paymentId}
          </PaymentIdText>
        </CellWrapper>
      ),
      cellProps: {
        width: 2,
        singleLine: false,
      },
    },
    {
      content: (
        <PaymentDescriptionText size="article" weight="medium">
          {payment.description}
        </PaymentDescriptionText>
      ),
      cellProps: { width: 5, singleLine: false },
    },
    {
      content: (
        <CurrencyText size="article">
          {t('generic.euros', { textOnly: true, 
            count: payment.value.toFixed(2),
            coin: payment.coin })}
        </CurrencyText>
      ),
      cellProps: {
        width: 2,
        singleLine: false,
        style: { textAlign: 'center' },
      },
    },
    {
      content: (
        <Text size="article">{moment(payment.paymentDate, 'YYYY-MM-DD').format('DD-MM-YYYY')}</Text>
      ),
      cellProps: {
        width: 2,
        singleLine: true,
        style: {
          textAlign: 'center',
        },
      },
    },
    {
      content: payment.receiptState ? (
        <Button
          loading={loadingReceipt[payment.billReceipt.id]}
          disabled={loadingReceipt[payment.billReceipt.id]}
          onClick={() => {
            setLoadingReceipt((currentValues) => {
              return { ...currentValues, [payment.billReceipt.id]: true };
            });

            if (!loadingReceipt[payment.billReceipt.id] && payment.billReceipt.url !== '') {
              API.files.getFileOnTab
                .call(`${config.API_WSO2}${config.API_SEC_VIRTUAL_PATH}${payment.billReceipt.url}`)
                .then((value) => {
                  setLoadingReceipt((currentValues) => {
                    return { ...currentValues, [payment.billReceipt.id]: false };
                  });
                })
                .catch((e) => {
                  setLoadingReceipt((currentValues) => {
                    return { ...currentValues, [payment.billReceipt.id]: false };
                  });
                  useDispatch()(
                    actions.Toaster.showToaster({
                      title: t('secVirtualNotifications.general_errorLoadingFile'),
                      icon: 'error',
                      type: 'danger',
                    })
                  );
                });
            }
          }}
          data-testid={`paymentsTable_download_button`}
          // leftIcon={<FontAwesomeIcon icon={faFilePdf} color={theme.colors.plusDarkGrey} size="lg" />}
          action
          borderless
        >
          {/* {t(`payments.receipt`)} */}
          <FontAwesomeIcon size={'2x'} icon={faFilePdf} />
        </Button>
      ) : (
        <></>
      ),
      cellProps: {
        width: 1,
        singleLine: false,
        style: {
          alignItems: 'center',
        },
      },
    },
  ],
});

const PaymentsTable = ({ t, data, loading, total, fetchPayments }: IPaymentsTableProps) => {
  const [loadingReceipt, setLoadingReceipt] = useState({});

  const { structure, pagination, onChangePage, onChangeRows } = useTable(
    t,
    data,
    compileTableRow,
    total,
    fetchPayments,
    loadingReceipt,
    setLoadingReceipt
  );

  return (
    <PaymentsTableWrapper>
      <Table
        dropdownUpward
        unstackable
        loading={loading}
        pagination={pagination}
        structure={structure}
        translations={t}
        onChangeRows={onChangeRows}
        onChangePage={onChangePage}
      />
    </PaymentsTableWrapper>
  );
};

export default PaymentsTable;

const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 14px;
  }
`;

const Button = styled(ButtonComponent)`
  width: 100%;
`;

const PaymentsTableWrapper = styled.div`
  overflow: auto;

  &&& th {
    padding-right: 14px !important;
    padding-left: 14px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-right: 14px !important;
    padding-left: 14px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }

  &&& .ui.table tfoot tr td {
    height: 45px;
  }
`;

const CurrencyText = styled(Text)`
  word-break: keep-all;
`;

const PaymentIdText = styled(Text)`
  line-height: inherit;
`;

const PaymentDescriptionText = styled(Text)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 1.3;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
