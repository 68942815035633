/**
 * NewApplicationSelectType container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { Grid } from 'semantic-ui-react';
import { AnimatedBackground, Text, Segment, Accordion, Dropdown, SearchBox } from '@paco_ua/pacoui';

const NewApplicationSelectType = () => {
  const theme = useContext(ThemeContext);

  const {
    setApplicationData,
    setSearchFilter,
    setTypeFilter,
    resetApplicationSelection,
    getApplicationTypes,
    resetStepThree,
    setApplicationTypeId,
  } = actions.NewApplication;

  const {
    loadingStatus,
    applicationData,
    applicationTypes,
    filterParams,
    errorStatus,
  } = useSelector(selectors.getNewApplicationSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getApplicationTypes(filterParams));
  }, [filterParams, errorStatus]);

  const getAccordionParent = (title: string) => {
    return (
      <AccordionParent>
        {title}
        <FontAwesomeIcon icon={faAngleDown} />
      </AccordionParent>
    );
  };

  const getAccordionChild = (
    themeGroup: string,
    title: string,
    description: string,
    typeId: number
  ) => {
    return (
      <AccordionChild
        key={`accordionChild-${typeId}`}
        onClick={() => {
          dispatch(setApplicationTypeId(typeId));
          dispatch(
            setApplicationData({
              ...applicationData,
              type: {
                theme: themeGroup,
                id: typeId,
                name: title,
              },
            })
          );
        }}
      >
        <Input
          type="radio"
          onChange={() => {
            dispatch(
              setApplicationData({
                ...applicationData,
                type: {
                  theme: themeGroup,
                  id: typeId,
                  name: title,
                },
              })
            );
            // when type is selected or changed, the form and data are reset
            dispatch(resetStepThree());
          }}
          checked={applicationData.type.id === typeId}
        />
        <AccordionChildDescription>
          <Text size="article" color={'primary'} weight={'medium'}>
            {title}
          </Text>
          {description && description !== '' && (
            <AccordionChildDescriptionText size="xSmall" color={'plusDarkGrey'} weight={'medium'}>
              {description}
            </AccordionChildDescriptionText>
          )}
        </AccordionChildDescription>
      </AccordionChild>
    );
  };

  const structure = Object.keys(applicationTypes.data).map((type) => {
    return {
      parent: getAccordionParent(applicationTypes.data[type][0].theme),
      children: applicationTypes.data[type].map((t) =>
        getAccordionChild(t.theme, t.type, t.description, t.applicationId)
      ),
    };
  });

  return (
    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
      <Segment>
        <SegmentTitleWrapper>
          <Text size="large" color={'plusDarkGrey'} weight={'regular'}>
            {t('applications.requestSecondStepTitle')}
          </Text>
          <SegmentDescriptionText size="article" color={'darkGrey'} weight={'regular'}>
            {t('applications.requestSecondStepDescription')}
          </SegmentDescriptionText>
        </SegmentTitleWrapper>

        {!loadingStatus.tabLoading && !errorStatus.errLoadingApplicationList && (
          <SearchAndFiltersWrapper>
            <SearchBox
              placeholder={t('forms.searchPlaceholder', { textOnly: true })}
              borderColor={theme.colors.plusDarkGrey}
              iconColor={theme.colors.plusDarkGrey}
              defaultValue={filterParams.search !== '' ? filterParams.search : undefined}
              delay={500}
              onSearch={(value) => {
                dispatch(setSearchFilter(value));
                dispatch(resetApplicationSelection());
              }}
            />
            <Dropdown
              placeholder={t('applications.listDropdownFilterPlaceholder', {
                textOnly: true,
              })}
              canBeCleared
              options={applicationTypes.filters}
              selectionWeight={'400'}
              selectionFontSize={'medium'}
              onChange={(e, { value }) => {
                dispatch(setTypeFilter(value));
                dispatch(resetApplicationSelection());
              }}
              value={filterParams.type !== '' ? filterParams.type : undefined}
              onClear={() => {
                dispatch(setTypeFilter(''));
                dispatch(resetApplicationSelection());
              }}
            />
          </SearchAndFiltersWrapper>
        )}

        {loadingStatus.tabLoading && (
          <LoadingWrapper>
            <AnimatedBackground height={theme.sizes.large} width="60%" />
            <AnimatedBackground height={theme.sizes.large} width="20%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="80%" />
            <AnimatedBackground height={theme.sizes.large} width="50%" />
          </LoadingWrapper>
        )}

        {!loadingStatus.tabLoading &&
          !errorStatus.errLoadingApplicationList &&
          structure.length > 0 && (
            <AccordionWrapper>
              <Accordion structure={structure} />
            </AccordionWrapper>
          )}

        {!loadingStatus.tabLoading &&
          errorStatus.errLoadingApplicationList &&
          structure.length === 0 && (
            <MessageNoApplications>
              <Icon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
              <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                {t('applications.noApplicationsFound', { textOnly: true })}
              </Text>
            </MessageNoApplications>
          )}

        {!loadingStatus.tabLoading && errorStatus.errNoApplications && structure.length === 0 && (
          <MessageNoApplications>
            <Icon>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Icon>
            <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
              {t('secVirtualNotifications.newApplication_errorNoApplications', { textOnly: true })}
            </Text>
          </MessageNoApplications>
        )}
      </Segment>
    </Grid.Column>
  );
};

export default NewApplicationSelectType;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const MessageNoApplications = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
  margin-top: 32px;

  * {
    margin: 8px 0px;
  }
`;

const AccordionWrapper = styled.div`
  margin-top: 32px;
`;

const AccordionParent = styled.div`
  height: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  background-color: ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.softGrey};
  border-right: 1px solid ${({ theme }) => theme.colors.softGrey};
  border-left: 1px solid ${({ theme }) => theme.colors.softGrey};
  border-top: 2px solid ${({ theme }) => theme.colors.darkGrey};

  svg {
    font-size: 24px;
  }
`;

const AccordionChild = styled.div`
  height: 69px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid ${({ theme }) => theme.colors.softGrey};
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.softGrey};
  }
`;

const AccordionChildDescription = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SearchAndFiltersWrapper = styled.div`
  width: 75%;
  display: flex;
  margin-bottom: 24px;
  margin-top: 32px;

  > div {
    width: 50%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const AccordionChildDescriptionText = styled(Text)`
  margin-top: 4px;
`;

const SegmentDescriptionText = styled(Text)`
  margin-top: 8px;
  line-height: 1.5;
`;

const Input = styled.input`
  /* hide default inner circle */
  appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.regularGrey};
  border-radius: 50%;
  outline: none;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    opacity: 1;
  }

  /* inner circle */
  :before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: middle;
  }

  :checked:before {
    background: ${({ theme }) => theme.colors.primary};
  }

  :checked {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }
`;
