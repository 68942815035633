/**
 * Toaster container sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { takeLatest, delay, put } from 'redux-saga/effects';

import { actions } from 'store/rootSlices';

function* timeoutAndHideToaster() {
  const { unsetDisplayToasterAction } = actions.ToasterAction;

  yield delay(500);

  yield put(unsetDisplayToasterAction());
}

export default function* watcherSignin() {
  yield takeLatest('ToasterAction/closeToasterAction', timeoutAndHideToaster);
}
