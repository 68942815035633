/**
 * SeriationHistoryDetails scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faHistory } from '@fortawesome/pro-regular-svg-icons';

import { Table, Text, Button } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { getCurrentRoute } from 'utils';

//import config from 'config'

const SeriationHistory = ({ t }) => {
  const dispatch = useDispatch();
  const { onMount, onUnmount, getSeriationHistoryDetails } = actions.SeriationHistoryDetails;

  const registration = useSelector(selectors.getRegistration);

  const { loading, seriationDate, ranking, log } = useSelector(
    selectors.getSeriationHistoryDetailsSlice
  );

  const theme = useContext(ThemeContext);

  const regimenMap = {
    O: 'sgh.regimen_O',
    T: 'sgh.regimen_T',
    D: 'sgh.regimen_D',
    A: 'sgh.regimen_A',
    E: 'sgh.regimen_E',
    M: 'sgh.regimen_M',
    B: 'sgh.regimen_B',
    G: 'sgh.regimen_G',
    L: 'sgh.regimen_L',
  };

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  useEffect(() => {
    if (registration !== null) {
      dispatch(getSeriationHistoryDetails());
    }
  }, [registration]);

  const getTableStructure = () => {
    return {
      header: {
        type: 'tableTitle',
        title: (
          <Text size="medium" color="plusDarkGrey" weight="medium">
            {`${t('sgh.studentRanking', { textOnly: true })}: ${ranking.rankingNumber} ${t(
              'sgh.of',
              { textOnly: true }
            )} ${ranking.rankingTotal}`}
          </Text>
        ),
      },
      rows: [
        {
          cells: [
            {
              content: (
                <RankingTextWrapper>
                  <Text size="article" color="plusDarkGrey" weight="medium">
                    {`${t('sgh.consideredInformation', { textOnly: true })} (REUA)`}
                  </Text>
                  <RankingTextSubWrapper>
                    {ranking.regimen && (
                      <Text size="article" color="darkGrey" weight="regular">
                        {`${t('sgh.regimen', { textOnly: true })}: ${t(
                          regimenMap[ranking.regimen],
                          {
                            textOnly: true,
                          }
                        )}`}
                      </Text>
                    )}
                    <Text size="article" color="darkGrey" weight="regular">
                      {`${t('sgh.ectsDone', { textOnly: true })}: ${ranking.totalEcts}`}
                    </Text>
                    <Text size="article" color="darkGrey" weight="regular">
                      {`${t('sgh.average', { textOnly: true })}: ${ranking.grade} ${t('sgh.score', {
                        textOnly: true,
                      })}`}
                    </Text>
                    <Text size="article" color="darkGrey" weight="regular">
                      {`${t('sgh.backloggedUcs', { textOnly: true })}: ${ranking.repeatUcs}`}
                    </Text>
                  </RankingTextSubWrapper>
                </RankingTextWrapper>
              ),
              cellProps: { width: 1 },
            },
          ],
        },
      ],
    };
  };

  const getTableStructureDetails = () => {
    return {
      header: {
        type: 'tableTitle',
        title: (
          <SeriationDetailsHeaderWrapper>
            <HistoryIconWrapper>
              <FontAwesomeIcon size="lg" icon={faHistory} color={theme.colors.plusDarkGrey} />
            </HistoryIconWrapper>
            <Text size="medium" color="plusDarkGrey" weight="medium">
              {t('sgh.detailedHistory', { textOnly: true })}
            </Text>
          </SeriationDetailsHeaderWrapper>
        ),
      },
      rows: [
        {
          cells: [
            {
              content: (
                <DetailedHistoryWrapper>
                  {log?.map((item, key) => {
                    let newLine = '';
                    for (var i = 0; i < item.length; i++) {
                      if (item.charAt(i) !== ' ') {
                        break;
                      }
                      newLine += '\u00a0\u00a0\u00a0';
                    }

                    newLine += item.trim();

                    return (
                      <Text
                        key={`${key}log_line`}
                        size="article"
                        color="plusDarkGrey"
                        weight="regular"
                      >
                        {newLine}
                      </Text>
                    );
                  })}
                </DetailedHistoryWrapper>
              ),
              cellProps: { width: 1 },
            },
          ],
        },
      ],
    };
  };

  const downloadTxtFile = () => {
    const element = document.createElement('a');
    let textToDownload = '';
    log?.forEach((item) => {
      textToDownload += `${item}\r\n`;
    });

    const file = new Blob([textToDownload], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `${t('sgh.detailedHistoryFileName', { textOnly: true })} - ${`${t(
      getSeriationType(),
      {
        textOnly: true,
      }
    )} ${moment(seriationDate).format('DD/MM/YYYY | HH:mm')}`}.txt`;

    // Required for this to work in FireFox
    document.body.appendChild(element);
    element.click();
  };

  const getSeriationType = () => {
    if (getCurrentRoute().route.key === 'seriationhistorydetails') {
      return 'sgh.finalSeriation';
    } else if (getCurrentRoute().route.key === 'provisoryseriationhistorydetails') {
      return 'sgh.provisorySeriation';
    } else {
      return '';
    }
  };

  return (
    <PageStructure
      loading={loading}
      expanded
      headerLeft={
        <Header
          backButton
          title={`${
            getSeriationType() !== ''
              ? t(getSeriationType(), {
                  textOnly: true,
                })
              : ''
          } ${moment(seriationDate).format('DD/MM/YYYY | HH:mm')}`}
        />
      }
      headerRight={
        log && (
          <Button
            action
            leftIcon={<FontAwesomeIcon icon={faDownload} />}
            onClick={() => downloadTxtFile()}
            data-testid="schedules_edit_button_remove"
          >
            {t('generic.download', { textOnly: true })}
          </Button>
        )
      }
      mainContent={
        <>
          <ContentWrapper>
            <Table structure={getTableStructure()} />

            <SeriationDetailsTableWrapper>
              <Table structure={getTableStructureDetails()} />
            </SeriationDetailsTableWrapper>
          </ContentWrapper>
        </>
      }
    />
  );
};

export default SeriationHistory;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const SeriationDetailsTableWrapper = styled.div`
  margin-top: 52px;
`;

const SeriationDetailsHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const HistoryIconWrapper = styled.div`
  margin-right: 10px;
`;

const RankingTextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:first-child {
    margin-bottom: 16px;
  }
`;

const RankingTextSubWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailedHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
