import React from 'react';
import styled from "styled-components";
import PdfReader from "../../../VirtualSecretary/Certificates/CertificatesDetails/containers/CertificateReady/PdfReader";

const PdfBackdrop = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1102;
	background-color: rgba(0, 0, 0, 0.5);
`;

const PdfWrapper = styled.div`
	position: fixed;
	top: 2.5rem;
  left: 18%;
  width: 65%;
	z-index: 1103;
`;
interface PdfModalProps {
  changeModal: () => void;
  pdfUrl: string | null;
  scale: number;
}

const PdfModal: React.FC<PdfModalProps & { pautaCode: string }> = (props) => {

  const hideModal = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      props.changeModal();
    }
  };

  return (
    <>
      <PdfBackdrop onClick={hideModal}></PdfBackdrop>
      <PdfWrapper>
        {props.pdfUrl ? (
          <PdfReader
            document={{ url: props.pdfUrl, name: `Pauta_${props.pautaCode}.pdf` }}
            height="92vh" // Example height, adjust as needed
          />
        ) : (
          <div>Loading PDF...</div>
        )}
      </PdfWrapper>
    </>
  );
};

export default PdfModal;
