/**
 * Mandatory Options Dropdown container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { t } from 'app';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { DropdownSelector, Modal } from '@paco_ua/pacoui';

import { getCurrentRoute } from 'utils';

import { ucSelectedOtherOption } from './utils';

const MandatoryOptionsDropdown: React.FC = () => {
  const preselected = useRef(false);
  const ucs = useSelector(selectors.getUcs);

  const { options, confirmationModalIsOpen, optionIndexToRemove, ucIdToRemove } = useSelector(
    selectors.getMandatoryOptions
  );

  const { currentPhase } = useSelector(selectors.getSchedulesSlices);

  const dispatch = useDispatch();
  const { setMandatoryOptions, removeMandatoryOptions } = actions.Schedules;
  const {
    setOptions,
    setConfirmationModalOpening,
    setOptionIndexToRemove,
    setUcIdToRemove,
  } = actions.MandatoryOptionsDropdown;

  useEffect(() => {
    preselected.current = false;
  }, []);

  // Effect Hooks
  useEffect(() => {
    options.length > 0 &&
      !preselected.current &&
      ucs.length > 0 &&
      getCurrentRoute().route.key === 'editschedules' &&
      handleClassPreSelection();
  }, [ucs, options]);

  const handleClassPreSelection = () => {
    const ucsToPreselect = ucs.reduce((acc: any[], uc: any) => {
      let index = 0;

      if (
        options.some((option) =>
          option.ucs.some((optionUc, key) => {
            index = key;
            return optionUc.ucId === uc.ucId;
          })
        )
      ) {
        if (uc.classSchedule.some((classUnit) => classUnit.selected)) {
          return [...acc, { ...uc, optionKey: index }];
        }
      }
      return acc;
    }, []);

    if (ucsToPreselect.length > 0) {
      preselected.current = true;

      const newOptions = options.map((option) => {
        if (ucsToPreselect.some((uc) => option.ucs.some((optionUc) => uc.ucId === optionUc.ucId))) {
          let idToSelect = 0;

          ucsToPreselect.forEach((uc, ucKey) => {
            uc.classSchedule.forEach((classUnit, key) => {
              if (classUnit.selected) {
                idToSelect = uc.optionKey;
              }
            });
          });

          return {
            ...option,
            open: true,
            selected: idToSelect,
          };
        } else {
          return option;
        }
      });

      dispatch(setOptions(newOptions));
    }
  };

  const handleClassSelection = (selection) => {
    const [optionIndex, classIndex] = selection;
    const newOptions = options.map((option) => {
      if (option.id === optionIndex + 1) {
        // attatch selected option classes to the schedule grid
        dispatch(
          setMandatoryOptions({
            ucId: option.ucs[classIndex].ucId,
            groupId: option.ucs[classIndex].groupId,
          })
        );
        return {
          ...option,
          open: true,
          selected: classIndex,
        };
      } else {
        return option;
      }
    });

    dispatch(setOptions(newOptions));
  };

  const handleOptionRemoval = (optionIndex: number) => {
    const newOptions = options.map((option) => {
      if (option.id === optionIndex + 1) {
        return {
          ...option,
          selected: null,
        };
      } else {
        return option;
      }
    });
    dispatch(setOptions(newOptions));
  };

  // Check if option to remove contains classes that are, already, selected @ Schedules
  // if true, show a confirmation modal before removing
  const handleCheckingIfOptionHasSelectedClasses = (
    selection,
    ucId: number,
    groupId: number | null
  ) => {
    const [optionIndex] = selection;
    // Find respective Schedule's state UC
    // and evaluate if it has classes selected
    const scheduleUcToRemove = ucs.find((uc) => uc.ucId === ucId && uc.groupId === groupId);
    if (scheduleUcToRemove) {
      const scheduleUcToRemoveHasSelectedClasses = scheduleUcToRemove.classSchedule.find(
        (eachClass) => {
          return eachClass.selected;
        }
      );
      if (scheduleUcToRemoveHasSelectedClasses) {
        // set option index and uc id to remove while waiting for user confirmation
        dispatch(setOptionIndexToRemove(optionIndex));
        dispatch(setUcIdToRemove({ ucId, groupId }));
        // show modal and wait for user confirmation
        dispatch(setConfirmationModalOpening(true));
      } else {
        dispatch(removeMandatoryOptions({ ucId, groupId }));
        handleOptionRemoval(optionIndex);
      }
    }
  };

  const handleModalSubmission = () => {
    if (optionIndexToRemove !== null && ucIdToRemove !== null) {
      handleOptionRemoval(optionIndexToRemove);
      dispatch(removeMandatoryOptions({ ucId: ucIdToRemove.ucId, groupId: ucIdToRemove.groupId }));
    }

    dispatch(setConfirmationModalOpening(false));
  };

  const handleModalOnCloseOrOnCancel = () => {
    dispatch(setOptionIndexToRemove(null));
    dispatch(setUcIdToRemove(null));
    dispatch(setConfirmationModalOpening(false));
  };

  if (options.length <= 0) {
    return null;
  }

  return (
    <Wrapper>
      <Modal
        type={'confirmation'}
        open={confirmationModalIsOpen}
        header={t('sgh.actionRemoveOption')}
        message={t('sgh.actionRemoveOptionDetails')}
        border
        cancelButtonText={t('generic.cancel')}
        submitButtonText={t('generic.remove')}
        onClose={() => handleModalOnCloseOrOnCancel()}
        onCancel={() => handleModalOnCloseOrOnCancel()}
        onSubmit={() => handleModalSubmission()}
      />

      <DropdownSelector
        title={t('sgh.mandatoryOptions')}
        tip={t('sgh.selectUcPerOption')}
        options={options.map((option) => ({
          title: option.name,
          items: option.ucs.map((eachClass, key) => {
            const countSlots = eachClass.availableSlots;
            const noClasses = !eachClass?.classSchedule.length;
            const noOnline = eachClass.irregular === 'noOnline';

            return {
              id: key,
              title: eachClass.ucFullName,
              selected: option.selected === key,
              disabled:
                currentPhase?.finished ||
                eachClass.disabled ||
                noClasses ||
                noOnline ||
                ucSelectedOtherOption(option, eachClass, options) ||
                eachClass.classSchedule.some(
                  (classItem) =>
                    classItem?.allocated?.state === 'assigned' ||
                    classItem?.allocated?.state === 'automatic'
                ),
              infoType: noClasses === false ? 'warning' : 'danger',
              info: noClasses
                ? t('sgh.infoAlertDisabledMandatoryNoClasses')
                : noOnline
                ? t('sgh.infoAlertDisabledOnline')
                : eachClass.disabled
                ? t('sgh.infoAlertDisabledMandatory', {
                    context: `${countSlots}`,
                    count: countSlots,
                  })
                : t('sgh.infoAlertDisabledMandatory', {
                    context: `${countSlots}`,
                    count: countSlots,
                  }),
            };
          }),
        }))}
        onRemove={(selection) => {
          const [optionId, itemId] = selection;
          handleCheckingIfOptionHasSelectedClasses(
            selection,
            options[optionId].ucs[itemId].ucId,
            options[optionId].ucs[itemId].groupId
          );
        }}
        onSelect={(selection) => {
          handleClassSelection(selection);
        }}
      />
    </Wrapper>
  );
};

export default MandatoryOptionsDropdown;

const Wrapper = styled.div`
  max-width: 280px;
  min-width: 150px;
  border-top: 1px solid #2eb4bd;
  border-left: 1px solid #2eb4bd;
  border-right: 1px solid #2eb4bd;
  width: 280px;
`;
