/**
 * SeriationHistory scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getSeriationHistorySlice = (state: RootState) => state.SeriationHistory;
