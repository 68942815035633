import axios from 'axios';

import Endpoints from 'api/endpoints';

import { IApiCall } from '../index';

const postFiles = (studentId, registrationId, data, config) => {
  return axios
    .post(Endpoints.files.postFiles(studentId, registrationId), data, config)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getFile = (fileType: string, fileId: string, expires: string, signature: string) => {
  return axios
    .get(Endpoints.files.getFile(fileType, fileId, expires, signature))
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

const getFileOnTab = (url: string) => {
  return axios
    .get(url)
    .then((res) => {
      // res.data is in base64
      const byteCharacters = atob(res.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], { type: 'application/pdf;base64' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      return res.data;
    })
    .catch((err) => {
      console.log('err', err);
      throw err;
    });
};

const getFileUint8Array = (url: string) => {
  return axios
    .get(url)
    .then((res) => {
      // res.data is in base64
      const byteCharacters = atob(res.data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      return new Uint8Array(byteNumbers);
    })
    .catch((err) => {
      throw err;
    });
};

export default {
  postFiles: {
    call: postFiles,
    scope: 'paco_2.0_secvirtual_post_file',
  } as IApiCall,
  getFile: {
    call: getFile,
    scope: 'paco_2.0_secvirtual_get_file',
  } as IApiCall,
  getFileOnTab: {
    call: getFileOnTab,
    scope: 'paco_2.0_secvirtual_get_file',
  } as IApiCall,
  getFileUint8Array: {
    call: getFileUint8Array,
    scope: 'paco_2.0_secvirtual_get_file',
  } as IApiCall,
};
