import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IGradeDataItem, IHDADOS, IGradeDetail} from './types';

interface IinitialState {
  gradesData: IGradeDataItem[];
  gradeDetails: IGradeDetail;
  HDADOS: IHDADOS;
  FDADOS: {};
  Dicionarios: {};
  regimes: {}
  validGradeValues: number[];
  gradeEdits: Record<string, string>;
  csvFile: File | null;
  isStatisticsModalOpen: boolean;
  isCSVModalOpen: boolean;
  isLabelsModalOpen: boolean;
  dropdownValueSelected: string;
  isConfirmPageLeaveModalOpen: boolean;
  isErrorsModalOpen: boolean;
  importErrors: [];
  importWarnings: {};
  isConfirmDiscardChangesModalOpen: boolean;
  examDate: string | null;
  loadingStatus: {
    loadingGradeData: boolean;
    loadingTable: boolean;
    loadingCSVData: boolean,
  };
}

const initialState: IinitialState = {
  gradesData: [],
  gradeDetails: {} as IGradeDetail,
  HDADOS: {
    type: '',
    dep: '',
    codDisciplina: '',
    nomeDisciplina: '',
    ano: '',
    tipoExame: '',
    epocaExame: '',
    tipoAvaliacao: {
      idTipoAvaliacao: '',
      nomeTipoAvaliacao: '',
      tipo: '',
      gama: {}
    },
    codigoPauta: '',
    semestre: '',
    x1: '',
    x2: '',
    dataExame: '',
    x3: '',
  },
  FDADOS: {},
  Dicionarios: {},
  regimes:{}, 
  validGradeValues: [],
  gradeEdits: {},
  csvFile: null,
  isStatisticsModalOpen: false,
  isCSVModalOpen: false,
  isLabelsModalOpen: false,
  dropdownValueSelected: '',
  isConfirmPageLeaveModalOpen: false,
  isErrorsModalOpen: false,
  importErrors: [],
  importWarnings: {},
  isConfirmDiscardChangesModalOpen: false,
  examDate: null,
  loadingStatus: {
    loadingGradeData: true,
    loadingTable: true,
    loadingCSVData: true,
  }
};

export default createSlice({
  name: 'EditGrades',
  initialState,
  reducers: {
    onMount: (state, action) => {
      state.gradesData = action.payload;
    },
    onUnmount: () => { },
    reset: (state) => initialState,
    fetchGradeData: state => {},
    setGradeData: (state, action: PayloadAction<IGradeDataItem[]>) => {
      state.gradesData = action.payload;
    },
    setGradeDetails: (state, action) => {
      state.gradeDetails = action.payload;
    },
    setHDADOS: (state, action) => {
      state.HDADOS = action.payload;
    },
    setFDADOS: (state, action) => {
      state.FDADOS = action.payload;
    },
    setDicionarios: (state, action) => {
      state.Dicionarios = action.payload;
    },
    setRegimes: (state, action) => {
      state.regimes = action.payload;
    },
    setValidGradeValues: (state, action) => {
      state.validGradeValues = action.payload;
    },
    setGradeEdits: (state, action: PayloadAction<Record<string, string>>) => {
      state.gradeEdits = action.payload;
    },
    clearGradeEdits: (state) => {
      state.gradeEdits = {};
    },
    clearExamDate: (state) => {
      state.examDate = null;
    },
    uploadGrades: (state, action) => {},
    setCSVFile: (state, action: PayloadAction<File | null>) => {
      state.csvFile = action.payload;
    },
    saveOrSubmitGrades: (state, action) => {},
    setStatisticsModal: (state) => {
      state.isStatisticsModalOpen = !state.isStatisticsModalOpen;
    },
    setIsCSVModalOpen: (state) => {
      state.isCSVModalOpen = !state.isCSVModalOpen;
    },
    setIsLabelsModalOpen:(state) => {
      state.isLabelsModalOpen = !state.isLabelsModalOpen;
    },
    setDropdownValueSelected:(state, action) => {
      state.dropdownValueSelected = action.payload;
    },
    setIsConfirmPageLeaveModalOpen: (state) => {
      state.isConfirmPageLeaveModalOpen = !state.isConfirmPageLeaveModalOpen;
    },
    setIsErrorsModalOpen: (state) => {
      state.isErrorsModalOpen = !state.isErrorsModalOpen;
    },
    setImportErrors: (state, action) => {
      state.importErrors = action.payload;
    },
    setImportWarnings: (state, action) => {
      state.importWarnings = action.payload;
    },
    setIsConfirmDiscardChangesModalOpen: (state) => {
      state.isConfirmDiscardChangesModalOpen = !state.isConfirmDiscardChangesModalOpen;
    },
    setExamDate: (state, action) => {
      state.examDate = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
  },
});