/**
 * Sidebar web component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import { Icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

import { actions } from 'store/rootSlices';

interface IProps {
  isSidebarOpen: boolean;
  sidebarLinks: {
    title: string;
    collapsed: boolean;
    icon: null | Icon;
    path: string | null;
    key: string | null;
    link: string | null;
    selected: boolean;
    disabled: boolean;
    subLinks: {
      id: number;
      title: string;
      icon: null | Icon;
      collapsed?: boolean;
      path: string | null;
      link: string | null;
      selected: boolean;
      disabled: boolean;
      subLinks?: any;
    }[];
  }[];
  collapseLink: (id: number, value?: boolean) => {};
  setSkipSeletectItems: (value: boolean) => {};
  collapseSubLink: Function;
  goTo: (path: string | null, link: string | null) => {};
}

const Sidebar: React.FC<IProps> = ({
  isSidebarOpen,
  sidebarLinks,
  collapseLink,
  collapseSubLink,
  goTo,
  setSkipSeletectItems,
}) => {
  const dispatch = useDispatch();
  const { toggleSidebar } = actions.App;

  return (
    <Wrapper isSidebarOpen={isSidebarOpen}>
      <StickyWrapper>
        <Heightblock />
        <RefLinks>
          {sidebarLinks.map((link, index) => (
            <RefLinkItem key={`${link.title}-${index}`} isSidebarOpen={isSidebarOpen}>
              {!isSidebarOpen && link.icon && (
                <RefLinkIcon
                  disabled={link.disabled}
                  selected={link.selected}
                  collapsed={link.collapsed}
                  data-testid={`sidebar_link_title_${link.title}_${index}`}
                  onClick={() => {
                    if (link.subLinks) {
                      setSkipSeletectItems(true);
                      collapseLink(index, true);
                      dispatch(toggleSidebar());
                    } else {
                      !link.disabled && goTo(link.path, link.link);
                    }
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={link.icon} />
                  <RefLinkIconName>{link.title}</RefLinkIconName>
                </RefLinkIcon>
              )}

              {isSidebarOpen && (
                <>
                  <RefLink
                    disabled={link.disabled}
                    selected={link.selected}
                    collapsed={link.collapsed}
                    data-testid={`sidebar_link_title_${link.title}_${index}`}
                    onClick={() => {
                      link.subLinks
                        ? collapseLink(index, undefined)
                        : !link.disabled && goTo(link.path, link.link);
                    }}
                  >
                    {link.title}
                    {link.subLinks && (
                      <Angle collapsed={link.collapsed}>
                        <FontAwesomeIcon size="lg" icon={faAngleDown} />
                      </Angle>
                    )}
                  </RefLink>
                  {link.subLinks && (
                    <RefSubLinks collapsed={link.collapsed}>
                      {link.subLinks.map((subLink, subLinkIndex) => (
                        <React.Fragment key={`${subLink.title}-${subLinkIndex}`}>
                          <RefSubLink
                            collapsed={link.collapsed}
                            disabled={subLink.disabled}
                            selected={subLink.selected}
                            data-testid={`sidebar_sublink_title_${subLink.title}_${subLinkIndex}`}
                            onClick={() => {
                              subLink.subLinks
                                ? collapseSubLink(index, subLinkIndex)
                                : !subLink.disabled && goTo(subLink.path, subLink.link);
                            }}
                          >
                            {subLink.title}
                            {subLink.subLinks && (
                              <Angle collapsed={subLink.collapsed ? true : false}>
                                <FontAwesomeIcon size="lg" icon={faAngleDown} />
                              </Angle>
                            )}
                          </RefSubLink>
                          {subLink.subLinks &&
                            subLink.collapsed &&
                            subLink.subLinks.map((subSubLink, i) => (
                              <RefSubLink
                                key={`${subSubLink.title}-${subLinkIndex}-${i}`}
                                collapsed={subLink.collapsed ? true : false}
                                disabled={subSubLink.disabled}
                                selected={subSubLink.selected}
                                lighter
                                data-testid={`sidebar_sublink_title_${subSubLink.title}_${subLinkIndex}_${i}`}
                                onClick={() => {
                                  !subSubLink.disabled && goTo(subSubLink.path, subSubLink.link);
                                }}
                              >
                                {subSubLink.title}
                              </RefSubLink>
                            ))}
                        </React.Fragment>
                      ))}
                    </RefSubLinks>
                  )}
                </>
              )}
            </RefLinkItem>
          ))}
        </RefLinks>
      </StickyWrapper>
    </Wrapper>
  );
};

export default Sidebar;

const Wrapper = styled.div<{ isSidebarOpen: boolean }>`
  width: ${({ theme }) => theme.sidebar.width};
  min-width: ${({ theme }) => theme.sidebar.width};
  margin-left: ${({ theme, isSidebarOpen }) =>
    isSidebarOpen
      ? '0'
      : `-${parseInt(theme.sidebar.width) - parseInt(theme.sidebar.collapsedWidth)}px`};
  z-index: 900;
  transition: margin 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.plusDarkGrey};
  position: relative;
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.header.height};
  overflow-y: auto;
`;

const Heightblock = styled.div`
  height: 24px;
  width: 100%;
`;

const RefLinks = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: visible;
  max-height: calc(90vh - 36px);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;
`;

const RefLinkItem = styled.div<{ isSidebarOpen: boolean }>`
  display: flex;
  flex-direction: column;
  overflow: visible;
  align-items: ${({ isSidebarOpen }) => (isSidebarOpen ? 'unset' : 'flex-end')};
`;

interface IRefLink {
  selected: boolean;
  collapsed: boolean;
  disabled: boolean;
}

const RefLink = styled.div<IRefLink>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 48px;
  cursor: pointer;
  padding: 0 16px 0 40px;
  font-size: 14px;
  font-weight: ${({ collapsed, selected }) => (collapsed || selected ? '500' : '400')};
  color: ${({ theme, selected, collapsed }) =>
    selected || collapsed
      ? selected
        ? theme.colors.white
        : theme.colors.white
      : theme.colors.regularGrey};
  text-transform: uppercase;

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.white};
  }

  &:after {
    content: '';
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.colors.primary : `rgba(0,0,0,0.5)`};
    cursor: not-allowed;
    position: absolute;
    left: 0;
    width: ${({ disabled }) => (!disabled ? `4px` : `100%`)};
    transform: scaleY(${({ selected, disabled }) => (!disabled ? (selected ? '1' : '0') : '1')});
    position: absolute;
    top: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    bottom: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    transition: transform 0.2s ease-in-out;
  }
`;

const RefLinkIconName = styled.div`
  display: flex;
  justify-contente: center;
  align-items: center;
  position: absolute;
  left: ${({ theme }) => theme.sidebar.collapsedWidth};
  height: 48px;
  background-color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
  font-weight: 300;
  text-transform: capitalize;
  padding: 12px 18px;
  border-left: 1px solid ${({ theme }) => theme.colors.darkGrey};
  white-space: nowrap;
`;

const RefLinkIcon = styled.div<IRefLink>`
  display: flex;
  width: ${({ theme }) => theme.sidebar.collapsedWidth};
  align-items: center;
  justify-content: center;
  position: relative;
  height: 48px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: ${({ collapsed, selected }) => (collapsed || selected ? '500' : '400')};
  color: ${({ theme, selected, collapsed }) =>
    selected || collapsed ? theme.colors.primary : theme.colors.white};

  text-transform: uppercase;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black};

    ${RefLinkIconName} {
      display: flex;
    }
  }

  ${RefLinkIconName} {
    display: none;
    color: ${({ theme, selected }) => (selected ? theme.colors.primary : theme.colors.white)};
  }
`;

const Angle = styled.div<{ collapsed: boolean }>`
  transform: ${({ collapsed }) => (collapsed ? 'rotate(180deg)' : 'rotate(0deg);')};
  transition: transform 0.4s ease-in-out;
`;

const RefSubLinks = styled.div<{ collapsed: boolean }>`
  display: flex;
  visibility: ${({ collapsed }) => (collapsed ? 'visible' : 'hidden')};
  flex-direction: column;
  opacity: ${({ collapsed }) => (collapsed ? '1' : '0')};
  background-color: ${({ theme }) => theme.colors.sideBar.subItemColor};
  text-transform: lowercase;
  transition: opacity 0.1s ease-in-out;
`;

interface IRefSubLink {
  collapsed: boolean;
  selected: boolean;
  disabled: boolean;
  lighter?: boolean;
}

const RefSubLink = styled.div<IRefSubLink>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: ${({ collapsed }) => (collapsed ? '48px' : '0')};
  transition: height 0.2s ease-in-out, opacity 0.1s ease-in-out;
  opacity: ${({ collapsed }) => (collapsed ? '1' : '0')};
  font-size: 14px;
  transition: height 0.2s ease-in-out, opacity 0.1s ease-in-out;
  cursor: pointer;
  font-weight: 300;
  padding: 0 16px 0 40px;
  color: ${({ theme, selected, lighter }) =>
    selected ? theme.colors.white : lighter ? theme.colors.regularGrey : theme.colors.darkGrey};
  background-color: ${({ theme, lighter }) =>
    lighter ? theme.colors.sideBar.subSubItemColor : 'transparent'};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.white};
  }

  &:after {
    content: '';
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.colors.primary : `rgba(0,0,0,0.4)`};
    cursor: not-allowed;
    position: absolute;
    left: 0;
    width: ${({ disabled }) => (!disabled ? `4px` : `100%`)};
    transform: scaleY(${({ selected, disabled }) => (!disabled ? (selected ? '1' : '0') : '1')});
    position: absolute;
    top: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    bottom: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    transition: transform 0.2s ease-in-out;
  }
`;
