/**
 * Home scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  markdown: {
    value: '',
    valueEn: '',
    valueCh: '',
  } as {
    value: string;
    valueEn: string;
    valueCh: string;
  },
  loadingMarkdown: true as boolean,
};

export default createSlice({
  name: 'SchedulesGuide',
  initialState,
  reducers: {
    onMount: (state, action: PayloadAction<string>) => {},
    onUnmount: () => {},
    setMarkdown: (state, action: PayloadAction<any>) => {
      state.markdown = action.payload;
    },
    setLoadingMarkdown: (state, action: PayloadAction<boolean>) => {
      state.loadingMarkdown = action.payload;
    },
    reset: (state) => initialState,
  },
});
