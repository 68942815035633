/**
 * CertificatesList scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IParams } from 'api';

interface ICertificate {
  id: number;
  theme: 'Certidões - De aprovação' | string;
  type: 'Certidões - Certidão de situação de prescrição' | string;
  requestDate: string;
  format: 'digitalFormat' | string;
  state: 'awaitingProcessing' | string;
  language: 'en' | 'pt' | string;
  ready: boolean;
  registrationId: number;
  responseDate: string | null;
  isExempt: boolean;
  exemptionMotive: string;
  accessKey: string;
  expirationDate: string | null;
  paymentState: string | null;
}

const initialState = {
  certificatesList: [] as ICertificate[],
  certificatesFilters: [] as any[],
  requestParams: {} as IParams,
  showAccesskeyModal: { status: false, accessKey: '' },
  loadingStatus: {
    loading: true as boolean,
    loadingCertificatesList: true as boolean,
  },
  errorStatus: {
    errLoading: false,
    errLoadingCertificateList: false,
  },
};

export default createSlice({
  name: 'CertificatesList',
  initialState,
  reducers: {
    onMount: () => {},
    getData: (state, action: PayloadAction<{} | undefined>) => {},
    setCertificatesList: (state, action: PayloadAction<ICertificate[]>) => {
      state.certificatesList = action.payload;
    },
    setRequestParams: (state, action: PayloadAction<IParams>) => {
      state.requestParams = { ...state.requestParams, ...action.payload };
    },
    setCertificatesFilters: (state, action: PayloadAction<any[]>) => {
      state.certificatesFilters = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setShowAccessKeyModal: (
      state,
      action: PayloadAction<{ status: boolean; accessKey: string }>
    ) => {
      state.showAccesskeyModal = action.payload;
    },
    reset: (state) => initialState,
    onUnmount: () => {},
  },
});
