/**
 * Toaster container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import React, { useContext, useEffect, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { ResponsiveLayout } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { Message, Transition } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle, faInfoCircle, faTimesSquare } from '@fortawesome/pro-light-svg-icons';

const Toaster: React.FC = () => {
  const timeout = useRef<any>(null);
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { showing, toast } = useSelector(selectors.getToasterSlice);

  const { title, body, icon, type } = toast;
  const { closeToaster } = actions.Toaster;

  const getIcon = () => {
    switch (icon) {
      case 'check':
        return faCheckCircle;
      case 'info':
        return faInfoCircle;
      case 'error':
        return faTimesSquare;
      default:
        return faInfoCircle;
    }
  };

  const getTypeColor = () => {
    switch (type) {
      case 'success':
        return theme.colors.successGreen;
      case 'danger':
        return theme.colors.dangerRed;
      case 'warning':
        return theme.colors.decisionYellow;
      case 'info':
        return theme.colors.regularGrey;
      default:
        return theme.colors.regularGrey;
    }
  };

  useEffect(() => {
    if (showing) {
      if (timeout.current !== null) {
        clearTimeout(timeout.current);
        timeout.current = null;
      }
      timeout.current = setTimeout(() => {
        clearTimeout(timeout.current);
        timeout.current = null;
        dispatch(closeToaster());
      }, 8000);
    }
  }, [showing]);

  const ToastContent = (
    <>
      <CloseIconWrapper onClick={() => dispatch(closeToaster())}>
        <FontAwesomeIcon icon={faTimes} />
      </CloseIconWrapper>
      <MessageWrapper>
        <TitleWrapper>
          <IconWrapper color={getTypeColor()}>
            <FontAwesomeIcon icon={getIcon()} />
          </IconWrapper>
          <MessageTitle color={getTypeColor()}>
            <span>{title}</span>
          </MessageTitle>
        </TitleWrapper>
        {body && (
          <MessageBody color={getTypeColor()}>
            <p>{body}</p>
          </MessageBody>
        )}
      </MessageWrapper>
    </>
  );

  return (
    <Transition visible={showing} animation="fade down" duration={500}>
      <Wrapper toastsize="584px">
        <Toast toastsize="584px">{ToastContent}</Toast>
      </Wrapper>
    </Transition>
  );
};

export default Toaster;

const Wrapper = styled.div<{ toastsize: string }>`
  position: absolute;
  top: 0;
  left: calc(50% - ${({ toastsize }) => toastsize} / 2);
  z-index: 9999;
`;

const Toast = styled(Message)<{ toastsize: string }>`
  &&& {
    width: ${({ toastsize }) => toastsize};
    margin: 0;
    padding: 32px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.regularGrey};
    border-radius: 0;
    box-shadow: ${({ theme }) => theme.shadows.strong};
    z-index: 9999;
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 13px;
  right: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 18px;
  cursor: pointer;
`;

const MessageWrapper = styled.div``;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 15px;
  }
`;

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  color: ${({ color }) => color};
  font-size: 48px;
`;

const MessageTitle = styled.div`
  margin-left: 24px;
  color: ${({ color }) => color};
`;

const MessageBody = styled.div`
  margin-left: 72px;
  color: ${({ theme }) => theme.colors.blackLight};
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizes.small};
`;
