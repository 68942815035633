/**
 * Payments Table container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

import { MbIcon, MbWayIcon /*, VisaIcon*/ } from 'shared/components/Icons';

import { Table, Button, Text, StatusLabel, Modal } from '@paco_ua/pacoui';
import styled from 'styled-components';

import { DEFAULT_DEBITS_PARAMS } from 'scenes/VirtualSecretary/Payments/utils';

import {
  IDebitStatus,
  PAYMENT_METHOD,
  PENDING_DEBIT_STATE,
} from 'scenes/VirtualSecretary/Payments/types';

import API from 'api';

import {
  IPendingDebitsTableProps,
  ITranslations,
  IPendingDebitsEntry,
} from 'scenes/VirtualSecretary/Payments/types';

import useTable from './useTable';

import { actions } from 'store/rootSlices';

const DEBIT_STATUS: IDebitStatus = {
  UNPAID: 'unpaid',
  IN_DEBIT: 'inDebit',
  AWAITING_PAYMENT: 'awaitingPayment',
  IN_DEBIT_AWAITING_PAYMENT: 'inDebitAwaitingPayment',
};

const DEBIT_STATUS_COLOR = (theme): { [key: string]: string } => ({
  [DEBIT_STATUS.UNPAID]: theme.colors.darkGrey,
  [DEBIT_STATUS.IN_DEBIT]: theme.colors.dangerRed,
  [DEBIT_STATUS.AWAITING_PAYMENT]: theme.colors.decisionYellow,
  [DEBIT_STATUS.IN_DEBIT_AWAITING_PAYMENT]: theme.colors.decisionYellow,
});

const ASSOCIATED_COSTS_TYPES = {
  lateFeeCost: 'days',
  ucCost: 'ucs',
  certCost: 'copies',
  mailCost: 'mailCost',
};

const getMultipaymentTableRows = (
  t,
  theme,
  multipayment: IPendingDebitsEntry,
  openMbInfoModal,
  remainingTime,
  dispatch,
  loadingCancel,
  setLoadingCancel,
  registrationId,
  setIsCancelPaymentsModalOpened,
  isCancelPaymentsModalOpened
) => {
  let paymentRows = [] as any;

  paymentRows.push({
    rowProps: {
      navigatable: true,
    },
    //collapsableOpen: true,
    cells: [
      {
        content: (
          <CellWrapperMultiPayment>
            <PaymentIdText weight="medium" size="article">
              {t('payments.multipayment', { textOnly: true })}
            </PaymentIdText>
          </CellWrapperMultiPayment>
        ),
        cellProps: {
          colSpan: 1,
          width: 5,
          singleLine: false,
          style: {
            justifyContent: 'left',
          },
        },
      },
      {
        content: (
          <CellWrapper>
            <PaymentIdText weight="regular" size="article">
              {`${t('generic.euros', {
                textOnly: true,
                count: multipayment.totalValue,
              })}`}
            </PaymentIdText>
          </CellWrapper>
        ),
        cellProps: {
          width: 2,
          colSpan: 1,
          singleLine: false,
        },
      },
      {
        content: (
          <CellWrapper>
            <PaymentIdText weight="regular" size="article">
              {moment(multipayment.dueDate).format('DD-MM-YYYY')}
            </PaymentIdText>
          </CellWrapper>
        ),
        cellProps: {
          colSpan: 1,
          singleLine: false,
        },
      },
      {
        content: (
          <CellWrapper>
            <StatusLabel
              background={
                multipayment.status === PENDING_DEBIT_STATE.awaitingPayment
                  ? theme.colors.decisionYellow
                  : theme.colors.successGreen
              }
              label={
                <Text size="xSmall" weight="medium">
                  {t(`payments.${multipayment.status}`, { textOnly: true })}
                </Text>
              }
            />
          </CellWrapper>
        ),
        cellProps: {
          colSpan: 1,
          singleLine: false,
        },
      },
      {
        content: (
          <ButtonCellWrapper>
            {multipayment.paymentMethod === PAYMENT_METHOD.mb ? (
              <ShowButton
                className={'rowBtn'}
                color={theme.colors.plusDarkGrey}
                onClick={() => {
                  openMbInfoModal(multipayment.paymentRequestId);
                }}
                leftIcon={<FontAwesomeIcon icon={faEye} />}
                action
                //disabled={disableButtons}
              >
                {t('payments.show', { textOnly: true })}
              </ShowButton>
            ) : (
              <></>
            )}
          </ButtonCellWrapper>
        ),
        cellProps: {
          colSpan: 2,
          singleLine: false,
        },
      },
    ],
    collapsableRows: multipayment.debits.reduce((acc, curDebit) => {
      let debitRows = [] as any;

      // main debit collapsed row
      debitRows.push({
        rowProps: {
          bgColor: theme.colors.softGrey,
          disableHover: true,
        },
        cells: [
          {
            content: (
              <Text color="plusDarkGrey" size="article" weight="medium">
                {curDebit.description}
              </Text>
            ),
            cellProps: { colSpan: 1, singleLine: false },
          },
          {
            content: (
              <CellWrapper>
                <Text color="plusDarkGrey" size="article" weight="regular">
                  {`${t('generic.euros', {
                    textOnly: true,
                    count: curDebit.value.toFixed(2),
                  })}`}
                </Text>
              </CellWrapper>
            ),
            cellProps: { colSpan: 1, singleLine: false },
          },
          {
            content: (
              <CellWrapper>
                <Text
                  color={DEBIT_STATUS_COLOR(theme)[curDebit.debitStatus]}
                  size="article"
                  weight="regular"
                >
                  {moment(curDebit.dueDate).format('DD-MM-YYYY')}
                </Text>
              </CellWrapper>
            ),
            cellProps: { colSpan: 1, singleLine: false },
          },
          {
            content: '',
            cellProps: { colSpan: '100%', singleLine: false },
          },
        ],
      });

      if (multipayment.debits[0].associatedCosts !== null && curDebit.associatedCosts !== null) {
        // sub debit collapsed row with the associated costs
        curDebit.associatedCosts.map((costEntry) => {
          debitRows.push({
            rowProps: {
              bgColor: theme.colors.softLightGrey,
              disableHover: true,
            },
            cells: [
              {
                content: (
                  <ExtraFeeWrapper>
                    <ExtraFeeCellWrapper>
                      {ASSOCIATED_COSTS_TYPES[costEntry.type] ? (
                        <Text color="plusDarkGrey" size="small" weight="medium">
                          {t(`payments.${costEntry.type}`, { textOnly: true })}
                        </Text>
                      ) : (
                        <Text>{costEntry.type}</Text>
                      )}
                    </ExtraFeeCellWrapper>
                    {costEntry.type !== ASSOCIATED_COSTS_TYPES.mailCost &&
                      ASSOCIATED_COSTS_TYPES[costEntry.type] && (
                        <ExtraFeeValueCellWrapper>
                          <Text color="plusDarkGrey" size="xSmall" weight="regular">
                            {`${t(`payments.${ASSOCIATED_COSTS_TYPES[costEntry.type]}`, {
                              textOnly: true,
                              count: costEntry.copies,
                            })}`}
                          </Text>
                        </ExtraFeeValueCellWrapper>
                      )}
                  </ExtraFeeWrapper>
                ),
                cellProps: { colSpan: 1, singleLine: false },
              },
              {
                content: (
                  <CostCellWrapper>
                    <Text color="plusDarkGrey" size="article" weight="regular">
                      {`${t('generic.euros', {
                        textOnly: true,
                        count: costEntry.value.toFixed(2),
                      })}`}
                    </Text>
                  </CostCellWrapper>
                ),
                cellProps: { colSpan: 1, singleLine: false },
              },
              {
                content: '',
                cellProps: {
                  colSpan: '100%',
                  singleLine: false,
                },
              },
            ],
          });
        });
      }

      return acc.concat(debitRows);
    }, []),
  });

  if (multipayment.status === PENDING_DEBIT_STATE.awaitingPayment) {
    paymentRows.push({
      rowProps: {
        disableHover: true,
        style: {
          backgroundColor: theme.colors.plusDarkGrey,
        },
      },
      cells: [
        {
          content: (
            <CellWrapperPayIconMethod>
              {multipayment.paymentMethod === PAYMENT_METHOD.mb && (
                <MbIcon fill={theme.colors.white} width={25} height={25} />
              )}
              {multipayment.paymentMethod === PAYMENT_METHOD.mbway && (
                <MbWayIcon fill={theme.colors.white} width={42} height={42} />
              )}
              <Text color="white" size="article" weight="medium">
                {t(`payments.${multipayment.paymentMethod}`, { textOnly: true })}
              </Text>
            </CellWrapperPayIconMethod>
          ),
          cellProps: {
            colSpan: 2,
            singleLine: false,
            style: {
              backgroundColor: theme.colors.plusDarkGrey,
              style: {
                justifyContent: 'left',
              },
            },
          },
        },
        // {
        //   content: (
        //     <CellWrapperPayMethod>
        //       <>
        //         <TimeLimitText color="grey" size="article" weight="medium">
        //           {t(`payments.${multipayment.paymentMethod}Limit`, { textOnly: true })}
        //         </TimeLimitText>
        //       </>
        //     </CellWrapperPayMethod>
        //   ),
        //   cellProps:{
        //     colspan: 2,
        //     singleLine: false,
        //     style: {
        //       backgroundColor: theme.colors.plusDarkGrey,
        //     },
        //   },
        // },
        {
          // LIMIT DATE CELL
          content: (
            <CellWrapperPayMethod>
              <>
                <TimeLimitText color="grey" size="article" weight="medium">
                  {t(`payments.${multipayment.paymentMethod}Limit`, { textOnly: true })}
                </TimeLimitText>
                <Text color="white" size="article" weight="medium">
                  {multipayment.paymentMethod === PAYMENT_METHOD.mb &&
                    moment(multipayment.dueDate).format('DD-MM-YYYY | HH:mm')}
                  {multipayment.paymentMethod === PAYMENT_METHOD.mbway &&
                    (moment(remainingTime).toDate().getSeconds() > 0 ||
                      moment(remainingTime).toDate().getMinutes() > 0) &&
                    moment(remainingTime).format('mm:ss')}
                  {multipayment.paymentMethod === PAYMENT_METHOD.mbway &&
                    moment(remainingTime).toDate().getSeconds() === 0 &&
                    moment(remainingTime).toDate().getMinutes() === 0 &&
                    t('payments.expired', { textOnly: true })}
                </Text>
              </>
            </CellWrapperPayMethod>
          ),
          cellProps: {
            colSpan:
              multipayment.paymentMethod === PAYMENT_METHOD.mb
                ? 4
                : multipayment.debits[0].associatedCosts === null
                ? '100%'
                : '100%',
            singleLine: false,
            style: {
              backgroundColor: theme.colors.plusDarkGrey,
              style: {
                justifyContent: 'right',
              },
            },
          },
        },
      ],
    });
  }

  if (multipayment.paymentMethod === PAYMENT_METHOD.mb) {
    paymentRows[paymentRows.length - 1].cells.push({
      content: (
        <ButtonCellWrapper>
          {<></>}
          {/* <Modal
            type="confirmation"
            open={
              isCancelPaymentsModalOpened.status === true &&
              isCancelPaymentsModalOpened.id === multipayment.paymentRequestId
            }
            onClose={() =>
              dispatch(
                setIsCancelPaymentsModalOpened({
                  id: multipayment.paymentRequestId,
                  status: false,
                })
              )
            }
            submitButtonText={t('generic.accept', { textOnly: true })}
            cancelButtonText={t('generic.reject', { textOnly: true })}
            onCancel={() =>
              dispatch(
                setIsCancelPaymentsModalOpened({
                  id: multipayment.paymentRequestId,
                  status: false,
                })
              )
            }
            header={
              <Title>
                <Text size="xLarge" weight="medium" color="plusDarkGrey">
                  {t('payments.cancelPayment', { textOnly: true })}
                </Text>
              </Title>
            }
            onSubmit={() => {
              setLoadingCancel((currentValues) => {
                return { ...currentValues, [multipayment.paymentRequestId]: true };
              });

              try {
                if (!loadingCancel[multipayment.paymentRequestId]) {
                  API.secVirtual.deletePaymentMb
                    .call({ paymentRequestId: multipayment.paymentRequestId, registrationId })
                    .then((value) => {
                      dispatch(
                        actions.Toaster.showToaster({
                          title: t('secVirtualNotifications.payments_successDeletingDebit'),
                          icon: 'check',
                          type: 'success',
                        })
                      );

                      dispatch(actions.Payments.fetchPendingDebits());
                      dispatch(actions.Payments.fetchDebits(DEFAULT_DEBITS_PARAMS));

                      setLoadingCancel((currentValues) => {
                        return { ...currentValues, [multipayment.paymentRequestId]: false };
                      });
                    });
                }
              } catch (e) {
                setLoadingCancel((currentValues) => {
                  return { ...currentValues, [multipayment.paymentRequestId]: false };
                });
                dispatch(
                  actions.Toaster.showToaster({
                    title: t('secVirtualNotifications.payments_errorDeletingDebit'),
                    icon: 'error',
                    type: 'danger',
                  })
                );
              }
            }}
          /> */}
        </ButtonCellWrapper>
      ),
      cellProps: {
        colSpan: '100%',
        singleLine: false,
        style: {
          backgroundColor: theme.colors.plusDarkGrey,
        },
      },
    });
  }

  return paymentRows;
};

const getSinglepaymentTableRows = (
  t,
  theme,
  multipayment: IPendingDebitsEntry,
  openMbInfoModal,
  remainingTime,
  dispatch,
  loadingCancel,
  setLoadingCancel,
  registrationId,
  isCancelPaymentsModalOpened,
  setIsCancelPaymentsModalOpened
) => {
  let paymentRows = [] as any;

  paymentRows.push({
    rowProps: {
      navigatable: multipayment.debits[0].associatedCosts === null ? false : true,
      disableHover: multipayment.debits[0].associatedCosts === null ? true : false,
    },
    //collapsableOpen: true,
    cells: [
      {
        content: (
          <Text color="plusDarkGrey" size="article" weight="medium">
            {multipayment.debits[0].description}
          </Text>
        ),
        cellProps: { colSpan: 1, singleLine: false },
      },
      {
        content: (
          <CellWrapper>
            <Text color="plusDarkGrey" size="article" weight="regular">
              {`${t('generic.euros', {
                textOnly: true,
                count: multipayment.debits[0].value.toFixed(2),
              })}`}
            </Text>
          </CellWrapper>
        ),
        cellProps: { colSpan: 1, width: 2, singleLine: false },
      },
      {
        content: (
          <CellWrapper>
            <Text
              color={DEBIT_STATUS_COLOR(theme)[multipayment.debits[0].debitStatus]}
              size="article"
              weight="regular"
            >
              {moment(multipayment.debits[0].dueDate).format('DD-MM-YYYY')}
            </Text>
          </CellWrapper>
        ),
        cellProps: { colSpan: 1, singleLine: false },
      },
      {
        content: (
          <LabelCellWrapper>
            <StatusLabel
              background={
                multipayment.status === PENDING_DEBIT_STATE.awaitingPayment
                  ? theme.colors.decisionYellow
                  : theme.colors.successGreen
              }
              label={
                <Text size="xSmall" weight="medium">
                  {t(`payments.${multipayment.status}`, { textOnly: true })}
                </Text>
              }
            />
          </LabelCellWrapper>
        ),
        cellProps: {
          colSpan: 1,
          singleLine: false,
        },
      },
      {
        content: (
          <ButtonCellWrapper>
            {multipayment.paymentMethod === PAYMENT_METHOD.mb ? (
              <ShowButton
                className={'rowBtn'} // class name used to not affect the dropdown open/close which contains the button
                color={theme.colors.plusDarkGrey}
                onClick={(event) => {
                  openMbInfoModal(multipayment.paymentRequestId);
                }}
                leftIcon={<FontAwesomeIcon icon={faEye} />}
                action
                //disabled={disableButtons}
              >
                {t('payments.show', { textOnly: true })}
              </ShowButton>
            ) : (
              <></>
            )}
          </ButtonCellWrapper>
        ),
        cellProps: {
          colSpan:
            multipayment.debits[0].associatedCosts !== null
              ? multipayment.paymentMethod === PAYMENT_METHOD.mbway
                ? 2
                : 2
              : '100%',
          singleLine: false,
        },
      },
    ],
    collapsableRows: multipayment.debits.reduce((acc, curDebit) => {
      let debitRows = [] as any;

      if (multipayment.debits[0].associatedCosts !== null) {
        // sub debit collapsed row with the associated costs
        multipayment.debits[0].associatedCosts.map((costEntry) => {
          debitRows.push({
            rowProps: {
              bgColor: theme.colors.softLightGrey,
              disableHover: true,
            },
            cells: [
              {
                content: (
                  <ExtraFeeWrapper>
                    <ExtraFeeCellWrapper>
                      {ASSOCIATED_COSTS_TYPES[costEntry.type] ? (
                        <Text color="plusDarkGrey" size="small" weight="medium">
                          {t(`payments.${costEntry.type}`, { textOnly: true })}
                        </Text>
                      ) : (
                        <Text>{costEntry.type}</Text>
                      )}
                    </ExtraFeeCellWrapper>
                    {costEntry.type !== ASSOCIATED_COSTS_TYPES.mailCost &&
                      ASSOCIATED_COSTS_TYPES[costEntry.type] && (
                        <ExtraFeeValueCellWrapper>
                          <Text color="plusDarkGrey" size="xSmall" weight="regular">
                            {`${t(`payments.${ASSOCIATED_COSTS_TYPES[costEntry.type]}`, {
                              textOnly: true,
                              count: costEntry.copies,
                            })}`}
                          </Text>
                        </ExtraFeeValueCellWrapper>
                      )}
                  </ExtraFeeWrapper>
                ),
                cellProps: { colSpan: 1, singleLine: false },
              },
              {
                content: (
                  <CostCellWrapper>
                    <Text color="plusDarkGrey" size="article" weight="regular">
                      {`${t('generic.euros', {
                        textOnly: true,
                        count: costEntry.value.toFixed(2),
                      })}`}
                    </Text>
                  </CostCellWrapper>
                ),
                cellProps: { colSpan: 1, singleLine: false },
              },
              {
                content: '',
                cellProps: {
                  colSpan: '100%',
                  singleLine: false,
                },
              },
            ],
          });
        });
      }

      return acc.concat(debitRows);
    }, []),
  });

  if (multipayment.status === PENDING_DEBIT_STATE.awaitingPayment) {
    paymentRows.push({
      rowProps: {
        disableHover: true,
        style: {
          backgroundColor: theme.colors.plusDarkGrey,
        },
      },
      cells: [
        {
          content: (
            <CellWrapperPayIconMethod>
              {multipayment.paymentMethod === PAYMENT_METHOD.mb && (
                <MbIcon fill={theme.colors.white} width={25} height={25} />
              )}
              {multipayment.paymentMethod === PAYMENT_METHOD.mbway && (
                <MbWayIcon fill={theme.colors.white} width={42} height={42} />
              )}
              <Text color="white" size="article" weight="medium">
                {t(`payments.${multipayment.paymentMethod}`, { textOnly: true })}
              </Text>
            </CellWrapperPayIconMethod>
          ),
          cellProps: {
            colSpan: 2,
            singleLine: false,
            style: {
              backgroundColor: theme.colors.plusDarkGrey,
              style: {
                justifyContent: 'left',
              },
            },
          },
        },
        // {
        //   content: (
        //     <CellWrapperPayMethod>
        //       <>
        //         <TimeLimitText color="grey" size="article" weight="medium">
        //           {t(`payments.${multipayment.paymentMethod}Limit`, { textOnly: true })}
        //         </TimeLimitText>
        //       </>
        //     </CellWrapperPayMethod>
        //   ),
        //   cellProps:{
        //     colspan: 2,
        //     singleLine: false,
        //     style: {
        //       backgroundColor: theme.colors.plusDarkGrey,
        //     },
        //   },
        // },
        {
          // LIMIT DATE CELL
          content: (
            <CellWrapperPayMethod>
              <>
                <TimeLimitText color="grey" size="article" weight="medium">
                  {t(`payments.${multipayment.paymentMethod}Limit`, { textOnly: true })}
                </TimeLimitText>
                <Text color="white" size="article" weight="medium">
                  {multipayment.paymentMethod === PAYMENT_METHOD.mb &&
                    moment(multipayment.dueDate).format('DD-MM-YYYY | HH:mm')}
                  {multipayment.paymentMethod === PAYMENT_METHOD.mbway &&
                    (moment(remainingTime).toDate().getSeconds() > 0 ||
                      moment(remainingTime).toDate().getMinutes() > 0) &&
                    moment(remainingTime).format('mm:ss')}
                  {multipayment.paymentMethod === PAYMENT_METHOD.mbway &&
                    moment(remainingTime).toDate().getSeconds() === 0 &&
                    moment(remainingTime).toDate().getMinutes() === 0 &&
                    t('payments.expired', { textOnly: true })}
                </Text>
              </>
            </CellWrapperPayMethod>
          ),
          cellProps: {
            colSpan:
              multipayment.paymentMethod === PAYMENT_METHOD.mb
                ? 5
                : multipayment.debits[0].associatedCosts === null
                ? '100%'
                : '100%',
            singleLine: false,
            style: {
              backgroundColor: theme.colors.plusDarkGrey,
              style: {
                justifyContent: 'right',
              },
            },
          },
        },
      ],
    });

    // if (paymentRows && paymentRows.length > 0 && multipayment.paymentMethod === PAYMENT_METHOD.mb) {
    //   paymentRows[paymentRows.length - 1].cells.push({
    //     content: (
    //       <ButtonCellWrapper>
    //         { (
    //           <></>
    //         )}
    //         {/* <Modal
    //           type="confirmation"
    //           open={
    //             isCancelPaymentsModalOpened.status === true &&
    //             isCancelPaymentsModalOpened.id === multipayment.paymentRequestId
    //           }
    //           onSubmit={(event) => {
    //             setLoadingCancel((currentValues) => {
    //               return { ...currentValues, [multipayment.paymentRequestId]: true };
    //             });

    //             try {
    //               if (!loadingCancel[multipayment.paymentRequestId]) {
    //                 API.secVirtual.deletePaymentMb
    //                   .call({ paymentRequestId: multipayment.paymentRequestId, registrationId })
    //                   .then((value) => {
    //                     dispatch(
    //                       actions.Toaster.showToaster({
    //                         title: t('secVirtualNotifications.payments_successDeletingDebit'),
    //                         icon: 'check',
    //                         type: 'success',
    //                       })
    //                     );

    //                     setLoadingCancel((currentValues) => {
    //                       return { ...currentValues, [multipayment.paymentRequestId]: false };
    //                     });

    //                     dispatch(actions.Payments.fetchPendingDebits());
    //                     dispatch(actions.Payments.fetchDebits(DEFAULT_DEBITS_PARAMS));
    //                   });
    //               }
    //             } catch (e) {
    //               setLoadingCancel((currentValues) => {
    //                 return { ...currentValues, [multipayment.paymentRequestId]: false };
    //               });
    //               dispatch(
    //                 actions.Toaster.showToaster({
    //                   title: t('secVirtualNotifications.general_errorDeletingPaymentRequest'),
    //                   icon: 'error',
    //                   type: 'danger',
    //                 })
    //               );
    //             }
    //           }}
    //           onClose={() =>
    //             dispatch(
    //               setIsCancelPaymentsModalOpened({
    //                 id: multipayment.paymentRequestId,
    //                 status: false,
    //               })
    //             )
    //           }
    //           submitButtonText={t('payments.yes', { textOnly: true })}
    //           cancelButtonText={t('payments.no', { textOnly: true })}
    //           onCancel={() =>
    //             dispatch(
    //               setIsCancelPaymentsModalOpened({
    //                 id: multipayment.paymentRequestId,
    //                 status: false,
    //               })
    //             )
    //           }
    //           header={
    //             <Title>
    //               <Text size="xLarge" weight="medium" color="plusDarkGrey">
    //                 {t('payments.cancelPayment', { textOnly: true })}
    //               </Text>
    //             </Title>
    //           }
    //         /> */}
    //       </ButtonCellWrapper>
    //     ),
    //     cellProps: {
    //       colSpan: '100%',
    //       singleLine: false,
    //       style: {
    //         backgroundColor: theme.colors.plusDarkGrey,
    //       },
    //     },
    //   });
    // }
  }

  return paymentRows;
};

const compileTableRow = (
  multipayment: IPendingDebitsEntry,
  theme: any,
  t: ITranslations,
  openMbInfoModal: any,
  remainingTime,
  dispatch,
  loadingCancel,
  setLoadingCancel,
  registrationId,
  isCancelPaymentsModalOpened,
  setIsCancelPaymentsModalOpened
) => {
  // multipayment: it can be a multipayment if IPendingDebitsEntry.debits length > 1, else it's a single payment
  if (multipayment.debits.length > 1) {
    return getMultipaymentTableRows(
      t,
      theme,
      multipayment,
      openMbInfoModal,
      remainingTime,
      dispatch,
      loadingCancel,
      setLoadingCancel,
      registrationId,
      isCancelPaymentsModalOpened,
      setIsCancelPaymentsModalOpened
    );
  } else if (multipayment.debits.length === 1) {
    return getSinglepaymentTableRows(
      t,
      theme,
      multipayment,
      openMbInfoModal,
      remainingTime,
      dispatch,
      loadingCancel,
      setLoadingCancel,
      registrationId,
      isCancelPaymentsModalOpened,
      setIsCancelPaymentsModalOpened
    );
  }
};

const PendingDebitsTable = ({ t, data, loading, openMbInfoModal }: IPendingDebitsTableProps) => {
  const [countdowns, setCountdowns] = useState({});
  const [loadingCancel, setLoadingCancel] = useState({});

  const toHHMMSS = (timeInSeconds: number) => {
    let hours = Math.floor(timeInSeconds / 3600);
    let minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
    let seconds = timeInSeconds - hours * 3600 - minutes * 60;
    let date = new Date();
    date.setHours(hours, minutes, seconds);
    return date;
  };

  useEffect(() => {
    let newCountdowns = {};
    data.forEach((debitItem) => {
      let timeList = debitItem.remainingTime.split('.');

      if (debitItem.paymentMethod === PAYMENT_METHOD.mbway) {
        newCountdowns = {
          ...newCountdowns,
          [debitItem.paymentRequestId]: moment(timeList[0], 'HH:mm:ss').toDate(),
        };
      }
    });

    setCountdowns(newCountdowns);
  }, [data]);

  useEffect(() => {
    const timer = setInterval(() => {
      let newCountdowns = {};

      Object.keys(countdowns).map((countdownKey) => {
        let remainingTimeInSeconds =
          +0 + // countdowns[countdownKey].getHours() * 60 * 60 +
          countdowns[countdownKey].getMinutes() * 60 +
          +countdowns[countdownKey].getSeconds();

        if (remainingTimeInSeconds > 0) {
          remainingTimeInSeconds -= 1;
        }

        newCountdowns[countdownKey] = toHHMMSS(remainingTimeInSeconds);
      });

      setCountdowns(newCountdowns);
    }, 1000);

    return () => (timer ? clearInterval(timer) : undefined);
  }, [countdowns]);

  const { structure } = useTable(
    t,
    data,
    compileTableRow,
    openMbInfoModal,
    countdowns,
    loadingCancel,
    setLoadingCancel
  );

  return (
    <PendingDebitsTableWrapper>
      <Table
        unstackable
        loading={loading}
        structure={structure}
        noAutoEmptyCells={true}
        translations={t}
        lockCollapsableInPlace
      />
    </PendingDebitsTableWrapper>
  );
};

export default PendingDebitsTable;

const CellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 14px;
  }
`;

const TimeLimitText = styled(Text)`
  margin-right: 8px;
`;

const CellWrapperPayIconMethod = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  svg {
    margin-right: 22px;
    margin-left: 16px;
  }
`;

const CellWrapperPayMethod = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  svg {
    margin-right: 14px;
  }
`;

const ExtraFeeWrapper = styled.div``;

/*const Button = styled(ButtonComponent)`
  width: 100%;
`;*/

const PendingDebitsTableWrapper = styled.div`
  overflow: auto;
  &&& th {
    padding-right: 14px !important;
    padding-left: 14px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-right: 14px !important;
    padding-left: 14px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }

  /*&&& tr {
       td:first-child {
         border-left: 0px;
       }
     }*/

  &&& .ui.table tfoot tr td {
    height: 45px;
  }
`;

const ShowButton = styled(Button)`
  &&& svg {
    margin-right: 0px;
  }

  width: 113px;
`;

const PaymentIdText = styled(Text)`
  line-height: inherit;
`;

const CellWrapperMultiPayment = styled.div``;

const CostCellWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonCellWrapper = styled.div`
  display: flex;
  justify-content: left;
`;

const ExtraFeeCellWrapper = styled.div`
  line-height: 1;
`;

const ExtraFeeValueCellWrapper = styled.div`
  line-height: 1;
  margin-top: 4px;
`;

const Title = styled.div`
  margin-bottom: 20px;
`;

const LabelCellWrapper = styled(CellWrapper)`
  > div {
    max-width: unset;
  }
`;
