/**
 * TitleHeader container logic selectors
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getAppSlice = (state: RootState) => state.App;
