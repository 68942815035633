export enum ECertificateState {
  ready = 'ready',
  cancelled = 'cancelled',
  pending = 'pending',
  awaitingPayment = 'awaitingPayment',
  rejected = 'rejected',
  submitted = 'submitted',
  awaitingExemptionDecision = 'awaitingExemptionDecision',
  exemptionDenied = 'exemptionDenied',
  expired = 'expired'
}
