/**
 * PendingDebitsPayment scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as selectors from './logic/selectors';

import { Text, Button } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { actions } from 'store/rootSlices';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import SelectDebits from './containers/SelectDebits';
import SelectPaymentMethod from './containers/SelectPaymentMethod';
import SubmitPaymentRequest from './containers/SubmitPaymentRequest';

import MultiPaymentsSelection from '../containers/MultiPaymentsSelection';

import { PAYMENT_METHODS } from './types';

import { yupSchema } from './validations';

const PendingDebitsPayment = ({ t }) => {
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  const { navigateTo } = actions.App;

  const {
    onMount,
    onUnmount,
    setCurrentStep,
    resetPendingDebits,
    submitMbwayDebitsRequest,
    submitMbDebitsRequest,
    setSearchFilter,
    setTypeFilter,
    fetchDebits,
    setDebitsSubmissionCompleted,
  } = actions.PendingDebitsPayment;

  const { setSelectedDebits, resetDebitsList } = actions.Payments;

  const {
    loadingStatus,
    errorStatus,
    currentStep,
    paymentMethod,
    debitsFilterTypes,
    filterParams,
    debitsSubmissionCompleted,
    allowMbwayPayment,
  } = useSelector(selectors.getPendingDebitsPaymentSlice);

  const currentLocale = useSelector(selectors.getAppSlice);
  const location = useLocation();

  useEffect(() => {
    dispatch(onMount(location.state));
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  useEffect(() => {
    if (debitsSubmissionCompleted && paymentMethod === PAYMENT_METHODS.MBWAY) {
      dispatch(setDebitsSubmissionCompleted(false));
      dispatch(navigateTo({ key: 'payments' }));
    }
  }, [debitsSubmissionCompleted]);

  const { selectedDebits, preSelectedDebits, debits } = useSelector(selectors.getPaymentsSlice);

  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema(t)),
  });

  const steps = [
    t('payments.requestFirstStep'),
    t('payments.requestSecondStep'),
    t('payments.requestThirdStep'),
  ];

  const stepStyles = {
    done: {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
      fontWeight: 500,
      border: 'none',
      borderStyle: null,
      checkTick: true,
    },
    current: {
      backgroundColor: theme.colors.lightGrey,
      color: theme.colors.primary,
      fontWeight: 500,
      border: 'top',
      borderStyle: `2px solid ${theme.colors.primary}`,
      checkTick: false,
    },
    toDo: {
      backgroundColor: 'transparent',
      color: theme.colors.regularGrey,
      fontWeight: 400,
      border: 'all',
      borderStyle: `1px solid ${theme.colors.regularGrey}`,
      checkTick: false,
    },
  };

  const onSubmitStatement = (data) => {
    if (paymentMethod === PAYMENT_METHODS.MBWAY) {
      dispatch(
        submitMbwayDebitsRequest({
          form: data,
          selectedDebits: selectedDebits,
        })
      );
    }
  };

  const getNextButtonDisabledState = (step) => {
    if (step === 1) {
      return false;
    } else if (step === 2) {
      // if method was selected
      if (paymentMethod) {
        return false;
      }
      return true;
    } else {
      return true;
    }
  };

  const closeDebitTabs = () => {
    dispatch(navigateTo({ key: 'payments' }));
  };

  const getTotalCost = (debits) => {
    if (debits.length <= 0) return 0;

    let cost = 0;

    debits.forEach((debit) => {
      cost += debit.totalValue;
    });

    return cost;
  };

  return (
    <PageStructure
      headerLeft={<Header pullBackButton backButton title={t('payments.dependentDebitsTitle')} />}
      loading={loadingStatus.loading || errorStatus.errLoadingPendingDebits}
      mainContent={
        <>
          <ApplicationStepsWrapper>
            {steps.map((step, index) => {
              if (index + 1 < currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.done.backgroundColor}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.done.color}
                      fontWeight={`${stepStyles.done.fontWeight}`}
                    >
                      {step}
                    </TabText>
                    <CheckTickWrapper>
                      <FontAwesomeIcon icon={faCheck} size={'xs'} />
                    </CheckTickWrapper>
                  </ApplicationStep>
                );
              } else if (index + 1 === currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.current.backgroundColor}
                    border={stepStyles.current.border}
                    borderStyle={stepStyles.current.borderStyle}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.current.color}
                      fontWeight={`${stepStyles.current.fontWeight}`}
                    >
                      {step}
                    </TabText>
                  </ApplicationStep>
                );
              } else if (index + 1 > currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.toDo.backgroundColor}
                    border={stepStyles.toDo.border}
                    borderStyle={stepStyles.toDo.borderStyle}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.toDo.color}
                      fontWeight={`${stepStyles.toDo.fontWeight}`}
                    >
                      {step}
                    </TabText>
                  </ApplicationStep>
                );
              }
            })}
          </ApplicationStepsWrapper>

          <>
            {currentStep === 1 && (
              <SelectDebits
                preSelectedDebits={preSelectedDebits}
                multiPaymentsComponent={
                  <MultiPaymentsSelection
                    fetchUnselectedDebits={(params) => {
                      dispatch(fetchDebits(params));
                    }}
                    setMultiple={(selected, structure) => {
                      let updatedSelected = [] as any;

                      // add preSelectedItems to the updateSelected list (these can't be removed)
                      preSelectedDebits.forEach((preSelDebit) => {
                        updatedSelected.push(preSelDebit);
                      });

                      // iterate through the 'selected' list
                      // and add to the updatedSelected list all the rows not present in the structure
                      // and that are not already present in updatedSelected list
                      selected.forEach((selectedItem) => {
                        const inUpdatedSelected = updatedSelected.some(
                          (updatedItem) => selectedItem.debitId === updatedItem.debitId
                        );

                        const inStructure = structure.some(
                          (structureItem) => selectedItem.debitId === structureItem.value.debitId
                        );

                        if (!inUpdatedSelected && !inStructure) {
                          updatedSelected.push(selectedItem);
                        }
                      });

                      structure.forEach((structureItem) => {
                        const inSelected = selected.some(
                          (selectedItem) => structureItem.value.debitId === selectedItem.debitId
                        );

                        const inUpdatedSelected = updatedSelected.some(
                          (updatedItem) => structureItem.value.debitId === updatedItem.debitId
                        );

                        if (!inSelected) {
                          if (inUpdatedSelected) {
                            // remove structureItem from updatedSelected
                            const index = updatedSelected
                              .map((item) => {
                                return item.Id;
                              })
                              .indexOf(structureItem.value.debitId);

                            updatedSelected.splice(index, 1);
                          }
                        } else {
                          if (!inUpdatedSelected) {
                            updatedSelected.push(structureItem.value);
                          }
                        }
                      });

                      dispatch(setSelectedDebits(updatedSelected));
                    }} // update selectedDebits list
                    selectedDebits={selectedDebits}
                    preSelectedDebits={preSelectedDebits}
                    addFilters={{
                      searchPlaceholder: t('forms.searchPlaceholder', { textOnly: true }),
                      dropdownPlaceholder: t('paymentTool.multiPaymentFilter', {
                        textOnly: true,
                      }),
                      onSearch: (value) => {
                        dispatch(setSearchFilter(value));
                      },
                      onFilterChange: (value) => {
                        dispatch(setTypeFilter(value));
                      },
                      onFilterClear: (value) => {
                        dispatch(setTypeFilter(value));
                      },
                      filterTypes: debitsFilterTypes,
                    }}
                    filterParams={filterParams}
                    {...debits}
                    loading={loadingStatus.loadingPaymentsTable}
                  />
                }
              />
            )}
            {currentStep === 2 && (
              <SelectPaymentMethod
                selectedDebits={selectedDebits}
                totalCost={getTotalCost(selectedDebits).toFixed(2)}
                allowMbwayPayment={allowMbwayPayment}
              />
            )}
            {currentStep === 3 && (
              <SubmitPaymentRequest
                selectedDebits={selectedDebits}
                formRegister={register}
                formControl={control}
                formGetValues={getValues}
                formErrors={errors}
                currentLocale={currentLocale}
              />
            )}
          </>

          {preSelectedDebits.length > 0 && (
            <NavigationButtonsWrapper
              currentStep={currentStep}
              hasOnlyOneButton={currentStep === 3 && paymentMethod === PAYMENT_METHODS.MB}
            >
              {!(currentStep === 3 && paymentMethod === PAYMENT_METHODS.MB) && (
                <Button
                  decision
                  disabled={loadingStatus.loading}
                  onClick={() => {
                    dispatch(
                      navigateTo({
                        key: 'payments',
                      })
                    );
                    dispatch(resetPendingDebits());
                  }}
                >
                  {t('generic.cancel')}
                </Button>
              )}
              <BackwardForwardButtonsWrapper>
                {currentStep === 2 && (
                  <Button
                    decision
                    disabled={loadingStatus.loading}
                    onClick={() => {
                      dispatch(setCurrentStep(currentStep - 1));
                    }}
                  >
                    {t('generic.previous')}
                  </Button>
                )}
                {currentStep === 3 && paymentMethod === PAYMENT_METHODS.MBWAY && (
                  <Button
                    decision
                    disabled={loadingStatus.loading}
                    onClick={() => {
                      dispatch(setCurrentStep(currentStep - 1));
                    }}
                  >
                    {t('generic.previous')}
                  </Button>
                )}
                {(currentStep === 1 || currentStep === 2) && (
                  <Button
                    action
                    color={theme.colors.primary}
                    rightIcon={
                      currentStep === 2 && paymentMethod === PAYMENT_METHODS.MB ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : (
                        <FontAwesomeIcon icon={faChevronRight} />
                      )
                    }
                    loading={currentStep === 2 && loadingStatus.loading}
                    onClick={() => {
                      if (currentStep === 1) {
                        // essential in order for the table to not reload with previous fetched rows, which
                        // may not correspond to the offset 0
                        dispatch(resetDebitsList());
                        dispatch(setCurrentStep(currentStep + 1));
                      } else if (currentStep === 2) {
                        if (paymentMethod === PAYMENT_METHODS.MB) {
                          dispatch(submitMbDebitsRequest(selectedDebits));
                          // the change to the step 3 is made in the saga, after the post request
                        } else {
                          dispatch(setCurrentStep(currentStep + 1));
                        }
                      }
                    }}
                    disabled={getNextButtonDisabledState(currentStep) || loadingStatus.loading}
                  >
                    {currentStep === 2 && paymentMethod === PAYMENT_METHODS.MB
                      ? t('generic.submit')
                      : t('generic.next')}
                  </Button>
                )}
                {currentStep === 3 && (
                  <Button
                    action
                    color={theme.colors.primary}
                    loading={loadingStatus.loading}
                    rightIcon={
                      paymentMethod === PAYMENT_METHODS.MBWAY ? (
                        <FontAwesomeIcon icon={faCheck} />
                      ) : undefined
                    }
                    onClick={
                      paymentMethod === PAYMENT_METHODS.MBWAY
                        ? handleSubmit(onSubmitStatement)
                        : paymentMethod === PAYMENT_METHODS.MB
                        ? () => closeDebitTabs()
                        : undefined /* change page back to the payments*/
                    }
                  >
                    {paymentMethod === PAYMENT_METHODS.MBWAY
                      ? t('generic.submit')
                      : t('generic.close')}
                  </Button>
                )}
              </BackwardForwardButtonsWrapper>
            </NavigationButtonsWrapper>
          )}
        </>
      }
      secondaryContent={
        <SecondaryContentWrapper>
          <Text size="large" weight="regular" color="plusDarkGrey">
            {t('payments.paymentSummary', { textOnly: true })}
          </Text>
          <NumberItens>
            <Text size="small" weight="regular" color="plusDarkGrey">
              {t('payments.numberItens', { textOnly: true })}
            </Text>
          </NumberItens>

          <NumberSelectedItens>
            <Text size="mediumSmall" weight="medium" color="plusDarkGrey">
              {`${
                selectedDebits.length // ? selectedDebits.length : preSelectedDebits.length
              } ${t('payments.selectedItens', { textOnly: true })}`}
            </Text>
          </NumberSelectedItens>

          <LineSeparator />

          <TotalPayWrapper>
            <Text size="article" transform="uppercase" weight="medium" color="plusDarkGrey">
              {t('payments.totalPayment', { textOnly: true })}
            </Text>
            <Text size="medium" color="plusDarkGrey" weight="medium">
              {`${getTotalCost(selectedDebits).toFixed(2)} ${t('generic.euros', {
                textOnly: true,
              })}`}
            </Text>
          </TotalPayWrapper>
        </SecondaryContentWrapper>
      }
    />
  );
};

export default PendingDebitsPayment;

const ApplicationStepsWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  overflow-y: hidden;
  overflow-x: auto;
`;

const SecondaryContentWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.lightGrey};
  padding: 38px 16px;
  min-width: fit-content;
`;
const NumberItens = styled.div`
  margin-top: 16px;
`;

const LineSeparator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.lightGrey};
  margin-top: 64px;
  margin-bottom: 22px;
`;

const TotalPayWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NumberSelectedItens = styled.div`
  margin-top: 6px;
`;

const ApplicationStep = styled.div<{
  backgroundColor: string;
  border?: string;
  borderStyle?: string;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ border, borderStyle }) => {
    if (border === 'all') {
      return `border: ${borderStyle};`;
    } else if (border === 'top') {
      return `border-top: ${borderStyle};`;
    }
  }}

  &:not(:last-child) {
    margin-right: 8px;
  }
  max-width: 239px;
`;

const TabText = styled(Text)`
  line-height: 1.2;
`;

const CheckTickWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;

  &&& {
    min-width: 16px;
    height: 16px;
  }

  svg {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const BackwardForwardButtonsWrapper = styled.div`
  display: flex;

  > button:last-child {
    margin-left: 16px;
  }
`;

const NavigationButtonsWrapper = styled.div<{ currentStep: number; hasOnlyOneButton: boolean }>`
  display: flex;
  justify-content: ${({ currentStep, hasOnlyOneButton }) =>
    currentStep === 1 || hasOnlyOneButton ? `flex-end` : `space-between`};
  margin-top: 32px;
`;
