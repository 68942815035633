/**
 * FreeOptionsDropdown container sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { call, put, takeLatest } from 'redux-saga/effects';

import { t } from 'app';

import { actions } from 'store/rootSlices';

import ErrorHandler from 'shared/errorHandler';

import { getSelectedOptionKey } from '../utils';

interface ISetFreeOptions {
  type: 'FreeOptionsDropdown/setFreeOptions';
  payload: { ucs: any[]; options: { name: string; id: number }[] };
}

function* setFreeOptionsSaga(action: ISetFreeOptions) {
  const payload = action.payload;

  try {
    const { setOptions } = actions.FreeOptionsDropdown;

    const options = payload.ucs.map((option, index) => {
      let selectedOptionKey = getSelectedOptionKey(option);

      return {
        id: index + 1,
        optionId: option.id,
        selected: selectedOptionKey,
        //if editing is called group else is name
        name: option.groupName || option.name,
        irregular: option.irregular,
        open: selectedOptionKey ? true : false,
        ucs: option.ucs,
      };
    });

    yield put(setOptions(options));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      const { showToaster } = actions.Toaster;
      yield put(
        showToaster({
          title: t('sgh.actionErrorGetFreeOptions'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

export default function* watcherSignin() {
  yield takeLatest('FreeOptionsDropdown/setFreeOptions', setFreeOptionsSaga);
}
