/**
 * EnrollmentRenewal scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
};

export default createSlice({
  name: 'EnrollmentRenewal',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    reset: (state) => initialState,
  },
});
