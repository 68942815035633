import numeral from 'numeral';

export const registerNumerals = () => {
  numeral.register('locale', 'pt', {
    delimiters: {
        thousands: ' ',
        decimal: ','
    },
    abbreviations: {
        thousand: 'mil',
        million: 'milhões',
        billion: 'biliões',
        trillion: 'triliões'
    },
    ordinal : function () {
        return '.º';
    },
    ordinalPlural : function () {
      return '.ºs';
    },
    ordinalFemale : function () {
      return '.ª';
    }, 
    ordinalFemalePlural : function () {
      return '.ªs';
    },
    currency: {
        symbol: '€'
    }
  });
   
   // load a format
   numeral.register('format', 'ordinalPlural', {
    regexps: {
      format: /^pn$/
    },
    format: function(value, format, roundingFunction) {
        var locale = numeral.locales[numeral.options.currentLocale],
            output,
            ordinalPlural = numeral._.includes(format, ' pn') ? ' ' : '';

        // check for space before
        format = format.replace(/\s?pn/, '');

        ordinalPlural += locale.ordinalPlural ? locale.ordinalPlural(value) : locale.ordinal(value);

        output = numeral._.numberToFormat(value, format, roundingFunction);

        return output + ordinalPlural;
    }
  });
  
     // load a format
     numeral.register('format', 'ordinalFemale', {
      regexps: {
        format: /^fn$/
      },
      format: function(value, format, roundingFunction) {
          var locale = numeral.locales[numeral.options.currentLocale],
              output,
              ordinalFemale = numeral._.includes(format, ' fn') ? ' ' : '';
  
          // check for space before
          format = format.replace(/\s?fn/, '');
  
          ordinalFemale += locale.ordinalFemale ? locale.ordinalFemale(value) : locale.ordinal(value);
  
          output = numeral._.numberToFormat(value, format, roundingFunction);
  
          return output + ordinalFemale;
      }
    });

     // load a format
     numeral.register('format', 'ordinalFemalePlural', {
      regexps: {
        format: /^fpn$/
      },
      format: function(value, format, roundingFunction) {
          var locale = numeral.locales[numeral.options.currentLocale],
              output,
              ordinalFemalePlural = numeral._.includes(format, ' fpn') ? ' ' : '';
  
          // check for space before
          format = format.replace(/\s?fpn/, '');
  
          ordinalFemalePlural += locale.ordinalFemalePlural ? locale.ordinalFemalePlural(value) : locale.ordinal(value);
  
          output = numeral._.numberToFormat(value, format, roundingFunction);
  
          return output + ordinalFemalePlural;
      }
    });

}