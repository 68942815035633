import { call, put, takeLatest, select } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import { t } from 'app';
import ErrorHandler from 'shared/errorHandler';
import API from 'api';
import { getPautaData } from './selectors';

function* fetchGradeDetailsSaga(action) {
  try {
    const codigoPauta = action.payload.codigoPauta;
    const response = yield call(API.pautas.getGradeDetails.call, codigoPauta);
    yield put(actions.GradesDetails.setGradeDetails(response));
  } catch (error) {
    console.error(error);
    const shouldRun = yield call(ErrorHandler, error);
  }
  yield put(
    actions.GradesDetails.setLoadingStatus({
      fieldName: 'loadingGradeDetailsInfo',
      fieldValue: false,
    })
  );
}

function* fetchDecryptedGradeSaga(action) {
  try {
    const codigoPauta = action.payload.codigoPauta;
    const response = yield call(API.pautas.getDecryptedGrade.call, codigoPauta);

    // Extracting hdadosItem and bdadosItem
    const hdadosItem = response.find(item => item.type === 'HDADOS');
    const bdadosItem = response.find(item => item.type === 'BDADOS');

    if (!hdadosItem || !bdadosItem) {
      console.error("BDADOS or HDADOS data not found");
      return;
    }

    // Set dataExame
    const dataExame = hdadosItem.dataExame;
    if (dataExame) {
      yield put(actions.GradesDetails.setDataExame(dataExame));
    }

    // Compute and set gradeCounts
    const studentData = bdadosItem.data as StudentData[];
    const gradeCounts = studentData.reduce<GradeCounts>((acc, curr) => {
      const grade = curr.nota;
      acc[grade] = (acc[grade] || 0) + 1;
      return acc;
    }, {});


    yield put(actions.GradesDetails.setGradeCounts(gradeCounts));
  } catch (error) {
    console.error('Error in fetchDecryptedGradeSaga:', error);
    const shouldRun = yield call(ErrorHandler, error);
  }
  yield put(
    actions.GradesDetails.setLoadingStatus({
      fieldName: 'loadingGradeDetailsCounts',
      fieldValue: false,
    })
  );
}

function* fetchPdfSaga() {
  try {
    const [pauta] = yield select(getPautaData);

    if (!pauta || pauta.length === 0) {
      console.error("pauta is empty.");
      yield put(actions.GradesDetails.setIsPdfReady(false));
      return;
    }

    let documentId: string | undefined = pauta.comAssinaturaId || pauta.paraAssinaturaId;
    let isSigned = pauta.comAssinaturaId ? true : false;

    if (!documentId) {
      console.error("Neither comAssinaturaId nor paraAssinaturaId found for the current pauta.");
      yield put(actions.GradesDetails.setIsPdfReady(false));
      return;
    }

    const pdfBlobUrl = yield call(API.pautas.getGradePdf.call, documentId, isSigned);

    yield put(actions.GradesDetails.setPdfUrl(pdfBlobUrl));
    yield put(actions.GradesDetails.setIsPdfReady(true));
  } catch (error) {
    console.error("Error fetching PDF: ", error);
    yield put(actions.GradesDetails.setIsPdfReady(false));
  }
}


interface StudentData {
  nota: string;
}

interface GradeCounts {
  [grade: string]: number;
}

function* onMountSaga() {
  try {
    yield put(actions.GradesDetails.reset());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.certificateDetails_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  // yield put(actions.GradesDetails.reset());
}

// Watcher Saga: Spawns a new fetchGradeDetailsSaga on each action dispatched
export default function* gradeDetailsWatcher() {
  yield takeLatest('GradesDetails/onMount', onMountSaga);
  yield takeLatest('GradesDetails/onUnmount', onUnmountSaga);
  yield takeLatest('GradesDetails/getGradeDetails', fetchGradeDetailsSaga);
  yield takeLatest('GradesDetails/getGradeDetails', fetchDecryptedGradeSaga);
  yield takeLatest('GradesDetails/fetchPdf', fetchPdfSaga);
}
