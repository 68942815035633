import React, { useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Table } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import { AnimatedBackground } from '@paco_ua/pacoui';
import Header from 'shared/containers/TitleHeader';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';
import { getCurrentRoute } from 'utils';
import GradeTitle from 'shared/components/GradeTitle';


const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const TimeWrapper = styled.div`
  margin-top: 4px;
  color: #666;
`;

const GradesHistory = ({ t }) => {
  const theme = useContext(ThemeContext);

  const { historyList, loadingStatus, gradeDetails } = useSelector(selectors.getGradeHistorySlice);

  const dispatch = useDispatch();

  const { getGradeHistory } = actions.GradesHistory;

  const codigoPauta = getCurrentRoute().params.codigoPauta;

  useEffect(() => {
    dispatch(actions.GradesHistory.onMount());

    return () => {
      dispatch(actions.GradesHistory.onUnmount());
    };
  }, [dispatch]);

  useEffect(() => {
    if (codigoPauta !== null) {
      dispatch(
        getGradeHistory({
          codigoPauta,
        })
      );
    }
  }, [codigoPauta]);

  const tableStructure = {
    header: {
      titles: [
        { text: t('pautas.historyDateHour'), style: { textAlign: 'left' } },
        { text: t('pautas.gradeState'), style: { textAlign: 'left' } },
        { text: t('pautas.actor'), style: { textAlign: 'left' } },
        { text: 'Obs.', style: { textAlign: 'left' } },
      ],
      type: 'columnTitle',
    },
    rows: historyList.map((item, index) => {
      const date = new Date(item.dataHora);
      const dateString = date.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
      const timeString = date.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }); // 24-hour format
      return {
        cells: [
          {
            cellProps: '', content: (
              <>
                <div>{dateString}</div>
                <TimeWrapper>{timeString}</TimeWrapper>
              </>
            )
          },
          {
            cellProps: '',
            content: t('pautas.gradeState' + item.codEstado),
          },
          { cellProps: '', content: item.nomeUtilizador },
          { cellProps: '', content: item.obs },
        ],
        key: `row-${index}`,
        collapsableRows: [],
      }
    }),
  };

  return (
    <PageStructure
      loading={loadingStatus.loadingGradeHistory}
      expanded={true}
      headerLeft={<Header title={t('routes.gradeHistory')} />}
      mainContent={
        <>
          {(loadingStatus.loadingGradeHistory) && (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="40%" />
              <AnimatedBackground height={theme.sizes.large} width="40%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
            </LoadingWrapper>
          )}
          {(!loadingStatus.loadingGradeHistory && gradeDetails) && (
            <>
              <GradeTitle
                codDisciplina={gradeDetails.codDisciplina}
                nomeDisciplina={gradeDetails.nomeDisciplina}
                nomeDisciplinaEng={gradeDetails.nomeDisciplinaEng}
                tipoExame={gradeDetails.tipoExame}
                epocaExame={gradeDetails.epocaExame}
                anoLectivo={gradeDetails.anoLectivo}
                codPauta={gradeDetails.codPauta} />
              <div style={{ width: '100%', overflowX: 'auto' }}>
                <Table structure={tableStructure} />
              </div>
            </>
          )}
        </>
      }
    />
  );
};

export default GradesHistory;
