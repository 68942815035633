import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled, { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import * as selectors from './selectors';

//import '@uaveiro/systems-bar';

import { actions } from 'store/rootSlices';

import { AnimatedBackground, Text } from '@paco_ua/pacoui';

import { redirectAuth } from 'app/utils';
import { t } from 'app';

const SystemBar = () => {
  const { signout } = actions.App;

  const { user, startingApp } = useSelector(selectors.getAppSlice);
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <LinksWrapper>
        {startingApp && <AnimatedBackground height={theme.sizes.medium} width="100px" />}
        {!startingApp && (
          <>
            <Link>
              <Text
                size="xSmall"
                weight="regular"
                color="black"
                onClick={() => {
                  window.open('https://www.ua.pt/noticias', '_blank');
                }}
              >
                Notícias
              </Text>
            </Link>
            <Link>
              <Text
                size="xSmall"
                weight="regular"
                color="black"
                onClick={() => {
                  window.open('https://www.ua.pt/agenda', '_blank');
                }}
              >
                Agenda
              </Text>
            </Link>
          </>
        )}
      </LinksWrapper>
      <UserWrapper>
        {startingApp && <AnimatedBackground height={theme.sizes.medium} width="100px" />}
        {!startingApp && (
          <>
            {!user.accessToken && (
              <LinkWrapper>
                <Text
                  size="xSmall"
                  weight="regular"
                  color="institutional"
                  onClick={() => {
                    redirectAuth();
                  }}
                  data-testid="header_button_login"
                >
                  entrar
                </Text>
                <FontAwesomeIcon size="xs" icon={faUser} />
              </LinkWrapper>
            )}
            {user.accessToken && (
              <>
                {user.fullName && (
                  <>
                    <Text
                      size="xSmall"
                      weight="regular"
                      color="institutional"
                      data-testid="header_text_login"
                    >
                      {user.fullName}
                    </Text>
                    <Separator>
                      <Text size="xSmall" weight="regular" color="institutional">
                        |
                      </Text>
                    </Separator>
                  </>
                )}
                <LinkWrapper>
                  <Text
                    size="xSmall"
                    weight="regular"
                    color="institutional"
                    data-testid="header_text_login_logout"
                    transform="lowercase"
                    onClick={() => {
                      dispatch(signout());
                    }}
                  >
                    {t('generic.logout')}
                  </Text>
                </LinkWrapper>
                <FontAwesomeIcon size="xs" icon={faUser} />
              </>
            )}
          </>
        )}
      </UserWrapper>
    </Wrapper>
  );
};

export default SystemBar;

const Wrapper = styled.div`
  max-height: 40px;
  min-height: 40px;
  height: 40px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding: 0 40px;
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;

  div:not(:last-child) {
    border-right: 1px solid ${({ theme }) => theme.colors.systemBar.grey};
    padding-right: 10px;
  }

  div:not(:first-child) {
    padding-left: 10px;
  }
`;

const Link = styled.div`
  padding: 3px 0;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.black};

    cursor: pointer;
    opacity: 0.8;
  }
`;

const UserWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 8px;
    color: ${({ theme }) => theme.colors.institutional};
  }
`;

const LinkWrapper = styled.div`
  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.institutional};

    cursor: pointer;
    opacity: 0.8;
  }
`;

const Separator = styled.div`
  margin: 0 10px;
`;
