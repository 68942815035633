/**
 * CertificateState component
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { t } from 'app';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCreditCard, faPaperPlane, faTimes } from '@fortawesome/pro-light-svg-icons';

import { Text, Theme as theme } from '@paco_ua/pacoui';

//import config from 'config'

interface IProps {
  certificatesDetails: any;
}

const CertificateState: React.FC<IProps> = ({ certificatesDetails }) => {
  const generateStates = (certificatesDetails) => {
    let elements = [] as any;

    certificatesDetails.history.forEach((item, index) => {
      const isNotLast = index === certificatesDetails.history.length - 1; // Check if it's the last element

      if (item.state === 'awaitingPayment') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'awaitingPayment'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'awaitingPayment'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faClock} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateAwaitingPayment')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'paymentDone') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'paymentDone'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'paymentDone'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.statePaid')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'sent') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'sent'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'sent'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateSent')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'expired') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'expired'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'expired'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateExpired')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'exemptionAccepted') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'exemptionAccepted'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'exemptionAccepted'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateExemptionAccepted')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'exemptionDenied') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'exemptionDenied'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'exemptionDenied'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateExemptionDenied')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'awaitingExemptionDecision') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'awaitingExemptionDecision'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'awaitingExemptionDecision'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faClock} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateAwaitingExemptionDecision')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      }
      else if (item.state === 'rejected') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'rejected'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'rejected'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.rejected')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } else if (item.state === 'pending') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'pending'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'pending'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faClock} />}
                  //transform="uppercase"
                >
                  {t('certificates.statePending')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      }  else if (item.state === 'submitted') {
        elements.push(
          <div key={`hist-item-${index}`}>
            <SituationWrapper>
              <SituationState>
                <SituationCircle selected={certificatesDetails.state.state === 'submitted'} 
                isNotLast={index < certificatesDetails.history.length - 1}/>
                <Text
                  weight="regular"
                  iconPadding="18px"
                  iconWidth="20px"
                  color={
                    certificatesDetails.state.state === 'submitted'
                      ? theme.colors.primary
                      : theme.colors.plusDarkGrey
                  }
                  icon={<FontAwesomeIcon size="lg" icon={faCreditCard} />}
                  //transform="uppercase"
                >
                  {t('certificates.stateSubmitted')}
                </Text>
              </SituationState>
              {item.date && (
                <SituationDetails>
                  <Text color={theme.colors.plusDarkGrey} weight="regular" size="xSmall">
                    {t('certificates.stateDate')}:{' '}
                    <Text color={theme.colors.plusDarkGrey} size="xSmall" weight="bold">
                      {moment(item.date).format("DD-MM-YYYY HH:mm")}
                    </Text>
                  </Text>
                </SituationDetails>
              )}
            </SituationWrapper>
          </div>
        );
      } 
    });

    return elements;
  };

  return <Wrapper>{generateStates(certificatesDetails)}</Wrapper>;
};

export default CertificateState;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div:not(:last-child) {
    height: 69px;
  }
`;

const SituationWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SituationState = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const SituationDetails = styled.div`
  margin-left: 70px;
`;

const SituationCircle = styled.div<{
  selected?: boolean;
  color?: string;
  colorVerticalLine?: string;
  isNotLast?: boolean;
}>`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid ${({ theme, color }) => (color ? color : theme.colors.primary)};
  background-color: ${({ selected, theme, color }) =>
    color ? color : selected ? 'transparent' : theme.colors.primary};
  margin-right: 18px;
  position: relative;

  ${({ selected, theme, colorVerticalLine, isNotLast }) =>

  `
      &:after {
        content: '';
        position: absolute;
        top: 11px;
        left: 5px;
        width: ${(isNotLast ? '1px' : '0')}; // Render only if it's not the last item;
        background-color: ${colorVerticalLine ? colorVerticalLine : theme.colors.primary};
        height: ${(isNotLast ? '58px' : '0')}; // Set height to 0 if it's the last element;
      } 
  `}
`;
