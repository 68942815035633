import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { overridePath } from '../utils';

interface IPathElem {
  key: string;
  name: string;
  url: string;
}

interface IPathElemOverride {
  key: string;
  name?: string;
  url?: string;
}

const initialState = {
  pathElemsList: [] as IPathElem[],
};

export default createSlice({
  name: 'Breadcrumb',
  initialState,
  reducers: {
    setPath: (state, action: PayloadAction<IPathElem[]>) => {
      state.pathElemsList = action.payload;
    },
    overridePath: (state, action: PayloadAction<IPathElemOverride>) => {
      state.pathElemsList = overridePath(state.pathElemsList, action.payload);
    },
  },
});
