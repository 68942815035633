import { all, cancel } from 'redux-saga/effects';

import appSaga from 'app/logic/sagas';

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports here
import logsstudentSaga from 'scenes/LogsStudent/logic/sagas'
import logsSaga from 'scenes/Logs/logic/sagas'
import examsCalendarSaga from 'scenes/VirtualSecretary/ExamsCalendar/logic/sagas';
import applicationdetailsSaga from 'scenes/VirtualSecretary/Applications/ApplicationDetails/logic/sagas';
import newapplicationSaga from 'scenes/VirtualSecretary/Applications/NewApplication/logic/sagas';
import newcertificateSaga from 'scenes/VirtualSecretary/Certificates/NewCertificate/logic/sagas';
import registrationdetailsSaga from 'scenes/VirtualSecretary/RegistrationDetails/logic/sagas';
import registrationsSaga from 'scenes/VirtualSecretary/Registrations/logic/sagas';
import seriationhistorySaga from 'scenes/Schedules/SeriationHistory/logic/sagas';
import seriationhistorydetailsSaga from 'scenes/Schedules/SeriationHistoryDetails/logic/sagas';
import freeOptionsDropdownSaga from 'scenes/Schedules/MySchedule/containers/FreeOptionsDropdown/logic/sagas';
import studentregistrationdropdownSaga from 'shared/containers/StudentRegistrationDropdown/logic/sagas';
import applicationslistSaga from 'scenes/VirtualSecretary/Applications/ApplicationsList/logic/sagas';
import certificateslistSaga from 'scenes/VirtualSecretary/Certificates/CertificatesList/logic/sagas';
import certificatesdetailsSaga from 'scenes/VirtualSecretary/Certificates/CertificatesDetails/logic/sagas';
import certificateValidatorSaga from 'scenes/VirtualSecretary/CertificateValidator/logic/sagas';
import paymentsSaga from 'scenes/VirtualSecretary/Payments/logic/sagas';
import pendingDebitsPaymentSaga from 'scenes/VirtualSecretary/Payments/PendingDebitsPayment/logic/sagas';
import gradesSaga from 'scenes/VirtualSecretary/Grades/logic/sagas';
import assessmentchoiceSaga from 'scenes/VirtualSecretary/AssessmentChoice/logic/sagas';
import examregistrationSaga from 'scenes/VirtualSecretary/ExamRegistration/logic/sagas';
import subscriptionrenewalSaga from 'scenes/VirtualSecretary/SubscriptionRenewal/logic/sagas';
import classsupoortSaga from 'scenes/VirtualSecretary/ClassSupoort/logic/sagas';
import curricularplanSaga from 'scenes/VirtualSecretary/CurricularPlan/CurricularPlan/logic/sagas';
import curricularUnitDetailsSaga from 'scenes/VirtualSecretary/CurricularPlan/CurricularUnitDetails/logic/sagas';
import personaldataSaga from 'scenes/VirtualSecretary/PersonalData/logic/sagas';
import gradesManagementSaga from 'scenes/GradesManagement/GradesManagement/logic/sagas';
import editGradesSaga from 'scenes/GradesManagement/EditGrades/logic/sagas';
import gradesDetailsSaga from 'scenes/GradesManagement/GradesDetails/logic/sagas';
import gradesHistorySaga from 'scenes/GradesManagement/GradesHistory/logic/sagas';
import myschedulesSaga from 'scenes/Schedules/CreatedSchedules/logic/sagas';
import mandatoryOptionsDropdownSaga from 'scenes/Schedules/MySchedule/containers/MandatoryOptionsDropdown/logic/sagas';
import toasterSaga from 'shared/containers/Toaster/logic/sagas';
import toasterActionSaga from 'shared/containers/ToasterAction/logic/sagas';
import schedulesSaga from 'scenes/Schedules/MySchedule/logic/sagas';
import freeOptionsListSaga from 'scenes/Schedules/FreeOptionsList/logic/sagas';
import freeOptionsTableSaga from 'scenes/Schedules/containers/FreeOptionsTable/logic/sagas';
import schedulesHomeSaga from 'scenes/Schedules/SchedulesHome/logic/sagas';
import schedulesGuideSaga from 'scenes/Schedules/SchedulesGuide/logic/sagas';
import gradesGuideSaga from 'scenes/GradesManagement/GradesGuide/logic/sagas';
import createdScheduleSaga from 'scenes/Schedules/CreatedSchedules/containers/CreatedSchedule/logic/sagas';
import homeSaga from 'scenes/Home/logic/sagas';
import helpSaga from 'scenes/Help/logic/sagas';
import improvementExamEnrollmentSaga from 'scenes/VirtualSecretary/Enrollments/ImprovementExamEnrollment/logic/sagas';
import examEnrollmentSaga from 'scenes/VirtualSecretary/Enrollments/ExamEnrollment/logic/sagas';
import evaluationTypeSelectionSaga from 'scenes/VirtualSecretary/Enrollments/EvaluationTypeSelection/logic/sagas';
import enrollmentRenewalSaga from 'scenes/VirtualSecretary/Enrollments/EnrollmentRenewal/logic/sagas';
import specialExamsSaga from 'scenes/VirtualSecretary/Enrollments/SpecialExamsEnroll/logic/sagas';

// WARNING!! DO NOT CHANGE STRUCTURE OR ELSE HYGEN WON'T WORK
export default function* rootSaga() {
  yield all([
    logsstudentSaga(),
    logsSaga(),
    examsCalendarSaga(),
    applicationdetailsSaga(),
    newapplicationSaga(),
    newcertificateSaga(),
    registrationdetailsSaga(),
    registrationsSaga(),
    seriationhistorySaga(),
    seriationhistorydetailsSaga(),
    freeOptionsDropdownSaga(),
    studentregistrationdropdownSaga(),
    applicationslistSaga(),
    certificateslistSaga(),
    certificatesdetailsSaga(),
    certificateValidatorSaga(),
    paymentsSaga(),
    pendingDebitsPaymentSaga(),
    gradesSaga(),
    assessmentchoiceSaga(),
    examregistrationSaga(),
    subscriptionrenewalSaga(),
    classsupoortSaga(),
    curricularplanSaga(),
    curricularUnitDetailsSaga(),
    personaldataSaga(),
    gradesManagementSaga(),
    editGradesSaga(),
    gradesDetailsSaga(),
    gradesHistorySaga(),
    createdScheduleSaga(),
    myschedulesSaga(),
    mandatoryOptionsDropdownSaga(),
    toasterSaga(),
    toasterActionSaga(),
    schedulesSaga(),
    freeOptionsListSaga(),
    freeOptionsTableSaga(),
    schedulesHomeSaga(),
    schedulesGuideSaga(),
    gradesGuideSaga(),
    improvementExamEnrollmentSaga(),
    examEnrollmentSaga(),
    evaluationTypeSelectionSaga(),
    enrollmentRenewalSaga(),
    specialExamsSaga(),
    homeSaga(),
    helpSaga(),
    appSaga(),
  ]);
}
