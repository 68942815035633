import API from 'api';
import { IRole } from 'config/types';

export const JSONtoQueryString = (data) =>
  Object.keys(data)
    .map((k) => k + '=' + data[k])
    .join('&');

export const listAllScopes = () => {
  let result: string[] = [];

  function findAll(obj, key) {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (property === key) {
          if (typeof obj[key] === 'string') {
            result.push(obj[key]); // returns the value
          } else {
            result = [...result, ...obj[key]];
          }
        } else if (typeof obj[property] === 'object') {
          findAll(obj[property], key);
        }
      }
    }
  }

  findAll(API, 'scope');
  return result;
};

export const checkBlacklist = (blacklist: IRole[], throwError?: boolean) =>
  new Promise((resolve, reject) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.roles.length > 0 && user?.accessToken) {
      //throw error if all roles are in blacklist
      if (user.roles.every((role) => blacklist.includes(role))) {
        if (throwError) {
          reject(new Error('BLACKLISTED_REQUEST'));
        } else {
          reject(new Error('UNHANDLED_BLACKLISTED_REQUEST_EXCEPTION'));
        }
      }
    }
    resolve(true);
  });

export const checkWhitelist = (blacklist: IRole[], throwError?: boolean) =>
  new Promise((resolve, reject) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (user?.roles.length > 0 && user?.accessToken) {
      //succesd if roles exists
      if (user.roles.some((role) => blacklist.includes(role))) {
        resolve(true);
      }
    }

    if (throwError) {
      reject(new Error('BLACKLISTED_REQUEST'));
    } else {
      reject(new Error('UNHANDLED_BLACKLISTED_REQUEST_EXCEPTION'));
    }
  });
