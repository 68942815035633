/**
 * AplicationsList scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getApplicationsListSlice = (state: RootState) => state.ApplicationsList;
export const getRegistrationId = (state: RootState) =>
  state.StudentRegistrationDropdown.registration;
export const getUser = (state: RootState) => state.App.user;
export const getStudentApplications = (state: RootState) =>
  state.ApplicationsList.studentApplications;
