/**
 * Schedules scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React from 'react';

//import { ResponsiveLayout } from '@paco_ua/pacoui';

//import SchedulesMobile from './mobile';
import SchedulesWeb from './web';

const Schedules: React.FC = () => {
  return <SchedulesWeb />;
  //return <ResponsiveLayout tablet={SchedulesWeb} web={SchedulesWeb} mobile={SchedulesWeb} />;
};

export default Schedules;
