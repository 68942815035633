/**
 * ApplicationDetails scene sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { takeLatest, put, select, putResolve, call } from 'redux-saga/effects';

import { t } from 'app';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';
import ErrorHandler from 'shared/errorHandler';

import { APPLICATION_ERROR_MESSAGE } from 'shared/errorMessages';

import API from 'api';

import { history, getCurrentRoute } from 'utils';

function* onMountSaga() {
  yield put(actions.ApplicationDetails.reset());
  yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
}

function* getStatusAndDetailsSaga() {
  try {
    yield put(
      actions.ApplicationDetails.setLoadingStatus({ fieldName: 'loading', fieldValue: true })
    );
    yield put(
      actions.ApplicationDetails.setErrorStatus({ fieldName: 'errLoading', fieldValue: false })
    );

    const { pathname } = history.location;
    const registrationId = Number(pathname.split('/').slice(-2)[0]);
    const applicationId = getCurrentRoute().params.id;
    const { name, registrations } = yield select(selectors.getUser);

    const currentRegistration = registrations.find((registration) => {
      return registration.registrationId === registrationId;
    });

    const response = yield call(API.secVirtual.getApplicationDetails.call, {
      registrationId,
      applicationId,
    });

    // TODO: add documents
    const applicationStatement = {
      studentName: response.details.studentName,
      course: `${currentRegistration.courseCode} - ${currentRegistration.courseName}`,
      category: `${response.state.theme} - ${response.state.type}`,
      statement: response.details.exposition,
      documents: response.details.documents,
      applicationDocument: response.details.applicationDocument,
    };

    yield put(actions.ApplicationDetails.setStatus(response.state));
    yield put(actions.ApplicationDetails.setDetails(applicationStatement));
  } catch (e) {
    yield put(
      actions.ApplicationDetails.setErrorStatus({ fieldName: 'errLoading', fieldValue: true })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (
        e.response.data.error_code === APPLICATION_ERROR_MESSAGE.ERR_STUDENT_INVALID_APPLICATION
      ) {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.applicationDetails_errorInvalidApplication'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.applicationDetails_errorLoadingPage'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  } finally {
    yield put(
      actions.ApplicationDetails.setLoadingStatus({ fieldName: 'loading', fieldValue: false })
    );
  }
}

function* onUnmountSaga() {
  yield put(actions.ApplicationDetails.reset());
}

export default function* watcherSignin() {
  yield takeLatest('ApplicationDetails/onMount', onMountSaga);
  yield takeLatest('ApplicationDetails/onUnmount', onUnmountSaga);
  yield takeLatest('ApplicationDetails/getStatusAndDetails', getStatusAndDetailsSaga);
}
