/**
 * Registrations scene utils
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { t } from 'app';

export const getEligibilityState = (status: number) => {
  if (status === 100) {
    return t('registrations.detailsEligibilityStatusRed');
  } else if (status === 1 || status === 2) {
    return t('registrations.detailsEligibilityStatusGreen');
  } else if (status === 25 || status === 50) {
    return t('registrations.detailsEligibilityStatusYellow');
  } else if (status === -1) {
    return t('registrations.detailsEligibilityStatusGrey');
  }
};

/*
  100 -- 'Prescrito'
  50 -- 'Em risco de prescricao (Ano atual)'
  25 -- 'Em risco de prescricao (Ano seguinte)'
  1 -- 'Não Prescrito'
  2 -- 'Não Prescrito'
 -1 -- 'N/D' --> não definido
*/
