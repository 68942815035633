/**
 * ImprovementExamEnrollment scene selectors
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getImprovementExamEnrollmentSlice = (state: RootState) =>
  state.ImprovementExamEnrollment;
export const getAppSlice = (state: RootState) => state.App;
export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getUserRegistrations = (state: RootState) => state.App.user.registrations;
export const getUser = (state: RootState) => state.App.user;
