/**
 * SeriationHistory scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import numeral from 'numeral';
import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faQuestionCircle, faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { faSmile, faFrown } from '@fortawesome/pro-regular-svg-icons';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';

import {
  Dropdown,
  Table,
  NoDataMessage,
  Loading,
  Text,
  StatusLabel,
  Button,
} from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { SeriationStatus } from './types';

import { getCurrentRoute } from 'utils';

//import config from 'config'

const SeriationHistory = ({ t }) => {
  const dispatch = useDispatch();
  const {
    onMount,
    onUnmount,
    getSeriationHistoryResults,
    setCurrentSelectedPhase,
  } = actions.SeriationHistory;

  const registration = useSelector(selectors.getRegistration);

  const {
    seriationHistoryResults,
    currentDetails,
    currentSelectedPhase,
    phaseFilterList,
    loading,
    invalidSchedule,
    finalSeriation,
    seriationId,
  } = useSelector(selectors.getSeriationHistorySlice);

  const theme = useContext(ThemeContext);
  const { navigateTo } = actions.App;

  const isProvisory =
    getCurrentRoute().route.key === 'provisoryseriationhistory' ||
    getCurrentRoute().route.key === 'provisoryscheduleseriationhistory';

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount]);

  useEffect(() => {
    if (registration !== null) {
      registration && dispatch(getSeriationHistoryResults({ registrationId: registration }));
    }
  }, [registration]);

  const getCurrentHeader = () => {
    if (isProvisory) {
      let currentRoute = getCurrentRoute().route.key;

      if (currentRoute === 'provisoryseriationhistory') {
        let phasename = getCurrentRoute().params.id;
        return (
          <Header
            title={`${t('sgh.provisorySeriationHistory', {
              textOnly: true,
              what: t('sgh.phase', { textOnly: true }),
            })} ${phasename}`}
            backButton
          />
        );
      } else if (currentRoute === 'provisoryscheduleseriationhistory') {
        return <Header title={currentDetails.currentScheduleName} backButton />;
      }
    } else {
      return (
        <>
          <Header
            title={`${t('sgh.seriationHistoryTitle', { textOnly: true })} ${
              currentDetails.currentYear
            } -  ${numeral(currentDetails.currentSemester).format('o')} ${t('generic.semester', {
              textOnly: true,
            })} ${phaseFilterList.length ? '/' : ''} `}
            backButton
          />
          &nbsp;
          {!!phaseFilterList.length && (
            <DropdownWrapper>
              <Dropdown
                selectionWeight={`${theme.weight.medium}`}
                selectionFontSize="xLarge"
                options={phaseFilterList.map((phase, key) => ({
                  key: `phase_existing_${key}`,
                  text: `${t('sgh.phase', { textOnly: true })} ${phase}`,
                  value: phase,
                }))}
                value={currentSelectedPhase || ''}
                onChange={(e, { value }) => {
                  if (registration) {
                    dispatch(setCurrentSelectedPhase(value));
                    dispatch(
                      getSeriationHistoryResults({ registrationId: registration, phase: value })
                    );
                  }
                }}
              />
            </DropdownWrapper>
          )}
        </>
      );
    }
  };

  //TODO review this because it simplifies current struture
  /*const getScheduleStateLabel = (placed: string, tableState: string | null) => {
    if (!tableState) {
      switch (placed) {
        case SeriationStatus.AUTOMATIC:
          return (
            <StatusLabel
              background={theme.colors.primary}
              label={t('sgh.automaticSeriation', { textOnly: true })}
            />
          );
        case SeriationStatus.ASSIGNED:
          return (
            <StatusLabel
              background={theme.colors.successGreen}
              label={t('sgh.assignedSchedule', { textOnly: true })}
            />
          );
        case SeriationStatus.PROBABLY_ASSIGNED:
          return (
            <StatusLabel
              background={theme.colors.successGreen}
              label={t('sgh.probableAssignedSchedule', { textOnly: true })}
            />
          );
        case SeriationStatus.PROBABLY_NOTASSIGNED:
          return (
            <StatusLabel
              background={theme.colors.dangerRed}
              label={t('sgh.probableUnassignedSchedule', { textOnly: true })}
            />
          );
        case SeriationStatus.NOTASSIGNED:
        case SeriationStatus.NOTASSIGNED_MANDATORY:
          return (
            <StatusLabel
              background={theme.colors.dangerRed}
              label={t('sgh.unassignedSchedule', { textOnly: true })}
            />
          );
      }
    } else {
      switch (tableState) {
        case SeriationStatus.PROBABLY_ASSIGNED:
          return (
            <StatusLabel
              background={theme.colors.successGreen}
              label={t('sgh.withProbableAllocation', { textOnly: true })}
            />
          );
        case SeriationStatus.PROBABLY_NOTASSIGNED:
          return (
            <StatusLabel
              background={theme.colors.dangerRed}
              label={t('sgh.withoutProbableAllocation', { textOnly: true })}
            />
          );
        case SeriationStatus.ASSIGNED:
        case SeriationStatus.AUTOMATIC:
          return (
            <StatusLabel
              background={theme.colors.successGreen}
              label={t('sgh.placed', { textOnly: true })}
            />
          );
        case SeriationStatus.NOTASSIGNED:
        case SeriationStatus.NOTASSIGNED_MANDATORY:
          return (
            <StatusLabel
              background={theme.colors.dangerRed}
              label={t('sgh.withoutAllocation', { textOnly: true })}
            />
          );
      }
    }
  };*/

  const getScheduleStateLabel = (
    placed: string,
    tableState: string | null,
    tableHeader: boolean = false
  ) => {
    if (placed === SeriationStatus.AUTOMATIC) {
      return (
        <StatusLabel
          background={theme.colors.primary}
          label={t('sgh.automaticSeriation', { textOnly: true })}
        />
      );
    } else if (placed === SeriationStatus.ASSIGNED) {
      if (tableHeader) {
        return (
          <StatusLabel
            background={theme.colors.successGreen}
            label={t('sgh.assignedSchedule', { textOnly: true })}
          />
        );
      }

      if (tableState === SeriationStatus.ASSIGNED || tableState === SeriationStatus.AUTOMATIC) {
        return (
          <StatusLabel
            background={theme.colors.successGreen}
            label={t('sgh.placed', { textOnly: true })}
          />
        );
      }

      return (
        <StatusLabel
          background={theme.colors.successGreen}
          label={t('sgh.probableAllocation', { textOnly: true })}
        />
      );
    } else if (placed === SeriationStatus.PROBABLY_ASSIGNED) {
      if (tableHeader) {
        return (
          <StatusLabel
            background={theme.colors.successGreen}
            label={t('sgh.probableAssignedSchedule', { textOnly: true })}
          />
        );
      }

      return (
        <StatusLabel
          background={theme.colors.successGreen}
          label={t('sgh.withProbableAllocation', { textOnly: true })}
        />
      );
    } else if (placed === SeriationStatus.PROBABLY_NOTASSIGNED) {
      if (tableHeader) {
        return (
          <StatusLabel
            background={theme.colors.dangerRed}
            label={t('sgh.probableUnassignedSchedule', { textOnly: true })}
          />
        );
      }

      return (
        <StatusLabel
          background={theme.colors.dangerRed}
          label={t('sgh.withoutProbableAllocation', { textOnly: true })}
        />
      );
    } else if (
      placed === SeriationStatus.NOTASSIGNED ||
      placed === SeriationStatus.NOTASSIGNED_MANDATORY
    ) {
      if (tableHeader) {
        return (
          <StatusLabel
            background={theme.colors.dangerRed}
            label={t('sgh.unassignedSchedule', { textOnly: true })}
          />
        );
      }

      return (
        <StatusLabel
          background={theme.colors.dangerRed}
          label={t('sgh.withoutAllocation', { textOnly: true })}
        />
      );
    }
  };

  const getScheduleProbabilityIcon = (allocationProbability) => {
    if (allocationProbability === 'high') {
      return <FontAwesomeIcon size="lg" icon={faSmile} />;
    }
    if (allocationProbability === 'low') {
      return <FontAwesomeIcon size="lg" icon={faFrown} />;
    }
  };

  const getScheduleProbabilityTitle = (allocationProbability) => {
    if (allocationProbability === 'high') {
      return t('sgh.legendAllocationProbable');
    }
    if (allocationProbability === 'low') {
      return t('sgh.legendAllocationImprobable');
    }
  };

  const getTableStructure = (table) => {
    return {
      header: {
        type: 'tableTitle',
        title: (
          <Text size="medium" color="plusDarkGrey" weight="medium">
            {isProvisory ? getScheduleProbabilityTitle(table.allocationProb) : table.name}
          </Text>
        ),
        icon: isProvisory ? (
          getScheduleProbabilityIcon(table.allocationProb)
        ) : (
          <FontAwesomeIcon size="lg" icon={faHistory} />
        ),
        label: getScheduleStateLabel(table.state, null, true),
        labelOffset: 0,
      },
      rows: table.classes.map((result) => {
        return {
          cells: [
            {
              content: result.idUc,
              cellProps: { color: theme.colors.primary, width: 1 },
            },
            { content: `${result.nameUc} - ${result.nameClass}`, cellProps: { width: 13 } },
            {
              content: (
                <StateLabelWrapper>
                  {getScheduleStateLabel(result.state, table.state)}
                </StateLabelWrapper>
              ),
              cellProps: { width: 1 },
            },
          ],
        };
      }),
    };
  };

  const tableStructure = useMemo(() => {
    return seriationHistoryResults.map((table, index) => {
      return <Table key={`${index}HistoryTableResults`} structure={getTableStructure(table)} />;
    });
  }, [currentDetails, seriationHistoryResults]);

  const resultsAvailable = isProvisory
    ? currentDetails?.allocationProb && currentDetails?.seriationDate
    : seriationHistoryResults.length > 0;

  return (
    <PageStructure
      loading={loading}
      expanded
      headerLeft={getCurrentHeader()}
      subHeader={
        resultsAvailable && (
          <SeriationDateWrapper>
            <SeriationDateText
              size="medium"
              color="plusDarkGrey"
              weight="regular"
              onClick={() => {
                dispatch(
                  navigateTo({
                    key: isProvisory
                      ? 'provisoryseriationhistorydetails'
                      : 'seriationhistorydetails',
                    params: { id: currentSelectedPhase, seriationId: seriationId },
                  })
                );
              }}
              icon={
                <Button
                  borderless
                  color={theme.colors.primary}
                  onClick={() => {
                    dispatch(
                      navigateTo({
                        key: isProvisory
                          ? 'provisoryseriationhistorydetails'
                          : 'seriationhistorydetails',
                        params: { id: currentSelectedPhase, seriationId: seriationId },
                      })
                    );
                  }}
                >
                  <FontAwesomeIcon size="2x" icon={faInfoCircle} />
                </Button>
              }
            >
              {t(isProvisory ? 'sgh.provisorySeriation' : 'sgh.finalSeriation')}
              {currentDetails?.seriationDate &&
                ` ${moment(currentDetails.seriationDate).format('DD/MM/YYYY | HH:mm')}`}
            </SeriationDateText>

            <AboutSimulationBarText size="medium" color="plusDarkGrey" weight="regular">
              {'|'}
            </AboutSimulationBarText>

            <AboutSimulationWrapper>
              <AboutSimulationText
                size="medium"
                color="plusDarkGrey"
                weight="regular"
                onClick={() =>
                  dispatch(
                    navigateTo({
                      key: 'schedulesGuide',
                      hash: 'heading-6',
                    })
                  )
                }
                icon={
                  <Button
                    noPadding
                    borderless
                    color={theme.colors.plusDarkGrey}
                    onClick={() => {
                      dispatch(
                        navigateTo({
                          key: 'schedulesGuide',
                          hash: 'heading-6',
                        })
                      );
                    }}
                  >
                    <FontAwesomeIcon size="2x" icon={faQuestionCircle} />
                  </Button>
                }
              >
                {t('sgh.aboutSeriation', { textOnly: true })}
              </AboutSimulationText>
            </AboutSimulationWrapper>
          </SeriationDateWrapper>
        )
      }
      mainContent={
        <>
          <ContentWrapper>
            {!loading && !resultsAvailable && (
              <NoDataMessage
                header={
                  isProvisory
                    ? t('sgh.noProvisorySeriationHistoryHeader')
                    : t('sgh.noSeriationHistoryHeader')
                }
                body={
                  !invalidSchedule
                    ? isProvisory
                      ? t('sgh.noProvisorySeriationHistoryBody')
                      : t('sgh.noSeriationHistoryBody')
                    : t('sgh.provisorySeriationInvalidSchedule')
                }
              />
            )}
            {!loading && resultsAvailable && <TablesWrapper>{tableStructure}</TablesWrapper>}
          </ContentWrapper>
        </>
      }
    />
  );
};

export default SeriationHistory;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
`;

const StateLabelWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TablesWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: 52px;
  }
`;

const DropdownWrapper = styled.div`
  width: 170px;
`;

const SeriationDateText = styled(Text)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const AboutSimulationWrapper = styled.div`
  align-items: center;
  display: flex;
  :hover {
    text-decoration: underline;
  }
`;

const AboutSimulationBarText = styled(Text)`
  line-height: 1;
  margin: 0px 10px;
  align-items: center;
  display: flex;
`;

const SeriationDateWrapper = styled.div`
  * {
    line-height: 1;
  }
  display: flex;
  align-items: center;
`;

const AboutSimulationText = styled(Text)`
  :hover {
    cursor: pointer;
  }
`;
