/**
 * Sidebar mobile component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

interface IProps {
  isSidebarOpen: boolean;
  sidebarLinks: {
    title: string;
    collapsed: boolean;
    path: string | null;
    key: string | null;
    link: string | null;
    selected: boolean;
    disabled: boolean;
    subLinks: {
      id: number;
      title: string;
      collapsed?: boolean;
      path: string | null;
      link: string | null;
      selected: boolean;
      disabled: boolean;
      subLinks?: any;
    }[];
  }[];
  collapseLink: (id: number, value?: boolean) => {};
  collapseSubLink: Function;
  goTo: (path: string | null, link: string | null) => {};
}

const Sidebar: React.FC<IProps> = ({
  isSidebarOpen,
  sidebarLinks,
  collapseLink,
  collapseSubLink,
  goTo,
}) => {
  return (
    <Wrapper>
      <Content isSidebarOpen={isSidebarOpen}>
        <StickyWrapper>
          <RefLinks>
            {sidebarLinks.map((link, index) => (
              <div key={`${link.title}-${index}`}>
                <RefLink
                  key={`${link.title}-${index}`}
                  disabled={link.disabled}
                  selected={link.selected}
                  collapsed={link.collapsed}
                  data-testid={`sidebar_link_title_${link.title}_${index}`}
                  onClick={() => {
                    link.subLinks
                      ? collapseLink(index, undefined)
                      : !link.disabled && goTo(link.path, link.link);
                  }}
                >
                  {link.title}
                  {link.subLinks && (
                    <Angle collapsed={link.collapsed}>
                      <FontAwesomeIcon size="lg" icon={faAngleDown} />
                    </Angle>
                  )}
                </RefLink>
                {link.subLinks && (
                  <RefSubLinks collapsed={link.collapsed}>
                    {link.subLinks.map((subLink, subLinkIndex) => (
                      <React.Fragment key={`${subLink.title}-${subLinkIndex}`}>
                        <RefSubLink
                          collapsed={link.collapsed}
                          disabled={subLink.disabled}
                          selected={subLink.selected}
                          data-testid={`sidebar_sublink_title_${subLink.title}_${subLinkIndex}`}
                          onClick={() => {
                            subLink.subLinks
                              ? collapseSubLink(index, subLinkIndex)
                              : !subLink.disabled && goTo(subLink.path, subLink.link);
                          }}
                        >
                          {subLink.title}
                          {subLink.subLinks && (
                            <Angle collapsed={subLink.collapsed ? true : false}>
                              <FontAwesomeIcon size="lg" icon={faAngleDown} />
                            </Angle>
                          )}
                        </RefSubLink>
                        {subLink.subLinks &&
                          subLink.subLinks.map((subSubLink, i) => (
                            <RefSubLink
                              key={`${subSubLink.title}-${subLinkIndex}-${i}`}
                              collapsed={subLink.collapsed ? true : false}
                              disabled={subSubLink.disabled}
                              selected={subSubLink.selected}
                              lighter
                              data-testid={`sidebar_sublink_title_${subSubLink.title}_${subLinkIndex}_${i}`}
                              onClick={() => {
                                !subSubLink.disabled && goTo(subSubLink.path, subSubLink.link);
                              }}
                            >
                              {subSubLink.title}
                            </RefSubLink>
                          ))}
                      </React.Fragment>
                    ))}
                  </RefSubLinks>
                )}
              </div>
            ))}
          </RefLinks>
        </StickyWrapper>
      </Content>
    </Wrapper>
  );
};

export default Sidebar;

const Wrapper = styled.div`
  position: relative;
  top: 0;
  bottom: 0;
  z-index: 900;
`;

const Content = styled.div<{ isSidebarOpen: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  width: ${({ theme }) => theme.sidebar.width};
  margin-left: ${({ isSidebarOpen }) => (isSidebarOpen ? '0' : '-380px')};
  transition: margin 0.3s ease-in-out;
  background-color: ${({ theme }) => theme.colors.plusDarkGrey};
`;

const StickyWrapper = styled.div`
  position: sticky;
  top: ${({ theme }) => theme.header.height};
`;

const RefLinks = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  height: calc(100% - 112px);
  max-height: calc(90vh - 36px);

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;
`;

interface IRefLink {
  selected: boolean;
  collapsed: boolean;
  disabled: boolean;
}

const RefLink = styled.div<IRefLink>`
  position: relative;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 0 16px 0 24px;
  font-size: 14px;
  font-weight: ${({ collapsed, selected }) => (collapsed || selected ? '500' : '400')};
  color: ${({ theme, selected, collapsed }) =>
    selected || collapsed
      ? selected
        ? theme.colors.white
        : theme.colors.white
      : theme.colors.regularGrey};
  text-transform: uppercase;

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.white};
  }

  &:after {
    content: '';
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.colors.primary : `rgba(0,0,0,0.5)`};
    cursor: not-allowed;
    position: absolute;
    left: 0;
    width: ${({ disabled }) => (!disabled ? `4px` : `100%`)};
    transform: scaleY(${({ selected, disabled }) => (!disabled ? (selected ? '1' : '0') : '1')});
    position: absolute;
    top: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    bottom: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    transition: transform 0.2s ease-in-out;
  }
`;

const Angle = styled.div<{ collapsed: boolean }>`
  transform: ${({ collapsed }) => (collapsed ? 'rotate(180deg)' : 'rotate(0deg);')};
  transition: transform 0.4s ease-in-out;
`;

const RefSubLinks = styled.div<{ collapsed: boolean }>`
  display: flex;
  flex-direction: column;
  opacity: ${({ collapsed }) => (collapsed ? '1' : '0')};
  transition: opacity 0.1s ease-in-out;
  background-color: ${({ theme }) => theme.colors.sideBar.subItemColor};
  text-transform: lowercase;
`;

interface IRefSubLink {
  collapsed: boolean;
  selected: boolean;
  disabled: boolean;
  lighter?: boolean;
}

const RefSubLink = styled.div<IRefSubLink>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: ${({ collapsed }) => (collapsed ? '48px' : '0')};
  opacity: ${({ collapsed }) => (collapsed ? '1' : '0')};
  font-size: 14px;
  font-weight: 300;
  transition: height 0.2s ease-in-out, opacity 0.1s ease-in-out;
  cursor: pointer;
  padding: 0 16px 0 40px;
  color: ${({ theme, selected, lighter }) =>
    selected ? theme.colors.white : lighter ? theme.colors.regularGrey : theme.colors.darkGrey};
  background-color: ${({ theme, lighter }) =>
    lighter ? theme.colors.sideBar.subSubItemColor : 'transparent'};

  &:hover {
    color: ${({ theme, disabled }) => !disabled && theme.colors.white};
  }
  &:after {
    content: '';
    background-color: ${({ theme, disabled }) =>
      !disabled ? theme.colors.primary : `rgba(0,0,0,0.4)`};
    cursor: not-allowed;
    position: absolute;
    left: 0;
    width: ${({ disabled }) => (!disabled ? `4px` : `100%`)};
    transform: scaleY(${({ selected, disabled }) => (!disabled ? (selected ? '1' : '0') : '1')});
    position: absolute;
    top: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    bottom: ${({ disabled }) => (!disabled ? `14px` : `0`)};
    transition: transform 0.2s ease-in-out;
  }
`;
