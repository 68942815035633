/**
 * Debits Table container utilities
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import moment from 'moment';
import {
  ITranslations,
  IPagination,
  IDebitStatus,
  IDebitData,
} from 'scenes/VirtualSecretary/Payments/types';

export const DEFAULT_STRUCTURE = (t: ITranslations) => ({
  header: {
    type: 'columnTitle',
    titles: [
      { text: t('payments.debitsTablePaymentDescription', { textOnly: true }) },
      { text: t('payments.debitsTablePaymentValue', { textOnly: true }) },
      { text: t('payments.debitsTableDueDate', { textOnly: true }) },
      { text: t('payments.debitsTablePaymentStatus', { textOnly: true }) },
      { text: '' },
    ],
  },
  rows: [],
});

export const DEBIT_STATUS: IDebitStatus = {
  UNPAID: 'unpaid',
  IN_DEBIT: 'inDebit',
  AWAITING_PAYMENT: 'awaitingPayment',
  IN_DEBIT_AWAITING_PAYMENT: 'inDebitAwaitingPayment',
};

export const DEBIT_STATUS_COLOR = (theme): { [key: string]: string } => ({
  [DEBIT_STATUS.UNPAID]: theme.colors.darkGrey,
  [DEBIT_STATUS.IN_DEBIT]: theme.colors.dangerRed,
  [DEBIT_STATUS.AWAITING_PAYMENT]: theme.colors.decisionYellow,
  [DEBIT_STATUS.IN_DEBIT_AWAITING_PAYMENT]: theme.colors.decisionYellow,
});

export const getMultipleValue = (multiples: IDebitData[]) => {
  return multiples.reduce((acc, curr) => acc + curr.value, 0);
};

export const getMultipleDate = (multiples: IDebitData[]) => {
  const dates = multiples.map((multiple) => moment(multiple.dueDate));
  return moment.min(dates).format('DD-MM-YYYY');
};

export const isRowSelected = (multiples, key: number) => {
  return multiples.some((item) => item.id === key);
};

export const DEFAULT_PAGINATION = (total: number): IPagination => ({
  hasNext: total > 20 ? true : false,
  hasPrevious: false,
  offset: 0,
  limit: 20,
  total,
});

export const findItem = (debitId, preSelectedDebits) =>
  preSelectedDebits.some((preSelectedDebit) => preSelectedDebit.debitId === debitId);
