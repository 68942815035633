/**
 * Payments Table container logic
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { useState, useEffect, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { actions } from 'store/rootSlices';

import * as selectors from '../../logic/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { ITranslations, IPendingDebitsEntry } from 'scenes/VirtualSecretary/Payments/types';
import { DEFAULT_STRUCTURE } from './utils';

export default (
  t: ITranslations,
  pendingDebits: IPendingDebitsEntry[],
  compileTableRow,
  openMbInfoModal,
  countdowns,
  loadingCancel,
  setLoadingCancel
) => {
  const theme = useContext(ThemeContext);
  const [structure, setStructure] = useState(DEFAULT_STRUCTURE(t));

  const registrationId = useSelector(selectors.getRegistrationId);

  const { setIsCancelPaymentsModalOpened } = actions.Payments;

  const { isCancelPaymentsModalOpened } = useSelector(selectors.getPaymentsSlice);

  const dispatch = useDispatch();

  const compileTableStructure = (
    t: ITranslations,
    pendingDebits: IPendingDebitsEntry[],
    theme,
    loadingCancel,
    setLoadingCancel
  ) => {
    let headerTitles = [
      { text: t('payments.debitsTablePaymentDescription', { textOnly: true }) },
      {
        text: t('payments.debitsTablePaymentValue', { textOnly: true }),
        style: { textAlign: 'center' },
      },
      {
        text: t('payments.debitsTableDueDate', { textOnly: true }),
        style: { textAlign: 'center' },
      },
      {
        text: t('payments.debitsTablePaymentStatus', { textOnly: true }),
        style: { textAlign: 'center' },
      },
      { text: '' },
      { text: '' },
      { text: '' },
    ];

    return {
      header: {
        type: 'columnTitle',
        titles: headerTitles,
      },
      rows: !pendingDebits.length
        ? ([] as any)
        : pendingDebits.reduce((acc, cur) => {
            let tmp = acc.concat(
              compileTableRow(
                cur,
                theme,
                t,
                openMbInfoModal,
                countdowns[cur.paymentRequestId],
                dispatch,
                loadingCancel,
                setLoadingCancel,
                registrationId,
                isCancelPaymentsModalOpened,
                setIsCancelPaymentsModalOpened
              )
            );
            return tmp;
          }, []),
    };
  };

  useEffect(() => {
    const tableStructure = compileTableStructure(
      t,
      pendingDebits,
      theme,
      loadingCancel,
      setLoadingCancel
    );
    setStructure(tableStructure);
  }, [pendingDebits, countdowns, loadingCancel]);

  return {
    theme,
    structure,
  };
};
