import { combineReducers } from '@reduxjs/toolkit';

import appSlice from 'app/logic/slice';
import appToolsSlice from 'app/AppTools/logic/slice';

// WARNING!! Don't erase the nextline as it is necessary for hygen to automatically import your components
// Define imports heree'
import logsstudentSlice from 'scenes/LogsStudent/logic/slice'
import logsSlice from 'scenes/Logs/logic/slice'
import examsCalendarlSlice from 'scenes/VirtualSecretary/ExamsCalendar/logic/slice';
import applicationdetailsSlice from 'scenes/VirtualSecretary/Applications/ApplicationDetails/logic/slice';
import newapplicationSlice from 'scenes/VirtualSecretary/Applications/NewApplication/logic/slice';
import registrationdetailsSlice from 'scenes/VirtualSecretary/RegistrationDetails/logic/slice';
import registrationsSlice from 'scenes/VirtualSecretary/Registrations/logic/slice';
import seriationhistorySlice from 'scenes/Schedules/SeriationHistory/logic/slice';
import seriationhistorydetailsSlice from 'scenes/Schedules/SeriationHistoryDetails/logic/slice';
import freeOptionsDropdownSlice from 'scenes/Schedules/MySchedule/containers/FreeOptionsDropdown/logic/slice';
import studentregistrationdropdownSlice from 'shared/containers/StudentRegistrationDropdown/logic/slice';
import applicationslistSlice from 'scenes/VirtualSecretary/Applications/ApplicationsList/logic/slice';
import paymentsSlice from 'scenes/VirtualSecretary/Payments/logic/slice';
import pendingDebitsPaymentSlice from 'scenes/VirtualSecretary/Payments/PendingDebitsPayment/logic/slice';
import gradesSlice from 'scenes/VirtualSecretary/Grades/logic/slice';
import assessmentchoiceSlice from 'scenes/VirtualSecretary/AssessmentChoice/logic/slice';
import examregistrationSlice from 'scenes/VirtualSecretary/ExamRegistration/logic/slice';
import subscriptionrenewalSlice from 'scenes/VirtualSecretary/SubscriptionRenewal/logic/slice';
import classsupoortSlice from 'scenes/VirtualSecretary/ClassSupoort/logic/slice';
import curricularplanSlice from 'scenes/VirtualSecretary/CurricularPlan/CurricularPlan/logic/slice';
import curricularUnitDetailsSlice from 'scenes/VirtualSecretary/CurricularPlan/CurricularUnitDetails/logic/slice';
import personaldataSlice from 'scenes/VirtualSecretary/PersonalData/logic/slice';
import createdScheduleSlice from 'scenes/Schedules/CreatedSchedules/containers/CreatedSchedule/logic/slice';
import myschedulesSlice from 'scenes/Schedules/CreatedSchedules/logic/slice';
import mandatoryOptionsDropdownSlice from 'scenes/Schedules/MySchedule/containers/MandatoryOptionsDropdown/logic/slice';
import toasterSlice from 'shared/containers/Toaster/logic/slice';
import toasterActionSlice from 'shared/containers/ToasterAction/logic/slice';
import schedulesSlice from 'scenes/Schedules/MySchedule/logic/slice';
import freeOptionsListSlice from 'scenes/Schedules/FreeOptionsList/logic/slice';
import freeOptionsTableSlice from 'scenes/Schedules/containers/FreeOptionsTable/logic/slice';
import schedulesHomeSlice from 'scenes/Schedules/SchedulesHome/logic/slice';
import schedulesGuideSlice from 'scenes/Schedules/SchedulesGuide/logic/slice';
import gradesGuideSlice from 'scenes/GradesManagement/GradesGuide/logic/slice';
import homeSlice from 'scenes/Home/logic/slice';
import gradesManagementSlice from 'scenes/GradesManagement/GradesManagement/logic/slice';
import editGradesSlice from 'scenes/GradesManagement/EditGrades/logic/slice';
import gradesDetailsSlice from 'scenes/GradesManagement/GradesDetails/logic/slice';
import gradesHistorySlice from 'scenes/GradesManagement/GradesHistory/logic/slice';
import helpSlice from 'scenes/Help/logic/slice';
import breadcrumbSlice from 'shared/containers/Breadcrumb/logic/slice';
import newcertificateSlice from 'scenes/VirtualSecretary/Certificates/NewCertificate/logic/slice';
import certificateslistSlice from 'scenes/VirtualSecretary/Certificates/CertificatesList/logic/slice';
import certificatesdetailsSlice from 'scenes/VirtualSecretary/Certificates/CertificatesDetails/logic/slice';
import certificateValidatorSlice from 'scenes/VirtualSecretary/CertificateValidator/logic/slice';
import improvementExamEnrollmentSlice from 'scenes/VirtualSecretary/Enrollments/ImprovementExamEnrollment/logic/slice';
import examEnrollmentSlice from 'scenes/VirtualSecretary/Enrollments/ExamEnrollment/logic/slice';
import evaluationTypeSelectionSlice from 'scenes/VirtualSecretary/Enrollments/EvaluationTypeSelection/logic/slice';
import enrollmentRenewalSlice from 'scenes/VirtualSecretary/Enrollments/EnrollmentRenewal/logic/slice';
import exams from 'scenes/VirtualSecretary/Enrollments/Exams/logic/slice';
import transferalCompetence from 'scenes/VirtualSecretary/Enrollments/TransferalCompetence/logic/slice';
import specialExams from 'scenes/VirtualSecretary/Enrollments/SpecialExamsEnroll/logic/slice';


export const rootReducer = combineReducers({
  LogsStudent: logsstudentSlice.reducer,
  Logs: logsSlice.reducer,
  ExamsCalendar: examsCalendarlSlice.reducer,
  ApplicationDetails: applicationdetailsSlice.reducer,
  NewApplication: newapplicationSlice.reducer,
  RegistrationDetails: registrationdetailsSlice.reducer,
  Registrations: registrationsSlice.reducer,
  SeriationHistory: seriationhistorySlice.reducer,
  SeriationHistoryDetails: seriationhistorydetailsSlice.reducer,
  FreeOptionsDropdown: freeOptionsDropdownSlice.reducer,
  StudentRegistrationDropdown: studentregistrationdropdownSlice.reducer,
  ApplicationsList: applicationslistSlice.reducer,
  Payments: paymentsSlice.reducer,
  PendingDebitsPayment: pendingDebitsPaymentSlice.reducer,
  Grades: gradesSlice.reducer,
  AssessmentChoice: assessmentchoiceSlice.reducer,
  ExamRegistration: examregistrationSlice.reducer,
  SubscriptionRenewal: subscriptionrenewalSlice.reducer,
  ClassSupoort: classsupoortSlice.reducer,
  CurricularPlan: curricularplanSlice.reducer,
  CurricularUnitDetails: curricularUnitDetailsSlice.reducer,
  PersonalData: personaldataSlice.reducer,
  CreatedSchedule: createdScheduleSlice.reducer,
  MySchedules: myschedulesSlice.reducer,
  FreeOptionsList: freeOptionsListSlice.reducer,
  FreeOptionsTable: freeOptionsTableSlice.reducer,
  MandatoryOptionsDropdown: mandatoryOptionsDropdownSlice.reducer,
  Toaster: toasterSlice.reducer,
  ToasterAction: toasterActionSlice.reducer,
  Schedules: schedulesSlice.reducer,
  SchedulesHome: schedulesHomeSlice.reducer,
  SchedulesGuide: schedulesGuideSlice.reducer,
  GradesGuide: gradesGuideSlice.reducer,
  Home: homeSlice.reducer,
  GradesManagement: gradesManagementSlice.reducer,
  EditGrades: editGradesSlice.reducer,
  GradesDetails: gradesDetailsSlice.reducer,
  GradesHistory: gradesHistorySlice.reducer,
  Help: helpSlice.reducer,
  App: appSlice.reducer,
  AppTools: appToolsSlice.reducer,
  Breadcrumb: breadcrumbSlice.reducer,
  NewCertificate: newcertificateSlice.reducer,
  CertificatesList: certificateslistSlice.reducer,
  CertificatesDetails: certificatesdetailsSlice.reducer,
  CertificateValidator: certificateValidatorSlice.reducer,
  ImprovementExamEnrollment: improvementExamEnrollmentSlice.reducer,
  ExamEnrollment: examEnrollmentSlice.reducer,
  EvaluationTypeSelection: evaluationTypeSelectionSlice.reducer,
  EnrollmentRenewal: enrollmentRenewalSlice.reducer,
  Exams: exams.reducer,
  TransferalCompetence: transferalCompetence.reducer,
  SpecialExams: specialExams.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export const actions = {
  LogsStudent: logsstudentSlice.actions,
  Logs: logsSlice.actions,
  ExamsCalendar: examsCalendarlSlice.actions,
  ApplicationDetails: applicationdetailsSlice.actions,
  NewApplication: newapplicationSlice.actions,
  NewCertificate: newcertificateSlice.actions,
  RegistrationDetails: registrationdetailsSlice.actions,
  Registrations: registrationsSlice.actions,
  SeriationHistory: seriationhistorySlice.actions,
  SeriationHistoryDetails: seriationhistorydetailsSlice.actions,
  FreeOptionsDropdown: freeOptionsDropdownSlice.actions,
  StudentRegistrationDropdown: studentregistrationdropdownSlice.actions,
  ApplicationsList: applicationslistSlice.actions,
  CertificatesList: certificateslistSlice.actions,
  CertificatesDetails: certificatesdetailsSlice.actions,
  CertificatesValidator: certificateValidatorSlice.actions,
  Payments: paymentsSlice.actions,
  PendingDebitsPayment: pendingDebitsPaymentSlice.actions,
  Grades: gradesSlice.actions,
  AssessmentChoice: assessmentchoiceSlice.actions,
  ExamRegistration: examregistrationSlice.actions,
  SubscriptionRenewal: subscriptionrenewalSlice.actions,
  ClassSupoort: classsupoortSlice.actions,
  CurricularPlan: curricularplanSlice.actions,
  CurricularUnitDetails: curricularUnitDetailsSlice.actions,
  PersonalData: personaldataSlice.actions,
  CreatedSchedule: createdScheduleSlice.actions,
  MySchedules: myschedulesSlice.actions,
  FreeOptionsList: freeOptionsListSlice.actions,
  FreeOptionsTable: freeOptionsTableSlice.actions,
  MandatoryOptionsDropdown: mandatoryOptionsDropdownSlice.actions,
  Toaster: toasterSlice.actions,
  ToasterAction: toasterActionSlice.actions,
  Schedules: schedulesSlice.actions,
  SchedulesHome: schedulesHomeSlice.actions,
  SchedulesGuide: schedulesGuideSlice.actions,
  GradesGuide: gradesGuideSlice.actions,
  Home: homeSlice.actions,
  GradesManagement: gradesManagementSlice.actions,
  EditGrades: editGradesSlice.actions,
  GradesDetails: gradesDetailsSlice.actions,
  GradesHistory: gradesHistorySlice.actions,
  Help: helpSlice.actions,
  App: appSlice.actions,
  AppTools: appToolsSlice.actions,
  Breadcrumb: breadcrumbSlice.actions,
  ImprovementExamEnrollment: improvementExamEnrollmentSlice.actions,
  ExamEnrollment: examEnrollmentSlice.actions,
  EvaluationTypeSelection: evaluationTypeSelectionSlice.actions,
  EnrollmentRenewal: enrollmentRenewalSlice.actions,
  Exams: exams.actions,
  SpecialExams: specialExams.actions,
  TransferalCompetence: transferalCompetence.actions,
};
