/**
 * PendingDebitsPayment slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDropdownOption } from 'shared/types';
import { ICallParams, IDebitData } from '../../types';
import { IMbwayResponse } from '../types';

const initialState = {
  currentStep: 1,
  showMorePayments: false,
  paymentMethod: undefined as string | undefined,
  mbwayForm: {
    countryDropdown: {
      input: '',
      countryValue: 'pt',
    },
  },
  // other debits table filters  (SelectDetails step)
  filterParams: {
    type: '' as string,
    search: '' as string,
  },
  debitsFilterTypes: [] as IDropdownOption[],
  debitsSubmissionCompleted: false,
  mbwayResponse: {} as IMbwayResponse | undefined,
  allowMbwayPayment: true as boolean,
  loadingStatus: {
    loading: true,
    loadingPaymentsTable: true,
  },
  errorStatus: {
    errLoadingPhoneNumber: false,
    errLoadingPendingDebits: false,
    errLoadingDebits: false,
  },
};

export default createSlice({
  name: 'PendingDebitsPayment',
  initialState,
  reducers: {
    onMount: (state, action: PayloadAction<number | undefined>) => {},
    onUnmount: () => {},
    fetchDebits: (state, action: PayloadAction<ICallParams>) => {
      state.loadingStatus.loadingPaymentsTable = true;
    },
    setShowMorePayments: (state, action: PayloadAction<boolean>) => {
      state.showMorePayments = action.payload;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setAllowMbwayPayment: (state, action: PayloadAction<boolean>) => {
      state.allowMbwayPayment = action.payload;
    },
    setDebitsSubmissionCompleted: (state, action: PayloadAction<boolean>) => {
      state.debitsSubmissionCompleted = action.payload;
    },
    setPaymentMethod: (state, action: PayloadAction<string | undefined>) => {
      state.paymentMethod = action.payload;
    },
    submitMbwayDebitsRequest: (state, action: PayloadAction<any>) => {},
    setMbwayResponse: (state, action: PayloadAction<IMbwayResponse>) => {
      state.mbwayResponse = action.payload;
    },
    setMbwayForm: (state, action: PayloadAction<any>) => {
      state.mbwayForm = action.payload;
    },
    fetchPhoneNumber: () => {},
    fetchPendingDebits: () => {},
    submitMbDebitsRequest: (state, action: PayloadAction<IDebitData[]>) => {},
    setDebitsFilterTypes: (state, action: PayloadAction<any>) => {
      state.debitsFilterTypes = action.payload.map((filter, key) => ({
        key: key,
        text: filter,
        value: filter,
      }));
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    resetPendingDebits: (state) => initialState,
  },
});
