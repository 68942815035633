/**
 * Created Schedule container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { call, select, put, takeLatest } from 'redux-saga/effects';

import { t } from 'app';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';
import API from 'api';

import { immutableReplace } from 'utils';

import { IUpdateSchedule } from 'scenes/Schedules/CreatedSchedules/containers/CreatedSchedule/types';

interface IUpdateScheduleSaga {
  type: 'CreatedSchedule/updateSchedule';
  payload: IUpdateSchedule;
}

function* updateScheduleSaga(action: IUpdateScheduleSaga) {
  try {
    yield put(actions.MySchedules.setSavingMySchedules(true));
    const registration = yield select(selectors.getRegistration);
    const { id, formData } = action.payload;
    const mySchedules = yield select(selectors.getMySchedules);

    const indexOf = mySchedules.findIndex((schedule) => schedule.id === id);
    const updatedSchedule = { ...mySchedules[indexOf], ...{ name: formData.name } };

    yield call(API.sgh.patchSchedules.call, {
      registrationId: registration,
      scheduleId: id,
      name: formData.name,
      preference: mySchedules[indexOf].preference,
    });

    yield put(
      actions.MySchedules.setSchedules(immutableReplace(mySchedules, indexOf, updatedSchedule))
    );

    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionSuccessUpdateSchedule_plural', { textOnly: true }),
        icon: 'check',
        type: 'success',
      })
    );
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionErrorUpdateSchedule_plural', { textOnly: true }),
        icon: 'error',
        type: 'danger',
      })
    );
  } finally {
    yield put(actions.MySchedules.setSavingMySchedules(false));
  }
}

export default function* watcherSignin() {
  yield takeLatest('CreatedSchedule/updateSchedule', updateScheduleSaga);
}
