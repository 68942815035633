/**
 * FormGroup
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Text } from '@paco_ua/pacoui';

import { useFormContext } from 'react-hook-form';

import { t } from 'app';

interface IProps extends React.HTMLAttributes<HTMLInputElement> {
  structure: any;
  onChange?: any;
  name: string;
}

const FormGroup: React.FC<IProps> = ({ name, children }) => {
  const [error, setError] = useState(null);
  const form = useFormContext();

  const childrenValues: any[] = [];

  React.Children.forEach(children, (child: any) => {
    const currentValue = form.getValues(`${name}.${child.props.nameKey}`);
    if (currentValue) {
      childrenValues.push(currentValue);
    }
  });

  return (
    <Wrapper>
      <FormGroupTitleWrapper>
        <Text size="mediumSmall" weight="medium" color="plusDarkGrey">
          {t('applications.fileUploaderTitle', { textOnly: true })}
        </Text>
        <Text size="xSmall" weight="regular" color="darkGrey">
          {t('applications.fileUploaderSubtitle', { textOnly: true })}
        </Text>
      </FormGroupTitleWrapper>
      <FormGroupFilesWrapper>
        {children &&
          React.Children.map(children, (child: any) => {
            let disabled = false;

            if (form && child.props?.nameKey && !!childrenValues.length) {
              const value = form.getValues(`${name}.${child.props.nameKey}`);
              if (value !== null && value !== undefined) {
                disabled = false;
              } else {
                disabled = true;
              }
            }

            return (
              <ChildWrapper disabled={disabled}>
                {React.cloneElement(child, {
                  onError: (error) => {
                    setError(error);
                  },
                })}
              </ChildWrapper>
            );
          })}
      </FormGroupFilesWrapper>
      <MessageArea>
        {error && (
          <Text size="xSmall" weight="medium" color="dangerRed">
            * {error}
          </Text>
        )}
      </MessageArea>
    </Wrapper>
  );
};

FormGroup.defaultProps = {
  children: [],
};

export default FormGroup;

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 24px;
`;

const FormGroupTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div:nth-child(2) {
    margin-top: 8px;
    margin-top: 8px;
  }
`;

const FormGroupFilesWrapper = styled.div`
  margin-top: 12px;

  > div:not(:first-child) {
    margin-top: 12px;
  }
`;

const MessageArea = styled.div``;

const ChildWrapper = styled.div<{ disabled?: boolean }>`
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  button {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }
`;
