/**
 * LabelledCell component
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';

import { actions } from 'store/rootSlices';
import { Text } from '@paco_ua/pacoui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { useDispatch } from 'react-redux';

interface IProps {
  content: {
    text: string | number | JSX.Element;
    props?: any;
  };
  label: {
    text: string | number | JSX.Element;
    props?: any;
    isLink?: boolean;
    ucOptionCode?: number;
  };
}

const LabeledCell: React.FC<IProps> = ({ content, label }) => {
  const { getUcOptions } = actions.CurricularPlan;

  const dispatch = useDispatch();

  const handleLink = () => {
    if (label.ucOptionCode) {
      // ucOptions
      dispatch(getUcOptions({ name: content.text.toString(), code: label.ucOptionCode }));
    }
  };

  return (
    <Wrapper>
      <Text {...content.props} fontWeight="500" size="article">
        {content.text}
      </Text>
      <LabelWrapper>
        {label.isLink && (
          <LabelIcon>
            <FontAwesomeIcon icon={faEye} size={'sm'} />
          </LabelIcon>
        )}
        {label.isLink ? (
          <LinkContainer onClick={handleLink}>
            <Text {...label.props} fontWeight="500" size="xSmall">
              {label.text}
            </Text>
          </LinkContainer>
        ) : (
          <Text {...label.props} fontWeight="500" size="xSmall">
            {label.text}
          </Text>
        )}
      </LabelWrapper>
    </Wrapper>
  );
};

export default LabeledCell;

const Wrapper = styled.div``;

const LinkContainer = styled.div`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 3px;
`;

const LabelIcon = styled.div`
  margin-right: 6px;
`;
