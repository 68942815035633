/**
 * NewCertificate scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext, useState, useMemo, useCallback } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';

import { actions } from 'store/rootSlices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import { AnimatedBackground, Text, Button, Segment, Modal } from '@paco_ua/pacoui';

import NewCertificateSelectRegistration from './containers/NewCertificateSelectRegistration';
import NewCertificateSelectType from './containers/NewCertificateSelectType';
import NewCertificateStatement from './containers/NewCertificateStatement';
import NewCertificateSubmission from './containers/NewCertificateSubmission';

import FormManager from 'shared/containers/FormManager';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useWarningModal } from './hooks/useWarningModal';

const NewCertificate = () => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);

  const registrations = useSelector(selectors.getRegistrations);
  const {
    isExempt,
    englishWarning,
    isDigital,
    currentStep,
    certificateData,
    certificateCosts,
    selectedCertificateType,
    loadingSubmission,
    loadingStatus,
    refreshPage,
    hasModeration,
  } = useSelector(selectors.getNewCertificateSlice);

  const {
    onMount,
    onUnmount,
    getCertificateForm,
    setCurrentStep,
    submitCertificate,
    resetSelectedCertificateType,
    resetSubmissionTabData,
    resetFormTabData,
    setLoadingStatus,
    resetPage,
    setRefreshPage,
    onLoadPage,
    setHasModeration,
  } = actions.NewCertificate;

  const dispatch = useDispatch();

  const currentLocale = useSelector(selectors.getAppSlice);
  const user = useSelector(selectors.getUser);

  const [loadingFile, setLoadingFile] = useState({});
  const [newCertificateReset, setNewCertificateReset] = useState(false);

  const {
    shouldRenderModal,
    onClose,
    setShowModal,
    submitButtonText,
    showModal,
    modalContent,
  } = useWarningModal({ form: certificateData.form });

  const steps = [
    t('certificates.requestFirstStep'),
    t('certificates.requestSecondStep'),
    t('certificates.requestThirdStep'),
    t('certificates.requestFourthStep'),
  ];

  const stepStyles = {
    done: {
      backgroundColor: theme.colors.primary,
      color: theme.colors.white,
      fontWeight: 500,
      border: 'none',
      borderStyle: null,
      checkTick: true,
    },
    current: {
      backgroundColor: theme.colors.lightGrey,
      color: theme.colors.primary,
      fontWeight: 500,
      border: 'top',
      borderStyle: `2px solid ${theme.colors.primary}`,
      checkTick: false,
    },
    toDo: {
      backgroundColor: 'transparent',
      color: theme.colors.regularGrey,
      fontWeight: 400,
      border: 'all',
      borderStyle: `1px solid ${theme.colors.regularGrey}`,
      checkTick: false,
    },
  };

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  useEffect(() => {
    if (refreshPage) {
      dispatch(resetPage());
      dispatch(onMount());
    }
  }, [refreshPage]);

  useEffect(() => {
    if (currentStep === 3 && certificateData.form === null) {
      // get formschema when the user goes to the statement tab from the select certificate types tab
      dispatch(getCertificateForm());
    }
  }, [currentStep]);

  useEffect(() => {
    if (newCertificateReset) {
      dispatch(setLoadingStatus({ fieldName: 'loading', fieldValue: false }));
      setNewCertificateReset(false);
    }
  }, [newCertificateReset]);

  const getFormSchema = useMemo(() => {
    if (certificateData.form) {
      const {
        additionalDocuments,
        details,
        requestedCertificate,
        mandatoryDocuments,
      } = certificateData.form;

      return [
        ...(additionalDocuments.values && additionalDocuments.values.length
          ? additionalDocuments.values
          : []),
        ...(details.values && details.values.length ? details.values : []),
        ...(requestedCertificate.values && requestedCertificate.values.length
          ? requestedCertificate.values
          : []),
        ...(mandatoryDocuments.values && mandatoryDocuments.values.length
          ? mandatoryDocuments.values
          : []),
      ];
    } else {
      return [];
    }
  }, [certificateData]);

  const _renderModal = () => {
    return (
      <Modal
        type="confirmation"
        open={showModal}
        onClose={() => onClose(true)}
        submitButtonText={submitButtonText}
        onSubmit={() => onClose()}
      >
        <ModalStatement>
          <Text size="medium" color={theme.colors.primary} fontWeight="500">
            {modalContent?.title}
          </Text>
          {modalContent?.markdown && <ReactMarkdown>{`${modalContent?.markdown}`}</ReactMarkdown>}
          <Text size="small" color={theme.colors.primary} fontWeight="400">
            {modalContent?.message}
          </Text>
        </ModalStatement>
      </Modal>
    );
  };

  const onSubmitCertificate = (data) => {
    const exemptionHasModeration = certificateData?.form?.requestedCertificate?.values
      ?.find((item) => item?.nameKey === 'exemptionMotive')
      ?.values?.find((motive) => motive?.valueId === data?.exemptionMotive)?.hasMotivation;

    if (exemptionHasModeration) {
      dispatch(setHasModeration(true));
    } else {
      dispatch(setHasModeration(false));
    }

    if (shouldRenderModal()) {
      setShowModal(true);
    } else {
      dispatch(setCurrentStep(currentStep + 1));
    }
  };

  const getNextButtonDisabledState = (step) => {
    if (step === 1 && certificateData.registrationId) {
      return false;
    } else if (step === 2 && selectedCertificateType.code) {
      return false;
    } else if (step === 3) {
      return false;
    } else {
      return true;
    }
  };

  

  const isPedagogicalDossierCertificate = useMemo(() => {
    //checking the id for dpUc certificates. Check with backend if this condition needs to be changed
    if (selectedCertificateType.id === 253) return true;
    return false;
  }, [selectedCertificateType]);

  return (
    <PageStructure
      loading={loadingStatus.loading || !registrations}
      headerLeft={<Header title={t('certificates.requestTitle')} />}
      mainContent={
        <>
          <ApplicationStepsWrapper>
            {steps.map((step, index) => {
              if (index + 1 < currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.done.backgroundColor}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.done.color}
                      fontWeight={`${stepStyles.done.fontWeight}`}
                    >
                      {step}
                    </TabText>
                    <CheckTickWrapper>
                      <FontAwesomeIcon icon={faCheck} />
                    </CheckTickWrapper>
                  </ApplicationStep>
                );
              } else if (index + 1 === currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.current.backgroundColor}
                    border={stepStyles.current.border}
                    borderStyle={stepStyles.current.borderStyle}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.current.color}
                      fontWeight={`${stepStyles.current.fontWeight}`}
                    >
                      {step}
                    </TabText>
                  </ApplicationStep>
                );
              } else if (index + 1 > currentStep) {
                return (
                  <ApplicationStep
                    key={`step-${index}`}
                    backgroundColor={stepStyles.toDo.backgroundColor}
                    border={stepStyles.toDo.border}
                    borderStyle={stepStyles.toDo.borderStyle}
                  >
                    <TabText
                      size="medium"
                      color={stepStyles.toDo.color}
                      fontWeight={`${stepStyles.toDo.fontWeight}`}
                    >
                      {step}
                    </TabText>
                  </ApplicationStep>
                );
              }
            })}
          </ApplicationStepsWrapper>
          <FormManager formSchema={getFormSchema}>
            {(
              { handleSubmit, getValues, watch, reset, setValue },
              formElements,
              formElementsData
            ) => {
              return (
                <>
                  {currentStep === 1 && <NewCertificateSelectRegistration />}
                  {currentStep === 2 && <NewCertificateSelectType />}
                  {currentStep === 3 && formElements && formElementsData && (
                    <NewCertificateStatement
                      formElements={formElements}
                      formElementsData={formElementsData}
                      formElementsWatch={watch}
                      resetField={(field) => {
                        setValue(field, []);
                      }}
                      setLoadingFile={setLoadingFile}
                    />
                  )}

                  {currentStep === 4 && (
                    <NewCertificateSubmission formElementsGetValues={getValues} />
                  )}
                  <Column stretched>
                    <NavigationButtonsWrapper currentStep={currentStep}>
                      {currentStep !== 1 && (
                        <Button
                          decision
                          disabled={
                            loadingSubmission.payNow ||
                            loadingSubmission.payLater ||
                            loadingStatus.tabLoading
                          }
                          onClick={() => {
                            setLoadingFile({});
                            reset(); // reset form
                            dispatch(resetPage());
                            dispatch(setRefreshPage(true));
                            //dispatch(onLoadPage());
                            setNewCertificateReset(true);
                          }}
                        >
                          {t('certificates.requestCancelButton')}
                        </Button>
                      )}
                      <BackwardForwardButtonsWrapper>
                        {currentStep !== 1 && (
                          <Button
                            decision
                            disabled={loadingSubmission.payNow || loadingSubmission.payLater}
                            onClick={() => {
                              if (currentStep === 2) {
                                dispatch(resetSelectedCertificateType());
                              } else if (currentStep === 3) {
                                dispatch(
                                  setLoadingStatus({ fieldName: 'tabLoading', fieldValue: true })
                                );

                                setLoadingFile({});
                                reset(); // reset form
                                dispatch(resetFormTabData());
                              } else if (currentStep === 4) {
                                dispatch(resetSubmissionTabData());
                              }
                              dispatch(setCurrentStep(currentStep - 1));
                            }}
                          >
                            {t('certificates.requestPreviousButton')}
                          </Button>
                        )}
                        {(currentStep === 1 || currentStep === 2) && (
                          <Button
                            action
                            color={theme.colors.primary}
                            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                            onClick={() => {
                              dispatch(
                                setLoadingStatus({ fieldName: 'tabLoading', fieldValue: true })
                              );
                              dispatch(resetFormTabData());
                              dispatch(setCurrentStep(currentStep + 1));
                            }}
                            disabled={
                              getNextButtonDisabledState(currentStep) || loadingStatus.tabLoading
                            }
                          >
                            {t('certificates.requestNextButton')}
                          </Button>
                        )}
                        {currentStep === 3 && (
                          <Button
                            action
                            color={theme.colors.primary}
                            disabled={
                              loadingStatus.tabLoading ||
                              loadingStatus.costLoading ||
                              Object.keys(loadingFile).some(
                                (itemKey) => loadingFile[itemKey] === true
                              )
                            }
                            rightIcon={<FontAwesomeIcon icon={faChevronRight} />}
                            onClick={handleSubmit(onSubmitCertificate)}
                          >
                            {t('certificates.requestNextButton')}
                          </Button>
                        )}
                        {currentStep === 3 && _renderModal()}
                        {currentStep === 4 && (
                          <SubmitButtonsWrapper>
                            {certificateCosts.totalCost === 0 || hasModeration ? (
                              <Button
                                action
                                loading={loadingSubmission.payLater}
                                disabled={loadingSubmission.payNow}
                                color={theme.colors.primary}
                                //rightIcon={<FontAwesomeIcon icon={faCheck} />}
                                onClick={() => {
                                  dispatch(
                                    submitCertificate({
                                      certificateData: certificateData,
                                      certificateOptions: watch(),
                                      selectedCertificateType: selectedCertificateType,
                                      payNow: false,
                                      isExempt: isExempt,
                                    })
                                  );
                                }}
                              >
                                {t('certificates.submitWait')}
                              </Button>
                            ) : (
                              <>
                                <Button
                                  action
                                  loading={loadingSubmission.payLater}
                                  disabled={loadingSubmission.payLater}
                                  color={theme.colors.primary}
                                  //rightIcon={<FontAwesomeIcon icon={faCheck} />}
                                  onClick={() => {
                                    dispatch(
                                      submitCertificate({
                                        certificateData: certificateData,
                                        certificateOptions: watch(),
                                        selectedCertificateType: selectedCertificateType,
                                        payNow: false,
                                      })
                                    );
                                  }}
                                >
                                  {t('certificates.payLater')}
                                </Button>
                                <Button
                                  primary
                                  loading={loadingSubmission.payNow}
                                  disabled={loadingSubmission.payNow}
                                  color={theme.colors.primary}
                                  full
                                  //rightIcon={<FontAwesomeIcon icon={faCheck} />}
                                  onClick={() => {
                                    dispatch(
                                      submitCertificate({
                                        certificateData: certificateData,
                                        certificateOptions: watch(),
                                        selectedCertificateType: selectedCertificateType,
                                        payNow: true,
                                      })
                                    );
                                  }}
                                >
                                  {t('certificates.payNow')}
                                </Button>
                              </>
                            )}
                          </SubmitButtonsWrapper>
                        )}
                      </BackwardForwardButtonsWrapper>
                    </NavigationButtonsWrapper>
                  </Column>
                </>
              );
            }}
          </FormManager>
        </>
      }
      secondaryContentOffset={113}
      secondaryContent={
        (currentStep === 3 || currentStep === 4) && (
          <>
            <SidebarWrapper>
              <Segment
                padding={'38px 16px'}
                footer={<>
                  {isDigital && 
                    <>
                      <ExemptionWrapper>
                        <FontAwesomeIcon
                            color={'red'}
                            icon={faInfoCircle}
                            size={'2x'}
                        ></FontAwesomeIcon>
                        <ExemptionNote>
                          <Text color="plusDarkGrey" weight="regular" size="article">
                            {t('certificates.expiredNotice')}
                          </Text>
                        </ExemptionNote>
                      </ExemptionWrapper>
                    </>
                  }
                  {isExempt ? (
                    <ExemptionWrapper>
                      <FontAwesomeIcon
                        color={'darkGrey'}
                        icon={faInfoCircle}
                        size={'2x'}
                      ></FontAwesomeIcon>
                      <ExemptionNote>
                        <Text color="darkGrey" weight="bold" size="article">
                          {t('certificates.costNote')}
                        </Text>
                        <NoteDescriptionText color="darkGrey" weight="regular" size="article">
                          {t('certificates.costNoteDescription')}
                        </NoteDescriptionText>
                      </ExemptionNote>
                    </ExemptionWrapper>
                  ) : isPedagogicalDossierCertificate && englishWarning ? (
                    <ExemptionWrapper>
                      <FontAwesomeIcon
                        color={'darkGrey'}
                        icon={faInfoCircle}
                        size={'2x'}
                      ></FontAwesomeIcon>
                      <ExemptionNote>
                        <Text color="darkGrey" weight="bold" size="article">
                          {t('certificates.certInvalidEngWarning')}
                        </Text>
                        <NoteDescriptionText color="darkGrey" weight="regular" size="article">
                          {t('certificates.certInvalidEngMessage')}
                        </NoteDescriptionText>
                      </ExemptionNote>
                    </ExemptionWrapper>
                  ) : null}
                  </>
                }
              >
                <SegmentHeader>
                  <Text color="plusDarkGrey" weight="regular" size="large">
                    {t('certificates.associatedCosts')}
                  </Text>
                </SegmentHeader>

                {loadingStatus.costLoading && (
                  <LoadingWrapper height={'20px'}>
                    <AnimatedBackground height={theme.sizes.medium} width="80%" />
                  </LoadingWrapper>
                )}

                {!loadingStatus.costLoading &&
                  Object.keys(certificateCosts.costs).map((key) => {
                    return (
                      <CertificateCostWrapper key={`certCost-${key}`}>
                        <Text color="darkGrey" size="article" weight="regular">
                          {t(`certificates.${certificateCosts.costs[key].type}`)}
                        </Text>

                        {certificateCosts.costs[key].copies > 1 && (
                          <Text color="plusDarkGrey" weight="regular" size="article">
                            {`${certificateCosts.costs[key].copies} x ${parseFloat(
                              certificateCosts.costs[key].value.toFixed(2)
                            ).toLocaleString(currentLocale, { minimumFractionDigits: 2 })}€`}
                          </Text>
                        )}

                        {certificateCosts.costs[key].copies === 1 && (
                          <Text color="plusDarkGrey" weight="regular" size="article">
                            {`${parseFloat(
                              certificateCosts.costs[key].value.toFixed(2)
                            ).toLocaleString(currentLocale, { minimumFractionDigits: 2 })}€`}
                          </Text>
                        )}
                      </CertificateCostWrapper>
                    );
                  })}

                <LineSeparator />

                {loadingStatus.costLoading && (
                  <LoadingWrapper height={'20px'}>
                    <AnimatedBackground height={theme.sizes.medium} width="80%" />
                  </LoadingWrapper>
                )}

                {!loadingStatus.costLoading && (
                  <CertificateCostWrapper>
                    <Text color="plusDarkGrey" weight="regular" size="article">
                      {t('certificates.totalPayment')}
                    </Text>

                    {!loadingStatus.costLoading && (
                      <Text color="plusDarkGrey" weight="regular" size="article">
                        {`${parseFloat(
                          certificateCosts.totalCost.toFixed(2)
                        ).toLocaleString(currentLocale, { minimumFractionDigits: 2 })}€`}
                      </Text>
                    )}
                  </CertificateCostWrapper>
                )}
              </Segment>
            </SidebarWrapper>
          </>
        )
      }
    />
  );
};

export default NewCertificate;

const LoadingWrapper = styled.div<{ height: string }>`
  width: 100%;
  height: ${({ height }) => `${height}`};
`;

const ApplicationStepsWrapper = styled.div`
  display: flex;
  margin-bottom: 32px;
  overflow-y: hidden;
  overflow-x: auto;
`;

const ApplicationStep = styled.div<{
  backgroundColor: string;
  border?: string;
  borderStyle?: string;
}>`
  height: 81px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 0 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  ${({ border, borderStyle }) => {
    if (border === 'all') {
      return `border: ${borderStyle};`;
    } else if (border === 'top') {
      return `border-top: ${borderStyle};`;
    }
  }}

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const CheckTickWrapper = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;

  &&& {
    min-width: 16px;
    height: 16px;
  }

  svg {
    font-size: 10px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const Column = styled(Grid.Column)``;

const NavigationButtonsWrapper = styled.div<{ currentStep: number }>`
  display: flex;
  flex: 1;
  justify-content: ${({ currentStep }) => (currentStep === 1 ? `flex-end` : `space-between`)};
  margin-top: 32px;
`;

const BackwardForwardButtonsWrapper = styled.div`
  display: flex;

  > button:last-child {
    margin-left: 16px;
  }
`;

const SidebarWrapper = styled.div`
  > div:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const SegmentHeader = styled.div`
  margin-bottom: 8px;
`;

const CertificateCostWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 16px;

  & div:first-child {
    max-width: 70%;
  }
`;

const LineSeparator = styled.div`
  height: 1px;
  background: ${({ theme }) => theme.colors.lightGrey};
  margin-top: 56px;
  margin-bottom: 22px;
`;

const ExemptionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.softLightGrey};
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.softRegularGrey};
  padding: 16px;

  & :first-child {
    margin-right: 16px;
  }

  & div:last-child {
    flex-grow: 1;
  }
`;

const ExemptionNote = styled.div`
  display: flex;
  flex-direction: column;

  & div:first-child {
    margin-bottom: 8px;
    margin-top: 0px;
  }
`;

const NoteDescriptionText = styled(Text)`
  line-height: 1.3;
`;

const SubmitButtonsWrapper = styled.div`
  margin-left: 16px;
  display: flex;

  button:first-child {
    margin-right: 16px;
  }
`;

const TabText = styled(Text)`
  line-height: 1.2;
`;

const ModalStatement = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  & > div:nth-child(2) {
    margin-top: 12px;
  }
`;
