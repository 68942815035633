/**
 * Mandatory Options Dropdown container logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getUcs = (state: RootState) => state.Schedules.ucs;
export const getFreeOptions = (state: RootState) => state.FreeOptionsDropdown;
export const getSchedulesSlices = (state: RootState) => state.Schedules;
