import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';
import { lineToAxisPlugin } from './utils';
Chart.plugins.register(lineToAxisPlugin);

declare global {
  interface Window {
    myLollipopChart?: Chart;
  }
}

interface GradesChartProps {
  gradeCounts: any;
  loadingStatus: {
    loadingGradeDetailsCounts: boolean;
    loadingGradeDetailsInfo: boolean;
  };
  setCustomTooltip: React.Dispatch<React.SetStateAction<{
    display: boolean;
    position: { x: number; y: number };
    content: string;
  }>>;
}

const GradesChart: React.FC<GradesChartProps> = ({ gradeCounts, loadingStatus, setCustomTooltip }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const updateChart = () => {
      if (!gradeCounts || Object.keys(gradeCounts).length === 0) return;

      const specialGrades = ['66', '77', '88', '99'];
      const regularGradesRange = Array.from({ length: 21 }, (_, i) => i.toString());
      const allValidGradeKeys = [...regularGradesRange, ...specialGrades];

      const validGrades = Object.keys(gradeCounts)
        .filter(key => allValidGradeKeys.includes(key))
        .reduce((acc, key) => {
          acc[key] = gradeCounts[key];
          return acc;
        }, {});

      const chartLabels = allValidGradeKeys;

      const chartData = chartLabels.map(grade => ({
        x: grade,
        y: validGrades[grade] || 0,
        r: validGrades[grade] ? 6 : 0,
        backgroundColor: specialGrades.includes(grade) ? '#FF5733' : '#0EB4BD',
      }));

      if (!window.myLollipopChart) {
        const canvas = canvasRef.current;
        if (canvas && canvas.getContext) {
          const ctx = canvas.getContext('2d');
          if (ctx) {
            window.myLollipopChart = new Chart(ctx, {
              type: 'bubble',
              data: {
                labels: chartLabels,
                datasets: [{
                  label: '',
                  data: chartData,
                  backgroundColor: chartData.map(d => d.backgroundColor)
                }]
              },
              options: {
                title: {
                  display: false,
                },
                legend: {
                  display: false,
                },
                responsive: true,
                maintainAspectRatio: false,
                layout: {
                  padding: {
                    right: 90
                  }
                },
                scales: {
                  xAxes: [{
                    type: 'category',
                    labels: chartLabels,
                    scaleLabel: {
                      display: true,
                      labelString: 'Notas'
                    }
                  }],
                  yAxes: [{
                    beginAtZero: true,
                    scaleLabel: {
                      display: true,
                      labelString: 'Número de Estudantes'
                    },
                    ticks: {
                      stepSize: 10
                    }
                  }]
                },
                tooltips: {
                  enabled: false,
                  mode: 'point',
                  intersect: true,
                  custom: function (tooltipModel) {
                    if (tooltipModel.opacity === 0) {
                      setCustomTooltip({ display: false, position: { x: 0, y: 0 }, content: '' });
                      return;
                    }

                    if (tooltipModel.body) {
                      let offset = -300;
                      let offsetH = -20;
                      const top = tooltipModel.caretY - offset;
                      const left = tooltipModel.caretX - offsetH;
                      const tooltipContent = `Número de Estudantes: ${tooltipModel.dataPoints[0].yLabel}`;

                      setCustomTooltip({
                        display: true,
                        position: { x: left, y: top },
                        content: tooltipContent
                      });
                    }
                  }
                },
                plugins: {
                  lineToAxisPlugin: lineToAxisPlugin
                }
              },
            });
          } else {
            console.error('2D context not available for the canvas');
          }
        } else {
          console.error('Canvas element not found');
        }
      } else {
        window.myLollipopChart.data.labels = chartLabels;
        window.myLollipopChart.data.datasets[0].data = chartData;
        window.myLollipopChart.update();
      }
    };

    if (!loadingStatus.loadingGradeDetailsCounts && !loadingStatus.loadingGradeDetailsInfo) {
      updateChart();
    }

    return () => {
      if (window.myLollipopChart) {
        window.myLollipopChart.destroy();
        window.myLollipopChart = undefined;
      }
    };
  }, [gradeCounts, loadingStatus.loadingGradeDetailsCounts, loadingStatus.loadingGradeDetailsInfo, setCustomTooltip]);

  const hasValidGradeCounts = gradeCounts && Object.keys(gradeCounts).some(key => {
    const grade = parseInt(key);
    const specialGrades = ['66', '77', '88', '99'];
    return (!isNaN(grade) && grade >= 0 && grade <= 20) || specialGrades.includes(key);
  });

  return hasValidGradeCounts ? (
    <div style={{ height: '26rem', width: '100%', marginTop: '2rem' }}>
      <canvas ref={canvasRef} id="lollipopChart" style={{ height: '100%', width: '100%' }}></canvas>
    </div>
  ) : null;
};

export default GradesChart;