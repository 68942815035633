/**
 * CurricularUnitDetails slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  selectedUcCode: null as null | number,
  ucDetails: {} as any,
  errorStatus: {
    errLoadingDetails: false,
  },
};

export default createSlice({
  name: 'CurricularUnitDetails',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    getUcDetails: (state, action: PayloadAction<any>) => {},
    setUcDetails: (state, action: PayloadAction<any>) => {
      state.ucDetails = action.payload;
    },
    setSelectedUcCode: (state, action: PayloadAction<number | null>) => {
      state.selectedUcCode = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    reset: (state) => initialState,
  },
});
