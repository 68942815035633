/**
 * ApplicationsList scene slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDropdownOption } from 'shared/types';

import { IParams } from 'api';

interface IStudentApplications {
  id: number;
  category: string;
  description: string;
  requestDate: string;
  responseDate: string;
  status: string;
  reference: string
}

const initialState = {
  studentApplications: [] as IStudentApplications[],
  applicationFilters: [] as IDropdownOption[],
  filterParams: {
    type: '' as string,
    search: '' as string,
  },
  loadingStatus: {
    loading: true,
    loadingList: true,
  },
  errorStatus: {
    errLoading: false,
    errLoadingList: false,
  },
};

export default createSlice({
  name: 'ApplicationsList',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setReset: (state) => initialState,
    getStudentApplications: (state, action?: PayloadAction<IParams>) => {},
    setStudentApplications: (state, action: PayloadAction<IStudentApplications[]>) => {
      state.studentApplications = action.payload;
    },
    setApplicationFilters: (state, action: PayloadAction<any>) => {
      state.applicationFilters = action.payload.map((filter, key) => {
        return {
          key: key,
          text: filter,
          value: filter,
        };
      });
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    reset: (state) => initialState,
  },
});
