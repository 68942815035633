/**
 * Component to load components
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React from 'react';
import { Controller } from 'react-hook-form';

import {
  FormInput,
  Text,
  Checkbox,
  RadioList,
  Counter,
  EditableFieldsGroup,
} from '@paco_ua/pacoui';

import FormInputWithFlags from 'shared/components/FormInputWithFlags';
import FormTextArea from 'shared/components/FormTextArea';
import FormLargeTextArea from 'shared/components/FormLargeTextArea';
import FileUploader from 'shared/components/FileUploader';
import FormTable from 'shared/components/FormTable';
import FormDropdown from 'shared/components/FormDropdown';
import FormGroup from 'shared/components/FormGroup';

import NewApplicationDropdown from 'scenes/VirtualSecretary/Applications/NewApplication/components/NewApplicationDropdown';

interface IProps extends Omit<React.ComponentProps<any>, ''> {
  type: string;
}

const mappings = {
  text: {
    component: Text,
    type: 'ref',
  },
  textbox: {
    component: FormInput,
    type: 'ref',
  },
  largetextbox: {
    component: FormLargeTextArea,
    type: 'controller',
  },
  big_textbox: {
    component: FormTextArea,
    type: 'controller',
  },
  file: {
    component: FileUploader,
    type: 'controller',
  },
  checkbox: {
    component: Checkbox,
    type: 'ref',
  },
  checkbox_table: {
    component: FormTable,
    type: 'controller',
  },
  radio_button: {
    component: RadioList,
    type: 'controller',
  },
  dropdown: {
    component: FormDropdown,
    type: 'controller',
  },
  flag_dropdown_textbox: {
    component: FormInputWithFlags,
    type: 'controller',
  },
  application_dropdown: {
    component: NewApplicationDropdown,
    type: 'controller',
  },
  counter: {
    component: Counter,
    type: 'controller',
  },
  editableGroupComponent: {
    component: EditableFieldsGroup,
    type: 'groupController',
  },
  group: {
    component: FormGroup,
    type: 'groupController',
  },
};

const FormElement: React.FC<IProps> = ({
  type,
  children,
  control,
  defaultValue,
  register,
  ...props
}) => {
  const Component = mappings[type].component;
  const ComponentType = mappings[type].type;

  return (
    <>
      {ComponentType === 'ref' && (
        <Component register={register(props.name)} {...props} defaultValue={defaultValue} />
      )}

      {ComponentType === 'controller' && (
        <Controller
          name={props.name}
          defaultValue={defaultValue}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Component {...props} onChange={onChange} defaultValue={defaultValue} value={value} />
            );
          }}
        />
      )}

      {ComponentType === 'groupController' && <Component {...props}>{children}</Component>}
    </>
  );
};
export default FormElement;
