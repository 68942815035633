/**
 * Toaster container slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 * @format
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IToast {
  title: string;
  body?: string;
  icon?: 'check' | 'info' | 'error';
  type?: 'success' | 'danger' | 'warning' | 'info';
}

const initialState = {
  showing: false,
  toast: {} as IToast,
};

export default createSlice({
  name: 'Toaster',
  initialState,
  reducers: {
    showToaster: (state, action: PayloadAction<IToast>) => {
      state.showing = true;
      state.toast = action.payload;
    },
    closeToaster: (state) => {
      state.showing = false;
    },
  },
});
