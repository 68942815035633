import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Breadcrumb as BreadcrumbUi } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';

import * as selectors from './logic/selectors';
import * as appSelectors from 'app/logic/selectors';

import { getPathToPage } from './utils';

import { history, getCurrentRoute } from 'utils';

interface IProps {
  loading?: boolean;
}

const Breadcrumb: React.FC<IProps> = ({ loading }) => {
  const { pathElemsList } = useSelector(selectors.getBreadcrumbSelector);
  const user = useSelector(appSelectors.getUser);
  const { routingStructure } = useSelector(appSelectors.getAppSlice);

  const userRoles = user.roles;

  const dispatch = useDispatch();
  const { navigateTo } = actions.App;

  // run onMount and when userRole changes value
  useEffect(() => {
    const currentRoute = getCurrentRoute();
    currentRoute && dispatch(actions.Breadcrumb.setPath(getRoutes(currentRoute.route)));
  }, [dispatch, userRoles, routingStructure]);

  history.listen(() => {
    const currentRoute = getCurrentRoute();
    currentRoute && dispatch(actions.Breadcrumb.setPath(getRoutes(currentRoute.route)));
  });

  const getRoutes = (location) => {
    return getPathToPage(location, routingStructure);
  };

  return (
    <BreadcrumbUi
      loading={loading}
      onNavigate={(key) => {
        dispatch(navigateTo({ key: key }));
      }}
      paths={pathElemsList}
    />
  );
};

export default Breadcrumb;
