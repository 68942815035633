/**
 * RegistrationDetails scene slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IEligibility {
  currentYearEctsEarned: number;
  currentYearEctsToDo: number;
  eligibilityState: number;
  totalEctsEarned: number;
  totalEctsToDo: number;
  numberSignups: number;
  graphMinEcts: number;
  graphMaxEcts: number;
}

interface IHistory {
  academicYear: string;
  currentYearEctsEarned: number;
  currentYearEctsToDo: number;
  ectsWaitingApproval: number;
  eligibilityState: number;
  coefficientType: string;
  numberSignups: number;
  totalEctsEarned: number;
  coefficient: string;
}

interface IScholarship {
  scholarshipCategory: string;
  scholarshipStatus: string;
}

interface IRegistrationGeneralInfo {
  accessScheme: string;
  courseCode: number;
  courseName: string;
  id: number;
  registrationDate: string;
  registrationAcademicYear: string;
  registrationStatus:
    | 'ANULADO'
    | 'SUSPENSO (OUTRO)'
    | 'TERMINADO'
    | 'ACTIVO'
    | 'CADUCADO'
    | 'SUSPENSO (PROPINAS)';
  academicYear: string;
  typology: string;
}

const initialState = {
  loading: true,
  eligibility: null as null | IEligibility,
  history: [] as IHistory[],
  scholarship: null as null | IScholarship,
  registrationGeneralInfo: null as null | IRegistrationGeneralInfo,
  errorStatus: {
    errorRegistrationDetails: false,
  },
};

export default createSlice({
  name: 'RegistrationDetails',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    getRegistration: () => {},
    setEligibility: (state, action: PayloadAction<IEligibility>) => {
      state.eligibility = action.payload;
    },
    setHistory: (state, action: PayloadAction<IHistory[]>) => {
      state.history = action.payload;
    },
    setScholarship: (state, action: PayloadAction<IScholarship>) => {
      state.scholarship = action.payload;
    },
    setRegistrationGeneralInfo: (state, action: PayloadAction<IRegistrationGeneralInfo>) => {
      state.registrationGeneralInfo = action.payload;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    reset: (state) => initialState,
  },
});
