/**
 * HelpMessage Component
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Nuno Silva   <nmsilva@ubiwhere.com>
 * @author Carlos Silva   <csilva@ubiwhere.com>
 */
import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

interface IProps {
  transKey: string;
  onUpdateKeys?: (name: string, form: object) => void;
  className?: string;
  i18n: any;
}

const HelpMessage: React.FC<IProps> = ({ transKey, i18n, className, onUpdateKeys }) => {
  const [isMouseInside, setIsMouseInside] = useState(false);
  const [isMouseInsideMessage, setIsMouseInsideMessage] = useState(false);

  const { handleSubmit, register } = useForm();

  const mouseEnter = () => {
    setIsMouseInside(true);
  };

  const mouseLeave = () => {
    setTimeout(() => {
      if (!isMouseInsideMessage) {
        setIsMouseInside(false);
      }
    }, 300);
  };

  const mouseEnterInside = () => {
    setIsMouseInsideMessage(true);
  };

  const mouseLeaveInside = () => {
    setIsMouseInsideMessage(false);
    setIsMouseInside(false);
  };

  const onClick = (e) => {
    e.stopPropagation();
  };

  const name = transKey.split('.')[0];
  let translationKeys: any[] = [];
  Object.keys(i18n.options.resources).forEach((language) => {
    const properties = i18n.options.resources[language].translation[name];
    translationKeys = Object.keys(properties).filter((property) =>
      property.includes(transKey.split('.')[1])
    );
  });

  return (
    <IconWrapper
      className={className}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={onClick}
    >
      {isMouseInside && (
        <OverlayWrapper onMouseLeave={mouseLeaveInside} onMouseEnter={mouseEnterInside}>
          <KeysWrapper>
            {translationKeys.map((translationKey, id) => (
              <IconMessage key={`translate_element_message_${translationKey}_${IDBDatabase}`}>
                <Flex>{translationKey}</Flex>
                <br />
                {Object.keys(i18n.options.resources).map((key) => (
                  <React.Fragment key={`translate_${key}_${transKey}`}>
                    {key}:
                    <input
                      name={`${translationKey}.${key}`}
                      defaultValue={i18n.options.resources[key].translation[name][translationKey]}
                    />
                  </React.Fragment>
                ))}
              </IconMessage>
            ))}
          </KeysWrapper>
          <button
            onClick={() => {
              onUpdateKeys && handleSubmit((values) => onUpdateKeys(name, values))();
            }}
          >
            UPDATE
          </button>
        </OverlayWrapper>
      )}
    </IconWrapper>
  );
};

export default HelpMessage;

const IconWrapper = styled.span`
  position: absolute;
  left: -10px;
  top: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px dashed cornflowerblue;
  z-index: 999999;
`;

const IconMessage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #616161;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  font-weight: normal;

  &:not(:last-child) {
    border-right: 1px solid black;
    padding-right: 10px;
  }

  &:not(:first-child) {
    padding-left: 10px;
  }
`;

const OverlayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  border-radius: 4px;
  height: auto;
  border: 1px solid rgba(212, 212, 213, 0.28);
  box-shadow: 0px 0px 7px rgba(204, 204, 204, 0.39);
`;

const KeysWrapper = styled.div`
  display: flex;
  padding: 5px;
`;

const Flex = styled.div`
  display: flex;
`;
