/**
 * TitleHeader component
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { getCurrentRoute, getLastRoute } from 'utils';

interface IProps {
  title?: any;
  backButton?: boolean;
  pullBackButton?: boolean;
  loading?: boolean;
  onBack?: () => void;
}

const TitleHeader: React.FC<IProps> = ({ backButton, onBack, ...props }) => {
  const { user, routingStructure } = useSelector(selectors.getAppSlice);
  const { navigateTo, navigateBack } = actions.App;

  const lastRoute = getLastRoute().route.key;
  const currentRoute = getCurrentRoute().route.key;
  const parentRoute = getCurrentRoute().route.childOf;

  const checkParentNavigation = () => {
    const parentRouteFound = routingStructure.find((route) => route.key === parentRoute);
    if (!parentRouteFound) {
      return false;
    }

    //check if user has at least one of the required roles
    if (parentRouteFound.roles && !user.impersonate) {
      if (
        !user.roles.some((userRole) =>
          parentRouteFound?.roles?.some((checkRoute) => checkRoute === userRole)
        )
      ) {
        return false;
      }
    }

    return true;
  };

  const canNavigateToParent = useMemo(checkParentNavigation, [
    user,
    props.title,
    props.loading,
    backButton,
    props.pullBackButton,
  ]);

  const dispatch = useDispatch();

  //show backbutton if property backbutton exists and if has a navigation history or has a parentRoute associated
  if (backButton && (lastRoute !== currentRoute || canNavigateToParent)) {
    return (
      <Header
        onBack={() => {
          if (onBack) {
            onBack();
          }
          else if (canNavigateToParent) {
            dispatch(navigateTo({ key: parentRoute }));
          } else {
            dispatch(navigateTo({ key: lastRoute }));
          }
        }}
        {...props}
      />
    );
  }

  return <Header {...props} />;
};

export default TitleHeader;
