/**
 * SelectDetails container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { Controller } from 'react-hook-form';
import FormInputWithFlags from 'shared/components/FormInputWithFlags';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../../logic/selectors';
import { actions } from 'store/rootSlices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faEllipsisH } from '@fortawesome/pro-light-svg-icons';

import moment from 'moment';

import { Grid } from 'semantic-ui-react';
import { Text, Segment } from '@paco_ua/pacoui';

import SelectedPayments from '../../components/SelectedPayments';

const SubmitPaymentRequest = ({
  selectedDebits,
  formControl,
  formRegister,
  formGetValues,
  formErrors,
  currentLocale,
}) => {
  const theme = useContext(ThemeContext);

  const { paymentMethod, mbwayForm, mbwayResponse } = useSelector(
    selectors.getPendingDebitsPaymentSlice
  );

  const { setMbwayForm } = actions.PendingDebitsPayment;

  const dispatch = useDispatch();

  interface IPaymentMethods {
    MB: string;
    VISA: string;
    MBWAY: string;
  }

  const PAYMENT_METHODS: IPaymentMethods = {
    MB: 'mb',
    MBWAY: 'mbway',
    VISA: 'visa',
  };

  return (
    <>
      <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
        {selectedDebits.length > 0 && (
          <>
            <SegmentTitleWrapper>
              <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
                {t('payments.performedSelection')}
              </Text>
            </SegmentTitleWrapper>

            <SelectedPayments selectedDebits={selectedDebits} />
          </>
        )}

        {selectedDebits.length === 0 && (
          <Segment>
            <MessageNoApplications>
              <Icon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
              <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                {t('payments.noDebitsSelect', { textOnly: true })}
              </Text>
            </MessageNoApplications>
          </Segment>
        )}
      </Grid.Column>

      <PaymentMethodOperationsColumn>
        {paymentMethod === PAYMENT_METHODS.MBWAY && (
          <>
            <PaymentMethodTitle>
              <Text size="large" weight="regular" color="plusDarkGrey">
                {t('payments.paymentMethodsTitle', { readOnly: true })}
              </Text>
            </PaymentMethodTitle>
            <MbwayTextWrapper>
              <Text size="medium" weight="bold" color="plusDarkGrey" transform="uppercase">
                MB WAY
              </Text>
            </MbwayTextWrapper>

            <form>
              <MbwayDetails>
                <CountryWrapper>
                  <DescriptionWrapper>
                    <Text size="xSmall" weight="regular" color="grey">
                      {t('payments.phoneNumber', { textOnly: true })}
                    </Text>
                    <Text size="xSmall" weight="regular" color="dangerRed">
                      {' *'}
                    </Text>
                  </DescriptionWrapper>
                  <DropdownWrapper>
                    <Controller
                      name={'countryDropdown'}
                      control={formControl}
                      defaultValue={mbwayForm.countryDropdown}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormInputWithFlags
                            register={formRegister}
                            placeholder={t(`payments.phonePlaceholder`, {
                              textOnly: true,
                            })}
                            onChange={(data) => {
                              onChange(data);
                              dispatch(setMbwayForm(formGetValues()));
                            }}
                            fontSize={'article'}
                            defaultValue={mbwayForm.countryDropdown}
                            error={
                              formErrors && formErrors.countryDropdown
                                ? formErrors.countryDropdown.message
                                : undefined
                            }
                          />
                        );
                      }}
                    />
                  </DropdownWrapper>
                </CountryWrapper>
              </MbwayDetails>
            </form>

            <NoteWrapper>
              <Text size="article" weight="medium" color="darkGrey">
                {t('payments.note', { textOnly: true })}
              </Text>
              <NoteTextWrapper>
                <Text size="article" weight="regular" color="darkGrey">
                  {t('payments.mbwayNoteDescription', { textOnly: true })}
                </Text>
              </NoteTextWrapper>
            </NoteWrapper>
          </>
        )}

        {paymentMethod === PAYMENT_METHODS.MB && (
          <>
            <PaymentMethodTitle>
              <Text size="large" weight="regular" color="plusDarkGrey">
                {t('payments.paymentMethodsTitle', { readOnly: true })}
              </Text>
            </PaymentMethodTitle>
            <MbReferenceInfo>
              <MBReferenceTitle>
                <Text size="medium" color="plusDarkGrey" weight="medium">
                  {t('payments.atmReference', { readOnly: true })}
                </Text>
              </MBReferenceTitle>

              {mbwayResponse && (
                <MbwayResponseWrapper>
                  {Object.keys(mbwayResponse)
                    .filter((key) => key !== 'paymentRequestId')
                    .map((key) => (
                      <MbwayResponseColumn key={key}>
                        <TextFieldTitle>
                          <Text color="plusDarkGrey" size="small" weight="medium">
                            {t(`payments.${key}`)}
                          </Text>
                        </TextFieldTitle>

                        {(mbwayResponse[key] === '' || mbwayResponse[key] === null) && (
                          <FontAwesomeIcon icon={faEllipsisH} />
                        )}
                        {mbwayResponse[key] !== '' &&
                          mbwayResponse[key] !== null &&
                          key === 'dueDate' && (
                            <Text weight="bold" color="plusDarkGrey" size="mediumSmall">
                              {moment(mbwayResponse[key]).format('DD-MM-YYYY')}
                            </Text>
                          )}
                        {mbwayResponse[key] !== '' &&
                          mbwayResponse[key] !== null &&
                          key !== 'dueDate' &&
                          key !== 'value' && (
                            <Text weight="bold" color="plusDarkGrey" size="mediumSmall">
                              {mbwayResponse[key]}
                            </Text>
                          )}
                        {mbwayResponse[key] !== '' &&
                          mbwayResponse[key] !== null &&
                          key === 'value' && (
                            <Text weight="bold" color="plusDarkGrey" size="mediumSmall">
                              {`${parseFloat(mbwayResponse[key]).toLocaleString(currentLocale, {
                                minimumFractionDigits: 2,
                              })} ${t('generic.euros', { textOnly: true })}`}
                            </Text>
                          )}
                      </MbwayResponseColumn>
                    ))}
                </MbwayResponseWrapper>
              )}
              <NoteWrapper>
                <Text size="article" weight="medium" color="darkGrey">
                  {t('payments.note', { textOnly: true })}
                </Text>
                <NoteTextWrapper>
                  <Text size="article" weight="regular" color="darkGrey">
                    {t('payments.mbwayNoteDescription', { textOnly: true })}
                  </Text>
                </NoteTextWrapper>
              </NoteWrapper>
            </MbReferenceInfo>
          </>
        )}
      </PaymentMethodOperationsColumn>
    </>
  );
};

export default SubmitPaymentRequest;

const MbwayResponseWrapper = styled.div`
  margin-top: 24px;
  background-color: ${({ theme }) => theme.colors.softGrey};
  border-style: solid;
  border-width: thin;
  border-color: ${({ theme }) => theme.colors.softLightGrey};
  border-top: 3px solid ${({ theme }) => theme.colors.primary};
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
`;

const TextFieldTitle = styled.div`
  margin: 0px 0px 12px 0px;
`;

const MbwayResponseColumn = styled.div``;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const MessageNoApplications = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const MbReferenceInfo = styled.div`
  padding: 0px 110px;
`;

const MbwayTextWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 20px;
`;

const MbwayDetails = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 312px;
`;

const DropdownWrapper = styled.div`
  margin-top: 12px;
`;

const NoteWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const NoteTextWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 82px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;

  div:first-child {
    margin-right: 6px;
  }
`;

const PaymentMethodTitle = styled.div`
  margin-bottom: 24px;
`;

const PaymentMethodOperationsColumn = styled(Grid.Column)`
  margin-top: 40px;
`;

const MBReferenceTitle = styled.div``;
