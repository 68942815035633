import React, { useContext } from "react";
import Modal from "shared/components/Modal";
import styled from 'styled-components';
import { Button } from '@paco_ua/pacoui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { t } from 'app';

const ConfirmActionModal = ({ title, body, onClick, onConfirm, confirmButtonText = t('pautas.continuar', { textOnly: true }), cancelButtonText = t('pautas.cancel', { textOnly: true }) }) => {

  const color = "#333333";

  return (
    <Modal changeModal={onClick}>
      <MessageWrapper>
        <TitleWrapper>
          <IconWrapper color={color}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </IconWrapper>
          <MessageTitle color={color}>
            <span>{title}</span>
          </MessageTitle>
        </TitleWrapper>
        {body && (
          <MessageBody color={color}>
            <p>{body}</p>
          </MessageBody>
        )}
      </MessageWrapper>
      <ButtonsWrapper>
        <Button danger onClick={onClick}>{cancelButtonText}</Button>
        <Button action onClick={onConfirm}>{confirmButtonText}</Button>
      </ButtonsWrapper>
    </Modal>
  );
};

export default ConfirmActionModal;

const MessageWrapper = styled.div`
  padding-right: 24px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-left: 12px;
  margin-top: 16px;
  width: 36px;
  height: 36px;
  color: ${({ color }) => color};
  font-size: 32px;
`;

const MessageTitle = styled.div`
  margin-left: 24px;
  color: ${({ color }) => color};
`;

const MessageBody = styled.div`
  margin-left: 72px;
  color: ${({ theme }) => theme.colors.blackLight};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  display: flex;
`;