/**
 * Schedules scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getSchedulesSlice = (state: RootState) => state.Schedules;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
export const getMySchedules = (state: RootState) => state.MySchedules.createdSchedules;
export const getCurrentPhase = (state: RootState) => state.Schedules.currentPhase;
export const getUcs = (state: RootState) => state.Schedules.ucs;
export const getMandatoryOptions = (state: RootState) => state.MandatoryOptionsDropdown.options;
export const getLoadingSchedule = (state: RootState) => state.Schedules.loadingSchedule;
export const getRegistrations = (state: RootState) => state.App.user.registrations;
