/**
 * PersonalData scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { updateFormEditingStatus } from '../utils';

interface IEditState {
  blockKey: string;
  editing: boolean;
}
interface IDataBlock {
  description: string;
  editing: boolean;
  lastUpdated: string;
  nameKey: string;
  picture: string | null;
  showPicture: boolean;
  values: IDataBlockField[];
}
interface IDataBlockField {
  nameKey: string;
  placeholderKey: string;
  type: string;
  validationType: string;
  validations: IFieldValidation[];
  value: string | null;
  visible: null;
}
interface IFieldValidation {
  params: [];
  type: string;
}
interface ICardBlock {
  fields: {
    nameKey: string;
    value: string;
  }[];
}
export interface IContactsFields {
  type: string;
  payload: {
    alternativeEmail: string;
    // alternativePhoneNumber: {
    //   countryValue: string;
    //   input: string;
    // };
    primaryPhoneNumber: {
      countryValue: string;
      input: string;
    };
  };
}
export interface IAddressFields {
  type: string;
  payload: {
    homeAddress: string;
    homeLocality: string;
    homePostalCode: {
      countryValue: string;
      input: string;
    };
  };
}

const initialState = {
  personalDataBlocks: {},
  studentCardBlock: {},
  loadingStatus: {
    loadingPage: true,
    loadingAddress: false,
    loadingContacts: false,
  },
  errorStatus: {
    errLoadingPersonalData: false,
    errLoadingCardData: false,
  },
  hasRegistrationId: true,
};

export default createSlice({
  name: 'PersonalData',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setPersonalDataBlocks: (state, action: PayloadAction<IDataBlock[]>) => {
      state.personalDataBlocks = action.payload;
    },
    setStudentCardBlock: (state, action: PayloadAction<ICardBlock>) => {
      state.studentCardBlock = action.payload;
    },
    setPersonalDataFormEditingState: (state, action: PayloadAction<IEditState>) => {
      const { blockKey, editing } = action.payload;

      state.personalDataBlocks = updateFormEditingStatus(
        state.personalDataBlocks,
        blockKey,
        editing
      );
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setHasRegistrationId: (state, action: PayloadAction<any>) => {},
    patchAddressForm: (state, action: PayloadAction<any>) => {},
    patchContactsForm: (state, action: PayloadAction<any>) => {},
    resetForm: (state, action: PayloadAction<any>) => {},
    reset: (state) => initialState,
  },
});
