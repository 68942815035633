import { createSlice } from '@reduxjs/toolkit';
import { PautasListaResponse, PautaStats } from './types';

import { PayloadAction } from '@reduxjs/toolkit';

interface HomeState {
  pautasLista: PautasListaResponse | null;
  pautaStats: object;
  periodosDisponiveis: string[];
  filtrarPorPeriodo: string;
  selectedState: string;
  loadingStatus: {
    loadingGrades: boolean;
    loadingTable: boolean;
    loadingStats: boolean;
  };
  pautasListaChanges: {
    filterState: string;
    pautasListaChanged: boolean;
  };
}

const initialState: HomeState = {
  pautasLista: null,
  pautaStats: {},
  periodosDisponiveis: [],
  filtrarPorPeriodo: '',
  selectedState: '',
  loadingStatus: {
    loadingGrades: true,
    loadingTable: true,
    loadingStats: true,
  },
  pautasListaChanges: {
    filterState: '',
    pautasListaChanged: false,
  },
};

export default createSlice({
  name: 'GradesManagement',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    reset: (state) => initialState,
    changeYearRequest: (state, action) => {
      state.filtrarPorPeriodo = action.payload;
    },
    setPautasLista: (state, action) => {
      state.pautasLista = action.payload;
    },
    setPautasStats: (state, action) => {
      state.pautaStats = action.payload;
    },
    setPeriodosDisponiveis: (state, action) => {
      state.periodosDisponiveis = action.payload;
    },
    setFiltrarPorPeriodo: (state, action) => {
      state.filtrarPorPeriodo = action.payload;
    },
    setSelectedState: (state, action) => {
      state.selectedState = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setPautasListaChanged: (state, action) => {
      state.pautasListaChanges.pautasListaChanged = action.payload;
    },
    setPautasListaState: (state, action) => {
      state.pautasListaChanges.filterState = action.payload;
    },
    resetPautasListaChanges: (state) => {
      state.pautasListaChanges = initialState.pautasListaChanges;
    },
  },
});
