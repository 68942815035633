/**
 * CreatedSchedule container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSmile,
  faFrown,
  faEye,
  faPen,
  faTrash,
  faClone,
} from '@fortawesome/pro-regular-svg-icons';

import { StatusLabel, Card, Text, Button, Tooltip } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';

//import config from 'config'

interface IProps {
  id: number;
  allocationProb?: 'high' | 'low';
  preference: number;
  name: string;
  onEdit?: () => void;
  onCancel?: () => void;
  indexKey: number;
  status?: 'assigned' | 'automatic' | 'notAssigned' | 'proposal';
  assignedClasses?: number;
  viewOnly?: boolean;
  creationTime?: string;
  seriationDate?: string;
  finishedPhase: boolean;
  showDetails?: boolean | undefined; // undefined => schedule of a not active phase; true/false => schedule of an active phase
}

const CreatedSchedule: React.FC<IProps> = ({
  id,
  allocationProb,
  name,
  preference,
  status,
  assignedClasses,
  indexKey,
  creationTime,
  seriationDate,
  viewOnly,
  showDetails,
  finishedPhase,
}) => {
  const dispatch = useDispatch();
  const { navigateTo } = actions.App;
  const { addScheduleToDelete } = actions.MySchedules;

  const theme = useContext(ThemeContext);

  const getStatusLabel = () => {
    switch (status) {
      case 'assigned':
        return (
          <StatusLabel
            label={t('sgh.createdSchedulesLabelAssigned')}
            background={theme.colors.successGreen}
          />
        );
      case 'automatic':
        return (
          <StatusLabel
            label={t('sgh.createdSchedulesLabelAssigned')}
            background={theme.colors.successGreen}
          />
        );
      case 'notAssigned':
        return (
          <StatusLabel
            label={t('sgh.createdSchedulesLabelNotAssigned')}
            background={theme.colors.dangerRed}
          />
        );
      case 'proposal':
        return <StatusLabel label={t('sgh.createdSchedulesLabelProposal')} />;
      default:
        return (
          <StatusLabel
            label={t('sgh.createdSchedulesLabelNotAssigned')}
            background={theme.colors.dangerRed}
          />
        );
    }
  };

  const getStateData = () => {
    if (status === 'proposal') {
      return (
        <StateWrapper>
          {(showDetails === undefined || showDetails) && (
            <Text
              color="plusDarkGrey"
              underline
              weight="regular"
              size="small"
              as="a"
              onClick={() =>
                dispatch(navigateTo({ key: 'provisoryscheduleseriationhistory', params: { id } }))
              }
            >
              {t('sgh.viewDetails')}
            </Text>
          )}
        </StateWrapper>
      );
    } else {
      return (
        <StateWrapper>
          <div>
            <StateWrapperText color="plusDarkGrey" weight="regular" size="small">
              {t('generic.state')}:
            </StateWrapperText>

            <StateWrapperSubtext>
              {t('generic.createdAt', { when: moment(creationTime).format('LLL') })}
            </StateWrapperSubtext>
          </div>
        </StateWrapper>
      );
    }
  };

  return (
    <Card noPadding shadow="strong" headerRight={getStatusLabel()}>
      <PreferenceAndEditScheduleWrapper>
        <Preference active>{preference}</Preference>
      </PreferenceAndEditScheduleWrapper>

      <TopWrapper>
        <TopInnerWrapper>
          <TopTitle color="plusDarkGrey" weight="medium" size="medium">
            {name}
          </TopTitle>
          {allocationProb === 'high' && (
            <Text
              color="primary"
              weight="regular"
              size="mediumSmall"
              icon={<FontAwesomeIcon color={theme.colors.primary} size="lg" icon={faSmile} />}
            >
              {t('sgh.legendAllocationProbable')}
            </Text>
          )}

          {allocationProb === 'low' && (
            <Text
              color="primary"
              weight="regular"
              size="mediumSmall"
              icon={<FontAwesomeIcon icon={faFrown} size="lg" color={theme.colors.primary} />}
            >
              {t('sgh.legendAllocationImprobable')}
            </Text>
          )}
        </TopInnerWrapper>
        {getStateData()}
      </TopWrapper>

      <BottomWrapper>
        <Tooltip
          content={t('sgh.tooltipViewSchedule')}
          trigger={
            <Button
              onClick={() => {
                dispatch(actions.MySchedules.getScheduleToView({ id, finishedPhase }));
              }}
              borderless
              color={theme.colors.plusDarkGrey}
              borderlessHoverColor={theme.colors.primary}
            >
              <FontAwesomeIcon icon={faEye} />
            </Button>
          }
        />
        {!viewOnly && (
          <Tooltip
            content={t('sgh.tooltipEditSchedule')}
            trigger={
              <Button
                onClick={() => dispatch(navigateTo({ key: 'editSchedule', params: { id } }))}
                borderless
                color={theme.colors.plusDarkGrey}
                borderlessHoverColor={theme.colors.primary}
              >
                <FontAwesomeIcon icon={faPen} />
              </Button>
            }
          />
        )}

        {!viewOnly && (
          <Tooltip
            content={t('sgh.tooltipDuplicateSchedule')}
            trigger={
              <Button
                onClick={() => dispatch(navigateTo({ key: 'duplicateSchedule', params: { id } }))}
                borderless
                color={theme.colors.plusDarkGrey}
                borderlessHoverColor={theme.colors.primary}
              >
                <FontAwesomeIcon icon={faClone} />
              </Button>
            }
          />
        )}

        {!viewOnly && (
          <Tooltip
            content={t('sgh.tooltipDeleteSchedule')}
            trigger={
              <Button
                data-testid={`created_schedule_button_delete_${indexKey}`}
                onClick={() => {
                  dispatch(addScheduleToDelete({ id, name }));
                }}
                borderless
                color={theme.colors.plusDarkGrey}
                borderlessHoverColor={theme.colors.primary}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            }
          />
        )}
      </BottomWrapper>
    </Card>
  );
};

export default CreatedSchedule;

const PreferenceAndEditScheduleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 14px;
  top: 0px;
  right: 0;
  z-index: 1;
`;

const Preference = styled.div<{ active?: boolean }>`
  height: 30px;
  width: 30px;
  background-color: ${({ active, theme }) => (active ? theme.colors.primary : theme.colors.grey)};
  box-shadow: 0px 3px 6px #00000029;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 194px;
  padding: 32px 14px 14px 14px;
`;

const TopTitle = styled(Text)`
  margin-bottom: 16px;
`;

const StateWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const BottomWrapper = styled.div`
  ${({ theme }) => `
    /*height: 38px;*/
    padding: 0 2px 0 14px;
    background-color: ${theme.colors.softGrey};
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `}
`;

const TopInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const StateWrapperText = styled(Text)`
  margin-right: 6px;
  line-height: 1;
`;

const StateWrapperSubtext = styled(Text)`
  line-height: 1;
`;
