/**
 * ScheduleListView container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useState, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, actions } from 'store/rootSlices';
import { Grid } from 'semantic-ui-react';

import { t } from 'app';

import styled, { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faEye } from '@fortawesome/pro-regular-svg-icons';

import { ScheduleGrid } from '@paco_ua/pacoui';

//import ScrollDownButton from 'components/ScrollDownButton';
import {
  MultiToggle,
  Modal,
  Dropdown,
  Tooltip,
  NoDataMessage,
  Button,
  Text,
} from '@paco_ua/pacoui';

import CaptionSchedule from 'scenes/Schedules/containers/CaptionSchedule';

import { IUc } from 'shared/types';

import {
  getDropdownOptions,
  getFirstScheduleType,
  getDropdownValue,
  getAllocatedState,
  getDropdownBorderColor,
  getScheduleAndLockState,
  getFilteredUcForZommAtScheduleOptions,
} from './utils';

interface IScheduleListView {}

interface IScheduleAndLock {
  allocation?: 'likely' | 'uncertain' | 'unlikely';
  locked: boolean;
  schedule: {
    weekDay: string;
    start: string;
    end: string;
  }[];
}

interface IZoomAtScheduleModalState {
  open: boolean;
  uc: null | IUc;
}

const ScheduleListView: React.FC<IScheduleListView> = () => {
  const {
    period,
    ucs,
    loadingSchedule,
    classesTypologies,
    currentPhase,
    scheduleGridInterval,
    currentMode,
  } = useSelector((state: RootState) => state.Schedules);

  const [zoomAtScheduleModalClassType, setZoomAtScheduleModalClassType] = useState('');
  const [zoomAtScheduleModalState, setZoomAtScheduleModalState] = useState<
    IZoomAtScheduleModalState
  >({
    open: false,
    uc: null,
  });

  const dispatch = useDispatch();
  const { setClassSelectionState } = actions.Schedules;
  const theme = useContext(ThemeContext);

  // Scene in view mode?
  const viewMode = currentMode === 'view';

  const zommAtScheduleOptions = classesTypologies
    .filter(
      (typology) =>
        zoomAtScheduleModalState.uc &&
        zoomAtScheduleModalState.uc.classSchedule.find(
          (classItem) => classItem.classType === typology.initials
        )
    )
    .map((typology, key) => {
      return {
        onClick: () => {
          setZoomAtScheduleModalClassType(typology.initials);
          setZoomAtScheduleModalState({
            ...zoomAtScheduleModalState,
            uc: getFilteredUcForZommAtScheduleOptions(
              zoomAtScheduleModalState.uc,
              typology.initials,
              period
            ),
          });
        },
        children: typology.name,
        selected: zoomAtScheduleModalClassType === typology.initials,
        testId: 'schedule_list_view_multitoggle_zoom',
      };
    });

  const getIrregularMessage = (message, infoDropdown?: boolean) => {
    return (
      <IrregularMessageWrapper noMargin={infoDropdown}>
        <IrregularMessage
          size={infoDropdown ? 'xSmall' : 'mediumSmall'}
          icon={
            <FontAwesomeIcon size="lg" color={theme.colors.primary} icon={faExclamationCircle} />
          }
          color={infoDropdown ? 'grey' : 'plusDarkGrey'}
        >
          {message}
        </IrregularMessage>
      </IrregularMessageWrapper>
    );
  };

  const handleUCZoomOnClick = (uc: IUc) => {
    const zoomedClassType = getFirstScheduleType(uc, classesTypologies);
    setZoomAtScheduleModalState({
      open: !zoomAtScheduleModalState.open,
      uc: getFilteredUcForZommAtScheduleOptions(uc, zoomedClassType, period),
    });
    setZoomAtScheduleModalClassType(zoomedClassType);
  };

  useEffect(() => {
    if (zoomAtScheduleModalState.open) {
      const uc = ucs.find((uc) => uc.ucId === zoomAtScheduleModalState?.uc?.ucId);
      const zoomedClassType = getFirstScheduleType(zoomAtScheduleModalState.uc, classesTypologies);
      if (uc) {
        setZoomAtScheduleModalState({
          open: zoomAtScheduleModalState.open,
          uc: getFilteredUcForZommAtScheduleOptions(uc, zoomedClassType, period),
        });
      }
    }
  }, [ucs]);

  const handleUCZoomOnClose = () => {
    setZoomAtScheduleModalState({ open: false, uc: null });
  };

  const selectedClassScheduleAndLockState = (
    selectedClassAndLockData: IScheduleAndLock | null,
    allocated: boolean
  ) => {
    if (selectedClassAndLockData) {
      const { /*locked,*/ allocation } = selectedClassAndLockData;
      return (
        <SelectedClass>
          {/*?<SelectedClassLockState allocated={allocated} allocation={allocation}>
            {locked ? <FontAwesomeIcon icon={faUnlock} /> : <FontAwesomeIcon icon={faLock} />}
          </SelectedClassLockState>
          */}
          <SelectedClassScheduleWrapper>
            {selectedClassAndLockData.schedule.map((selectedClass, index) => {
              const { weekDay, start, end } = selectedClass;
              return (
                <SelectedClassSchedule key={`selectedClassSchedule-${index}`}>
                  <SelectedClassWeekDay>{`${weekDay}\u00A0|`}</SelectedClassWeekDay>
                  <SelectedClassHour>{`\u00A0${start} - ${end}`}</SelectedClassHour>
                </SelectedClassSchedule>
              );
            })}
          </SelectedClassScheduleWrapper>
        </SelectedClass>
      );
    }
  };

  const zommAtScheduleModalHeader = (id: number, name: string, color: string) => {
    return (
      <ZommAtScheduleModalHeader ucColor={color}>
        {id} | {name}
      </ZommAtScheduleModalHeader>
    );
  };

  if (!ucs.length && !loadingSchedule) {
    return (
      <NoDataMessageWrapper>
        <NoDataMessage header={t('sgh.noUc_plural')} width={'50%'} />
      </NoDataMessageWrapper>
    );
  }

  return (
    <Wrapper>
      {zoomAtScheduleModalState && zoomAtScheduleModalState.uc && (
        <Modal
          open={zoomAtScheduleModalState && zoomAtScheduleModalState.uc ? true : false}
          type={'content'}
          /*cancelButtonText={t('generic.close')}
          onCancel={() => {
            handleUCZoomOnClose();
          }}*/
          header={zommAtScheduleModalHeader(
            zoomAtScheduleModalState.uc.ucId,
            zoomAtScheduleModalState.uc.ucFullName,
            zoomAtScheduleModalState.uc.ucColor
          )}
          onClose={() => handleUCZoomOnClose()}
          submitButtonText={t('generic.confirm')}
          onSubmit={() => handleUCZoomOnClose()}
        >
          <>
            <ZommAtScheduleModalMultiToggle>
              <MultiToggle
                buttons={zommAtScheduleOptions}
                content={'text'}
                fontSize={'15px'}
                fontWeight={'300'}
                textTransform={'uppercase'}
              />
            </ZommAtScheduleModalMultiToggle>
            <ScheduleGrid
              t={{ noUcs: t('sgh.noUc_plural') }}
              weekdays={6}
              startTime={scheduleGridInterval.min}
              endTime={scheduleGridInterval.max}
              intervalInMin={60}
              disabled={
                (currentPhase?.phase && currentPhase?.finished) || !currentPhase?.phase || viewMode
                  ? true
                  : false
              }
              schedule={[zoomAtScheduleModalState.uc]}
              onSelect={(classItem) => {
                dispatch(
                  setClassSelectionState({
                    ucId: classItem.ucId,
                    groupId: classItem.groupId,
                    classId: classItem.classId,
                    type: classItem.classType,
                  })
                );
              }}
            />
            <CaptionWrapper>
              <CaptionSchedule />
            </CaptionWrapper>
          </>
        </Modal>
      )}

      <InnerWrapper /*ref={wrapperRef}*/>
        {ucs.map((uc: IUc, index) => {
          const { classSchedule } = uc;
          if (classSchedule.length && classSchedule.some((eachClass) => !eachClass.filtered)) {
            return (
              <UCWrapper key={`uc-${uc.ucId}_${index}`}>
                <UCHeader ucColor={uc.ucColor}>
                  <UCFullName>{`${uc.ucId} | ${uc.ucInitials} - ${uc.ucFullName}`}</UCFullName>
                  <Button
                    borderless
                    color={theme.colors.darkGrey}
                    size={'20px'}
                    onClick={() => handleUCZoomOnClick(uc)}
                    data-testid="schedule_list_view_button_zoom"
                  >
                    <Tooltip
                      content={t('sgh.tooltipSeeGrid')}
                      trigger={<FontAwesomeIcon icon={faEye} />}
                    />
                  </Button>
                </UCHeader>
                <ClassSelectionWrapper>
                  <Grid>
                    {classesTypologies.map((typology, index) => {
                      const options = getDropdownOptions(
                        uc,
                        typology.initials,
                        classesTypologies,
                        getIrregularMessage
                      );
                      if (options.length) {
                        const allocated = getAllocatedState(ucs, uc.ucId, typology.initials);
                        return (
                          <Grid.Column
                            largeScreen={4}
                            computer={4}
                            tablet={8}
                            mobile={16}
                            key={`classSelection-${index}`}
                          >
                            {
                              <Tooltip
                                content={typology.name}
                                disabled={
                                  !(
                                    viewMode ||
                                    allocated ||
                                    uc.disabled ||
                                    (currentPhase?.phase && currentPhase?.finished) ||
                                    currentPhase === null
                                  )
                                }
                                trigger={
                                  <div>
                                    <Dropdown
                                      options={options}
                                      fitToContent
                                      selectionWeight="500"
                                      description
                                      placeholder={typology.name}
                                      disabled={
                                        viewMode ||
                                        allocated ||
                                        uc.disabled ||
                                        (currentPhase?.phase && currentPhase?.finished) ||
                                        currentPhase === null
                                      }
                                      borderColor={
                                        allocated
                                          ? getDropdownBorderColor(
                                              ucs,
                                              uc.ucId,
                                              typology.initials,
                                              allocated,
                                              {
                                                blue: theme.colors.primary,
                                                green: theme.colors.successGreen,
                                                red: theme.colors.dangerRed,
                                              }
                                            )
                                          : ''
                                      }
                                      value={getDropdownValue(
                                        ucs,
                                        uc.ucId,
                                        typology.initials,
                                        classesTypologies
                                      )}
                                      onChange={(e, { value }) =>
                                        dispatch(
                                          setClassSelectionState({
                                            ucId: uc.ucId,
                                            groupId: uc.groupId,
                                            classId: value,
                                            type: typology.initials,
                                          })
                                        )
                                      }
                                      data-testid="schedule_list_view_dropdown"
                                    />
                                  </div>
                                }
                              />
                            }
                            {uc.full && getIrregularMessage(uc.full)}

                            {!uc.full &&
                              selectedClassScheduleAndLockState(
                                getScheduleAndLockState(
                                  ucs,
                                  uc.ucId,
                                  uc.groupId,
                                  typology.initials
                                ),
                                allocated
                              )}
                          </Grid.Column>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </Grid>
                </ClassSelectionWrapper>
              </UCWrapper>
            );
          } else {
            return null;
          }
        })}
      </InnerWrapper>
      {/*isWrapperScrollable() && (
        <ScrollDownButtonWrapper>
          <ScrollDownButton onClick={() => handleOnClick()} />
        </ScrollDownButtonWrapper>
      )*/}
    </Wrapper>
  );
};

export default ScheduleListView;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  position: relative;
`;

const InnerWrapper = styled.div`
  > div {
    width: 100%;
    margin-bottom: 27px;
  }

  // Hide scrollbar
  // Chrome, Safari and Opera
  &::-webkit-scrollbar {
    display: none;
  }

  // IE and Edge
  -ms-overflow-style: none;

  // Firefox
  scrollbar-width: none;
`;

/*const ScrollDownButtonWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 0;
  left: 50%;
  z-index: 1;
`;*/

const UCWrapper = styled.div`
  margin-bottom: 68px;
`;

const UCHeader = styled.div<{ ucColor?: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid ${({ theme, ucColor }) => ucColor || theme.colors.grey};
`;

const UCFullName = styled.h3`
  padding: 8px 0;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-weight: 700;
  text-transform: uppercase;
`;

const ZommAtScheduleModalHeader = styled.div<{ ucColor: string }>`
  margin: 68px 0 62px 48px;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;

  &:after {
    content: '';
    width: 40px;
    height: 123px;
    position: absolute;
    top: 0;
    left: 24px;
    background-color: ${({ ucColor }) => ucColor};
  }
`;

const ZommAtScheduleModalMultiToggle = styled.div`
  margin: 0 0 16px 62px;
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};

  > div {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }
`;

const ClassSelectionWrapper = styled.div`
  display: flex;
  margin-top: 28px;

  .grid {
    width: 100%;
  }
`;

const SelectedClass = styled.div`
  display: flex;
  margin-top: 24px;
  text-align: left;
`;

const SelectedClassLockState = styled.div<{
  allocated: boolean;
  allocation?: 'likely' | 'uncertain' | 'unlikely';
}>`
  width: 18px;
  height: 18px;
  position: relative;
  margin-right: 2px;

  svg {
    display: inline-block;
    position: absolute;
    bottom: 3px;
    right: 0;
    font-size: 10px;
  }

  &:before {
    width: 0;
    height: 0;
    display: ${({ allocated, allocation }) => (!allocated && allocation ? `block` : `none`)};
    border-top: 10px solid
      ${({ theme, allocation }) => {
        if (allocation === 'likely') {
          return theme.colors.successGreen;
        } else if (allocation === 'uncertain') {
          return theme.colors.decisionYellow;
        } else if (allocation === 'unlikely') {
          return theme.colors.dangerRed;
        }
      }};
    border-right: 10px solid transparent;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const SelectedClassScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectedClassSchedule = styled.div``;

const SelectedClassWeekDay = styled.span`
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  font-weight: 700;
`;

const SelectedClassHour = styled.span`
  color: ${({ theme }) => theme.colors.plusDarkGrey};
  font-weight: 300;
`;

const NoDataMessageWrapper = styled.div`
  height: 422px;
  display: flex;
  justify-content: center;
  padding-top: 100px;
`;

const IrregularMessage = styled(Text)`
  &&& {
    align-items: flex-start;
  }
`;

const IrregularMessageWrapper = styled.div<{ noMargin?: boolean }>`
  margin-top: ${({ noMargin }) => (noMargin ? '0' : '24px')};
`;

const CaptionWrapper = styled.div`
  margin-top: -20px;
  padding-left: 63px;
`;
