/**
 * CertificatesList scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { select, call, put, putResolve, takeLatest, delay } from 'redux-saga/effects';

import { t } from 'app';

import API, { IParams } from 'api';
import ErrorHandler from 'shared/errorHandler';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

function* onMountSaga() {
  try {
    yield put(actions.CertificatesList.reset());
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.CertificatesList.setErrorStatus({
          fieldName: 'errLoading',
          fieldValue: true,
        })
      );

      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.certificatesList_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getInitialFilterStateSaga(params?: IParams) {
  try {
    const registrationId = yield select(selectors.getRegistration);
    const { requestParams } = yield select(selectors.getCertificatesListSlice);

    if (registrationId === null) return;
    
    if (requestParams.state !== undefined) {
      yield put(actions.CertificatesList.setRequestParams({ ...params }));
      return;
    }

    const initialFilterState = yield call(API.secVirtual.getStudentCertificatesOnMountState.call, {
      registrationId,
    });
    yield put(
      actions.CertificatesList.setRequestParams({
        ...params,
        state: initialFilterState.onMountState !== '' ? initialFilterState.onMountState : 'ready',
      })
    );
    
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.CertificatesList.setErrorStatus({
          fieldName: 'errLoadingCertificateList',
          fieldValue: true,
        })
      );

      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.certificatesList_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getDataSaga(action?: { payload: IParams | undefined }) {
  const params = action?.payload || {};

  try {
    yield put(
      actions.CertificatesList.setErrorStatus({
        fieldName: 'errLoadingCertificateList',
        fieldValue: false,
      })
    );

    const registrationId = yield select(selectors.getRegistration);
    yield getInitialFilterStateSaga(params);
    const { requestParams } = yield select(selectors.getCertificatesListSlice);

    yield put(
      actions.CertificatesList.setLoadingStatus({
        fieldName: 'loadingCertificatesList',
        fieldValue: true,
      })
    );

    const certificatesList = yield call(API.secVirtual.getCertificatesList.call, {
      ...requestParams,
      registrationId: registrationId,
    });

    yield put(actions.CertificatesList.setCertificatesList(certificatesList.data));

    const filters = certificatesList.filters.map((filter, key) => ({
      key: key,
      text: filter,
      value: filter,
    }));

    yield put(actions.CertificatesList.setCertificatesFilters(filters));

    yield put(
      actions.CertificatesList.setLoadingStatus({
        fieldName: 'loadingCertificatesList',
        fieldValue: false,
      })
    );

    yield put(
      actions.CertificatesList.setLoadingStatus({ fieldName: 'loading', fieldValue: false })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.CertificatesList.setErrorStatus({
          fieldName: 'errLoadingCertificateList',
          fieldValue: true,
        })
      );

      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.certificatesList_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.CertificatesList.reset());
}

export default function* watcherSignin() {
  yield takeLatest('CertificatesList/onMount', onMountSaga);
  yield takeLatest('CertificatesList/getData', getDataSaga);
  yield takeLatest('CertificatesList/onUnmount', onUnmountSaga);
}
