/**
 * MySchedules scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { call, takeLatest, put, putResolve, select } from 'redux-saga/effects';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { IScheduleToDelete } from './slice';

import { immutableRemove } from 'utils';
import { reformatUcs } from 'scenes/Schedules/MySchedule/utils';

import { getScheduleInterval } from 'scenes/Schedules/utils';

import { IMySchedule, ICreatedScheduleClass } from 'shared/types';

import { sortSchedules } from '../utils';

import ErrorHandler from 'shared/errorHandler';

interface IGetSchedules {
  type: 'MySchedules/getSchedules';
  payload: {
    registrationId: number;
    activateLoading: boolean;
    data?: any;
  };
}

interface IDeleteScheduleSaga {
  type: 'MySchedules/deleteSchedule';
  payload: IScheduleToDelete;
}

interface IUpdateSchedulesSaga {
  type: 'MySchedules/updateSchedules';
  payload: IMySchedule[];
}

interface IGetScheduleToViewSaga {
  type: 'MySchedules/getScheduleToView';
  payload: { id: number; finishedPhase: boolean };
}

function* onMountSaga() {
  try {
    yield put(actions.MySchedules.setLoadingSchedules(true));
    yield putResolve(actions.MySchedules.reset());
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(actions.Schedules.setRestriction(true));
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetSchedule_plural', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onPhaseUpdateSaga() {
  yield put(actions.MySchedules.setLoadingSchedules(true));
  const registration = yield select(selectors.getRegistration);
  yield put(
    actions.MySchedules.getSchedules({ registrationId: registration, activateLoading: true })
  );
}

function* getSchedulesSaga(action: IGetSchedules) {
  try {
    yield put(actions.MySchedules.setLoadingSchedules(action.payload.activateLoading));

    if (!action.payload.registrationId) {
      return;
    }

    const phases = yield call(API.sgh.getPhases.call);
    const currentPhase = yield call(API.sgh.getStudentAccess.call, {
      registrationId: action.payload.registrationId,
    });
    const isCurrentPhaseActive = phases.find(p => { return p.period === currentPhase.period && p.name === currentPhase.name })?.isActive ?? false
    yield putResolve(
      actions.MySchedules.setCurrentActivePhase(
        currentPhase.name !== null && currentPhase.name !== undefined && !currentPhase.finished && isCurrentPhaseActive
          ? currentPhase.name
          : null
      )
    );

    yield putResolve(
      actions.MySchedules.setCurrentPhase({
        phase: currentPhase.name,
        year: currentPhase.year,
        period: currentPhase.period,
        isFree: currentPhase.type === 'queue',
        finished: currentPhase.finished,
        hasAllocatedSchedule: currentPhase.hasAllocatedSchedule,
        attemptedSchedule: currentPhase.attemptedSchedule,
        seriationFinished: currentPhase.seriationFinished,
      })
    );

    if (!currentPhase.hasAccess) {
      yield put(actions.MySchedules.setWarningNoAccess(true));
      yield put(actions.MySchedules.setWarningNoAccessMotive(currentPhase.motive));
    } else {
      yield put(actions.MySchedules.setWarningNoAccess(false));
      yield put(actions.MySchedules.setWarningNoAccessMotive(''));

      yield put(
        actions.MySchedules.setShowHistory(
          currentPhase.attemptedSchedule && currentPhase.seriationFinished
        )
      );

      const createdSchedules = action.payload.data
        ? action.payload.data
        : yield call(API.sgh.getSchedules.call, {
            registrationId: action.payload.registrationId,
          });

      yield put(actions.MySchedules.setLastSeriatedPhase(createdSchedules.lastSeriatedPhase));
      yield put(actions.MySchedules.setNextSeriationDate(createdSchedules.nextSeriationDate));
      yield put(actions.MySchedules.setSeriationDate(createdSchedules.seriationDate));

      const sortedSchedules = sortSchedules(
        createdSchedules,
        currentPhase.name !== null && currentPhase.name !== undefined && !currentPhase.finished
      );
      yield put(actions.MySchedules.setSchedules(sortedSchedules));
    }

    yield put(actions.MySchedules.setLoadingSchedules(false));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (e?.response?.data?.errcode === 'ERR_PHASE_NO_PERMISSION') {
        yield put(actions.MySchedules.setWarningNoAccess(true));
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('sgh.actionErrorGetSchedule_plural', { textOnly: true }),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  }
}

function* deleteScheduleSaga(action: IDeleteScheduleSaga) {
  try {
    const { id } = action.payload;
    const { createdSchedules } = yield select(selectors.getMySchedulesSlice);

    const registration = yield select(selectors.getRegistration);

    yield put(actions.MySchedules.setSavingMySchedules(true));

    const indexOfSchedule = createdSchedules.activePhase.schedules.findIndex(
      (schedule) => schedule.id === id
    );
    const newSchedules = immutableRemove(
      createdSchedules.activePhase.schedules,
      indexOfSchedule
    ).map((schedule, key) => {
      if (key >= indexOfSchedule) {
        return { ...schedule, preference: schedule.preference - 1 };
      }
      return schedule;
    });

    const updatedSchedules = {
      ...createdSchedules,
      ...{ activePhase: { ...createdSchedules.activePhase, schedules: newSchedules } },
    };

    yield call(API.sgh.deleteSchedule.call, [{ scheduleId: id, registrationId: registration }]);

    yield put(actions.MySchedules.removeScheduleToDelete());
    yield put(actions.MySchedules.setSchedules(updatedSchedules));

    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionSuccessUpdateSchedule_plural'),
        icon: 'check',
        type: 'success',
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorRemoveSchedule'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(actions.MySchedules.setSavingMySchedules(false));
  }
}

function* updateSchedulesSaga(action: IUpdateSchedulesSaga) {
  const { createdSchedules } = yield select(selectors.getMySchedulesSlice);
  const schedulesPayload = action.payload;

  try {
    yield put(actions.MySchedules.setSavingMySchedules(true));

    const registration = yield select(selectors.getRegistration);

    const updatedSchedules = {
      ...createdSchedules,
      ...{ activePhase: { ...createdSchedules.activePhase, schedules: schedulesPayload } },
    };

    yield put(actions.MySchedules.setSchedules(updatedSchedules));

    const formattedSchedules = schedulesPayload.map((schedule) => ({
      id: schedule.id,
      preference: schedule.preference,
    }));

    const output = yield call(API.sgh.patchSchedule.call, {
      registrationId: registration,
      schedules: formattedSchedules,
    });

    yield put(
      actions.MySchedules.getSchedules({
        registrationId: registration,
        activateLoading: false,
        data: output,
      })
    );

    yield put(
      actions.Toaster.showToaster({
        title: t('sgh.actionSuccessUpdateSchedule_plural', { textOnly: true }),
        icon: 'check',
        type: 'success',
      })
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorUpdateSchedule_plural', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(actions.MySchedules.setSavingMySchedules(false));
  }
}

function* getScheduleToViewSaga(action: IGetScheduleToViewSaga) {
  const registration = yield select(selectors.getRegistration);
  const { scheduleGridInterval } = yield select(selectors.getMySchedulesSlice);
  // Get UCs in order to be able to create an object
  // that match the <ScheduleGrid /> structure
  const { id, finishedPhase } = action.payload;

  try {
    yield put(actions.MySchedules.showScheduleModalOpening());
    yield put(actions.MySchedules.setLoadingScheduleToView(true));

    const scheduleToView = yield call(API.sgh.getSchedule.call, id, registration);

    const ucs = {
      normal: scheduleToView?.normal,
      mandatory: scheduleToView?.mandatory,
      free: scheduleToView?.free,
    };

    let { allUcs } = reformatUcs(ucs, false, false, false, false, finishedPhase);
    //filter only selected items
    allUcs = allUcs.map((ucUnit) => ({
      ...ucUnit,
      classSchedule: ucUnit.classSchedule.filter((classItem) => classItem.selected),
    }));

    yield put(actions.MySchedules.setUcs({ ucs: allUcs, hideFaces: finishedPhase }));

    yield put(
      actions.MySchedules.setScheduleGridInterval(getScheduleInterval(allUcs, scheduleGridInterval))
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetScheduleToView'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(actions.MySchedules.setLoadingScheduleToView(false));
  }
}

export default function* watcherSignin() {
  yield takeLatest('MySchedules/onMount', onMountSaga);
  yield takeLatest('MySchedules/onPhaseUpdate', onPhaseUpdateSaga);
  yield takeLatest('MySchedules/getSchedules', getSchedulesSaga);
  yield takeLatest('MySchedules/updateSchedules', updateSchedulesSaga);
  yield takeLatest('MySchedules/deleteSchedule', deleteScheduleSaga);
  yield takeLatest('MySchedules/getScheduleToView', getScheduleToViewSaga);
}
