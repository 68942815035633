/**
 * PersonalDataBlock component
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Button, Text, Tooltip } from '@paco_ua/pacoui';

import { useDispatch } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import FormManager from 'shared/containers/FormManager';
import { Grid } from 'semantic-ui-react';

import { actions } from 'store/rootSlices';

import { setValuesStructure } from './utils';

import { t } from 'app';

interface IProps {
  blockKey: string;
  title: string;
  subtitle?: string;
  picture?: any | null;
  showPicture?: boolean;
  lastUpdated: string;
  showEditBlockButton: boolean;
  formDataList: any;
  editing?: boolean;
  loading?: boolean | undefined;
}

const PersonalDataBlock: React.FC<IProps> = ({
  blockKey,
  title,
  subtitle,
  picture,
  showPicture,
  lastUpdated,
  showEditBlockButton,
  formDataList,
  editing,
  loading,
}) => {
  const theme = useContext(ThemeContext);

  const [editBlockState, setEditBlockState] = useState(false);

  const dispatch = useDispatch();

  const {
    patchAddressForm,
    patchContactsForm,
    resetForm,
    setPersonalDataFormEditingState,
  } = actions.PersonalData;

  useEffect(() => {
    editing !== undefined && setEditBlockState(editing);
  }, [editing]);

  const onCancel = (data) => {
    dispatch(resetForm({ type: blockKey, payload: data }));
    setEditBlockState(false);
  };

  const onSubmit = (data) => {
    if (blockKey === 'address') {
      dispatch(patchAddressForm(data));
    } else if (blockKey === 'contacts') {
      dispatch(patchContactsForm(data));
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <HeaderWrapper picture={picture}>
          <TitleWrapper style={blockKey === 'profile' ? { marginBottom: '32px' } : {}}>
            <Text color="plusDarkGrey" size="large" fontWeight="400">
              {title}
            </Text>
            {subtitle && blockKey !== 'profile' && blockKey !== 'parents' && (
              <SubtitleText color="darkGrey" size="article" fontWeight="400">
                {subtitle}
              </SubtitleText>
            )}
          </TitleWrapper>

          {showEditBlockButton && !editBlockState && (
            <EditButtonWrapper>
              <Tooltip
                trigger={
                  <Button
                    onClick={() => {
                      dispatch(
                        setPersonalDataFormEditingState({
                          blockKey: blockKey,
                          editing: true,
                        })
                      ) && setEditBlockState(true);
                    }}
                    borderless
                    data-testid={`personaldata_edit_button`}
                    action
                  >
                    <FontAwesomeIcon icon={faPen} size="lg" />
                  </Button>
                }
                content={t('generic.update', { textOnly: true })}
              />
            </EditButtonWrapper>
          )}
        </HeaderWrapper>

        {showPicture && (
          <PictureWrapper>
            <PictureDescriptionWrapper>
              <Text weight="bold" color={theme.colors.darkGrey} size="xSmall">
                {t('personalData.picture')}
              </Text>
              <Text color={theme.colors.grey} size="xSmall" fontWeight="400">
                {t('personalData.pictureMessage')}
              </Text>
            </PictureDescriptionWrapper>
            <PictureFrameWrapper>
              <StudentPicture
                src={picture ? `data:image/jpeg;base64,${picture}` : undefined}
                alt="Fotografia do Estudante"
              />
            </PictureFrameWrapper>
          </PictureWrapper>
        )}

        {showPicture && <LineSeparator picture={picture} />}

        <FormTableWrapper formSchema={formDataList}>
          {({ formState: { errors }, getValues, handleSubmit }, FormElements, FormElementsData) => (
            <>
              {formDataList && (
                <Grid>
                  {Object.keys(FormElements).map((fieldName, index) => {
                    const FormElement = FormElements[fieldName];
                    const defaultValue = FormElementsData[fieldName].value;
                    const type = FormElementsData[fieldName].type;
                    const hidden = FormElementsData[fieldName].hidden;

                    if (hidden) {
                      return null;
                    }

                    return (
                      <Grid.Column
                        key={`personalDataBlock_${index}`}
                        mobile={16}
                        tablet={8}
                        computer={fieldName === '' ? 5 : 8}
                        largeScreen={fieldName === '' ? 5 : 8}
                        widescreen={fieldName === '' ? 5 : 8}
                      >
                        {(editBlockState && fieldName === 'homeCountryName') ||
                        fieldName === 'photo' ||
                        fieldName === 'photo_extension' ? (
                          ''
                        ) : (
                          <TextFieldTitle>
                            <Text color="grey" size="xSmall" fontWeight="400">
                              {t(`personalData.${fieldName}`)}
                            </Text>
                          </TextFieldTitle>
                        )}

                        {editBlockState && type === 'textbox' && (
                          <FormInputWrapper>
                            <FormElement
                              fontSize={'mediumSmall'}
                              border
                              inputType={fieldName === 'primaryPhoneNumber' && 'tel'}
                              placeholder={t(
                                `personalData.${FormElementsData[fieldName].placeholderKey}`,
                                {
                                  textOnly: true,
                                }
                              )}
                              error={
                                errors[fieldName] &&
                                t(`forms.${errors[fieldName].message}`, { textOnly: true })
                              }
                            />
                          </FormInputWrapper>
                        )}

                        {editBlockState && type === 'flag_dropdown_textbox' && (
                          <FormElement
                            fontSize={'mediumSmall'}
                            border={type === 'textbox' ? true : undefined}
                            defaultValue={{
                              input: getValues(fieldName)?.input || defaultValue || '',
                              countryValue: FormElementsData.homeCountry.value.toLowerCase() || '',
                            }}
                            placeholder={t(
                              `personalData.${FormElementsData[fieldName].placeholderKey}`,
                              {
                                textOnly: true,
                              }
                            )}
                            showTooltip={fieldName === 'homePostalCode'}
                            displayLocale={fieldName === 'homePostalCode' ? true : false}
                            error={
                              errors[fieldName] &&
                              (errors[fieldName].type === 'typeError'
                                ? t('forms.invalidFieldValue', { textOnly: true })
                                : t(`forms.${errors[fieldName].message}`, { textOnly: true }))
                            }
                          />
                        )}

                        {editBlockState && type !== 'textbox' && type !== 'flag_dropdown_textbox' && (
                          <FormElement
                            fontSize={'mediumSmall'}
                            border={type === 'textbox' ? true : undefined}
                            placeholder={t(
                              `personalData.${FormElementsData[fieldName].placeholderKey}`,
                              {
                                textOnly: true,
                              }
                            )}
                            displayLocale={fieldName === 'homePostalCode' ? true : false}
                            error={
                              errors[fieldName] &&
                              (errors[fieldName].type === 'typeError'
                                ? t('forms.invalidFieldValue', { textOnly: true })
                                : t(`forms.${errors[fieldName].message}`, { textOnly: true }))
                            }
                          />
                        )}

                        {!editBlockState &&
                          fieldName !== 'photo' &&
                          fieldName !== 'photo_extension' && (
                            <Text fontWeight="400" color="black" size="mediumSmall">
                              {!defaultValue ? (
                                <FontAwesomeIcon icon={faEllipsisH} />
                              ) : (
                                setValuesStructure(getValues(fieldName), defaultValue)
                              )}
                            </Text>
                          )}
                      </Grid.Column>
                    );
                  })}
                </Grid>
              )}

              {editBlockState && (
                <ButtonWrapper>
                  <Button
                    data-testid={`personaldata_cancel_button`}
                    decision
                    onClick={() => onCancel(FormElementsData)}
                    disabled={loading}
                  >
                    {t('generic.cancel', { textOnly: true })}
                  </Button>

                  <Button
                    data-testid={`personaldata_save_button`}
                    primary
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                    disabled={loading}
                  >
                    {t('generic.save', { textOnly: true })}
                  </Button>
                </ButtonWrapper>
              )}
            </>
          )}
        </FormTableWrapper>

        <LineSeparator />

        <FooterWrapper>
          <Text color="Grey" size="xSmall" fontWeight="400">
            {lastUpdated}
          </Text>
        </FooterWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.div<{ picture: any }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ picture }) => (picture ? `0` : `32px`)};
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`;

const PictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const PictureDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: left;

  & div:first-child {
    margin-right: 120px;
  }
`;

const PictureFrameWrapper = styled.div`
  min-width: 60px;

  .ui.image {
    object-fit: cover;
  }
`;

const LineSeparator = styled.div<{ picture?: any }>`
  height: 1px;
  background: ${({ theme }) => theme.colors.softRegularGrey};
  margin-top: 16px;
  margin-bottom: ${({ picture }) => (picture ? `32px` : `12px`)};
`;

const EditButtonWrapper = styled.div`
  margin-left: auto;
`;

const FormTableWrapper = styled(FormManager)`
  margin-right: -1rem;
  margin-left: -1rem;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-top: 28px;

  > Button:not(:last-child) {
    margin-right: 8px;
  }
`;

const FormInputWrapper = styled.div`
  div:first-child {
    padding: 0px 8px 0px 8px;
  }
  &&& {
    line-height: 1;
  }
  && :only-child {
    padding: 0;
  }
  flex-grow: 1;
`;

const TextFieldTitle = styled.div`
  margin: 0px 0px 12px 0px;
`;

const FooterWrapper = styled.div``;

const SubtitleText = styled(Text)`
  padding: 8px 0px 0px 0px;
`;

const StudentPicture = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
`;

export default PersonalDataBlock;
