/**
 * RegistrationDetails scene sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { takeLatest, call, put, putResolve } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';

import API from 'api';
import { t } from 'app';

import ErrorHandler from 'shared/errorHandler';

import { getCurrentRoute } from 'utils';

function* onMountSaga() {
  yield put(actions.RegistrationDetails.setLoading(true));
  yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());

  yield call(getRegistrationSaga);
}

function* getRegistrationSaga() {
  try {
    yield put(
      actions.RegistrationDetails.setErrorStatus({
        fieldName: 'errorRegistrationDetails',
        fieldValue: false,
      })
    );

    const registrationId = getCurrentRoute().params.id;

    const registrationDetails = yield call(API.secVirtual.getRegistration.call, {
      registrationId: registrationId,
    });

    const registrationGeneralInfo = {
      accessScheme: registrationDetails.accessScheme,
      courseCode: registrationDetails.courseCode,
      courseName: registrationDetails.courseName,
      id: registrationDetails.id,
      registrationDate: registrationDetails.registrationDate,
      registrationAcademicYear: registrationDetails.registrationAcademicYear,
      registrationStatus: registrationDetails.registrationStatus,
      academicYear: registrationDetails.academicYear,
      typology: registrationDetails.typology,
    };

    yield put(actions.RegistrationDetails.setEligibility(registrationDetails.eligibility));
    yield put(actions.RegistrationDetails.setHistory(registrationDetails.history));
    yield put(actions.RegistrationDetails.setScholarship(registrationDetails.scholarship));
    yield put(actions.RegistrationDetails.setRegistrationGeneralInfo(registrationGeneralInfo));

    yield put(actions.RegistrationDetails.setLoading(false));
  } catch (e) {
    yield put(
      actions.RegistrationDetails.setErrorStatus({
        fieldName: 'errorRegistrationDetails',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.registrationDetails_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.RegistrationDetails.reset());
}

export default function* watcherSignin() {
  yield takeLatest('RegistrationDetails/onMount', onMountSaga);
  yield takeLatest('RegistrationDetails/onUnmount', onUnmountSaga);
}
