/**
 * CertificatesDetails scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { call, put, putResolve, takeLatest } from 'redux-saga/effects';
import ErrorHandler from 'shared/errorHandler';

import { PayloadAction } from '@reduxjs/toolkit';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';

import config from 'config';

function* onMountSaga() {
  try {
    yield put(actions.CertificatesDetails.reset());
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.certificateDetails_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* getDataSaga(action: PayloadAction<{ certificateId: number; registrationId: number }>) {
  try {
    yield put(
      actions.CertificatesDetails.setLoadingStatus({
        fieldName: 'errLoadingCertificatesDetails',
        fieldValue: false,
      })
    );

    const certificatesDetails = yield call(
      API.secVirtual.getCertificatesDetails.call,
      action.payload.certificateId,
      action.payload.registrationId
    );

    // only retrieve preview when the certificate request is ready
    if (certificatesDetails.state.state === 'ready' && certificatesDetails.certificate) {
      const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

      if (certificatesDetails.certificate.url !== null) {
        const pdfFile = yield call(
          API.files.getFileUint8Array.call,
          `${API_SEC_VIRTUAL}${certificatesDetails.certificate.url}`
        );

        yield put(actions.CertificatesDetails.setCertificateFile(pdfFile));
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.certificates_errorLoadingPdfFile'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }

    yield put(actions.CertificatesDetails.setCertificatesDetails(certificatesDetails));

    yield put(
      actions.CertificatesDetails.setLoadingStatus({
        fieldName: 'loadingCertificatesDetails',
        fieldValue: false,
      })
    );
  } catch (e) {
    yield put(
      actions.CertificatesDetails.setErrorStatus({
        fieldName: 'errLoadingCertificateDetails',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (e.response.data.error_code === 'nullFileUrl') {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.certificateDetails_errorLoadingCertificateFile'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.certificateDetails_errorLoadingPage'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  }
}

function* certificatePaymentSaga(action: PayloadAction<any>) {
  try {
    const certificatePaymentData = action.payload;

    yield put(actions.Payments.setPreSelectedDebits(certificatePaymentData));
    yield put(actions.Payments.setSelectedDebits(certificatePaymentData));

    yield put(
      actions.App.navigateTo({
        key: 'pendingDebitsPayment',
      })
    );
  } catch (e) {
  } finally {
  }
}

function* certificatePaymentPendingSaga(action: PayloadAction<any>) {
  try {
    yield put(
      actions.App.navigateTo({
        key: 'payments',
      })
    );
  } catch (e) {
  } finally {
  }
}

function* openChangeHistoryModalSaga(
  action: PayloadAction<{ certificateId: number; registrationId: number }>
) {
  try {
    yield put(
      actions.CertificatesDetails.setLoadingStatus({
        fieldName: 'loadingAccessHistory',
        fieldValue: true,
      })
    );
    yield put(actions.CertificatesDetails.setVisibleAccessHistoryModal(true));
    const certificatesChangeHistory = yield call(
      API.secVirtual.getCertificatesDetailsChangeHistory.call,
      action.payload.certificateId,
      action.payload.registrationId
    );

    if (certificatesChangeHistory) {
      yield put(actions.CertificatesDetails.setAccessHistory(certificatesChangeHistory));
    }
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.certificateDetails_errorLoadingCertificateChangeHistory'),
        icon: 'error',
        type: 'danger',
      })
    );
  } finally {
    yield put(
      actions.CertificatesDetails.setLoadingStatus({
        fieldName: 'loadingAccessHistory',
        fieldValue: false,
      })
    );
  }
}

function* onUnmountSaga() {
  //yield put(actions.CertificatesDetails.reset());
}

export default function* watcherSignin() {
  yield takeLatest('CertificatesDetails/onMount', onMountSaga);
  yield takeLatest('CertificatesDetails/getData', getDataSaga);
  yield takeLatest('CertificatesDetails/certificatePayment', certificatePaymentSaga);
  yield takeLatest('CertificatesDetails/certificatePaymentPending', certificatePaymentPendingSaga);
  yield takeLatest('CertificatesDetails/openChangeHistoryModal', openChangeHistoryModalSaga);
  yield takeLatest('CertificatesDetails/onUnmount', onUnmountSaga);
}
