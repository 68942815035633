import { t } from 'app';

export interface StudentData {
  nota: string;
}

export const formatDate = (dateStr: string) => {
  const parts = dateStr.split('-'); // split the string into an array of parts
  if (parts.length === 3) {
    let [day, month, year] = parts;

    // ddd leading zeros if day or month is a single digit
    day = day.length === 1 ? `0${day}` : day;
    month = month.length === 1 ? `0${month}` : month;
    return `${day}/${month}/${year}`;
  }
  return dateStr; // return original string if format is unexpected
};