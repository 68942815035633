/**
 * ToasterAction container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { Message, Transition } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCookieBite } from '@fortawesome/pro-light-svg-icons';

const ToasterAction: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const { showing, toast, displayToaster } = useSelector(selectors.getToasterActionSlice);

  const { title, buttonTitle, cancelTitle, onAccept, onCancel } = toast;
  const { closeToasterAction } = actions.ToasterAction;

  const ToastContent = (
    <>
      <MessageWrapper>
        <IconWrapper color={theme.colors.primary}>
          <FontAwesomeIcon icon={faCookieBite} />
        </IconWrapper>
        <BodyWrapper>
          <TitleWrapper color={theme.colors.darkGrey}>
            <span>{title}</span>
          </TitleWrapper>
          <MessageBody>
            <Button
              danger
              onClick={() => {
                onCancel && onCancel();
                dispatch(closeToasterAction());
              }}
            >
              {cancelTitle}
            </Button>
            <Button
              full
              primary
              onClick={() => {
                onAccept && onAccept();
                dispatch(closeToasterAction());
              }}
            >
              {buttonTitle}
            </Button>
          </MessageBody>
        </BodyWrapper>
      </MessageWrapper>
    </>
  );

  if (!displayToaster) {
    return null;
  }

  return (
    <Transition visible={showing} animation="fade up" duration={500}>
      <Wrapper>
        <Toast toastsize="60rem">{ToastContent}</Toast>
      </Wrapper>
    </Transition>
  );
};

export default ToasterAction;

const Wrapper = styled.div`
  &&& {
    position: fixed;
    bottom: 30px;
    z-index: 9999;
    display: flex !important;
    justify-content: center;
    width: 100%;
    padding: 0 3rem;
  }
`;

const BodyWrapper = styled.div``;

const Toast = styled(Message)<{ toastsize: string }>`
  &&& {
    padding: 0;
    width: ${({ toastsize }) => toastsize};
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.regularGrey};
    border-radius: 0;
    box-shadow: ${({ theme }) => theme.shadows.strong};
    z-index: 9999;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 24px;
  display: flex;
  justify-content: center;
  color: ${({ color }) => color};
  font-weight: 300;
`;

const IconWrapper = styled.div`
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ color }) => color};
  color: ${({ theme }) => theme.colors.white};
  font-size: 48px;
`;

const MessageBody = styled.div`
  margin: 0;
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: flex-end;
  color: ${({ color }) => color};

  > button:not(:last-child) {
    margin-right: 24px;
  }
`;
