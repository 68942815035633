/**
 * StudentRegistrationDropdown container sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { call, takeLatest, put, putResolve, select, cancel } from 'redux-saga/effects';


import { actions } from 'store/rootSlices';

import { t } from 'app';
import { getCurrentRoute } from 'utils';

import API from 'api';

import * as selectors from './selectors';

import ErrorHandler from 'shared/errorHandler';

function* getRegistrationsSaga() {
  try {
    const service = getCurrentRoute()?.route?.module?.service || null;
    const user = yield select(selectors.getUser);
    const registrationId = yield select(selectors.getRegistration);

    yield put(actions.StudentRegistrationDropdown.setLoadingRestriction(true));

    const registrations = yield call(API.student.getRegistrations.call, {
      service,
      user,
    });
    
    if(registrations.length === 0){
      yield put(actions.StudentRegistrationDropdown.setLoadingRestriction(false));
      yield put(
        actions.Toaster.showToaster({
          title: t('generic.errorSuspendedRegistration'),
          icon: 'error',
          type: 'danger',
        })
      );
      yield putResolve(actions.App.navigateTo({ key: 'home' }));
    }
    
    
    const sameItems =
      user?.registrations &&
      registrations.every((fetchedRegistration) =>
        user.registrations.some(
          (userRegistration) =>
            userRegistration.registrationId === fetchedRegistration.registrationId
        )
      ) &&
      user.registrations.every((userRegistration) =>
        registrations.some(
          (fetchedRegistration) =>
            userRegistration.registrationId === fetchedRegistration.registrationId
        )
      );

    if (registrations && registrations.length > 0 && !sameItems && registrationId === null) {
      yield putResolve(
        actions.StudentRegistrationDropdown.setRegistration(registrations[0].registrationId)
      );
    }

    yield putResolve(actions.App.setUser({ ...user, registrations }));

    yield put(actions.StudentRegistrationDropdown.setLoadingRestriction(false));
    
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('generic.errorRegistrations'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

export default function* watcherSignin() {
  yield takeLatest('StudentRegistrationDropdown/getRegistrations', getRegistrationsSaga);
}
