/**
 * Registration scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest, call, put, putResolve } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import ErrorHandler from 'shared/errorHandler';

import { t } from 'app';

function* onMountSaga() {
  try {
    yield put(actions.Registrations.setLoading(true));

    yield put(
      actions.Registrations.setErrorStatus({
        fieldName: 'errLoadingRegistrations',
        fieldValue: false,
      })
    );

    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());

    yield put(actions.Registrations.setLoading(false));
  } catch (e) {
    yield put(
      actions.Registrations.setErrorStatus({
        fieldName: 'errLoadingRegistrations',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorLoadingRegistrations', { textOnly: true }),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.Registrations.reset());
}

export default function* watcherSignin() {
  yield takeLatest('Registrations/onMount', onMountSaga);
  yield takeLatest('Registrations/onUnmount', onUnmountSaga);
}
