import development from './development';
import staging from './staging';
import production from './production';

import { IConfigs } from './types';

const config = () => {
  if (process.env.REACT_APP_ENV === 'development') {
    return development;
  } else if (process.env.REACT_APP_ENV === 'staging') {
    return staging;
  } else {
    return production;
  }
};

const configs: IConfigs = {
  // Add common config values here
  ROUTES_ENV: process.env.REACT_APP_ROUTES,
  ROLES_RELATIONSHIPS: [
    {
      role: 'guest',
      relationships: [],
    },
    {
      role: 'superUser_ReadOnly',
      relationships: [],
    },
    {
      role: 'superUser_FullAccess',
      relationships: [],
    },
    {
      role: 'sgh_superUser_ReadOnly',
      relationships: [],
    },
    {
      role: 'sgh_superUser_FullAccess',
      relationships: [],
    },
    {
      role: 'sec_superUser_ReadOnly',
      relationships: [],
    },
    {
      role: 'sec_superUser_FullAccess',
      relationships: [],
    },
    {
      role: 'pautas_superUser_ReadOnly',
      relationships: [],
    },
    {
      role: 'pautas_superUser_FullAccess',
      relationships: [],
    },
    {
      role: 'student',
      relationships: ['FI', 'PG'],
    },
    {
      role: 'exStudent',
      relationships: ['EA'],
    },
    {
      role: 'scholarship',
      relationships: ['B'],
    },
    {
      role: 'researcher',
      relationships: ['I', 'IE'],
    },
    {
      role: 'protocol',
      relationships: ['PR'],
    },
    {
      role: 'nonProfessor',
      relationships: ['CA', 'F'],
    },
    {
      role: 'other',
      relationships: ['CI', 'CL', 'REL'],
    },
    {
      role: 'professor',
      relationships: ['D'],
    },
  ],
  SCOPES: {
    GET: {
      SERIATION_RESULTS: 'paco_2.0_sgh_get_seriation_results',
      ALL_PHASES: 'paco_2.0_sgh_get_all_phases',
      CURRENT_PHASE: 'paco_2.0_sgh_get_current_phase',
      FINAL_SCHEDULE: 'paco_2.0_sgh_get_final_schedule',
      TEMP_SCHEDULE: 'paco_2.0_sgh_get_temp_schedules',
      STUDENT_UCS: 'paco_2.0_sgh_get_student_ucs',
      STUDENT_UCS_MANDATORY: 'paco_2.0_sgh_get_student_ucs_mandatory',
      STUDENT_UCS_FREE: 'paco_2.0_sgh_get_student_ucs_free',
      STUDENT_HISTORY: 'paco_2.0_sgh_get_history',
    },
    POST: {
      TEMP_SCHEDULES: 'paco_2.0_sgh_post_temp_schedules',
    },
    PATCH: {
      TEMP_SCHEDULE: 'paco_2.0_sgh_patch_temp_schedules',
      TEMP_SCHEDULES: 'paco_2.0_sgh_patch_temp_schedules',
    },
    PUT: {
      TEMP_SCHEDULES: 'paco_2.0_sgh_put_temp_schedules',
    },
    DELETE: {
      TEMP_SCHEDULE: 'paco_2.0_sgh_delete_temp_schedules',
      ALL_SCHEDULES: 'paco_2.0_sgh_delete_all_schedules',
    },
  },
  ...process.env,
  ...config(),
};

export default configs;
export { IGroup, IScope, IRole, IService, IHealthChecks } from './types';
