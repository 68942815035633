/**
 * Toaster container slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 * @format
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IToast {
  title: string;
  cancelTitle: string;
  buttonTitle: string;
  onCancel?: Function;
  onAccept?: Function;
}

const initialState = {
  showing: false,
  displayToaster: true,
  toast: {} as IToast,
};

export default createSlice({
  name: 'ToasterAction',
  initialState,
  reducers: {
    showToasterAction: (state, action: PayloadAction<IToast>) => {
      state.showing = true;
      state.toast = action.payload;
    },
    unsetDisplayToasterAction: (state) => {
      state.displayToaster = false;
    },
    closeToasterAction: (state) => {
      state.showing = false;
    },
  },
});
