import React from 'react';
import styled from 'styled-components';
import { Container } from 'semantic-ui-react';

import { Text } from '@paco_ua/pacoui';

import logoUA from 'assets/images/logo512.png';

const InMaintenanceScene = ({ t }) => {
  return (
    <Wrapper>
      <Container>
        <AlignCenter>
          <LogoUA src={logoUA} alt="Universidade de Aveiro" />
        </AlignCenter>
        <WrapperText>
          <FormattedText weight="bold" size="xLarge">
            <AlignCenter>{t('generic.maintenance')}</AlignCenter>
          </FormattedText>
        </WrapperText>
      </Container>
    </Wrapper>
  );
};
export default InMaintenanceScene;

const FormattedText: React.FC<{
  size?: string;
  color?: string;
  weight?: string;
  onClick?: () => void;
}> = ({ children, size, weight, color }) => (
  <Text size={size ? size : 'large'} weight={weight}>
    {children}
  </Text>
);

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 26%;

  ${({ theme }) => theme.medias.sm} {
    padding: 0 20%;
  }
  ${({ theme }) => theme.medias.xs} {
    padding: 0 80px;
  }
`;

const LogoUA = styled.img`
  width: 120px;
  height: 120px;
  margin: 120px 0 80px;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    margin-bottom: 48px;
  }
`;

/*const PacoLegacyHref = styled.a`
  &:hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`;*/
