export interface ICourseUC {
  gradeType: string | null;
  bolonha: number;
  courseId: number;
  credits: number;
  ects: number;
  link: string;
  grade: number | null;
  scientificArea: string;
  semester: string;
  ucCode: number;
  ucName: string;
  ucType: number;
  mobilityInstitutionName: null | string;
  hasOneSignup?: boolean;
  yearOfStudy: number;
  selectedOption: null | {
    ucCode: number;
    ucName: string;
  };
}

export interface Dictionary<T> {
  [key: number]: T;
}

export interface ICourseSubUCs {
  ucCode: number;
  ucsList: ICourseUC[];
}

export interface ITabItem {
  key: string;
  value: string;
}

export interface IColumnItem {
  key: string;
  value: string;
}

export interface ICurricularPlan {
  courseName: string;
  courseCode: number;
  ucs: Dictionary<ICourseUC[]>; // key: curricular year
  subUcs: Dictionary<ICourseUC[]>; // key: ucCode
  extraCurricularUcs: ICourseUC[]; // disciplinas extra curriculares
  mobilityUcs: IMobilityUc[]; // disciplinas de mobilidade
  accreditedUcs: IAccreditedUcs[]; // disciplinas creditadas
}

export interface IMobilityUc {
  ucName: string;
  grade: number;
  academicYear: number;
  institutionName: string;
  programName: string;
}

export interface IAccreditedUcs {
  ucCode: number;
  ucName: string;
  grade: number;
  type: string;
  academicYear: string;
  institutionName: string;
  courseName: string;
}

export interface ISummary {
  grade: number;
  summary: {
    totalEctsEarned: number;
    totalEctsToDo: number;
    totalEctsAccredited: number;
    totalUcsCompleted: number;
    totalUcsToDo: number;
    totalUcsAccredited: number;
  };
}

export interface ITableRawData {
  ucs: ICourseUC[];
  subUcs: Dictionary<ICourseUC[]>;
  accreditedUcs: IAccreditedUcs[];
  mobilityUcs: IMobilityUc[];
  extraCurricularUcs: ICourseUC[];
}

export interface ICourseFields {
  ACADEMIC_YEAR: string;
  CREDITS: string;
  ECTS: string;
  GRADE: string;
  INSTITUTION: string;
  PROGRAMME: string;
  SEMESTER: string;
  TYPE: string;
  UC_CODE: string;
  UC_NAME: string;
  YEAR_STUDY: string;
  UC_DETAILS: string;
}

export interface ICourseTypes {
  FIRST_YEAR: string;
  SECOND_YEAR: string;
  THIRD_YEAR: string;
  FOURTH_YEAR: string;
  FIFTH_YEAR: string;
  SIXTH_YEAR: string;
  EVERY_COURSE_UCS: string;
  EXTRA_CURRICULAR_UCS: string;
  MOBILITY_UCS: string;
  ACCREDITED_UCS: string;
}

export const GRADE_TYPE = {
  ECTS: 1,
  CREDITS: -1,
};
