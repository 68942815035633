/**
 * FormDropdown component
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import React from 'react';

import styled from 'styled-components';

import { Dropdown, Text } from '@paco_ua/pacoui';

interface INewCertificateDropdown {
  register: any;
  onChange: (value: any) => void;
  error?: string;
  success?: any;
  name: string;
  placeholder: string;
  options: any[];
  defaultValue: number;
  value: number;
}

const FormDropdown: React.FC<INewCertificateDropdown> = ({
  error,
  name,
  defaultValue,
  onChange,
  value,
  options,
}) => {
  return (
    <Wrapper>
      <DropdownWrapper>
        <Dropdown
          name={name}
          onChange={(e, data) => onChange(data.value)}
          options={options.map((v, index) => ({
            key: `option_${index}`,
            text: v.nameKey,
            value: v.valueId,
          }))}
          defaultValue={defaultValue}
        />
      </DropdownWrapper>
      {error && (
        <MessageArea>
          <Text size="xSmall" weight="medium" color="dangerRed">
            {`* ${error}`}
          </Text>
        </MessageArea>
      )}
    </Wrapper>
  );
};

export default FormDropdown;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DropdownWrapper = styled.div`
   display: flex;
   flex-direction: column;
   width: 372px;
 
   &&& {
     padding: 0px;
   }
 
   && {
     .ui.dropdown {
       /*padding: 16px 12px;
       background-color: ${({ theme }) => theme.colors.lightGrey};
       align-self: flex-start;*/
       display: flex;
     }
 
     .ui.dropdown > .text {
       color: ${({ theme }) => theme.colors.plusDarkGrey};
       font-size: 15px;
       font-weight: 400;
       margin-right: 8px;
       flex-grow: 1;
     }
     
     /*.ui.dropdown > .dropdown.icon {
       color: ${({ theme }) => theme.colors.grey};
     }*/
 
     .ui.dropdown .menu > .item {
       color: ${({ theme }) => theme.colors.plusDarkGrey};
       font-size: 12px;
     }
   }
 `;

const MessageArea = styled.div`
  margin-top: 4px;
`;
