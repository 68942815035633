/**
 * FreeOptionsList scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest } from 'redux-saga/effects';

function* onMountSaga() {}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('FreeOptionsList/onMount', onMountSaga);
  yield takeLatest('FreeOptionsList/onUnmount', onUnmountSaga);
}
