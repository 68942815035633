/**
 * NewApplication scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getCurrentStep = (state: RootState) => state.NewApplication.currentStep;
export const getUserNmec = (state: RootState) => state.App.user.nmec;
export const getNewApplicationData = (state: RootState) => state.NewApplication.applicationData;
export const getNewApplicationSlice = (state: RootState) => state.NewApplication;
export const getApplicationRequestParams = (state: RootState) => state.NewApplication.filterParams;
export const getApplicationTypeId = (state: RootState) => state.NewApplication.applicationTypeId;
export const getApplicationLoading = (state: RootState) =>
  state.NewApplication.loadingStatus.loading;
export const getApplicationTabLoading = (state: RootState) =>
  state.NewApplication.loadingStatus.tabLoading;
export const getApplicationErrorStatus = (state: RootState) => state.NewApplication.errorStatus;
export const getRegistrations = (state: RootState) => state.App.user.registrations;
export const getUser = (state: RootState) => state.App.user;
