import React from 'react';
import styled from 'styled-components';

import { Text } from '@paco_ua/pacoui';
import { Container } from 'semantic-ui-react';

import logoUA from 'assets/images/logo512.png';
import { useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
//import config from 'config'

const PageServerError = ({ t, i18n }) => {
  const dispatch = useDispatch();
  const { navigateBack } = actions.App;

  return (
    <Wrapper>
      <Container>
        <AlignCenter>
          <LogoUA src={logoUA} alt="Universidade de Aveiro" />
        </AlignCenter>
        <WrapperText>
          <FormattedText weight="bold" size="xxxLarge">
            <AlignCenter>500</AlignCenter>
          </FormattedText>
          <FormattedText>
            <AlignCenter>{t('offline.pageServerError')}</AlignCenter>
          </FormattedText>
          <BackAction>
            <FontAwesomeIcon
              icon={faArrowLeft}
              size={'1x'}
              onClick={() => dispatch(navigateBack())}
            />
            <AlignCenter>{t('generic.previous')}</AlignCenter>
          </BackAction>
        </WrapperText>
      </Container>
    </Wrapper>
  );
};

const FormattedText: React.FC<{
  size?: string;
  color?: string;
  weight?: string;
  onClick?: () => void;
}> = ({ children, size, onClick, weight, color }) => (
  <div
    onClick={() => {
      onClick && onClick();
    }}
  >
    <Text color={'plusDarkGrey'} size={size ? size : 'large'} weight={weight}>
      {children}
    </Text>
  </div>
);

export default PageServerError;

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 0 26%;

  ${({ theme }) => theme.medias.sm} {
    padding: 0 20%;
  }

  ${({ theme }) => theme.medias.xs} {
    padding: 0 80px;
  }
`;

const BackAction = styled.div`
  display: flex;
  align-items: center;
  gap: 1.25rem;
  justify-content: center;
  padding: 1.25rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const LogoUA = styled.img`
  width: 120px;
  height: 120px;
  margin: 120px 0 80px;
`;

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

const WrapperText = styled.div`
  display: flex;
  flex-direction: column;

  > div:last-child {
    margin-bottom: 48px;
  }
`;
