/**
 * NewCertificate scene utils
 *
 * @author Diogo Gugedes <dguedes@ubiwhere.com>
 *
 */

import { IFormPostValues } from './types';

const getRequestedCertificateData = (requestCertificateData, formData, radioBtnMappings) => {
  let formRequestData = {} as {
    exemption: string;
    exemptionMotive: string;
    exemptionMotiveOther?: string;
    schoolYear?: string;
    ucs_codes?: any;
  };
  if (requestCertificateData) {
    requestCertificateData.forEach((element) => {
      if (formData[element.nameKey] !== null && formData[element.nameKey] !== undefined) {
        if (element.type === 'radio_button') {
          if (formData[element.nameKey] !== undefined && formData[element.nameKey] !== null) {
            const selectedOption = formData[element.nameKey];

            const nameKey = element.nameKey;

            formRequestData[element.nameKey] = radioBtnMappings[nameKey][formData[nameKey]];
          }
        } else if (element.type === 'dropdown') {
          const selectedOption = formData[element.nameKey];

          element.values.forEach((elem) => {
            if (elem.valueId === selectedOption) {
              formRequestData[element.nameKey] = elem.valueId;
              return;
            }
          });
        } else if (element.type === 'checkbox_table') {
          let selectedUcCodes = [] as any[];

          formData[element.nameKey].forEach((formTableCheckbox) => {
            element.values.forEach((ucRow) => {
              if (ucRow.valueId === formTableCheckbox) {
                selectedUcCodes.push({
                  ucCode: ucRow.nameKey.ucCode,
                  isAvailable:
                    ucRow.nameKey.isAvailable !== null ? ucRow.nameKey.isAvailable : true,
                });
              }
            });
          });

          formRequestData['ucs_codes'] = selectedUcCodes;
        } else {
          formRequestData[element.nameKey] = formData[element.nameKey];
        }
      }
    });

    return formRequestData;
  } else {
    return undefined;
  }
};

const getDetailsData = (detailsData, formData, radioBtnMappings) => {
  let formRequestData = {} as {
    language: string;
    format: string;
    pageCounter?: number;
    deliveryMethod?: string;
    email?: string;
    address?: string;
  };

  detailsData.forEach((element) => {
    if (element.type === 'radio_button') {
      if (formData[element.nameKey] !== undefined && formData[element.nameKey] !== null) {
        const selectedOption = formData[element.nameKey];

        // const nameKey = element.values[selectedOption].nameKey;
        const valueId = element.values[selectedOption].valueId;
        // formRequestData[element.nameKey] = nameKey;

        formRequestData[element.nameKey] = radioBtnMappings[element.nameKey]
          ? radioBtnMappings[element.nameKey][valueId]
          : -1;
      }
    } else if (element.type === 'dropdown') {
      if (formData[element.nameKey] !== undefined && formData[element.nameKey] !== null) {
        const selectedOption = formData[element.nameKey];

        formRequestData[element.nameKey] = element.values[selectedOption].nameKey;
      }
    } else {
      formRequestData[element.nameKey] = formData[element.nameKey];
    }
  });

  return formRequestData;
};

const getAdditionalDocumentsData = (documentsData, formData) => {
  let formRequestData = { documents: [] } as {
    documents: {
      name: string;
      id: string;
    }[];
  };

  Object.keys(formData).forEach((elemNamekey) => {
    if (
      elemNamekey.includes('otherDocument') &&
      formData[elemNamekey] !== null &&
      formData[elemNamekey] !== undefined
    ) {
      formRequestData.documents.push({
        name: elemNamekey,
        id: formData[elemNamekey].id,
      });
    }
  });

  return formRequestData;
};

/**
 * Returns form data for posting to the form submission endpoint
 * @param form slice certificateData.form
 * @param formValues slice certificateData.formValues
 * @param radioBtnMappings
 */
export const getFinalResults = (form, formValues, radioBtnMappings): IFormPostValues | null => {
  const { requestedCertificate, details, /*mandatoryDocuments,*/ additionalDocuments } = form;

  const result = {
    ...getRequestedCertificateData(requestedCertificate.values, formValues, radioBtnMappings),
    ...getDetailsData(details.values, formValues, radioBtnMappings),
    ...getAdditionalDocumentsData(additionalDocuments.values, formValues),
  } as IFormPostValues;

  if (formValues) {
    return result;
  } else {
    return null;
  }
};
