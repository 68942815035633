/**
 * SelectDetails container
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from '../../logic/selectors';
import { actions } from 'store/rootSlices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { Grid } from 'semantic-ui-react';
import { Text, Segment, Checkbox } from '@paco_ua/pacoui';

import SelectedPayments from '../../components/SelectedPayments';

const SelectDetails = ({ preSelectedDebits, multiPaymentsComponent }) => {
  const theme = useContext(ThemeContext);

  const dispatch = useDispatch();

  const { showMorePayments } = useSelector(selectors.getPendingDebitsPaymentSlice);
  const { setSelectedDebits } = actions.Payments;

  const { setShowMorePayments } = actions.PendingDebitsPayment;

  return (
    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={16} widescreen={16}>
      {preSelectedDebits.length > 0 && (
        <>
          <SegmentTitleWrapper>
            <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
              {t('payments.performedSelection')}
            </Text>
          </SegmentTitleWrapper>

          <SelectedPayments selectedDebits={preSelectedDebits} />

          {/* <WrapperOtherPayments>
            <Title>
              <Text size="medium" weight="regular" color="plusDarkGrey">
                {t('paymentTool.multipaymentTitle')}
              </Text>
            </Title>
            <Description>
              <Text size="article" weight="regular" color="darkGrey">
                {t('paymentTool.multipaymentDescription')}
              </Text>
            </Description>
            <Segment padding="0">
              <SegmentCheckbox checked={showMorePayments}>
                <WrapperColumn>
                  <Text
                    size="article"
                    weight="bold"
                    color={showMorePayments ? 'white' : 'plusDarkGrey'}
                  >
                    {t('paymentTool.checkboxMultipaymentTitle')}
                  </Text>
                  <CheckboxMultiPayText
                    size="xSmall"
                    weight="regular"
                    color={showMorePayments ? 'white' : 'plusDarkGrey'}
                  >
                    {t('paymentTool.checkboxMultipaymentDesciption')}
                  </CheckboxMultiPayText>
                </WrapperColumn>
                <Checkbox
                  checked={showMorePayments}
                  onChange={() => {
                    dispatch(setShowMorePayments(!showMorePayments));

                    if (showMorePayments) {
                      dispatch(setSelectedDebits(preSelectedDebits)); // reset selectedDebits to preSelectedDebits
                    }
                  }}
                />
              </SegmentCheckbox>
            </Segment>

            {showMorePayments && multiPaymentsComponent && (
              <WrapperMultiDebts>
                <Segment padding="32px 16px">{multiPaymentsComponent}</Segment>
              </WrapperMultiDebts>
            )}
          </WrapperOtherPayments> */}
        </>
      )}

      {preSelectedDebits.length === 0 && (
        <Segment>
          <MessageNoApplications>
            <Icon>
              <FontAwesomeIcon icon={faInfoCircle} />
            </Icon>
            <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
              {t('payments.noDebitsSelect', { textOnly: true })}
            </Text>
          </MessageNoApplications>
        </Segment>
      )}
    </Grid.Column>
  );
};

export default SelectDetails;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const MessageNoApplications = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;

const WrapperColumn = styled.div<{ marginTop?: string; maxWidth?: string }>`
  display: flex;
  flex-direction: column;

  > div:last-child {
    margin-top: ${({ marginTop }) => (marginTop ? marginTop : '8px')};
  }
`;

const SegmentCheckbox = styled.div<{ checked?: boolean }>`
  padding: 18px 48px 18px 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: ${({ theme, checked }) => (checked ? theme.colors.primary : 'transparent')};

  > div:first-child {
    flex: 1;
  }
`;

const CheckboxMultiPayText = styled(Text)`
  margin-top: 8px;
`;

const Title = styled.div``;

const Description = styled.div``;

const WrapperOtherPayments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  line-height: 1.3;

  ${Title} {
    margin-bottom: 16px;
  }

  ${Description} {
    margin-bottom: 16px;
  }
`;

const WrapperMultiDebts = styled.div`
  margin-top: 24px;
`;
