/**
 * ImprovementExamEnrollment scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IImprovementExamsSignupData } from '../types';

const initialState = {
  loading: true as boolean,
  enrollImprovementExamId: null as null | number, // inscrever ou editar
  requestImprovementExamId: null as null | number, // solicitar
  signups: null as null | IImprovementExamsSignupData,
};

export default createSlice({
  name: 'ImprovementExamEnrollment',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    getStudentSignups: (state, action: PayloadAction<any>) => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setData: (state, action: PayloadAction<IImprovementExamsSignupData>) => {
      state.signups = action.payload;
    },
    setEnrollImprovementExamId: (state, action: PayloadAction<number | null>) => {
      state.enrollImprovementExamId = action.payload;
    },
    setRequestImprovementExamId: (state, action: PayloadAction<number | null>) => {
      state.requestImprovementExamId = action.payload;
    },
    reset: (state) => initialState,
  },
});
