/**
 * PersonalData scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAltEdit, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import PersonalDataBlock from './containers/PersonalDataBlock';

import { CallToAction, Text, Segment } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

const PersonalData = ({ t }) => {
  const { onMount, onUnmount, setHasRegistrationId } = actions.PersonalData;

  const { navigateTo } = actions.App;

  const { personalDataBlocks, loadingStatus, errorStatus, studentCardBlock } = useSelector(
    selectors.getPersonalDataSlice
  );
  console.log('Personal');
  
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);

  const studendId = useSelector(selectors.getUser);

  const footnoteDataPerBlock = {
    profile: {
      title: 'profileFootnoteTitle',
      text: 'profileFootnoteText',
    },
    parents: {
      title: 'parentsFootnoteTitle',
      text: 'parentsFootnoteText',
    },
  };

  const studentCardNotice = t('personalData.studentCardInfo');
  const blocksWithEditButton = ['contacts', 'address'];

  const checkIfHasRegistrationId = () => {
    const profileData = personalDataBlocks['profile'];

    if (profileData && profileData.values) {
      const registrationIdObject = profileData.values.find(
        (item) => item.nameKey === 'registration_id'
      );

      if (registrationIdObject.value !== '') {
        const registrationId = registrationIdObject.value;

        dispatch(
          navigateTo({
            key: 'newapplication',
            state: {
              status: 'changePersonalData',
              registrationId: registrationId,
            },
          })
        );
      } else {
        dispatch(setHasRegistrationId(false));
      }
    }
  };

  useEffect(() => {
    dispatch(onMount());

    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, studendId.impersonate]);

  return (
    <PageStructure
      loading={loadingStatus.loadingPage || errorStatus.errLoadingPersonalData}
      headerLeft={<Header title={t('routes.personalData')} />}
      mainContent={
        <>
          {/*!loadingStatus.loadingPage && errorStatus.errLoadingPersonalData && (
            <MessageNoCertificates>
              <Icon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Icon>
              <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                {t('personalData.errorLoadingPersonalData', { textOnly: true })}
              </Text>
            </MessageNoCertificates>
          )*/}

          {!loadingStatus.loadingPage &&
            !errorStatus.errLoadingPersonalData &&
            personalDataBlocks['profile'] &&
            Object.keys(personalDataBlocks).map((field, fieldIndex) => {
              return (
                <PersonalDataBlockWrapper key={`${fieldIndex}${field}_wrapper`}>
                  <Row paddingbottom="0px" paddingtop="20px">
                    <Segment>
                      <PersonalDataBlock
                        key={fieldIndex.toString() + field}
                        blockKey={field}
                        title={t(`personalData.${personalDataBlocks[field].nameKey}`, {
                          textOnly: true,
                        })}
                        subtitle={t(`personalData.${personalDataBlocks[field].descriptionKey}`, {
                          textOnly: true,
                        })}
                        picture={personalDataBlocks[field].picture}
                        showPicture={personalDataBlocks[field].showPicture}
                        lastUpdated={
                          personalDataBlocks[field].lastUpdated === '01-01-1900' ||
                          personalDataBlocks[field].lastUpdated === '01/01/1900'
                            ? ''
                            : t('personalData.lastUpdated', { textOnly: true }) +
                              ': ' +
                              personalDataBlocks[field].lastUpdated
                        }
                        showEditBlockButton={
                          blocksWithEditButton.indexOf(personalDataBlocks[field].nameKey) > -1
                        }
                        formDataList={personalDataBlocks[field].values}
                        editing={personalDataBlocks[field].editing}
                        loading={
                          personalDataBlocks[field].nameKey === 'address'
                            ? loadingStatus.loadingAddress
                            : personalDataBlocks[field].nameKey === 'contacts'
                            ? loadingStatus.loadingContacts
                            : undefined
                        }
                      />
                    </Segment>
                  </Row>

                  <Row paddingbottom="20px" paddingtop="0px">
                    {personalDataBlocks[field].nameKey in footnoteDataPerBlock && (
                      <CallToAction
                        padding={'14px 38px'}
                        footerIcon={
                          <FontAwesomeIcon
                            color={theme.colors.darkGrey}
                            size="2x"
                            icon={faCommentAltEdit}
                          />
                        }
                        footerArrow
                        footer={
                          <PageBottom>
                            <Text weight="bold" color="darkGrey" size="small">
                              {t(
                                'personalData.' +
                                  footnoteDataPerBlock[personalDataBlocks[field].nameKey].title
                              )}
                            </Text>
                            <Text fontWeight="400" color="darkGrey" size="small">
                              {t(
                                'personalData.' +
                                  footnoteDataPerBlock[personalDataBlocks[field].nameKey].text
                              )}
                            </Text>
                          </PageBottom>
                        }
                        onAction={() => {
                          checkIfHasRegistrationId();
                        }}
                      />
                    )}
                  </Row>
                </PersonalDataBlockWrapper>
              );
            })}

          {!loadingStatus.loadingPage &&
            !errorStatus.errLoadingPersonalData &&
            personalDataBlocks['profile'] && (
              <Row paddingbottom="24px" paddingtop="12px">
                <CardBlockTitleWrapper>
                  <Text color="plusDarkGrey" size="xLarge" fontWeight="500">
                    {t('personalData.cardInfo')}
                  </Text>
                </CardBlockTitleWrapper>
              </Row>
            )}

          {!loadingStatus.loadingPage &&
            !errorStatus.errLoadingPersonalData &&
            !errorStatus.errLoadingCardData &&
            personalDataBlocks['profile'] && (
              <Row paddingbottom="16px" paddingtop="0px">
                <CallToAction
                  topBorder
                  footer={
                    <Text color="darkGrey" size="article">
                      {studentCardNotice}
                    </Text>
                  }
                  padding={'28px 38px'}
                  noPointer
                />
              </Row>
            )}

          {!loadingStatus.loadingPage &&
            !errorStatus.errLoadingPersonalData &&
            !errorStatus.errLoadingCardData &&
            personalDataBlocks['profile'] && (
              <Row paddingbottom="32px" paddingtop="0px">
                <CardBlockWrapper>
                  {Object.keys(studentCardBlock).map((key) => (
                    <CardColumn key={key}>
                      <TextFieldTitle>
                        <Text color="grey" size="xSmall" fontWeight="400">
                          {t(`personalData.${key}`)}
                        </Text>
                      </TextFieldTitle>

                      {(studentCardBlock[key] === '' || studentCardBlock[key] === null) && (
                        <FontAwesomeIcon icon={faEllipsisH} />
                      )}

                      {studentCardBlock[key] !== '' &&
                        studentCardBlock[key] !== null &&
                        key === 'reportDate' && (
                          <Text fontWeight="400" color="blackLight" size="mediumSmall">
                            {moment(studentCardBlock[key]).format('YYYY-MM-DD')}
                          </Text>
                        )}
                      <CardInfoField>
                        {studentCardBlock[key] !== '' &&
                          studentCardBlock[key] !== null &&
                          key !== 'reportDate' && (
                            <Text fontWeight="400" color="blackLight" size="mediumSmall">
                              {studentCardBlock[key]}
                            </Text>
                          )}
                      </CardInfoField>
                    </CardColumn>
                  ))}
                </CardBlockWrapper>
              </Row>
            )}

          {!loadingStatus.loadingPage &&
            !errorStatus.errLoadingPersonalData &&
            errorStatus.errLoadingCardData && (
              <MessageError>
                <Icon>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Icon>
                <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                  {t('personalData.errorLoadingCardData', { textOnly: true })}
                </Text>
              </MessageError>
            )}
        </>
      }
    />
  );
};

export default PersonalData;

const Row = styled.div<{ paddingtop?: string; paddingbottom?: string }>`
  padding-top: ${({ paddingtop }) => (paddingtop ? paddingtop : '1rem')};
  padding-bottom: ${({ paddingbottom }) => (paddingbottom ? paddingbottom : '1rem')};
`;

const CardBlockWrapper = styled.div`
  border-style: solid;
  border-width: thin;
  border-color: ${({ theme }) => theme.colors.softLightGrey};
  padding: 16px 38px;
  display: flex;
  justify-content: space-between;
`;

const CardColumn = styled.div``;

const CardBlockTitleWrapper = styled.div`
  display: flex;
  justify-content: left;
`;

const TextFieldTitle = styled.div`
  margin: 0px 0px 12px 0px;
`;

const PageBottom = styled.div`
  display: flex;
  flex-direction: column;

  div:first-child {
    margin-bottom: 8px;
  }
`;

const PersonalDataBlockWrapper = styled.div``;

const CardInfoField = styled.div`
  max-width: 220px;
  white-space: normal;
  overflow: hidden;
`;

const MessageError = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.primary};
`;
