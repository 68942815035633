/**
 * EvaluationTypeSelection scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import styled from 'styled-components';

import { actions } from 'store/rootSlices';
import { CallToAction, Text } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

const EvaluationTypeSelection = ({ t }) => {
  const { onMount, onUnmount } = actions.EvaluationTypeSelection;

  const dispatch = useDispatch();
  const { loading } = useSelector(selectors.getEvaluationTypeSelectionSlice);
  const user = useSelector(selectors.getUser);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount]);

  return (
    // <PageStructure
    //   loading={loading}
    //   headerLeft={<Header title={t('enrollments.evaluationTypeSelection')} />}
    //   mainContent={<>Evaluation Type Selection</>}
    //   secondaryContentOffset={113}
    //   secondaryContent={<>Secondary content</>}
    // />
    <Wrapper>
      <CallToAction
        topBorder       
        footer={
          <Text color="darkGrey" size="article">
            {t('generic.iframeBanner')}
          </Text>
        }
        padding={'15px 38px'}
        noPointer
      />
    <iframe src={"https://paco.ua.pt/PACO2/secvirtual/aulas/disciplinas_em_modo_avaliacaoV4.asp?token=" + user.idToken + "&impe=" + user.impersonate} width="100%" height="800px" frameBorder="0" ></iframe>
 </Wrapper>
  );
};

const Wrapper = styled.div`
  text-align: center;
`;
export default EvaluationTypeSelection;
