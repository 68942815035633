/**
 * FormTextArea component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';

import styled from 'styled-components';

import { TextArea as SemanticTextArea } from 'semantic-ui-react';

import { Text } from '@paco_ua/pacoui';

interface IFormTextArea {
  register: any;
  onChange: (data: any) => void;
  error?: any;
  success?: any;
  name: string;
  value: string;
  placeholder: string;
  defaultValue: string;
}

const FormTextArea: React.FC<IFormTextArea> = ({
  placeholder,
  name,
  error,
  onChange,
  defaultValue,
}) => {
  return (
    <Wrapper>
      <StyledTextArea
        placeholder={placeholder}
        onChange={(e, data) => onChange(data.value)}
        name={name}
        defaultValue={defaultValue}
      />
      <MessageArea>
        {error && (
          <Text size="xSmall" weight="medium" color="dangerRed">
            {error}
          </Text>
        )}
      </MessageArea>
    </Wrapper>
  );
};
export default FormTextArea;

const Wrapper = styled.div`
  && {
    textarea {
      min-height: 80px;
      max-height: 300px;
      width: 100%;
      padding: 12px;
      border: 1px solid ${({ theme }) => theme.colors.softRegularGrey};
      border-radius: 0;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.plusDarkGrey};
      resize: vertical;
    }

    textarea::placeholder {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.darkGrey};
    }

    textarea:focus {
      outline: none;
    }
  }
`;

const StyledTextArea = styled(SemanticTextArea)``;

const MessageArea = styled.div``;
