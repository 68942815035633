/**
 * CertificatesList scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Grid } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'store/rootSlices';

import { AnimatedBackground, MultiToggle, Text, SearchBox, Dropdown } from '@paco_ua/pacoui';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import Certificate from './components/Certificate';
import * as selectors from './logic/selectors';

const CertificatesList = ({ t }) => {
  const {
    loadingStatus,
    errorStatus,
    certificatesList,
    certificatesFilters,
    requestParams,
  } = useSelector(selectors.getCertificatesListSlice);

  const currentRegistrationId = useSelector(selectors.getRegistration);

  const theme = useContext(ThemeContext);
  const { onMount, onUnmount, getData } = actions.CertificatesList;
  const user = useSelector(selectors.getUser);

  const dispatch = useDispatch();

  const currentLocale = useSelector(selectors.getAppSlice);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate, currentLocale]);

  useEffect(() => {
    if (currentRegistrationId) {
      dispatch(getData());
    }
  }, [currentRegistrationId, currentLocale]);

  return (
    <PageStructure
      loading={loadingStatus.loading || errorStatus.errLoading}
      headerLeft={<Header title={t('routes.certificatesList')} />}
      subHeader={
        <Text color="darkGrey" size="article" weight={'regular'}>
          {t('certificates.listPageSubHeader1', {
            textOnly: true,
          })}
          &nbsp;
          <Text
            as="a"
            href={t('links.registrationsSGA', { textOnly: true })}
            target="_blank"
            weight={'regular'}
            color={theme.colors.primary}
          >
            {t('certificates.listPageSubHeader2', {
              textOnly: true,
            })}
            &nbsp;
          </Text>
        </Text>
      }
      mainContent={
        <>
          <StudentRegistrationDropdownWrapper>
            <StudentRegistrationDropdown />
          </StudentRegistrationDropdownWrapper>

          {!loadingStatus.loading && !errorStatus.errLoadingCertificateList && (
            <SearchAndFiltersWrapper>
              <SearchBox
                placeholder={t('forms.searchPlaceholder', { textOnly: true })}
                borderColor={theme.colors.plusDarkGrey}
                iconColor={theme.colors.plusDarkGrey}
                delay={500}
                onSearch={(value) => {
                  dispatch(getData({ search: value }));
                }}
              />
              <Dropdown
                placeholder={t('certificates.listDropdownFilterPlaceholder', {
                  textOnly: true,
                })}
                canBeCleared
                options={certificatesFilters}
                selectionWeight={'400'}
                selectionFontSize={'medium'}
                onChange={(e, { value }) => {
                  dispatch(getData({ type: value }));
                }}
                onClear={() => {
                  dispatch(getData({ type: '' }));
                }}
              />
            </SearchAndFiltersWrapper>
          )}

          {!loadingStatus.loading && !errorStatus.errLoadingCertificateList && <Separator />}

          {!loadingStatus.loading && !errorStatus.errLoadingCertificateList && (
            <MultiToggleWrapper>
              <MultiToggle
                buttons={[
                  {
                    onClick: () => {
                      dispatch(getData({ state: 'ready' }));
                    },
                    children: t('certificates.toggleEmitted', { textOnly: true }),
                    selected: requestParams.state === 'ready',
                    testId: 'certificates_list_multitoggle_type_emmitedCertificates',
                  },
                  {
                    onClick: () => {
                      dispatch(getData({ state: 'pending' }));
                    },
                    children: t('certificates.toggleProcessing', { textOnly: true }),
                    selected: requestParams.state === 'pending',
                    testId: 'certificates_list_multitoggle_type_inProcessing',
                  },
                  {
                    onClick: () => {
                      dispatch(getData({ state: 'awaitingPayment' }));
                    },
                    children: t('certificates.toggleAwaitsPayment', { textOnly: true }),
                    selected: requestParams.state === 'awaitingPayment',
                    testId: 'certificates_list_multitoggle_type_awaitsPayment',
                  },
                  {
                    onClick: () => {
                      dispatch(getData({ state: 'rejected' }));
                    },
                    children: t('certificates.toggleRejected', { textOnly: true }),
                    selected: requestParams.state === 'rejected',
                    testId: 'certificates_list_multitoggle_type_rejected',
                  },
                  {
                    onClick: () => {
                      dispatch(getData({ state: 'expired' }));
                    },
                    children: t('certificates.toggleExpired', { textOnly: true }),
                    selected: requestParams.state === 'expired',
                    testId: 'certificates_list_multitoggle_type_expired',
                  },
                ]}
                content={'buttons'}
              />
            </MultiToggleWrapper>
          )}

          {errorStatus.errLoadingCertificateList ||
            (!loadingStatus.loading && loadingStatus.loadingCertificatesList && (
              <LoadingWrapper>
                <AnimatedBackground height={theme.sizes.large} width="20%" />
                <AnimatedBackground height={theme.sizes.large} width="50%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="80%" />
                <AnimatedBackground height={theme.sizes.large} width="50%" />
              </LoadingWrapper>
            ))}

          {!loadingStatus.loadingCertificatesList &&
            !errorStatus.errLoadingCertificateList &&
            certificatesList &&
            certificatesList.length > 0 && (
              <CertificatesWrapper columns={3} doubling stackable>
                {certificatesList.map((certificate) => {
                  return (
                    <CertificateWrapper
                      key={`certificates_list_certificate_card_${certificate.id}`}
                    >
                      <Certificate
                        type={certificate.type}
                        language={certificate.language}
                        id={certificate.id}
                        state={certificate.state}
                        format={certificate.format}
                        registrationId={certificate.registrationId}
                        requestDate={certificate.requestDate}
                        responseDate={certificate.responseDate}
                        ready={certificate.ready}
                        isExempt={certificate.isExempt}
                        exemptionMotive={certificate.exemptionMotive}
                        accessKey={certificate.accessKey}
                        expirationDate={certificate.expirationDate}
                        paymentState={certificate.paymentState}
                      />
                    </CertificateWrapper>
                  );
                })}
              </CertificatesWrapper>
            )}

          {!loadingStatus.loadingCertificatesList &&
            !errorStatus.errLoadingCertificateList &&
            certificatesList.length === 0 && (
              <MessageNoCertificates>
                <Icon>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Icon>
                <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                  {t('certificates.noCertificateListFound', { textOnly: true })}
                </Text>
              </MessageNoCertificates>
            )}
        </>
      }
    />
  );
};

export default CertificatesList;

const StudentRegistrationDropdownWrapper = styled.div`
  margin-bottom: 32px;
`;

const SearchAndFiltersWrapper = styled.div`
  width: 75%;
  display: flex;
  margin-bottom: 24px;

  > div {
    width: 50%;
  }

  > div:last-child {
    margin-left: 16px;
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.colors.softRegularGrey};
  margin-top: 16px;
  margin-bottom: 24px;
`;

const MultiToggleWrapper = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  overflow-y: hidden;
  overflow-x: auto;
`;

const CertificatesWrapper = styled(Grid)`
  &&& {
    margin-top: 24px;
  }
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  margin-top: 32px;

  * {
    margin: 8px 0px;
  }
`;

const CertificateWrapper = styled(Grid.Column)``;

const MessageNoCertificates = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;
