/**
 * FreeOptionsDropdown container slice
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 * @format
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/*interface IFreeOptionsDropdown {
  id: number;
  name: string;
  items: any[];
  optionId: number;
  selected: boolean;
  open: boolean;
}*/

type IFreeOptionsDropdown = any;

const initialState = {
  options: [] as IFreeOptionsDropdown[],
  confirmationModalIsOpen: false,
  freeOptionsSearchModalIsOpen: { modalOpen: false, option: null } as {
    modalOpen: boolean;
    option: any | null;
  },
  optionIndexToRemove: null as null | number[],
  optionToRemove: null as null | any,
  ucIdToRemove: null as null | number,
};

export default createSlice({
  name: 'FreeOptionsDropdown',
  initialState,
  reducers: {
    setOptions: (state, action: PayloadAction<IFreeOptionsDropdown[]>) => {
      state.options = action.payload;
    },
    setFreeOptions: (
      state,
      action: PayloadAction<{ ucs: any[]; options: { name: string; id: number }[] }>
    ) => {},
    setConfirmationModalOpening: (state, action: PayloadAction<boolean>) => {
      state.confirmationModalIsOpen = action.payload;
    },
    setFreeOptionsSearchModalOpening: (
      state,
      action: PayloadAction<{ modalOpen: boolean; option: object | null }>
    ) => {
      state.freeOptionsSearchModalIsOpen = action.payload;
    },
    // hold option index to remove while waiting for user confirmation
    setOptionIndexToRemove: (state, action: PayloadAction<number[] | null>) => {
      state.optionIndexToRemove = action.payload;
    },
    // hold item index to remove while waiting for user confirmation
    setOptionToRemove: (state, action: PayloadAction<any>) => {
      state.optionToRemove = action.payload;
    },
    // hold UC id to remove while waiting for user confirmation
    setUcIdToRemove: (state, action: PayloadAction<number | null>) => {
      state.ucIdToRemove = action.payload;
    },
  },
});
