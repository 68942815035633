/**
 * Translation Container
 *
 * @author Hugo Fonseca <hfonseca@ubiwhere.com>
 * @author Carlos Silva <csilva@ubiwhere.com>
 */
import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

import { actions } from 'store/rootSlices';

import { Text } from '@paco_ua/pacoui';

import HelpMessage from './message';
import * as selectors from '../logic/selectors';

interface IProps extends WithTranslation {
  tKey: string;
  options?: any;
}

const Translation: React.FC<IProps> = ({ t, i18n, tKey, options }) => {
  const translationsEdit = useSelector(selectors.getTranslationsEdit);

  const { updateApiTranslation } = actions.App;

  const dispatch = useDispatch();

  return (
    <Wrapper>
      <Trans
        i18nKey={tKey}
        values={options}
        defaults={tKey}
        components={{
          Link: <Link />,
          link: <Link />,
          a: <Link />,
          strong: <strong />,
          b: <strong />,
          //p: <p />,
        }}
      />

      {translationsEdit && (
        <HelpMessage
          i18n={i18n}
          onUpdateKeys={(name: string, form: object) =>
            dispatch(updateApiTranslation({ name, form }))
          }
          transKey={tKey}
        />
      )}
    </Wrapper>
  );
};

export default withTranslation()(Translation);

const Wrapper = styled.span`
  position: relative;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.primary} !important;
`;
