/**
 * PendingDebitsPayment selector
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { RootState } from 'store/rootSlices';

export const getPendingDebitsPaymentSlice = (state: RootState) => state.PendingDebitsPayment;
export const getPaymentsSlice = (state: RootState) => state.Payments;
export const getRegistrationId = (state: RootState) =>
  state.StudentRegistrationDropdown.registration;
export const getAppSlice = (state: RootState) => state.App;
export const getUser = (state: RootState) => state.App.user;
