/**
 * NewApplicationSelectRegistration container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import { Grid } from 'semantic-ui-react';
import { Text, Segment, Table, Tooltip, StatusLabel } from '@paco_ua/pacoui';

//import config from 'config'

const NewApplicationSelectRegistration = () => {
  const theme = useContext(ThemeContext);

  const { setApplicationData, setActiveRegistration } = actions.NewApplication;

  const registrations = useSelector(selectors.getRegistrations);

  const newApplicationData = useSelector(selectors.getNewApplicationData);
  const dispatch = useDispatch();

  //to populate active registration input
  useEffect(() => {
    dispatch(setActiveRegistration());
  }, [registrations]);

  const getEligibilityColor = (status: number) => {
    if (status === 100) {
      return theme.colors.dangerRed;
    } else if (status === 1 || status === 2) {
      return theme.colors.successGreen;
    } else if (status === 25 || status === 50) {
      return theme.colors.decisionYellow;
    } else if (status === -1) {
      return theme.colors.grey;
    }
  };

  const structure = {
    header: {
      type: 'columnTitle',
      titles: [],
    },
    rows:
      !registrations || !registrations.length
        ? []
        : registrations
            //.filter((registration) => registration.registrationState === 'ACTIVO')
            .map((registration) => {
              return {
                rowProps: {
                  color: theme.colors.white,
                  navigatable: true,
                  onClick: () => {
                    dispatch(
                      setApplicationData({
                        ...newApplicationData,
                        registrationId: registration.registrationId,
                      })
                    );
                  },
                },
                cells: [
                  {
                    content: (
                      <TableRowWrapper>
                        <Input
                          type="radio"
                          onChange={() =>
                            dispatch(
                              setApplicationData({
                                ...newApplicationData,
                                registrationId: registration.registrationId,
                              })
                            )
                          }
                          checked={
                            newApplicationData.registrationId === registration.registrationId
                          }
                        />
                        <TableRowInfo>
                          <Text size="article" color={theme.colors.primary} weight={'medium'}>
                            {registration.courseCode && registration.courseName ? (
                              `${registration.courseCode} - ${registration.courseName}`
                            ) : (
                              <FontAwesomeIcon icon={faEllipsisH} />
                            )}
                          </Text>
                          <Text size="article" color={theme.colors.primary} weight={'regular'}>
                            {`\n${registration.branchName}\n`}
                          </Text>
                          <span>
                            {registration.eligibilityState ||
                            registration.registrationState === 'activo' ? (
                              <StatusLabel
                                label={`${t('enrollmentState.' + registration.registrationState, {
                                  textOnly: true,
                                })}`}
                                background={theme.colors.successGreen}
                                hideTooltip
                              ></StatusLabel>
                            ) : registration.registrationState === 'comdiploma' ||
                              registration.registrationState === 'terminado' ? (
                              <StatusLabel
                                label={`${t('enrollmentState.' + registration.registrationState, {
                                  textOnly: true,
                                })}`}
                                background={theme.colors.softgrey}
                                hideTooltip
                              ></StatusLabel>
                            ) : (
                              <StatusLabel
                                label={`${t('enrollmentState.' + registration.registrationState, {
                                  textOnly: true,
                                })}`}
                                background={theme.colors.dangerRed}
                                hideTooltip
                              ></StatusLabel>
                            )}
                            <Text size="article" color={theme.colors.primary} weight={'regular'}>
                              {`${registration.academicYear} `}
                            </Text>
                            <Text size="small" color={theme.colors.primary} weight={'regular'}>
                              {`${t('applications.requestFirstStepTableRegistrationDate', {
                                textOnly: true,
                              })}: ${
                                registration.registrationDate
                                  ? moment(registration.registrationDate,'DD/MM/YYYY').format('DD/MM/YYYY')
                                  : null
                              }
                        `}
                            </Text>
                          </span>
                        </TableRowInfo>
                      </TableRowWrapper>
                    ),
                    cellProps: {
                      width: 12,
                      style: {
                        height: 'auto',
                      },
                    },
                  },
                ],
              };
            }),
  };

  return (
    <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={14} widescreen={12}>
      <Segment>
        <SegmentTitleWrapper>
          <Text size="large" color={theme.colors.plusDarkGrey} weight={'regular'}>
            {t('applications.requestFirstStepTitle')}
          </Text>
          <SegmentDescriptionWrapper
            size="article"
            color={theme.colors.darkGrey}
            weight={'regular'}
          >
            {t('applications.requestFirstStepDescription')}
          </SegmentDescriptionWrapper>
        </SegmentTitleWrapper>
        <TableWrapper>
          <Table structure={structure} unstackable />
        </TableWrapper>
      </Segment>
    </Grid.Column>
  );
};

export default NewApplicationSelectRegistration;

const TableWrapper = styled.div`
  margin-top: 32px;
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }
  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;

const SegmentTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SegmentDescriptionWrapper = styled(Text)`
  margin-top: 8px;
`;

const TableRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1.25rem;
  width: 100%;
  height: 100%;
`;

const TableRowInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: fit-content;
  white-space: break-space;
  gap: 0.375rem;
  padding: 0.5rem 0rem;

  span {
    display: inline-flex;
    align-items: center;
    gap: 0.625rem;

    >div: first-child {
      max-width: unset;
    }
  }

  & div {
    :hover {
      cursor: pointer;
    }
  }
`;

const Input = styled.input`
  /* hide default inner circle */
  appearance: none;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.regularGrey};
  border-radius: 50%;
  outline: none;

  :hover {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    opacity: 1;
  }

  /* inner circle */
  :before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: middle;
  }

  :checked:before {
    background: ${({ theme }) => theme.colors.primary};
  }

  :checked {
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }
`;
