/**
 * CurricularUnitDetails scene
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCurrentRoute } from 'utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { Text, Segment, Table, Button, StatusLabel } from '@paco_ua/pacoui';

import { useDispatch, useSelector } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { Theme as theme } from '@paco_ua/pacoui';

import numeral from 'numeral';

import API from 'api';

import config from 'config';

const CurricularUnitDetails = ({ t }) => {
  const { onMount, onUnmount, getUcDetails, setSelectedUcCode } = actions.CurricularUnitDetails;
  const dispatch = useDispatch();

  const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

  const { loading, errorStatus, ucDetails } = useSelector(selectors.getCurricularUnitDetailsSlice);

  const [loadingModels, setLoadingModels] = useState({});

  const SUMMARY_COLUMN_TITLES = [
    t('curricularUnitDetails.yearOfStudy', { textOnly: true }),
    t('curricularUnitDetails.academicYear', { textOnly: true }),
    t('curricularUnitDetails.semester', { textOnly: true }),
    t('curricularUnitDetails.evaluationType', { textOnly: true }),
    t('curricularUnitDetails.evaluationStatus', { textOnly: true }),
  ];

  const HISTORY_COLUMN_TITLES = [
    t('curricularUnitDetails.yearOfStudy', { textOnly: true }),
    /* t('curricularUnitDetails.semester', { textOnly: true }), hide column rows temporarly */
    t('curricularUnitDetails.gradeMethod', { textOnly: true }),
    t('curricularUnitDetails.gradeDate', { textOnly: true }),
    t('curricularUnitDetails.obtainedGrade', { textOnly: true }),
    t('curricularUnitDetails.gradeSheet', { textOnly: true }),
  ];

  const EVALUATION_METHOD = {
    er: t('curricularUnitDetails.erasmus', { textOnly: true }),
    eq: t('curricularUnitDetails.equivalence', { textOnly: true }),
    ml: t('curricularUnitDetails.improvementExam', { textOnly: true }),
    nm: t('curricularUnitDetails.examSeason', { textOnly: true }),
    re: t('curricularUnitDetails.improvementSeason', { textOnly: true }),
    ex: t('curricularUnitDetails.extraordinary', { textOnly: true }),
    es: t('curricularUnitDetails.specialSeason', { textOnly: true }),
  };

  const EVALUATION_TYPE = {
    finalExam: t('curricularUnitDetails.finalExam', { textOnly: true }),
    continuous: t('curricularUnitDetails.continuous', { textOnly: true }),
  };

  const EVALUATION_STATUS = {
    ongoing: t('curricularUnitDetails.ongoingEvaluation', { textOnly: true }),
    done: t('curricularUnitDetails.doneEvaluation', { textOnly: true }),
  };

  // get ucCode from the url
  const selectedUcCode = getCurrentRoute().params.ucCode;
  const registrationId = getCurrentRoute().params.regid;

  const user = useSelector(selectors.getUser);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  useEffect(() => {
    if (selectedUcCode !== null) {
      dispatch(setSelectedUcCode(selectedUcCode));
      dispatch(getUcDetails({ selectedUcCode, registrationId }));
    }
  }, [selectedUcCode]);

  const getSummaryTableStructure: any = (
    signups,
    summaryColumnTitles,
    evaluationStatus,
    evaluationType
  ) => {
    const structure = {
      header: {
        type: 'columnTitle' as string,
        titles: summaryColumnTitles.map((title) => {
          return {
            text: title,
            style: {
              textAlign: 'center',
            },
          };
        }),
      },
      rows: signups
        ? signups.map((entry) => {
            return {
              rowProps: { color: theme.colors.white },
              cells: Object.keys(entry).map((field) => {
                if (field === 'evaluationStatus') {
                  return {
                    content: (
                      <StatusLabelWrapper>
                        <StatusLabel
                          background={
                            entry[field] === 'done'
                              ? theme.colors.successGreen
                              : theme.colors.regularGrey
                          }
                          label={evaluationStatus[entry[field]]}
                        />
                      </StatusLabelWrapper>
                    ),
                    cellProps: {
                      textAlign: 'center',
                    },
                  };
                } else if (field === 'evaluationType') {
                  return {
                    content: evaluationType[entry[field]],
                    cellProps: {
                      textAlign: 'center',
                    },
                  };
                } else if (field === 'semester') {
                  return {
                    content:
                      entry[field] === 0
                        ? t('enrollments.annual', { textOnly: true })
                        : `${numeral(entry[field]).format('o')} ${t('enrollments.semester', {
                            textOnly: true,
                          })}`,
                    cellProps: {
                      textAlign: 'center',
                      style: {
                        whiteSpace: 'nowrap',
                      },
                    },
                  };
                } else if (field === 'yearOfStudy') {
                  return {
                    content: `${numeral(entry[field]).format('o')} ${t('enrollments.year', {
                      textOnly: true,
                    })}`,
                    cellProps: {
                      textAlign: 'center',
                    },
                  };
                } else {
                  return {
                    content: entry[field],
                    cellProps: {
                      textAlign: 'center',
                    },
                  };
                }
              }),
            };
          })
        : [
            {
              cells: [
                {
                  content: '',
                },
              ],
            },
          ],
    };

    return structure;
  };

  const getHistoryTableStructure: any = (history, historyColumnTitles, evaluationMethod) => {
    const structure = {
      header: {
        type: 'columnTitle' as string,
        titles: historyColumnTitles.map((title) => {
          return {
            text: title,
            style: {
              textAlign: 'center',
            },
          };
        }),
      },
      rows: history
        ? history.map((entry) => {
            const fields = Object.keys(entry)
              .filter((field) => field !== 'evaluationTypeId')
              .filter((field) => field !== 'semester'); //hide semester rows temporarly
            return {
              rowProps: { color: theme.colors.white },
              cells: fields.map((field) => {
                if (field === 'examPeriod') {
                  return {
                    content: evaluationMethod[entry[field]],
                    cellProps: {
                      textAlign: 'center',
                    },
                  };
                } else if (field === 'grade') {
                  return {
                    content:
                      `${t('suplementaryGrades.' + entry[field], {
                        textOnly: true,
                      })}` ===
                        'suplementaryGrades.' + entry[field] || entry['evaluationTypeId'] !== 1
                        ? entry[field]
                        : `${t('suplementaryGrades.' + entry[field], {
                            textOnly: true,
                          })}`,
                    cellProps: {
                      color:
                        Number(entry[field]) > 9 && Number(entry[field]) <= 20
                          ? theme.colors.successGreen
                          : theme.colors.dangerRed,
                      weight: 'bold',
                      textAlign: 'center',
                    },
                  };
                } else if (field === 'gradesFile') {
                  return {
                    content:
                      entry.gradesFile.name === null ? (
                        <></>
                      ) : (
                        <Button
                          action
                          borderless
                          loading={loadingModels[entry.gradesFile.id]}
                          disabled={loadingModels[entry.gradesFile.id]}
                          onClick={() => {
                            if (!loadingModels[entry.gradesFile.id]) {
                              setLoadingModels((currentValues) => {
                                return { ...currentValues, [entry.gradesFile.id]: true };
                              });
                              API.files.getFileOnTab
                                .call(`${API_SEC_VIRTUAL}${entry[field].url}`)
                                .then((value) => {
                                  setLoadingModels((currentValues) => {
                                    return { ...currentValues, [entry.gradesFile.id]: false };
                                  });
                                })
                                .catch((e) => {
                                  setLoadingModels((currentValues) => {
                                    return { ...currentValues, [entry.gradesFile.id]: false };
                                  });
                                  dispatch(
                                    actions.Toaster.showToaster({
                                      title: t('secVirtualNotifications.general_errorLoadingFile'),
                                      icon: 'error',
                                      type: 'danger',
                                    })
                                  );
                                });
                            }
                          }}
                        >
                          <FontAwesomeIcon size={'2x'} icon={faFilePdf} />
                        </Button>
                      ),
                    cellProps: {
                      textAlign: 'center',
                      style: {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      },
                    },
                  };
                } else {
                  /* hide semester rows temporarly 
                else if (field === 'semester') {
                  return {
                    content:
                      entry[field] === 0
                        ? t('enrollments.annual', { textOnly: true })
                        : `${numeral(entry[field]).format('o')} ${t('enrollments.semester', {
                            textOnly: true,
                          })}`,
                    cellProps: {
                      textAlign: 'center',
                      style: {
                        whiteSpace: 'nowrap',
                      },
                    },
                  };
                } */
                  return {
                    content: entry[field],
                    cellProps: {
                      textAlign: 'center',
                    },
                  };
                }
              }),
            };
          })
        : [
            {
              cells: [
                {
                  content: '',
                },
              ],
            },
          ],
    };

    return structure;
  };

  const summaryTableStructure = getSummaryTableStructure(
    ucDetails.signups ? ucDetails.signups : null,
    SUMMARY_COLUMN_TITLES,
    EVALUATION_STATUS,
    EVALUATION_TYPE
  );

  const historyTableStructure = getHistoryTableStructure(
    ucDetails.history ? ucDetails.history : null,
    HISTORY_COLUMN_TITLES,
    EVALUATION_METHOD
  );

  return (
    <PageStructure
      loading={loading || errorStatus.errLoadingDetails}
      headerLeft={
        <Header
          pullBackButton
          backButton
          title={selectedUcCode ? `${selectedUcCode} - ${ucDetails.ucName}` : ''}
        />
      }
      mainContent={
        <>
          {Object.keys(ucDetails).length > 0 && (
            <>
              {/*Hide Resumo Matricula*/}
              {/* <Segment>
                <EnrollmentSummaryContent>
                  <Text size={'large'} weight={'regular'} color={theme.colors.plusDarkGrey}>
                    {t(`curricularUnitDetails.enrollmentSummary_title`)}
                  </Text>

                  {summaryTableStructure.rows.length > 0 && (
                    <SummaryTableWrapper>
                      <Table structure={summaryTableStructure} loading={loading}></Table>
                    </SummaryTableWrapper>
                  )}

                  {summaryTableStructure.rows.length === 0 && (
                    <MessageNoCertificates>
                      <Icon>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Icon>
                      <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                        {t('curricularUnitDetails.noEnrollmentSummary', { textOnly: true })}
                      </Text>
                    </MessageNoCertificates>
                  )}
                </EnrollmentSummaryContent>
              </Segment> */}

              <HistorySegmentWrapper>
                <Segment>
                  <Text size={'large'} weight={'regular'} color={theme.colors.plusDarkGrey}>
                    {t(`curricularUnitDetails.enrollmentHistory_title`)}
                  </Text>

                  {historyTableStructure.rows.length > 0 && (
                    <SummaryTableWrapper>
                      <Table
                        structure={historyTableStructure}
                        loading={loading}
                        unstackable
                      ></Table>
                    </SummaryTableWrapper>
                  )}

                  {historyTableStructure.rows.length === 0 && (
                    <MessageNoCertificates>
                      <Icon>
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </Icon>
                      <Text color="plusDarkGrey" size={'medium'} weight={'regular'}>
                        {t('curricularUnitDetails.noEnrollmentHistory', { textOnly: true })}
                      </Text>
                    </MessageNoCertificates>
                  )}
                </Segment>
              </HistorySegmentWrapper>
            </>
          )}
        </>
      }
    />
  );
};

export default CurricularUnitDetails;

const EnrollmentSummaryContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryTableWrapper = styled.div`
  margin-top: 28px;
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;

const HistorySegmentWrapper = styled.div`
  margin-top: 40px;
`;

const StatusLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const MessageNoCertificates = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 16px;
  }
`;

const Icon = styled.div`
  font-size: 60px;
  color: ${({ theme }) => theme.colors.primary};
`;
