/**
 * FormInputWithFlags
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import React from 'react';

import styled from 'styled-components';

import { getStorage } from 'utils';

import { DropdownFlags, FormInput, Text } from '@paco_ua/pacoui';

interface IProps {
  register?: any;
  onChange?: any;
  error?: any;
  success?: any;
  name?: string;
  defaultValue?: {
    input: string;
    countryValue: string;
  };
  displayLocale?: boolean;
  placeholder?: string;
  fontSize?:
    | 'xxSmall'
    | 'xSmall'
    | 'small'
    | 'article'
    | 'mediumSmall'
    | 'medium'
    | 'large'
    | 'xLarge'
    | 'xxLarge'
    | 'xxxLarge';
  showTooltip?: boolean;
}

const FormInputWithFlags: React.FC<IProps> = ({
  error,
  success,
  onChange,
  name,
  defaultValue,
  displayLocale,
  placeholder,
  fontSize,
  showTooltip,
}) => {
  const currentLang = () => {
    const lang = getStorage('currentLocale');
    if (lang === 'pt') return 'pt-PT';
    if (lang === 'en') return 'en-UK';
    return lang;
  };
  return (
    <Wrapper>
      <WrapperFlags>
        <DropdownWrapper>
          <DropdownFlags
            defaultValue={defaultValue?.countryValue || ''}
            onChange={(flagValue) => {
              onChange && onChange({ input: defaultValue?.input, countryValue: flagValue });
            }}
            displayLocale={displayLocale}
            showTooltip={showTooltip}
            language={showTooltip ? currentLang() : undefined}
          />
        </DropdownWrapper>

        <FormInputWrapper>
          <FormInput
            name={name}
            placeholder={placeholder}
            fontSize={fontSize}
            defaultValue={defaultValue?.input}
            onChange={(e) => {
              onChange &&
                onChange({
                  input: e.target.value,
                  countryValue: defaultValue?.countryValue,
                });
            }}
          />
        </FormInputWrapper>
      </WrapperFlags>

      <MessageArea>
        {error && (
          <Text size="xSmall" weight="medium" color="dangerRed">
            * {error}
          </Text>
        )}
        {success && (
          <Text size="xSmall" weight="medium" color="successGreen">
            * {success}
          </Text>
        )}
      </MessageArea>
    </Wrapper>
  );
};

export default FormInputWithFlags;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const WrapperFlags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-width: thin;
  border-color: ${({ theme }) => theme.colors.darkGrey};
`;

const DropdownWrapper = styled.div`
  /*padding: 0px 8px 0px 8px;*/
  height: 40px;
  white-space: nowrap;
  border-right: 1px solid ${({ theme }) => theme.colors.darkGrey};
  display: flex;
  justify-content: center;
  align-items: center;

  /*&&& i:nth-child(3) {
    margin-left: 24px;
    margin-right: 8px;
  }*/

  &&& .ui.search.dropdown > input.search {
    height: 20px !important;
    margin-top: 10px !important;
    min-width: 0px;
    max-width: 69px;
    left: 8px;
  }

  &&& .ui.search.dropdown > .text {
    margin-left: 8px;
    left: 0px;
  }

  /*when input text is not empty*/
  &&& .ui.dropdown > .dropdown.icon {
    margin-left: 100.4px;
    margin-right: 8px;
  }

  /*when input text is empty*/
  &&& div.divider.text ~ i.dropdown.icon {
    margin-left: 24px;
  }

  &&& {
    min-width: 118px !important;
  }
`;

const FormInputWrapper = styled.div`
  flex-grow: 1;
`;

const MessageArea = styled.div`
  margin-top: 4px;
`;
