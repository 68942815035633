/**
 * MySchedules scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { GridContextProvider, GridDropZone, GridItem } from 'react-grid-dnd';
import { withSize } from 'react-sizeme';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faPlus,
  faAngleDown,
  faQuestionCircle,
  faExclamationCircle,
} from '@fortawesome/pro-light-svg-icons';

import CreatedSchedule from './containers/CreatedSchedule';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import { AnimatedBackground } from '@paco_ua/pacoui';

import CaptionSchedule from 'scenes/Schedules/containers/CaptionSchedule';

import AuthWrapper from 'shared/components/AuthWrapper';

import { t } from 'app';

import {
  Accordion,
  Text,
  Loading,
  Modal,
  Button,
  NoDataMessage,
  RestrictAccessOverlay,
  ScheduleGrid,
} from '@paco_ua/pacoui';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

//import config from 'config'
import { swapSchedules, getStatusName } from './utils';

const MySchedules = ({ theme, t, size }) => {
  const [cursor, setCursor] = useState('grab');

  const {
    createdSchedules,
    scheduleToDelete,
    deleteModalOpen,
    loadingSchedules,
    savingMySchedules,
    loadingScheduleToView,
    dragEnabled,
    warningNoAccess,
    warningNoAccessMotive,
    currentActivePhase,
    seriationDate,
    viewScheduleModalIsOpen,
    ucs,
    scheduleGridInterval,
    currentPhase,
    lastSeriatedPhase,
    nextSeriationDate,
    showHistory,
    viewScheduleHideFaces,
  } = useSelector(selectors.getMySchedulesSlice);
  const registration = useSelector(selectors.getRegistration);
  const {
    setDeleteModalOpen,
    deleteSchedule,
    onMount,
    onPhaseUpdate,
    updateSchedules,
    setDragEnabled,
    hideScheduleModalOpening,
    setWarningNoAccess,
    setWarningNoAccessMotive,
    setLoadingSchedules,
  } = actions.MySchedules;
  const { navigateTo } = actions.App;

  const dispatch = useDispatch();

  const registrations = useSelector(selectors.getRegistrations);

  const boxesPerRow = size.width > 1350 ? 4 : size.width > 880 ? 3 : size.width > 500 ? 2 : 1 || 4;
  const rowHeight = 238;
  const rowsNumber = Math.ceil(createdSchedules.activePhase?.schedules.length / boxesPerRow);
  const rowGap = 24;
  const colGap = 64;

  const hasCurrentActivePhase = currentActivePhase !== null && currentActivePhase !== undefined;
  // Active phase and finished
  const hasFinishedActivePhase = currentPhase?.finished;

  useEffect(() => {
    dispatch(onMount());
  }, [dispatch, onMount]);

  useEffect(() => {
    if (registrations !== null && !registrations?.length) {
      dispatch(setWarningNoAccess(true));
      dispatch(setWarningNoAccessMotive(t('sgh.noAccessRegistrations', { textOnly: true })));
      dispatch(setLoadingSchedules(false));
    }
  }, [registrations]);

  useEffect(() => {
    if (registration) {
      dispatch(onPhaseUpdate());
    }
  }, [dispatch, registration]);

  const onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    //change order of preferences
    if (sourceIndex !== targetIndex && createdSchedules.activePhase.schedules.length > 1) {
      dispatch(
        updateSchedules(
          swapSchedules(createdSchedules.activePhase?.schedules, sourceIndex, targetIndex)
        )
      );
    }
  };

  const handleScheduleDelete = () => {
    dispatch(setDeleteModalOpen(false));
    scheduleToDelete && dispatch(deleteSchedule(scheduleToDelete));
  };

  const handleModalOnCloseOrOnCancel = () => {
    dispatch(hideScheduleModalOpening());
  };

  const hasAllocatedSchedule = currentPhase?.hasAllocatedSchedule;

  const getMainContent = () => {
    if (warningNoAccess) {
      return (
        <RestrictAccessOverlay
          restrictionStatus={'restricted'}
          restrictionMotive={warningNoAccessMotive ? t(warningNoAccessMotive) : ''}
          onComplete={() => {}}
        />
      );
    }

    if (!warningNoAccess) {
      /*if (savingMySchedules || loadingScheduleToView) {
        return (
          <LoadingSchedulesWrapper>
            <Loading overlay size={'5x'} />
          </LoadingSchedulesWrapper>
        );
      }*/

      if (
        hasFinishedActivePhase &&
        !hasCurrentActivePhase &&
        !currentPhase?.seriationFinished &&
        (currentPhase?.attemptedSchedule || currentPhase?.phase === 1)
      ) {
        return (
          <NoDataMessage header={t('sgh.finishedOnePhaseHeader')} body={t('sgh.pleaseWait')} />
        );
      }

      // ON ACTIVE PHASE AND SERIATION FISINHED
      if ((hasFinishedActivePhase && currentPhase?.seriationFinished) || !hasCurrentActivePhase) {
        // SCHEDULE NOT SENT NOT ALLOCATED TO STUDENT
        if (!currentPhase?.attemptedSchedule && !currentPhase?.hasAllocatedSchedule) {
          return (
            <NoDataMessage
              header={t('sgh.scheduleProcessFinished')}
              body={t('sgh.scheduleNotSent')}
            />
          );
        }
      }

      //CHECK IF ALL SHCEDULES WHERE PREALLOCATED
      if (
        currentPhase?.hasAllocatedSchedule &&
        (currentActivePhase !== null || currentActivePhase !== undefined) &&
        !createdSchedules.finishedPhases.length &&
        !createdSchedules?.activePhase?.schedules.length
      ) {
        return (
          <NoDataMessage
            header={t('sgh.schedulePreAllocatedScheduleStartPhaseTitle')}
            body={t('sgh.schedulePreAllocatedScheduleStartPhase')}
          />
        );
      }

      //CHECK IF ALL SHCEDULES WHERE GIVEN SCHEDULE
      if (
        currentPhase?.hasAllocatedSchedule &&
        (currentActivePhase === null || currentActivePhase === undefined) &&
        !createdSchedules.finishedPhases.length
      ) {
        return (
          <NoDataMessage
            header={t('sgh.scheduleProcessFinished')}
            body={t('sgh.schedulePreAllocatedSchedule')}
          />
        );
      }

      return (
        <>
          {hasCurrentActivePhase && (
            <>
              <GridWrapper>
                <PhaseTitleWrapper>
                  <CurrentActivePhaseText color="primary" size="xLarge" weight="medium">
                    {t('sgh.phase')} {currentActivePhase}
                  </CurrentActivePhaseText>

                  <SeriationDateWrapper>
                    {(seriationDate || nextSeriationDate) && (
                      <SeriationDateText
                        size="medium"
                        color="plusDarkGrey"
                        weight="regular"
                        icon={
                          <Button
                            noPadding
                            borderless
                            color={theme.colors.primary}
                            onClick={() => {
                              if (!(!seriationDate && nextSeriationDate)) {
                                dispatch(
                                  navigateTo({
                                    key: 'provisoryseriationhistory',
                                    params: {
                                      id: currentPhase?.phase,
                                    },
                                  })
                                );
                              } else {
                                dispatch(
                                  actions.Toaster.showToaster({
                                    title: t('sgh.lastProvSeriationIsBehind'),
                                    icon: 'info',
                                    type: 'warning',
                                  })
                                );
                              }
                            }}
                          >
                            <FontAwesomeIcon size="2x" icon={faExclamationCircle} />
                          </Button>
                        }
                        onClick={() => {
                          if (!(!seriationDate && nextSeriationDate)) {
                            dispatch(
                              navigateTo({
                                key: 'provisoryseriationhistory',
                                params: {
                                  id: currentPhase?.phase,
                                },
                              })
                            );
                          } else {
                            dispatch(
                              actions.Toaster.showToaster({
                                title: t('sgh.lastProvSeriationIsBehind'),
                                icon: 'info',
                                type: 'warning',
                              })
                            );
                          }
                        }}
                      >
                        {seriationDate &&
                          `${t('sgh.simulatedAttribution', { textOnly: true })} ${moment(
                            seriationDate
                          ).format('HH:mm')}`}
                        {!seriationDate &&
                          nextSeriationDate &&
                          `${t('sgh.waitForNextSeriation', { textOnly: true })}`}
                      </SeriationDateText>
                    )}

                    {(seriationDate || nextSeriationDate) && (
                      <AboutSimulationBarText size="medium" color="plusDarkGrey" weight="regular">
                        {'|'}
                      </AboutSimulationBarText>
                    )}

                    <AboutSimulationWrapper>
                      <AboutSimulationText
                        size="medium"
                        color="plusDarkGrey"
                        weight="regular"
                        onClick={() =>
                          dispatch(
                            navigateTo({
                              key: 'schedulesGuideSpecific',
                              params: {
                                id: 'simulationHelp',
                              },
                            })
                          )
                        }
                        icon={
                          <Button
                            noPadding
                            borderless
                            color={theme.colors.plusDarkGrey}
                            onClick={() => {
                              dispatch(
                                navigateTo({
                                  key: 'schedulesGuideSpecific',
                                  params: {
                                    id: 'simulationHelp',
                                  },
                                })
                              );
                            }}
                          >
                            <FontAwesomeIcon size="2x" icon={faQuestionCircle} />
                          </Button>
                        }
                      >
                        {t('sgh.aboutSimulation', { textOnly: true })}
                      </AboutSimulationText>
                    </AboutSimulationWrapper>
                  </SeriationDateWrapper>
                </PhaseTitleWrapper>

                {(createdSchedules.activePhase === null ||
                  !createdSchedules.activePhase?.schedules.length) && (
                  <StaticGrid boxesPerRow={boxesPerRow} colGap={colGap}>
                    <StaticGridItem>
                      <NoDataMessage
                        type="segment"
                        header={t('sgh.warningNoSchedulesMySchedulesHeader')}
                        body={t('sgh.warningNoSchedulesMySchedulesBody')}
                      />
                    </StaticGridItem>
                  </StaticGrid>
                )}

                {(createdSchedules.activePhase !== null ||
                  !!createdSchedules.activePhase?.schedules.length) && (
                  <GridContextProvider onChange={onChange}>
                    <DropZone
                      id="items"
                      disableDrag={!dragEnabled}
                      boxesPerRow={boxesPerRow}
                      rowHeight={rowHeight + colGap}
                      height={rowHeight * rowsNumber + (colGap * rowsNumber - 1)}
                    >
                      {createdSchedules.activePhase.schedules.map((schedule: any, key: number) => (
                        <GridItem key={`schedules_list_assigned_${schedule.id}`}>
                          <CreatedScheduleWrapper
                            cursor={cursor}
                            onMouseUp={() => {
                              setCursor('grab');
                            }}
                            onMouseDown={() => {
                              setCursor('grabbing');
                            }}
                            padding={(key + 1) % boxesPerRow !== 0 && rowGap}
                          >
                            <CreatedSchedule
                              finishedPhase={false}
                              onEdit={() => {
                                dispatch(setDragEnabled(false));
                              }}
                              onCancel={() => {
                                dispatch(setDragEnabled(true));
                              }}
                              status={getStatusName(schedule.state)}
                              name={schedule.name}
                              id={schedule.id}
                              allocationProb={schedule.allocationProb}
                              seriationDate={schedule.seriationDate}
                              assignedClasses={schedule.currentSelections}
                              indexKey={key}
                              showDetails={
                                currentActivePhase === lastSeriatedPhase && schedule.allocationProb
                              }
                              preference={schedule.preference}
                            />
                          </CreatedScheduleWrapper>
                        </GridItem>
                      ))}
                    </DropZone>
                  </GridContextProvider>
                )}
              </GridWrapper>
            </>
          )}
          {createdSchedules.finishedPhases.length > 0 && (
            <FinishedPhases>
              {createdSchedules.finishedPhases.map((finishedPhase, key: number) => (
                <Accordion
                  key={`schedules_list_not_assigned_phases_${key}`}
                  structure={[
                    {
                      defaultOpen: true,
                      parent: (
                        <AccordionPhaseWrapper>
                          <Text color="plusDarkGrey" size="xLarge" weight="medium">
                            {!currentPhase?.seriationFinished &&
                            currentPhase?.phase === finishedPhase.phase
                              ? `${t('sgh.phase', { textOnly: true })} ${
                                  finishedPhase.phase
                                } ${t('sgh.scheduleProcessingAllocation', { textOnly: true })}`
                              : `${t('sgh.phase', { textOnly: true })} ${finishedPhase.phase}`}
                          </Text>

                          <FontAwesomeIcon size="lg" icon={faAngleDown} />
                        </AccordionPhaseWrapper>
                      ),
                      children: [
                        <StaticGrid
                          key="createdSchedules_staticGrid"
                          boxesPerRow={boxesPerRow}
                          colGap={colGap}
                          rowGap={rowGap}
                        >
                          {finishedPhase.schedules.map((schedule: any) => (
                            <StaticGridItem
                              key={`schedules_list_not_assigned_${schedule.id}`}
                              padding={rowGap}
                            >
                              <CreatedSchedule
                                finishedPhase={true}
                                status={getStatusName(schedule.state)}
                                viewOnly
                                assignedClasses={schedule.currentSelections}
                                creationTime={schedule.creationTime}
                                name={schedule.name}
                                allocationProb={schedule.allocationProb}
                                seriationDate={schedule.seriationDate}
                                id={schedule.id}
                                indexKey={key}
                                preference={schedule.preference}
                                showDetails={false}
                              />
                            </StaticGridItem>
                          ))}
                        </StaticGrid>,
                      ],
                    },
                  ]}
                />
              ))}
            </FinishedPhases>
          )}
        </>
      );
    }
  };

  return (
    <>
      <Modal
        type="simple"
        open={viewScheduleModalIsOpen /* && !loadingScheduleToView*/}
        /*cancelButtonText={t('generic.close')}
        onCancel={() => {
          handleModalOnCloseOrOnCancel();
        }}*/
        onClose={() => handleModalOnCloseOrOnCancel()}
        data-testid="schedules_modal_schedule_view"
      >
        <ScheduleModalContentWrapper>
          {loadingScheduleToView && (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="20%" />
              <AnimatedBackground height={theme.sizes.large} width="50%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="80%" />
              <AnimatedBackground height={theme.sizes.large} width="50%" />
            </LoadingWrapper>
          )}
          {!loadingScheduleToView && (
            <ScheduleGridWrapper>
              <ScheduleGrid
                t={{ noUcs: t('sgh.noUc_plural') }}
                weekdays={6}
                startTime={scheduleGridInterval.min}
                endTime={scheduleGridInterval.max}
                intervalInMin={60}
                schedule={ucs}
                disabled
              />
              <CaptionWrapper>
                <CaptionSchedule ucs={ucs} hideSmiles={viewScheduleHideFaces} />
              </CaptionWrapper>
            </ScheduleGridWrapper>
          )}
        </ScheduleModalContentWrapper>
      </Modal>

      <Modal
        type={'danger'}
        open={!!deleteModalOpen && !!scheduleToDelete}
        header={t('sgh.actionConfirmDelete', {
          what: (scheduleToDelete && scheduleToDelete.name) || '',
        })}
        border
        cancelButtonText={t('generic.cancel')}
        submitButtonText={t('generic.remove')}
        onClose={() => {
          dispatch(setDeleteModalOpen(false));
        }}
        onCancel={() => {
          dispatch(setDeleteModalOpen(false));
        }}
        onSubmit={() => {
          handleScheduleDelete();
        }}
        data-testid="my_schedules_modal_delete_confirmation"
      />

      <PageStructure
        expanded
        loading={loadingSchedules}
        headerLeft={<Header backButton title={t('sgh.mySchedule_plural')} />}
        headerRight={
          !warningNoAccess &&
          !loadingSchedules && (
            <HeaderButtons>
              <AuthWrapper
                roles={[
                  'superUser_FullAccess',
                  'superUser_ReadOnly',
                  'sgh_superUser_ReadOnly',
                  'sgh_superUser_FullAccess',
                  'sec_superUser_ReadOnly',
                  'sec_superUser_FullAccess',
                  'student'
                ]}
              >
                <Button
                  action
                  leftIcon={<FontAwesomeIcon icon={faHistory} />}
                  onClick={() => dispatch(navigateTo({ key: 'seriationhistory' }))}
                  data-testid="schedules_button_seriation_history"
                >
                  {t('sgh.finalSeriationHistoryButton')}
                </Button>
              </AuthWrapper>

              {!hasAllocatedSchedule && hasCurrentActivePhase ? (
                <Button
                  action
                  leftIcon={<FontAwesomeIcon icon={faPlus} />}
                  onClick={() => dispatch(navigateTo({ key: 'createSchedule' }))}
                  data-testid="my_schedules_button_new_schedule"
                >
                  {t('sgh.buttonNewSchedule')}
                </Button>
              ) : (
                <Button
                  action
                  onClick={() => dispatch(navigateTo({ key: 'mySchedule' }))}
                  data-testid="my_schedules_button_my_schedule"
                >
                  {t('sgh.mySchedule')}
                </Button>
              )}
            </HeaderButtons>
          )
        }
        subHeader={<StudentRegistrationDropdown loading={loadingSchedules} />}
        mainContent={<ContentWrapper>{getMainContent()}</ContentWrapper>}
      />
    </>
  );
};

export default withSize()(MySchedules);

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const GridWrapper = styled.div`
  margin-bottom: 80px;
`;

const HeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`;

const DropZone = styled(GridDropZone)<{ height: number }>`
  height: ${({ height }) => `${height}px`};
  margin-top: 40px;
`;

interface ICreatedScheduleWrapper {
  padding?: number | boolean;
  cursor: string;
}

const CreatedScheduleWrapper = styled.div<ICreatedScheduleWrapper>`
  padding-right: ${({ padding }) => (padding ? `${padding}px` : `0`)};
  cursor: ${({ cursor }) => cursor};
`;

const LoadingSchedulesWrapper = styled.div`
  position: fixed;
  display: flex;
  top: 0;
  height: 100vh;
  width: 100vw;
  align-items: center;
  z-index: 3;
`;
const StaticGrid = styled.div<{ boxesPerRow: number; colGap: number; rowGap?: number }>`
  display: grid;
  grid-template-columns: ${({ boxesPerRow }) => `repeat(${boxesPerRow}, 1fr)`};
  gap: 24px 0;
  margin: ${({ rowGap }) => (rowGap ? `0 -${rowGap / 2}px` : `0`)};
`;

const StaticGridItem = styled.div<{ padding?: number }>`
  margin-top: 40px;
  position: relative;
  padding: ${({ padding }) => (padding ? `${padding / 2}px` : `0`)};
`;

const FinishedPhases = styled.div`
  margin-top: 20px;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const AccordionPhaseWrapper = styled.div`
  display: flex;
  align-items: center;

  > div {
    padding-right: 24px;
  }
`;

const CaptionWrapper = styled.div`
  margin-top: -20px;
  padding-left: 63px;
`;

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  margin-top: 32px;

  * {
    margin: 8px 0px;
  }
`;

const ScheduleGridWrapper = styled.div`
  min-height: 400px;
`;

const SeriationDateText = styled(Text)`
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const PhaseTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CurrentActivePhaseText = styled(Text)`
  margin-bottom: 8px;
`;

const AboutSimulationWrapper = styled.div`
  align-items: center;
  display: flex;
  :hover {
    text-decoration: underline;
  }
`;

const AboutSimulationBarText = styled(Text)`
  line-height: 1;
  margin: 0px 10px;
  align-items: center;
  display: flex;
`;

const SeriationDateWrapper = styled.div`
  * {
    line-height: 1;
  }
  display: flex;
  align-items: center;
`;

const AboutSimulationText = styled(Text)`
  :hover {
    cursor: pointer;
  }
`;

const ScheduleModalContentWrapper = styled.div`
  margin-top: 40px;
`;
