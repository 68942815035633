/**
 * SeriationHistoryDetails scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  loading: true,
  seriationDate: null as null | string,
  ranking: {
    rankingNumber: null as null | number,
    rankingTotal: null as null | number,
    regimen: null as null | string,
    totalEcts: null as null | number,
    grade: null as null | number,
    repeatUcs: null as null | number,
  },
  log: null as null | string[],
};

export default createSlice({
  name: 'SeriationHistoryDetails',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSeriationDate: (state, action: PayloadAction<string>) => {
      state.seriationDate = action.payload;
    },
    setRanking: (state, action: PayloadAction<any>) => {
      state.ranking = action.payload;
    },
    setLog: (state, action: PayloadAction<string[]>) => {
      state.log = action.payload;
    },
    getSeriationHistoryDetails: () => {},
    reset: (state) => initialState,
  },
});
