/**
 * LogsStudent scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */

import { takeLatest, put } from 'redux-saga/effects'
import { actions } from 'store/rootSlices';
//import { actions } from 'store/rootSlices'

function* onMountSaga() {
  yield put(actions.LogsStudent.setLoadingModule(false));
}

function* onUnmountSaga() {
  // IMPORTANT: we must return to the initial state

  yield put(actions.LogsStudent.setLoadingModule(true));
}


export default function* watcherSignin() {
  yield takeLatest('LogsStudent/onMount', onMountSaga)
  yield takeLatest('LogsStudent/onUnmount', onUnmountSaga)
}


