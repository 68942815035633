/**
 * Logs scene sagas
 *
 * @author Manuel Gil <mgil@ubiwhere.com>
 *
 */

import { put, call, takeLatest } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
//import { actions } from 'store/rootSlices'

function* onMountSaga() {
}

function* onUnmountSaga() {
  // IMPORTANT: we must return to the initial state
  yield put(actions.Logs.setLoadingModule(true));
}

export default function* watcherSignin() {
  yield takeLatest('Logs/onMount', onMountSaga)
  yield takeLatest('Logs/onUnmount', onUnmountSaga)
}


