import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Router,useLocation} from 'react-router-dom';
import { Provider } from 'react-redux';

import App from 'app';
import CloseApp from 'app/CloseApp';

import * as serviceWorker from 'serviceWorker';

import { history, initLocales, hideLogs } from 'utils';
import store from 'store';
import 'api/interceptors';

import 'moment/locale/pt';
import 'moment/locale/zh-hk';

// External styling
import 'semantic-ui-css/semantic.min.css';
import 'normalize.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

initLocales();
hideLogs();

function ScrollToTop( ) {
  let location = useLocation();

  useEffect( () => {
    window.scrollTo({ top: -50,  behavior: 'smooth' })
  }, [ location ] );

  return null;
}

ReactDOM.render(
  <Router history={history}>
    <Provider store={store}>
      <ScrollToTop />
      <CloseApp>
        <App />
      </CloseApp>
    </Provider>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();