/**
 * Payments scene selectors
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getUser = (state: RootState) => state.App.user;
export const getPaymentsSlice = (state: RootState) => state.Payments;
export const getPendingDebitsPaymentSlice = (state: RootState) => state.PendingDebitsPayment;
export const getRegistrationId = (state: RootState) =>
  state.StudentRegistrationDropdown.registration;
export const getStudentRegistrationDropdown = (state: RootState) =>
  state.StudentRegistrationDropdown;
export const getAppSlice = (state: RootState) => state.App;
