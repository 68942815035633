/**
 * App sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import { call, takeLatest, put, putResolve, select } from 'redux-saga/effects';
import moment from 'moment';
import numeral from 'numeral';
import { camelizeKeys } from 'humps';
import routes, { IRoutes } from 'routes';
import LogRocket from 'logrocket';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { ITranslation } from 'app/logic/slice';
import { ISignin, IUser } from './slice';

import { initTracking, shouldSignin, hasTokenParameter, decodeJWT } from 'app/utils';

import ErrorHandler from 'shared/errorHandler';

import { setStorage, getStorage, history, i18nInstance, getCurrentRoute } from 'utils';
import {
  initWebSocket,
  initWebSocketChannel,
  createTranslationBundles,
  getUserRoles,
} from 'app/utils';

import { listAllScopes } from 'api/utils';

import config, { IRole } from 'config';


interface INavigateToSaga {
  type: 'App/navigateTo';
  payload: { key: string; params?: any; state?: any; forceRefresh?: boolean; hash?: string };
}

interface IChangeLocaleSaga {
  type: 'App/changeLocale';
  payload: string;
}

interface IUpdateApiTranslationSaga {
  type: 'App/updateApiTranslation';
  payload: ITranslation;
}

interface IImpersonateUserSaga {
  type: 'App/impersonateUser';
  payload: string;
}

function* onMountSaga() {
  const App = yield select(selectors.getAppSlice);
  const signInfo = shouldSignin();
  const tokenParameter = hasTokenParameter();

  try {
    let { route, params } = getCurrentRoute();

    const user = getStorage('user') || {};
    const cookies = getStorage('cookies-accepted');

    initTracking();
    console.log('INIT TRACKING');

    if (signInfo) {
      // user is signing in
      yield call(signinSaga, signInfo);
      yield call(getSuperUserSaga);
    } else if (tokenParameter !== null) {
      // force user token
      yield call(forceSigninSaga, tokenParameter);
      yield call(getSuperUserSaga);
    } else {
      // user already signed in
      yield putResolve(actions.App.setUser({ ...App.user, ...user }));
    }

    yield* getApiTranslationsAndCheckRoutesSaga();

    if (cookies === null) {
      yield put(
        actions.ToasterAction.showToasterAction({
          title: t('generic.cookies'),
          buttonTitle: t('generic.accept'),
          cancelTitle: t('generic.reject'),
          onCancel: () => {
            localStorage.setItem('cookies-accepted', 'false');
          },
          onAccept: () => {
            try {
              initTracking();
              if (config.LOGROCKET_ID) {
                LogRocket.identify(user.email);
              }
              localStorage.setItem('cookies-accepted', 'true');
            } catch (e) {}
          },
        })
      );
    }

    if (signInfo) {
      yield put(actions.App.navigateTo({ key: 'home' }));
    }

    yield put(actions.App.setStartingApp(false));
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('offline.actionErrorStartUp'),
        icon: 'error',
        type: 'danger',
      })
    );
  }
}

/* try {
     if (getStorage('user')) {
       const socket = yield call(initWebSocket);
 
       const channel = yield call(initWebSocketChannel, socket);
 
       while (true) {
         const action = yield take(channel);
         yield put(action);
       }
     }
   } catch (e) {
     console.error('Socket initiation error');
   }*/

function* navigateToSaga(action: INavigateToSaga) {
  const { key, params, state, forceRefresh, hash } = action.payload;
  const { routingStructure } = yield select(selectors.getAppSlice);

  let route = routingStructure.find((route) => route.key === key);
  let path: any = route?.path || null;

  if (!path) {
    return null;
  }

  Object.keys(path).forEach((key) => {
    if (i18nInstance.language.includes(key)) {
      path = path[key];
    }
  });

  if (typeof path !== 'string') {
    path = path.en;
  }

  if (hash) {
    path += `#${hash}`;
  }

  //check path based on current language
  if (params) {
    Object.keys(params).forEach((param) => {
      path = path.replace(`:${param}`, params[param]);
    });
  }

  if (forceRefresh) {
    window.location.href = path;
  } else {
    yield call(history.push, path, state);
  }
}

function* navigateBackSaga() {
  yield call(history.goBack);
}

function* changeLocaleSaga(action: IChangeLocaleSaga) {
  const { payload } = action;
  moment.locale(payload);
  numeral.locale(payload);
  i18nInstance.changeLanguage(payload);

  let { route, params } = getCurrentRoute();

  yield put(actions.App.setCurrentLocale(i18nInstance.language));
  yield put(actions.App.navigateTo({ key: route.key, params }));

  setStorage('currentLocale', payload);
}

function* toggleApiTranslationsEditSaga() {
  const App = yield select(selectors.getAppSlice);
  yield put(actions.App.setTranslationsEdit(!App.translationsEdit));
}

function* getApiTranslationsAndCheckRoutesSaga() {
  const allTranslations = yield call(API.translations.getTranslations.call);
  const newRoutes = yield call(API.routes.getFunctionalities.call);

  if (newRoutes.length === 0) {
    throw new Error();
  }

  let translations = [] as any[];

  allTranslations.forEach((translation) => {
    if (translation.fields.name !== 'routingStructure') {
      translations.push(translation);
    }
  });

  const translationBundles = createTranslationBundles(translations);

  Object.keys(translationBundles).forEach((language) => {
    i18nInstance.addResourceBundle(
      language,
      'translation',
      translationBundles[language],
      true,
      true
    );
  });

  let currentLocale = getStorage('currentLocale');

  if (currentLocale) {
    i18nInstance.changeLanguage(currentLocale);
  } else {
    i18nInstance.changeLanguage(i18nInstance.language);
    setStorage('currentLocale', i18nInstance.language);
  }

  if (
    config.ENVIRONMENT === 'DEV' ||
    (config.ENVIRONMENT === 'DEV' && config.ROUTES_ENV !== 'STG')
  ) {
    yield putResolve(actions.App.setRoutingStructure(routes));
  } else {
    const mergedRoutes = routes.reduce<IRoutes[]>((acc, route) => {
      let foundRoute = newRoutes.find((newRoute) => newRoute.key === route.key);
      if (foundRoute) {
        if (foundRoute?.path?.pt?.length === 0 || foundRoute?.path?.en?.length === 0)
          delete foundRoute.path;
        return [...acc, { ...route, ...foundRoute }];
      }
      return acc;
    }, []);

    yield putResolve(actions.App.setRoutingStructure(mergedRoutes));
  }

  yield put(actions.App.setApiTranslations(translations));
  yield put(actions.App.setCurrentLocale(i18nInstance.language));
}

function* updateApiTranslationSaga(action: IUpdateApiTranslationSaga) {
  const App = yield select(selectors.getAppSlice);
  const { name, form } = action.payload;

  try {
    const { id } = App.apiTranslations.find((translation) => translation.name === name);
    const properties = Object.keys(form).map((property) => ({
      key: property,
      value: form[property].pt,
      valueCh: form[property].zh,
      valueEn: form[property].en,
    }));

    //yield call(API.translations.patchTranslations.call, id, { properties });
  } catch (e) {
    console.log('update translation error', e);
  }
}

function* updateUserRoleSaga() {
  //alert('Update user role')
}

function* signinSaga(signInfo: ISignin) {
  
  try {
    const { code } = signInfo;
    let userData = yield call(API.signin.token.call, {
      grantType: 'authorization_code',
      code,
      redirectUri: config.OIDC_CONFIG.redirectUri,
    });
    userData = camelizeKeys(userData);
    const profile = camelizeKeys(decodeJWT(userData.idToken));

    const { accessToken, refreshToken, idToken, tokenType, expiresIn, scope } = userData;
    const studentNmec = config.HARDCODED_NMEC || profile.studentNumber || profile.nMecAluno;

    if (!profile.iss || !profile.aud[0]) throw Error();

    const userRoles: IRole[] = getUserRoles(config.HARDCODED_ROLES || profile.groups, []);

    let user: IUser = {
      name: `${profile.givenName}`,
      fullName: `${profile.uAFriendlyName}`,
      email: `${profile.email}`,
      expiresIn,
      roles: userRoles,
      nmec: studentNmec,
      registrations: null,
      accessToken,
      refreshToken,
      scopes: scope,
      idToken,
      tokenType,
    };
    
    //logRocket signUser
    if (config.LOGROCKET_ID) {
      try {
        LogRocket.identify(profile.email);
      } catch (e) {}
    }
    localStorage.setItem('user', JSON.stringify(user));
    yield putResolve(actions.App.setUser(user));
    try{
      yield call(API.sgh.getLogin.call);
    }
    catch (e){
      const { scope } = userData;  
      yield call(API.signin.postLogin.call,scope);
    } 
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('generic.actionErrorUnauthorized'),
        icon: 'error',
        type: 'danger',
      })
    );
  }
}

function* forceSigninSaga(signInfo: {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}) {
  const { accessToken, refreshToken, idToken } = signInfo;

  try {
    const profile = camelizeKeys(decodeJWT(idToken));
    const studentNmec = config.HARDCODED_NMEC || profile.studentNumber || profile.nMecAluno;

    if (!profile.iss || !profile.aud[0]) throw Error();

    const userRoles = getUserRoles(config.HARDCODED_ROLES || profile.groups, []);

    let user = {
      name: `${profile.givenName}`,
      fullName: `${profile.givenName} ${profile.familyName}`,
      email: `${profile.email}`,
      expiresIn: '3600',
      roles: userRoles,
      nmec: studentNmec,
      registrations: null,
      accessToken,
      refreshToken,
      scopes: listAllScopes(),
      idToken,
      tokenType: 'Bearer',
    };

    //logRocket signUser
    if (config.LOGROCKET_ID) {
      try {
        LogRocket.identify(profile.email);
      } catch (e) {}
    }
    localStorage.setItem('user', JSON.stringify(user));
    yield putResolve(actions.App.setUser(user));
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('generic.actionErrorUnauthorized'),
        icon: 'error',
        type: 'danger',
      })
    );
  }
}

function* getSuperUserSaga() {
  try {
    const storageUser = getStorage('user') || {};

    const profile = camelizeKeys(decodeJWT(storageUser.idToken));
    let superUserData = [] as string[];

    // if (
    //   config?.WHITELIST_DEV_NMEC.some((whiteNmec) => whiteNmec === parseInt(storageUser.nmec)) ||
    //   config?.WHITELIST_DEV_EMAIL.some((whiteEmail) => whiteEmail === storageUser.email)
    // ) {
    //   superUserData = ['FullAccess', 'ReadOnly'];
    // }

    let userRoles = getUserRoles(config.HARDCODED_ROLES || profile.groups, superUserData);

    //in case of some roles we need to get more information at RCU,
    //this cannot happen for every user so that the system doesn't have problems with many requests
    
      try {
        const rcuData = yield call(
          API.signin.getRcuGroups.call,
          profile.uAIUPI,
          storageUser.accessToken
        );

        rcuData.forEach((data) => {
          if (data.includes('SGA-PACO2-superUser-ReadOnly')) {
            superUserData.push('superUser_ReadOnly');
          }
          if (data.includes('SGA-PACO2-superUser-FullAccess')) {
            superUserData.push('superUser_FullAccess');
          }

          if (data.includes('SGA-PACO2-SecretariaVirtual-ReadOnly')) {
            superUserData.push('sec_superUser_ReadOnly');
          }
          if (data.includes('SGA-PACO2-SecretariaVirtual-FullAccess')) {
            superUserData.push('sec_superUser_FullAccess');
          }

          if (data.includes('SGA-PACO2-Horarios-ReadOnly')) {
            superUserData.push('sgh_superUser_ReadOnly');
          }
          if (data.includes('SGA-PACO2-Horarios-FullAccess')) {
            superUserData.push('sgh_superUser_FullAccess');
          }
          if (data.includes('SGA-PACO2-Pautas-FullAccess')) {
            superUserData.push('pautas_superUser_FullAccess');
          }
          if (data.includes('SGA-PACO2-Pautas-ReadOnly')) {
            superUserData.push('pautas_superUser_ReadOnly');
          }
          
          if (data.includes('STIC-PACO2-DEV-superUser-ReadOnly')) {
            superUserData.push('superUser_ReadOnly');
          }
          if (data.includes('STIC-PACO2-DEV-superUser-FullAccess')) {
            superUserData.push('superUser_FullAccess');
          }

          if (data.includes('STIC-PACO2-DEV-SecretariaVirtual-ReadOnly')) {
            superUserData.push('sec_superUser_ReadOnly');
          }
          if (data.includes('STIC-PACO2-DEV-SecretariaVirtual-FullAccess')) {
            superUserData.push('sec_superUser_FullAccess');
          }

          if (data.includes('STIC-PACO2-DEV-Horarios-ReadOnly')) {
            superUserData.push('sgh_superUser_ReadOnly');
          }
          if (data.includes('STIC-PACO2-DEV-Horarios-FullAccess')) {
            superUserData.push('sgh_superUser_FullAccess');
          }

          if (data.includes('STIC-PACO2-DEV-Pautas-FullAccess')) {
            superUserData.push('pautas_superUser_FullAccess');
          }
          if (data.includes('STIC-PACO2-DEV-Pautas-ReadOnly')) {
            superUserData.push('pautas_superUser_ReadOnly');
          }
        });

        userRoles = getUserRoles(config.HARDCODED_ROLES || profile.groups, superUserData);

        if (rcuData.some((group) => group.includes('Horarios-Pivot'))) {
          if (!userRoles.some((role) => role === 'nonProfessor')) {
            userRoles.push('nonProfessor');
          }
        }
      } catch (e) {}
    

    const newUser = {
      ...storageUser,
      roles: userRoles,
    };
    localStorage.setItem('user', JSON.stringify(newUser));

    yield putResolve(actions.App.setUser(newUser));
  } catch (e) {
    //do nothing if this fails. should not block app
  }
}

function* signoutSaga() {
  try {
    yield put(actions.App.setStartingApp(true));
    const { navigateTo } = actions.App;
    localStorage.removeItem('user');
    yield put(
      actions.App.setUser({
        name: '',
        roles: ['guest'],
        accessToken: '',
        scopes: [],
      })
    );
    yield* getApiTranslationsAndCheckRoutesSaga();
    yield put(navigateTo({ key: 'home' }));
    yield put(actions.App.setStartingApp(false));
  } catch (e) {}
}

function* impersonateUserSaga(action: IImpersonateUserSaga) {
  const studentNmec = action.payload;

  try {
    yield put(actions.App.setStartingApp(true));
    yield put(actions.StudentRegistrationDropdown.setRegistration(null));

    const storageUser = getStorage('user') || {};

    const newUser = {
      ...storageUser,
      impersonate: studentNmec,
    };
    localStorage.setItem('user', JSON.stringify(newUser));

    yield putResolve(actions.App.setUser(newUser));

    yield* getApiTranslationsAndCheckRoutesSaga();
    yield put(actions.App.setStartingApp(false));
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('superuser.errorInvalidNmec'),
        icon: 'error',
        type: 'danger',
      })
    );
  } finally {
    yield put(actions.App.setStartingApp(false));
  }
}

function* cleanImpersonateUserSaga() {
  try {
    yield put(actions.App.setStartingApp(true));
    const storageUser = getStorage('user') || {};

    const { impersonate, ...cleanImpersonate } = storageUser;
    const newUser = {
      ...cleanImpersonate,
    };

    localStorage.setItem('user', JSON.stringify(newUser));
    yield putResolve(actions.App.setUser(newUser));
    yield put(actions.App.navigateTo({ key: 'home' }));
    yield put(actions.App.setStartingApp(false));
  } catch (e) {
    yield put(
      actions.Toaster.showToaster({
        title: t('generic.actionErrorUnauthorized'),
        icon: 'error',
        type: 'danger',
      })
    );
  }
}

export default function* watcherSignin() {
  yield takeLatest('App/onMount', onMountSaga);
  yield takeLatest('App/navigateTo', navigateToSaga);
  yield takeLatest('App/navigateBack', navigateBackSaga);
  yield takeLatest('App/changeLocale', changeLocaleSaga);
  yield takeLatest('App/toggleApiTranslationsEdit', toggleApiTranslationsEditSaga);
  yield takeLatest('App/updateApiTranslation', updateApiTranslationSaga);
  yield takeLatest('App/updateUserRole', updateUserRoleSaga);
  yield takeLatest('App/signout', signoutSaga);
  yield takeLatest('App/impersonateUser', impersonateUserSaga);
  yield takeLatest('App/cleanImpersonateUser', cleanImpersonateUserSaga);
  yield takeLatest('App/getApiTranslationsAndCheckRoutes', getApiTranslationsAndCheckRoutesSaga);
}
