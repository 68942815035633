/**
 * Footer component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faTwitter,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import { useSelector } from 'react-redux';
import * as selectors from './selectors';

import { t } from 'app';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import EasyPayLogo from "./cc-reta_white.png";

interface IProps {
  // example: string;
}

const SOCIAL = [
  {
    name: 'Facebook',
    url: 'https://www.facebook.com/universidadedeaveiro/',
    icon: faFacebookF,
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com/universidadedeaveiro/',
    icon: faInstagram,
  },
  {
    name: 'Twitter',
    url: 'https://twitter.com/univaveiro',
    icon: faTwitter,
  },
  {
    name: 'Linkedin',
    url: 'https://www.linkedin.com/school/universidade-de-aveiro/?originalSubdomain=pt',
    icon: faLinkedinIn,
  },
  {
    name: 'YouTube',
    url: 'https://www.youtube.com/channel/UCJnxPIxMHdxcYhRIIw_ztEg',
    icon: faYoutube,
  },
];

const Footer: React.FC<IProps> = () => {
  const currentLocale = useSelector(selectors.getCurrentLocale);
  return (
    <div>
      <SocialLinksContacts>
        <Column>
          <ColumnTitle>{t('generic.footerFollow')}</ColumnTitle>
          <FirstColumnWrapper>
            <SocialLinksWrapper>
              {SOCIAL.map((social, index) => (
                <Link href={social.url} key={index} aria-label={social.name} target="_blank">
                  <FontAwesomeIcon icon={social.icon as IconProp} />
                </Link>
              ))}
            </SocialLinksWrapper>
            <Link
              href={`https://www.ua.pt/${currentLocale === 'pt' ? 'pt' : 'en'}/covid-19-info`}
              target="_blank"
            >
              <img
                height="100px"
                src="https://www.ua.pt/imgs/selo_sgs.png"
                alt="Logótipo do Consórcio Europeu de Universidades Inovadoras"
              />
            </Link>
          </FirstColumnWrapper>
        </Column>
        <Column>
          <ColumnTitle>{t('generic.footerQuestions')}</ColumnTitle>
          <ul>
            <li>
              <Link
                target="_blank"
                href={`https://forms.ua.pt/index.php?r=survey/index&sid=988175&lang=${
                  currentLocale === 'pt' ? 'pt' : 'en'
                }`}
              >
                {t('generic.footerRector')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`https://forms.ua.pt/index.php?r=survey/index&sid=115724&lang=${
                  currentLocale === 'pt' ? 'pt' : 'en'
                }`}
              >
                {t('generic.footerAskStudent')}
              </Link>
            </li>
          </ul>
        </Column>
        <Column>
          <ColumnTitle>{t('generic.footerLinks')}</ColumnTitle>
          <ul>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/universidade`
                    : `https://www.ua.pt/${currentLocale}/university`
                }`}
              >
                {t('generic.footerAbout')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/tipo-curso`
                    : `https://www.ua.pt/${currentLocale}/course-types`
                }`}
              >
                {t('generic.footerCourses')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/candidaturas`
                    : `https://www.ua.pt/${currentLocale}/applications`
                }`}
              >
                {t('generic.footerApplications')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/departamentos-escolas`
                    : `https://www.ua.pt/${currentLocale}/departments-schools`
                }`}
              >
                {t('generic.footerDepSchools')}
              </Link>
            </li>
            <li>
              <Link
                href={`https://www.ua.pt/${currentLocale}/administracao-servicos`}
                target="_blank"
              >
                {t('generic.footerServices')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/unidades-investigacao`
                    : `https://www.ua.pt/${currentLocale}/research-units`
                }`}
              >
                {t('generic.footerResearchUnits')}
              </Link>
            </li>
            <li>
              <Link href={`https://www.ua.pt/${currentLocale}/sbidm`} target="_blank">
                {t('generic.footerLibrary')}
              </Link>
            </li>
          </ul>
        </Column>
        <Column>
          <ColumnTitle>{t('generic.footerPublics')}</ColumnTitle>
          <ul>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/publico-futuros-estudantes`
                    : `https://www.ua.pt/${currentLocale}/public-prospective-students`
                }`}
              >
                {t('generic.footerProspectiveStudents')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/publico-estudantes`
                    : `https://www.ua.pt/${currentLocale}/public-students`
                }`}
              >
                {t('generic.footerUaStudents')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/publico-estudantes-internacionais`
                    : `https://www.ua.pt/${currentLocale}/public-internacional-student`
                }`}
              >
                {t('generic.footerIntStudents')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/publico-alumni`
                    : `https://www.ua.pt/${currentLocale}/public-alumni`
                }`}
              >
                {t('generic.footerAlumni')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/publico-pessoas`
                    : `https://www.ua.pt/${currentLocale}/public-people`
                }`}
              >
                {t('generic.footerPeople')}
              </Link>
            </li>
            <li>
              <Link
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/publico-sociedade`
                    : `https://www.ua.pt/${currentLocale}/public-society`
                }`}
              >
                {t('generic.footerSociety')}
              </Link>
            </li>
          </ul>
        </Column>
        <Column>
          <ColumnTitle>{t('generic.footerContacts')}</ColumnTitle>
          <ContactsWrapper>
            <p>
              Campus Universitário de Santiago <br /> 3810-193 Aveiro <br /> Portugal
            </p>
            <div>
              <EmailLink href={`mailto:geral@ua.pt`}>geral@ua.pt</EmailLink>
              <PhoneLink href="tel:+351234370200">+351 234 370 200</PhoneLink>
            </div>
            <Link
              target="_blank"
              href={`${
                currentLocale === 'pt'
                  ? `https://www.ua.pt/${currentLocale}/contactos-gerais`
                  : `https://www.ua.pt/${currentLocale}/contacts`
              }`}
            >
              {t('generic.footerGeneralContacts')}
            </Link>
          </ContactsWrapper>
        </Column>
      </SocialLinksContacts>
      <LogoLinks>
        <BottomFooterWrapper>
          <LinksBrandWrapper>
            <LinksWrapper>
              <Link
                aria-label={t('generic.footerDataProtection')}
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/protecao-dados`
                    : `https://www.ua.pt/${currentLocale}/gdpr`
                }`}
              >
                {t('generic.footerDataProtection')}
              </Link>
              <Link
                aria-label={t('generic.footerTermsOfService')}
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/termos-de-utilizacao`
                    : `https://www.ua.pt/${currentLocale}/terms-of-use`
                }`}
              >
                {t('generic.footerTermsOfService')}
              </Link>
              <Link
                aria-label={t('generic.footerAccessibility')}
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/acessibilidade`
                    : `https://www.ua.pt/${currentLocale}/accessibility`
                }`}
              >
                {t('generic.footerAccessibility')}
              </Link>
              <Link
                aria-label={t('generic.footerSiteMap')}
                target="_blank"
                href={`${
                  currentLocale === 'pt'
                    ? `https://www.ua.pt/${currentLocale}/mapa-site`
                    : `https://www.ua.pt/${currentLocale}/site-map`
                }`}
              >
                {t('generic.footerSiteMap')}
              </Link>
            </LinksWrapper>
            <Brand>{`Universidade de Aveiro ${new Date().getFullYear()}`}</Brand>
          </LinksBrandWrapper>
          <LogosWrapper>
            <Link href="https://www.ua.pt/stic/page/22825" target="_blank">
              <Logo
                src="https://www.ua.pt/imgs/logos/logo_compete.jpg"
                alt="Logótipo Compete 2020"
              />
            </Link>
            <Link href="http://www.eciu.org/" target="_blank">
              <Logo
                src="https://www.ua.pt/imgs/logos/eciu_01.svg"
                alt="Logótipo do Consórcio Europeu de Universidades Inovadoras"
              />
            </Link>
            <Link href="https://www.ua.pt/file/42005" target="_blank">
              <Logo
                src="https://www.ua.pt/imgs/logos/eche.jpg"
                alt="Logótipo do ERASMUS charter for higher education 2014-2020"
              />
            </Link>
            <Link href="https://www.ua.pt/file/33409" target="_blank">
              <Logo
                src="https://www.ua.pt/imgs/logos/eps.jpg"
                alt="Logótipo do ERASMUS policy statement"
              />
            </Link>
            <Link href="https://www.cgd.pt/" target="_blank">
              <Logo src="https://www.ua.pt/imgs/logos/logo_cgd.jpg" alt="CGD" height="25px" />
            </Link>
            <Logo src={EasyPayLogo} alt="EasyPay" height="45px" />
          </LogosWrapper>
        </BottomFooterWrapper>
      </LogoLinks>
    </div>
  );
};

export default Footer;

const SocialLinksContacts = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 40px 15px;
  margin: auto;
  justify-content: space-between;

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (max-width: 1200px) {
    padding-bottom: 0;
  }
`;

const Column = styled.div`
  margin: 0;
  flex: 0 0 50%;

  & ul {
    margin: 0;
    padding-inline-start: 0;
  }

  & li {
    line-height: normal;
    list-style-type: none;
    margin-bottom: 5px;
  }

  & a {
    font-size: 14px;
  }

  & p {
    font-size: 0.875rem;
    color: rgb(255, 255, 255);
    line-height: 1.4;
    font-weight: 300;
    margin-bottom: 20px;
    word-break: break-word;
    font-family: Roboto, sans-serif;
  }

  @media (min-width: 576px) {
    flex: 0 0 auto;
  }

  @media (min-width: 1200px) {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  @media (max-width: 1199px) {
    &:not(:first-of-type) {
      margin-top: 25px;
    }
    &:first-of-type,
    &:last-of-type {
      padding-bottom: 25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:last-of-type {
      padding-top: 25px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  @media (max-width: 575px) {
    &:nth-of-type(4) {
      padding-top: 25px;
      padding-bottom: 25px;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }

  &:first-of-type {
    flex: 0 0 25%;

    @media (max-width: 1199px) {
      flex: 0 0 100%;
    }

    & h3 {
      @media (max-width: 576px) {
        display: none;
      }
    }
  }

  &:nth-of-type(2) {
    @media (min-width: 1200px) {
      flex: 0 0 16.666667%;
    }
  }

  &:last-of-type {
    @media (max-width: 575px) {
      flex: 0 0 50%;
    }

    @media (min-width: 576px) and (max-width: 1199px) {
      flex: 0 0 100%;
    }
  }
`;

const ColumnTitle = styled.h3`
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white};
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
  word-break: break-word;
  font-family: Roboto, sans-serif;
  margin-bottom: 20px;
`;

const SocialLinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 15px;
  font-size: 24px;
  flex: 0 0 50%;

  & a:hover {
    color: rgb(26, 26, 26);
    opacity: 0.5;
    & svg {
      color: rgb(128, 128, 128);
    }
  }

  @media (min-width: 576px) {
    flex: 0 0 66.666667%;
  }

  @media (min-width: 768px) {
    flex: 0 0 75%;
  }

  @media (min-width: 992px) {
    flex: 0 0 83.333333%;
  }

  @media (min-width: 1200px) {
    flex: 0 0 100%;
    margin-bottom: 30px;
  }

  & svg {
    font-size: 24px;
  }
`;

const FirstColumnWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;
  & img {
    height: 100px;

    @media (max-width: 1200px) {
      margin-top: -20px;
      height: 60px;
    }
  }

  @media (min-width: 1200px) {
    flex-direction: column;
  }

  & > a {
    flex: 0 0 50%;
    @media (min-width: 576px) {
      flex: 0 0 33.333333%;
    }

    @media (min-width: 768px) {
      flex: 0 0 25%;
    }

    @media (min-width: 992px) {
      flex: 0 0 16.666667%;
    }
  }
`;

const ContactsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & p {
    margin-bottom: 0;
  }

  @media (max-width: 1199px) {
    gap: 30px;
  }

  @media (max-width: 575px) {
    flex-direction: column;
    gap: 20px;
  }

  @media (min-width: 1200px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const EmailLink = styled.a`
  pointer-events: auto;
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  display: block;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`;

const PhoneLink = styled.a`
  pointer-events: auto;
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 300;
  text-decoration: none;
  cursor: pointer;
  display: block;

  @media (max-width: 575px) {
    width: 100%;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`;

const LogoLinks = styled.div`
  padding: 20px 0;

  @media (max-width: 991px) {
    padding-top: 0px;
  }

  @media (min-width: 576px) {
    margin: auto;
    max-width: 540px;
  }

  @media (min-width: 768px) {
    margin: auto;
    max-width: 720px;
  }

  @media (min-width: 992px) {
    margin: auto;
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    max-width: 100%;
  }
`;

const BottomFooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  padding: 0 15px;

  @media (max-width: 1199px) {
    flex-direction: column;
    padding-bottom: 20px;
  }

  @media (min-width: 576px) {
    max-width: 540px;
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1340px;
  }
`;

const LinksBrandWrapper = styled.div`
  width: 100%;

  @media (max-width: 1199px) {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  column-gap: 26px;
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

const Link = styled.a`
  font-size: 11px;
  cursor: pointer;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`;

const Brand = styled.span`
  color: rgb(117, 117, 117);
  font-size: 11px;
`;

const LogosWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  column-gap: 30px;

  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const Logo = styled.img`
  height: ${(p) => (p.height ? p.height : '30px')}};
  width: auto;
  object-fit: cover;
  font-weight: 300;
`;
