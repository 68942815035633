/**
 * CertificateValidator scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest, call, put, putResolve, select } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';
import { t } from 'app';

import API from 'api';
import * as selectors from './selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import config from 'config';
import _ from 'lodash';

import ErrorHandler from 'shared/errorHandler';

function* onMountSaga() {}

function* validateCertificateCodeSaga(
  action: PayloadAction<{
    recaptchaToken: string;
    accessCode: string;
  }>
) {
  try {
    yield put(
      actions.CertificatesValidator.setErrorStatus({
        fieldName: 'errLoadingCertificateValidation',
        fieldValue: false,
      })
    );

    yield put(
      actions.CertificatesValidator.setLoadingStatus({
        fieldName: 'loadingCertificateValidation',
        fieldValue: true,
      })
    );

    const certData = yield call(API.secVirtual.postCertAccessCodeWithRecaptcha.call, {
      recaptchaToken: action.payload.recaptchaToken,
      accessCode: action.payload.accessCode,
    });

    yield put(actions.CertificatesValidator.setCertificateData(certData));
    yield put(actions.CertificatesValidator.setHasValidated(true));
  } catch (e) {
    yield put(
      actions.CertificatesValidator.setErrorStatus({
        fieldName: 'errLoadingCertificateValidation',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if(e.response.data.error_code === "ERR_EXPIRED_CERT"){
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.certificateValidator_errorExpiredCertificate'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
      else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.certificateValidator_errorValidatingCertificate'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }

    yield put(actions.CertificatesValidator.setHasValidated(false));
  } finally {
    yield put(
      actions.CertificatesValidator.setLoadingStatus({
        fieldName: 'loadingCertificateValidation',
        fieldValue: false,
      })
    );
  }
}

function* onUnmountSaga() {
  yield put(actions.CertificatesValidator.reset());
}

export default function* watcherSignin() {
  yield takeLatest('CertificateValidator/onMount', onMountSaga);
  yield takeLatest('CertificateValidator/onUnmount', onUnmountSaga);
  yield takeLatest('CertificateValidator/validateCertificateCode', validateCertificateCodeSaga);
}
