/**
 * ClassSupoort scene sagas
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { takeLatest } from 'redux-saga/effects';

//import { actions } from 'store/rootSlices'

function* onMountSaga() {}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('ClassSupoort/onMount', onMountSaga);
  yield takeLatest('ClassSupoort/onUnmount', onUnmountSaga);
}
