/**
 * Header utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import { t } from 'app';

export const getLanguageName = (locale) => {
  if (locale.includes('pt')) {
    return 'EN';
  } else {
    return 'PT';
  }
};

export const getResults = (query, user, Routes) =>
  query &&
  Routes.filter(
    (route) =>
      !route.disabled &&
      (!route.roles ||
        route?.roles.some((role) => user.roles.some((userRole) => userRole === role)))
  )
    .map((route) => ({
      key: route.key,
      text: t(`routes.${route.name}`, { textOnly: true }),
      value: route.key,
    }))
    .filter(
      (route) =>
        route.text.toLowerCase().includes(query.toLowerCase()) && !route.text.includes('routes.')
    );
