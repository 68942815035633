/**
 * Certificate component
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useContext, useState, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import i18n from 'i18next';
import { t } from 'app';

import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHistory,
  faKey,
  faEye,
  faCreditCard,
  faClock,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';

import { ECertificateState } from '../../types';

import { MbIcon } from 'shared/components/Icons';

import { Button, Segment, CallToAction, Text, StatusLabel, Tooltip, Modal } from '@paco_ua/pacoui';

import * as selectors from '../../logic/selectors';
import { IRegistration } from 'shared/types';

//import config from 'config'

interface IProps {
  type: string;
  language: string;
  id: number;
  state?: ECertificateState | string;
  format?: string;
  requestDate: string;
  responseDate: string | null;
  registrationId: number;
  ready: boolean;
  isExempt: boolean;
  exemptionMotive: string;
  accessKey: string;
  expirationDate: string | null;
  paymentState?: string | null;
}

const Certificate: React.FC<IProps> = ({
  type,
  language,
  id,
  registrationId,
  state,
  responseDate,
  requestDate,
  format,
  ready,
  isExempt,
  exemptionMotive,
  accessKey,
  expirationDate,
  paymentState,
}) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();
  const user = useSelector(selectors.getUser);

  const { showAccesskeyModal } = useSelector(selectors.getCertificatesListSlice);
  const { setShowAccessKeyModal } = actions.CertificatesList;
  const { navigateTo } = actions.App;
  const { showToaster } = actions.Toaster;
  const [selectedRegistration, setSelectedRegistration] = useState(null as null | IRegistration);

  useEffect(() => {
    if (registrationId !== null && registrationId !== undefined && user.registrations) {
      const registration = user.registrations.find((reg) => reg.registrationId === registrationId);
      if (registration) {
        setSelectedRegistration(registration);
      }
    }
  }, [registrationId]);

  const getColor = (state) => {
    switch (state) {
      case ECertificateState.awaitingExemptionDecision:
        return theme.colors.decisionYellow;
      case ECertificateState.awaitingPayment:
        return theme.colors.darkGrey;
      case ECertificateState.rejected:
      case ECertificateState.cancelled:
      case ECertificateState.exemptionDenied:
        case ECertificateState.expired:
        return theme.colors.dangerRed;
      case ECertificateState.ready:
        return theme.colors.primary;
      default:
        return theme.colors.softRegularGrey;
    }
  };

  const getLabelState = () => {
    switch (state) {
      case ECertificateState.pending:
        return paymentState && paymentState !== 'paid' ? (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.decisionYellow}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faClock} />}
                >
                  {t('certificates.labelProcessing')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        ) : (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.successGreen}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<MbIcon fill={theme.colors.white} width={12} height={12} />}
                >
                  {t('certificates.labelPaid')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.awaitingPayment:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.darkGrey}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faCreditCard} />}
                >
                  {t('certificates.labelAwaitsPayment')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.awaitingExemptionDecision:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.darkGrey}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faCreditCard} />}
                >
                  {t('certificates.esenta')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.rejected:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.dangerRed}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faTimes} />}
                >
                  {t('certificates.labelRejected')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.ready:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.successGreen}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<MbIcon fill={theme.colors.white} width={12} height={12} />}
                >
                  {t('certificates.labelPaid')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
    }
  };

  const getRequestDateLabel = () => {
    const date = moment(expirationDate);
    if (moment().isAfter(date, 'day')) {
      return (
        <Text size="xSmall" weight="medium" color="dangerRed">
          {date.format('DD-MM-YYYY')}
        </Text>
      );
    } else {
      return (
        <Text size="xSmall" weight="medium" color="successGreen">
          {date.format('DD-MM-YYYY')}
        </Text>
      );
    }
  };

  return (
    <Segment
      grow
      padding={'24px'}
      borderTopColor={getColor(state)}
      footer={
        <CallToAction
          noPointer
          padding={'0px 6px'}
          footer={
            <FooterWrapper>
              <Tooltip
                content={t('certificates.details')}
                trigger={
                  <Button
                    action
                    data-testid={`certificate-details-button`}
                    onClick={() => {
                      dispatch(
                        navigateTo({
                          key: 'certificatesdetails',
                          params: { certificateId: id, registrationId: registrationId },
                        })
                      );
                    }}
                    borderless
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </Button>
                }
              />
              {ready && (
                <Tooltip
                  content={t('certificates.sendAccessCode')}
                  trigger={
                    <Button
                      disabled={accessKey === ''}
                      action
                      data-testid={`certificate-access-key-button`}
                      onClick={() =>
                        dispatch(setShowAccessKeyModal({ status: true, accessKey: accessKey }))
                      }
                      borderless
                    >
                      <FontAwesomeIcon icon={faKey} />
                    </Button>
                  }
                />
              )}
            </FooterWrapper>
          }
        />
      }
    >
      <SegmentBody>
        <SegmentHeader>
          <Text color="plusDarkGrey" transform="uppercase" weight="medium" size="xSmall">
            {t('certificates.listFormat', { textOnly: true })}: {format}
          </Text>
        </SegmentHeader>

        <SegmentWrapper>
          <SegmentSubHeader>
            <Text color="plusDarkGrey" weight="regular" size="xSmall">
              {t('certificates.request', { textOnly: true })}: {id} |{' '}
              {t('certificates.listRequestDate', { textOnly: true })}:{' '}
              {moment(requestDate).format('DD-MM-YYYY')}
            </Text>

            {selectedRegistration && (
              <TextWrapper size="article" weight="medium" color="plusDarkGrey">
                {`${type} - ${language}`}
              </TextWrapper>
            )}
          </SegmentSubHeader>
          <SegmentLabels>
            {isExempt && (
              <PaymentStateWrapper>
                <TextLabel size="xSmall" weight="medium" color="darkGrey">
                  {t('certificates.withExemption', { textOnly: true })}
                </TextLabel>
                <TextLabel size="xSmall" weight="regular" color="plusDarkGrey">
                  {exemptionMotive}
                </TextLabel>
              </PaymentStateWrapper>
            )}
            {responseDate && (
              <ResponseDateWrapper>
                <DateWrapper>
                  <TextLabel size="xSmall" weight="regular" color="darkGrey">
                    {t('certificates.sentDate', { textOnly: true })}
                  </TextLabel>
                  <TextLabel size="xSmall" weight="medium" color="plusDarkGrey">
                    {moment(responseDate).format('DD-MM-YYYY')}
                  </TextLabel>
                </DateWrapper>
                {expirationDate && (
                  <DateWrapper>
                    <TextLabel size="xSmall" weight="regular" color="darkGrey">
                      {t('certificates.expirationDate', { textOnly: true })}
                    </TextLabel>

                    <TextLabel size="xSmall" weight="medium" color="plusDarkGrey">
                      {getRequestDateLabel()}
                    </TextLabel>
                  </DateWrapper>
                )}
              </ResponseDateWrapper>
            )}

            {!responseDate && (
              <ResponseDateWrapper>
                <DateWrapper>
                  <TextLabel size="xSmall" weight="regular" color="darkGrey">
                    {t('certificates.state', { textOnly: true })}
                  </TextLabel>
                  {(state === ECertificateState.pending) && (
                    <TextLabel size="xSmall" weight="medium" color="decisionYellow">
                      {t('certificates.stateProcessing', { textOnly: true })}
                    </TextLabel>
                  )}
                  {(state === ECertificateState.awaitingExemptionDecision) && (
                    <TextLabel size="xSmall" weight="medium" color="decisionYellow">
                      {t('certificates.stateAwaitingExemptionDecision', { textOnly: true })}
                    </TextLabel>
                  )}
                  {(state === ECertificateState.cancelled ||
                    state === ECertificateState.rejected) && (
                    <TextLabel size="xSmall" weight="medium" color="dangerRed">
                      {t('certificates.cancelled', { textOnly: true })}
                    </TextLabel>
                  )}
                  {state === ECertificateState.submitted && (
                    <TextLabel size="xSmall" weight="medium" color="darkGrey">
                      {t('certificates.submittedRequest', { textOnly: true })}
                    </TextLabel>
                  )}
                  {state === ECertificateState.ready && (
                    <TextLabel size="xSmall" weight="medium" color="successGreen">
                      {t('certificates.ready', { textOnly: true })}
                    </TextLabel>
                  )}
                  {state === ECertificateState.exemptionDenied && (
                    <TextLabel size="xSmall" weight="medium" color="dangerRed">
                      {t('certificates.stateExemptionDenied', { textOnly: true })}
                    </TextLabel>
                  )}
                  {state === ECertificateState.expired && (
                    <TextLabel size="xSmall" weight="medium" color="dangerRed">
                      {t('certificates.stateExpired', { textOnly: true })}
                    </TextLabel>
                  )}
                  {state === ECertificateState.awaitingPayment && (
                    <FontAwesomeIcon icon={faEllipsisH} />
                  )}
                </DateWrapper>
              </ResponseDateWrapper>
            )}

            {state !== ECertificateState.rejected &&
              state !== ECertificateState.cancelled &&
              state !== ECertificateState.exemptionDenied &&
              state !== ECertificateState.expired &&
              paymentState &&
              paymentState !== 'noDebits' && (
                <PaymentStateWrapper>
                  <TextLabel size="xSmall" weight="regular" color="darkGrey">
                    {t('certificates.paymentState', { textOnly: true })}
                  </TextLabel>
                  {getLabelState()}
                </PaymentStateWrapper>
              )}
          </SegmentLabels>
        </SegmentWrapper>
      </SegmentBody>
      <Modal
        type="simple"
        open={showAccesskeyModal.status && showAccesskeyModal.accessKey === accessKey}
        onClose={() => dispatch(setShowAccessKeyModal({ status: false, accessKey: '' }))}
        data-testid="access-key-modal"
      >
        <ModalStatement>
          <Text size="small" color={theme.colors.primary} fontWeight="400">
            {t('certificates.accessKey', { textOnly: true })}
          </Text>
          <Text size="medium" color={theme.colors.primary} fontWeight="500">
            {accessKey}
          </Text>
          <Button
            action
            data-testid={`certificate-access-key-button`}
            onClick={async () => {
              const path =
                i18n.language === 'en' ? 'certificate-consultation' : 'consulta-certidao';

              try {
                await navigator.clipboard.writeText(`${window.location.host}/${path}/${accessKey}`);
                dispatch(
                  showToaster({
                    title: t('certificates.copied', { textOnly: true }),
                    icon: 'check',
                    type: 'success',
                  })
                );
              } catch (err) {}
            }}
          >
            {t('certificates.copyLink', { textOnly: true })}
          </Button>
        </ModalStatement>
      </Modal>
    </Segment>
  );
};

export default Certificate;

const SegmentHeader = styled.div`
  margin-bottom: 16px;
`;

const SegmentSubHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResponseDateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 24px;
  }
`;

const PaymentStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: left;
`;

const SegmentBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SegmentLabels = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top 8px;
  padding-top: -8px;

  > div {
    margin-top: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const StatusLabelWrapper = styled.div`
  margin-top: 4px;
`;

const TextWrapper = styled(Text)`
  margin-top: 4px;
  line-height: 1.3;
`;

const TextLabel = styled(Text)`
  margin-top: 4px;
`;

const ModalStatement = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    margin-top: 12px;
    max-width: 10rem;
  }
`;
