/**
 * PendingDebitsPayment sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { actions } from 'store/rootSlices';

import { PayloadAction } from '@reduxjs/toolkit';

import { call, takeLatest, put, select, putResolve } from 'redux-saga/effects';
import { t } from 'app';

import ErrorHandler from 'shared/errorHandler';
import API from 'api';
import * as selectors from './selectors';
import { ICallParams, IDebitData } from '../../types';

import { PAYMENT_ERROR_MESSAGE } from 'shared/errorMessages';

import { PAYMENT_METHOD, PENDING_DEBIT_STATE } from 'scenes/VirtualSecretary/Payments/types';

function* onMountSaga(action: PayloadAction<number | undefined>) {
  const registrationId = action.payload;

  if (registrationId) {
    yield putResolve(actions.StudentRegistrationDropdown.setRegistration(registrationId));
  } else {
    yield putResolve(actions.StudentRegistrationDropdown.getRegistrations());
  }

  yield putResolve(actions.PendingDebitsPayment.fetchPhoneNumber());

  yield put(actions.PendingDebitsPayment.fetchPendingDebits());
}

function* fetchDebitsSaga(action: PayloadAction<ICallParams>) {
  try {
    yield put(
      actions.PendingDebitsPayment.setErrorStatus({
        fieldName: 'errLoadingDebits',
        fieldValue: false,
      })
    );

    const registrationId = yield select(selectors.getRegistrationId);

    const pendingDebitsSlice = yield select(selectors.getPendingDebitsPaymentSlice);

    const debitsData = yield call(API.secVirtual.getStudentsUnpaidDebits.call, {
      ...action.payload,
      registrationId,
      ...pendingDebitsSlice.filterParams,
    });

    yield put(actions.Payments.setDebit(debitsData));

    yield put(actions.PendingDebitsPayment.setDebitsFilterTypes(debitsData.filters));
  } catch (e) {
    yield put(
      actions.PendingDebitsPayment.setErrorStatus({
        fieldName: 'errLoadingDebits',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newPaymentRequest_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loadingPaymentsTable',
        fieldValue: false,
      })
    );
  }
}

function* fetchPendingDebitsSaga() {
  try {
    yield put(
      actions.PendingDebitsPayment.setErrorStatus({
        fieldName: 'errLoadingPendingDebits',
        fieldValue: false,
      })
    );

    const registrationId = yield select(selectors.getRegistrationId);
    const paymentsSlice = yield select(selectors.getPaymentsSlice);

    if (registrationId !== null && registrationId !== undefined) {
      let pendingData = [] as any;

      if (paymentsSlice.pendingDebits.data.length > 0) {
        pendingData = paymentsSlice.pendingDebits.data;
      } else {
        pendingData = yield call(API.secVirtual.getStudentsPendingDebits.call, registrationId);
      }

      if (pendingData && pendingData.length > 0) {
        const allowMbwayPayment = !pendingData.some((debit) => {
          return (
            debit.paymentMethod === PAYMENT_METHOD.mbway &&
            debit.status === PENDING_DEBIT_STATE.awaitingPayment
          );
        });

        yield put(actions.PendingDebitsPayment.setAllowMbwayPayment(allowMbwayPayment));
      }
    }
  } catch (e) {
    yield put(
      actions.PendingDebitsPayment.setErrorStatus({
        fieldName: 'errLoadingPendingDebits',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newPaymentRequest_errorLoadingPage'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }
}

function* fetchPhoneNumberSaga() {
  try {
    yield put(
      actions.PendingDebitsPayment.setErrorStatus({
        fieldName: 'errLoadingPhoneNumber',
        fieldValue: false,
      })
    );

    const pendingDebitsSlice = yield select(selectors.getPendingDebitsPaymentSlice);

    const phoneNumber = yield call(API.secVirtual.getPersonalDataPhoneNumber.call);

    yield put(
      actions.PendingDebitsPayment.setMbwayForm({
        countryDropdown: {
          ...pendingDebitsSlice.mbwayForm.countryDropdown,
          input: phoneNumber.toString(),
        },
      })
    );
  } catch (e) {
    yield put(
      actions.PendingDebitsPayment.setErrorStatus({
        fieldName: 'errLoadingPhoneNumber',
        fieldValue: true,
      })
    );

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newPaymentRequest_errorFetchingPhoneNumber'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  } finally {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loadingPaymentsTable',
        fieldValue: false,
      })
    );
  }
}

function* submitMBDebitsRequestSaga(action: PayloadAction<IDebitData[]>) {
  const allDebits: number[] = [];
  const registrationId = yield select(selectors.getRegistrationId);

  action.payload.forEach((debit) => {
    allDebits.push(debit.debitId);

    debit.associatedCosts?.forEach((el) => {
      if (el.debitId) allDebits.push(el.debitId);
    });
  });

  try {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: true,
      })
    );

    const response = yield call(API.secVirtual.postPaymentReferenceMb.call, {
      registrationId,
      data: { debits: allDebits },
    });

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.newPaymentRequest_successPaymentAtm'),
        icon: 'check',
        type: 'success',
      })
    );

    yield put(actions.PendingDebitsPayment.setMbwayResponse(response));

    yield put(actions.PendingDebitsPayment.setDebitsSubmissionCompleted(true));

    yield put(actions.PendingDebitsPayment.setCurrentStep(3));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);

    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.newPaymentRequest_errorPaymentAtm'),
          icon: 'error',
          type: 'danger',
        })
      );
    }

    //get back to payments initial page with previous selected registration
    yield put(
      actions.App.navigateTo({
        key: 'payments',
        state: registrationId,
      })
    );
  } finally {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }
}

function* submitMbwayDebitsRequestSaga(action: any) {
  const allDebits: number[] = [];

  action.payload.selectedDebits.forEach((debit) => {
    allDebits.push(debit.debitId);

    debit.associatedCosts?.forEach((el) => {
      if (el.debitId) allDebits.push(el.debitId);
    });
  });

  const data = {
    phone: {
      number: action.payload.form.countryDropdown.input,
      prefix: action.payload.form.countryDropdown.countryValue,
    },
    debits: allDebits,
  };

  try {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: true,
      })
    );

    const registrationId = yield select(selectors.getRegistrationId);

    /*const response =*/ yield call(API.secVirtual.postPaymentReferenceMbWay.call, {
      registrationId,
      data,
    });

    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.newPaymentRequest_successPaymentMbway'),
        icon: 'check',
        type: 'success',
      })
    );

    const paymentsSlice = yield select(selectors.getPaymentsSlice);

    if (!paymentsSlice.isSocketActivated) {
      yield put(actions.Payments.handleWebsocketMessages());
    }

    yield put(actions.PendingDebitsPayment.setDebitsSubmissionCompleted(true));
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      if (
        e.response.data.error_code === PAYMENT_ERROR_MESSAGE.ERR_STUDENT_DUPLICATE_PAYMENT_METHOD
      ) {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newPaymentRequest_errorSubmitDuplicatePaymentMethod'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else if (e.response.data.error_code === PAYMENT_ERROR_MESSAGE.ERR_STUDENT_INVALID_PAYMENT) {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newPaymentRequest_errorInvalidPayment'),
            icon: 'error',
            type: 'danger',
          })
        );
      } else {
        yield put(
          actions.Toaster.showToaster({
            title: t('secVirtualNotifications.newPaymentRequest_errorPaymentMbway'),
            icon: 'error',
            type: 'danger',
          })
        );
      }
    }
  } finally {
    yield put(
      actions.PendingDebitsPayment.setLoadingStatus({
        fieldName: 'loading',
        fieldValue: false,
      })
    );
  }
}

function* onUnmountSaga() {
  yield put(actions.PendingDebitsPayment.resetPendingDebits());
}

export default function* watcherSignin() {
  yield takeLatest('PendingDebitsPayment/onMount', onMountSaga);
  yield takeLatest('PendingDebitsPayment/submitMbwayDebitsRequest', submitMbwayDebitsRequestSaga);
  yield takeLatest('PendingDebitsPayment/submitMbDebitsRequest', submitMBDebitsRequestSaga);
  yield takeLatest('PendingDebitsPayment/fetchDebits', fetchDebitsSaga);
  yield takeLatest('PendingDebitsPayment/fetchPhoneNumber', fetchPhoneNumberSaga);
  yield takeLatest('PendingDebitsPayment/fetchPendingDebits', fetchPendingDebitsSaga);
  yield takeLatest('PendingDebitsPayment/onUnmount', onUnmountSaga);
}
