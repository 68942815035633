/**
 * Sidebar component
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'store/rootSlices';
import * as selectors from './selectors';

import { ResponsiveLayout } from '@paco_ua/pacoui';

import SidebarMobile from './mobile';
import SidebarWeb from './web';

import { LinksList, setSelectedItems } from './utils';
import { history, getCurrentRoute } from 'utils';
import { IRole } from 'config';

interface IProps {
  userRoles: IRole[];
  onReady?: (hasRoutes: boolean) => void;
}

const Sidebar: React.FC<IProps> = ({ userRoles, onReady }) => {
  const [sidebarLinks, setSidebar] = useState<any>([]);
  const isSidebarOpen = useSelector(selectors.getSidebarOpening);
  const { currentLocale, routingStructure, user } = useSelector(selectors.getAppSlice);

  const { toggleSidebar } = actions.App;
  const skipSeletectItems = useRef<boolean>(false);

  const dispatch = useDispatch();
  const setLinks = () => {
    if (getCurrentRoute()) {
      return setSidebar(
        LinksList(
          userRoles,
          getCurrentRoute().route.key,
          routingStructure,
          user.impersonate ? true : false
        )
      );
    }
  };
  useMemo(setLinks, [userRoles, routingStructure, currentLocale]);

  const { navigateTo } = actions.App;

  useEffect(() => {
    if (isSidebarOpen && !skipSeletectItems.current) {
      skipSeletectItems.current = false;
      setSidebar(setSelectedItems(getCurrentRoute().route, sidebarLinks, routingStructure));
    }
    if (!isSidebarOpen) {
      skipSeletectItems.current = false;
      setSidebar(setSelectedItems(getCurrentRoute().route, sidebarLinks, routingStructure));
    }
  }, [isSidebarOpen]);

  history.listen(() => {
    setSidebar(setSelectedItems(getCurrentRoute().route, sidebarLinks, routingStructure));
  });

  useEffect(() => {
    onReady && onReady(sidebarLinks.length > 0);
  }, [sidebarLinks]);

  const collapseLink = (index: number, value) => {
    setSidebar(
      sidebarLinks.map((link, linkIndex) => {
        if (linkIndex === index) {
          if (link.subLinks) {
            return {
              ...link,
              subLinks: link.subLinks.map((subLink) => {
                if (subLink.collapsed === undefined) {
                  return subLink;
                } else {
                  return {
                    ...subLink,
                    collapsed: false,
                  };
                }
              }),
              collapsed: value !== undefined ? value : !link.collapsed,
            };
          } else {
            return {
              ...link,
              collapsed: value !== undefined ? value : !link.collapsed,
            };
          }
        } else {
          if (link.subLinks) {
            return {
              ...link,
              subLinks: link.subLinks.map((subLink) => {
                if (subLink.collapsed === undefined) {
                  return subLink;
                } else {
                  return {
                    ...subLink,
                    collapsed: false,
                  };
                }
              }),
              collapsed: false,
            };
          } else {
            return {
              ...link,
              collapsed: false,
            };
          }
        }
      })
    );
  };

  const collapseSubLink = (index: number, subLinkIndex: number) => {
    const sidebarLinksCopy = [...sidebarLinks];
    sidebarLinksCopy[index].subLinks = sidebarLinksCopy[index].subLinks.map((link, linkIndex) => {
      if (linkIndex === subLinkIndex) {
        return {
          ...link,
          collapsed: !link.collapsed,
        };
      } else {
        return {
          ...link,
          collapsed: false,
        };
      }
    });

    setSidebar(sidebarLinksCopy);
  };

  return (
    <>
      <ResponsiveLayout
        wideScreen={SidebarWeb}
        largeScreen={SidebarWeb}
        mobile={SidebarMobile}
        tablet={SidebarMobile}
        web={SidebarMobile}
        contextualProps={{ web: { noLogo: true } }}
        isSidebarOpen={isSidebarOpen}
        collapseLink={collapseLink}
        collapseSubLink={collapseSubLink}
        setSkipSeletectItems={(value) => {
          skipSeletectItems.current = value;
        }}
        goTo={(path, link) => {
          if (path) {
            dispatch(navigateTo({ key: path }));
            dispatch(toggleSidebar());
          } else {
            if (link) {
              window.open(link, '_blank');
            }
          }
        }}
        sidebarLinks={sidebarLinks}
      />
    </>
  );
};

export default Sidebar;
