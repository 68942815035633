/**
 * AssessmentChoice scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
 
import React, { useEffect } from 'react'
import styled from 'styled-components'

import { useDispatch, /*useSelector*/ } from 'react-redux'

import { actions } from 'store/rootSlices'

//import config from 'config'

const AssessmentChoice = ({ t, i18n }) => {

  //const { } = useSelector(state => state.AssessmentChoice)

  const { onMount, onUnmount} = actions.AssessmentChoice
  //const { api_t } = actions.App

  const dispatch = useDispatch()

  useEffect(() => {

    dispatch(onMount())
    return () => {
      dispatch(onUnmount())
    }
  }, [dispatch, onMount, onUnmount]);

  return (
    <Wrapper>
      AssessmentChoice
    </Wrapper>
  )
}

export default AssessmentChoice

const Wrapper = styled.div``