/**
 * ScheduleConfirmationPreview container utils
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { IUc } from 'shared/types';

export const filterUcsByPeriod = (ucs: IUc[], period: string) => {
  return ucs.map((eachUc) => {
    return {
      ...eachUc,
      classSchedule: eachUc.classSchedule.map((eachClass) => {
        if (eachClass.period !== period) {
          return { ...eachClass, filtered: true };
        } else {
          return { ...eachClass, filtered: false };
        }
      }),
    };
  });
};
