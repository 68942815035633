import axios from 'axios';

import mockStudent from 'api/schemas/mockStudent';

import Endpoints from 'api/endpoints';

import { IService } from 'config';
import { IUser } from 'app/logic/slice';

import { IApiCall } from '../index';

const getRegistrations = ({ service, user }: { service: IService; user: IUser }) => {
  //return mockStudent.getRegistrations;
  let params: any = {};
  let endpoint;

  if (service === 'sgh') {
    if (user?.nmec) {
      params.nmec = user.nmec;
    }

    endpoint = Endpoints.sgh.allStudentRegistrations;
  } else if (service === 'secVirtual') {
    endpoint = Endpoints.secVirtual.allStudentRegistrations;
  }

  return axios
    .get(endpoint, {
      params,
    })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export default {
  getRegistrations: {
    call: getRegistrations,
    scope: ['paco_2.0_sgh_get_registrations', 'paco_2.0_secvirtual_get_registrations'],
  } as IApiCall,
};
