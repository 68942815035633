import config from 'config';

const API_SCHEDULES = config.API_WSO2 + config.API_SCHEDULES_PATH;
//const API_SCHEDULES = config.API_BASE_URL + config.API_SCHEDULES_PATH_DIRECT;
//const API_SCHEDULES_DIRECT = config.API_BASE_URL + config.API_SCHEDULES_PATH_DIRECT;

const API_SEC_VIRTUAL = config.API_WSO2 + config.API_SEC_VIRTUAL_PATH;

const API_TRANSLATIONS = config.API_BASE_URL + config.API_TRANSLATIONS_PATH;
const API_STUDENT = (config.INTERNAL_API_BASE_URL ?? config.API_BASE_URL) + config.API_STUDENT;
const API_SEARCH = config.API_BASE_URL + config.API_SEARCH;

const API_PAUTAS_GRADES_MANAGEMENT = config.API_WSO2 + config.API_PAUTAS_PATH + 'grades-management';
const API_PAUTAS_GRADES_SIGNATURE = config.API_WSO2 + config.API_PAUTAS_PATH + 'grades-signature';

export default {
  signin: {
    superUser: `${API_STUDENT}rcu/gfuserbymailpaco2`,
    rcuGroups: `${API_STUDENT}rcu/gfuserbymailpaco2`,
    token: config.API_WSO2 + 'token',
    refresh: config.API_WSO2 + 'refresh',
    login: `${API_STUDENT}login`,
  },
  st: {
    translations: API_TRANSLATIONS + 'translations/?markdown=false',
    markdown: API_TRANSLATIONS + 'translations/?markdown=true',
  },
  student: {
    registration: `${API_STUDENT}students/registrations/`,
  },
  search: {
    functionalities: `${API_SEARCH}functionalities/`,
    functionalitiesSearch: `${API_SEARCH}functionalities/search`,
    functionalitiesHelp: `${API_SEARCH}functionalities/help`,
  },
  sgh: {
    allStudentRegistrations: `${API_SCHEDULES}Students/`,
    schedule: `${API_SCHEDULES}Schedule/`,
    studentSchedules: `${API_SCHEDULES}Schedules/`,
    freeOptions: `${API_SCHEDULES}FreeOptionsList/`,
    studentFreeOptions: `${API_SCHEDULES}FreeOptions/`,
    studentAccess: `${API_SCHEDULES}Phase/`,
    studentUcs: `${API_SCHEDULES}Ucs/`,
    studentSeriation: `${API_SCHEDULES}Students/`,
    phases: `${API_SCHEDULES}Phases/`,
    history: `${API_SCHEDULES}History/`,
    historyDetails: `${API_SCHEDULES}Seriation`,
    login: `${API_SCHEDULES}Login`,
  },
  secVirtual: {
    postCertAccessCodeWithRecaptcha: `${API_SEC_VIRTUAL}recaptcha`,
    allStudentRegistrations: `${API_SEC_VIRTUAL}registrations/`,
    studentCurricularPlan: `${API_SEC_VIRTUAL}curricular-plan/`,
    studentRegistrationSummary: `${API_SEC_VIRTUAL}curricular-plan/summary`,
    studentExtraCurricularUcs: `${API_SEC_VIRTUAL}curricular-plan/extracurricular-ucs/`,
    studentMobilityUcs: `${API_SEC_VIRTUAL}curricular-plan/mobility-ucs/`,
    studentAccreditedUcs: `${API_SEC_VIRTUAL}curricular-plan/accredited-ucs/`,
    studentModuleOptions: (ucCode) => `${API_SEC_VIRTUAL}curricular-plan/options-modules/${ucCode}`,
    getCurricularUnitDetails: (ucCode: number) =>
      `${API_SEC_VIRTUAL}curricular-plan/${ucCode}/grades`, // '/plano-curricular/:ucCode/notas?registrationId=:regid'
    personalDataForms: `${API_SEC_VIRTUAL}personal-data/`,
    personalDataPhoneNumber: `${API_SEC_VIRTUAL}personal-data/contacts`,
    certificateCodeValidation: (accessKey: string) =>
      `${API_SEC_VIRTUAL}requests/certificates/validate?accessKey=${accessKey}`,
    studentCardInfo: `${API_SEC_VIRTUAL}personal-data/card/`,
    studentPicture: `${API_SEC_VIRTUAL}personal-data/photo/`,
    addressForm: `${API_SEC_VIRTUAL}personal-data/address/`,
    contactsForm: `${API_SEC_VIRTUAL}personal-data/contacts/`,
    registration: (id: number) => `${API_SEC_VIRTUAL}registrations/${id}`,
    payments: `${API_SEC_VIRTUAL}payments/`,
    unpaidDebits: `${API_SEC_VIRTUAL}payments/unpaid-debits/`,
    pendingDebits: `${API_SEC_VIRTUAL}payments/pending-debits/`,
    getExamCalendar: `${API_SEC_VIRTUAL}calendar`,
    paymentsMbWay: `${API_SEC_VIRTUAL}payments/mb-way-ref`,
    paymentsMb: `${API_SEC_VIRTUAL}payments/mb-ref`,
    paymentMbInfo: (paymentRequestId: number) =>
      `${API_SEC_VIRTUAL}payments/mb-ref/${paymentRequestId}`,
    studentSignups: `${API_SEC_VIRTUAL}signups`,

    studentCertificatesList: `${API_SEC_VIRTUAL}requests/certificates/submitted/`,
    studentCertificatesDetails: (certificateId: number) =>
      `${API_SEC_VIRTUAL}requests/certificates/submitted/${certificateId}`,
    getCertificateTypes: `${API_SEC_VIRTUAL}requests/certificates/`,
    getCertificateForm: (id: number, registrationId: number) =>
      `${API_SEC_VIRTUAL}requests/certificates/${id}?registrationId=${registrationId}`,
    postCertificateForm: (registrationId: number) =>
      `${API_SEC_VIRTUAL}requests/certificates?registrationId=${registrationId}`,
    postCertificateOptions: (registrationId: number) =>
      `${API_SEC_VIRTUAL}requests/certificates/cost?registrationId=${registrationId}`,
    getCertificateChangeHistory: (certificateId: number, registrationId: number) =>
      `${API_SEC_VIRTUAL}requests/certificates/${certificateId}/access-history?registrationId=${registrationId}`,
    getStudentCertificateRequests: `${API_SEC_VIRTUAL}requests/certificates/submitted`,
    getStudentCertificateRequestsOnMountState: `${API_SEC_VIRTUAL}requests/certificates/onmountstate`,

    getApplicationTypes: API_SEC_VIRTUAL + 'requests/applications/',
    getApplicationForm: (id: number) => `${API_SEC_VIRTUAL}requests/applications/${id}`,
    postApplicationForm: `${API_SEC_VIRTUAL}requests/applications`,
    getStudentApplications: API_SEC_VIRTUAL + 'requests/applications/submitted',
    getApplicationDetails: (id: number) =>
      `${API_SEC_VIRTUAL}requests/applications/submitted/${id}`,
  },
  files: {
    postFiles: (studentId: number, registrationId: number) =>
      `${API_SEC_VIRTUAL}files?studentId=${studentId}&registrationId=${registrationId}`,
    getFile: (fileType: string, fileId: string, expires: string, signature: string) =>
      `${API_SEC_VIRTUAL}files/${fileType}/${fileId}/?expires=${expires}&signature=${signature}`,
    getFileEndpoint: `${API_SEC_VIRTUAL}files/test`,
  },
  pautas: {
    professorGradesInfo: API_PAUTAS_GRADES_MANAGEMENT,
    gradesStatistics: `${API_PAUTAS_GRADES_MANAGEMENT}/statistics`,
    gradeDetails: (codigoPauta: string) => `${API_PAUTAS_GRADES_MANAGEMENT}/${codigoPauta}`,
    gradeHistoryInfo: (codigoPauta: string) =>
      `${API_PAUTAS_GRADES_MANAGEMENT}/${codigoPauta}/history-pauta`,
    gradeDecryptedInfo: (codigoPauta: string) =>
      `${API_PAUTAS_GRADES_MANAGEMENT}/${codigoPauta}/decrypted-pauta`,
    saveIntermediateGrade: (codigoPauta: string) =>
      `${API_PAUTAS_GRADES_MANAGEMENT}/${codigoPauta}/save-intermediate-pauta`,
    submitGrade: (codigoPauta: string) =>
      `${API_PAUTAS_GRADES_MANAGEMENT}/${codigoPauta}/submit-pauta`,
    uploadGradeFile: (codigoPauta, uniqueIdentifierColumn, gradeColumn, hasHeader, sheetNumber) =>
      `${API_PAUTAS_GRADES_MANAGEMENT}/${codigoPauta}/upload-pauta?uniqueIdentifierColumn=${uniqueIdentifierColumn}
      &gradeColumn=${gradeColumn}&hasHeader=${hasHeader}&sheetNumber=${sheetNumber}`,
    gradePdf: (id: number, signed: boolean) =>
      `${API_PAUTAS_GRADES_SIGNATURE}/download-pdf/${id}/${signed}`,
  },
};
