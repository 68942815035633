// useSignalRConnection.ts

import { useState, useEffect, useRef } from 'react';
import config from 'config';
import * as signalR from "@microsoft/signalr";

const useSignalRConnection = () => {
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);
    const connectionInitialized = useRef(false);
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const initiateConnection = async () => {
        if (connectionInitialized.current) {
            console.warn("Connection is already being initialized. No need to re-initiate.");
            return connection;
        }

        connectionInitialized.current = true;


        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            console.warn("Connection is already established. No need to reconnect.");
            return connection;
        }

        // Create a new SignalR connection
        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(`${config.API_BASE_URL + config.API_PAUTAS_WEBSOCKET}signingHub`, { accessTokenFactory: () => user.idToken })
            .build();

        try {
            await newConnection.start();
            console.log("SignalR connection established. Connection ID:", newConnection.connectionId);
        } catch (err) {
            console.error("Error while establishing connection:", err);
        }

        setConnection(newConnection);
        return newConnection;
    };

    useEffect(() => {
        return () => {
            if (connection && connection.state === signalR.HubConnectionState.Connected) {
                connection.stop()
                    .then(() => console.log("Connection closed on hook cleanup"))
                    .catch(err => console.error("Error while closing the connection on hook cleanup:", err));
            }
        };
    }, [connection]);

    return {
        connection,
        initiateConnection
    };
};

export default useSignalRConnection;
