/**
 * Schedules scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';
import { Grid } from 'semantic-ui-react';
import { withSize } from 'react-sizeme';
import { useForm } from 'react-hook-form';
import numeral from 'numeral';

import { t } from 'app';

import styled, { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExchange,
  faTable,
  faList,
  faUsers,
  faInfoCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';

import ScheduleConfirmationPreview from './containers/ScheduleConfirmationPreview';
import ScheduleGridView from './containers/ScheduleGridView';
import ScheduleListView from './containers/ScheduleListView';
import SelectedClasses from './containers/SelectedClasses';
import MandatoryOptionsDropdown from './containers/MandatoryOptionsDropdown';
import FreeOptionsDropdown from './containers/FreeOptionsDropdown';
import ClassCardDetails from './containers/ClassCardDetails';
import SchedulesSystemHelp from './containers/SchedulesSystemHelp';

import CaptionSchedule from 'scenes/Schedules/containers/CaptionSchedule';

import StudentRegistrationDropdown from 'shared/containers/StudentRegistrationDropdown';
import MobileSupportModal from 'shared/containers/MobileSupportModal';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import {
  Tooltip,
  Modal,
  MultiToggle,
  Button,
  Text,
  RestrictAccessOverlay,
  NoDataMessage,
  ResponsiveLayout,
  StatusLabel,
  FormInput,
} from '@paco_ua/pacoui';

import { getCurrentRoute } from 'utils';
import { getSelectedClasses, compareUcs } from './utils';

interface ISchedule {
  size: any;
}

const Schedules: React.FC<ISchedule> = ({ size }) => {
  const {
    restricted,
    restrictionMotive,
    currentMode,
    scheduleToEdit,
    ucs,
    originalEditingUcs,
    scheduleName,
    editScheduleName,
    exchangeUcs,
    isCurrentPhaseActive,
    currentPhase,
    period,
    periods,
    studentTotalChoices,
    scheduleType,
    confirmationModalIsOpen,
    disclaimerMobileModalIsOpen,
    loadingRestriction,
    loadingSchedule,
    savingSchedule,
    editingSchedule,
  } = useSelector(selectors.getSchedulesSlice);

  const currentRegistrationId = useSelector(selectors.getRegistration);

  const dispatch = useDispatch();
  const theme = useContext(ThemeContext);
  const ScheduleNameForm = useForm();

  const {
    onMount,
    getAccessAndUcs,
    setPeriod,
    setScheduleType,
    deleteSchedule,
    setScheduleName,
    setEditScheduleName,
    setDisclaimerMobileModalIsOpen,
    handleConfirmationModalOpening,
    submitSchedule,
    editSchedule,
    getStudentTotalChoices,
    clearSchedule,
    setRestriction,
    setLoadingRestriction,
  } = actions.Schedules;

  const { navigateTo } = actions.App;
  const { showToaster } = actions.Toaster;

  const registrations = useSelector(selectors.getRegistrations);

  const periodButtons = periods.map((periodItem, key) => ({
    children: `${t('sgh.period', { textOnly: true })} ${key + 1}`,
    onClick: () => dispatch(setPeriod(periodItem)),
    selected: period === periodItem,
    testId: 'schedules_multitoggle_period',
  }));

  const scheduleActionButtons = [
    /*...currentPhase?.isFree
      ? [
          {
            children: (
              <Tooltip
                content={t('sgh.tooltipExchanges')}
                trigger={<FontAwesomeIcon icon={faExchange} />}
              />
            ),
            onClick: () => dispatch(setScheduleType('gridExchange')),
            selected: scheduleType === 'gridExchange',
            testId: 'schedules_multitoggle_grid_exchange',
          },
        ]
      : */ ...[],
    {
      children: (
        <Tooltip content={t('sgh.tooltipGrid')} trigger={<FontAwesomeIcon icon={faTable} />} />
      ),
      onClick: () => dispatch(setScheduleType('grid')),
      selected: scheduleType === 'grid',
      testId: 'schedules_multitoggle_grid',
    },
    {
      children: (
        <Tooltip content={t('sgh.tooltipList')} trigger={<FontAwesomeIcon icon={faList} />} />
      ),
      onClick: () => dispatch(setScheduleType('list')),
      selected: scheduleType === 'list',
      testId: 'schedules_multitoggle_list',
    },
  ];

  const selectedClasses = getSelectedClasses(ucs);
  //check if no ucs and no classes at all
  const noClasses = !ucs.length || !ucs.some((uc) => !!uc.classSchedule.length);

  // Scene in view mode?
  const viewMode = currentMode === 'view';
  // Scene in view mode?
  const createMode = currentMode === 'create';
  // Scene in edit mode?
  const editMode = currentMode === 'edit';
  // Scene in duplicate mode?
  const duplicateMode = currentMode === 'duplicate';

  // Active phase and not finished
  const hasActivePhase =
    currentPhase?.phase !== null && currentPhase?.phase !== undefined && !currentPhase?.finished && isCurrentPhaseActive;
  // Active phase and finished
  const hasFinishedActivePhase =
    ((currentPhase?.phase === null && !currentPhase?.seriationFinished) ||
      (currentPhase?.phase !== null && currentPhase?.phase !== undefined)) &&
    currentPhase?.finished;

  // Hooks
  useEffect(() => {
    dispatch(onMount());
  }, [dispatch, onMount]);

  useEffect(() => {
    if (registrations !== null && !registrations?.length) {
      dispatch(setRestriction(true));
      dispatch(setLoadingRestriction(false));
    }
  }, [registrations]);

  useEffect(() => {
    if (currentRegistrationId) {
      dispatch(getAccessAndUcs());
    }
  }, [dispatch, currentRegistrationId]);

  // Hooks
  useEffect(() => {
    dispatch(getStudentTotalChoices(ucs));
  }, [ucs]);

  const getScheduleName = () => {
    if (editMode) {
      if (scheduleToEdit) {
        return scheduleName;
      } else {
        return t('sgh.mySchedule', { textOnly: true });
      }
    } else {
      return scheduleName;
    }
  };

  const getScheduleToEditLabel = (label) => {
    if (label === 'automatic') {
      return (
        <EditScheduleLabel>
          <StatusLabel
            background={theme.colors.primary}
            label={t('sgh.automaticSeriation', { textOnly: true })}
          />
        </EditScheduleLabel>
      );
    } else if (label === 'assigned') {
      return (
        <EditScheduleLabel>
          <StatusLabel
            background={theme.colors.successGreen}
            label={t('sgh.manualSeriation', { textOnly: true })}
          />
        </EditScheduleLabel>
      );
    } else if (label === 'proposal') {
      return (
        <EditScheduleLabel>
          <StatusLabel label={t('sgh.createdSchedulesLabelProposal', { textOnly: true })} />
        </EditScheduleLabel>
      );
    } else if (label === 'notAssigned') {
      return (
        <EditScheduleLabel>
          <StatusLabel
            background={theme.colors.dangerRed}
            label={t('sgh.notPlaced', { textOnly: true })}
          />
        </EditScheduleLabel>
      );
    }
  };

  const getScheduleNameContainer = () => {
    if (!currentPhase?.phase) {
      if (currentPhase?.hasAllocatedSchedule) {
        if (currentPhase?.year) {
          return `${t('sgh.mySchedule', { textOnly: true })} ${currentPhase.year}/${
            parseInt(currentPhase.year) + 1
          }`;
        } else {
        }
        return `${t('sgh.mySchedule', { textOnly: true })}`;
      } else {
        return t('sgh.mySchedule', { textOnly: true });
      }
    } else {
      if (currentPhase?.finished || viewMode || restricted) {
        if (currentPhase?.year) {
          return (
            <>
              {`${t('sgh.mySchedule', { textOnly: true })} ${currentPhase.year}/${
                parseInt(currentPhase.year) + 1
              }`}{' '}
              <Phase>{` - ${numeral(currentPhase.period).format('o')} ${t('generic.semester', {
                textOnly: true,
              })}`}</Phase>
            </>
          );
        } else {
          return `${t('sgh.mySchedule', { textOnly: true })}`;
        }
      } else if (!currentPhase?.finished) {
        if (restricted || noClasses) {
          return (
            <>
              {`${t('sgh.mySchedule', { textOnly: true })} ${
                currentPhase.year && `${currentPhase.year}/${parseInt(currentPhase.year) + 1}`
              }`}{' '}
              <Phase>{` - ${t('sgh.phase', {
                textOnly: true,
              })} ${currentPhase?.phase}`}</Phase>
            </>
          );
        }
        return (
          <ScheduleNameWrapper>
            {editScheduleName && (
              <FormInput
                register={ScheduleNameForm.register('scheduleName', {
                  required: t('forms.validationFieldRequired'),
                  maxLength: {
                    value: 30,
                    message: t('forms.validationFieldMaxLength_count', { count: 30 }),
                  },
                  minLength: {
                    value: 3,
                    message: t('forms.validationFieldMinLength_count', { count: 3 }),
                  },
                  pattern: {
                    value: /^[A-Za-zÀ-ÖØ-öø-ÿ0-9\s]*$/,
                    message: t('sgh.formValidationFieldCreatedSchedulePattern'),
                  },
                })}
                defaultValue={getScheduleName()}
                autoFocus
                border
                placeholder={t('forms.fieldNamePlaceholder', { textOnly: true })}
                decisionActions
                onChangeClick={() => {
                  ScheduleNameForm.handleSubmit((formData) => {
                    dispatch(setScheduleName(formData.scheduleName));
                    dispatch(setEditScheduleName(false));
                  })();
                }}
                onCancel={() => {
                  dispatch(setEditScheduleName(false));
                }}
                error={
                  ScheduleNameForm.formState.errors.scheduleName &&
                  ScheduleNameForm.formState.errors.scheduleName.message
                }
              />
            )}
            {!editScheduleName && (
              <>
                <Tooltip
                  content={t('sgh.tooltipEditScheduleName')}
                  trigger={
                    <Button
                      data-testid="my_schedule_edit_schedule_name"
                      onClick={() => dispatch(setEditScheduleName(true))}
                      borderless
                    >
                      <FontAwesomeIcon icon={faPen} color={theme.colors.plusDarkGrey} />
                    </Button>
                  }
                />
                {getScheduleName()}
                &nbsp;
                <Phase>{` - ${t('sgh.phase', {
                  textOnly: true,
                })} ${currentPhase?.phase}`}</Phase>
              </>
            )}

            {getScheduleToEditLabel(scheduleToEdit?.state)}
          </ScheduleNameWrapper>
        );
      }
    }
  };

  const handleAddSchedule = () => {
    if (!selectedClasses.length) {
      dispatch(
        showToaster({
          title: t('sgh.alertIncompleteSchedule'),
          body: t('sgh.alertIncompleteScheduleDetails'),
          icon: 'info',
          type: 'info',
        })
      );
    } else {
      dispatch(handleConfirmationModalOpening(true));
    }
  };

  const handleModalSubmission = () => {
    dispatch(handleConfirmationModalOpening(false));
    if (editMode) {
      const scheduleIdToEdit = getCurrentRoute().params.id;
      dispatch(editSchedule(scheduleIdToEdit));
    } else {
      dispatch(submitSchedule());
    }
  };

  const handleModalOnCloseOrOnCancel = () => {
    dispatch(handleConfirmationModalOpening(false));
  };

  const getRestrictionStatus = () => {
    if (restricted) {
      return 'restricted';
    } else if (restricted === false) {
      return 'allowed';
    } else {
      return '';
    }
  };

  const checkSubmitButtonDisabled = () => {
    if (editMode) {
      return compareUcs(ucs, originalEditingUcs);
    }

    if (createMode || duplicateMode) {
      return !selectedClasses.some((classItem) => classItem?.allocated?.state === 'notAssigned');
    }
    return false;
  };

  const getMainContent = () => {
    if (restricted) {
      return (
        <RestrictAccessOverlay
          restrictionStatus={getRestrictionStatus()}
          restrictionMotive={restrictionMotive ? t(restrictionMotive) : ''}
          onComplete={() => {}}
        />
      );
    }

    if (!loadingRestriction) {
      if (!loadingSchedule) {
        // VIEW MODE
        if (viewMode) {
          // ON ACTIVE PHASE, NOT FINISHED AND NO ALLOCATED SCHEDULE
          if (hasActivePhase && !currentPhase?.hasAllocatedSchedule) {
            return (
              <NoDataMessage
                header={t('sgh.warningViewModeActivePhaseNoScheduleHeader')}
                body={t('sgh.warningViewModeActivePhaseNoScheduleBody')}
              />
            );
          }

          if (hasActivePhase && noClasses) {
            return (
              <NoDataMessage header={t('sgh.noScheduleHeader')} body={t('sgh.noScheduleBody')} />
            );
          }
        }

        // ALL MODES (VIEW | CREATE | DUPLICATE | EDIT)
        // ON ACTIVE PHASE, FINISHED AND SERIATION NOT FINISHED
        if (hasFinishedActivePhase && !currentPhase?.seriationFinished) {
          return (
            <NoDataMessage header={t('sgh.finishedOnePhaseHeader')} body={t('sgh.pleaseWait')} />
          );
        }

        // ON ACTIVE PHASE AND SERIATION FISINHED
        if (hasFinishedActivePhase && currentPhase?.seriationFinished) {
          // SCHEDULE NOT SENT NOT ALLOCATED TO STUDENT
          if (!currentPhase?.attemptedSchedule && !currentPhase?.hasAllocatedSchedule) {
            return (
              <NoDataMessage
                header={t('sgh.scheduleProcessFinished')}
                body={t('sgh.scheduleNotSent')}
              />
            );
          }

          // SCHEDULE SENT BUT NOT ALLOCATED
          if (currentPhase?.attemptedSchedule && !currentPhase?.hasAllocatedSchedule) {
            return (
              <NoDataMessage
                header={t('sgh.scheduleNotAssigned')}
                body={t('sgh.scheduleProcessFinished')}
              />
            );
          }
        }

        // ON PHASE (END|NOT STARTED) AND NO ALLOCATED SCHEDULE
        if (
          (currentPhase?.phase === null || currentPhase?.phase === undefined) &&
          !currentPhase?.hasAllocatedSchedule
        ) {
          //ON PHASE END
          if (currentPhase?.finished) {
            return (
              <NoDataMessage
                header={t('sgh.scheduleNotAssigned')}
                body={t('sgh.scheduleProcessFinished')}
              />
            );
            //PHASE DIDN'T START
          } else {
            return (
              <NoDataMessage
                header={t('sgh.scheduleProcessNotStarted')}
                body={t('sgh.pleaseWait')}
              />
            );
          }
        }

        // ON ACTIVE PHASE, NOT FINISHED AND NO CLASSES
        if (hasActivePhase && noClasses && !currentPhase?.isFree) {
          return <NoDataMessage header={t('sgh.noClassesHeader')} body={t('sgh.noClassesBody')} />;
        }

        // the student has an empty schedule
        if (
          selectedClasses.length === 0 &&
          !createMode &&
          !editMode &&
          !duplicateMode &&
          !currentPhase?.isFree
        ) {
          return (
            <NoDataMessage header={t('sgh.noScheduleHeader')} body={t('sgh.noScheduleBody')} />
          );
        }
      }

      return (
        <>
          <TopScheduleControllers>
            <PeriodButtonsWrapper>
              <MultiToggle buttons={periodButtons} content={'buttons'} scrollableAtWidth={280} />
            </PeriodButtonsWrapper>

            {!viewMode && !currentPhase?.isFree && <MandatoryOptionsDropdown />}

            {currentPhase?.isFree && !viewMode && <FreeOptionsDropdown />}

            <ScheduleActionButtonsWrapper>
              <MultiToggle buttons={scheduleActionButtons} content={'icons'} />
            </ScheduleActionButtonsWrapper>
          </TopScheduleControllers>

          {(scheduleType === 'grid' || scheduleType === 'gridExpanded') && (
            <ScheduleGridView ucs={ucs} />
          )}
          {scheduleType === 'list' && <ScheduleListView />}
          {(!noClasses || currentPhase?.isFree) && hasActivePhase && (
            <CaptionAndActionButtons padding={scheduleType === 'grid' ? 63 : 18}>
              <Grid>
                <Grid.Column mobile={16} computer={10} largeScreen={10}>
                  {scheduleType !== 'list' && (
                    <CaptionSchedule
                      ucs={ucs}
                      hideSmiles={!!currentPhase?.hasAllocatedSchedule && viewMode}
                    />
                  )}
                </Grid.Column>

                <Grid.Column mobile={16} computer={6} largeScreen={6}>
                  {!viewMode && (
                    <ActionButtons>
                      <Button
                        decision
                        onClick={() => dispatch(clearSchedule())}
                        data-testid="schedules_button_cancel_schedule"
                      >
                        {t('generic.clear')}
                      </Button>
                      <Button
                        success
                        loading={savingSchedule || editingSchedule}
                        disabled={checkSubmitButtonDisabled()}
                        onClick={() => handleAddSchedule()}
                        data-testid="schedules_button_add_schedule"
                      >
                        {editMode
                          ? t('generic.saveElement', {
                              what: t('generic.change_plural', { textOnly: true }),
                            })
                          : t('generic.addElement', {
                              what: t('sgh.schedule', { textOnly: true }),
                            })}
                      </Button>
                    </ActionButtons>
                  )}
                </Grid.Column>
              </Grid>
            </CaptionAndActionButtons>
          )}
        </>
      );
    }
  };

  const getSecondaryContent = () => {
    if (restricted || size.width < 1055) {
      return null;
    }

    //quando fase terminar e enquando nao termina a seriação ele não terá acesso ao horario
    if (!viewMode) {
      if (!currentPhase?.isFree && noClasses) {
        return null;
      }
    } else {
      if (!currentPhase?.hasAllocatedSchedule && selectedClasses.length === 0) {
        return null;
      }

      if (hasFinishedActivePhase && currentPhase?.seriationFinished === false) {
        return null;
      }

      if (noClasses) {
        return null;
      }
    }

    return (
      <>
        {(currentPhase?.isFree || !noClasses) &&
          selectedClasses.length === 0 &&
          !loadingSchedule &&
          !loadingRestriction && (
            <NoSelectedClassesHelp>
              <NoSelectedClassesHelpIcon>
                <FontAwesomeIcon icon={faInfoCircle} />
              </NoSelectedClassesHelpIcon>
              <Text size={'article'} color={theme.colors.plusDarkGrey}>
                {t('sgh.noSelectedClasses')}
              </Text>
            </NoSelectedClassesHelp>
          )}
        {selectedClasses.length > 0 && (
          <SelectedClasses
            icon={<FontAwesomeIcon icon={faUsers} />}
            title={t('sgh.myClass_plural')}
            selected={selectedClasses.length}
            seriationFinished={currentPhase?.phase && currentPhase?.finished ? true : false}
            total={studentTotalChoices}
          >
            {selectedClasses
              .sort((a, b) => (b.selectedAt || 0) - (a.selectedAt || 0))
              .map((selectedClass, index) => {
                return (
                  <ClassCardDetails
                    key={`ClassCardDetails-${index}`}
                    ranking={{
                      total: selectedClass.selectedStudents,
                      above: selectedClass.studentsHigherRanking,
                      below: selectedClass.studentsLowerRanking,
                      freeSlots: selectedClass.freeSlots,
                    }}
                    overlaps={selectedClass.overlaps}
                    classItem={{
                      ucId: selectedClass.ucId,
                      ucInitials: selectedClass.ucInitials,
                      ucFullName: selectedClass.ucFullName,
                      color: selectedClass.ucColor,
                      classesDays: selectedClass.classesDays,
                      classType: selectedClass.classType,
                      className: selectedClass.className,
                      classId: selectedClass.classId,
                      locked: selectedClass.locked,
                      allocated: selectedClass.allocated,
                      slots: selectedClass.slots,
                    }}
                    seriationFinalResult={selectedClass.seriationTries}
                  />
                );
              })}
          </SelectedClasses>
        )}
      </>
    );
  };

  return (
    <>
      {!viewMode && (
        <Modal
          type={'confirmation'}
          open={confirmationModalIsOpen}
          header={editMode ? t('sgh.confirmationUpdateSchedule') : t('sgh.confirmationAddSchedule')}
          border
          cancelButtonText={t('generic.cancel')}
          submitButtonText={editMode ? t('generic.update') : t('generic.add')}
          onClose={() => handleModalOnCloseOrOnCancel()}
          onCancel={() => handleModalOnCloseOrOnCancel()}
          onSubmit={() => handleModalSubmission()}
          data-testid="schedules_modal_confirmation"
        >
          <ScheduleConfirmationPreview />
        </Modal>
      )}
      <ResponsiveLayout
        mobile={() => (
          <Modal
            open={disclaimerMobileModalIsOpen}
            type="simple"
            onClose={() => dispatch(setDisclaimerMobileModalIsOpen(false))}
          >
            <MobileSupportModal onEnter={() => dispatch(setDisclaimerMobileModalIsOpen(false))} />
          </Modal>
        )}
        web={() => null}
      />
      {!viewMode && hasActivePhase && (
        <SchedulesSystemHelp shouldHide={loadingRestriction && loadingSchedule} />
      )}
      <PageStructure
        loading={loadingRestriction || loadingSchedule}
        expanded
        headerLeft={
          <Header
            title={
              <Title size="xLarge" weight="regular" color="plusDarkGrey">
                <Name editMode={editMode}>{getScheduleNameContainer()}</Name>
              </Title>
            }
            backButton={!viewMode ? true : false}
          />
        }
        headerRight={
          <TopRightButtonWrapper>
            {(editMode || duplicateMode) && hasActivePhase && !restricted && (
              <>
                <Button
                  action
                  onClick={() => {
                    dispatch(navigateTo({ key: 'createdSchedules' }));
                  }}
                  data-testid="schedules_edit_button_cancel"
                >
                  {t('generic.cancel')}
                </Button>
                {editMode && (
                  <Button
                    action
                    leftIcon={<FontAwesomeIcon icon={faTrash} />}
                    onClick={() => dispatch(deleteSchedule(getCurrentRoute().params.id))}
                    data-testid="schedules_edit_button_remove"
                  >
                    {t('sgh.deleteSchedule')}
                  </Button>
                )}
              </>
            )}
            {viewMode &&
              hasActivePhase &&
              !restricted &&
              currentPhase?.hasAllocatedSchedule &&
              selectedClasses.length < studentTotalChoices && (
                <Button
                  action
                  onClick={() => dispatch(navigateTo({ key: 'createSchedule' }))}
                  data-testid="schedules_view_button_complete"
                >
                  {t('sgh.completeSchedule')}
                </Button>
              )}
          </TopRightButtonWrapper>
        }
        subHeader={
          <StudentRegistrationDropdownWrapper hidden={editScheduleName}>
            <StudentRegistrationDropdown
              loading={loadingRestriction || loadingSchedule}
              onChange={() => {
                dispatch(getAccessAndUcs());
              }}
              disabled={editMode || (loadingRestriction && loadingSchedule && !restricted)}
              data-testid="schedules_dropdown_registration"
            />
          </StudentRegistrationDropdownWrapper>
        }
        mainContent={getMainContent()}
        secondaryContent={getSecondaryContent()}
      />
    </>
  );
};

export default withSize()(Schedules);

const Title = styled(Text)`
  display: flex;
  align-items: center;
`;

const ScheduleNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Name = styled.span<{ editMode: boolean }>`
  margin-left: 0;
`;

const Phase = styled.span`
  text-transform: lowercase;
`;

const TopRightButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button:not(:last-child) {
    margin-right: 20px;
  }
`;

const TopScheduleControllers = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  margin-bottom: 36px;

  &:after {
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    right: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const PeriodButtonsWrapper = styled.div``;

const ScheduleActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;

const CaptionAndActionButtons = styled.div<{ padding: number }>`
  padding-left: ${({ padding }) => padding}px;
`;

const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  > button:first-child {
    margin-right: 22px;
  }
`;

const NoSelectedClassesHelp = styled.div`
  padding: 24px 22px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  box-shadow: ${({ theme }) => theme.shadows.medium};
  white-space: pre-line;
`;

const NoSelectedClassesHelpIcon = styled.div`
  margin-bottom: 22px;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.primary};
`;

const EditScheduleLabel = styled.div`
  margin-left: 12px;
`;

const StudentRegistrationDropdownWrapper = styled.div<{ hidden?: boolean }>`
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
`;
