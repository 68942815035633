/**
 * CertificateValidator scene slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ICertificateDetails {
  certificateData: any;
  hasValidated: boolean;
  loadingStatus: {
    loadingCertificateValidation: boolean;
  };
  errorStatus: {
    errLoadingCertificateValidation: boolean;
  };
}

const initialState = {
  certificateData: undefined as any,
  hasValidated: false,
  loadingStatus: {
    loadingCertificateValidation: false,
    loadingCertificateFile: false,
  },
  errorStatus: {
    errLoadingCertificateValidation: false,
  },
};

export default createSlice({
  name: 'CertificateValidator',
  initialState,
  reducers: {
    onMount: () => {},
    setCertificateData: (state, action: PayloadAction<any>) => {
      state.certificateData = action.payload;
    },
    setHasValidated: (state, action: PayloadAction<boolean>) => {
      state.hasValidated = action.payload;
    },
    validateCertificateCode: (
      state,
      action: PayloadAction<{
        recaptchaToken: string;
        accessCode: string;
      }>
    ) => {},
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    reset: (state) => initialState,
    onUnmount: () => {},
  },
});
