/**
 * Registrations scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { useSelector, useDispatch } from 'react-redux';
import * as selectors from './logic/selectors';
import { actions } from 'store/rootSlices';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';

import { Text, Table, StatusLabel } from '@paco_ua/pacoui';

import { getEligibilityState } from './utils';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

//import config from 'config'

const Registrations = ({ t }) => {
  const theme = useContext(ThemeContext);

  const { navigateTo } = actions.App;
  const { onMount, setLoading, onUnmount } = actions.Registrations;

  const dispatch = useDispatch();

  const { loading, errorStatus } = useSelector(selectors.getRegistrationsSlice);

  const user = useSelector(selectors.getUser);

  useEffect(() => {
    dispatch(onMount());
    return () => {
      dispatch(onUnmount());
    };
  }, [dispatch, onMount, onUnmount, user.impersonate]);

  /*useEffect(() => {
    if (user.registrations !== null && user.registrations !== undefined) {
      dispatch(setLoading(false));
    }
  }, [user.registrations]);*/

  const getEligibilityColor = (status: number) => {
    if (status === 100) {
      return theme.colors.dangerRed;
    } else if (status === 1 || status === 2) {
      return theme.colors.successGreen;
    } else if (status === 25 || status === 50) {
      return theme.colors.decisionYellow;
    } else if (status === -1) {
      return theme.colors.grey;
    }
  };

  const getTablestructure = () => {
    return {
      header: {
        type: 'columnTitle',
        titles: [
          {
            text: t('registrations.registrationTableTitleCourse', { textOnly: true }),
            style: {
              fontWeight: 400,
            },
          },
          {
            text: t('registrations.registrationTableTitleBranch', { textOnly: true }),
            style: {
              fontWeight: 400,
            },
          },
          {
            text: t('registrations.registrationTableTitleYear', { textOnly: true }),
            style: {
              fontWeight: 400,
              textAlign: 'center',
            },
          },
          {
            text: t('registrations.registrationTableRegistrationState', { textOnly: true }),
            style: {
              fontWeight: 400,
              textAlign: 'center',
            },
          },
          {
            text: t('registrations.registrationTablePrescription', { textOnly: true }),
            style: {
              fontWeight: 400,
              textAlign: 'center',
            },
          },
          { text: '' },
        ],
      },
      rows: !user.registrations
        ? []
        : user.registrations.map((registration) => {
            return {
              rowProps: {
                navigatable: true,
                onClick: () =>
                  dispatch(
                    navigateTo({
                      key: 'registrationdetails',
                      params: { id: registration.registrationId },
                    })
                  ),
                color: theme.colors.white,
              },
              cells: [
                {
                  content: (
                    <Text size="article" weight={'bold'} color={theme.colors.primary}>
                      {`${registration.courseCode}-${registration.courseName}`}
                    </Text>
                  ),
                  cellProps: {
                    width: 7,
                    singleLine: false,
                    style: {
                      whiteSpace: 'break-space',
                      minWidth: '100px',
                    },
                  },
                },
                {
                  content: (
                    <Text size="article" weight={'regular'}>
                      {registration.branchName}
                    </Text>
                  ),
                  cellProps: {
                    width: 3,
                    style: {
                      whiteSpace: 'break-space',
                      minWidth: '100px',
                    },
                  },
                },
                {
                  content: registration.academicYear ? (
                    <Text size="article" weight={'regular'}>
                      {registration.academicYear}
                    </Text>
                  ) : (
                    <FontAwesomeIcon icon={faEllipsisH} />
                  ),
                  cellProps: { width: 2, singleLine: true, textAlign: 'center' },
                },
                {
                  content: (
                    <Text size="article" weight={'regular'}>
                      {`${t('enrollmentState.' + registration.registrationState, {
                        textOnly: true,
                      })}`}
                    </Text>
                  ),
                  cellProps: {
                    width: 1,
                    singleLine: true,
                    textAlign: 'center',
                  },
                },
                {
                  content: registration.eligibilityState ? (
                    <StatusLabelWrapper>
                      <StatusLabel
                        label={getEligibilityState(registration.eligibilityState)}
                        background={getEligibilityColor(registration.eligibilityState)}
                        hideTooltip
                      />
                    </StatusLabelWrapper>
                  ) : (
                    <FontAwesomeIcon icon={faEllipsisH} />
                  ),
                  cellProps: {
                    width: 1,
                    singleLine: true,
                    textAlign: 'center',
                  },
                },
                {
                  content: <FontAwesomeIcon icon={faChevronRight} />,
                  cellProps: {
                    width: 1,
                    singleLine: true,
                  },
                },
              ],
              collapsableRows: [],
            };
          }),
    };
  };
  return (
    <PageStructure
      loading={
        loading || errorStatus.errLoadingRegistrations || getTablestructure().rows.length <= 0
      }
      headerLeft={<Header title={t('registrations.registrationTitle')} />}
      subHeader={
        <Text color="darkGrey" size="article" weight={'regular'}>
          {t('registrations.description', { textOnly: true })}&nbsp;
          <Text
            as="a"
            href={t('links.registrationsSGA', { textOnly: true })}
            weight="regular"
            target="_blank"
            color="primary"
          >
            Serviços de Gestão Académica
          </Text>
        </Text>
      }
      mainContent={
        <>
          <TableWrapper>
            <Table structure={getTablestructure()} navigatable unstackable />
          </TableWrapper>
        </>
      }
    />
  );
};

export default Registrations;

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;

  &&& th {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 61px;
  }

  &&& td {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    height: 76px;
  }
`;

const StatusLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & div {
    :hover {
      cursor: pointer;
    }
  }
`;
