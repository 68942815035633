import { RootState } from 'store/rootSlices';

export const getPautaData = (state: RootState) => state.GradesDetails.pautaData;
export const getDataExame = (state: RootState) => state.GradesDetails.dataExame;
export const getGradeCounts = (state: RootState) => state.GradesDetails.gradeCounts;
export const getGradeDetailsSlice = (state: RootState) => state.GradesDetails;
export const getPdfUrl = (state: RootState) => state.GradesDetails.pdfUrl;
export const getIsPdfReady = (state: RootState) => state.GradesDetails.isPdfReady;
export const isPdfModalOpen = (state: RootState) => state.GradesDetails.isPdfModalOpen;
export const getCurrentLocale = (state: RootState) => state.App.currentLocale;

