/**
 * ExamsCalendar scene utils
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import moment from 'moment';
import ical2json from 'ical2json';

const setSummaryKey = (label) => {
  switch (label) {
    case 'Exam':
      return 'ex';
    case 'Project':
      return 'pr';
    case 'alternativeExam':
      return 'al';
    case 'Sign-ups':
      return 'si';
    case 'm':
      return 'm';
    default:
      return label.substring(0, 2);
  }
};

export const reformatCalendarEvents = (calendarData) => {
  if (!ical2json.convert(calendarData).VCALENDAR[0].hasOwnProperty('VEVENT')) {
    return [];
  }

  return ical2json.convert(calendarData).VCALENDAR[0].VEVENT.reduce((acc, event, key) => {
    const startDate = event['DTSTART'] || event['DTSTART;VALUE=DATE'];

    if (startDate) {
      return [
        ...acc,
        {
          id: key,
          uuid: event.UID,
          title: event.DESCRIPTION,
          desc: event.DESCRIPTION,
          start: moment(startDate).toDate(),
          //end: moment(event.DTEND).toDate(),
          end: moment(startDate).toDate(),
          icon: setSummaryKey(event.SUMMARY),
          location: event.LOCATION,
        },
      ];
    }

    return acc;
  }, []);
};

export const setEventData = (eventData, getIcon, getFooter) =>
  eventData.map((event) => ({
    ...event,
    icon: getIcon(event.icon),
    popupFooter: getFooter(event),
  }));

export const convertEventToIcs = (event, currentICS) => {
  let fullEvent = ical2json.convert(currentICS);
  fullEvent.VCALENDAR[0].VEVENT = fullEvent.VCALENDAR[0].VEVENT.filter(
    (filteredEvent) => filteredEvent.UID === event.uuid
  );
  return ical2json.revert(fullEvent);
};
