import { put, call, takeLatest } from 'redux-saga/effects';
import { t } from 'app';
import API from 'api';
import { actions } from 'store/rootSlices';
import ErrorHandler from 'shared/errorHandler';

function* onMountSaga(action) {
  try {
    console.log('Action payload:', action.payload); // Debug log

    yield put(actions.GradesGuide.setLoadingMarkdown(true));
    const markdowns = yield call(API.translations.getMarkdown.call);

    let param = action.payload;

    const guides = markdowns.find((markdown) => markdown.fields.name === 'pautasAjuda');

    if (guides) {
      const selectedGuide = guides.fields.properties.find((field) => field.key === param);

      console.log('Selected Guide:', selectedGuide); 

      if (selectedGuide) {
        yield put(actions.GradesGuide.setMarkdown(selectedGuide));
      } else {
        console.error('Guide not found for param:', param);
      }

      yield put(actions.GradesGuide.setLoadingMarkdown(false));
    } else {
      throw new Error('Guides not found');
    }
  } catch (e) {
    console.error('Error in onMountSaga:', e);

    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetScheduleGuide'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {
  yield put(actions.GradesGuide.reset());
}

export default function* watcherSignin() {
  yield takeLatest('GradesGuide/onMount', onMountSaga);
  yield takeLatest('GradesGuide/onUnmount', onUnmountSaga);
}
