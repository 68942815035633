/**
 * Home scene sagas
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { put, call, takeLatest } from 'redux-saga/effects';

import { t } from 'app';

import API from 'api';

import { actions } from 'store/rootSlices';
//import { actions } from 'store/rootSlices'

import ErrorHandler from 'shared/errorHandler';

function* onMountSaga() {
  try {
    const phases = yield call(API.sgh.getPhases.call);
    yield put(actions.Home.setPhases(phases));
    yield put(actions.Home.setLoadingPhases(false));

    //get markdown
    const markdowns = yield call(API.translations.getMarkdown.call);
    const cards = markdowns.find((markdown) => markdown.fields.name === 'paginaPrincipal');

    yield put(
      actions.Home.setMarkdown(
        cards.fields.properties.map((property) => ({
          value: property.value,
          valueEn: property.valueEn,
          valueCh: property.valueCh,
        }))
      )
    );
  } catch (e) {
    const shouldRun = yield call(ErrorHandler, e);
    if (shouldRun) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.actionErrorGetAllPhases'),
          icon: 'error',
          type: 'danger',
        })
      );
    }
  }
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('Home/onMount', onMountSaga);
  yield takeLatest('Home/onUnmount', onUnmountSaga);
}
