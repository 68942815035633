/**
 * NewApplication scene slice
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IApplicationData, IApplicationTypes } from '../types';
import { IDropdownOption } from 'shared/types';
import { IParams } from 'api';

const initialState = {
  currentStep: 1,
  applicationTypeId: -1,
  applicationData: {
    registrationId: null,
    type: {
      theme: null,
      id: null,
      name: null,
    },
    form: null,
    formValues: null,
  } as IApplicationData,
  applicationTypes: {
    data: {} as IApplicationTypes,
    filters: [] as IDropdownOption[],
  },
  applicationTypePagination: {
    limit: null as null | number,
    offset: null as null | number,
    totalPages: null as null | number,
    totalRecords: null as null | number,
  },
  filterParams: {
    type: '' as string,
    search: '' as string,
  },
  numAdditionalDocs: 1,
  loadingStatus: {
    loading: true,
    tabLoading: true,
  },
  errorStatus: {
    errLoading: false,
    errNoApplications: false,
    errLoadingApplicationList: false,
    errLoadingApplicationForm: false,
    errSubmittingForm: false,
  },
};

export default createSlice({
  name: 'NewApplication',
  initialState,
  reducers: {
    onMount: () => {},
    onUnmount: () => {},
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setApplicationData: (state, action: PayloadAction<IApplicationData>) => {
      state.applicationData = action.payload;
    },
    getApplicationTypes: (state, action?: PayloadAction<IParams>) => {},
    setApplicationTypes: (state, action: PayloadAction<any>) => {
      state.applicationTypes = {
        data: action.payload.data,
        filters: action.payload.filters.map((filter, key) => ({
          key: key,
          text: filter,
          value: filter,
        })),
      };
    },
    setActiveRegistration: () => {},
    setApplicationTypeId: (state, action: PayloadAction<number>) => {
      state.applicationTypeId = action.payload;
    },
    getApplicationForm: () => {},
    submitApplication: () => {},
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setTypeFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.type = action.payload;
    },
    setFormSchema: (state, action: PayloadAction<any>) => {
      state.applicationData.form = action.payload;
    },
    setNumAdditionalDocs: (state, action: PayloadAction<number>) => {
      state.numAdditionalDocs = action.payload;
    },
    addAdditionalDoc: (state) => {
      state.numAdditionalDocs += 1;
    },
    remAdditionalDoc: (state) => {
      state.numAdditionalDocs -= 1;
    },
    resetAdditionDocs: (state) => {
      state.numAdditionalDocs = initialState.numAdditionalDocs;
    },
    addDocumentToFormSchema: (state, action: PayloadAction<any>) => {},
    removeDocumentFromFormSchema: (state, action: PayloadAction<any>) => {},
    resetApplicationSelection: (state) => {
      state.applicationData.type = initialState.applicationData.type;
      state.applicationData.form = initialState.applicationData.form;
      state.applicationData.formValues = initialState.applicationData.formValues;
      state.numAdditionalDocs = initialState.numAdditionalDocs;
      state.applicationTypes = initialState.applicationTypes;
    },
    resetStepThree: (state) => {
      state.applicationData.form = initialState.applicationData.form;
      state.applicationData.formValues = initialState.applicationData.formValues;
      state.numAdditionalDocs = initialState.numAdditionalDocs;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    resetPage: (state) => initialState,
  },
});
