/**
 * EligibilityGraph container
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { t } from 'app';

import { Text } from '@paco_ua/pacoui';

interface IEligibilityGraph {
  data: {
    eligibilityState: number;
    signUpsAmount: number;
    ectsEarned: number;
    minimumECTS: number;
    maximumECTS: number;
  };
}

const EligibilityGraph: React.FC<IEligibilityGraph> = ({ data }) => {
  const theme = useContext(ThemeContext);

  const ectsScale = [60, 120, 180, 240, 300, 360];
  const signUpsScale = [3, 4, 5, 6, 7, 8];
  const signupsValue =
    ectsScale[signUpsScale.indexOf(data.signUpsAmount)] ??
    (data.signUpsAmount === 2 ? 30 : data.signUpsAmount ? 15 : 1);

  const getEligibilityColor = (status: number) => {
    if (status === 100) {
      return theme.colors.dangerRed;
    } else if (status === 1 || status === 2) {
      return theme.colors.successGreen;
    } else if (status === 25 || status === 50) {
      return theme.colors.decisionYellow;
    } else if (status === -1) {
      return theme.colors.grey;
    }
  };

  return (
    <Wrapper>
      <AxisCaption>
        {ectsScale.map((value, index) => {
          if (index === 0) {
            return (
              <ECTSValue index={index} key={`ectsValue-${index}`}>
                <Text size="xSmall" color={theme.colors.plusDarkGrey} weight={'regular'}>
                  {t('registrations.detailsEligibilityGraphAccumulatedECTS')}
                </Text>
                <CaptionTopText
                  key={`signUpsScale-${index}`}
                  size="xSmall"
                  weight={'regular'}
                  color={theme.colors.plusDarkGrey}
                >
                  {ectsScale[index]}
                </CaptionTopText>
              </ECTSValue>
            );
          } else {
            return (
              <ECTSValue index={index} key={`ectsValue-${index}`}>
                <CaptionTopText
                  key={`signUpsScale-${index}`}
                  size="xSmall"
                  weight={'regular'}
                  color={theme.colors.plusDarkGrey}
                >
                  {index !== 4 ? ectsScale[index] : ''}
                </CaptionTopText>
              </ECTSValue>
            );
          }
        })}
      </AxisCaption>
      <ECTSBar>
        <ECTSApproved
          width={(data.ectsEarned * 100) / 360}
          background={getEligibilityColor(data.eligibilityState)}
        />
        <ECTSMinimum width={(data.minimumECTS * 100) / 360} />
        <ECTSMaximum width={(data.maximumECTS * 100) / 360} />
      </ECTSBar>
      <SignUpsBar>
        <SignUpsAmount width={(signupsValue * 100) / 360} />
      </SignUpsBar>
      <AxisCaption lower>
        {signUpsScale.map((value, index) => {
          if (index === 0) {
            return (
              <SignUpsValue key={`signUpsValue-${index}`}>
                <NumText size="xSmall" color={theme.colors.plusDarkGrey} weight={'regular'}>
                  {t('registrations.detailsEligibilityGraphSignupsAmount')}
                </NumText>
                <CaptionBottomText
                  key={`signUpsScale-${index}`}
                  size="xSmall"
                  color={theme.colors.plusDarkGrey}
                  weight={'regular'}
                >
                  {signUpsScale[index]}
                </CaptionBottomText>
              </SignUpsValue>
            );
          } else {
            return (
              <SignUpsValue key={`signUpsValue-${index}`}>
                <CaptionBottomText
                  key={`signUpsScale-${index}`}
                  size="xSmall"
                  color={theme.colors.plusDarkGrey}
                  weight={'regular'}
                >
                  {index !== 4 ? signUpsScale[index] : ''}
                </CaptionBottomText>
              </SignUpsValue>
            );
          }
        })}
      </AxisCaption>

      <GraphCaptions>
        <LeftCaptions>
          <CaptionElementWrapper>
            <CaptionElementColor divider={theme.colors.successGreen} />
            <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
              {t('registrations.detailsEligibilityGraphMinimumECTS')}
            </Text>
          </CaptionElementWrapper>
          <CaptionElementWrapper>
            <CaptionElementColor divider={theme.colors.primary} />
            <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
              {t('registrations.detailsEligibilityGraphMaximumECTS')}
            </Text>
          </CaptionElementWrapper>
          <CaptionElementWrapper>
            <CaptionElementColor background={theme.colors.primary} />
            <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
              {t('registrations.detailsEligibilityGraphSignups')}
            </Text>
          </CaptionElementWrapper>
        </LeftCaptions>

        <RightCaptions>
          <CaptionElementWrapper>
            <CaptionElementColor background={theme.colors.successGreen} />
            <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
              {t('registrations.detailsEligibilityStatusGreen')}
            </Text>
          </CaptionElementWrapper>
          <CaptionElementWrapper>
            <CaptionElementColor background={theme.colors.decisionYellow} />
            <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
              {t('registrations.detailsEligibilityStatusYellow')}
            </Text>
          </CaptionElementWrapper>
          <CaptionElementWrapper>
            <CaptionElementColor background={theme.colors.dangerRed} />
            <Text size="xSmall" color={theme.colors.grey} weight={'regular'}>
              {t('registrations.detailsEligibilityStatusRed')}
            </Text>
          </CaptionElementWrapper>
        </RightCaptions>
      </GraphCaptions>
    </Wrapper>
  );
};

export default EligibilityGraph;

const Wrapper = styled.div``;

const AxisCaption = styled.div<{ lower?: boolean }>`
  display: flex;

  > div {
    width: calc(100% / 6);
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
  }

  > div:not(:first-child) {
    display: flex;
    justify-content: flex-end;
  }

  > div:not(:last-child) {
    > div:last-child {
      margin-right: ${({ lower }) => (lower ? `-2px` : `-7px`)};
    }
  }
`;

const ECTSBar = styled.div`
  width: 100%;
  height: 55px;
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const ECTSApproved = styled.div<{ width: number; background?: string }>`
  width: ${({ width }) => width}%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${({ background }) => (background ? background : `transparent`)};
  opacity: 0.7;
`;

const ECTSMinimum = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 2px solid ${({ theme }) => theme.colors.successGreen};
`;

const ECTSMaximum = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 2px solid ${({ theme }) => theme.colors.primary};
`;

const SignUpsBar = styled.div`
  width: 100%;
  height: 6px;
  margin-top: 1px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const SignUpsAmount = styled.div<{ width: number }>`
  width: ${({ width }) => width}%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

const ECTSValue = styled.div<{ index: number }>``;

const SignUpsValue = styled.div``;

const GraphCaptions = styled.div`
  width: 75%;
  display: flex;
  margin-top: 24px;
`;

const LeftCaptions = styled.div`
  margin-right: 50px;

  > div:not(:first-child) {
    margin-top: 8px;
  }
`;

const RightCaptions = styled.div`
  > div:not(:first-child) {
    margin-top: 8px;
  }
`;

const CaptionElementWrapper = styled.div`
  display: flex;
  align-items: center;

  > div:first-child {
    margin-right: 8px;
  }
`;

const CaptionElementColor = styled.div<{ background?: string; divider?: string }>`
  width: 40px;
  height: 24px;
  display: flex;
  justify-content: center;
  background: ${({ theme, background }) => (background ? background : theme.colors.lightGrey)};

  &:after {
    content: '';
    width: 2px;
    height: 100%;
    display: ${({ divider }) => (divider ? 'block' : 'none')};
    background-color: ${({ divider }) => divider};
  }
`;

const CaptionBottomText = styled(Text)`
  margin-top: 4px;
`;

const CaptionTopText = styled(Text)`
  margin-bottom: 4px;
`;

const NumText = styled(Text)`
  margin-top: 4px;
`;
