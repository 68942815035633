import { put } from 'redux-saga/effects';

import { actions } from 'store/rootSlices';

import { t } from 'app';

import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_ERROR_MESSAGE_SERVER,
  GENERAL_ERROR_MESSAGE_REQUEST,
  GRADES_ERROR_MESSAGE,
  PAYMENT_ERROR_MESSAGE,
  CERTIFICATE_ERROR_MESSAGE,
  PERSONAL_DATA_ERROR_MESSAGE
} from './errorMessages';

export default function* (err) {
  try {
    // front end error handling
    if (err.message === GENERAL_ERROR_MESSAGE.INTERCEPTOR_LOGOUT) {
      yield put(actions.App.signout());
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_expiredToken'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.message === GENERAL_ERROR_MESSAGE.ERR_SGH_SCHEDULE_MAX) {
      yield put(
        actions.Toaster.showToaster({
          title: t('sgh.general_errSghScheduleMax'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.message === GENERAL_ERROR_MESSAGE.ERR_INVALID_ACCESS) {
      yield put(actions.App.navigateBack());
      yield put(
        actions.Toaster.showToaster({
          title: t('superuser.errUnauthorizedAccess'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (!err || !err.response || !err.response.data || !err.response.data.error_code) {
      console.log('ErrorHandler - unsupported error message format:', err);
    }

    // api error handling
    if (err.response.data.error_code === GENERAL_ERROR_MESSAGE.BLACKLISTED_REQUEST) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_blacklistedRequest'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === GENERAL_ERROR_MESSAGE.ERR_INVALID_STUDENT) {
      yield put(actions.App.navigateTo({ key: 'home' }));
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_invalidStudent'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code === GENERAL_ERROR_MESSAGE.ERR_STUDENT_INVALID_REGISTRATION
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_invalidRegistration'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === GRADES_ERROR_MESSAGE.ERR_FORBIDDEN_PAUTA_REQUEST) {
      yield put(
        actions.Toaster.showToaster({
          title: t('pautas.invalidGrade'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === GRADES_ERROR_MESSAGE.ERR_TEACHER_NOT_FOUND) {
      yield put(
        actions.Toaster.showToaster({
          title: t('pautas.teacherNotFound'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (Object.values(GENERAL_ERROR_MESSAGE_SERVER).includes(err.response.data.error_code)) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_errorServer'),
          icon: 'error',
          type: 'danger',
        })
      );
      yield put(actions.App.navigateTo({ key: 'pageServerError' }));
      return false;
    } else if (
      Object.values(GENERAL_ERROR_MESSAGE_REQUEST).includes(err.response.data.error_code)
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t('secVirtualNotifications.general_invalidRequest'),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    }

    //api payments error handling
    if (err.response.data.error_code === PAYMENT_ERROR_MESSAGE.ERR_DEBT_DELETED) {
      yield put(actions.App.navigateTo({ key: 'payments' }));
      yield put(
        actions.Toaster.showToaster({
          title: t(`secVirtualNotifications.payments_${PAYMENT_ERROR_MESSAGE.ERR_DEBT_DELETED}`),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === PAYMENT_ERROR_MESSAGE.ERR_DEBT_PAID) {
      yield put(actions.App.navigateTo({ key: 'payments' }));
      yield put(
        actions.Toaster.showToaster({
          title: t(`secVirtualNotifications.payments_${PAYMENT_ERROR_MESSAGE.ERR_DEBT_PAID}`),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_REGISTRATION_SUSPENDED_CERT
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_REGISTRATION_SUSPENDED_CERT}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_REGISTRATION_NOT_FINISHED_CERT
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_REGISTRATION_NOT_FINISHED_CERT}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_UNPAID_CERT_DEBTS) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_UNPAID_CERT_DEBTS}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_STUDENT_INVALID_CERTIFICATE
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_STUDENT_INVALID_CERTIFICATE}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code ===
      CERTIFICATE_ERROR_MESSAGE.ERR_STUDENT_INVALID_CERTIFICATE_TYPE
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_STUDENT_INVALID_CERTIFICATE_TYPE}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_CERT_PAID) {
      yield put(
        actions.Toaster.showToaster({
          title: t(`secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_CERT_PAID}`),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_CERT_CANCELLED) {
      yield put(
        actions.Toaster.showToaster({
          title: t(`secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_CERT_CANCELLED}`),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_IDENTICAL_CERT_PROCESSING
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_IDENTICAL_CERT_PROCESSING}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_GRADE_FINAL_COURSE) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_GRADE_FINAL_COURSE}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (
      err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_FINAL_COURSE_DOC_FINAL_MISSING
    ) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_FINAL_COURSE_DOC_FINAL_MISSING}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === CERTIFICATE_ERROR_MESSAGE.ERR_DEBT_DELETED) {
      yield put(
        actions.Toaster.showToaster({
          title: t(`secVirtualNotifications.certs_${CERTIFICATE_ERROR_MESSAGE.ERR_DEBT_DELETED}`),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else if (err.response.data.error_code === PAYMENT_ERROR_MESSAGE.ERR_ASSOCIATED_DEBT_MBREF) {
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.newPaymentRequest_${PAYMENT_ERROR_MESSAGE.ERR_ASSOCIATED_DEBT_MBREF}`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    }  else if (err.response.data.error_code === PERSONAL_DATA_ERROR_MESSAGE.ERR_STUDENT_DOESNT_EXIST) {
      yield put(actions.App.navigateTo({ key: 'home' }));
      yield put(
        actions.Toaster.showToaster({
          title: t(
            `secVirtualNotifications.general_invalidStudent`
          ),
          icon: 'error',
          type: 'danger',
        })
      );
      return false;
    } else {
      return true;
    }
  } catch (e) {
    // Most likely some error in the code triggered the catch, as the
    // 'err' object is not well structured
    yield put(
      actions.Toaster.showToaster({
        title: t('secVirtualNotifications.general_exception'),
        icon: 'error',
        type: 'danger',
      })
    );
  }
}
