import React from "react";
import styled from "styled-components";
// import 'animate.css';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 31;
  z-index: 1102;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const ModalWrapper = styled.div`
  background-color: #fff;
  position: fixed;
  width: 60%;
  padding: 1rem;
  z-index: 1103;
  top: 0;
  left: 0;
  margin: 1% 20% 0 20%;
  max-width: 100%; // Ensure it doesn't exceed the device width

  @media (max-width: 768px) {
    width: 90%;
    margin: 5% 5%;
    max-width: 90%;
  }
`;

interface ModalProps {
  children: React.ReactNode;
  changeModal: (...args: any[]) => void;
}

const Modal: React.FC<ModalProps> = (props) => {
  const hideModal = () => {
    props.changeModal();
  };

  return (
    <>
      <Backdrop className="animate__animated animate__fadeIn animate__fast" onClick={hideModal}></Backdrop>
      <ModalWrapper className="animate__animated animate__fadeIn animate__fast">{props.children}</ModalWrapper>
    </>
  );
};

export default Modal;