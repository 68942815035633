/**
 * Home scene
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */
import React, { useContext, useEffect } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignIn, faTable } from '@fortawesome/pro-light-svg-icons';
import { Grid } from 'semantic-ui-react';

import { useDispatch, useSelector } from 'react-redux';

import { CallToAction, Button, Text, Card } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { actions } from 'store/rootSlices';
import * as selectors from './logic/selectors';

import { redirectAuth } from 'app/utils';

import AuthWrapper from 'shared/components/AuthWrapper';
import ReactMarkdown from 'react-markdown';
import MarkdownRenderer from 'shared/containers/MarkdownRenderer';

const Home = ({ t }) => {
  const { onMount } = actions.Home;
  const { navigateTo } = actions.App;
  const dispatch = useDispatch();

  const theme = useContext(ThemeContext);
  const { user } = useSelector(selectors.getAppSlice);
  const { phases, loadingPhases, markdown } = useSelector(selectors.getHomeSlice);

  useEffect(() => {
    dispatch(onMount());
  }, []);

  return (
    <PageStructure
      loading={loadingPhases}
      expanded
      headerLeft={<Header title={t('generic.pacoFullName')} />}
      subHeader={
        <Text weight="medium" color="primary" size="large">
          {/* {!user?.accessToken && t('home.subtitle')} */}
          {user?.accessToken && t('generic.hello', { who: user?.fullName || '' })}
        </Text>
      }
      mainContent={
        <ContentWrapper>
          {!user?.accessToken && (
            <Grid>
              <Grid.Column mobile={16} tablet={8} computer={8} largeScreen={8} widescreen={4}>
                <Card shadow="strong" data-testid={`home_card_login_schedules`}>
                  <CardTitle>
                    <Text color="plusDarkGrey" weight="regular" size="medium">
                      {`${t('home.cardLoginTitle', { textOnly: true })}`}
                    </Text>
                    <Text color="darkGrey" weight="light" size="article">
                      {`${t('home.cardLoginBody', { textOnly: true })}`}
                    </Text>
                  </CardTitle>
                  <CardButton>
                    <Button
                      data-testid={`home_button_login`}
                      onClick={() => {
                        redirectAuth();
                      }}
                      action
                    >
                      {t('generic.enter')}
                    </Button>
                  </CardButton>
                </Card>
              </Grid.Column>
            </Grid>
          )}
          <Grid>
            {markdown.map((cards) => (
              <Grid.Column mobile={16} tablet={16} computer={16} largeScreen={8} widescreen={8}>
                <CardWrapper>
                  <Card
                    style="min-height"
                    shadow="strong"
                    data-testid={`home_card_login_schedules`}
                  >
                    <MarkdownRenderer
                      value={cards?.value || ''}
                      valueEn={cards?.valueEn || ''}
                      valueCh={cards?.valueCh || ''}
                    />
                  </Card>
                </CardWrapper>
              </Grid.Column>
            ))}
          </Grid>
        </ContentWrapper>
      }
    />
  );
};

export default Home;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
`;

const CardButton = styled.div``;

const CardWrapper = styled.div`
  height: 100%;

  > div {
    min-height: 250px;
    height: 100%;
  }
`;
