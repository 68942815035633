/**
 * FreeOptionsTable container slice
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDropdownOption } from 'shared/types';
import {
  IFreeOption,
  IPaginationInfo,
  IFreeOptionsPayload,
  ICallParams,
  IRegistration,
  DEFAULT_PAGINATION_PARAMS,
} from '../types';

const initialState = {
  freeOptionsList: [] as IFreeOption[],
  freeOptionsPage: {
    limit: DEFAULT_PAGINATION_PARAMS.limit,
    offset: DEFAULT_PAGINATION_PARAMS.offset,
    totalPages: 0,
    totalRecords: 0,
  } as IPaginationInfo,
  departmentFilterList: [] as IDropdownOption[],
  cycleFilterList: [] as IDropdownOption[],
  semester: null as number | null,
  year: null as number | null,
  filterParams: {
    department: '' as string,
    cycle: '' as string,
    search: '' as string,
  },
  loadingStatus: {
    loading: true as boolean,
    loadingTable: true as boolean,
  },
  errorStatus: {
    errLoading: false as boolean,
    errLoadingFreeOptions: false as boolean,
  },
};

export default createSlice({
  name: 'FreeOptionsTable',
  initialState,
  reducers: {
    onMount: (state, action: PayloadAction<IRegistration & { mandatoryFilters?: boolean }>) => {},
    fetchFreeOptions: (
      state,
      action: PayloadAction<ICallParams & IRegistration & { mandatoryFilters?: boolean }>
    ) => {},
    setFreeOptionsData: (state, action: PayloadAction<IFreeOptionsPayload>) => {
      state.freeOptionsList = action.payload.data;
      state.departmentFilterList = action.payload.filters[0].department.map(
        (filter: any, key: number) => ({
          key: `${key}${filter}`,
          text: filter?.key,
          value: filter?.value,
        })
      );
      state.cycleFilterList = action.payload.filters[2].cycle.map((filter: any, key: number) => ({
        key: `${key}${filter}`,
        text: filter?.key,
        value: filter?.value,
      }));
      state.year = action.payload.year;
      state.semester = action.payload.semester;
      state.freeOptionsPage = {
        limit: action.payload.limit,
        offset: action.payload.offset,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalRecords,
      };
    },
    removeUcList: (state, action: PayloadAction<number>) => {
      state.freeOptionsList = state.freeOptionsList.reduce((acc: any, uc) => {
        if (uc.id !== action.payload) {
          acc.push(uc);
        }
        return acc;
      }, [] as any);
    },
    setSearchFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.search = action.payload;
    },
    setDepartmentFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.department = action.payload;
    },
    setFreeOptionsPage: (
      state,
      action: PayloadAction<{
        limit: number;
        offset: number;
        totalPages: number;
        totalRecords: number;
      }>
    ) => {
      state.freeOptionsPage = {
        limit: action.payload.limit,
        offset: action.payload.offset,
        totalPages: action.payload.totalPages,
        totalRecords: action.payload.totalRecords,
      };
    },
    setCycleFilter: (state, action: PayloadAction<string>) => {
      state.filterParams.cycle = action.payload;
    },
    setYear: (state, action: PayloadAction<number | null>) => {
      state.year = action.payload;
    },
    setSemester: (state, action: PayloadAction<number | null>) => {
      state.semester = action.payload;
    },
    setLoadingStatus: (
      state,
      action: PayloadAction<{ fieldName: string; fieldValue: boolean }>
    ) => {
      state.loadingStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setErrorStatus: (state, action: PayloadAction<{ fieldName: string; fieldValue: boolean }>) => {
      state.errorStatus[action.payload.fieldName] = action.payload.fieldValue;
    },
    setDepartmentFilterList: (state, action: PayloadAction<string[]>) => {
      state.departmentFilterList = action.payload.map((filter: string, key: number) => ({
        key: `${key}${filter}`,
        text: filter,
        value: filter,
      }));
    },
    onUnmount: (state) => initialState,
    reset: (state) => initialState,
  },
});
