/**
 * Multi Payments Selection container utilities
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import { ITranslations, IPagination } from 'scenes/VirtualSecretary/Payments/types';

export const DEFAULT_STRUCTURE = (t: ITranslations) => ({
  header: {
    type: 'columnTitle',
    titles: [
      { text: t('payments.debitsTablePaymentDescription', { textOnly: true }) },
      { text: t('payments.debitsTablePaymentValue', { textOnly: true }) },
      { text: t('payments.debitsTableDueDate', { textOnly: true }) },
      { text: t('payments.debitsTablePaymentStatus', { textOnly: true }) },
      { text: t('generic.select', { textOnly: true }) },
    ],
  },
  rows: [],
});

export const DEFAULT_PAGINATION = (total: number): IPagination => ({
  hasNext: total > 5 ? true : false,
  hasPrevious: false,
  offset: 0,
  limit: 5,
  total,
});

export const findItem = (debitId, preSelectedDebits) =>
  preSelectedDebits.some((preSelectedDebit) => preSelectedDebit.debitId === debitId);
