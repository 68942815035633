import React, { useEffect, useState } from 'react';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';
import { formatDate } from './utils';
import * as selectors from './logic/selectors';
import { Button, Table, AnimatedBackground } from '@paco_ua/pacoui';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentRoute } from 'utils';
import { actions } from 'store/rootSlices';
import styled from 'styled-components';
import GradesChart from './Chart/index';
import PdfModal from './PDFModal';
import GradeTitle from 'shared/components/GradeTitle';

const LoadingWrapper = styled.div`
  width: 100%;
  height: 300px;

  * {
    margin: 8px 0px;
  }
`;

const GradesDetails = ({ theme, t }) => {
  const dispatch = useDispatch();
  const { pautaData, dataExame, gradeCounts, loadingStatus, pdfUrl, isPdfReady, isPdfModalOpen } = useSelector(selectors.getGradeDetailsSlice);
  const [customTooltip, setCustomTooltip] = useState({
    display: false,
    position: { x: 0, y: 0 },
    content: ''
  });
  const { navigateTo } = actions.App;
  const { getGradeDetails } = actions.GradesDetails;
  const codigoPauta = getCurrentRoute().params.codigoPauta;
  const formattedDate = dataExame ? formatDate(dataExame) : '';
  const currentLocale = useSelector(selectors.getCurrentLocale);

  useEffect(() => {
    dispatch(actions.GradesDetails.onMount());
    return () => {
      dispatch(actions.GradesDetails.onUnmount());
    };
  }, [dispatch]);

  useEffect(() => {
    if (codigoPauta !== null) {
      dispatch(getGradeDetails({ codigoPauta }));
    }
  }, [codigoPauta, getGradeDetails, dispatch]);

  const handleIsPdfModalOpen = () => {
    dispatch(actions.GradesDetails.setIsPdfModalOpen());
  }

  const handlePreview = () => {
    dispatch(actions.GradesDetails.fetchPdf());
    dispatch(actions.GradesDetails.setIsPdfModalOpen());
  };

  const tableStructure = {
    header: {
      titles: [
        { text: t('pautas.codigoDisciplina'), style: { textAlign: 'left' } },
        { text: t('pautas.nomeDisciplina'), style: { textAlign: 'left', width: '12%' } },
        { text: t('pautas.codigoPauta'), style: { textAlign: 'left' } },
        { text: t('pautas.anoLectivo'), style: { textAlign: 'left' } },
        { text: t('pautas.semestre'), style: { textAlign: 'left', width: '2%' } },
        { text: t('pautas.tipoExame'), style: { textAlign: 'left', width: '5%' } },
        { text: t('pautas.examDate'), style: { textAlign: 'left', width: '10%' } },
        { text: t('pautas.studentsNumber'), style: { textAlign: 'left', width: '3%' } },
        { text: t('pautas.gradeState'), style: { textAlign: 'left', width: '10%' } },
        { text: '', style: { textAlign: 'left' } },
      ],
      type: 'columnTitle'
    },
    rows: pautaData.map((item, index) => ({
      cells: [
        { cellProps: '', content: item.codDisciplina },
        { cellProps: '', content: currentLocale === 'pt' ? item.nomeDisciplina : item.nomeDisciplinaEng },
        { cellProps: '', content: item.codPauta },
        { cellProps: '', content: item.anoLectivo },
        { cellProps: '', content: item.semestre },
        { cellProps: '', content: item.tipoExame },
        { cellProps: '', content: formattedDate },
        { cellProps: '', content: item.ntotalAlunos },
        { cellProps: '', content: t(`pautas.gradeState` + item.estadoActual) },
        {
          cellProps: '',
          content: (
            <div style={{ display: 'flex', gap: '1rem' }}>
              <Button onClick={() => dispatch(navigateTo({ key: 'gradeHistory', params: { codigoPauta: item.codPauta } }))} action>
                {t('pautas.historic')}
              </Button>
              {(item.estadoActual === 7 || item.estadoActual === 8) && (
                <Button action onClick={handlePreview}>
                  {t('pautas.visualize')}
                </Button>
              )}
            </div>
          )
        },
      ],
      key: `row-${index}`,
      collapsableRows: [],
    })),
  };

  return (
    <PageStructure
      headerLeft={<Header title={t('routes.gradeDetails')} />}
      expanded={true}
      mainContent={
        <>
          {(isPdfReady && isPdfModalOpen) && (
            <PdfModal
              changeModal={handleIsPdfModalOpen}
              pdfUrl={pdfUrl}
              scale={0.8}
              pautaCode={codigoPauta || ''}
            />
          )}
          {customTooltip.display && (
            <div style={{
              position: 'absolute',
              left: customTooltip.position.x,
              top: customTooltip.position.y,
              backgroundColor: 'white',
              border: '1px solid #ccc',
              padding: '8px',
              zIndex: 1000
            }}>
              {customTooltip.content}
            </div>
          )}
          {(loadingStatus.loadingGradeDetailsInfo || loadingStatus.loadingGradeDetailsCounts) && (
            <LoadingWrapper>
              <AnimatedBackground height={theme.sizes.large} width="40%" />
              <AnimatedBackground height={theme.sizes.large} width="40%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
              <AnimatedBackground height={theme.sizes.large} width="100%" />
            </LoadingWrapper>
          )}
          {(
            !loadingStatus.loadingGradeDetailsInfo
            && pautaData.length !== 0
            && !loadingStatus.loadingGradeDetailsCounts
            && dataExame !== ""
            && gradeCounts
            && Object.keys(gradeCounts).length > 0)
            && (
              <>
                <GradeTitle
                  codDisciplina={pautaData[0].codDisciplina.toString()}
                  nomeDisciplina={pautaData[0].nomeDisciplina}
                  nomeDisciplinaEng={pautaData[0].nomeDisciplinaEng}
                  tipoExame={pautaData[0].tipoExame}
                  epocaExame={pautaData[0].epocaExame}
                  anoLectivo={pautaData[0].anoLectivo}
                  codPauta={pautaData[0].codPauta} />
                <div style={{ width: '100%', overflowX: 'auto' }}>
                  <Table structure={tableStructure} />
                </div>
                <GradesChart gradeCounts={gradeCounts} loadingStatus={loadingStatus} setCustomTooltip={setCustomTooltip} />
              </>
            )}
        </>
      }
    />
  );
}

export default GradesDetails;