/**
 * CertificateReady container
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */

import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import moment from 'moment';
import numeral from 'numeral';

import { t } from 'app';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { faCreditCard, faClock, faTimes, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { MbIcon } from 'shared/components/Icons';

import { Text, StatusLabel } from '@paco_ua/pacoui';
import PdfReader from './PdfReader';
import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

import { ICertificateDetails } from '../../logic/slice';

import { getDeliveryMethod } from '../../utils';
import CertificateState from '../../components/CertificateState';
import { ECertificateState } from 'scenes/VirtualSecretary/Certificates/CertificatesList/types';
import CertificatesDetails from '../..';

import config from 'config';
import API from 'api';
import { useDispatch } from 'react-redux';
import { actions } from 'store/rootSlices';

interface IProps {
  certificatesDetails: ICertificateDetails;
  certificateFile?: Uint8Array | string | null;
}

const CertificatesReady: React.FC<IProps> = ({ certificatesDetails, certificateFile }) => {
  const theme = useContext(ThemeContext);
  const dispatch = useDispatch();

  const PAID_STATUS_COLOR = {
    noDebits: theme.colors.darkGrey,
    awaitingPayment: theme.colors.decisionYellow,
    paid: theme.colors.dangerRed,
  };

  const getColor = (state) => {
    switch (state) {
      case ECertificateState.awaitingExemptionDecision:
        return theme.colors.decisionYellow;
      case ECertificateState.awaitingPayment:
        return theme.colors.darkGrey;
      case ECertificateState.rejected:
      case ECertificateState.cancelled:
        return theme.colors.dangerRed;
      case ECertificateState.ready:
        return theme.colors.primary;
      default:
        return theme.colors.softRegularGrey;
    }
  };

  const getLabelState = () => {
    switch (certificatesDetails.state.state) {
      case ECertificateState.pending:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.decisionYellow}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faClock} />}
                >
                  {t('certificates.labelProcessing')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.awaitingPayment:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.darkGrey}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faCreditCard} />}
                >
                  {t('certificates.labelAwaitsPayment')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.awaitingExemptionDecision:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.darkGrey}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faCreditCard} />}
                >
                  {t('certificates.esenta')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.rejected:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.dangerRed}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<FontAwesomeIcon icon={faTimes} />}
                >
                  {t('certificates.labelRejected')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
      case ECertificateState.ready:
        return (
          <StatusLabelWrapper>
            <StatusLabel
              background={theme.colors.successGreen}
              label={
                <Text
                  weight="medium"
                  size="xSmall"
                  color="white"
                  icon={<MbIcon fill={theme.colors.white} width={12} height={12} />}
                >
                  {t('certificates.labelPaid')}
                </Text>
              }
            />
          </StatusLabelWrapper>
        );
    }
  };

  const getRequestDateLabel = () => {
    const date = moment(certificatesDetails.state.expirationDate);

    if (moment().isAfter(date, 'day')) {
      return (
        <Text size="xSmall" weight="medium" color="dangerRed">
          {date.format('DD-MM-YYYY')}
        </Text>
      );
    } else {
      return (
        <Text size="xSmall" weight="medium" color="successGreen">
          {date.format('DD-MM-YYYY')}
        </Text>
      );
    }
  };

  return (
    <PageStructure
      headerLeft={<Header pullBackButton backButton title={t('routes.certificatesDetails')} />}
      mainContent={
        certificateFile && (
          <PdfReader
            height="800px"
            document={{
              url: certificateFile,
              name: certificatesDetails.certificate.name,
            }}
          />
        )
      }
      secondaryContent={
        <DetailsWrapper>
          <DetailsTitle>
            <Text size="large" weight="medium" color="plusDarkGrey">
              {t('certificates.details')}
            </Text>
            {/* <Text size="xSmall" color="plusDarkGrey">
              {`${t('certificates.request', { textOnly: true })}: ${
                certificatesDetails.state.id
              } | ${moment(certificatesDetails.state.requestDate).format('DD-MM-YYYY')}`}
            </Text> */}
            <SegmentWrapper>
              <SegmentSubHeader>
                <Text color="plusDarkGrey" weight="regular" size="xSmall">
                  {t('certificates.request', { textOnly: true })}: {certificatesDetails.state.id} |{' '}
                  {t('certificates.listRequestDate', { textOnly: true })}:{' '}
                  {moment(certificatesDetails.state.requestDate).format('DD-MM-YYYY')}
                </Text>

                <TextWrapper size="article" weight="medium" color="plusDarkGrey">
                  {`${certificatesDetails.state.type}`}
                </TextWrapper>
              </SegmentSubHeader>
              <SegmentLabels>
                {certificatesDetails.state.responseDate && (
                  <ResponseDateWrapper>
                    <DateWrapper>
                      <TextLabel size="xSmall" weight="regular" color="darkGrey">
                        {t('certificates.sentDate', { textOnly: true })}
                      </TextLabel>
                      <TextLabel size="xSmall" weight="medium" color="plusDarkGrey">
                        {moment(certificatesDetails.state.responseDate).format('DD-MM-YYYY')}
                      </TextLabel>
                    </DateWrapper>
                    {certificatesDetails.state.expirationDate && (
                      <ValidDateWrapper>
                        <TextLabel size="xSmall" weight="regular" color="darkGrey">
                          {t('certificates.expirationDate', { textOnly: true })}
                        </TextLabel>
                        <TextLabel size="xSmall" weight="medium" color="plusDarkGrey">
                          {getRequestDateLabel()}
                        </TextLabel>
                      </ValidDateWrapper>
                    )}
                  </ResponseDateWrapper>
                )}

                {!certificatesDetails.state.responseDate && (
                  <ResponseDateWrapper>
                    <DateWrapper>
                      <TextLabel size="xSmall" weight="regular" color="darkGrey">
                        {t('certificates.state', { textOnly: true })}
                      </TextLabel>
                      {(certificatesDetails.state.state === ECertificateState.pending ||
                        certificatesDetails.state.state ===
                          ECertificateState.awaitingExemptionDecision) && (
                        <TextLabel size="xSmall" weight="medium" color="decisionYellow">
                          {t('certificates.stateProcessing', { textOnly: true })}
                        </TextLabel>
                      )}
                      {(certificatesDetails.state.state === ECertificateState.cancelled ||
                        certificatesDetails.state.state === ECertificateState.rejected) && (
                        <TextLabel size="xSmall" weight="medium" color="dangerRed">
                          {t('certificates.cancelled', { textOnly: true })}
                        </TextLabel>
                      )}
                      {certificatesDetails.state.state === ECertificateState.submitted && (
                        <TextLabel size="xSmall" weight="medium" color="darkGrey">
                          {t('certificates.submittedRequest', { textOnly: true })}
                        </TextLabel>
                      )}
                      {certificatesDetails.state.state === ECertificateState.ready && (
                        <TextLabel size="xSmall" weight="medium" color="successGreen">
                          {t('certificates.ready', { textOnly: true })}
                        </TextLabel>
                      )}
                      {certificatesDetails.state.state === ECertificateState.awaitingPayment && (
                        <FontAwesomeIcon icon={faEllipsisH} />
                      )}
                    </DateWrapper>
                  </ResponseDateWrapper>
                )}

                {certificatesDetails.state.state !== ECertificateState.rejected &&
                  certificatesDetails.state.state !== ECertificateState.cancelled && (
                    <PaymentStateWrapper>
                      <TextLabel size="xSmall" weight="regular" color="darkGrey">
                        {t('certificates.paymentState', { textOnly: true })}
                      </TextLabel>
                      {getLabelState()}
                    </PaymentStateWrapper>
                  )}
              </SegmentLabels>
            </SegmentWrapper>
          </DetailsTitle>
          <Separator />
          {certificatesDetails.certificate.id > 0 && (
            <>
              <DetailsSentTitle>
                <Text size="article" weight="medium" color="grey">
                  {t('certificates.actions')}
                </Text>
              </DetailsSentTitle>
              <Text
                as="a"
                size="small"
                weight="medium"
                color="plusDarkGrey"
                style={{ width: '100%' }}
                onClick={() => {
                  dispatch(
                    actions.CertificatesDetails.openChangeHistoryModal({
                      certificateId: certificatesDetails.state.id,
                      registrationId: certificatesDetails.state.registrationId,
                    })
                  );
                }}
              >
                <ChangesItem>
                  {t('certificates.changeHistory')}
                  <FontAwesomeIcon size="lg" icon={faChevronRight} />
                </ChangesItem>
              </Text>
              <Separator />
            </>
          )}

          <DetailsItems>
            <DetailItem>
              <Text size="mediumSmall" weight="regular" color="primary">
                {t('certificates.detailsLang')}
              </Text>
              <Text size="small" weight="regular" color="plusDarkGrey">
                {certificatesDetails.state.language}
              </Text>
            </DetailItem>
            {certificatesDetails.state.format && (
              <DetailItem>
                <Text size="mediumSmall" weight="regular" color="primary">
                  {t('certificates.detailsFormat')}
                </Text>
                <Text size="small" weight="regular" color="plusDarkGrey">
                  {certificatesDetails.state.format}
                </Text>
              </DetailItem>
            )}
            {getDeliveryMethod(certificatesDetails) && (
              <DetailItem>
                <Text size="mediumSmall" weight="regular" color="primary">
                  {t('certificates.detailsDeliveryType')}
                </Text>
                <DetailSubItem>
                  <Text size="small" weight="regular" color="plusDarkGrey">
                    {getDeliveryMethod(certificatesDetails)}
                  </Text>
                  {certificatesDetails.state.method === 'deliveryMethodMail' &&
                    certificatesDetails.history.pending && (
                      <Text size="xSmall" color="grey">
                        {t('certificates.shipmentDataDate')}:{' '}
                        {moment(certificatesDetails.history.pending).format('DD-MM-YYYY')}
                      </Text>
                    )}
                </DetailSubItem>
              </DetailItem>
            )}
          </DetailsItems>
          {certificatesDetails.state.format === 'Digital'
            ? certificatesDetails.email && (
                <>
                  <Separator />
                  <DetailsSent>
                    <DetailsSentTitle>
                      <Text size="article" weight="medium" color="grey">
                        {t('certificates.shipmentData')}
                      </Text>
                    </DetailsSentTitle>
                    <DetailsSentItems>
                      {certificatesDetails.email && (
                        <DetailsSentItem>
                          <Text size="xSmall" color="grey">
                            {t('certificates.detailsStudentEmail')}
                          </Text>
                          <Text size="xSmall" color="plusDarkGrey">
                            {certificatesDetails.email}
                          </Text>
                        </DetailsSentItem>
                      )}
                    </DetailsSentItems>
                  </DetailsSent>
                </>
              )
            : (certificatesDetails?.address?.homeAddress ||
                certificatesDetails?.address?.homeLocality ||
                certificatesDetails?.address?.homePostalCode ||
                certificatesDetails.email) && (
                <>
                  <Separator />
                  <DetailsSent>
                    <DetailsSentTitle>
                      <Text size="article" weight="medium" color="grey">
                        {t('certificates.shipmentData')}
                      </Text>
                    </DetailsSentTitle>
                    <DetailsSentItems>
                      {certificatesDetails.email && (
                        <DetailsSentItem>
                          <Text size="xSmall" color="grey">
                            {t('certificates.detailsStudentEmail')}
                          </Text>
                          <Text size="xSmall" color="plusDarkGrey">
                            {certificatesDetails.email}
                          </Text>
                        </DetailsSentItem>
                      )}
                      {(certificatesDetails?.address?.homeAddress ||
                        certificatesDetails?.address?.homeLocality ||
                        certificatesDetails?.address?.homePostalCode) && (
                        <>
                          <DetailsSentItem>
                            <Text size="xSmall" color="grey">
                              {t('generic.address')}
                            </Text>
                            <Text size="xSmall" color="plusDarkGrey">
                              {certificatesDetails.address.homeAddress}
                            </Text>
                          </DetailsSentItem>
                          <DetailsSentItem row={true}>
                            <DetailsSentItem>
                              <Text size="xSmall" color="grey">
                                {t('generic.locality')}
                              </Text>
                              <Text size="xSmall" color="plusDarkGrey">
                                {certificatesDetails.address.homeLocality}
                              </Text>
                            </DetailsSentItem>
                            <DetailsSentItem>
                              <Text size="xSmall" color="grey">
                                {t('generic.postCode')}
                              </Text>
                              <Text size="xSmall" color="plusDarkGrey">
                                {certificatesDetails.address.homePostalCode.number}
                              </Text>
                            </DetailsSentItem>
                          </DetailsSentItem>
                        </>
                      )}
                    </DetailsSentItems>
                  </DetailsSent>
                </>
              )}
          <Separator />
          <CostDetails>
            <Text size="article" weight="medium" color="primary">
              {t('certificates.exemptionTitle')}
            </Text>
            <Text size="small" weight="medium" color="plusDarkGrey">
              {certificatesDetails.state.isExempt
                ? t('certificates.withExemption')
                : t('certificates.withoutExemption')}
            </Text>

            {certificatesDetails.state.isExempt && (
              <Text size="small" color="plusDarkGrey">
                {t('certificates.exemptionMotiveShort')}:{' '}
                {certificatesDetails.state.exemptionMotive}
              </Text>
            )}

            {!certificatesDetails.state.isExempt ?? (
              <Text size="small" transform="uppercase" color="plusDarkGrey">
                {t('certificates.totalPaid')}: &nbsp;
                <Text size="small" transform="none" weight="medium" color="plusDarkGrey">
                  {t('generic.euros', {
                    count: numeral(certificatesDetails.payment?.totalCost).format('0.00'),
                  })}
                </Text>
              </Text>
            )}
          </CostDetails>
          {certificatesDetails.certificate !== null && (
            <Text
              size="small"
              weight="medium"
              color="plusDarkGrey"
              icon={<FontAwesomeIcon size="lg" icon={faFilePdf} />}
            >
              {certificatesDetails.certificate?.name}
            </Text>
          )}
          {certificatesDetails.payment?.receipt !== null && certificatesDetails.payment !== null && (
            <>
              <Separator />
              <CostDetails>
                <Text size="article" weight="medium" color="primary">
                  {t('payments.receipt')}
                </Text>
                <Text size="small" weight="medium" color="plusDarkGrey">
                  {certificatesDetails.payment?.receipt?.id}
                </Text>
              </CostDetails>
              <Text
                as="a"
                size="small"
                weight="medium"
                color="plusDarkGrey"
                onClick={() => {
                  API.files.getFileOnTab
                    .call(
                      `${config.API_WSO2}${config.API_SEC_VIRTUAL_PATH}${certificatesDetails.payment?.receipt?.url}`
                    )
                    .catch((e) => {
                      dispatch(
                        actions.Toaster.showToaster({
                          title: t('secVirtualNotifications.general_errorLoadingFile'),
                          icon: 'error',
                          type: 'danger',
                        })
                      );
                    });
                }}
                icon={<FontAwesomeIcon size="lg" icon={faFilePdf} />}
              >
                {certificatesDetails.payment?.receipt?.name}
              </Text>
            </>
          )}
        </DetailsWrapper>
      }
    />
  );
};

export default CertificatesReady;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  div:nth-child(2) {
    margin-top: 8px;
  }
`;

const DetailsItems = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ChangesItem = styled.div`
  display: flex;
  flex-direction: Row;
  justify-content: space-between;
`;

const DetailItem = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const DetailSubItem = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsSentTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`;

const DetailsSentItems = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 10px;
  }
`;

const DetailsSentItem = styled.div<{ row?: boolean }>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};

  > div:not(:last-child) {
    margin-right: ${({ row }) => (row ? '24px' : '0')};
  }

  div:nth-child(2) {
    /* margin-top: 8px; */
  }
`;

const DetailsSent = styled.div`
  display: flex;
  flex-direction: column;
`;

const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.softRegularGrey};
  margin: 16px 0;
`;

const CostDetails = styled.div`
  display: flex;
  flex-direction: column;

  > div:not(:first-child) {
    margin-top: 4px;
  }

  > div:last-child {
    margin-bottom: 18px;
  }

  div:nth-child(2) {
    margin-top: 10px;
  }

  div:nth-child(3) {
    margin-top: 10px;
  }

  div:nth-child(4) {
    margin-top: 10px;
  }
`;

const SegmentLabels = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top 8px;
  padding-top: -8px;

  > div {
    margin-top: 8px;
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

const SegmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`;

const StatusLabelWrapper = styled.div`
  margin-top: 4px;
`;

const TextWrapper = styled(Text)`
  margin-top: 4px;
  line-height: 1.3;
`;

const TextLabel = styled(Text)`
  margin-top: 4px;
`;
const SegmentSubHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ValidDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0px !important;
`;

const ResponseDateWrapper = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-right: 24px;
  }
`;

const PaymentStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
