/**
 * AplicationDetails scene logic selectors
 *
 * @author Rafael Guedes <rguedes@ubiwhere.com>
 *
 */

import { RootState } from 'store/rootSlices';

export const getApplicationDetailsSlice = (state: RootState) => state.ApplicationDetails;
export const getUser = (state: RootState) => state.App.user;
export const getStatus = (state: RootState) => state.ApplicationDetails.status;
export const getDetails = (state: RootState) => state.ApplicationDetails.details;
export const getRegistration = (state: RootState) => state.StudentRegistrationDropdown.registration;
