/**
 * Mb icon
 *
 * @author Nuno Gago <ngago@ubiwhere.com>
 *
 */

import React from 'react';

interface IProps {
  width: number;
  height: number;
  fill?: string;
}

const MbIcon = ({ width, height, fill }: IProps) => (
  <svg width={width} height={height} viewBox="0 0 153.98 181.88">
    {/* <title id="logo__title">Mb</title> */}
    <desc id="logo__desc">Mb icon</desc>

    <g fill={fill || '#3d73b9'}>
      <path
        d="M83.48,153.58h63.06c14.39,0,14.54-15.3,13.07-22.85-.8-5.08-9.43-5-10.38,0v5.89a4.91,4.91,0,0,1-4.9,4.9H23.45a4.91,4.91,0,0,1-4.9-4.9v-5.89c-.95-5-9.58-5.08-10.38,0-1.47,7.55-1.32,22.85,13.07,22.85H83.48ZM38.91,7h97.22c6.83,0,12.42,5.92,12.42,13.15v6.28c0,8.88-12,8.84-12,.06V23.07a4,4,0,0,0-4-4H35.13a4,4,0,0,0-4,4v3.36c0,8.83-11.46,8.77-11.46.19V20.16C19.7,12.93,25.28,7,32.11,7Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M146.1,79.21a19.22,19.22,0,0,1,11.38,17.22c0,10.58-9.24,19.24-20.53,19.24H105.28a5.23,5.23,0,0,1-5.38-5V50.39a5.3,5.3,0,0,1,5.29-5.28h26.37a20.13,20.13,0,0,1,14.54,34.1m-23.63-4.9h10.05V74.2a9.61,9.61,0,0,0,8.12-9.45h0a9.59,9.59,0,0,0-9.56-9.56H110.83v50H136.5a9.88,9.88,0,1,0,0-19.75h-4v0H122.47a5.55,5.55,0,1,1,0-11.1"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M42.92,172.08a1.7,1.7,0,0,1,3.4,0v9.18h0a7.63,7.63,0,0,1-7.61,7.62h0a7.63,7.63,0,0,1-7.62-7.61h0v-9.18a1.7,1.7,0,1,1,3.39,0v9.18h0a4.27,4.27,0,0,0,4.24,4.23h0a4.25,4.25,0,0,0,4.23-4.24h0Z"
        transform="translate(-7.28 -7.01)"
      />
    </g>

    <g fill={fill || '#1d1d1b'}>
      <path
        d="M59,185.49a1.7,1.7,0,1,1,0,3.4H54.28a6.37,6.37,0,0,1-6.37-6.37h0V172.08a1.69,1.69,0,1,1,3.38,0V182.5h0a3,3,0,0,0,3,3H59Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M94.87,109.66A6.36,6.36,0,1,1,82.23,111L77,63.09,58.59,109.94l0,0h0l0,.12,0,.1v0l0,.1,0,.06,0,.09,0,0,0,.1,0,0A6.12,6.12,0,0,1,57,112.33v0l-.1.08a5.75,5.75,0,0,1-1.1.78l0,0-.12.06h0l-.11.06,0,0-.07,0-.08,0-.07,0-.08,0-.06,0-.07,0-.06,0h0l-.06,0-.08,0,0,0a6.05,6.05,0,0,1-1.86.35h-.46a6.09,6.09,0,0,1-2.08-.43l-.09,0h0l-.09,0-.08,0-.06,0-.08,0-.07,0-.05,0-.11-.06h0l-.1-.06-.06,0a6,6,0,0,1-1-.67l0,0-.21-.19h0l-.16-.15L48,112h0l-.19-.21,0,0a6,6,0,0,1-.67-1l0-.06-.06-.1h0l-.06-.11,0,0,0-.07,0-.08,0-.06,0-.09,0-.08v0l0-.09L28.27,63.09,23.06,111a6.35,6.35,0,1,1-12.63-1.34l6.14-56.48v0h0a10.53,10.53,0,0,1,1.18-3.8,10.54,10.54,0,0,1,7.9-5.48h0l.34,0a10.87,10.87,0,0,1,1.61,0h0a10.23,10.23,0,0,1,1.92.26,10.43,10.43,0,0,1,7.39,6.38L52.65,90.32,68.36,50.41a10.45,10.45,0,0,1,9.31-6.64h0a10.87,10.87,0,0,1,1.61,0l.34,0h0a10.56,10.56,0,0,1,7.9,5.48,12.16,12.16,0,0,1,.77,1.84,10.36,10.36,0,0,1,.41,2h0v0Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M30.43,187a1.7,1.7,0,1,1-3.37.4l-1.45-12.23-5.19,12a1.7,1.7,0,0,1-2.23.89,1.67,1.67,0,0,1-.88-.89h0l-5.19-12-1.46,12.23a1.7,1.7,0,1,1-3.37-.4L9,172.8a2.73,2.73,0,0,1,1.49-2.11,2.52,2.52,0,0,1,.33-.15l.13,0a2.18,2.18,0,0,1,.5-.11h0a2.89,2.89,0,0,1,2.37.75,2.57,2.57,0,0,1,.34.39,2.8,2.8,0,0,1,.2.32,1.74,1.74,0,0,1,.1.19l4.4,10.2L23.26,172a2.8,2.8,0,0,1,2-1.61,4,4,0,0,1,.5-.07h.52a2.86,2.86,0,0,1,2.12,1.35h0a2.42,2.42,0,0,1,.23.49,1.59,1.59,0,0,1,.09.33,1.7,1.7,0,0,1,0,.22v0Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M66.4,187.19a1.7,1.7,0,1,1-3.4,0V173.78H58.17a1.7,1.7,0,0,1,0-3.4H71.23a1.7,1.7,0,0,1,0,3.4H66.4Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M77,187.19a1.7,1.7,0,1,1-3.4,0V172.08a1.7,1.7,0,0,1,3.4,0Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M111.81,187a1.7,1.7,0,0,1-3.37.41l-.6-4.8H102a1.7,1.7,0,1,1,0-3.39h5.46l-.16-1.25h0s0-.08,0-.12a6.77,6.77,0,0,0-.19-.87,5.69,5.69,0,0,0-.35-.89,3.86,3.86,0,0,0-3.44-2.29h0a4,4,0,0,0-.86.1,3.38,3.38,0,0,0-.76.29,4.75,4.75,0,0,0-2.36,3.78l-1.06,9.44a1.69,1.69,0,1,1-3.36-.37l1.06-9.44a8.12,8.12,0,0,1,4.19-6.44,7,7,0,0,1,1.5-.55,7.31,7.31,0,0,1,1.65-.19h0a7.15,7.15,0,0,1,6.46,4.17,9.64,9.64,0,0,1,.58,1.44,9.76,9.76,0,0,1,.29,1.39.4.4,0,0,1,0,.15v0Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M117,187.19a1.7,1.7,0,1,1-3.39,0v-14h0a1.37,1.37,0,0,1,0-.29,2.45,2.45,0,0,1,.13-.55h0l.14-.3h0a2.44,2.44,0,0,1,1.33-1.11l.23-.06.18,0h0l.4,0h.05a2.4,2.4,0,0,1,1.79.92l9,11.53V172.08a1.7,1.7,0,1,1,3.4,0v14a2.39,2.39,0,0,1-.89,1.85,2.16,2.16,0,0,1-.35.24,1.36,1.36,0,0,1-.21.1h0l-.14.06h0a2.39,2.39,0,0,1-1.82-.1l-.22-.11a2,2,0,0,1-.33-.26,2.27,2.27,0,0,1-.22-.22l-.06-.07h0l0,0L117,176Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M143.52,185.49a1.7,1.7,0,1,1,0,3.4h-5a6.69,6.69,0,0,1-6.69-6.69h0v-5.11h0a6.71,6.71,0,0,1,6.69-6.69h5a1.7,1.7,0,0,1,0,3.4h-5a3.33,3.33,0,0,0-2.34,1,3.28,3.28,0,0,0-1,2.33h0v5.11h0a3.33,3.33,0,0,0,3.31,3.31h5Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M153.35,173.77h0a4.62,4.62,0,0,0-3.21,1.28,4.16,4.16,0,0,0-1.31,3h0v3.17h0a4.15,4.15,0,0,0,1.3,3,4.62,4.62,0,0,0,3.21,1.28h0a4.62,4.62,0,0,0,3.21-1.28,4.16,4.16,0,0,0,1.31-3h0v-3.17h0a4.19,4.19,0,0,0-1.3-3,4.67,4.67,0,0,0-3.22-1.28m0-3.39h0a8,8,0,0,1,5.56,2.22,7.53,7.53,0,0,1,2.35,5.44h0v3.17h0a7.53,7.53,0,0,1-2.35,5.44,8,8,0,0,1-5.56,2.22h0a8,8,0,0,1-5.56-2.22,7.56,7.56,0,0,1-2.34-5.44h0v-3.17h0a7.56,7.56,0,0,1,2.34-5.44,8,8,0,0,1,5.56-2.22h0Z"
        transform="translate(-7.28 -7.01)"
      />
      <path
        d="M81.81,173.78v11.71h6.56a2.13,2.13,0,0,0,2.13-2.14h0a2.12,2.12,0,0,0-.56-1.44l-.07-.06a2.09,2.09,0,0,0-1.5-.64H86.11a1.7,1.7,0,0,1,0-3.4h.77a2.09,2.09,0,0,0,1.28-.59h0a2,2,0,0,0,.6-1.43h0a2,2,0,0,0-2-2H81.81Zm-3.4,5.75v-7.38h0a1.64,1.64,0,0,1,.14-.67,2,2,0,0,1,.39-.59l0,0h0a1.74,1.74,0,0,1,1.12-.48h6.65a5.41,5.41,0,0,1,5.41,5.41h0a5.47,5.47,0,0,1-.83,2.88,5.44,5.44,0,0,1,1,.76l.11.12a5.5,5.5,0,0,1,1.51,3.79h0a5.53,5.53,0,0,1-5.52,5.52H80.11a1.7,1.7,0,0,1-1.7-1.7Z"
        transform="translate(-7.28 -7.01)"
      />
    </g>
  </svg>
);

MbIcon.defaultProps = {
  width: 24,
  height: 24,
};

export default MbIcon;
