/**
 * AllSchedules scene
 *
 * @author Carlos Silva <csilva@ubiwhere.com>
 *
 */
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-light-svg-icons';

import { Button, NoDataMessage } from '@paco_ua/pacoui';

import PageStructure from 'shared/containers/PageStructure';
import Header from 'shared/containers/TitleHeader';

const AllSchedules = ({ t }) => {
  return (
    <PageStructure
      headerLeft={<Header title={t('routes.allSchedules')} />}
      expanded
      mainContent={
        <CenteredWrapper>
          <NoDataMessage
            header={t('sgh.warningAllSchedulesLinkHeader')}
            body={t('sgh.warningAllSchedulesLinkBody')}
          />

          <Button
            onClick={() => {
              window.open(t('links.schedules', { textOnly: true }), '_blank');
            }}
            rightIcon={<FontAwesomeIcon icon={faAngleRight} />}
            full
            primary
          >
            {t('sgh.buttonAllSchedulesLink')}
          </Button>
        </CenteredWrapper>
      }
    />
  );
};

export default AllSchedules;

const CenteredWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    flex: 0 !important;
  }

  > button {
    margin-top: 32px;
  }
`;
