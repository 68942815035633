/**
 * ExamEnrollment scene sagas
 *
 * @author Diogo Guedes <dguedes@ubiwhere.com>
 *
 */

import { takeLatest, put } from 'redux-saga/effects';
import { actions } from 'store/rootSlices';

function* onMountSaga() {
  yield put(actions.ExamEnrollment.setLoading(false));
}

function* onUnmountSaga() {}

export default function* watcherSignin() {
  yield takeLatest('ExamEnrollment/onMount', onMountSaga);
  yield takeLatest('ExamEnrollment/onUnmount', onUnmountSaga);
}
