import * as yup from 'yup';
import PostalCodes from 'postal-codes-js';
import PhoneNumber from 'awesome-phonenumber';

// field can be empty, and the emptiness is validated (required) in the emptyInputField addMethod()
yup.addMethod(yup.object, 'phone', function (param) {
  return this.test(this.value, param, (value) => {
    const pn = new PhoneNumber(value.input, value.countryValue);
    return pn.isValid() || value.input === '';
  });
});

yup.addMethod(yup.object, 'inputFlagTextboxRequired', function (param) {
  return this.test(this.value, param, (value) => {
    return value.input !== '';
  });
});

yup.addMethod(yup.object, 'postal_code', function (param) {
  return this.test(this.value, param, (value) => {
    return PostalCodes.validate(value.countryValue, value.input);
  });
});

const groupValidations = (groupName) => ({
  oneOfRequired: (param) => ({
    type: 'oneOfRequired',
    params: [{ message: param, type: 'group' }, groupName],
  }),
});

yup.addMethod(yup.object, 'oneOfRequired', function (param, groupName) {
  return this.test(this.value, param, function (value) {
    const group = this?.parent[groupName];
    if (group) {
      return Object.keys(group).some((item) => {
        return group[item] !== undefined && group[item] !== null;
      });
    }
    return true;
  });
});

export function createYupSchema(schema, config) {
  const { id, validationType, validations = [] } = config;
  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();

  validations.forEach((validation) => {
    const { params, type } = validation;
    if (!validator[type]) {
      return;
    }
    validator = validator[type](...params);
  });
  schema[id] = validator;

  return schema;
}

export const createValidationFormData = (formData) => {
  let newFormatFormData = [];

  formData.forEach((field, index) => {
    if (!field?.hidden) {
      //if is a group of values inside another
      if (field.type === 'group' && field.values && field.values.length > 0) {
        const groupValidation = field.validations.map((validation) =>
          groupValidations(field.nameKey)[validation.type](validation.params[0])
        );

        field.values.forEach((fieldValue) => {
          newFormatFormData.push({
            id: fieldValue.nameKey,
            validationType: fieldValue.validationType,
            validations: [...groupValidation, ...fieldValue.validations],
          });
        });
      } else if (
        field.type === 'editableGroupComponent' &&
        field.values &&
        field.values.length > 0
      ) {
        const yupSchema = field.values
          .map((fieldValue) => {
            return {
              id: fieldValue.nameKey,
              validationType: fieldValue.validationType,
              validations: fieldValue.validations,
            };
          })
          .reduce(createYupSchema, {});

        newFormatFormData.push({
          id: field.nameKey,
          validationType: 'object',
          validations: [
            {
              type: 'shape',
              params: [yupSchema],
            },
          ],
        });
      } else {
        newFormatFormData.push({
          id: field.nameKey,
          validationType: field.validationType,
          validations: field.validations,
        });
      }
    }
  });

  return newFormatFormData;
};
